import { useReadContract } from "wagmi"
import { Address, erc20Abi } from "viem"
import { isUndefined } from "lodash"

export const useErc20TokenSymbol = (tokenAddress: Address | undefined) => {
  const isValidProps = !isUndefined(tokenAddress)

  const { data: symbol } = useReadContract({
    address: tokenAddress,
    abi: erc20Abi,
    functionName: "symbol",
    query: {
      enabled: isValidProps,
    },
  })

  return symbol
}
