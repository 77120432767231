import { Address, erc20Abi, maxUint256 } from "viem"
import { simulateContract, writeContract } from "@wagmi/core"
import { wagmiConfig } from "src/wagmiConfig"

export const approveErc20Token = async ({
  chainId,
  amount,
  spenderAddress,
  tokenAddress,
}: {
  chainId: number
  amount: bigint
  spenderAddress: Address
  tokenAddress: Address
}) => {
  const { request } = await simulateContract(wagmiConfig, {
    chainId,
    address: tokenAddress,
    abi: erc20Abi,
    functionName: "approve",
    args: [spenderAddress, amount ?? maxUint256],
  })

  const hash = await writeContract(wagmiConfig, request)

  return hash
}
