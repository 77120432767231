import { DOTS, usePagination } from "src/components/TablePagination/usePagination"
import { ArrowLeftIcon } from "../Icons/ArrowLeftIcon"
import { ArrowRightIcon } from "../Icons/ArrowRightIcon"

interface TablePaginationProps {
  totalCount: number
  pageSize: number
  siblingCount?: number
  currentPage: number
  onPageChange: (page: number) => void
}

const TablePagination = ({
  totalCount,
  pageSize,
  siblingCount = 1,
  currentPage,
  onPageChange,
}: TablePaginationProps) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  })
  if (currentPage === 0 || (paginationRange && paginationRange.length < 2)) {
    return null
  }

  const nextPage = () => {
    onPageChange(currentPage + 1)
  }

  const prevPage = () => {
    onPageChange(currentPage - 1)
  }

  let lastPage = paginationRange?.[paginationRange.length - 1]

  return (
    <div className="mt-6 flex items-center justify-center space-x-2">
      <div
        onClick={currentPage === 1 ? () => {} : prevPage}
        className={`flex h-9 w-9 items-center justify-center ${currentPage === 1 ? "cursor-auto opacity-30" : "cursor-pointer hover:opacity-80"}`}
      >
        <ArrowLeftIcon />
      </div>
      {/* <ChevronLeftIcon
        onClick={currentPage === 1 ? () => {} : prevPage}
        className={`w-6 h-6 ${currentPage === 1 ? "opacity-80" : "hover:opacity-80 hover:text-marginalOrange-500 cursor-pointer"}`}
      /> */}
      {paginationRange?.map((pageNumber) => {
        if (pageNumber.toString() === DOTS) {
          return <div className="">&#8230;</div>
        }

        return (
          <button
            disabled={currentPage === pageNumber}
            className={`${currentPage === pageNumber ? "rounded-lg bg-marginalGray-800 text-marginalGray-100 disabled:cursor-auto" : "cursor-pointer rounded-lg text-marginalGray-400 hover:opacity-80"} h-9 w-9`}
            onClick={() => onPageChange(Number(pageNumber))}
          >
            {pageNumber}
          </button>
        )
      })}

      <div
        onClick={currentPage === lastPage ? () => {} : nextPage}
        className={`flex h-9 w-9 items-center justify-center ${currentPage === lastPage ? "cursor-auto opacity-30" : "cursor-pointer hover:opacity-80"}`}
      >
        <ArrowRightIcon />
      </div>
    </div>
  )
}

export default TablePagination
