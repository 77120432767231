import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Token } from "../../types"
import { Address } from "viem"
import { BigNumberish } from "ethers"
import { RemoveLiquidityParams, RemoveLiquidityQuote } from "src/types"
import { getRemoveLiquidityQuote } from "src/pages/removeLiquidity/helpers/getRemoveLiquidityQuote"

export const EMPTY_REMOVE_LIQUIDITY_QUOTE: RemoveLiquidityQuote = {
  liquidityDelta: 0n,
  amount0: 0n,
  amount1: 0n,
  liquidityAfter: 0n,
}

export interface RemoveLiquidityState {
  tokenA: Token | null
  tokenB: Token | null
  inputValue: string
  poolAddress: string | null
  oracleAddress: string | null
  maintenance: BigNumberish | null
  quote: RemoveLiquidityQuote
  error: string | null
  loading: boolean
}

export const initialState: RemoveLiquidityState = {
  tokenA: null,
  tokenB: null,
  inputValue: "",
  poolAddress: null,
  oracleAddress: null,
  maintenance: null,
  quote: EMPTY_REMOVE_LIQUIDITY_QUOTE,
  error: null,
  loading: false,
}

export const fetchRemoveLiquidityQuote = createAsyncThunk(
  "removeLiquidity/fetchQuote",
  async ({
    chainId,
    quoterAddress,
    params,
  }: {
    chainId: number
    quoterAddress: Address
    params: RemoveLiquidityParams
  }) => {
    const quote = await getRemoveLiquidityQuote(chainId, quoterAddress, params)
    if (!quote) {
      throw new Error("Failed to fetch remove liquidity quote")
    }
    return quote
  },
)

export const removeLiquiditySlice = createSlice({
  name: "removeLiquidity",
  initialState,
  reducers: {
    setTokenA: (state: RemoveLiquidityState, action: PayloadAction<Token>) => {
      state.tokenA = action.payload
    },
    setTokenB: (state: RemoveLiquidityState, action: PayloadAction<Token>) => {
      state.tokenB = action.payload
    },
    setInputValue: (state: RemoveLiquidityState, action: PayloadAction<string>) => {
      state.inputValue = action.payload
    },
    resetRemoveLiquidityQuote: (state) => {
      state.quote = EMPTY_REMOVE_LIQUIDITY_QUOTE
      state.error = null
      state.loading = false
    },
    resetRemoveLiquidityState: (state) => {
      return initialState
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRemoveLiquidityQuote.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(fetchRemoveLiquidityQuote.fulfilled, (state, action) => {
        state.quote = action.payload
        state.loading = false
        state.error = null
      })
      .addCase(fetchRemoveLiquidityQuote.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message ?? "Failed to fetch quote"
      })
  },
})

export const {
  setTokenA,
  setTokenB,
  setInputValue,
  resetRemoveLiquidityState,
  resetRemoveLiquidityQuote,
} = removeLiquiditySlice.actions

export default removeLiquiditySlice.reducer
