import marginalBanner from "src/assets/marginal-launch-banner.svg"

export const LaunchBannerImage = ({
  hideImageMobile = false,
}: {
  hideImageMobile?: boolean
}) => {
  return (
    <div
      className={`${hideImageMobile ? "hidden md:flex" : ""} aspect-auto h-full w-full`}
    >
      <img src={marginalBanner} alt="marginal launch banner" className="rounded-r-2xl" />
    </div>
  )
}
