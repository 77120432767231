import { Address, getAddress } from "viem"
import { BigNumberish } from "ethers"

export interface IgniteQuoteParams {
  token0: Address
  token1: Address
  maintenance: string
  oracle: Address
  tokenId: string
  amountOutMinimum: bigint | string
  recipient: Address
  deadline: BigNumberish
}

interface ConstructIgniteQuoteInputs {
  token0: Address
  token1: Address
  maintenance: string
  oracle: Address
  tokenId: string
  amountOutMinimum: bigint
  recipient: Address
  currentBlockTimestamp?: bigint
  transactionDeadline: string
}

const MAX_DEADLINE =
  115792089237316195423570985008687907853269984665640564039457584007913129639935n

export const constructIgniteQuoteParams = ({
  token0,
  token1,
  maintenance,
  oracle,
  tokenId,
  amountOutMinimum,
  recipient,
  currentBlockTimestamp,
  transactionDeadline,
}: ConstructIgniteQuoteInputs): IgniteQuoteParams | null => {
  return {
    token0: getAddress(token0),
    token1: getAddress(token1),
    maintenance: maintenance,
    oracle: getAddress(oracle),
    tokenId: tokenId,
    amountOutMinimum: amountOutMinimum,
    recipient: getAddress(recipient),
    deadline: currentBlockTimestamp
      ? currentBlockTimestamp + BigInt(transactionDeadline) * BigInt("60")
      : MAX_DEADLINE,
  }
}
