import { ComponentProps } from "react"

export const BoxCheckIcon = (props: ComponentProps<"svg">) => (
  <svg
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clip-path="url(#clip0_7337_33002)">
      <rect y="0.5" width="15" height="15" rx="4" fill="#4C2D1E" />
      <g opacity="0.3">
        <path
          d="M3.125 9.27734L5.3125 11.4648L11.875 4.58984"
          stroke="#FF6B26"
          stroke-linecap="square"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_7337_33002">
        <rect y="0.5" width="15" height="15" rx="4" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
