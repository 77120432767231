import { useMemo, useEffect } from "react"
import { useAccount } from "wagmi"
import {
  useGetPositionsQuery,
  GetPositionsQuery,
  useGetPositionQuery,
  GetPositionQuery,
} from "src/state/api/generated"
import { useApplicationState } from "src/state/application/hooks"

interface PositionsState {
  isLoading: boolean
  isError: boolean
  error?: Error
  positions: { [tokenId: string]: GetPositionsQuery["positions"][0] } | undefined
  refetch: () => void
}

interface PositionState {
  isLoading: boolean
  isError: boolean
  error?: Error
  position: GetPositionQuery["position"]
  refetch: () => void
}

/**
 * Hook to fetch indexed positions from the subgraph
 * @returns {PositionsState} Object containing positions data and state
 */
export function useSubgraphIndexedPositions(): PositionsState {
  const { address } = useAccount()
  const { chainId } = useApplicationState()
  const {
    data: positionsData,
    refetch,
    isLoading,
    isError,
  } = useGetPositionsQuery({ address: address?.toLowerCase() ?? "" }, { skip: !address })

  useEffect(() => {
    if (address) {
      refetch()
    }
  }, [chainId, address, refetch])

  const positionsMap = useMemo(() => {
    if (!positionsData?.positions) return undefined
    return positionsData.positions.reduce<{
      [tokenId: string]: GetPositionsQuery["positions"][0]
    }>((acc, position) => {
      if (position.tokenId) {
        acc[position.tokenId] = position
      }
      return acc
    }, {})
  }, [positionsData?.positions])

  return useMemo(
    () => ({
      positions: address ? positionsMap : undefined,
      refetch,
      isLoading,
      isError,
    }),
    [positionsMap, refetch, isLoading, isError, address],
  )
}

/**
 * Hook to fetch a single indexed position from the subgraph
 * @param {string} indexedId - The ID of the position to fetch
 * @returns {PositionState} Object containing position data and state
 */
export function useSubgraphIndexedPosition(indexedId?: string): PositionState {
  const { chainId } = useApplicationState()
  const {
    data: positionData,
    refetch,
    isLoading,
    isError,
  } = useGetPositionQuery({ id: indexedId ?? "" }, { skip: !indexedId })

  useEffect(() => {
    if (indexedId) {
      refetch()
    }
  }, [chainId, indexedId, refetch])

  return useMemo(
    () => ({
      position: positionData?.position,
      refetch,
      isLoading,
      isError,
    }),
    [positionData?.position, refetch, isLoading, isError],
  )
}
