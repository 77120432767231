import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { Token } from "src/types"
import { AddLiquidityQuote } from "src/types"
import { fetchAddLiquidityQuote } from "./hooks"

export const EMPTY_ADD_LIQUIDITY_QUOTE: AddLiquidityQuote = {
  shares: 0n,
  amount0: 0n,
  amount1: 0n,
  liquidityAfter: 0n,
}

export enum FetchState {
  IDLE = "idle",
  LOADING = "loading",
  VALID = "valid",
  INVALID = "invalid",
}

export interface AddLiquidityState {
  tokenA: Token | null
  tokenB: Token | null
  inputValueA: string
  inputValueB: string
  addLiquidityQuote: AddLiquidityQuote | null
  status: FetchState
  error: any
}

const initialState: AddLiquidityState = {
  tokenA: null,
  tokenB: null,
  inputValueA: "",
  inputValueB: "",
  addLiquidityQuote: null,
  status: FetchState.IDLE,
  error: null,
}

const addLiquiditySlice = createSlice({
  name: "addLiquidity",
  initialState,
  reducers: {
    setTokenA: (state, action: PayloadAction<Token>) => {
      state.tokenA = action.payload
    },
    setTokenB: (state, action: PayloadAction<Token>) => {
      state.tokenB = action.payload
    },
    setInputValueA: (state, action: PayloadAction<string>) => {
      state.inputValueA = action.payload
    },
    setInputValueB: (state, action: PayloadAction<string>) => {
      state.inputValueB = action.payload
    },
    resetAddLiquidityQuote: (state) => {
      state.addLiquidityQuote = EMPTY_ADD_LIQUIDITY_QUOTE
      state.status = FetchState.IDLE
      state.error = null
    },
    resetAddLiquidityState: () => {
      return initialState
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAddLiquidityQuote.pending, (state) => {
        state.status = FetchState.LOADING
      })
      .addCase(fetchAddLiquidityQuote.fulfilled, (state, action) => {
        state.addLiquidityQuote = action.payload
        state.status = FetchState.VALID
        state.error = null
      })
      .addCase(fetchAddLiquidityQuote.rejected, (state, action) => {
        state.addLiquidityQuote = EMPTY_ADD_LIQUIDITY_QUOTE
        state.status = FetchState.INVALID
        state.error = action.payload
      })
  },
})

export const {
  setTokenA,
  setTokenB,
  setInputValueA,
  setInputValueB,
  resetAddLiquidityQuote,
  resetAddLiquidityState,
} = addLiquiditySlice.actions

export default addLiquiditySlice.reducer
