import { simulateContract, waitForTransactionReceipt, writeContract } from "@wagmi/core"
import { Address } from "viem"
import { wagmiConfig } from "src/wagmiConfig"
import MultiRewardsAbi from "src/constants/abis/MultiRewards.json"

export const stake = async (spenderAddress: Address, amount: bigint, chainId: number) => {
  const { request } = await simulateContract(wagmiConfig, {
    chainId,
    address: spenderAddress,
    abi: MultiRewardsAbi,
    functionName: "stake",
    args: [amount],
  })

  const hash = await writeContract(wagmiConfig, request)

  const receipt = await waitForTransactionReceipt(wagmiConfig, { hash })

  return receipt
}
