import React from "react"
import { useAccount } from "wagmi"
// import FeaturedCarousel from "src/components/Launch/FeaturedCarousel"
import { useMobileView } from "src/hooks/useMobileView"
import {
  MobileTableCell,
  PoolRowLoading,
  TableCell,
  TableHeaderCell,
  TableRow,
} from "src/components/Launch/TableComponents"
import { BoxCheckIcon } from "src/components/Icons/BoxCheckIcon"
import { DEFAULT_CHAIN_ID } from "src/constants/chains/chains"
import { CircularProgressBar } from "../pools/components"
import { useNavigateRoutes } from "src/hooks/useNavigateRoutes"
import { LaunchItemProps, useLBPoolsData } from "src/hooks/useLBData"
import { CaretRightIcon } from "src/components/Icons/CaretRightIcon"
import { TokenLogo } from "src/components/TokenBadge"

export const Launch = () => {
  const { address, chain } = useAccount()
  const { isMobileView } = useMobileView()

  const { onNavigateToLaunchProject } = useNavigateRoutes()

  const { pools, isLoading } = useLBPoolsData()

  return (
    <div className="space-y-6">
      {/* <FeaturedCarousel featuredItems={pools.concat({} as any)} /> */}

      <LaunchContainer>
        <div
          id="launches-header"
          className="flex w-full flex-col items-start space-y-4 bg-marginalGray-900 px-4 py-5"
        >
          <div className="flex items-center gap-2">
            <span className="text-xl text-[#CACACA]">Launches</span>

            <div className="bg-marginalGray-800 px-1 py-0.5 text-sm text-marginalGray-400">
              {pools.length}
            </div>
          </div>
        </div>

        <table
          className={
            isMobileView
              ? "w-full border-separate border-spacing-0 bg-marginalGray-900 p-2 text-marginalGray-200"
              : "w-full table-auto border-separate border-spacing-0 overflow-hidden bg-marginalGray-900 p-2"
          }
        >
          <thead>
            <>
              {isMobileView || !address ? (
                <></>
              ) : (
                <TableRow>
                  <TableHeaderCell align="left">Project Name</TableHeaderCell>
                  <TableHeaderCell>Status</TableHeaderCell>
                  <TableHeaderCell>Released</TableHeaderCell>
                  <TableHeaderCell>Funds Raised</TableHeaderCell>
                  <TableHeaderCell>Price</TableHeaderCell>
                </TableRow>
              )}
            </>
          </thead>
          <tbody className="">
            {isLoading ? (
              <>
                <PoolRowLoading
                  isMobile={isMobileView}
                  isOnlyPosition={false}
                  isFirst={true}
                  isLast={false}
                />
                <PoolRowLoading
                  isMobile={isMobileView}
                  isOnlyPosition={false}
                  isFirst={false}
                  isLast={false}
                />
                <PoolRowLoading
                  isMobile={isMobileView}
                  isOnlyPosition={false}
                  isFirst={false}
                  isLast={true}
                />
              </>
            ) : pools ? (
              pools.map((project: LaunchItemProps, index: number) => {
                return (
                  <LaunchRow
                    chainId={chain?.id ?? DEFAULT_CHAIN_ID}
                    project={project}
                    isMobile={isMobileView}
                    isOnlyPosition={pools.length === 1}
                    isFirst={pools[0] === pools[index]}
                    isLast={pools[pools.length - 1] === pools[index]}
                    onNavigate={() => onNavigateToLaunchProject(project.address)}
                  />
                )
              })
            ) : null}
          </tbody>
        </table>
      </LaunchContainer>
    </div>
  )
}

const LaunchRow = ({
  chainId,
  project,
  isMobile,
  isOnlyPosition,
  isFirst,
  isLast,
  onNavigate,
}: {
  chainId: number | undefined
  project: LaunchItemProps
  isMobile: boolean
  isOnlyPosition: boolean
  isFirst: boolean
  isLast: boolean
  onNavigate: () => void
}) => {
  return (
    <>
      {isMobile ? (
        <>
          <tr onClick={onNavigate}>
            <div
              className={`relative h-52 space-y-4 border-b bg-marginalGray-950 p-4 ${
                isOnlyPosition
                  ? "rounded-xl border-b-transparent"
                  : isFirst
                    ? "rounded-t-xl border-b-marginalGray-800"
                    : isLast
                      ? "rounded-b-xl border-b-transparent"
                      : "border-b-marginalGray-800"
              } `}
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-2 text-xs">
                  <TokenLogo
                    symbol={project.launchToken?.symbol}
                    imgUrl={project.launchToken?.imgUrl}
                    size={4}
                  />
                  <span className="text-sm text-marginalGray-200">{project.name}</span>
                  <BoxCheckIcon />
                </div>
                <CaretRightIcon />
              </div>

              <div className="flex">
                <MobileTableCell label={project.status}>
                  {project.statusTime}
                </MobileTableCell>
                <MobileTableCell label="Released">
                  {project.released ? (
                    <div className="flex items-center justify-start space-x-2">
                      <CircularProgressBar
                        sqSize={16}
                        strokeWidth={2}
                        percentage={parseFloat(project.released)}
                        className="stroke-marginalOrange-500"
                      />
                      <div>{project.released}</div>
                    </div>
                  ) : (
                    <div>-</div>
                  )}
                </MobileTableCell>
              </div>

              <div className="flex">
                <MobileTableCell label="Funds Raised">
                  {project.fundsRaised}
                </MobileTableCell>

                <MobileTableCell label="Price">{project.price}</MobileTableCell>
              </div>
            </div>
          </tr>
        </>
      ) : (
        <tr
          className={`w-fit border-none bg-marginalBlack ${
            isOnlyPosition
              ? "[&>td]:border-none first:[&>td]:rounded-bl-2xl first:[&>td]:rounded-tl-2xl last:[&>td]:rounded-br-2xl last:[&>td]:rounded-tr-2xl"
              : isFirst
                ? "first:[&>td]:rounded-tl-2xl last:[&>td]:rounded-tr-2xl"
                : isLast
                  ? "[&>td]:border-none first:[&>td]:rounded-bl-2xl last:[&>td]:rounded-br-2xl"
                  : ""
          }`}
        >
          <TableCell>
            <div className="flex">
              <div className="flex items-center space-x-3">
                <TokenLogo
                  symbol={project.launchToken?.symbol}
                  imgUrl={project.launchToken?.imgUrl}
                  size={7}
                />
                <span className="text-sm text-marginalGray-200">{project.name}</span>
                <BoxCheckIcon />
              </div>
            </div>
          </TableCell>

          <TableCell>
            <div className="flex justify-end space-x-2">
              <div
                className={`w-fit gap-2 rounded-sm px-2 py-0.5 text-xs ${
                  project.status === "Live Now"
                    ? "bg-success-800 text-success-500"
                    : project.status === "Upcoming"
                      ? "bg-[#162A3B] text-[#3BB7DB]"
                      : "bg-marginalOrange-800 text-marginalOrange-500"
                } `}
              >
                {project.status}
              </div>
              <div className="text-sm text-marginalGray-200">{project.statusTime}</div>
            </div>
          </TableCell>

          <TableCell>
            <div className="flex items-center justify-end space-x-2">
              <CircularProgressBar
                sqSize={16}
                strokeWidth={2}
                percentage={parseFloat(project.released)}
                className="stroke-marginalOrange-500"
              />
              <div>{project.released}</div>
            </div>
          </TableCell>

          <TableCell>{project.fundsRaised}</TableCell>

          <TableCell>{project.price}</TableCell>
          <TableCell>
            <button
              onClick={onNavigate}
              className="w-fit gap-1 rounded-lg bg-marginalOrange-800 px-4 py-2 text-sm text-marginalOrange-500 hover:opacity-80"
            >
              View
            </button>
          </TableCell>
        </tr>
      )}
    </>
  )
}

export const LaunchContainer = ({
  children,
  onMouseEnter,
  onMouseLeave,
}: {
  children: React.ReactNode
  onMouseEnter?: () => void
  onMouseLeave?: () => void
}) => {
  return (
    <div
      className="mx-auto w-[343px] min-w-min border border-marginalGray-800 shadow-none md:w-[720px] md:shadow-outerBlack lg:w-[900px] xl:w-[1000px]"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </div>
  )
}
