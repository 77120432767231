import { formatBigIntToString } from "src/utils/formatBigIntToString"
import { formatNumberAmount } from "src/utils/formatNumberAmount"
import { getAddLiquidityParamsWithSlippage } from "./getAddLiquidityParamsWithSlippage"
import { AddLiquidityQuote } from "src/types"
import { Token } from "src/types"

interface DerivedAddLiquidityQuoteData {
  token0Amount: {
    raw: bigint | undefined
    parsed: string | undefined
    formatted: string | null | undefined
  }
  token1Amount: {
    raw: bigint | undefined
    parsed: string | undefined
    formatted: string | null | undefined
  }
  shares: {
    raw: bigint | undefined
    parsed: string | undefined
    formatted: string | null | undefined
  }
  derivedAddLiquidityParamsWithSlippage: any
  quotedToken0AmountFormatted: string | null | undefined
  quotedToken1AmountFormatted: string | null | undefined
  quotedSharesFormatted: string | null | undefined
}

export const deriveAddLiquidityQuoteData = (
  addLiquidityQuote: AddLiquidityQuote | null,
  token0: Token | null | undefined,
  token1: Token | null | undefined,
  poolDecimals: string | undefined,
  maxSlippage: string,
  addLiquidityParams: any,
): DerivedAddLiquidityQuoteData => {
  // Process token0 amount data
  const token0Amount = {
    raw: addLiquidityQuote?.amount0,
    parsed: formatBigIntToString(addLiquidityQuote?.amount0, token0?.decimals),
    formatted: formatNumberAmount(
      formatBigIntToString(addLiquidityQuote?.amount0, token0?.decimals),
      true,
    ),
  }

  // Process token1 amount data
  const token1Amount = {
    raw: addLiquidityQuote?.amount1,
    parsed: formatBigIntToString(addLiquidityQuote?.amount1, token1?.decimals),
    formatted: formatNumberAmount(
      formatBigIntToString(addLiquidityQuote?.amount1, token1?.decimals),
      true,
    ),
  }

  // Process shares data
  const shares = {
    raw: addLiquidityQuote?.shares,
    parsed: formatBigIntToString(addLiquidityQuote?.shares, poolDecimals),
    formatted: formatNumberAmount(
      formatBigIntToString(addLiquidityQuote?.shares, poolDecimals),
      true,
    ),
  }

  const derivedAddLiquidityParamsWithSlippage = getAddLiquidityParamsWithSlippage(
    addLiquidityParams,
    addLiquidityQuote,
    maxSlippage,
  )

  return {
    token0Amount,
    token1Amount,
    shares,
    derivedAddLiquidityParamsWithSlippage,
    quotedToken0AmountFormatted: token0Amount.formatted,
    quotedToken1AmountFormatted: token1Amount.formatted,
    quotedSharesFormatted: shares.formatted,
  }
}
