import { Token } from "src/types"
import { ListRow } from "src/components/ui/ListRow"
import { CircularProgressBar } from "src/pages/pools/components"
import { TokenBadge } from "../../../components/TokenBadge"

export const RemoveLiquidityDetailList = ({
  token0,
  token1,
  quotedToken0Amount,
  quotedToken1Amount,
  poolUtilizationPercentage,
  userOwnedPoolSharePercentage,
}: {
  token0: Token | null | undefined
  token1: Token | null | undefined
  quotedToken0Amount: string | null | undefined
  quotedToken1Amount: string | null | undefined
  poolUtilizationPercentage: number
  userOwnedPoolSharePercentage: number
}) => {
  return (
    <div className="space-y-2 py-2 text-sm text-marginalGray-600">
      <ListRow
        item={`Pooled ${token0?.symbol}`}
        value={
          <div className="flex items-center space-x-1 text-marginalGray-200">
            <span>{quotedToken0Amount ?? "-"}</span>
            {token0 && <TokenBadge token={token0} reverse={true} size={4} />}
          </div>
        }
      />
      <ListRow
        item={`Pooled ${token1?.symbol}`}
        value={
          <div className="flex items-center space-x-1 text-marginalGray-200">
            <span>{quotedToken1Amount ?? "-"}</span>
            {token1 && <TokenBadge token={token1} reverse={true} size={4} />}
          </div>
        }
      />
      <ListRow
        item={`Pool Utilization`}
        value={
          <div className="flex items-center space-x-1 text-marginalGray-200">
            <span>{poolUtilizationPercentage.toFixed(2)}%</span>
            <CircularProgressBar
              sqSize={16}
              strokeWidth={2}
              percentage={poolUtilizationPercentage}
            />
          </div>
        }
      />
      <ListRow
        item={`Your Share`}
        value={
          <div className="flex items-center space-x-1 text-marginalGray-200">
            <span>{userOwnedPoolSharePercentage.toFixed(2)}%</span>
            <CircularProgressBar
              sqSize={16}
              strokeWidth={2}
              percentage={userOwnedPoolSharePercentage}
            />
          </div>
        }
      />
    </div>
  )
}
