import { LbpExactInputSingleParams } from "src/types"
import { formatStringToBigInt } from "src/utils/formatStringToBigInt"

const DECIMALS = 18
const HUNDRED_PERCENT = "100"
const ONE = 1000000000000000000n

interface LBPSlippageCalculationParams {
  amountIn: bigint
  poolPrice: bigint
  zeroForOne: boolean
  maxSlippage: string
}

const calculateMinimumOutputWithSlippage = ({
  amountIn,
  poolPrice,
  zeroForOne,
  maxSlippage,
}: LBPSlippageCalculationParams): bigint | undefined => {
  // Convert max slippage to effective percentage (e.g., 95% for 5% slippage)
  const effectivePercent = (100 - parseFloat(maxSlippage)).toString()

  const slippageNumerator = formatStringToBigInt(effectivePercent, DECIMALS)
  const slippageDenominator = formatStringToBigInt(HUNDRED_PERCENT, DECIMALS)

  if (!slippageNumerator || !slippageDenominator) {
    return undefined
  }

  // Calculate effective slippage percentage with precision
  const effectivePercentage = (slippageNumerator * ONE) / slippageDenominator

  // Calculate theoretical output based on pool price and direction
  const theoreticalOutput = zeroForOne
    ? (amountIn * poolPrice) / ONE
    : (amountIn * ONE) / poolPrice

  // Apply slippage to theoretical output
  return (theoreticalOutput * effectivePercentage) / ONE
}

export const getLbpSwapParamsWithSlippage = (
  params: LbpExactInputSingleParams | null,
  quote: any, // TODO: Define proper quote type
  zeroForOne: boolean,
  poolPrice: string | undefined,
  maxSlippage: string,
): LbpExactInputSingleParams | null => {
  // Input validation
  if (!params || !quote || !poolPrice) {
    return null
  }

  const minimumOutput = calculateMinimumOutputWithSlippage({
    amountIn: params.amountIn,
    poolPrice: BigInt(poolPrice),
    zeroForOne,
    maxSlippage,
  })

  if (!minimumOutput) {
    return null
  }

  // Return new params with calculated minimum output
  return {
    ...params,
    amountOutMinimum: minimumOutput,
  }
}
