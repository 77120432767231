import { parseEther } from "ethers"
import { isUndefined } from "lodash"
import { Token } from "src/types"
import { formatBigIntToString } from "src/utils/formatBigIntToString"

export const convertX96Price = (
  sqrtPriceX96?: bigint,
  token0?: Token | null,
  token1?: Token | null,
) => {
  if (isUndefined(sqrtPriceX96)) return undefined
  if (!token0 || !token1) return undefined

  const extend = BigInt(parseEther("1").toString())
  const denominator = 2n ** 192n

  const numerator = sqrtPriceX96 ** 2n * extend
  const divided = numerator / denominator

  const isSameDecimals = Number(token1.decimals) === Number(token0.decimals)
  const decimalDifference = Number(token1.decimals) - Number(token0.decimals)

  let parsed
  if (isSameDecimals) {
    parsed = formatBigIntToString(BigInt(divided), 18)
  } else {
    parsed = formatBigIntToString(BigInt(divided), 18 + decimalDifference)
  }

  return parsed
}

export const convertX96PricesToBigInt = (sqrtPriceX96?: bigint): bigint | undefined => {
  if (isUndefined(sqrtPriceX96)) return undefined

  const extend = BigInt(parseEther("1").toString())
  const denominator = 2n ** 192n

  const numerator = BigInt(sqrtPriceX96) ** 2n * extend
  const divided = numerator / denominator

  return divided
}
