import type { Token } from "src/types"
import { getAddress } from "viem"
import { Address } from "viem"

/**
 * Resolves the USD price of a token within a pool by determining if it's token0 or token1
 * @param targetToken - The token to get the price for
 * @param pool - The pool containing the token pair
 * @param token0Price - The USD price of token0
 * @param token1Price - The USD price of token1
 * @returns The USD price of the target token, or undefined if inputs are invalid
 */
export const resolvePoolTokenPrice = (
  targetToken: Token | null | undefined,
  pool: {
    token0: Token
    token1: Token
    maintenance: bigint
    oracleAddress: Address
  } | null,
  token0Price: number | undefined,
  token1Price: number | undefined,
): number | undefined => {
  if (!targetToken?.address || !pool?.token0.address) {
    return undefined
  }

  return getAddress(targetToken.address) === getAddress(pool.token0.address)
    ? token0Price
    : token1Price
}
