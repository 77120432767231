/** New Global Component that works directly with state. */

import { Modal } from "src/components/ui/Modal"
import { CheckIcon } from "src/components/Icons/CheckIcon"
import { SpinningLoader } from "src/components/Icons/SpinningLoader"
import { getExplorerLink, ExplorerDataType } from "src/utils/getExplorerLink"
import {
  useTransactionActionHandlers,
  useTransactionState,
} from "src/state/transaction/hooks"
import { useCallback } from "react"
import { ErrorIcon } from "src/components/Icons/ErrorIcon"
import { useAccount } from "wagmi"
import { ModalStatusView } from "src/components/ui/ModalStatusView"

interface Props {
  open: boolean
  onClose: () => void
  onCallback?: (() => unknown) | undefined

  children?: React.ReactNode
  buttonText?: string
  onConfirmText?: string
  onPendingText?: string
  onSuccessText?: string
}

export const TransactionModal = ({
  open,
  onClose,
  onCallback,

  children,
  buttonText,
  onConfirmText,
  onPendingText,
  onSuccessText,
}: Props) => {
  const { chain } = useAccount()
  const { pending, txHash, txError } = useTransactionState()
  const { onResetTransactionState } = useTransactionActionHandlers()

  const handleClose = useCallback(() => {
    onResetTransactionState()
    onClose()
  }, [onResetTransactionState, onClose])

  if (open) {
    return (
      <Modal header="" isOpen={open} onClose={pending ? () => null : handleClose}>
        {txError ? (
          <ModalStatusView
            icon={<ErrorIcon />}
            primaryText="Transaction failed"
            secondaryText="Something went wrong"
          />
        ) : pending === "wallet" ? (
          <ModalStatusView
            icon={<SpinningLoader />}
            primaryText={onConfirmText ?? "Confirm transaction"}
            secondaryText="Proceed in your wallet"
          />
        ) : pending === "tx" ? (
          <ModalStatusView
            icon={<SpinningLoader />}
            primaryText={onPendingText ?? "Transaction submitted"}
            secondaryText="Waiting for transaction to finalize"
          />
        ) : pending !== "tx" && txHash ? (
          <ModalStatusView
            icon={<CheckIcon />}
            primaryText={onSuccessText ?? "Transaction Executed"}
            buttonText="Close"
            onClick={handleClose}
            blockExplorerUrl={
              txHash
                ? getExplorerLink(
                    chain?.id as number,
                    txHash,
                    ExplorerDataType.TRANSACTION,
                  )
                : ""
            }
          />
        ) : children ? (
          <div className="flex flex-col space-y-3 px-2 py-3">
            {children}

            <button
              id={`${buttonText}-button`}
              className={`w-full rounded-xl bg-marginalOrange-500 p-2 font-bold text-white hover:animate-pulse hover:opacity-80`}
              onClick={onCallback}
            >
              {buttonText ?? "Submit Transaction"}
            </button>
          </div>
        ) : null}
      </Modal>
    )
  } else {
    return null
  }
}
