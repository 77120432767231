import { type Token } from "src/types"
import { Address, getAddress } from "viem"
import { WRAPPED_GAS_TOKEN_MAP } from "src/constants/tokens"

export const isWrappedGasToken = (token: Token | null | undefined, chainId: number) => {
  if (!token) return false
  return getAddress(token.address) === getAddress(WRAPPED_GAS_TOKEN_MAP[chainId].address)
}

export const isWrappedGasTokenAddress = (token: Address, chainId: number) => {
  if (!token) return false
  return getAddress(token) === getAddress(WRAPPED_GAS_TOKEN_MAP[chainId].address)
}
