import { SwitchIcon } from "src/components/Icons/SwitchIcon"
import { useState } from "react"

const ms = 300

export const SwitchButton = ({
  onClick,
  disabled,
}: {
  onClick: () => void
  disabled: boolean
}) => {
  const [isActive, setIsActive] = useState(false)

  const handleClick = () => {
    if (!disabled) {
      onClick()
      setIsActive(true)
      setTimeout(() => setIsActive(false), ms)
    }
  }

  return (
    <button
      id="switch-token-button"
      className={`relative -my-4 mx-auto flex cursor-pointer items-end justify-center bg-marginalBlack p-1 transition-transform disabled:cursor-not-allowed`}
      disabled={disabled}
      onClick={handleClick}
    >
      <SwitchIcon
        width={28}
        height={28}
        className={`${isActive ? `rotate-180 ease-in-out duration-${ms}` : "ease-out"}`}
      />
    </button>
  )
}
