import { simulateContract, waitForTransactionReceipt, writeContract } from "@wagmi/core"
import { Address } from "viem"
import { parseEther } from "ethers"
import { wagmiConfig } from "src/wagmiConfig"
import marginalV1NonfungiblePositionManagerAbi from "src/constants/abis/MarginalV1NonfungiblePositionManager.json"
import { LockParams } from "src/types"

export const lock = async (
  chainId: number,
  positionManager: Address,
  params: LockParams,
  useNativeCurrency: boolean,
  inputValue: string,
) => {
  const { request } = await simulateContract(wagmiConfig, {
    chainId,
    address: positionManager,
    abi: marginalV1NonfungiblePositionManagerAbi,
    functionName: "lock",
    args: [params],
    value: useNativeCurrency ? parseEther(inputValue) : undefined,
  })

  const transaction = await writeContract(wagmiConfig, request)

  const receipt = await waitForTransactionReceipt(wagmiConfig, { hash: transaction })

  return receipt
}
