import { type PositionRowProps } from "src/pages/positions/components/PositionRow"
import { useNavigateRoutes } from "src/hooks/useNavigateRoutes"
import { convertMaintenanceToLeverage } from "src/utils/conversions/convertMaintenanceToLeverage"
import {
  calculatePnLByPercentage,
  calculateRawPnL,
} from "src/utils/conversions/calculateProfitLoss"
import { formatNumberAmount } from "src/utils/formatNumberAmount"
import { formatBigIntToString } from "src/utils/formatBigIntToString"
import { derivePositionData } from "src/pages/position/managePosition/helpers/derivePositionData"
import { getHealthFactorIndicatorColor } from "src/pages/position/managePosition/helpers/getHealthFactorIndicatorColor"
import { deriveIgniteQuoteData } from "src/pages/position/managePosition/helpers/deriveIgniteQuoteData"
import { TableCell } from "src/pages/pools/components"
import { PositionView } from "src/pages/positions/Positions"
import { useInversePrice } from "src/hooks/useInversePrice"
import { extrapolateTokenPair } from "src/utils/extrapolateTokenPair"
import { useLivePoolPrices } from "src/pages/trade/hooks/useLivePoolPrices"
import { formatUSDCurrency } from "src/utils/formatUSDCurrency"
import { getTokenByAddress } from "src/constants/tokenList"
import { DoubleTokenLogo } from "src/components/TokenBadge"
import { resolvePoolTokenPrice } from "src/utils/pools/resolvePoolTokenPrice"
import { getProfitLossIndicatorColor } from "src/utils/getProfitLossIndicatorColor"

export const PositionRowContent = ({
  view,
  positionKey,
  chainId,
  tokenId,
  positionData,
  pool,
  igniteParams,
  igniteQuote,
  uniswapV3PoolPrice,
  uniswapV3PoolFee,
  indexedPositionData,
}: PositionRowProps) => {
  const { onNavigateToPosition } = useNavigateRoutes()
  const handleNavigateToPosition = () => onNavigateToPosition(positionKey)
  const zeroForOne: boolean = positionData?.zeroForOne
  const token0 = getTokenByAddress(pool?.token0?.address, chainId) ?? pool?.token0
  const token1 = getTokenByAddress(pool?.token1?.address, chainId) ?? pool?.token1
  const leverageCap = convertMaintenanceToLeverage(pool?.maintenance)
  const [marginToken, debtToken] = zeroForOne ? [token1, token0] : [token0, token1]
  const { margin, debt, size, totalSize, healthFactor } = derivePositionData(
    positionData,
    pool,
    marginToken,
    debtToken,
  )
  const healthFactorIndicator = getHealthFactorIndicatorColor(healthFactor)
  const [useInverse] = useInversePrice()
  const { quoteToken } = extrapolateTokenPair(token0, token1, chainId)

  const { token0PriceInUSD, token1PriceInUSD } = useLivePoolPrices({
    chainId,
    selectedPool: pool,
    zeroForOne,
    useInverse,
    quoteToken,
    position: positionData,
  })

  const marginTokenPriceInUSD = resolvePoolTokenPrice(
    marginToken,
    pool,
    token0PriceInUSD,
    token1PriceInUSD,
  )

  const { profitLoss } = deriveIgniteQuoteData(
    igniteQuote,
    marginToken,
    igniteParams,
    "100",
    margin?.raw as bigint,
    debt?.raw as bigint,
    size?.raw as bigint,
    zeroForOne,
    uniswapV3PoolPrice as bigint,
    uniswapV3PoolFee as bigint,
  )

  const profitLossIndicator = getProfitLossIndicatorColor(profitLoss?.isProfit)

  const { marginAmountOut, initialMargin, isClosed, isLiquidated } =
    indexedPositionData || {}

  const marginAmountOut_BI = marginAmountOut ? BigInt(marginAmountOut) : undefined
  const initialMargin_BI = initialMargin ? BigInt(initialMargin) : undefined
  const settledProfitLoss = calculateRawPnL(marginAmountOut_BI, initialMargin_BI)
  const settledProfitLossPercentage = calculatePnLByPercentage(
    settledProfitLoss,
    initialMargin_BI,
  )

  const settledProfitLossIndicator = getProfitLossIndicatorColor(
    settledProfitLossPercentage ? settledProfitLossPercentage > 0 : undefined,
  )

  if (isClosed && view === PositionView.CLOSED) {
    return (
      <tr className="hidden w-full cursor-not-allowed bg-marginalBlack md:table-row">
        <TableCell>{tokenId}</TableCell>
        <TableCell>
          <div className="flex items-center gap-1.5 text-left text-xs">
            <DoubleTokenLogo token0={token0} token1={token1} size={6} />
            <div className="flex flex-col">
              {token0?.symbol} / {token1?.symbol} ∙ {leverageCap}X MAX
              <small className="text-marginalGray-600">
                {token0?.name} ∙ {token1?.name}
              </small>
            </div>
          </div>
        </TableCell>
        <TableCell>{isLiquidated ? "Liquidated" : "Closed"}</TableCell>
        <TableCell>
          {formatNumberAmount(
            formatBigIntToString(marginAmountOut, marginToken?.decimals),
            true,
          )}{" "}
          {marginToken?.symbol}
        </TableCell>
        <TableCell>
          <div className={`flex flex-col ${settledProfitLossIndicator}`}>
            {formatNumberAmount(
              formatBigIntToString(settledProfitLoss, marginToken?.decimals),
              true,
            )}{" "}
            {marginToken?.symbol}
            <small className={settledProfitLossIndicator}>
              ({formatNumberAmount(settledProfitLossPercentage?.toString(), true)}%)
            </small>
          </div>
        </TableCell>
      </tr>
    )
  }

  if (!isClosed && view === PositionView.OPEN) {
    return (
      <tr
        onClick={handleNavigateToPosition}
        className="hidden w-full cursor-pointer bg-marginalBlack hover:bg-marginalGray-950 md:table-row"
      >
        <TableCell>{tokenId}</TableCell>
        <TableCell>
          <div className="flex items-center gap-1.5 text-left text-xs">
            <DoubleTokenLogo token0={token0} token1={token1} size={6} />
            <div className="flex flex-col">
              {token0?.symbol} / {token1?.symbol} ∙ {leverageCap}X MAX
              <small className="text-marginalGray-600">
                {token0?.name} ∙ {token1?.name}
              </small>
            </div>
          </div>
        </TableCell>
        <TableCell>
          {formatNumberAmount(totalSize?.parsed, true)} {marginToken?.symbol}
        </TableCell>
        <TableCell>
          {formatNumberAmount(margin?.parsed, true)} {marginToken?.symbol}
        </TableCell>
        <TableCell>
          <span className={healthFactorIndicator}>{healthFactor?.toFixed(2)}</span>
        </TableCell>
        <TableCell>
          <div className={`flex flex-col ${profitLossIndicator}`}>
            {formatNumberAmount(profitLoss?.parsed, true)} {marginToken?.symbol}
            <small className={profitLossIndicator}>
              ({formatNumberAmount(profitLoss?.percentageFormatted, true)}%)
            </small>
            <small className={profitLossIndicator}>
              {profitLoss.formatted &&
                marginTokenPriceInUSD &&
                formatUSDCurrency(
                  parseFloat(profitLoss.formatted) * marginTokenPriceInUSD,
                )}
            </small>
          </div>
        </TableCell>
      </tr>
    )
  }
}
