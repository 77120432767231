import { useCallback } from "react"
import { useAppDispatch, useAppSelector } from "../hooks"
import { setPending, setTxHash, setTxError, resetTransactionState } from "./reducer"
import { AppState } from ".."

export const useTransactionState = (): AppState["transaction"] => {
  return useAppSelector((state) => state.transaction)
}

export function useTransactionActionHandlers(): {
  onSetPending: (pending: "wallet" | "tx" | null) => void
  onSetTxHash: (hash: string | null) => void
  onSetTxError: (error: any) => void
  onResetTransactionState: () => void
} {
  const dispatch = useAppDispatch()

  const onSetPending = useCallback(
    (pending: "wallet" | "tx" | null) => {
      dispatch(setPending(pending))
    },
    [dispatch],
  )

  const onSetTxHash = useCallback(
    (hash: string | null) => {
      dispatch(setTxHash(hash))
    },
    [dispatch],
  )

  const onSetTxError = useCallback(
    (error: any) => {
      dispatch(setTxError(error))
    },
    [dispatch],
  )

  const onResetTransactionState = useCallback(() => {
    dispatch(resetTransactionState())
  }, [dispatch])

  return {
    onSetPending,
    onSetTxHash,
    onSetTxError,
    onResetTransactionState,
  }
}
