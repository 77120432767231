import { RemoveLiquidityParams } from "src/types"
import { simulateContract, waitForTransactionReceipt, writeContract } from "@wagmi/core"
import { wagmiConfig } from "src/wagmiConfig"
import { V1_ROUTER_ADDRESS } from "src/constants/addresses"
import marginalV1RouterAbi from "src/constants/abis/MarginalV1Router.json"

export const removeLiquidity = async (
  removeLiquidityParams: RemoveLiquidityParams,
  chainId: number,
) => {
  const { request } = await simulateContract(wagmiConfig, {
    chainId,
    address: V1_ROUTER_ADDRESS[chainId],
    abi: marginalV1RouterAbi,
    functionName: "removeLiquidity",
    args: [removeLiquidityParams],
  })

  const hash = await writeContract(wagmiConfig, request)

  const receipt = await waitForTransactionReceipt(wagmiConfig, { hash })

  return receipt
}
