import { useCallback, useEffect, useState } from "react"
import { type Token } from "src/types"
import { Modal } from "src/components/ui/Modal"
import { TokenSelector } from "src/components/ui/TokenSelector"
import { SearchInput } from "src/components/ui/SearchInput"

export interface TokenSelectorModalProps {
  isOpen: boolean
  selectedToken: Token | null
  tokenOptions: Token[]
  onSelect: (token: Token) => void
  onClose: () => void
}

export const TokenSelectorModal = ({
  isOpen,
  selectedToken,
  tokenOptions,
  onSelect,
  onClose,
}: TokenSelectorModalProps) => {
  const [filteredTokens, setFilteredTokens] = useState<Token[]>(tokenOptions)
  const [lastSearchQuery, setLastSearchQuery] = useState("")

  const handleFilteredTokens = useCallback((tokens: Token[]) => {
    setFilteredTokens(tokens)
  }, [])

  // Only reset filtered tokens when modal opens or when no search is active
  useEffect(() => {
    if (isOpen && !lastSearchQuery) {
      setFilteredTokens(tokenOptions)
    }
  }, [isOpen, tokenOptions, lastSearchQuery])

  // Handle modal close
  const handleClose = useCallback(() => {
    setLastSearchQuery("")
    onClose()
  }, [onClose])

  return (
    <Modal header="Select Token" isOpen={isOpen} onClose={handleClose}>
      <TokenSearch
        tokenOptions={tokenOptions}
        onFilteredTokens={handleFilteredTokens}
        onSearchQueryChange={setLastSearchQuery}
      />
      <TokenSelector
        tokenOptions={filteredTokens}
        selectedToken={selectedToken}
        isOpen={isOpen}
        onSelect={onSelect}
        onClose={handleClose}
      />
    </Modal>
  )
}

const TokenSearch = ({
  tokenOptions,
  onFilteredTokens,
  onSearchQueryChange,
}: {
  tokenOptions: Token[]
  onFilteredTokens: (tokens: Token[]) => void
  onSearchQueryChange: (query: string) => void
}) => {
  const [searchQuery, setSearchQuery] = useState("")

  const filterTokens = useCallback(
    (query: string) => {
      if (!query) {
        onFilteredTokens(tokenOptions)
        return
      }

      const lowercaseQuery = query.toLowerCase()
      const filtered = tokenOptions.filter(
        (token) =>
          token.symbol.toLowerCase().includes(lowercaseQuery) ||
          token.name.toLowerCase().includes(lowercaseQuery) ||
          token.address.toLowerCase().includes(lowercaseQuery),
      )
      onFilteredTokens(filtered)
    },
    [tokenOptions, onFilteredTokens],
  )

  const onSearch = useCallback(
    (value: string) => {
      const trimmedValue = value.trim()
      setSearchQuery(trimmedValue)
      onSearchQueryChange(trimmedValue)
      filterTokens(trimmedValue)
    },
    [filterTokens, onSearchQueryChange],
  )

  return (
    <SearchInput
      searchStr={searchQuery}
      onSearch={onSearch}
      placeholder="Search tokens"
      disabled={false}
    />
  )
}
