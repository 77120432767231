import { formatBigIntToString } from "src/utils/formatBigIntToString"

const PRECISION_DECIMALS = 18n
const ONE_WITH_DECIMALS = 10n ** PRECISION_DECIMALS

/**
 * Calculates the health factor of a position using native BigInt
 * Health factor is margin divided by safe margin minimum
 * @param margin - The current margin amount
 * @param safeMarginMinimum - The minimum safe margin amount
 * @returns The health factor as a number, null if invalid inputs
 */
export function calculatePositionHealthFactor(
  margin: bigint | string | undefined | null,
  safeMarginMinimum: bigint | string | undefined | null,
): number | null {
  if (!margin || !safeMarginMinimum) {
    return null
  }

  try {
    const marginBigInt = typeof margin === "string" ? BigInt(margin) : margin
    const safeMarginBigInt =
      typeof safeMarginMinimum === "string"
        ? BigInt(safeMarginMinimum)
        : safeMarginMinimum

    if (safeMarginBigInt === 0n) {
      return 0
    }

    // Multiply by 10^18 first to maintain precision in division
    const healthFactorBigInt = (marginBigInt * ONE_WITH_DECIMALS) / safeMarginBigInt

    const healthFactorString = formatBigIntToString(
      healthFactorBigInt,
      Number(PRECISION_DECIMALS),
    )
    if (!healthFactorString) {
      return null
    }

    return parseFloat(healthFactorString)
  } catch (error) {
    console.error("Error calculating position health factor:", error)
    return null
  }
}
