import { PoolData, Token } from "src/types"
import { isAddress } from "viem"

export const validateAddLiquidityParams = (
  pool: PoolData | null,
  token0: Token | null,
  token1: Token | null,
  input0: string | undefined,
  input1: string | undefined,
  recipient: string | undefined,
): boolean => {
  if (!pool || !token0 || !token1 || !input0 || !input1 || !recipient) {
    return false
  }
  if (!pool?.maintenance) {
    return false
  }
  if (!pool?.oracleAddress) {
    return false
  }
  if (!isAddress(pool?.oracleAddress)) {
    return false
  }
  if (!pool?.token0?.address || !pool?.token1?.address) {
    return false
  }
  if (!isAddress(pool?.token0?.address) || !isAddress(pool?.token1?.address)) {
    return false
  }
  if (!token0?.address || !token1?.address) {
    return false
  }
  if (!isAddress(token0?.address) || !isAddress(token1?.address)) {
    return false
  }
  if (!isAddress(recipient)) {
    return false
  }
  // Validate input values are numeric and greater than 0
  if (isNaN(Number(input0)) || Number(input0) <= 0) {
    return false
  }
  if (isNaN(Number(input1)) || Number(input1) <= 0) {
    return false
  }
  return true
}
