import { useEffect, useState } from "react"
import { useMobileView } from "src/hooks/useMobileView"
import { LaunchContainer } from "src/pages/lbp/Launch"
import {
  MobileTableCell,
  TableCell,
  TableHeaderCell,
  TableRow,
} from "src/components/Launch/TableComponents"
import { useAccount } from "wagmi"
import { TokenLogo } from "../TokenBadge"
import TablePagination from "../TablePagination/TablePagination"
import { CircularProgressBar } from "src/pages/pools/components"
import { Token } from "src/types"
import { shortenAddress } from "src/utils/shortenAddress"
import { LaunchProjectHoldersProps } from "src/hooks/useLBData"

interface LaunchProjectHoldersTableProps {
  holders: Array<LaunchProjectHoldersProps>
  launchToken: Token
}

const LaunchProjectHoldersTable = ({
  holders,
  launchToken,
}: LaunchProjectHoldersTableProps) => {
  const { address } = useAccount()
  const { isMobileView } = useMobileView()
  const isLoading = false

  const [currentPage, setCurrentPage] = useState<number>(1)
  const [currentPageHolders, setCurrentPageHolders] = useState<any[] | null>(null)

  useEffect(() => {
    if (holders) {
      const reversedPageHolders = holders.slice(0).reverse()
      setCurrentPageHolders(
        reversedPageHolders.slice((currentPage - 1) * 10, (currentPage - 1) * 10 + 10),
      )
    }
  }, [holders, address, currentPage])

  return (
    <>
      <LaunchContainer>
        <div
          id="launches-header"
          className="flex w-full flex-col items-start space-y-4 rounded-t-3xl bg-marginalGray-900 px-4 py-5"
        >
          <div className="flex items-center gap-2">
            <span className="text-xl text-[#CACACA]">
              Holders
            </span>

            <div className="rounded-md bg-marginalGray-800 px-1 py-0.5 text-sm text-marginalGray-400">
              {holders.length}
            </div>
          </div>
        </div>

        <table
          className={
            isMobileView
              ? "w-full border-separate border-spacing-0 rounded-b-3xl bg-marginalGray-900 p-2 text-marginalGray-200"
              : "w-full table-auto border-separate border-spacing-0 overflow-hidden rounded-b-3xl bg-marginalGray-900 p-2"
          }
        >
          <thead>
            <>
              {isMobileView || !address ? (
                <></>
              ) : (
                <TableRow>
                  <TableHeaderCell align="left">Rank</TableHeaderCell>
                  <TableHeaderCell align="left">Holder</TableHeaderCell>
                  <TableHeaderCell>Tokens</TableHeaderCell>
                  <TableHeaderCell>Allocation</TableHeaderCell>
                </TableRow>
              )}
            </>
          </thead>
          <tbody className="">
            {isLoading ? (
              <></>
            ) : holders && currentPageHolders && address ? (
              currentPageHolders.map((holder: any, index: number) => {
                return (
                  <LaunchProjectHoldersRow
                    launchToken={launchToken}
                    holder={holder}
                    isMobile={isMobileView}
                    isOnlyPosition={currentPageHolders.length === 1}
                    isFirst={currentPageHolders[0] === currentPageHolders[index]}
                    isLast={
                      currentPageHolders[currentPageHolders.length - 1] ===
                      currentPageHolders[index]
                    }
                    // onNavigate={() => onNavigateToLaunchedProject()}
                  />
                )
              })
            ) : null}
          </tbody>
        </table>
      </LaunchContainer>
      {holders && (
        <TablePagination
          totalCount={holders?.length}
          pageSize={10}
          currentPage={currentPage}
          onPageChange={(page) => setCurrentPage(page)}
        />
      )}
    </>
  )
}

export default LaunchProjectHoldersTable

const LaunchProjectHoldersRow = ({
  holder,
  isMobile,
  isOnlyPosition,
  isFirst,
  isLast,
  launchToken,
}: {
  holder: LaunchProjectHoldersProps
  isMobile: boolean
  isOnlyPosition: boolean
  isFirst: boolean
  isLast: boolean
  launchToken: Token
}) => {
  return (
    <>
      {isMobile ? (
        <>
          <tr>
            <div
              className={`relative h-52 space-y-4 border-b bg-marginalGray-950 p-4 ${
                isOnlyPosition
                  ? "rounded-xl border-b-transparent"
                  : isFirst
                    ? "rounded-t-xl border-b-marginalGray-800"
                    : isLast
                      ? "rounded-b-xl border-b-transparent"
                      : "border-b-marginalGray-800"
              } `}
            >
              <div className="flex items-center justify-start">
                <div className="flex items-center space-x-2 text-xs">
                  <TokenLogo
                    symbol={launchToken?.symbol}
                    imgUrl={launchToken?.imgUrl}
                    size={4}
                  />
                  <MobileTableCell label="Rank">{holder.rank}x</MobileTableCell>
                </div>
              </div>

              <div className="flex">
                <MobileTableCell label="Holder">
                  {shortenAddress(holder.holder)}
                </MobileTableCell>
                <MobileTableCell label="Tokens">
                  <div className="flex items-center space-x-1">
                    <TokenLogo
                      symbol={launchToken?.symbol}
                      imgUrl={launchToken?.imgUrl}
                      size={4}
                    />
                    <span className="text-xs text-marginalGray-200">
                      {holder.tokens}
                    </span>
                  </div>
                </MobileTableCell>
              </div>

              <div className="flex">
                <MobileTableCell label="Allocation">
                  {holder.allocation ? (
                    <div className="flex items-center justify-start space-x-2">
                      <CircularProgressBar
                        sqSize={16}
                        strokeWidth={2}
                        percentage={parseFloat(holder.allocation)}
                        className="stroke-white"
                      />
                      <span className="text-xs text-marginalGray-200">
                        {holder.allocation}
                      </span>
                    </div>
                  ) : (
                    <div>-</div>
                  )}
                </MobileTableCell>
              </div>
            </div>
          </tr>
        </>
      ) : (
        <tr
          // onClick={handleNavigateToPool}
          className={`w-fit border-none bg-marginalBlack ${
            isOnlyPosition
              ? "[&>td]:border-none first:[&>td]:rounded-bl-2xl first:[&>td]:rounded-tl-2xl last:[&>td]:rounded-br-2xl last:[&>td]:rounded-tr-2xl"
              : isFirst
                ? "first:[&>td]:rounded-tl-2xl last:[&>td]:rounded-tr-2xl"
                : isLast
                  ? "[&>td]:border-none first:[&>td]:rounded-bl-2xl last:[&>td]:rounded-br-2xl"
                  : ""
          }`}
        >
          <TableCell>{holder.rank}</TableCell>

          <TableCell>
            <div className="text-left text-sm text-marginalGray-200">
              {shortenAddress(holder.holder, 8)}
            </div>
          </TableCell>

          <TableCell>
            <div className="flex items-center justify-end space-x-3">
              <TokenLogo
                symbol={launchToken?.symbol}
                imgUrl={launchToken?.imgUrl}
                size={4}
              />
              <span className="text-sm text-marginalGray-200">
                {holder.tokens}
              </span>
            </div>
          </TableCell>

          <TableCell>
            <div className="flex items-center justify-end space-x-3">
              <CircularProgressBar
                sqSize={16}
                strokeWidth={2}
                percentage={parseFloat(holder.allocation)}
                className="stroke-white"
              />
              <span className="text-sm text-marginalGray-200">
                {holder.allocation}
              </span>
            </div>
          </TableCell>
        </tr>
      )}
    </>
  )
}
