import { readContract } from "@wagmi/core"
import { Address } from "viem"
import { wagmiConfig } from "src/wagmiConfig"
import marginalV1QuoterAbi from "src/constants/abis/MarginalV1Quoter.json"
import { AddLiquidityQuote, AddLiquidityParams } from "src/types"

export async function getAddLiquidityQuote(
  chainId: number,
  quoterAddress: Address,
  params: AddLiquidityParams,
): Promise<AddLiquidityQuote | null> {
  try {
    const data = await readContract(wagmiConfig, {
      chainId,
      address: quoterAddress,
      abi: marginalV1QuoterAbi,
      functionName: "quoteAddLiquidity",
      args: [params],
    })

    if (!Array.isArray(data)) {
      return null
    }

    return {
      shares: data[0],
      amount0: data[1],
      amount1: data[2],
      liquidityAfter: data[3],
    }
  } catch (error) {
    console.error("Error getting add liquidity quote:", error)
    return null
  }
}
