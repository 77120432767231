import { Address, getAddress } from "viem"
import { formatStringToBigInt } from "src/utils/formatStringToBigInt"
import { FreeParams, Token } from "src/types"

const MAX_DEADLINE =
  115792089237316195423570985008687907853269984665640564039457584007913129639935n

export const constructFreeParams = (
  token0: Token,
  token1: Token,
  maintenance: bigint,
  oracle: Address,
  tokenId: string,
  marginOut: string,
  marginToken: Token,
  recipient: Address,
  currentBlockTimestamp: bigint | undefined,
  transactionDeadline: string,
): FreeParams => {
  return {
    token0: getAddress(token0.address),
    token1: getAddress(token1.address),
    maintenance,
    oracle: getAddress(oracle),
    tokenId,
    marginOut: formatStringToBigInt(marginOut, marginToken.decimals) ?? 0n,
    recipient,
    deadline: currentBlockTimestamp
      ? currentBlockTimestamp + BigInt(transactionDeadline) * BigInt("60")
      : MAX_DEADLINE,
  }
}
