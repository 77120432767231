import { NavLink } from "react-router-dom"
import { LANDING_URL } from "src/constants/links"
import marginalLogo from "src/assets/logos/marginal.svg"

export const BrandLogo = () => {
  return (
    <NavLink to={LANDING_URL} target="_blank" className="flex w-fit space-x-2">
      <img src={marginalLogo} alt="Marginal logo" className="mt-0.5" />
      <h1 className="text-2xl font-bold text-white">Marginal</h1>
    </NavLink>
  )
}
