interface SearchInputProps {
  searchStr: string
  onSearch: (str: string) => void
  placeholder: string
  disabled?: boolean
}

export const SearchInput = ({
  searchStr,
  onSearch,
  placeholder,
  disabled = false,
}: SearchInputProps) => {
  return (
    <input
      placeholder={placeholder}
      onChange={(e) => onSearch(e.target.value)}
      value={searchStr}
      disabled={disabled}
      tabIndex={0}
      className={`rounded-xl border border-transparent bg-transparent placeholder-white placeholder-opacity-40 outline-none ring-0 focus:border-transparent focus:outline-none focus:ring-0 ${disabled && "opacity-30"}`}
    />
  )
}
