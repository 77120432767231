import { simulateContract, waitForTransactionReceipt, writeContract } from "@wagmi/core"
import { Address, parseUnits } from "viem"
import { wagmiConfig } from "src/wagmiConfig"
import marginalV1PoolInitializerAbi from "src/constants/abis/MarginalV1PoolInitializer.json"
import { Token, UniswapPool } from "src/types"
import { toAmountMax, toAmountMin } from "src/utils/slippage"
import { isGasToken } from "src/utils/isGasToken"
export interface CreatePoolParams {
  token0: Token
  token1: Token
  maintenance: number
  uniswapPool: UniswapPool
  recipient: Address
  amount0: string
  amount1: string
  maxSlippage: string
  transactionDeadline: string
  currentBlockTimestamp: bigint
}

export const createAndInitializePoolIfNecessary = async (
  params: CreatePoolParams,
  poolInitializerAddress: Address,
  chainId: number,
) => {
  const value = isGasToken(params.token0)
    ? toAmountMax(params.amount0, params.token0.decimals, params.maxSlippage)
    : isGasToken(params.token1)
      ? toAmountMax(params.amount1, params.token1.decimals, params.maxSlippage)
      : undefined

  const { request } = await simulateContract(wagmiConfig, {
    chainId,
    address: poolInitializerAddress,
    abi: marginalV1PoolInitializerAbi,
    functionName: "createAndInitializePoolIfNecessary",
    value,
    args: [
      {
        token0: params.token0.address as Address,
        token1: params.token1.address as Address,
        maintenance: params.maintenance,
        uniswapV3Fee: Number(params.uniswapPool.fee),
        recipient: params.recipient,
        sqrtPriceX96: params.uniswapPool.sqrtPriceStartX96 ?? 0n,
        sqrtPriceLimitX96: 0n,
        liquidityBurned: BigInt(1e8),
        amount0BurnedMax: toAmountMax(
          params.amount0,
          params.token0.decimals,
          params.maxSlippage,
        ),
        amount1BurnedMax: toAmountMin(
          params.amount1,
          params.token1.decimals,
          params.maxSlippage,
        ),
        amount0Desired: parseUnits(params.amount0, params.token0.decimals),
        amount1Desired: parseUnits(params.amount1, params.token1.decimals),
        amount0Min: toAmountMin(
          params.amount0,
          params.token0.decimals,
          params.maxSlippage,
        ),
        amount1Min: toAmountMin(
          params.amount1,
          params.token1.decimals,
          params.maxSlippage,
        ),
        deadline:
          params.currentBlockTimestamp +
          BigInt(params.transactionDeadline) * BigInt("60"),
      },
    ],
  })

  const hash = await writeContract(wagmiConfig, request)

  const receipt = await waitForTransactionReceipt(wagmiConfig, { hash })

  return receipt
}
