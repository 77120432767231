import { readContract } from "@wagmi/core"
import { Address } from "viem"
import { wagmiConfig } from "src/wagmiConfig"
import marginalV1QuoterAbi from "src/constants/abis/MarginalV1Quoter.json"
import { ExactInputSingleParams, ExactInputSingleQuote } from "src/types"

export async function getExactInputSingleQuote(
  chainId: number,
  quoterAddress: Address,
  params: ExactInputSingleParams,
): Promise<ExactInputSingleQuote | null> {
  try {
    const data = await readContract(wagmiConfig, {
      chainId,
      address: quoterAddress,
      abi: marginalV1QuoterAbi,
      functionName: "quoteExactInputSingle",
      args: [params],
    })

    if (!Array.isArray(data)) {
      return null
    }

    return {
      amountOut: data[0],
      liquidityAfter: data[1],
      sqrtPriceX96After: data[2],
    }
  } catch (error) {
    console.error("Error getting swap quote:", error)
    return null
  }
}
