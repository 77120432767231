import { Address } from "viem"
import { useNavigateRoutes } from "src/hooks/useNavigateRoutes"
import { useIncentivizedPoolYieldPercentageRate } from "src/pages/pools/hooks/useIncentivizedPoolYieldPercentageRate"
import { calculatePercentageOfTotal } from "src/utils/conversions/calculatePercentageOfTotal"
import { convertMaintenanceToLeverage } from "src/utils/conversions/convertMaintenanceToLeverage"
import { formatNumberAmount } from "src/utils/formatNumberAmount"
import { PoolRowProps } from "src/pages/pools/components/PoolRow"
import { TableCell } from "src/pages/pools/components/TableComponents"
import { CircularProgressBar } from "src/pages/pools/components/CircularProgressBar"
import { getTokenByAddress } from "src/constants/tokenList"
import { DoubleTokenLogo, TokenBadge } from "src/components/TokenBadge"
import { type Token } from "src/types"

export const PoolRowContent = ({
  chainId,
  pool,
  liquidityLocked,
  userPoolBalance,
  poolTotalSupply,
  poolState,
  poolTokenBalances,
}: PoolRowProps) => {
  const { onNavigateToPool } = useNavigateRoutes()
  const handleNavigateToPool = () => onNavigateToPool(pool.poolAddress)
  const token0 = getTokenByAddress(pool?.token0?.address, chainId) ?? pool?.token0
  const token1 = getTokenByAddress(pool?.token1?.address, chainId) ?? pool?.token1
  const maxLeverage = convertMaintenanceToLeverage(pool.maintenance)
  const userSharePercentageOfTotal = calculatePercentageOfTotal({
    total: parseFloat(poolTotalSupply?.parsedTotalSupply ?? "0"),
    partial: parseFloat(userPoolBalance?.parsedBalance ?? "0"),
  })
  const poolUtilization = calculatePercentageOfTotal({
    total: parseFloat(poolTotalSupply?.parsedTotalSupply ?? "0"),
    partial: parseFloat(liquidityLocked?.parsedLockedLiquidity ?? "0"),
  })
  const incentivizedPoolYieldPercentageRate = useIncentivizedPoolYieldPercentageRate(
    chainId,
    pool,
    pool.poolAddress as Address,
    pool.poolAddress as Address, // TODO: Update for reward token when available
  )

  return (
    <tr
      onClick={handleNavigateToPool}
      className="hidden w-full cursor-pointer bg-marginalBlack hover:opacity-80 md:table-row"
    >
      <TableCell>
        <div className="flex items-center gap-1.5">
          <DoubleTokenLogo token0={token0 as Token} token1={token1 as Token} size={6} />
          <span>
            {token0?.symbol} / {token1?.symbol}
          </span>
        </div>
      </TableCell>
      <TableCell>{maxLeverage}x</TableCell>
      <TableCell>
        {formatNumberAmount(incentivizedPoolYieldPercentageRate?.parsedYieldRate) ?? "-"}%
      </TableCell>
      <TableCell>
        <div className="flex flex-col items-end space-y-1">
          {poolTokenBalances?.map((balance, index) => (
            <div key={index} className="flex items-center gap-1.5">
              {formatNumberAmount(balance?.parsedBalance ?? "-")}
              <span className="flex items-center gap-1.5">
                <TokenBadge token={index === 0 ? token0 : token1} size={4} />
              </span>
            </div>
          ))}
        </div>
      </TableCell>
      <TableCell>
        <div className="flex items-center justify-end gap-1.5">
          <CircularProgressBar
            sqSize={16}
            strokeWidth={2}
            percentage={poolUtilization ?? 0}
          />
          {formatNumberAmount(poolUtilization?.toString() ?? "0")}%
        </div>
      </TableCell>
      <TableCell>{formatNumberAmount(userSharePercentageOfTotal?.toString())}%</TableCell>
    </tr>
  )
}
