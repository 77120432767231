import { useState, type ReactNode } from "react"

const Collapsible = ({ title, children }: { title: string; children: ReactNode }) => {
  const [isOpen, setIsOpen] = useState(true)

  return (
    <div className="overflow-hidden border-b border-marginalGray-800 bg-marginalGray-900">
      <button
        onClick={() => setIsOpen((p) => !p)}
        className="flex h-14 w-full cursor-pointer items-center justify-between px-4 py-2 text-left text-white"
      >
        <span
          className={`${isOpen ? "text-marginalGray-200" : "text-marginalGray-600"} whitespace-nowrap text-lg leading-5`}
        >
          {title}
        </span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="10"
          viewBox="0 0 16 10"
          fill="none"
          className={`transform ${isOpen ? "rotate-180" : "rotate-0"} transition-transform duration-200`}
        >
          <path
            d="M7.99995 6.22721L13.8333 0.5L15.5 2.13635L7.99995 9.5L0.5 2.13635L2.16666 0.5L7.99995 6.22721Z"
            fill={isOpen ? "#BEBEBE" : "#7A7A7A"}
          />
        </svg>
      </button>
      <div
        className={`overflow-hidden transition-max-height duration-300 ease-in-out ${
          isOpen ? "max-h-screen" : "max-h-0"
        }`}
      >
        <div className="mb-4 bg-[#1c1c1c] bg-marginalGray-900 px-4 py-2 text-sm text-gray-300">
          {children}
        </div>
      </div>
    </div>
  )
}

export default Collapsible
