import { formatBigIntToString } from "src/utils/formatBigIntToString"
import { formatNumberAmount } from "src/utils/formatNumberAmount"
import { getIgniteParamsWithSlippage } from "./getIgniteParamsWithSlippage"
import { calculateIgniteSlippage } from "./getIgniteParamsWithSlippage"
import { IgniteQuote } from "src/types"
import { Token } from "src/types"
import {
  calculatePnLByPercentage,
  calculateRawPnL,
} from "src/utils/conversions/calculateProfitLoss"

interface DerivedIgniteQuoteData {
  profitLoss: {
    raw: bigint | undefined
    parsed: string | undefined
    formatted: string | null | undefined
    percentage: number | undefined
    percentageFormatted: string | null | undefined
    isProfit: boolean | undefined
  }
  impact: {
    raw: string | undefined
    formatted: string | null | undefined
  }
  minReceived: {
    raw: bigint | undefined
    parsed: string | undefined
    formatted: string | null | undefined
  }
  igniteParamsWithSlippage: any
  error: string | undefined
}

/**
 * Derives quote data for an Ignite position including profit/loss calculations,
 * impact assessments, and minimum received amounts
 */
export const deriveIgniteQuoteData = (
  igniteQuote: IgniteQuote | null,
  marginToken: Token | null | undefined,
  igniteParams: any,
  maxSlippage: string,
  margin: bigint,
  debt: bigint,
  size: bigint,
  zeroForOne: boolean,
  uniswapV3PoolPrice: bigint,
  uniswapV3PoolFee: bigint,
): DerivedIgniteQuoteData => {
  // Process profit/loss data
  const profitLossRaw = calculateRawPnL(igniteQuote?.amountOut, margin)
  const profitLossPercentage = calculatePnLByPercentage(profitLossRaw, margin)
  const profitLossParsed = formatBigIntToString(profitLossRaw, marginToken?.decimals)

  const profitLoss = {
    raw: profitLossRaw,
    parsed: profitLossParsed,
    formatted: formatNumberAmount(profitLossParsed, true),
    percentage: profitLossPercentage,
    percentageFormatted: formatNumberAmount(profitLossPercentage?.toString(), true),
    isProfit: profitLossPercentage ? profitLossPercentage > 0 : undefined,
  }

  // Process slippage impact data
  const slippageImpact = calculateIgniteSlippage(
    margin,
    debt,
    size,
    zeroForOne,
    uniswapV3PoolPrice,
    uniswapV3PoolFee,
    igniteQuote?.amountOut,
  )

  const priceImpactFormatted = formatNumberAmount(slippageImpact, true)
  const impact = {
    raw: slippageImpact,
    formatted: priceImpactFormatted,
  }

  // Process minimum received data
  const derivedIgniteParamsWithSlippage = getIgniteParamsWithSlippage(
    igniteParams,
    maxSlippage,
    margin,
    debt,
    size,
    zeroForOne,
    uniswapV3PoolPrice,
    uniswapV3PoolFee,
    igniteQuote?.amountOut,
  )

  const minReceived = {
    raw: derivedIgniteParamsWithSlippage?.amountOutMinimum,
    parsed: formatBigIntToString(
      derivedIgniteParamsWithSlippage?.amountOutMinimum,
      marginToken?.decimals,
    ),
    formatted: formatNumberAmount(
      formatBigIntToString(
        derivedIgniteParamsWithSlippage?.amountOutMinimum,
        marginToken?.decimals,
      ),
      true,
    ),
  }

  // Check for errors
  const error =
    priceImpactFormatted && maxSlippage
      ? parseFloat(priceImpactFormatted.toString()) > parseFloat(maxSlippage)
        ? "Slippage exceeds tolerance"
        : undefined
      : undefined

  return {
    profitLoss,
    impact,
    minReceived,
    igniteParamsWithSlippage: derivedIgniteParamsWithSlippage,
    error,
  }
}
