import ethImg from "src/assets/chains/ethereum.svg"
import baseImg from "src/assets/chains/base.svg"

import baseExplorerImg from "src/assets/explorer/basescan.svg"
import ethExplorerImg from "src/assets/explorer/etherscan.svg"

import { zeroAddress } from "viem"

export type Chain = {
  id: number
  chainSymbol: string
  name: string
  chainImg: any
  rpcUrls: { primary: string; fallback: string }
  explorerUrl: string
  explorerName: string
  explorerImg: any
  blockTime: number
  nativeCurrency: {
    name: string
    symbol: string
    decimals: number
    address: string
    icon: any
  }
  priorityRank: number
}

export const ETH: Chain = {
  id: 1,
  chainSymbol: "ETH",
  name: "Ethereum",
  chainImg: ethImg,
  rpcUrls: {
    primary: "https://eth-mainnet.g.alchemy.com/v2/rJ3f0IWjZbpgEwnzrRS6yYO3WNH0jGle",
    fallback: "https://eth.llamarpc.com",
  },
  explorerUrl: "https://etherscan.com",
  explorerName: "Etherscan",
  explorerImg: ethExplorerImg,
  blockTime: 12000,
  nativeCurrency: {
    name: "Ethereum",
    symbol: "ETH",
    decimals: 18,
    address: zeroAddress,
    icon: ethImg,
  },
  priorityRank: 1,
}

export const BASE: Chain = {
  id: 8453,
  chainSymbol: "BASE",
  name: "Base",
  chainImg: baseImg,
  rpcUrls: {
    primary: "https://base-mainnet.g.alchemy.com/v2/_YKy-Vm3LsknT8JKSa2ZTSmKu9Qp01Vd",
    fallback: "https://developer-access-mainnet.base.org",
  },
  explorerUrl: "https://basescan.org",
  explorerName: "BaseScan",
  explorerImg: baseExplorerImg,
  blockTime: 3000,
  nativeCurrency: {
    name: "Ethereum",
    symbol: "ETH",
    decimals: 18,
    address: zeroAddress,
    icon: baseImg,
  },
  priorityRank: 2,
}

export const SEPOLIA: Chain = {
  id: 11155111,
  chainSymbol: "Sepolia",
  name: "Sepolia",
  chainImg: ethImg,
  rpcUrls: {
    primary: "https://sepolia.drpc.org",
    fallback: "https://ethereum-sepolia-rpc.publicnode.com",
  },
  explorerUrl: "https://sepolia.etherscan.io",
  explorerName: "Etherscan",
  explorerImg: ethExplorerImg,
  blockTime: 12000,
  nativeCurrency: {
    name: "Sepolia Ether",
    symbol: "ETH",
    decimals: 18,
    address: zeroAddress,
    icon: ethImg,
  },
  priorityRank: 3,
}

export const BARTIO_TESTNET: Chain = {
  id: 80084,
  chainSymbol: "bArtio",
  name: "Berachain bArtio",
  chainImg: ethImg,
  rpcUrls: {
    primary: "https://bartio.rpc.berachain.com",
    fallback: "https://bera-testnet.nodeinfra.com",
  },
  explorerUrl: "https://bartio.beratrail.io",
  explorerName: "Beratrail",
  explorerImg: ethExplorerImg,
  blockTime: 2000,
  nativeCurrency: {
    name: "BERA Token",
    symbol: "BERA",
    decimals: 18,
    address: zeroAddress,
    icon: ethImg,
  },
  priorityRank: 4,
}
