import { formatBigIntToString } from "src/utils/formatBigIntToString"
import { useReadContracts } from "wagmi"
import { Address } from "viem"
import MultiRewardsAbi from "../constants/abis/MultiRewards.json"

export interface RewardBalance {
  tokenAddress: Address
  balance: bigint | undefined
  formattedBalance: string | undefined
}

interface UserRewards {
  rewards: RewardBalance[]
  isLoading: boolean
  error: Error | null
}

/**
 * Creates a contract read configuration for the MultiRewards earned function
 * @param stakePoolAddress - Address of the stake pool contract
 * @param userAddress - Address of the user to check rewards for
 * @param rewardTokenAddress - Address of the reward token
 * @returns Contract read configuration
 */
const createContractRead = (
  stakePoolAddress: Address,
  userAddress: Address,
  rewardTokenAddress: Address,
) => ({
  address: stakePoolAddress,
  abi: MultiRewardsAbi as any,
  functionName: "earned",
  args: [userAddress, rewardTokenAddress],
})

/**
 * Hook to fetch user reward balances for multiple reward tokens
 * @param stakePoolAddress - Address of the stake pool contract
 * @param userAddress - Address of the user to check rewards for
 * @param rewardTokens - Array of reward token addresses
 * @returns Object containing reward balances and loading state
 */
export const useUserRewardsBalance = (
  stakePoolAddress: Address | undefined,
  userAddress: Address | undefined,
  rewardTokens: Address[],
): UserRewards => {
  // Create contract read configurations for each reward token
  const contracts = rewardTokens.map((tokenAddress) =>
    createContractRead(stakePoolAddress as Address, userAddress as Address, tokenAddress),
  )

  const isEnabled = contracts.length > 0 && !!stakePoolAddress && !!userAddress

  // Fetch balances for all reward tokens
  const { data, isLoading, error } = useReadContracts({
    contracts,
    query: {
      enabled: isEnabled,
    },
  })

  // Process and format the results
  const rewards: RewardBalance[] = rewardTokens.map((tokenAddress, index) => {
    const rawBalance = data?.[index]?.result as bigint | undefined

    return {
      tokenAddress,
      balance: rawBalance,
      formattedBalance: formatBigIntToString(rawBalance || 0n, 18),
    }
  })

  return {
    rewards,
    isLoading,
    error: error as Error | null,
  }
}
