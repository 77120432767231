import { ChevronDownIcon } from "@heroicons/react/24/outline"

export const RotatingActiveArrow = ({ isActive }: { isActive: boolean }) => {
  return (
    <ChevronDownIcon
      width={16}
      height={16}
      className={`transform text-marginalGray-200 transition-transform duration-200 ease-in-out ${isActive ? "rotate-180" : ""}`}
    />
  )
}
