import { parseEther } from "ethers"
import { isUndefined } from "lodash"
import { formatBigIntToString } from "src/utils/formatBigIntToString"

export const convertX96FundingRate = (fundingRatioX96?: bigint): number | null => {
  if (isUndefined(fundingRatioX96)) return null

  const extend = BigInt(parseEther("1").toString())
  const denominator = 2n ** 96n

  const numerator = BigInt(fundingRatioX96) * extend
  const divided = (numerator / denominator).toString()

  const parsed = formatBigIntToString(BigInt(divided), 18)

  return parsed ? (parseFloat(parsed) - 1) * 100 : null
}
