import { useAccount, useReadContracts } from "wagmi"
import { Address, zeroAddress, type Abi } from "viem"
import { useMemo } from "react"
import { UNISWAP_V3_FACTORY_ADDRESS } from "src/constants/addresses"
import useMarginalPoolExists from "./useMarginalPoolExists"
import { type UniswapPool } from "src/types"
import { useCreatePoolState } from "src/state/createPool/hooks"

export const UNISWAP_FEE_AMOUNT = {
  LOWEST: 100,
  LOW: 500,
  MEDIUM: 3000,
  HIGH: 10000,
} as const

const UNISWAP_FACTORY_ABI: Abi = [
  {
    type: "function",
    name: "getPool",
    inputs: [
      {
        name: "tokenA",
        type: "address",
        internalType: "address",
      },
      {
        name: "tokenB",
        type: "address",
        internalType: "address",
      },
      {
        name: "fee",
        type: "uint24",
        internalType: "uint24",
      },
    ],
    outputs: [
      {
        name: "pool",
        type: "address",
        internalType: "address",
      },
    ],
    stateMutability: "view",
  },
]

/** Get the Uniswap V3 pools for all fees of a specific pair of token.
 * It will create an object for each pool with useful information about it */
export const useUniswapV3Factory = () => {
  const { isConnected, chain } = useAccount()
  const { token0, token1 } = useCreatePoolState()
  const { isMarginalPool } = useMarginalPoolExists({ token0, token1 })

  const { data: getPoolData, isLoading } = useReadContracts({
    contracts: [
      {
        address: UNISWAP_V3_FACTORY_ADDRESS[chain?.id ?? 0],
        abi: UNISWAP_FACTORY_ABI,
        functionName: "getPool",
        args: [
          token0?.address as string,
          token1?.address as string,
          UNISWAP_FEE_AMOUNT.LOWEST,
        ],
      },
      {
        address: UNISWAP_V3_FACTORY_ADDRESS[chain?.id ?? 0],
        abi: UNISWAP_FACTORY_ABI,
        functionName: "getPool",
        args: [
          token0?.address as string,
          token1?.address as string,
          UNISWAP_FEE_AMOUNT.LOW,
        ],
      },
      {
        address: UNISWAP_V3_FACTORY_ADDRESS[chain?.id ?? 0],
        abi: UNISWAP_FACTORY_ABI,
        functionName: "getPool",
        args: [
          token0?.address as string,
          token1?.address as string,
          UNISWAP_FEE_AMOUNT.MEDIUM,
        ],
      },
      {
        address: UNISWAP_V3_FACTORY_ADDRESS[chain?.id ?? 0],
        abi: UNISWAP_FACTORY_ABI,
        functionName: "getPool",
        args: [
          token0?.address as string,
          token1?.address as string,
          UNISWAP_FEE_AMOUNT.HIGH,
        ],
      },
    ],
    scopeKey: JSON.stringify(chain?.id) + token0?.address + token1?.address,
    query: {
      enabled:
        Boolean(token0?.address) &&
        Boolean(token1?.address) &&
        isConnected &&
        !isMarginalPool,
      staleTime: Infinity,
    },
  })

  const isUniswapPool = useMemo(() => {
    return getPoolData?.some(({ result }) => {
      return result !== zeroAddress
    })
  }, [getPoolData])

  const uniswapFactoryPools: Pick<UniswapPool, "address" | "isPool" | "fee">[] =
    useMemo(() => {
      if (!getPoolData) return []

      return getPoolData.map(({ result, status }, index) => ({
        /**Uniswap V3 pool address*/
        address: status === "success" ? (result as Address) : null,
        /**True if the Uniswap V3 pool exists*/
        isPool: result !== zeroAddress && status === "success",
        fee: Object.values(UNISWAP_FEE_AMOUNT)[index],
      }))
    }, [getPoolData])

  return {
    uniswapFactoryPools,
    isUniswapPool,
    isMarginalPool,
    isLoading,
  }
}
