import { formatBigIntToString } from "src/utils/formatBigIntToString"
import { formatNumberAmount } from "src/utils/formatNumberAmount"
import { calculatePositionHealthFactor } from "src/utils/conversions/calculatePositionHealthFactor"
import { calculatePriceImpact } from "src/utils/conversions/calculatePriceImpact"
import { getMintParamsWithSlippage } from "./getMintParamsWithSlippage"
import { convertX96PricesToBigInt } from "src/utils/conversions/convertX96Price"
import { MintQuote } from "src/types"
import { Token } from "src/types"

interface DerivedMintQuoteData {
  margin: {
    raw: bigint | undefined
    parsed: string | undefined
    formatted: string | null | undefined
  }
  debt: {
    raw: bigint | undefined
    parsed: string | undefined
    formatted: string | null | undefined
  }
  size: {
    raw: bigint | undefined
    parsed: string | undefined
    formatted: string | null | undefined
  }
  fees: {
    raw: string | undefined
    formatted: string | null | undefined
  }
  healthFactor: number | null
  priceImpact: number | null
  mintQuoteError: string | undefined
  derivedMintParamsWithSlippage: any
}

export const deriveMintQuoteData = (
  mintQuote: MintQuote | null,
  marginToken: Token | null | undefined,
  debtToken: Token | null | undefined,
  fees: string | undefined,
  sqrtPriceX96: bigint,
  zeroForOne: boolean,
  maxSlippage: string,
  mintQuoteParams: any,
): DerivedMintQuoteData => {
  // Process margin data
  const margin = {
    raw: mintQuote?.margin,
    parsed: formatBigIntToString(mintQuote?.margin, marginToken?.decimals),
    formatted: formatNumberAmount(
      formatBigIntToString(mintQuote?.margin, marginToken?.decimals),
      true,
    ),
  }

  // Process debt data
  const debt = {
    raw: mintQuote?.debt,
    parsed: formatBigIntToString(mintQuote?.debt, debtToken?.decimals),
    formatted: formatNumberAmount(
      formatBigIntToString(mintQuote?.debt, debtToken?.decimals),
      true,
    ),
  }

  // Process size data
  const size = {
    raw: mintQuote?.size,
    parsed: formatBigIntToString(mintQuote?.size, marginToken?.decimals),
    formatted: formatNumberAmount(
      formatBigIntToString(mintQuote?.size, marginToken?.decimals),
      true,
    ),
  }

  const quoteFees = {
    raw: fees,
    formatted: formatNumberAmount(fees, true),
  }

  const healthFactor = calculatePositionHealthFactor(
    mintQuote?.margin,
    mintQuote?.safeMarginMinimum,
  )

  const priceImpact = calculatePriceImpact(
    mintQuote?.sqrtPriceX96After,
    sqrtPriceX96,
    zeroForOne,
  )

  const mintQuoteError = checkMintQuoteError(mintQuote, priceImpact, maxSlippage)

  const derivedMintParamsWithSlippage = getMintParamsWithSlippage(
    mintQuoteParams,
    mintQuote,
    zeroForOne,
    convertX96PricesToBigInt(sqrtPriceX96),
    maxSlippage,
  )

  return {
    margin,
    debt,
    size,
    fees: quoteFees,
    healthFactor: derivedMintParamsWithSlippage ? healthFactor : null,
    priceImpact,
    mintQuoteError,
    derivedMintParamsWithSlippage,
  }
}

const checkMintQuoteError = (
  mintQuote: MintQuote | null,
  priceImpact: number | null,
  maxSlippage: string,
): string | undefined => {
  if (!mintQuote) {
    return undefined
  }
  if (!mintQuote.safe) {
    return "Not enough margin"
  }

  if (priceImpact && maxSlippage && priceImpact > parseFloat(maxSlippage)) {
    return "Slippage exceeds tolerance"
  }

  return undefined
}
