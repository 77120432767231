import { useReadContract } from "wagmi"
import { Address } from "viem"
import multicallAbi from "src/constants/abis/multicall.json"
import { isListDefined } from "src/utils/isListDefined"

const MULTICALL3_ADDRESS: Address = `0xcA11bde05977b3631167028862bE2a173976CA11`

export const useGetCurrentBlockTimestamp = (chainId: number): bigint | undefined => {
  const isEnabled = isListDefined([chainId])

  const { data } = useReadContract({
    chainId,
    address: MULTICALL3_ADDRESS,
    abi: multicallAbi,
    functionName: "getCurrentBlockTimestamp",
    query: {
      enabled: isEnabled,
    },
  })

  if (typeof data === "bigint") {
    return data
  } else {
    return undefined
  }
}
