import { cleanInput } from "src/utils/cleanInput"

export const StakeInput = ({
  inputValue,
  onChange,
}: {
  inputValue: string
  onChange: (value: string) => void
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(cleanInput(e.target.value))
  }
  return (
    <div id="stake-input">
      <input
        value={inputValue}
        onChange={handleChange}
        placeholder="0"
        pattern="^[0-9]*[.,]?[0-9]*$"
        autoComplete="off"
        className={`w-full border-none bg-marginalBlack p-0 text-3xl text-marginalGray-200 caret-marginalOrange-500 outline-none focus:border-none focus:outline-none focus:ring-0`}
      />
    </div>
  )
}
