import { isNull, isString } from "lodash"
import { useMemo } from "react"
import { isOnlyZeroes } from "src/utils/isOnlyZeroes"
import { isBlank } from "src/utils/isBlank"
import { useCreatePoolState } from "src/state/createPool/hooks"

const validateAmount = (value: unknown) =>
  isString(value) && !isBlank(value) && !isOnlyZeroes(value)

export const useCreatePoolValidations = () => {
  const { token0, token1, amount0, amount1, uniswapPool, leverage } = useCreatePoolState()

  const isTokenSelectionsValid = useMemo(() => {
    return !isNull(token0) && !isNull(token1)
  }, [token0, token1])

  /**True when Token0, Token1 and respective amounts are populated. */
  const isDepositAmountValid = useMemo(() => {
    return (
      !isNull(token0) &&
      !isNull(token1) &&
      validateAmount(amount0) &&
      validateAmount(amount1)
    )
  }, [token0, token1, amount0, amount1])

  const isOracleSelectionValid = useMemo(() => {
    return !isNull(uniswapPool)
  }, [uniswapPool])

  const isLeverageSelectionValid = useMemo(() => {
    return !isNull(leverage)
  }, [leverage])

  /**True when the selected Uniswap v3 pool exists and its Observable Cardinality Next is greater than the minimum required */
  const isChooseOracleValid = useMemo(() => {
    return Boolean(uniswapPool?.isPool) && !Boolean(uniswapPool?.isOCNIncreaseRequired)
  }, [uniswapPool])

  const isDeployPoolValid = useMemo(() => {
    return isDepositAmountValid && isChooseOracleValid
  }, [isDepositAmountValid, isChooseOracleValid])

  const isOracleInitRequired = useMemo(() => {
    return Boolean(uniswapPool?.isOCNIncreaseRequired)
  }, [uniswapPool])

  const isUniswapPool = useMemo(() => {
    return Boolean(uniswapPool?.isPool)
  }, [uniswapPool])

  return {
    isOracleSelectionValid,
    isLeverageSelectionValid,
    isTokenSelectionsValid,
    isDepositAmountValid,
    isChooseOracleValid,
    isDeployPoolValid,
    isOracleInitRequired,
    isUniswapPool,
  }
}
