import { useState } from "react"
import { useAccount } from "wagmi"
import { useSubgraphIndexedPositions } from "src/pages/positions/hooks/useSubgraphIndexedPositions"
import { Address } from "viem"
import { usePositionsOnChainState } from "src/pages/positions/hooks/usePositionsOnChainState"
import { useMultipleIgniteQuote } from "src/pages/positions/hooks/useMultipleIgniteQuote"
import { useMultipleUniswapV3PoolState } from "src/pages/position/managePosition/hooks/useUniswapV3PoolState"
import { useApplicationState } from "src/state/application/hooks"
import { PositionsHeader } from "src/pages/positions/components/PositionsHeader"
import { PositionsTable } from "src/pages/positions/components/PositionsTable"
import { PositionRow } from "src/pages/positions/components/PositionRow"

export enum PositionView {
  OPEN = "Open",
  CLOSED = "Closed",
}

export const Positions = () => {
  const { chainId } = useApplicationState()
  const { address } = useAccount()
  const { positions } = useSubgraphIndexedPositions()
  const positionTokenIds = Object.keys(positions || {})
  const { data, isLoading: isLoadingOnChainPositionsState } = usePositionsOnChainState(
    positionTokenIds,
    chainId,
  )

  const quoteParams = Object.values(data).map((position) => ({
    tokenId: position.tokenId,
    token0: positions?.[position.tokenId]?.pool?.token0?.address as Address,
    token1: positions?.[position.tokenId]?.pool?.token1?.address as Address,
    maintenance: positions?.[position.tokenId]?.pool?.maintenance,
    oracle: positions?.[position.tokenId]?.pool?.oracle as Address,
    zeroForOne: positions?.[position.tokenId]?.zeroForOne as boolean,
  }))
  const { data: quotesData, isLoading: isLoadingQuotes } = useMultipleIgniteQuote(
    quoteParams,
    chainId,
  )
  const { data: uniswapV3PoolData } = useMultipleUniswapV3PoolState(
    quoteParams?.map((quote) => quote.oracle),
    chainId,
  )
  const isLoading =
    positionTokenIds.length > 0 && (isLoadingOnChainPositionsState || isLoadingQuotes)

  const [view, setView] = useState<PositionView>(PositionView.OPEN)

  const [currentPage, setCurrentPage] = useState<number>(1)

  const handleView = (view: PositionView) => {
    setView(view)
    setCurrentPage(1)
    console.log("Current page:", currentPage)
  }

  return (
    <div className="mx-auto flex w-full max-w-4xl flex-col border border-marginalGray-800">
      <PositionsHeader view={view} onViewChange={handleView} />
      <PositionsTable
        connectedAddress={address}
        positions={positionTokenIds}
        view={view}
        isLoading={isLoading}
      >
        {positionTokenIds.map((tokenId) => (
          <PositionRow
            key={`position-${tokenId}`}
            view={view}
            chainId={chainId}
            tokenId={tokenId}
            positionKey={positions?.[tokenId]?.id as string}
            positionData={data?.[tokenId]}
            pool={positions?.[tokenId]?.pool}
            igniteParams={quoteParams?.find((param) => param.tokenId === tokenId)}
            igniteQuote={quotesData?.[tokenId]}
            uniswapV3PoolPrice={
              uniswapV3PoolData?.[positions?.[tokenId]?.pool?.address]?.price
            }
            uniswapV3PoolFee={
              uniswapV3PoolData?.[positions?.[tokenId]?.pool?.address]?.fee
            }
            indexedPositionData={positions?.[tokenId]}
          />
        ))}
      </PositionsTable>
    </div>
  )
}
