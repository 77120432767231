/**
 * Constant for the decimal precision used in uint24 values
 * 1_000_000 represents 6 decimal places (10^6)
 */
const UINT24_DECIMAL_PRECISION = 1_000_000n

/**
 * Converts a uint24 value to its decimal representation
 * @param uint24Value - The uint24 value as a bigint
 * @returns The decimal representation of the uint24 value
 * @throws Error if the input value is invalid
 */
const convertUint24ToDecimal = (uint24Value: bigint): number => {
  try {
    const numericValue = Number(uint24Value)

    if (isNaN(numericValue)) {
      throw new Error("Invalid uint24 value")
    }

    return numericValue / Number(UINT24_DECIMAL_PRECISION)
  } catch (error) {
    throw new Error(`Failed to convert uint24 value: ${error}`)
  }
}

/**
 * Calculates the maximum leverage from a maintenance value
 * The formula is: totalLeverage = 1 + (1 / maintenance)
 * where:
 * - 1 represents the base position
 * - 1/maintenance represents the additional leverage
 *
 * @param maintenance - The maintenance value as a bigint
 * @returns The maximum leverage as a number
 * @throws Error if the maintenance value is invalid or zero
 */
export const convertMaintenanceToLeverage = (maintenance: bigint): number | null => {
  try {
    if (!maintenance) {
      return null
    }

    const maintenanceDecimal = convertUint24ToDecimal(maintenance)

    if (maintenanceDecimal === 0) {
      throw new Error("Maintenance value cannot be zero")
    }

    const BASE_POSITION = 1
    const additionalLeverage = 1 / maintenanceDecimal
    const totalLeverage = BASE_POSITION + additionalLeverage

    return totalLeverage
  } catch (error) {
    throw new Error(`Failed to convert maintenance to leverage: ${error}`)
  }
}
