import { readContract } from "@wagmi/core"
import { Address } from "viem"
import { wagmiConfig } from "src/wagmiConfig"
import marginalV1QuoterAbi from "src/constants/abis/MarginalV1Quoter.json"
import { IgniteQuote, Token } from "src/types"
import { isWrappedGasToken } from "src/utils/isWrappedGasToken"
import { IgniteQuoteParams } from "src/pages/positions/helpers/constructIgniteQuoteParams"

export async function getIgniteQuote(
  chainId: number,
  quoterAddress: Address,
  params: IgniteQuoteParams,
  marginToken: Token,
): Promise<IgniteQuote | null> {
  try {
    const data = await readContract(wagmiConfig, {
      chainId,
      address: quoterAddress,
      abi: marginalV1QuoterAbi,
      functionName: "quoteIgnite",
      args: [params],
    })

    if (!Array.isArray(data)) {
      return null
    }

    const isMarginTokenWeth = isWrappedGasToken(marginToken, chainId)

    return {
      amountOut: isMarginTokenWeth ? data[0] - data[1] : data[0],
      rewards: data[1],
      liquidityAfter: data[2],
      sqrtPriceX96After: data[3],
      liquidityLockedAfter: data[4],
    }
  } catch (error) {
    console.error("Error getting ignite quote:", error)
    return null
  }
}
