import { useReadContract } from "wagmi"
import { V1_ORACLE_ADDRESS } from "src/constants/addresses"
import marginalV1OracleAbi from "src/constants/abis/MarginalV1Oracle.json"
import { isListDefined } from "src/utils/isListDefined"

export const useLiquidationPrice = (
  chainId: number,
  zeroForOne: boolean,
  size?: bigint,
  debt?: bigint,
  margin?: bigint,
  maintenance?: bigint,
): bigint | undefined => {
  const isValidProps = isListDefined([chainId, size, debt, margin, maintenance])

  const { data } = useReadContract({
    chainId,
    address: V1_ORACLE_ADDRESS[chainId],
    abi: marginalV1OracleAbi,
    args: [zeroForOne, size, debt, margin, maintenance],
    functionName: "liquidationSqrtPriceX96",
    query: {
      enabled: isValidProps,
    },
  })

  if (typeof data === "bigint") {
    return data
  }

  return undefined
}
