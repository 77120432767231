import { readContract } from "@wagmi/core"
import { Address } from "viem"
import { wagmiConfig } from "src/wagmiConfig"
import marginalV1QuoterAbi from "src/constants/abis/MarginalV1Quoter.json"
import { RemoveLiquidityQuote, RemoveLiquidityParams } from "src/types"

export async function getRemoveLiquidityQuote(
  chainId: number,
  quoterAddress: Address,
  params: RemoveLiquidityParams,
): Promise<RemoveLiquidityQuote | null> {
  try {
    const data = await readContract(wagmiConfig, {
      address: quoterAddress,
      abi: marginalV1QuoterAbi,
      functionName: "quoteRemoveLiquidity",
      args: [params],
      chainId,
    })

    if (!Array.isArray(data)) {
      return null
    }

    return {
      liquidityDelta: data[0],
      amount0: data[1],
      amount1: data[2],
      liquidityAfter: data[3],
    }
  } catch (error) {
    console.error("Error getting remove liquidity quote:", error)
    return null
  }
}
