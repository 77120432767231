export const LaunchTabs = ({
  view,
  handlePrimaryClick,
  handleSecondaryClick,
  handleTertiaryClick,
  transactionsCount,
  holdersCount,
  primaryText = "Details",
  secondaryText = "Trade History",
  tertiaryText = "Holders",
}: {
  view: string
  handlePrimaryClick?: any
  handleSecondaryClick?: any
  handleTertiaryClick?: any
  transactionsCount: number
  holdersCount: number
  primaryText?: string
  secondaryText?: string
  tertiaryText?: string
}) => {
  return (
    <div className="mx-auto h-10 w-fit gap-1 rounded-2xl bg-marginalBlack p-1 md:mx-0">
      <div className="flex w-full space-x-1">
        <button
          key="primary-tab-btn"
          onClick={handlePrimaryClick}
          className={`${
            view === primaryText
              ? "text-marginalOrange-500 shadow-outerBlue"
              : "text-marginalGray-200 hover:bg-marginalGray-950"
          } relative flex h-8 w-full cursor-pointer items-center justify-center rounded-xl px-4 py-2 text-sm transition`}
        >
          {view === primaryText && (
            <span className="absolute inset-0 z-10 rounded-xl bg-marginalOrange-800" />
          )}
          <span className="z-10">{primaryText}</span>
        </button>
        <button
          key="secondary-tab-btn"
          onClick={handleSecondaryClick}
          className={` ${view === secondaryText ? "text-marginalOrange-500 shadow-outerBlue" : "text-marginalGray-200 hover:bg-marginalGray-950"} relative flex h-8 w-full cursor-pointer items-center justify-center rounded-xl px-4 py-2 text-sm transition`}
        >
          {view === secondaryText && (
            <span className="absolute inset-0 z-10 rounded-xl bg-marginalOrange-800" />
          )}
          <span className="z-10 text-nowrap">{secondaryText}</span>

          <div
            className={`${view === secondaryText ? "bg-marginalOrange-500 text-marginalOrange-800" : "bg-marginalGray-800"} z-10 ml-3 w-fit rounded px-1`}
          >
            {transactionsCount}
          </div>
        </button>
        <button
          key="tertiary-tab-btn"
          onClick={handleTertiaryClick}
          className={` ${view === tertiaryText ? "text-marginalOrange-500 shadow-outerBlue" : "text-marginalGray-200 hover:bg-marginalGray-950"} relative flex h-8 w-full cursor-pointer items-center justify-center rounded-xl px-4 py-2 text-sm transition`}
        >
          {view === tertiaryText && (
            <span className="absolute inset-0 z-10 rounded-xl bg-marginalOrange-800" />
          )}
          <span className="z-10">{tertiaryText}</span>

          <div
            className={`${view === tertiaryText ? "bg-marginalOrange-500 text-marginalOrange-800" : "bg-marginalGray-800"} z-10 ml-3 w-fit rounded px-1`}
          >
            {holdersCount}
          </div>
        </button>
      </div>
    </div>
  )
}
