import _ from "lodash"
import { useMemo, useEffect } from "react"
import { useApplicationState } from "src/state/application/hooks"
import { useGetPoolsQuery, useGetPoolQuery } from "src/state/api/generated-lb"

export const useGetLBPoolsQuery = () => {
  const { chainId } = useApplicationState()

  const { data, isLoading, isFetching, isError, refetch } = useGetPoolsQuery()

  const poolsExist = !_.isUndefined(data) && !_.isEmpty(data)

  useEffect(() => {
    refetch()
  }, [refetch, chainId])

  return useMemo(
    () => ({
      pools: poolsExist ? data.marginalV1LBPools : [],
      hasPools: poolsExist,
      isLoading,
      isFetching,
      isError,
      refetch,
    }),
    [data?.marginalV1LBPools, poolsExist, isLoading, isFetching, isError, refetch],
  )
}

export const useGetLBPoolQuery = (poolAddress: string | undefined) => {
  const { data: poolData, refetch } = useGetPoolQuery(
    { address: poolAddress ?? "" },
    { skip: !poolAddress },
  )

  return useMemo(
    () => ({
      pool: poolData?.marginalV1LBPool,
      refetch,
    }),
    [poolData?.marginalV1LBPool, refetch],
  )
}
