import { Transport, type Chain } from "viem"
import { fallback, http } from "@wagmi/core"

import { CHAINS_BY_ID } from "src/constants/chains/chains"

type Transports = Record<Chain["id"], Transport>

export const createTransports = (chains: Chain[]): Transports => {
  return chains.reduce<Transports>((acc, chain) => {
    const marginalChain = CHAINS_BY_ID[chain.id]

    acc[chain.id] = fallback([
      http(marginalChain.rpcUrls.primary),
      http(marginalChain.rpcUrls.fallback),
    ])
    return acc
  }, {})
}
