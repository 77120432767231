import {
  useApplicationState,
  useCloseModal,
  useModalIsOpen,
  useOpenModal,
} from "src/state/application/hooks"
import {
  useCreatePoolActionHandlers,
  useCreatePoolState,
} from "src/state/createPool/hooks"
import { TokenSelector } from "src/pages/trade/components/TokenSelector"
import { useAccount } from "wagmi"
import { useEffect } from "react"
import { TokenSelectorQueryModal } from "src/components/ui/TokenSelectorQueryModal"
import { useTokenList } from "src/hooks/useTokenList"

const ApplicationModal = {
  Token0: 0,
  Token1: 1,
}

const SelectTokens = () => {
  const { chainId } = useApplicationState()
  const openToken0List = useOpenModal(ApplicationModal.Token0)
  const openToken1List = useOpenModal(ApplicationModal.Token1)
  const isInput0Open = useModalIsOpen(ApplicationModal.Token0)
  const isInput1Open = useModalIsOpen(ApplicationModal.Token1)
  const closeInputModal = useCloseModal()
  const { token0, token1 } = useCreatePoolState()
  const { onSelectToken0, onSelectToken1 } = useCreatePoolActionHandlers()
  const tokenList = useTokenList(chainId)

  const handleSelectInput0Token = (token: any) => {
    if (token === token1) {
      onSelectToken1(null)
    }
    onSelectToken0(token)
    closeInputModal()
  }

  const handleSelectInput1Token = (token: any) => {
    if (token === token0) {
      onSelectToken0(null)
    }
    onSelectToken1(token)
    closeInputModal()
  }

  const { chain } = useAccount()

  useEffect(() => {
    /**If the selected token's chainId doesn't match the current chain ID, we clear the inputs. */
    if (chain && (token0?.chainId !== chain.id || token1?.chainId !== chain.id)) {
      onSelectToken0(null)
      onSelectToken1(null)

      if ((token0?.address && !token0.chainId) || (token1?.address && !token1.chainId)) {
        throw Error(
          "Token list is missing the chain ID parameter. Use a token list with chainId params to avoid issues with the token selector.",
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chain?.id])

  return (
    <div className="align-center flex w-full justify-center gap-3">
      <div className="max-h-12 w-full rounded-lg bg-marginalBlack p-3 text-xl">
        <TokenSelector
          onClick={openToken0List}
          selectedToken={token0}
          tokenOptions={tokenList}
          onSelect={onSelectToken0}
        />
      </div>

      <TokenSelectorQueryModal
        chainId={chainId}
        isOpen={isInput0Open}
        selectedToken={token0}
        tokenOptions={tokenList}
        onSelect={handleSelectInput0Token}
        onClose={closeInputModal}
      />

      <div className="max-h-12 w-full rounded-lg bg-marginalBlack p-3 text-xl">
        <TokenSelector
          onClick={openToken1List}
          selectedToken={token1}
          tokenOptions={tokenList}
          onSelect={onSelectToken1}
        />
      </div>

      <TokenSelectorQueryModal
        chainId={chainId}
        isOpen={isInput1Open}
        selectedToken={token1}
        tokenOptions={tokenList}
        onSelect={handleSelectInput1Token}
        onClose={closeInputModal}
      />
    </div>
  )
}

export default SelectTokens
