import { useParams } from "react-router-dom"
import { useNavigateRoutes } from "src/hooks/useNavigateRoutes"
import { getPoolDataByAddress, usePoolsData } from "src/hooks/usePoolsData"
import { useAccount } from "wagmi"
import { type PoolData, type Token } from "src/types"
import { useErc20TokenSymbol } from "src/hooks/useErc20TokenSymbol"
import { PositionHeader } from "src/pages/position/components/PositionHeader"
import { CaretLeftIcon } from "src/components/Icons/CaretLeftIcon"
import { type Address } from "viem"
import { useNetworkChangeRedirect } from "src/hooks/useNetworkChangeRedirect"
import { usePoolRewardsAPR } from "src/hooks/usePoolRewardsAPR"
import { MARGINAL_DAO_TOKEN } from "src/constants/tokens"
import { useUserRewardsBalance } from "src/hooks/useUserRewardsBalance"
import { useTotalSupplyInPools } from "src/pages/pools/hooks/useTotalSupplyInPools"
import { useLiquidityLockedInPools } from "src/pages/pools/hooks/useLiquidityLockedInPools"
import { useCurrentPoolsState } from "src/pages/pools/hooks/useCurrentPoolsState"
import { useUserPoolBalance } from "src/pages/pool/hooks/useUserPoolBalance"
import { calculatePercentageOfTotal } from "src/utils/conversions/calculatePercentageOfTotal"
import { useUserStakedPoolBalance } from "src/pages/pool/hooks/useUserStakedPoolBalance"
import { derivePoolLiquidity } from "src/pages/pool/helpers/derivePoolLiquidity"
import { calculateMulDiv } from "src/pages/pool/helpers/derivePoolLiquidity"
import { convertMaintenanceToLeverage } from "src/utils/conversions/convertMaintenanceToLeverage"
import { useLivePoolPrices } from "src/pages/trade/hooks/useLivePoolPrices"
import { extrapolateTokenPair } from "src/utils/extrapolateTokenPair"
import { useApplicationState } from "src/state/application/hooks"
import { getTokenByAddress } from "src/constants/tokenList"
import { PoolLoading } from "src/pages/pool/components/PoolLoading"
import { PoolDetailsUtilization } from "src/pages/pool/components/PoolDetailsUtilization"
import { PoolSharesAndRewards } from "src/pages/pool/components/PoolSharesAndRewards"
import { PoolLinks } from "src/pages/pool/components/PoolLinks"

const Pool = () => {
  const { chainId } = useApplicationState()
  const { address } = useAccount()
  const { onNavigateToPools, onNavigateToAddLiquidity, onNavigateToRemoveLiquidity } =
    useNavigateRoutes()
  useNetworkChangeRedirect(onNavigateToPools)
  const { poolsDataByAddress, isLoading } = usePoolsData()
  const { poolAddress } = useParams()
  const pool = getPoolDataByAddress(poolAddress as string, poolsDataByAddress)
  const leverageCap = convertMaintenanceToLeverage(pool?.maintenance as bigint)
  const poolsTotalSupply = useTotalSupplyInPools([pool])
  const liquidityLockedInPools = useLiquidityLockedInPools([pool])
  const { balances } = useUserPoolBalance([pool], address)
  const { poolStates } = useCurrentPoolsState([pool?.poolAddress as Address])
  const { balances: userStakedBalance } = useUserStakedPoolBalance(
    [pool as PoolData],
    address,
  )

  const poolState = poolStates[pool?.poolAddress as Address]
  const userLpBalance = balances?.[0]
  const userStakedLpBalance = userStakedBalance?.[pool?.stakePool as Address]
  const userTotalLpBalance = (
    parseFloat(userLpBalance?.parsedBalance ?? "0") +
    parseFloat(userStakedLpBalance?.parsedBalance ?? "0")
  ).toString()

  const poolLiquidityAvailable = poolState
  const poolLiquidityLocked = liquidityLockedInPools?.[0]
  const poolLiquidityTotal = poolsTotalSupply?.[0]

  const userSharePercentageOfTotal = calculatePercentageOfTotal({
    total: parseFloat(poolLiquidityTotal?.parsedTotalSupply ?? "0"),
    partial: parseFloat(userTotalLpBalance ?? "0"),
  })
  const poolUtilization = calculatePercentageOfTotal({
    total: parseFloat(poolLiquidityTotal?.parsedTotalSupply ?? "0"),
    partial: parseFloat(poolLiquidityLocked?.parsedLockedLiquidity ?? "0"),
  })

  const { rewards } = useUserRewardsBalance(
    pool?.stakePool as Address,
    address,
    pool?.rewardTokens ?? [],
  )

  const token0 = getTokenByAddress(pool?.token0?.address, chainId) ?? pool?.token0
  const token1 = getTokenByAddress(pool?.token1?.address, chainId) ?? pool?.token1
  const { quoteToken } = extrapolateTokenPair(token0 ?? null, token1 ?? null, chainId)

  const { token0PriceInUSD, token1PriceInUSD } = useLivePoolPrices({
    chainId,
    selectedPool: pool,
    zeroForOne: true,
    useInverse: true,
    quoteToken,
    position: undefined,
  })

  const { available, locked, total } = derivePoolLiquidity({
    token0: token0 ?? null,
    token1: token1 ?? null,
    sqrtPriceX96: poolState?.sqrtPriceX96,
    liquidityAvailable: poolLiquidityAvailable?.liquidity,
    liquidityLocked: poolLiquidityLocked?.liquidityLocked,
    liquidityTotal: poolLiquidityTotal?.totalSupply,
  })

  const lpTokenSymbol = useErc20TokenSymbol(poolAddress as Address)

  const userTotalLpBalanceRaw =
    userLpBalance &&
    userStakedLpBalance &&
    userLpBalance?.balance + userStakedLpBalance?.balance

  const userToken0Balance = calculateMulDiv({
    x: total.token0.raw,
    y: userTotalLpBalanceRaw,
    d: poolLiquidityTotal?.totalSupply,
  })

  const userToken1Balance = calculateMulDiv({
    x: total.token1.raw,
    y: userTotalLpBalanceRaw,
    d: poolLiquidityTotal?.totalSupply,
  })

  const durationOneYearInSeconds = 86400 * 365

  const { aprPercentage } = usePoolRewardsAPR(
    pool?.poolAddress as Address,
    MARGINAL_DAO_TOKEN as Address,
    pool?.poolAddress as Address,
    durationOneYearInSeconds,
    chainId,
  )

  const tvl =
    (total?.token0?.parsed ? parseFloat(total.token0.parsed) : 0) *
      (token0PriceInUSD ?? 0) +
    (total?.token1?.parsed ? parseFloat(total.token1.parsed) : 0) *
      (token1PriceInUSD ?? 0)

  return (
    <div className="mx-auto flex flex-col space-y-4 md:space-y-6">
      <div
        onClick={() => onNavigateToPools()}
        className="flex cursor-pointer items-center justify-start space-x-1 text-marginalGray-200"
      >
        <CaretLeftIcon />
        <span className="text-sm">Back to Pools</span>
      </div>

      {isLoading ? (
        <PoolLoading />
      ) : (
        <>
          <div className="rounded-3xl border border-marginalGray-800 bg-marginalGray-900 shadow-outerBlack">
            <PositionHeader
              token0={token0 as Token}
              token1={token1 as Token}
              handlePrimaryButton={() => onNavigateToAddLiquidity(poolAddress as string)}
              handleSecondaryButton={() =>
                onNavigateToRemoveLiquidity(poolAddress as string)
              }
              primaryText="Add Liquidity"
              secondaryText="Remove Liquidity"
            />

            <div className="flex w-full flex-col space-x-0 p-2 md:flex-row md:space-x-2 md:p-4">
              <PoolDetailsUtilization
                token0={token0 as Token}
                token1={token1 as Token}
                poolUtilizationPercentage={poolUtilization}
                lpTokenSymbol={lpTokenSymbol}
                poolSupplyTotal={poolLiquidityTotal?.parsedTotalSupply}
                userPoolBalance={userTotalLpBalance}
                availableBalance={available}
                lockedBalance={locked}
                leverageCap={leverageCap}
                tvl={tvl}
              />
              <PoolSharesAndRewards
                chainId={chainId}
                token0={token0 as Token}
                token1={token1 as Token}
                userSharePercentageOfTotal={userSharePercentageOfTotal}
                userToken0Balance={userToken0Balance}
                userToken1Balance={userToken1Balance}
                userStakedLpBalance={userStakedLpBalance?.parsedBalance}
                aprPercentage={aprPercentage}
                poolAddress={poolAddress}
                stakePool={pool?.stakePool}
                rewards={rewards}
              />
            </div>
          </div>
          <PoolLinks
            chainId={chainId}
            token0={token0 as Token}
            token1={token1 as Token}
            poolAddress={poolAddress}
          />
        </>
      )}
    </div>
  )
}

export default Pool
