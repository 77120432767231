import { useState } from "react"
import { RotatingActiveArrow } from "src/components/RotatingActiveArrow"
import { type Token } from "src/types"
import { GAS_TOKEN_MAP, WRAPPED_GAS_TOKEN_MAP } from "src/constants/tokens"

export const GasSelect = ({
  useGas,
  setUseGas,
  chainId,
}: {
  useGas: boolean
  setUseGas: React.Dispatch<React.SetStateAction<boolean>>
  chainId: number
}) => {
  const WRAPPED_GAS_TOKEN = WRAPPED_GAS_TOKEN_MAP[chainId]
  const GAS_TOKEN = GAS_TOKEN_MAP[chainId]
  const options: Token[] = [WRAPPED_GAS_TOKEN, GAS_TOKEN]
  const [token, setToken] = useState(useGas ? GAS_TOKEN : WRAPPED_GAS_TOKEN)
  const [showOptions, setShowOptions] = useState(false)

  return (
    <div className="relative">
      <div
        id="gas-select"
        className="mt-0.5 flex w-fit cursor-pointer items-center justify-between space-x-2 hover:opacity-60"
        onClick={() => setShowOptions(!showOptions)}
      >
        <RotatingActiveArrow isActive={showOptions} />
      </div>

      {showOptions && (
        <div className="duration-175 absolute right-0 top-4 z-20 mt-2 flex w-28 transform-gpu flex-col space-y-1 rounded-xl border border-borderGray bg-bgGray px-3 py-3 text-white shadow-xl">
          {options.map((option) => (
            <button
              id={`${option.symbol}-tab-button`}
              key={option.symbol}
              onClick={() => {
                setToken(
                  option.symbol === GAS_TOKEN.symbol ? GAS_TOKEN : WRAPPED_GAS_TOKEN,
                )
                setUseGas(option.symbol === GAS_TOKEN.symbol ? true : false)
                setShowOptions(false)
              }}
              className={`${
                token.symbol === option.symbol
                  ? ""
                  : "opacity-60 hover:text-marginalOrange-500 hover:opacity-100"
              } relative text-marginalGray-200`}
            >
              {option.symbol}
            </button>
          ))}
        </div>
      )}
    </div>
  )
}
