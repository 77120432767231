import { Token, UniswapPool } from "src/types"

export const validateCreatePoolParams = (
  token0: Token | null,
  token1: Token | null,
  amount0: string,
  amount1: string,
  uniswapPool: UniswapPool | null,
  leverage: { leverage: number; maintenance: number } | null,
  currentBlockTimestamp: bigint | undefined,
): boolean => {
  if (!token0 || !token1) return false
  if (!amount0 || !amount1) return false
  if (!uniswapPool) return false
  if (!leverage) return false
  if (Number(amount0) <= 0 || Number(amount1) <= 0) return false
  if (leverage.maintenance <= 0) return false
  if (!currentBlockTimestamp) return false

  return true
}
