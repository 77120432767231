import axios from "axios"
import { useQuery } from "@tanstack/react-query"
import { Token } from "src/types"
import { useReadContract } from "wagmi"
import { Address, erc20Abi } from "viem"

interface TokenInfo {
  address: string
  cmcId: string
  id: string
  isScam: boolean
  name: string
  networkId: number
  symbol: string
  totalSupply: string
  imageThumbUrl: string
  imageSmallUrl: string
  imageLargeUrl: string
  circulatingSupply: string
}

interface TokenQueryResponse {
  data: {
    getTokenInfo: TokenInfo
  }
}

interface TokenQueryProps {
  address: string
  chainId: number
}

export const useTokenQuery = ({ address, chainId }: TokenQueryProps) => {
  return useQuery({
    queryKey: ["token", address, chainId],
    queryFn: async () => {
      const response = await axios.post<TokenQueryResponse>(
        "https://graph.codex.io/graphql",
        {
          query: `{
            getTokenInfo(address: "${address}", networkId: ${chainId}) {
              address
              cmcId
              id
              isScam
              name
              networkId
              symbol
              totalSupply
              imageThumbUrl
              imageSmallUrl
              imageLargeUrl
              circulatingSupply
            }
          }`,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${process.env.REACT_APP_CODEX}`,
          },
        },
      )
      return response.data.data.getTokenInfo
    },
    enabled: Boolean(address && chainId),
  })
}

const serializeToken = (tokenInfo: TokenInfo, decimals: number): Token => ({
  chainId: tokenInfo.networkId,
  name: tokenInfo.name,
  symbol: tokenInfo.symbol,
  address: tokenInfo.address,
  decimals,
  imgUrl: tokenInfo.imageLargeUrl || tokenInfo.imageSmallUrl || tokenInfo.imageThumbUrl,
  priorityRank: 1, // Highest priority for queried tokens
})

export const useErc20TokenQuery = ({ address, chainId }: TokenQueryProps) => {
  const tokenQuery = useTokenQuery({ address, chainId })

  const { data: decimals } = useReadContract({
    address: address as Address,
    abi: erc20Abi as any,
    functionName: "decimals",
    chainId,
    query: {
      enabled: Boolean(address) && Boolean(chainId),
    },
  })

  return useQuery({
    queryKey: ["erc20", address, chainId, decimals],
    queryFn: async () => {
      const tokenInfo = tokenQuery.data

      if (!tokenInfo || typeof decimals !== "number") {
        throw new Error("Failed to fetch token information")
      }

      return serializeToken(tokenInfo, decimals)
    },
    enabled: Boolean(tokenQuery.data && decimals !== undefined && address && chainId),
  })
}
