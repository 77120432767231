import { useReadContracts } from "wagmi"
import { Address } from "viem"
import { PoolData } from "src/types"
import { formatBigIntToString } from "src/utils/formatBigIntToString"
import MarginalV1PoolAbi from "src/constants/abis/MarginalV1Pool.json"

export interface PoolLiquidityLocked {
  pool: PoolData | undefined | null
  liquidityLocked: bigint
  parsedLockedLiquidity: string | undefined
}

const createPoolContract = (poolAddress: Address) => ({
  address: poolAddress,
  abi: MarginalV1PoolAbi as any,
  functionName: "liquidityLocked" as const,
})

const formatLiquidityLocked = (
  liquidityLocked: bigint,
  decimals?: string,
): string | undefined => {
  return formatBigIntToString(liquidityLocked, decimals)
}

const mapPoolToContract = (pool: PoolData | undefined | null) => {
  if (!pool?.poolAddress) return null
  return createPoolContract(pool.poolAddress as Address)
}

/**
 * Hook to fetch and format liquidity locked in pools
 * @param pools Array of pool data
 * @returns Array of liquidity locked results for each pool
 */
export const useLiquidityLockedInPools = (
  pools: (PoolData | undefined | null)[],
): PoolLiquidityLocked[] | undefined => {
  // Filter out invalid pools and create contract configs
  const contracts = pools
    .map(mapPoolToContract)
    .filter((contract): contract is NonNullable<typeof contract> => contract !== null)

  const { data } = useReadContracts({
    contracts,
    query: {
      enabled: contracts.length > 0,
    },
  })

  if (!data) return undefined

  return data.map((result, index) => {
    const liquidityLocked = (result?.result as bigint) ?? 0n
    return {
      pool: pools[index],
      liquidityLocked,
      parsedLockedLiquidity: formatLiquidityLocked(
        liquidityLocked,
        pools[index]?.decimals,
      ),
    }
  })
}
