import { simulateContract, waitForTransactionReceipt, writeContract } from "@wagmi/core"
import { Address } from "viem"
import { wagmiConfig } from "src/wagmiConfig"
import marginalV1NonfungiblePositionManagerAbi from "src/constants/abis/MarginalV1NonfungiblePositionManager.json"
import { IgniteQuoteParams } from "src/pages/positions/helpers/constructIgniteQuoteParams"

export const ignite = async (
  chainId: number,
  positionManager: Address,
  params: IgniteQuoteParams,
) => {
  const { request } = await simulateContract(wagmiConfig, {
    chainId,
    address: positionManager,
    abi: marginalV1NonfungiblePositionManagerAbi,
    functionName: "ignite",
    args: [params],
  })

  const transaction = await writeContract(wagmiConfig, request)

  const receipt = await waitForTransactionReceipt(wagmiConfig, { hash: transaction })

  return receipt
}
