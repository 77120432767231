import { useCallback } from "react"
import { Link } from "react-router-dom"
import { type Token } from "src/types"
import useCopyClipboard from "src/hooks/useCopyClipboard"
import { getExplorerLink, ExplorerDataType } from "src/utils/getExplorerLink"
import { getValidAddress } from "src/utils/getValidAddress"
import { shortenAddress } from "src/utils/shortenAddress"
import { MouseoverTooltip } from "src/components/ui/MouseoverTooltip"
import { SquareStackIcon } from "src/components/Icons/SquareStackIcon"
import { EtherscanLogo } from "src/components/Icons/Etherscan"
import { DoubleTokenLogo, TokenLogo } from "src/components/TokenBadge"

interface PoolDetailsLinkProps {
  chainId: number | undefined
  address: string | undefined
  tokens: (Token | null | undefined)[]
}

export const PoolDetailsLink = ({ chainId, address, tokens }: PoolDetailsLinkProps) => {
  const [isCopied, setCopied] = useCopyClipboard()

  const handleCopy = useCallback(() => {
    const checksummedAddress = getValidAddress(address)
    checksummedAddress && setCopied(checksummedAddress)
  }, [address, setCopied])

  const isPool = tokens.length === 2
  const token0 = tokens[0]
  const token1 = isPool ? tokens[1] : null

  const explorerUrl =
    address && chainId
      ? getExplorerLink(
          chainId,
          address,
          isPool ? ExplorerDataType.ADDRESS : ExplorerDataType.TOKEN,
        )
      : undefined

  return (
    <div className="flex items-center justify-between">
      <ContractInfo token0={token0} token1={token1} isPool={isPool} />
      <AddressActions
        address={address}
        explorerUrl={explorerUrl}
        isCopied={isCopied}
        onCopy={handleCopy}
      />
    </div>
  )
}

interface ContractInfoProps {
  token0: Token | null | undefined
  token1: Token | null | undefined
  isPool: boolean
}

export const ContractInfo = ({ token0, token1, isPool }: ContractInfoProps) => {
  return (
    <div className="flex items-center space-x-2">
      {isPool ? (
        <>
          <DoubleTokenLogo token0={token0 as Token} token1={token1 as Token} size={6} />
          <div className="flex flex-col">
            <span className="text-sm text-marginalGray-200">
              {token0?.symbol} / {token1?.symbol}
            </span>

            <div className="text-xs text-marginalGray-600">
              Pool contract
            </div>
          </div>
        </>
      ) : (
        <>
          <TokenLogo symbol={token0?.symbol} imgUrl={token0?.imgUrl} size={6} />
          <div className="flex flex-col">
            <span className="text-sm text-marginalGray-200">
              {token0?.symbol}
            </span>

            <div className="text-xs text-marginalGray-600">
              Token contract
            </div>
          </div>
        </>
      )}
    </div>
  )
}

interface AddressActionsProps {
  address: string | undefined
  explorerUrl: string | undefined
  isCopied: boolean
  onCopy: () => void
}

export const AddressActions = ({
  address,
  explorerUrl,
  isCopied,
  onCopy,
}: AddressActionsProps) => {
  return (
    <div className="flex items-center space-x-2">
      <MouseoverTooltip
        hoverContent={<div>{isCopied ? "Copied" : "Copy"}</div>}
        timeoutInMs={isCopied ? 500 : 0}
      >
        <div
          onClick={onCopy}
          className="flex cursor-pointer items-center space-x-1 rounded-lg border border-transparent bg-marginalGray-800 py-2 pl-2.5 pr-2 hover:border-marginalGray-600 focus:outline-none focus:ring-1 focus:ring-marginalOrange-300 active:bg-marginalGray-800 active:text-marginalGray-600"
        >
          <span className="text-sm text-marginalGray-200">
            {shortenAddress(address)}
          </span>
          <SquareStackIcon />
        </div>
      </MouseoverTooltip>

      <Link to={explorerUrl ?? "#"} target="_blank" className="p-1 hover:opacity-50">
        <EtherscanLogo />
      </Link>
    </div>
  )
}
