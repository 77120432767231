import { Token } from "src/types"
import { PoolDetailsLink } from "src/pages/pool/components/PoolDetailsLink"

interface PoolLinksProps {
  chainId: number
  token0: Token | undefined
  token1: Token | undefined
  poolAddress: string | undefined
}

export const PoolLinks = ({ chainId, poolAddress, token0, token1 }: PoolLinksProps) => {
  return (
    <div className="space-y-4 md:px-4 md:pb-4">
      <div className="text-sm text-marginalGray-200">Links</div>
      <div className="flex flex-col space-y-6 md:flex-row md:space-y-0">
        <div className="flex w-full flex-col space-y-6">
          <PoolDetailsLink
            chainId={chainId}
            address={poolAddress}
            tokens={[token0, token1]}
          />
          <PoolDetailsLink
            chainId={chainId}
            address={token0?.address}
            tokens={[token0]}
          />
        </div>

        <div className="mx-6 my-auto hidden h-24 w-px bg-marginalGray-200/20 md:block" />

        <div className="flex w-full flex-col space-y-6">
          <PoolDetailsLink
            chainId={chainId}
            address={token1?.address}
            tokens={[token1]}
          />
        </div>
      </div>
    </div>
  )
}
