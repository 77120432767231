import { MintQuoteParams, MintQuote } from "src/types"
import { formatStringToBigInt } from "src/utils/formatStringToBigInt"

const HUNDRED_PERCENT = "100"
const DECIMALS = 18
const ONE = 1000000000000000000n

interface SlippageCalculationParams {
  value: bigint
  slippagePercent: string
  isDebt?: boolean
}

const calculateSlippageAdjustedValue = ({
  value,
  slippagePercent,
  isDebt = false,
}: SlippageCalculationParams): bigint | undefined => {
  // For size, we subtract slippage. For debt, we add slippage
  const effectivePercent = isDebt
    ? (100 + parseFloat(slippagePercent)).toString()
    : (100 - parseFloat(slippagePercent)).toString()

  const slippageNumerator = formatStringToBigInt(effectivePercent, DECIMALS)
  const slippageDenominator = formatStringToBigInt(HUNDRED_PERCENT, DECIMALS)

  if (!ONE || !slippageNumerator || !slippageDenominator) {
    return undefined
  }

  const effectivePercentage = (slippageNumerator * ONE) / slippageDenominator
  const bumpedValue = value * effectivePercentage

  return bumpedValue
}

const convertToDecimal = (value: bigint, bump: bigint = ONE): number => {
  const valueByPercentage = (value * 100n) / bump
  return parseFloat(valueByPercentage.toString()) / 100
}

const calculateDebtWithoutSlippage = (
  size: bigint,
  poolPrice: bigint,
  zeroForOne: boolean,
  bump: bigint = ONE,
): bigint => {
  const bumpedSize = size * bump
  return zeroForOne ? bumpedSize / poolPrice : bumpedSize * poolPrice
}

export const getMintParamsWithSlippage = (
  mintParams: MintQuoteParams | null,
  mintQuote: MintQuote | null,
  zeroForOne: boolean,
  poolPrice: bigint | undefined,
  maxSlippage: string,
): MintQuoteParams | null => {
  if (!mintQuote || !mintParams || !poolPrice) {
    return null
  }

  const rawSize = BigInt(mintQuote.size.toString())
  const marginalPoolPrice = BigInt(poolPrice)

  // Calculate size minimum with slippage
  const bumpedSizeMinimum = calculateSlippageAdjustedValue({
    value: rawSize,
    slippagePercent: maxSlippage,
  })

  if (!bumpedSizeMinimum || !ONE) {
    return null
  }

  const sizeMinimum = Math.floor(convertToDecimal(bumpedSizeMinimum))

  // Calculate debt maximum with slippage
  const debtWithoutSlippage = calculateDebtWithoutSlippage(
    rawSize,
    marginalPoolPrice,
    zeroForOne,
    ONE,
  )

  const bumpedDebtMaximum = calculateSlippageAdjustedValue({
    value: debtWithoutSlippage,
    slippagePercent: maxSlippage,
    isDebt: true,
  })

  if (!bumpedDebtMaximum) {
    return null
  }

  const debtDivisor = zeroForOne ? ONE : ONE * ONE * ONE
  const debtMaximum = Math.ceil(convertToDecimal(bumpedDebtMaximum, debtDivisor))

  return {
    ...mintParams,
    sizeMinimum: BigInt(sizeMinimum),
    debtMaximum: BigInt(debtMaximum),
  }
}
