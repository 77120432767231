import { simulateContract, waitForTransactionReceipt, writeContract } from "@wagmi/core"
import { Address } from "viem"
import { wagmiConfig } from "src/wagmiConfig"
import { LbpExactInputSingleParams } from "src/types"
import marginalV1LBRouterAbi from "src/constants/abis/MarginalV1LBRouter.json"
import { parseEther } from "ethers"

export const lbpExactInputSingle = async (
  chainId: number,
  amount: string,
  params: LbpExactInputSingleParams | null,
  routerAddress: Address,
  isGasToken: boolean,
) => {
  const { request } = await simulateContract(wagmiConfig, {
    chainId,
    address: routerAddress,
    abi: marginalV1LBRouterAbi,
    functionName: "exactInputSingle",
    args: [params],
    value: isGasToken ? parseEther(amount) : undefined,
  })

  const hash = await writeContract(wagmiConfig, request)

  const receipt = await waitForTransactionReceipt(wagmiConfig, { hash })

  return receipt
}
