import _, { isString } from "lodash"
import { useEffect, useMemo, useState, useCallback } from "react"
import { Address } from "viem"
import { useParams } from "react-router-dom"
import { InputContainer } from "src/components/ui/InputContainer"
import { AddLiquidityInput } from "src/pages/addLiquidity/components/AddLiquidityInput"
import { useAddLiquidityState } from "src/state/addLiquidity/hooks"
import { useAddLiquidityActionHandlers } from "src/state/addLiquidity/hooks"
import { useNavigateRoutes } from "src/hooks/useNavigateRoutes"
import { usePoolsData, getPoolDataByAddress } from "src/hooks/usePoolsData"
import { isBlank } from "src/utils/isBlank"
import { formatStringToBigInt } from "src/utils/formatStringToBigInt"
import { useAccount } from "wagmi"
import { V1_QUOTER_ADDRESS } from "src/constants/addresses"
import { ListRow } from "src/components/ui/ListRow"
import { useErc20TokenBalances } from "src/hooks/useErc20TokenBalances"
import { ConfirmAddLiquidityButton } from "src/pages/addLiquidity/components/ConfirmAddLiquidityButton"
import { useAddLiquidityStatus } from "src/state/addLiquidity/hooks"
import { V1_ROUTER_ADDRESS } from "src/constants/addresses"
import { maxUint256 } from "viem"
import { waitForTransactionReceipt } from "@wagmi/core"
import { wagmiConfig } from "src/wagmiConfig"
import { getTransactionError } from "src/utils/getTransactionError"
import { useErc20TokenAllowance } from "src/hooks/useErc20TokenAllowance"
import { approveErc20Token } from "src/utils/actions/approveErc20Token"
import { formatBigIntToString } from "src/utils/formatBigIntToString"
import { isOnlyZeroes } from "src/utils/isOnlyZeroes"
import { deriveOppositeAddLiquidityInputFromSingleInput } from "src/pages/addLiquidity/helpers/deriveOppositeAddLiquidityInputFromSingleInput"
import { formatNumberAmount } from "src/utils/formatNumberAmount"
import { useErc20TokenSymbol } from "src/hooks/useErc20TokenSymbol"
import { TransactionStatusModal } from "src/components/TransactionStatusModal"
import { useSettingsToggle } from "src/hooks/useSettingsToggle"
import { useSettingsState } from "src/state/settings/hooks"
import { SlippageButton } from "src/components/Settings/SlippageButton"
import { AssetPairPriceRatio } from "../../components/AssetPairPriceRatio"
import { useLivePoolPrices } from "src/pages/trade/hooks/useLivePoolPrices"
import { useGetCurrentBlockTimestamp } from "src/hooks/useGetCurrentBlockTimestamp"
import { useInversePrice } from "src/hooks/useInversePrice"
import { Card } from "src/components/Card"
import { CaretLeftIcon } from "src/components/Icons/CaretLeftIcon"
import { isWrappedGasToken } from "src/utils/isWrappedGasToken"
import { useNativeTokenBalance } from "src/hooks/useNativeTokenBalance"
import { GasSelect } from "src/components/GasSelect"
import { useNetworkChangeRedirect } from "src/hooks/useNetworkChangeRedirect"
import { addLiquidity } from "src/utils/actions/addLiquidity"
import { validateAddLiquidityParams } from "src/pages/addLiquidity/helpers/validateAddLiquidityParams"
import { constructAddLiquidityQuoteParams } from "src/pages/addLiquidity/helpers/constructAddLiquidityQuoteParams"
import { zeroAddress } from "viem"
import { Token } from "src/types"
import { resetAddLiquidityQuote } from "src/state/addLiquidity/reducer"
import { fetchAddLiquidityQuote } from "src/state/addLiquidity/hooks"
import { deriveAddLiquidityQuoteData } from "./helpers/deriveAddLiquidityQuoteData"
import { useAppDispatch } from "src/state/hooks"
import { useTransactionModal } from "src/pages/trade/hooks/useTransactionModal"
import { useUserPoolBalance } from "../pool/hooks/useUserPoolBalance"
import { useCurrentPoolsState } from "../pools/hooks/useCurrentPoolsState"
import { convertMaintenanceToLeverage } from "src/utils/conversions/convertMaintenanceToLeverage"
import { PoolData } from "src/types"
import { useApplicationState } from "src/state/application/hooks"
import { getTokenByAddress } from "src/constants/tokenList"
import { DoubleTokenLogo, TokenBadge } from "src/components/TokenBadge"
import { GAS_TOKEN_MAP } from "src/constants/tokens"

const AddLiquidity = () => {
  const dispatch = useAppDispatch()
  const { chainId } = useApplicationState()
  const { address } = useAccount()
  const { poolAddress } = useParams()
  const { pools, poolsDataByAddress } = usePoolsData()
  const { tokenA, tokenB, inputValueA, inputValueB, addLiquidityQuote } =
    useAddLiquidityState()
  const {
    onUserInputA,
    onUserInputB,
    onSelectTokenA,
    onSelectTokenB,
    onResetAddLiquidityInput,
  } = useAddLiquidityActionHandlers()
  const { showSettings, onOpenSettings, onCloseSettings } = useSettingsToggle()
  const { maxSlippage, transactionDeadline } = useSettingsState()
  const { isValidInputs, isValidTokens } = useAddLiquidityStatus()
  const [useInverse, onToggleInverse] = useInversePrice()
  const currentBlockTimestamp = useGetCurrentBlockTimestamp(chainId)
  const { onNavigateToPool, onNavigateToPools } = useNavigateRoutes()
  const handleReturnToPool = (poolAddress: string) => onNavigateToPool(poolAddress)
  useNetworkChangeRedirect(onNavigateToPools)
  const pool = getPoolDataByAddress(poolAddress as string, poolsDataByAddress)
  const lpTokenSymbol = useErc20TokenSymbol(poolAddress as Address)
  const { poolStates } = useCurrentPoolsState([pool?.poolAddress as Address])
  const poolState = poolStates[pool?.poolAddress as Address]
  const { balances: poolBalances, refetch: fetchUserPoolBalance } = useUserPoolBalance(
    pools,
    address,
  )
  const userPoolBalance = poolBalances?.find(
    (balance) => balance.pool?.poolAddress === poolAddress,
  )
  const GAS_TOKEN = GAS_TOKEN_MAP[chainId]
  const token0 = getTokenByAddress(pool?.token0?.address, chainId) ?? pool?.token0
  const token1 = getTokenByAddress(pool?.token1?.address, chainId) ?? pool?.token1
  const [useGasToken, setUseGasToken] = useState<boolean>(true)
  const isTokenAWrappedGasToken = isWrappedGasToken(tokenA, chainId)
  const isTokenBWrappedGasToken = isWrappedGasToken(tokenB, chainId)
  const leverageMax = convertMaintenanceToLeverage(pool?.maintenance as bigint)

  const inputValueGas = useGasToken
    ? (isTokenAWrappedGasToken && inputValueA) ||
      (isTokenBWrappedGasToken && inputValueB) ||
      undefined
    : undefined

  const { parsedAllowance: parsedTokenAAllowance, fetchAllowance: fetchTokenAAllowance } =
    useErc20TokenAllowance(
      tokenA?.address as Address,
      Number(tokenA?.decimals),
      address,
      V1_ROUTER_ADDRESS[chainId],
      chainId,
    )

  const { parsedAllowance: parsedTokenBAllowance, fetchAllowance: fetchTokenBAllowance } =
    useErc20TokenAllowance(
      tokenB?.address as Address,
      Number(tokenB?.decimals),
      address,
      V1_ROUTER_ADDRESS[chainId],
      chainId,
    )

  const fetchTokenAllowances = useCallback(async () => {
    await fetchTokenAAllowance()
    await fetchTokenBAllowance()
  }, [fetchTokenAAllowance, fetchTokenBAllowance])

  const isTokenAApproved = useMemo(() => {
    if (useGasToken && isTokenAWrappedGasToken) return true
    if (_.isUndefined(parsedTokenAAllowance)) return false

    return parseFloat(inputValueA) <= parseFloat(parsedTokenAAllowance)
  }, [parsedTokenAAllowance, inputValueA, useGasToken, isTokenAWrappedGasToken])

  const isTokenBApproved = useMemo(() => {
    if (useGasToken && isTokenBWrappedGasToken) return true
    if (_.isUndefined(parsedTokenBAllowance)) return false

    return parseFloat(inputValueB) <= parseFloat(parsedTokenBAllowance)
  }, [parsedTokenBAllowance, inputValueB, useGasToken, isTokenBWrappedGasToken])

  const {
    parsedBalance: parsedGasBalance,
    shortenedParsedBalance: shortenedParsedGasBalance,
  } = useNativeTokenBalance(address, chainId)

  const { balances: tokenBalances, refetch: fetchTokenBalances } = useErc20TokenBalances(
    [token0, token1],
    address,
  )

  const {
    shortenedParsedBalance: shortenedParsedTokenABalance,
    parsedBalance: parsedTokenABalance,
  } = _.find(tokenBalances, { token: tokenA }) || {}

  const {
    shortenedParsedBalance: shortenedParsedTokenBBalance,
    parsedBalance: parsedTokenBBalance,
  } = _.find(tokenBalances, { token: tokenB }) || {}

  const isBalanceSufficient = useMemo(() => {
    if (!parsedTokenABalance || !parsedTokenBBalance || !parsedGasBalance) return true

    const parsedA = parseFloat(inputValueA || "0")
    const parsedB = parseFloat(inputValueB || "0")

    if (useGasToken && (isTokenAWrappedGasToken || isTokenBWrappedGasToken)) {
      if (isTokenAWrappedGasToken) {
        return (
          parsedA <= parseFloat(parsedGasBalance) &&
          parsedB <= parseFloat(parsedTokenBBalance)
        )
      } else {
        return (
          parsedA <= parseFloat(parsedTokenABalance) &&
          parsedB <= parseFloat(parsedGasBalance)
        )
      }
    } else {
      return (
        parsedA <= parseFloat(parsedTokenABalance) &&
        parsedB <= parseFloat(parsedTokenBBalance)
      )
    }
  }, [
    inputValueA,
    inputValueB,
    parsedTokenABalance,
    parsedTokenBBalance,
    parsedGasBalance,
    isTokenAWrappedGasToken,
    isTokenBWrappedGasToken,
    useGasToken,
  ])

  const formattedInputA = !isBlank(inputValueA)
    ? formatStringToBigInt(inputValueA, tokenA?.decimals)
    : 0n

  const formattedInputB = !isBlank(inputValueB)
    ? formatStringToBigInt(inputValueB, tokenB?.decimals)
    : 0n

  const { poolPrice } = useLivePoolPrices({
    chainId,
    selectedPool: pool,
    zeroForOne: false,
    useInverse,
    quoteToken: null,
  })

  const addLiquidityParams = validateAddLiquidityParams(
    pool,
    tokenA,
    tokenB,
    inputValueA,
    inputValueB,
    address ?? zeroAddress,
  )
    ? constructAddLiquidityQuoteParams(
        pool as PoolData,
        tokenA as Token,
        tokenB as Token,
        inputValueA,
        inputValueB,
        address ?? zeroAddress,
        transactionDeadline,
        currentBlockTimestamp,
      )
    : null

  useEffect(() => {
    if (tokenA && tokenB && address) {
      ;(async () => {
        await fetchTokenAllowances()
      })()
    }
  }, [chainId, tokenA, tokenB, address, fetchTokenAllowances])

  useEffect(() => {
    onResetAddLiquidityInput()
  }, [chainId, onResetAddLiquidityInput])

  useEffect(() => {
    if (token0 && tokenA !== token0) {
      onSelectTokenA(token0)
    }
    if (token1 && tokenB !== token1) {
      onSelectTokenB(token1)
    }
  }, [token0, token1, tokenA, tokenB, onSelectTokenA, onSelectTokenB])

  useEffect(() => {
    if (!addLiquidityParams || !inputValueA || !inputValueB) {
      dispatch(resetAddLiquidityQuote())
      return
    }

    dispatch(
      fetchAddLiquidityQuote({
        chainId,
        quoterAddress: V1_QUOTER_ADDRESS[chainId],
        params: addLiquidityParams,
      }),
    )
  }, [dispatch, chainId, inputValueA, inputValueB]) // eslint-disable-line react-hooks/exhaustive-deps

  const { shares, derivedAddLiquidityParamsWithSlippage } = deriveAddLiquidityQuoteData(
    addLiquidityQuote,
    token0,
    token1,
    pool?.decimals,
    maxSlippage,
    addLiquidityParams,
  )

  const {
    state: {
      showConfirm,
      isPendingWallet,
      isPendingApprove,
      isPendingTx,
      isTxSubmitted,
      txHash,
      txError,
    },
    openConfirmModal,
    closeConfirmModal,
    resetState: resetTransactionState,
    setPendingWallet,
    setPendingApprove,
    setTxSubmitted,
  } = useTransactionModal()

  const onSuccessReset = () => {
    resetTransactionState()
    onResetAddLiquidityInput()
    onUserInputA("")
    onUserInputB("")
  }

  const approveToken = async (
    amount: bigint | undefined,
    spenderAddress: Address,
    tokenAddress: Address,
  ) => {
    if (!amount) {
      throw new Error("Require amount to approve")
    }
    if (!spenderAddress) {
      throw new Error("Require spender address to approve")
    }
    if (!tokenAddress) {
      throw new Error("Require token address to approve")
    }
    try {
      openConfirmModal()
      setPendingWallet(true)
      const txHash = await approveErc20Token({
        chainId,
        amount: maxUint256,
        spenderAddress,
        tokenAddress,
      })
      setPendingApprove(true)
      await waitForTransactionReceipt(wagmiConfig, {
        hash: txHash,
      })
      setPendingApprove(false)
      setTxSubmitted(true, txHash)
      fetchTokenAllowances()
    } catch (error) {
      console.error("Error approving token: ", error)
    }
  }

  const handleAddLiquidityCallback = async () => {
    try {
      if (!derivedAddLiquidityParamsWithSlippage) {
        return new Error("Missing required parameters for add liquidity")
      }
      openConfirmModal()
      setPendingWallet(true)
      const transaction = await addLiquidity(
        derivedAddLiquidityParamsWithSlippage,
        inputValueGas,
        chainId,
      )
      setPendingWallet(false)
      setTxSubmitted(true, transaction.transactionHash)
      fetchUserPoolBalance()
      fetchTokenBalances()
      return transaction.transactionHash
    } catch (error) {
      console.error("Error executing add liquidity: ", error)
      if (getTransactionError(error) === "Wallet rejected transaction.") {
        resetTransactionState()
      }
    }
  }

  const handleUserInputA = (value: string) => {
    onUserInputA(value)

    if (!tokenA || !tokenB || !poolState) return
    if (!isString(value)) return

    if (!isOnlyZeroes(value)) {
      const inputValueA_BI = formatStringToBigInt(value, tokenA?.decimals)
      if (!inputValueA_BI) return

      const derivedAmount1 = deriveOppositeAddLiquidityInputFromSingleInput(
        inputValueA_BI.toString(),
        true,
        poolState?.sqrtPriceX96.toString(),
      )

      const derivedInputValueB = derivedAmount1
        ? formatBigIntToString(BigInt(derivedAmount1), tokenB?.decimals)
        : null

      onUserInputB(derivedInputValueB ?? "")
    } else if (isOnlyZeroes(value) && value.length === 0) {
      onUserInputA("")
      onUserInputB("")
    }
  }

  const handleUserInputB = (value: string) => {
    onUserInputB(value)

    if (!tokenA || !tokenB || !poolState) return
    if (!isString(value)) return

    if (!isOnlyZeroes(value)) {
      const inputValueB_BI = formatStringToBigInt(value, tokenB?.decimals)
      if (!inputValueB_BI) return

      const derivedAmount0 = deriveOppositeAddLiquidityInputFromSingleInput(
        inputValueB_BI.toString(),
        false,
        poolState?.sqrtPriceX96.toString(),
      )

      const derivedInputValueA = derivedAmount0
        ? formatBigIntToString(BigInt(derivedAmount0), tokenA?.decimals)
        : null

      onUserInputA(derivedInputValueA ?? "")
    } else if (isOnlyZeroes(value) && value.length === 0) {
      onUserInputA("")
      onUserInputB("")
    }
  }
  return (
    <Card>
      <div className="flex justify-between">
        <div
          onClick={() => handleReturnToPool(poolAddress as string)}
          className="flex cursor-pointer items-center justify-start space-x-1 text-marginalGray-200"
        >
          <CaretLeftIcon />
          <span className="text-sm text-marginalGray-200">Back to Pool</span>
        </div>
      </div>

      <div className="relative mx-auto mt-12 w-full max-w-[343px] shadow-outerBlack sm:max-w-[440px]">
        <div className="rounded-t-3xl border border-marginalGray-800 bg-marginalGray-900">
          <div className="flex items-center justify-between p-4">
            <div className="relative text-lg text-marginalGray-200 md:text-xl md:leading-6">
              Add Liquidity
            </div>
            <SlippageButton
              maxSlippage={maxSlippage}
              showSettings={showSettings}
              onClose={onCloseSettings}
              onOpen={onOpenSettings}
            />
          </div>

          <div className="px-4 pb-4 pt-0">
            <div className="rounded-xl border border-marginalGray-800 bg-marginalGray-950 p-4">
              <div className="flex items-center space-x-2">
                {token0 && token1 && (
                  <DoubleTokenLogo token0={token0} token1={token1} size={8} />
                )}
                <div className="flex flex-col overflow-x-hidden">
                  <div className="flex items-center space-x-1 text-lg text-marginalGray-200">
                    <div>{token0?.symbol}</div>
                    <div className="my-auto">/</div>
                    <div>{token1?.symbol}</div>
                    <div className="ml-3">{leverageMax}x</div>
                  </div>

                  <div className="flex flex-nowrap items-center text-xs text-marginalGray-600 md:text-sm">
                    <div>{token0?.name}</div>

                    <div className="my-auto px-0.5">∙</div>
                    <div>{token1?.name}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="relative">
          <div className="space-y-2 rounded-b-3xl border border-t-0 border-marginalGray-800 bg-marginalGray-900 p-4">
            <InputContainer id="add-liquidity-input-a-container">
              <AddLiquidityInput inputValue={inputValueA} onChange={handleUserInputA} />
              <div className="flex flex-col items-end">
                <div className="mb-1.5 flex items-center space-x-1 text-xl">
                  {token0 && (
                    <TokenBadge
                      token={isTokenAWrappedGasToken && useGasToken ? GAS_TOKEN : token0}
                      size={6}
                    />
                  )}
                  {address && isTokenAWrappedGasToken && (
                    <>
                      <GasSelect
                        useGas={useGasToken}
                        setUseGas={setUseGasToken}
                        chainId={chainId}
                      />
                    </>
                  )}
                </div>

                {isTokenAWrappedGasToken && useGasToken ? (
                  <div className="flex space-x-2 text-sm">
                    <div className="whitespace-nowrap text-marginalGray-600">
                      Balance: {shortenedParsedGasBalance}
                    </div>
                    <div
                      className="cursor-pointer rounded-sm bg-[#4C2D1E] px-0.5 text-marginalOrange-500"
                      onClick={() =>
                        handleUserInputA(parsedGasBalance ? parsedGasBalance : "")
                      }
                    >
                      Max
                    </div>
                  </div>
                ) : (
                  <div className="flex space-x-2 text-sm">
                    <div className="whitespace-nowrap text-marginalGray-600">
                      Balance:{" "}
                      {shortenedParsedTokenABalance
                        ? parseFloat(shortenedParsedTokenABalance).toFixed(4).trim()
                        : "0.0"}
                    </div>
                    <div
                      className="cursor-pointer rounded-sm bg-[#4C2D1E] px-0.5 text-marginalOrange-500"
                      onClick={() =>
                        handleUserInputA(parsedTokenABalance ? parsedTokenABalance : "")
                      }
                    >
                      Max
                    </div>
                  </div>
                )}
              </div>
            </InputContainer>

            <InputContainer id="add-liquidity-input-b-container">
              <AddLiquidityInput inputValue={inputValueB} onChange={handleUserInputB} />
              <div className="flex flex-col items-end">
                <div className="mb-1.5 flex items-center space-x-1 text-xl">
                  {token1 && (
                    <TokenBadge
                      token={isTokenBWrappedGasToken && useGasToken ? GAS_TOKEN : token1}
                      size={6}
                    />
                  )}
                  {address && isTokenBWrappedGasToken && (
                    <>
                      <GasSelect
                        useGas={useGasToken}
                        setUseGas={setUseGasToken}
                        chainId={chainId}
                      />
                    </>
                  )}
                </div>

                {isTokenBWrappedGasToken && useGasToken ? (
                  <div className="flex space-x-2 text-sm">
                    <div className="whitespace-nowrap text-marginalGray-600">
                      Balance: {shortenedParsedGasBalance}
                    </div>
                    <div
                      className="cursor-pointer rounded-sm bg-[#4C2D1E] px-0.5 text-marginalOrange-500"
                      onClick={() =>
                        handleUserInputB(parsedGasBalance ? parsedGasBalance : "")
                      }
                    >
                      Max
                    </div>
                  </div>
                ) : (
                  <div className="flex space-x-2 text-sm">
                    <div className="whitespace-nowrap text-marginalGray-600">
                      Balance:{" "}
                      {shortenedParsedTokenBBalance
                        ? parseFloat(shortenedParsedTokenBBalance).toFixed(4)
                        : "0.0"}
                    </div>
                    <div
                      className="cursor-pointer rounded-sm bg-[#4C2D1E] px-0.5 text-marginalOrange-500"
                      onClick={() =>
                        handleUserInputB(parsedTokenBBalance ? parsedTokenBBalance : "")
                      }
                    >
                      Max
                    </div>
                  </div>
                )}
              </div>
            </InputContainer>

            <div className="space-y-2 py-2 text-sm text-marginalGray-600">
              <ListRow
                item="Shares"
                value={
                  <div className="text-marginalGray-200">
                    {shares.formatted ?? "-"} {lpTokenSymbol}
                  </div>
                }
              />

              <ListRow
                item="Current Shares"
                value={
                  <div className="text-marginalGray-200">
                    {userPoolBalance?.parsedBalance ?? "-"} {lpTokenSymbol}
                  </div>
                }
              />

              <ListRow
                item="Rate"
                value={
                  <AssetPairPriceRatio
                    token0={token0}
                    token1={token1}
                    price={formatNumberAmount(poolPrice, true)}
                    useInverse={useInverse}
                    onToggleInverse={onToggleInverse}
                  />
                }
              />
            </div>
            <ConfirmAddLiquidityButton
              chainId={chainId}
              isInputValid={isValidInputs}
              isTokensValid={isValidTokens}
              isTokenAApproved={isTokenAApproved}
              isTokenBApproved={isTokenBApproved}
              isPendingWallet={isPendingWallet}
              isPendingApprove={isPendingApprove}
              isBalanceSufficient={isBalanceSufficient}
              onApproveTokenA={() =>
                approveToken(
                  formattedInputA,
                  V1_ROUTER_ADDRESS[chainId],
                  tokenA?.address as Address,
                )
              }
              onApproveTokenB={() =>
                approveToken(
                  formattedInputB,
                  V1_ROUTER_ADDRESS[chainId],
                  tokenB?.address as Address,
                )
              }
              tokenA={tokenA}
              tokenB={tokenB}
              onConfirm={openConfirmModal}
              addLiquidityCallback={handleAddLiquidityCallback}
              error={null}
            />
          </div>
        </div>
      </div>

      <TransactionStatusModal
        chainId={chainId}
        open={showConfirm}
        onOpen={openConfirmModal}
        onClose={closeConfirmModal}
        onReset={onSuccessReset}
        onCallback={handleAddLiquidityCallback}
        isPendingWallet={isPendingWallet}
        isPendingApprove={isPendingApprove}
        isPendingTx={isPendingTx}
        isTxSubmitted={isTxSubmitted}
        txHash={txHash}
        txError={txError}
        hasConfirmModal={false}
        onSuccessText="Transaction Success"
      />
    </Card>
  )
}

export default AddLiquidity
