import { Address, getAddress } from "viem"
import { Token, UniswapPool } from "src/types"
import { CreatePoolParams } from "src/utils/actions/createAndInitializePoolIfNecessary"

export const constructCreatePoolParams = (
  token0: Token,
  token1: Token,
  amount0: string,
  amount1: string,
  uniswapPool: UniswapPool,
  leverage: { leverage: number; maintenance: number },
  recipient: Address,
  transactionDeadline: string,
  currentBlockTimestamp: bigint,
  maxSlippage: string,
): CreatePoolParams => {
  let _token0
  let _token1
  let _amount0
  let _amount1

  if (getAddress(uniswapPool.token0) === getAddress(token0.address)) {
    _token0 = token0
    _token1 = token1
    _amount0 = amount0
    _amount1 = amount1
  } else {
    _token0 = token1
    _token1 = token0
    _amount0 = amount1
    _amount1 = amount0
  }

  return {
    token0: _token0,
    token1: _token1,
    maintenance: leverage.maintenance,
    uniswapPool,
    recipient,
    amount0: _amount0,
    amount1: _amount1,
    maxSlippage,
    transactionDeadline,
    currentBlockTimestamp,
  }
}
