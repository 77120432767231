import { Token } from "src/types"
import { isAddress } from "viem"

export const validateIgniteQuoteParams = (
  token0: Token | undefined | null,
  token1: Token | undefined | null,
  maintenance: string | undefined,
  oracle: string | undefined,
  tokenId: string | undefined | null,
  amountOutMinimum: bigint | undefined,
  recipient: string | undefined,
): boolean => {
  if (!token0 || !token1 || !maintenance || !oracle || !tokenId || !recipient) {
    return false
  }
  if (amountOutMinimum === undefined) {
    return false
  }
  if (!token0.address || !token1.address) {
    return false
  }

  if (!isAddress(token0.address) || !isAddress(token1.address)) {
    return false
  }

  if (!isAddress(oracle)) {
    return false
  }

  if (!isAddress(recipient)) {
    return false
  }

  return true
}
