import { Routes as BrowserRoutes, Route } from "react-router-dom"
import { Trade } from "src/pages/trade/Trade"
import { Swap } from "src/pages/swap/Swap"
import { Pools } from "src/pages/pools/Pools"
import AddLiquidity from "src/pages/addLiquidity/AddLiquidity"
import RemoveLiquidity from "src/pages/removeLiquidity/RemoveLiquidity"
import Pool from "src/pages/pool/Pool"
import ManagePosition from "src/pages/position/managePosition/ManagePosition"
import { Close } from "src/pages/position/managePosition/Close"
import { Positions } from "src/pages/positions/Positions"
import { Position } from "src/pages/position/Position"
import { Stake } from "src/pages/stake/Stake"
import Unstake from "src/pages/unstake/Unstake"
import CreateNewPool from "src/pages/createPool/CreateNewPool"
import { Layout } from "src/Layout"
import { Launch } from "src/pages/lbp/Launch"
import { LaunchProject } from "src/pages/lbp/LaunchProject"

export default function AppRoutes() {
  return (
    <Layout>
      <BrowserRoutes>
        <Route path="/launch" element={<Launch />} />
        <Route path="/launch/:projectAddress" element={<LaunchProject />} />
        <Route path="positions/position/close/:indexedId" element={<Close />} />
        <Route
          path="positions/position/manage/:positionKey"
          element={<ManagePosition />}
        />
        <Route path="/pools/unstake/:poolAddress" element={<Unstake />} />
        <Route path="/pools/stake/:poolAddress" element={<Stake />} />
        <Route path="/pools/:poolAddress" element={<Pool />} />
        <Route
          path="/pools/liquidity/remove/:poolAddress"
          element={<RemoveLiquidity />}
        />
        <Route path="/pools/liquidity/add/:poolAddress" element={<AddLiquidity />} />
        <Route path="/positions/position/:positionKey" element={<Position />} />
        <Route path="/positions" element={<Positions />} />
        <Route path="/pools/create" element={<CreateNewPool />} />
        <Route path="/pools" element={<Pools />} />
        <Route path="/swap" element={<Swap />} />
        <Route path="/" element={<Trade />} />
      </BrowserRoutes>
    </Layout>
  )
}
