import { ComponentProps } from "react"

export const ArrowLeftIcon = (props: ComponentProps<"svg">) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.21897 8.66657L8.79497 12.2426L7.85217 13.1854L2.66671 7.99991L7.85217 2.81445L8.79497 3.75727L5.21897 7.33324L13.3334 7.33324L13.3334 8.66657L5.21897 8.66657Z"
      fill="#BEBEBE"
    />
  </svg>
)
