import { useCallback } from "react"
import { AppState } from "src/state"
import { useAppDispatch, useAppSelector } from "src/state/hooks"
import {
  setAmount0,
  setAmount1,
  setToken0,
  setToken1,
  resetCreatePoolState,
  setUniswapPool,
  setLeverage,
} from "./reducer"
import { Token, UniswapPool } from "src/types"

export const useCreatePoolState = (): AppState["createPool"] => {
  return useAppSelector((state) => state.createPool)
}

export function useCreatePoolActionHandlers(): {
  onSelectToken0: (token: Token | null) => void
  onSelectAmount0: (amount: string) => void
  onSelectToken1: (token: Token | null) => void
  onSelectAmount1: (amount: string) => void
  onSelectLeverage: (leverage: { leverage: number; maintenance: number }) => void
  onSelectUniswapPool: (pool: UniswapPool | null) => void
  onResetCreatePoolState: () => void
} {
  const dispatch = useAppDispatch()

  const onSelectToken0 = useCallback(
    (token: Token | null) => {
      dispatch(setToken0(token))
    },
    [dispatch],
  )

  const onSelectAmount0 = useCallback(
    (amount: string) => {
      dispatch(setAmount0(amount))
    },
    [dispatch],
  )
  const onSelectToken1 = useCallback(
    (token: Token | null) => {
      dispatch(setToken1(token))
    },
    [dispatch],
  )

  const onSelectAmount1 = useCallback(
    (amount: string) => {
      dispatch(setAmount1(amount))
    },
    [dispatch],
  )

  const onSelectUniswapPool = useCallback(
    (amount: UniswapPool | null) => {
      dispatch(setUniswapPool(amount))
    },
    [dispatch],
  )

  const onSelectLeverage = useCallback(
    (leverage: { leverage: number; maintenance: number } | null) => {
      dispatch(setLeverage(leverage))
    },
    [dispatch],
  )

  const onResetCreatePoolState = useCallback(() => {
    dispatch(resetCreatePoolState())
  }, [dispatch])

  return {
    onSelectToken0,
    onSelectAmount0,
    onSelectToken1,
    onSelectAmount1,
    onSelectUniswapPool,
    onSelectLeverage,
    onResetCreatePoolState,
  }
}
