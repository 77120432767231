import _ from "lodash"
import { useMemo, useEffect, useState } from "react"
import { useAccount } from "wagmi"
import { Address, zeroAddress, maxUint256 } from "viem"
import { useParams } from "react-router-dom"
import { waitForTransactionReceipt } from "@wagmi/core"
import { usePoolsData, getPoolDataByAddress } from "src/hooks/usePoolsData"
import { useNavigateRoutes } from "src/hooks/useNavigateRoutes"
import { InputContainer } from "src/components/ui/InputContainer"
import { V1_ROUTER_ADDRESS, V1_QUOTER_ADDRESS } from "src/constants/addresses"
import { useRemoveLiquidityState } from "src/state/removeLiquidity/hooks"
import { useErc20TokenBalances } from "src/hooks/useErc20TokenBalances"
import { Token, PoolData } from "src/types"
import { RemoveLiquidityInput } from "src/pages/removeLiquidity/components/RemoveLiquidityInput"
import {
  useRemoveLiquidityActionHandlers,
  useRemoveLiquidityStatus,
} from "src/state/removeLiquidity/hooks"
import { validateRemoveLiquidityParams } from "src/pages/removeLiquidity/helpers/validateRemoveLiquidityParams"
import { deriveRemoveLiquidityQuoteData } from "src/pages/removeLiquidity/helpers/deriveRemoveLiquidityQuoteData"
import { constructRemoveLiquidityParams } from "src/pages/removeLiquidity/helpers/constructRemoveLiquidityParams"
import { approveErc20Token } from "src/utils/actions/approveErc20Token"
import { wagmiConfig } from "src/wagmiConfig"
import { useErc20TokenAllowance } from "src/hooks/useErc20TokenAllowance"
import { getTransactionError } from "src/utils/getTransactionError"
import { formatStringToBigInt } from "src/utils/formatStringToBigInt"
import { isBlank } from "src/utils/isBlank"
import { trimTrailingZeroes } from "src/utils/trimTrailingZeroes"
import { RemoveLiquidityDetailList } from "src/pages/removeLiquidity/components/RemoveLiquidityDetailList"
import { ConfirmRemoveLiquidityButton } from "src/pages/removeLiquidity/components/ConfirmRemoveLiquidityButton"
import { useErc20TokenSymbol } from "src/hooks/useErc20TokenSymbol"
import { useSettingsToggle } from "src/hooks/useSettingsToggle"
import { useSettingsState } from "src/state/settings/hooks"
import { SlippageButton } from "src/components/Settings/SlippageButton"
import { PercentSlider } from "src/components/PercentSlider"
import { useGetCurrentBlockTimestamp } from "src/hooks/useGetCurrentBlockTimestamp"
import { Card } from "src/components/Card"
import { CaretLeftIcon } from "src/components/Icons/CaretLeftIcon"
import { useNetworkChangeRedirect } from "src/hooks/useNetworkChangeRedirect"
import { removeLiquidity } from "src/utils/actions/removeLiquidity"
import { useAppDispatch } from "src/state/hooks"
import {
  fetchRemoveLiquidityQuote,
  resetRemoveLiquidityQuote,
  resetRemoveLiquidityState,
} from "src/state/removeLiquidity/reducer"
import { useTransactionModal } from "src/pages/trade/hooks/useTransactionModal"
import { useTotalSupplyInPools } from "src/pages/pools/hooks/useTotalSupplyInPools"
import { useLiquidityLockedInPools } from "src/pages/pools/hooks/useLiquidityLockedInPools"
import { useUserPoolBalance } from "src/pages/pool/hooks/useUserPoolBalance"
import { useUserStakedPoolBalance } from "src/pages/pool/hooks/useUserStakedPoolBalance"
import { calculatePercentageOfTotal } from "src/utils/conversions/calculatePercentageOfTotal"
import { useApplicationState } from "src/state/application/hooks"
import { getTokenByAddress } from "src/constants/tokenList"
import { DoubleTokenLogo } from "src/components/TokenBadge"
import { TransactionStatusModal } from "src/components/TransactionStatusModal"

const RemoveLiquidity = () => {
  const dispatch = useAppDispatch()
  const { chainId } = useApplicationState()
  const { address } = useAccount()
  const { poolAddress } = useParams()
  const { poolsDataByAddress } = usePoolsData()
  const {
    tokenA,
    tokenB,
    inputValue,
    quote: removeLiquidityQuote,
    error: removeLiquidityError,
  } = useRemoveLiquidityState()
  const { onUserInput, onSelectTokenA, onSelectTokenB } =
    useRemoveLiquidityActionHandlers()
  const { showSettings, onOpenSettings, onCloseSettings } = useSettingsToggle()
  const { maxSlippage, transactionDeadline } = useSettingsState()
  const { isTokensValid, isInputValid } = useRemoveLiquidityStatus()
  const currentBlockTimestamp = useGetCurrentBlockTimestamp(chainId)
  const { onNavigateToPools, onNavigateToPool } = useNavigateRoutes()
  useNetworkChangeRedirect(onNavigateToPools)
  const lpTokenSymbol = useErc20TokenSymbol(poolAddress as Address)
  const pool = getPoolDataByAddress(poolAddress as string, poolsDataByAddress)

  const poolsTotalSupply = useTotalSupplyInPools([pool])
  const liquidityLockedInPools = useLiquidityLockedInPools([pool])
  const { balances } = useUserPoolBalance([pool], address)
  const { balances: userStakedBalance } = useUserStakedPoolBalance(
    [pool as PoolData],
    address,
  )

  const userLpBalance = balances?.[0]
  const userStakedLpBalance = userStakedBalance?.[0]
  const userTotalLpBalance = (
    parseFloat(userLpBalance?.parsedBalance ?? "0") +
    parseFloat(userStakedLpBalance?.parsedBalance ?? "0")
  ).toString()

  const poolLiquidityLocked = liquidityLockedInPools?.[0]
  const poolLiquidityTotal = poolsTotalSupply?.[0]

  const userSharePercentageOfTotal = calculatePercentageOfTotal({
    total: parseFloat(poolLiquidityTotal?.parsedTotalSupply ?? "0"),
    partial: parseFloat(userTotalLpBalance ?? "0"),
  })
  const poolUtilization = calculatePercentageOfTotal({
    total: parseFloat(poolLiquidityTotal?.parsedTotalSupply ?? "0"),
    partial: parseFloat(poolLiquidityLocked?.parsedLockedLiquidity ?? "0"),
  })

  const { balances: tokenBalances, refetch: fetchTokenBalances } = useErc20TokenBalances(
    [
      {
        address: poolAddress,
        name: "Marginal V1 LP Token",
        symbol: lpTokenSymbol ?? "MARGV1-LP",
        decimals: pool?.decimals ?? 18,
      },
    ] as Token[],
    address,
  )

  const {
    balance: lpTokenBalance,
    parsedBalance: parsedLpTokenBalance,
    shortenedParsedBalance: shortenedParsedLpTokenBalance,
  } = tokenBalances?.[0] || {}

  const [percentage, setPercentage] = useState<number>(0)
  const [percentError, setPercentError] = useState(false)

  const token0 = getTokenByAddress(pool?.token0?.address, chainId) ?? pool?.token0
  const token1 = getTokenByAddress(pool?.token1?.address, chainId) ?? pool?.token1

  useEffect(() => {
    if (token0 && tokenA !== token0) {
      onSelectTokenA(token0)
    }
    if (token1 && tokenB !== token1) {
      onSelectTokenB(token1)
    }
  }, [token0, token1, tokenA, tokenB, onSelectTokenA, onSelectTokenB])

  const formattedInput = !isBlank(inputValue)
    ? formatStringToBigInt(inputValue, pool?.decimals)
    : 0n

  const { parsedAllowance: parsedLpTokenAllowance, fetchAllowance } =
    useErc20TokenAllowance(
      poolAddress as Address,
      Number(pool?.decimals),
      address,
      V1_ROUTER_ADDRESS[chainId],
      chainId,
    )

  useEffect(() => {
    if (poolAddress && address) {
      ;(async () => {
        await fetchAllowance()
      })()
    }
  }, [chainId, poolAddress, address, fetchAllowance])

  const isLpTokenApproved = useMemo(() => {
    if (_.isUndefined(parsedLpTokenAllowance)) return false
    return parseFloat(inputValue) <= parseFloat(parsedLpTokenAllowance)
  }, [parsedLpTokenAllowance, inputValue])

  const selectedPool = {
    token0: pool?.token0 as Token,
    token1: pool?.token1 as Token,
    maintenance: pool?.maintenance,
    oracleAddress: pool?.oracleAddress,
  }

  const removeLiquidityParams = validateRemoveLiquidityParams(
    selectedPool,
    tokenA,
    tokenB,
    pool?.decimals,
    inputValue,
    address ?? zeroAddress,
  )
    ? constructRemoveLiquidityParams(
        inputValue,
        tokenA as Token,
        tokenB as Token,
        pool?.decimals as string,
        pool?.maintenance as bigint,
        pool?.oracleAddress as string,
        address ?? zeroAddress,
        transactionDeadline,
        currentBlockTimestamp,
      )
    : null

  useEffect(() => {
    if (!removeLiquidityParams || !inputValue) {
      dispatch(resetRemoveLiquidityQuote())
      return
    }

    dispatch(
      fetchRemoveLiquidityQuote({
        chainId,
        quoterAddress: V1_QUOTER_ADDRESS[chainId],
        params: removeLiquidityParams,
      }),
    )
  }, [dispatch, chainId, inputValue]) // eslint-disable-line react-hooks/exhaustive-deps

  const { derivedRemoveLiquidityParamsWithSlippage, token0Amount, token1Amount } =
    deriveRemoveLiquidityQuoteData(
      removeLiquidityQuote,
      token0,
      token1,
      pool?.decimals?.toString(),
      maxSlippage,
      removeLiquidityParams,
    )

  const {
    state: {
      showConfirm,
      isPendingWallet,
      isPendingApprove,
      isPendingTx,
      isTxSubmitted,
      txHash,
    },
    openConfirmModal,
    closeConfirmModal,
    resetState: resetTransactionState,
    setPendingWallet,
    setPendingApprove,
    setTxSubmitted,
  } = useTransactionModal()

  const onSuccessReset = () => {
    resetTransactionState()
    dispatch(resetRemoveLiquidityState())
    setPercentage(0)
  }

  const approveToken = async (
    amount: bigint | undefined,
    spenderAddress: Address,
    tokenAddress: Address,
  ) => {
    if (!amount) {
      throw new Error("Require amount to approve")
    }
    if (!spenderAddress) {
      throw new Error("Require spender address to approve")
    }
    if (!tokenAddress) {
      throw new Error("Require token address to approve")
    }
    try {
      openConfirmModal()
      setPendingWallet(true)
      const txHash = await approveErc20Token({
        chainId,
        amount: maxUint256,
        spenderAddress,
        tokenAddress,
      })
      setPendingApprove(true)
      await waitForTransactionReceipt(wagmiConfig, {
        hash: txHash,
      })
      setPendingApprove(false)
      setTxSubmitted(true, txHash)
      fetchAllowance()
      onSuccessReset()
    } catch (error) {
      if (getTransactionError(error) === "Wallet rejected transaction.") {
        resetTransactionState()
      } else {
        console.error("Error approving token: ", error)
      }
    }
  }

  const handleRemoveLiquidityCallback = async () => {
    try {
      if (!derivedRemoveLiquidityParamsWithSlippage) {
        return new Error("Missing required parameters for remove liquidity")
      }
      openConfirmModal()
      setPendingWallet(true)
      const transaction = await removeLiquidity(
        derivedRemoveLiquidityParamsWithSlippage,
        chainId,
      )
      setPendingWallet(false)
      setTxSubmitted(true, transaction.transactionHash)
      fetchTokenBalances()
      onSuccessReset()
      return transaction.transactionHash
    } catch (error) {
      if (getTransactionError(error) === "Wallet rejected transaction.") {
        resetTransactionState()
      } else {
        console.error("Error executing remove liquidity: ", error)
      }
    }
  }

  return (
    <Card>
      <div className="flex justify-between">
        <div
          onClick={() => {
            setPercentage(0)
            onUserInput("")
            onNavigateToPool(poolAddress as string)
          }}
          className="flex cursor-pointer items-center justify-start space-x-1 text-marginalGray-200"
        >
          <CaretLeftIcon />
          <span className="text-sm text-marginalGray-200">
            Back to Pool
          </span>
        </div>
      </div>

      <div className="relative mx-auto w-full max-w-[343px] shadow-outerBlack sm:max-w-[440px]">
        <div className="rounded-t-3xl border border-marginalGray-800 bg-marginalGray-900">
          <div className="flex items-center justify-between p-4">
            <div className="relative text-lg font-bold text-marginalGray-200 md:text-xl md:leading-6">
              Remove Liquidity
            </div>
            <SlippageButton
              maxSlippage={maxSlippage}
              showSettings={showSettings}
              onClose={onCloseSettings}
              onOpen={onOpenSettings}
            />
          </div>

          <div className="px-4 pb-4 pt-0">
            <div className="rounded-xl border border-marginalGray-800 bg-marginalGray-950 p-4">
              <div className="flex items-center space-x-2">
                {token0 && token1 && (
                  <DoubleTokenLogo token0={token0} token1={token1} size={8} />
                )}

                <div className="flex flex-col overflow-x-hidden">
                  <div className="flex items-center space-x-1 text-lg font-bold text-marginalGray-200">
                    <span>{token0?.symbol}</span>
                    <div className="my-auto">/</div>
                    <span>{token1?.symbol}</span>
                  </div>

                  <div className="flex flex-nowrap items-center text-xs text-marginalGray-600 md:text-sm">
                    <span>{token0?.name}</span>

                    <div className="my-auto px-0.5">∙</div>
                    <span>{token1?.name}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="relative">
          <div className="space-y-2 rounded-b-3xl border border-t-0 border-marginalGray-800 bg-marginalGray-900 p-4">
            <InputContainer id="remove-liquidity-input-container">
              <RemoveLiquidityInput
                setPercentage={setPercentage}
                inputValue={inputValue}
                totalLiquidity={parsedLpTokenBalance}
                onChange={onUserInput}
                setPercentError={setPercentError}
              />
              <div className="flex flex-col items-end">
                <div className="text-white">{lpTokenSymbol}</div>

                <div className="flex space-x-2 text-sm">
                  <div className="whitespace-nowrap text-marginalGray-600">
                    Balance: {trimTrailingZeroes(shortenedParsedLpTokenBalance)}
                  </div>
                  <div
                    className="cursor-pointer rounded-sm bg-[#4C2D1E] px-0.5 text-marginalOrange-500"
                    onClick={() => {
                      if (Number(parsedLpTokenBalance) > 0) {
                        onUserInput(parsedLpTokenBalance ? parsedLpTokenBalance : "")
                        setPercentage(100)
                      }
                    }}
                  >
                    Max
                  </div>
                </div>
              </div>
            </InputContainer>

            <PercentSlider
              userInput={inputValue}
              isInputValid={isInputValid}
              formattedBalance={parsedLpTokenBalance}
              margin={lpTokenBalance}
              decimals={Number(pool?.decimals)}
              onUserInput={onUserInput}
              selectedPercentage={percentage}
              setPercentage={setPercentage}
              setPercentError={setPercentError}
              percentError={percentError}
            />

            <RemoveLiquidityDetailList
              token0={token0}
              token1={token1}
              quotedToken0Amount={token0Amount.formatted}
              quotedToken1Amount={token1Amount.formatted}
              poolUtilizationPercentage={poolUtilization ?? 0}
              userOwnedPoolSharePercentage={userSharePercentageOfTotal ?? 0}
            />

            <ConfirmRemoveLiquidityButton
              chainId={chainId}
              isInputValid={isInputValid}
              isTokensValid={isTokensValid}
              isTokenApproved={isLpTokenApproved}
              isPendingWallet={isPendingWallet}
              isPendingApprove={isPendingApprove}
              onApprove={() =>
                approveToken(
                  formattedInput,
                  V1_ROUTER_ADDRESS[chainId],
                  poolAddress as Address,
                )
              }
              tokenA={tokenA}
              tokenB={tokenB}
              onConfirm={openConfirmModal}
              removeLiquidityCallback={handleRemoveLiquidityCallback}
              error={removeLiquidityError}
              disabled={Number(parsedLpTokenBalance) === 0}
            />
          </div>
        </div>

        <TransactionStatusModal
          chainId={chainId}
          open={showConfirm}
          onOpen={openConfirmModal}
          onClose={closeConfirmModal}
          onReset={onSuccessReset}
          onCallback={handleRemoveLiquidityCallback}
          isPendingWallet={isPendingWallet}
          isPendingApprove={isPendingApprove}
          isPendingTx={isPendingTx}
          isTxSubmitted={isTxSubmitted}
          txHash={txHash}
          // txError={txError}
          hasConfirmModal={false}
          onSuccessText="Transaction Success"
        />
      </div>
    </Card>
  )
}

export default RemoveLiquidity
