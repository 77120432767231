import { Link } from "react-router-dom"
import { ActionButton } from "../ActionButton"
import { ArrowOutgoingIcon } from "../Icons/ArrowOutgoingIcon"

export const ModalStatusView = ({
  icon,
  primaryText,
  secondaryText,
  buttonText,
  onClick,
  blockExplorerUrl,
}: {
  icon: JSX.Element
  primaryText: string
  secondaryText?: string
  buttonText?: string
  onClick?: () => void
  blockExplorerUrl?: string
}) => {
  return (
    <div className="flex w-full flex-col items-center space-y-4 p-4 pb-8">
      <div className="mx-auto flex h-20 w-20 items-center justify-center rounded-full border border-marginalGray-800 bg-marginalBlack">
        {icon}
      </div>

      <h1 className="text-xl text-marginalGray-200">{primaryText}</h1>

      {secondaryText && (
        <p className="animate-pulse text-sm text-marginalGray-600">
          {secondaryText}
        </p>
      )}

      {buttonText && (
        <div className="flex w-full flex-col space-y-2">
          {blockExplorerUrl && (
            <Link
              className="flex w-full items-center justify-center rounded-xl border border-transparent bg-marginalGray-800 py-3 text-sm text-marginalGray-200 hover:border-marginalGray-600 focus:outline-none focus:ring-1 focus:ring-marginalOrange-300 active:border-marginalGray-800"
              to={blockExplorerUrl}
              target="_blank"
            >
              View on Block Explorer
              <ArrowOutgoingIcon className="ml-2" />
            </Link>
          )}
          <ActionButton action={buttonText} onClick={onClick} />
        </div>
      )}
    </div>
  )
}
