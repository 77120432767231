import { TableHeaderCell } from "src/pages/pools/components"
import { PositionView } from "src/pages/positions//Positions"
import { PositionRowLoading } from "src/pages/positions/components/PositionRowLoading"
import { CircleStackIcon } from "src/components/Icons/CircleStackIcon"
import { CustomConnectButton } from "src/components/Header/CustomConnectButton"

interface PositionsTableProps {
  connectedAddress?: string
  positions: string[]
  view: PositionView
  children: React.ReactNode
  isLoading?: boolean
}

export const PositionsTable = ({
  connectedAddress,
  positions,
  view,
  children,
  isLoading,
}: PositionsTableProps) => {
  if (!connectedAddress || positions.length === 0) {
    return (
      <EmptyPositionsContainer
        isOpen={view === PositionView.OPEN}
        showConnect={!!connectedAddress}
      />
    )
  }
  return (
    <table className="w-full table-auto border-separate border-spacing-0 overflow-hidden bg-marginalGray-900">
      <thead className="hidden md:table-header-group">
        {view === PositionView.OPEN ? (
          <tr>
            <TableHeaderCell align="left">Token</TableHeaderCell>
            <TableHeaderCell align="left">Pool</TableHeaderCell>
            <TableHeaderCell>Size</TableHeaderCell>
            <TableHeaderCell>Collateral</TableHeaderCell>
            <TableHeaderCell>Health Factor</TableHeaderCell>
            <TableHeaderCell>Profit/Loss (ROI)</TableHeaderCell>
          </tr>
        ) : (
          <tr>
            <TableHeaderCell align="left">Token</TableHeaderCell>
            <TableHeaderCell align="left">Pool</TableHeaderCell>
            <TableHeaderCell>Status</TableHeaderCell>
            <TableHeaderCell>Closing Payout</TableHeaderCell>
            <TableHeaderCell>Profit/Loss (ROI)</TableHeaderCell>
          </tr>
        )}
      </thead>

      <tbody>
        {isLoading ? (
          Array.from({ length: 5 }).map((_, index) => (
            <PositionRowLoading key={index} isOpen={view === PositionView.OPEN} />
          ))
        ) : (
          <>{children}</>
        )}
      </tbody>
    </table>
  )
}

const EmptyPositionsContainer = ({
  isOpen,
  showConnect,
}: {
  isOpen: boolean
  showConnect: boolean
}) => {
  return (
    <tr className="flex h-96 w-full items-center justify-center bg-marginalGray-900 p-2 md:h-72 md:bg-none">
      <td className="flex h-full w-full flex-col items-center justify-center space-y-6 bg-marginalGray-950 md:bg-marginalBlack">
        <div className="flex max-w-52 flex-col items-center space-y-4">
          <CircleStackIcon />
          <span className="text-center text-sm text-marginalGray-400">
            Your {isOpen ? "open" : "closed"} positions will appear here.
          </span>
        </div>
        {!showConnect && <CustomConnectButton />}
      </td>
    </tr>
  )
}
