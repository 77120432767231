import { useEffect } from "react"
import { useAppDispatch } from "src/state/hooks"
import { useAccount } from "wagmi"
import { updateChainId } from "./reducer"
import { DEFAULT_CHAIN_ID } from "src/constants/chains/chains"

export default function Updater(): null {
  const dispatch = useAppDispatch()
  const { chain } = useAccount()

  useEffect(() => {
    if (chain?.id) {
      dispatch(updateChainId(chain?.id))
    } else {
      dispatch(updateChainId(DEFAULT_CHAIN_ID))
    }
  }, [dispatch, chain?.id])

  return null
}
