import { useWrappedGasUSDPrices } from "src/state/prices/hooks"
import { Token } from "src/types"
import { getValidAddress } from "src/utils/getValidAddress"
import { convertX96Price } from "src/utils/conversions/convertX96Price"
import { isListDefined } from "src/utils/isListDefined"

interface Props {
  chainId: number
  quoteToken: Token | null
  token0: Token | null | undefined
  token1: Token | null | undefined
  poolPriceX96: bigint
}

interface TokenUSDPrices {
  token0PriceInUSD: number | undefined
  token1PriceInUSD: number | undefined
}

/**
 * Hook to calculate USD prices for both tokens in a pool
 */
export const usePooledTokensUSDPrices = ({
  chainId,
  quoteToken,
  token0,
  token1,
  poolPriceX96,
}: Props): TokenUSDPrices => {
  const poolPrice = convertX96Price(poolPriceX96, token0, token1)
  const wrappedTokenPriceInUSD = useWrappedGasUSDPrices(chainId)

  // Early return if any required data is missing
  if (!isListDefined([poolPrice, wrappedTokenPriceInUSD, quoteToken, token0, token1])) {
    return {
      token0PriceInUSD: undefined,
      token1PriceInUSD: undefined,
    }
  }
  const isToken1QuoteToken =
    getValidAddress(token1?.address) === getValidAddress(quoteToken?.address)
  const poolPriceNumber = Number(poolPrice)

  return calculateTokenPrices(isToken1QuoteToken, wrappedTokenPriceInUSD, poolPriceNumber)
}

/**
 * Calculates USD prices for both tokens based on quote token position
 */
const calculateTokenPrices = (
  isToken1QuoteToken: boolean,
  wrappedTokenPriceInUSD: number,
  poolPrice: number,
): TokenUSDPrices => {
  if (isToken1QuoteToken) {
    return {
      token0PriceInUSD: wrappedTokenPriceInUSD * poolPrice,
      token1PriceInUSD: wrappedTokenPriceInUSD,
    }
  }

  return {
    token0PriceInUSD: wrappedTokenPriceInUSD,
    token1PriceInUSD: wrappedTokenPriceInUSD / poolPrice,
  }
}
