import { useState, useCallback, useEffect } from "react"
import { useErc20TokenQuery } from "src/hooks/useTokenQuery"
import { Token } from "src/types"
import { isAddress } from "viem"
import { Modal } from "src/components/ui/Modal"
import { TokenSelector } from "src/components/ui/TokenSelector"
import { SearchInput } from "src/components/ui/SearchInput"

interface TokenSelectorQueryModalProps {
  chainId: number
  isOpen: boolean
  selectedToken: Token | null
  tokenOptions: Token[]
  onSelect: (token: Token) => void
  onClose: () => void
}

export const TokenSelectorQueryModal = ({
  chainId,
  isOpen,
  selectedToken,
  tokenOptions,
  onSelect,
  onClose,
}: TokenSelectorQueryModalProps) => {
  const [filteredTokens, setFilteredTokens] = useState<Token[]>(tokenOptions)

  const handleFilteredTokens = useCallback((tokens: Token[]) => {
    setFilteredTokens(tokens)
  }, [])

  // Only reset filtered tokens when modal opens
  useEffect(() => {
    if (isOpen) {
      setFilteredTokens(tokenOptions)
    }
  }, [isOpen, tokenOptions])

  // Handle modal close
  const handleClose = useCallback(() => {
    onClose()
  }, [onClose])

  return (
    <Modal header="Select Token" isOpen={isOpen} onClose={handleClose}>
      <TokenSearchAndQuery
        tokenOptions={tokenOptions}
        onFilteredTokens={handleFilteredTokens}
        chainId={chainId}
      />
      <TokenSelector
        tokenOptions={filteredTokens}
        selectedToken={selectedToken}
        isOpen={isOpen}
        onSelect={onSelect}
        onClose={handleClose}
      />
    </Modal>
  )
}

interface TokenSearchProps {
  tokenOptions: Token[]
  onFilteredTokens: (tokens: Token[]) => void
  chainId: number
}

const TokenSearchAndQuery = ({
  tokenOptions,
  onFilteredTokens,
  chainId,
}: TokenSearchProps) => {
  const [searchQuery, setSearchQuery] = useState("")
  const [searchAddress, setSearchAddress] = useState<string>()

  // Query for token if search query is a valid address
  const { data: fetchedToken, isSuccess } = useErc20TokenQuery({
    address: searchAddress || "",
    chainId,
  })

  const filterTokens = useCallback(
    (query: string) => {
      if (!query) {
        onFilteredTokens(tokenOptions)
        setSearchAddress(undefined)
        return
      }

      const lowercaseQuery = query.toLowerCase()
      const filtered = tokenOptions.filter(
        (token) =>
          token.symbol.toLowerCase().includes(lowercaseQuery) ||
          token.name.toLowerCase().includes(lowercaseQuery) ||
          token.address.toLowerCase().includes(lowercaseQuery),
      )

      // If no matches found and query is a valid address, try to fetch the token
      if (filtered.length === 0 && isAddress(query)) {
        setSearchAddress(query)
        // Don't update filtered tokens yet, wait for fetchedToken
        return
      } else {
        setSearchAddress(undefined)
        onFilteredTokens(filtered)
      }
    },
    [tokenOptions, onFilteredTokens],
  )

  // Handle fetched token updates separately
  useEffect(() => {
    if (isSuccess && fetchedToken && searchAddress) {
      onFilteredTokens([fetchedToken])
    }
  }, [fetchedToken, isSuccess, searchAddress, onFilteredTokens])

  const onSearch = useCallback(
    (value: string) => {
      const trimmedValue = value.trim()
      setSearchQuery(trimmedValue)
      filterTokens(trimmedValue)
    },
    [filterTokens],
  )

  return (
    <SearchInput
      searchStr={searchQuery}
      onSearch={onSearch}
      placeholder="Search by name or address"
      disabled={false}
    />
  )
}
