import { useEffect, useRef } from "react"
import { Chain } from "viem"
import { useAppDispatch } from "src/state/hooks"
import { supportedChains } from "src/constants/chains/supportedChains"
import { CHAINS_BY_ID } from "src/constants/chains/chains"
import { useApplicationState } from "src/state/application/hooks"
import { updateChainId } from "src/state/application/reducer"

export default function NetworkModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean
  onClose: () => void
}) {
  const modalRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        onClose()
      }
    }
    document.addEventListener("mousedown", handleClickOutside)

    return () => document.removeEventListener("mousedown", handleClickOutside)
  }, [onClose])

  if (!isOpen) return null

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-40">
      <div
        ref={modalRef}
        className="w-96 rounded-2xl bg-[#2c2c2c] p-5 text-marginalGray-100 shadow-lg"
      >
        <div className="mb-4 flex items-center justify-between">
          <h3 className="text-lg font-semibold">Switch Networks</h3>
          <button
            className="align-center relative flex justify-center rounded-full bg-[#ffffff14] p-3 text-sm"
            onClick={onClose}
          >
            <svg
              aria-hidden="true"
              fill="none"
              height="10"
              viewBox="0 0 10 10"
              width="10"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Close</title>
              <path
                d="M1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L3.58579 5L0.292893 8.29289C-0.0976311 8.68342 -0.0976311 9.31658 0.292893 9.70711C0.683417 10.0976 1.31658 10.0976 1.70711 9.70711L5 6.41421L8.29289 9.70711C8.68342 10.0976 9.31658 10.0976 9.70711 9.70711C10.0976 9.31658 10.0976 8.68342 9.70711 8.29289L6.41421 5L9.70711 1.70711C10.0976 1.31658 10.0976 0.683417 9.70711 0.292893C9.31658 -0.0976311 8.68342 -0.0976311 8.29289 0.292893L5 3.58579L1.70711 0.292893Z"
                fill="currentColor"
              ></path>
            </svg>
          </button>
        </div>
        <div className="space-y-2">
          {supportedChains.map((chain) => (
            <NetworkItem key={chain.id} chain={chain} onClose={onClose} />
          ))}
        </div>
      </div>
    </div>
  )
}

const NetworkItem = ({ chain, onClose }: { chain: Chain; onClose: () => void }) => {
  const dispatch = useAppDispatch()
  const { chainId } = useApplicationState()

  const onSelect = () => {
    dispatch(updateChainId(chain.id))
    onClose()
  }

  return (
    <div
      className={`flex cursor-pointer items-center gap-2 rounded-xl p-2 transition-colors duration-200 ${
        chainId === chain.id
          ? "bg-marginalOrange-500"
          : "hover:bg-[#e0e8ff1a] hover:opacity-80"
      }`}
      onClick={onSelect}
    >
      <img
        alt={chain?.name ?? "Chain icon"}
        src={CHAINS_BY_ID[chain.id].chainImg}
        style={{ width: 26, height: 26, borderRadius: "100%" }}
      />
      <span className="font-medium">{chain.name}</span>
      {chainId === chain.id && (
        <span className="ml-auto text-xs font-semibold text-marginalGray-100">
          Selected
        </span>
      )}
    </div>
  )
}
