import { Token } from "src/types"
import { convertX96Price } from "src/utils/conversions/convertX96Price"

interface Props {
  useInverse: boolean
  oraclePriceX96: bigint | undefined
  poolPriceX96: bigint | undefined
  liquidationPriceX96: bigint | undefined
  token0: Token | null | undefined
  token1: Token | null | undefined
}

interface PriceOutputs {
  oraclePrice: string | undefined
  poolPrice: string | undefined
  liquidationPrice: string | undefined
}

/**
 * Calculates regular or inverse prices based on user preference
 * @param inputs - Object containing price data and tokens
 * @returns Object containing calculated prices
 */
export function getInversablePrices({
  useInverse,
  oraclePriceX96,
  poolPriceX96,
  liquidationPriceX96,
  token0,
  token1,
}: Props): PriceOutputs {
  // Convert X96 prices to decimal strings
  const prices = {
    oracle: convertX96Price(oraclePriceX96, token0, token1),
    pool: convertX96Price(poolPriceX96, token0, token1),
    liquidation: convertX96Price(liquidationPriceX96, token0, token1),
  }

  // Return regular prices if inverse not requested
  if (!useInverse) {
    return {
      oraclePrice: prices.oracle,
      poolPrice: prices.pool,
      liquidationPrice: prices.liquidation,
    }
  }

  // Calculate inverse prices
  return {
    oraclePrice: getInversePrice(prices.oracle),
    poolPrice: getInversePrice(prices.pool),
    liquidationPrice: getInversePrice(prices.liquidation),
  }
}

/**
 * Calculates the inverse of a price (1/price)
 * @param price - Price as string
 * @returns Inverse price as string or undefined if input invalid
 */
function getInversePrice(price: string | undefined): string | undefined {
  if (!price) return undefined

  const numericPrice = Number(price)

  if (!Number.isFinite(numericPrice) || numericPrice === 0) {
    return undefined
  }

  return (1 / numericPrice).toString()
}
