import { useEffect } from "react"
import { UNISWAP_FEE_AMOUNT } from "src/pages/createPool/hooks/useUniswapV3Factory"
import useUniswapV3Pools from "src/pages/createPool/hooks/useUniswapV3Pools"
import {
  useCreatePoolActionHandlers,
  useCreatePoolState,
} from "src/state/createPool/hooks"
import { UniswapPool } from "src/types"
import { useAccount } from "wagmi"

interface PoolOptionProps {
  label: string
  percentage: number
  selected: boolean
  disabled: boolean
  onSelect: () => void
}

const PoolOption = ({
  label,
  percentage,
  selected,
  onSelect,
  disabled,
}: PoolOptionProps) => {
  return (
    <div
      className={`flex select-none flex-col items-start justify-center rounded-lg border-2 p-2 ${selected && !disabled ? "border-marginalOrange-500" : "border-marginalGray-600"} ${disabled ? "opacity-30 hover:cursor-not-allowed" : "cursor-pointer"} w-full transition-all`}
      onClick={() => !disabled && onSelect()}
    >
      <div className="flex justify-between items-center w-full">
        <div
          className={`flex h-4 w-4 items-center justify-center rounded-full ${
            selected ? "bg-marginalOrange-500" : "border border-marginalGray-600"
          }`}
        >
          <div
            className={`h-2 w-2 rounded-full ${selected ? "bg-white" : "bg-transparent"}`}
          ></div>
        </div>
        <div className="mr-1 text-base font-semibold text-[#CACACA]">{percentage}%</div>
      </div>
      <div className="text-sm text-gray-400">{label}</div>
    </div>
  )
}

const SelectOracle = () => {
  const { uniswapPools } = useUniswapV3Pools()
  const { onSelectUniswapPool } = useCreatePoolActionHandlers()
  const { uniswapPool, token0, token1 } = useCreatePoolState()
  const { chain } = useAccount()

  function onSelect(fee: number) {
    onSelectUniswapPool(
      uniswapPools.find(
        (pool: UniswapPool) => pool.fee === fee && pool.isPool,
      ) as UniswapPool,
    )
  }

  console.table({
    token0: token0?.name,
    token1: token1?.name,
    isOCNIncreaseRequired: uniswapPool?.isOCNIncreaseRequired,
    address: uniswapPool?.address,
    isPool: uniswapPool?.isPool,
    fee: uniswapPool?.fee,
    obsCarMin: uniswapPool?.obsCarMin,
    obsCarNext: uniswapPool?.obsCarNext,
  })

  useEffect(() => {
    /** If the Network is switched the uniswap pool stored in RKT will be stale, it needs to be updated
     * with the new fetched Uniswap pools from this new chain.
     * If the uniswapPools variable changes, the selected chain also needs to be updated.
     */
    if (uniswapPool && uniswapPools.length) {
      onSelectUniswapPool(
        uniswapPools.find(
          (pool: UniswapPool) => pool.fee === uniswapPool.fee,
        ) as UniswapPool,
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chain, uniswapPools])

  return (
    <div className="flex gap-3">
      {Object.values(UNISWAP_FEE_AMOUNT).map((fee) => (
        <PoolOption
          key={fee}
          label={"Pool Fee"}
          percentage={fee / 10000}
          selected={uniswapPool?.fee === fee && uniswapPool.isPool}
          onSelect={() => onSelect(fee)}
          disabled={Boolean(!uniswapPools.find((pool: any) => pool.fee === fee)?.isPool)}
        />
      ))}
    </div>
  )
}

export default SelectOracle
