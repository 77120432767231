import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export interface TransactionState {
  pending: "wallet" | "tx" | null
  txHash: string | null
  txError: any
}

export const initialState: TransactionState = {
  pending: null,
  txHash: null,
  txError: null,
}

export const transactionSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: {
    setPending: (
      state: TransactionState,
      action: PayloadAction<"wallet" | "tx" | null>,
    ) => {
      state.pending = action.payload
    },
    setTxHash: (state: TransactionState, action: PayloadAction<string | null>) => {
      state.txHash = action.payload
    },
    setTxError: (state: TransactionState, action: PayloadAction<unknown>) => {
      state.txError = action.payload
    },
    resetTransactionState: (state) => {
      state.pending = initialState.pending
      state.txHash = initialState.txHash
      state.txError = initialState.txError
    },
  },
})

export const { setTxHash, setTxError, resetTransactionState, setPending } =
  transactionSlice.actions

export default transactionSlice.reducer
