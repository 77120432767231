/**
 * Converts a sqrtPriceX96 value into the actual price.
 * @param sqrtPriceX96 - The square root price scaled by 2^96.
 * @returns The actual price as a number.
 */
export function calculatePrice(sqrtPriceX96: bigint): number {
  const Q96 = BigInt(2) ** BigInt(96)
  const price = Number(sqrtPriceX96 * sqrtPriceX96) / Number(Q96 * Q96)
  return price
}

/**
 * Calculates the token1 amount given token0 amount and sqrtPriceX96.
 * @param token0Amount - The amount of token0.
 * @param sqrtPriceX96 - The square root price scaled by 2^96.
 * @returns The required amount of token1.
 */
export function calculateToken1Amount(
  token0Amount: number,
  sqrtPriceX96: bigint,
): number {
  const price = calculatePrice(sqrtPriceX96)
  return token0Amount * price
}

/**
 * Calculates the token0 amount given token1 amount and sqrtPriceX96.
 * @param token1Amount - The amount of token1.
 * @param sqrtPriceX96 - The square root price scaled by 2^96.
 * @returns The required amount of token0.
 */
export function calculateToken0Amount(
  token1Amount: number,
  sqrtPriceX96: bigint,
): number {
  const price = calculatePrice(sqrtPriceX96)
  return token1Amount / price
}
