import { useReadContracts } from "wagmi"
import { Address } from "viem"
import { PoolData } from "src/types"
import { formatBigIntToString } from "src/utils/formatBigIntToString"
import MarginalV1PoolAbi from "src/constants/abis/MarginalV1Pool.json"

export interface PoolTotalSupply {
  pool: PoolData | undefined | null
  totalSupply: bigint
  parsedTotalSupply?: string
}

const createPoolContract = (poolAddress: Address) => ({
  address: poolAddress,
  abi: MarginalV1PoolAbi as any,
  functionName: "totalSupply" as const,
})

const formatTotalSupply = (
  totalSupply: bigint,
  decimals?: string,
): string | undefined => {
  return formatBigIntToString(totalSupply, decimals, 4)
}

const mapPoolToContract = (pool: PoolData | undefined | null) => {
  if (!pool?.poolAddress) return null
  return createPoolContract(pool.poolAddress as Address)
}

/**
 * Hook to fetch and format total supply in pools
 * @param pools Array of pool data
 * @returns Array of total supply results for each pool
 */
export const useTotalSupplyInPools = (
  pools: (PoolData | undefined | null)[],
): PoolTotalSupply[] | undefined => {
  // Filter out invalid pools and create contract configs
  const contracts = pools
    .map(mapPoolToContract)
    .filter((contract): contract is NonNullable<typeof contract> => contract !== null)

  const { data } = useReadContracts({
    contracts,
    query: {
      enabled: contracts.length > 0,
    },
  })

  if (!data) return undefined

  return data.map((result, index) => {
    const totalSupply = (result?.result as bigint) ?? 0n
    return {
      pool: pools[index],
      totalSupply,
      parsedTotalSupply: formatTotalSupply(totalSupply, pools[index]?.decimals),
    }
  })
}
