import { waitForTransactionReceipt, WriteContractReturnType } from "@wagmi/core"
import {
  resetTransactionState,
  setPending,
  setTxError,
  setTxHash,
} from "src/state/transaction/reducer"
import store from "src/state"
import { wagmiConfig } from "src/wagmiConfig"
import { TransactionReceipt } from "viem"

export async function submitTransaction(
  transactionFunction: () => Promise<WriteContractReturnType>,
  onSuccess?: () => unknown,
) {
  resetTransactionState()
  try {
    store.dispatch(setPending("wallet"))

    const data = await transactionFunction()
    store.dispatch(setPending("tx"))

    const receipt: TransactionReceipt = await waitForTransactionReceipt(wagmiConfig, {
      hash: data,
      timeout: 60_000,
    })
    onSuccess?.()
    store.dispatch(setPending(null))
    store.dispatch(setTxHash(receipt.transactionHash))
  } catch (error) {
    console.error("Error submitting the transaction: ", error)
    store.dispatch(setTxError(error))
  }
}
