import { TokenLogo } from "../TokenBadge"
import { BondingCurveProgressSlider } from "./BondingCurveProgressSlider"

const BondingCurveProgress = ({ token }: any) => {
  return (
    <div className="relative mx-auto w-full max-w-[380px] rounded-3xl border border-marginalGray-800 bg-marginalGray-900 shadow-outerBlack sm:max-w-[440px]">
      <div className="px-4 py-4">
        <span className="text-xl text-marginalGray-200">
          Bonding Curve Progress
        </span>
      </div>

      <hr className="m-0 h-px border-none bg-marginalGray-800" />

      <div id="bonding-curve-progress-content" className="px-4 py-4">
        <div className="px-4 py-4 space-y-4 text-sm rounded-lg bg-marginalBlack text-marginalGray-600">
          <div className="flex justify-between">
            <span className="">Max Raise Amount</span>

            <div className="flex items-center space-x-2">
              <TokenLogo symbol={token?.symbol} imgUrl={token?.imgUrl} size={4} />
              <div className="text-marginalGray-200">49.25%</div>
            </div>
          </div>

          <BondingCurveProgressSlider />
        </div>
      </div>
    </div>
  )
}

export default BondingCurveProgress
