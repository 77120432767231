export const PoolsTabSelector = ({
  view,
  handlePrimaryClick,
  handleSecondaryClick,
}: {
  view: string
  handlePrimaryClick?: any
  handleSecondaryClick?: any
}) => {
  return (
    <div id="pools-tab-selector" className="flex h-10 w-fit">
      <div className="flex">
        <button
          key={`incentivized-tab`}
          onClick={(e: any) => handlePrimaryClick(e)}
          className={`relative w-[135px] px-4 pb-4 pt-2 text-sm ${view === "Incentivized" ? "text-marginalGray-200" : "text-marginalGray-600 hover:text-marginalGray-400"}`}
        >
          Incentivized
          {view === "Incentivized" && (
            <div className="absolute bottom-0 left-0 right-0 h-px bg-marginalOrange-500" />
          )}
        </button>
        <button
          key={`unincentivized-tab`}
          onClick={(e: any) => handleSecondaryClick(e)}
          className={`relative w-[135px] px-4 pb-4 pt-2 text-sm ${view === "Unincentivized" ? "text-marginalGray-200" : "text-marginalGray-600 hover:text-marginalGray-400"}`}
        >
          Unincentivized
          {view === "Unincentivized" && (
            <div className="absolute bottom-0 left-0 right-0 h-px bg-marginalOrange-500" />
          )}
        </button>
      </div>
    </div>
  )
}
