import { LaunchProjectTransactionProps } from "src/hooks/useLBData"

export function filterTransactionsByMaker(
  maker: any,
  transactions: LaunchProjectTransactionProps[],
) {
  return transactions.filter(
    (transaction: any) => transaction.maker.toLowerCase() === maker.toLowerCase(),
  )
}
