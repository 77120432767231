import { SwitchButton } from "src/components/ui/SwitchButton"
import { PositionView } from "src/pages/positions/Positions"

interface PositionsHeaderProps {
  view: PositionView
  onViewChange: (view: PositionView) => void
}

export const PositionsHeader = ({ view, onViewChange }: PositionsHeaderProps) => {
  return (
    <div className="flex w-full flex-col items-start justify-between space-y-4 bg-marginalGray-900 px-4 py-4 md:flex-row md:items-center md:space-y-0">
      <span className="text-xl text-marginalGray-200">Positions</span>
      <div className="h-8 w-full md:max-w-48">
        <SwitchButton
          view={view}
          onLeftButtonClick={() => onViewChange(PositionView.OPEN)}
          onRightButtonClick={() => onViewChange(PositionView.CLOSED)}
          leftButtonText="Open"
          rightButtonText="Closed"
        />
      </div>
    </div>
  )
}
