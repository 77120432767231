import { isUndefined } from "lodash"
import { type Address } from "viem"
import { type Token } from "src/types"
import { type RewardBalance } from "src/hooks/useUserRewardsBalance"
import { useNavigateRoutes } from "src/hooks/useNavigateRoutes"
import { useErc20TokenSymbol } from "src/hooks/useErc20TokenSymbol"
import { formatBigIntToString } from "src/utils/formatBigIntToString"
import { formatNumberAmount } from "src/utils/formatNumberAmount"
import { getReward } from "src/utils/actions/getReward"
import { TokenBadge } from "src/components/TokenBadge"
import { ActionButton } from "src/components/ActionButton"

interface PoolSharesAndRewardsProps {
  chainId: number
  token0: Token | null
  token1: Token | null
  userSharePercentageOfTotal: number | null
  userToken0Balance: string | undefined
  userToken1Balance: string | undefined
  userStakedLpBalance: string | undefined
  aprPercentage: number | undefined
  poolAddress: string | undefined
  stakePool: string | undefined
  rewards: RewardBalance[]
}

export const PoolSharesAndRewards = ({
  chainId,
  token0,
  token1,
  userSharePercentageOfTotal,
  userToken0Balance,
  userToken1Balance,
  userStakedLpBalance,
  aprPercentage,
  poolAddress,
  stakePool,
  rewards,
}: PoolSharesAndRewardsProps) => {
  const { onNavigateToStake, onNavigateToUnstake } = useNavigateRoutes()

  const userToken0BalanceParsed = formatBigIntToString(
    BigInt(userToken0Balance ?? 0n),
    token0?.decimals,
  )
  const userToken1BalanceParsed = formatBigIntToString(
    BigInt(userToken1Balance ?? 0n),
    token1?.decimals,
  )

  const handleClaimCallback = async () => {
    try {
      if (!stakePool) {
        throw new Error("Missing stake pool address")
      }

      const receipt = await getReward(stakePool as Address, chainId)

      console.log("Claim transaction successful:", receipt.transactionHash)
    } catch (error) {
      console.error("Claim callback error:", error)
    }
  }
  return (
    <div className="flex w-full flex-col space-y-1">
      <div className="mt-2 flex h-fit w-full flex-col space-y-3 rounded-xl bg-marginalBlack p-4 md:mt-0 md:rounded-lg">
        <div className="text-sm text-marginalGray-200">My Share</div>

        <div className="flex w-full justify-between">
          <div className="text-3xl text-marginalGray-100">
            {userSharePercentageOfTotal?.toFixed(2)}%
          </div>

          <div className="flex flex-col items-end space-y-1">
            <p className="flex items-center space-x-1 text-xs text-marginalGray-200">
              <span>{formatNumberAmount(userToken0BalanceParsed)}</span>
              <TokenBadge token={token0 as Token} size={4} reverse={true} />
            </p>

            <p className="flex items-center space-x-1 text-xs text-marginalGray-200">
              <span>{formatNumberAmount(userToken1BalanceParsed)}</span>
              <TokenBadge token={token1 as Token} size={4} reverse={true} />
            </p>
          </div>
        </div>

        <div className="h-px bg-marginalGray-200/20" />

        <div className="space-y-3">
          <div className="text-sm text-marginalGray-600">Staked</div>
          <div className="flex items-center justify-between">
            <div className="text-base text-marginalGray-200">
              {userStakedLpBalance
                ? formatNumberAmount(userStakedLpBalance, true)
                : "0.0"}{" "}
              MARGV1-LP
            </div>

            <p className="flex items-center gap-2 text-sm text-marginalGray-200">
              <span className="text-marginalGray-600">APR</span>
              {aprPercentage?.toFixed(2) || "-"}%
            </p>
          </div>

          <div className="flex space-x-4">
            <ActionButton
              action="Stake"
              onClick={() => {
                onNavigateToStake(poolAddress as string)
              }}
              primary={false}
              size="sm"
              disabled={isUndefined(stakePool)}
            />
            <ActionButton
              action="Unstake"
              onClick={() => {
                onNavigateToUnstake(poolAddress as string)
              }}
              primary={false}
              size="sm"
              disabled={isUndefined(stakePool)}
            />
          </div>
        </div>
      </div>

      {stakePool && (
        <div className="mt-4 flex h-full w-full flex-col space-y-3 rounded-xl bg-marginalBlack p-4 md:mt-0 md:justify-between md:rounded-lg">
          <div className="text-sm text-marginalGray-600">Rewards</div>

          {rewards.length > 0 ? (
            <div className="flex w-full justify-between">
              {rewards.map((reward) =>
                PoolReward({
                  rewardToken: reward.tokenAddress,
                  balance: reward.formattedBalance,
                }),
              )}
            </div>
          ) : (
            <p className="text-xs">There are currently no claimable rewards.</p>
          )}

          <ActionButton
            action="Claim"
            onClick={() => {
              handleClaimCallback()
              console.log("Claiming Rewards...")
            }}
            primary={false}
            size="sm"
            disabled={isUndefined(stakePool) || rewards.length === 0}
          />
        </div>
      )}
    </div>
  )
}

const PoolReward = ({
  rewardToken,
  balance,
}: {
  rewardToken: Address
  balance?: string
}) => {
  const tokenSymbol = useErc20TokenSymbol(rewardToken)
  return (
    <div className="flex items-center space-x-1">
      <div className="text-base text-marginalGray-200">
        {formatNumberAmount(balance)}
      </div>
      <span className="text-base text-marginalGray-200">
        {tokenSymbol}
      </span>
    </div>
  )
}
