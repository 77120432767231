import { Address } from "viem"
import { getInversablePrices } from "../helpers/getInversablePrices"
import { usePooledTokensUSDPrices } from "./usePooledTokensUSDPrices"
import { useLiquidationPrice } from "./useLiquidationPrice"
import { useMarginalOraclePrices } from "./useMarginalOraclePrices"
import { Token } from "src/types"
import { convertX96FundingRate } from "src/utils/conversions/convertX96FundingRate"
import { isWrappedGasToken } from "src/utils/isWrappedGasToken"
import { formatNumberAmount } from "src/utils/formatNumberAmount"

interface Props {
  chainId: number
  selectedPool: {
    token0: Token
    token1: Token
    maintenance: bigint
    oracleAddress: Address
  } | null
  zeroForOne: boolean
  useInverse: boolean
  quoteToken: Token | null
  position?: {
    size: bigint
    debt: bigint
    margin: bigint
  }
}

interface LivePoolPrices {
  sqrtPriceX96: bigint
  oracleSqrtPriceX96: bigint
  fundingRatioX96: bigint
  oraclePrice: string | undefined
  poolPrice: string | undefined
  liquidationPrice: string | undefined
  liquidationPriceX96: bigint | undefined
  token0PriceInUSD: number | undefined
  token1PriceInUSD: number | undefined
  fundingRate: string | null | undefined
}

export const useLivePoolPrices = ({
  chainId,
  selectedPool,
  zeroForOne,
  useInverse,
  quoteToken,
  position,
}: Props): LivePoolPrices => {
  const liquidationPriceX96 = useLiquidationPrice(
    chainId,
    zeroForOne,
    position?.size,
    position?.debt,
    position?.margin,
    selectedPool?.maintenance,
  )

  const { sqrtPriceX96, oracleSqrtPriceX96, fundingRatioX96 } = useMarginalOraclePrices({
    chainId,
    token0: selectedPool?.token0?.address as Address,
    token1: selectedPool?.token1?.address as Address,
    maintenance: selectedPool?.maintenance as bigint,
    oracle: (selectedPool?.oracleAddress || (selectedPool as any)?.oracle) as Address,
  })

  const { oraclePrice, poolPrice, liquidationPrice } = getInversablePrices({
    useInverse,
    oraclePriceX96: oracleSqrtPriceX96,
    poolPriceX96: sqrtPriceX96,
    liquidationPriceX96,
    token0: selectedPool?.token0,
    token1: selectedPool?.token1,
  })

  const { token0PriceInUSD, token1PriceInUSD } = usePooledTokensUSDPrices({
    chainId,
    quoteToken,
    token0: selectedPool?.token0,
    token1: selectedPool?.token1,
    poolPriceX96: sqrtPriceX96,
  })

  const fundingRate = getFundingRate(fundingRatioX96, selectedPool?.token1, chainId)

  return {
    sqrtPriceX96,
    oracleSqrtPriceX96,
    fundingRatioX96,
    oraclePrice,
    poolPrice,
    liquidationPrice,
    liquidationPriceX96,
    token0PriceInUSD,
    token1PriceInUSD,
    fundingRate,
  }
}

const getFundingRate = (
  fundingRatioX96: bigint,
  token1: Token | null | undefined,
  chainId: number,
): string | null | undefined => {
  const fundingRateParsed = convertX96FundingRate(fundingRatioX96)
  if (!fundingRateParsed || !token1) {
    return null
  }

  const shouldInvertSign = !isWrappedGasToken(token1, chainId)
  const signedRate = shouldInvertSign ? -fundingRateParsed : fundingRateParsed
  return formatNumberAmount(signedRate.toString(), true)
}
