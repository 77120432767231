import _ from "lodash"
import { Address, maxUint256 } from "viem"
import { useLocation, useParams } from "react-router-dom"
import { InputContainer } from "src/components/ui/InputContainer"
import { useNavigateRoutes } from "src/hooks/useNavigateRoutes"
import { usePoolsData, getPoolDataByAddress } from "src/hooks/usePoolsData"
import { useAccount } from "wagmi"
import { ListRow } from "src/components/ui/ListRow"
import { useErc20TokenSymbol } from "src/hooks/useErc20TokenSymbol"
import { TransactionStatusModal } from "src/components/TransactionStatusModal"
import { Card } from "src/components/Card"
import { CaretLeftIcon } from "src/components/Icons/CaretLeftIcon"
import { useNetworkChangeRedirect } from "src/hooks/useNetworkChangeRedirect"
import { StakeInput } from "src/pages/stake/components/StakeInput"
import { useEffect, useMemo } from "react"
import {
  useStakeActionHandlers,
  useStakeState,
  useStakeStatus,
} from "src/state/stake/hook"
import { isOnlyZeroes } from "src/utils/isOnlyZeroes"
import { formatStringToBigInt } from "src/utils/formatStringToBigInt"
import { useErc20TokenAllowance } from "src/hooks/useErc20TokenAllowance"
import { isBlank } from "src/utils/isBlank"
import { waitForTransactionReceipt } from "@wagmi/core"
import { wagmiConfig } from "src/wagmiConfig"
import { approveErc20Token } from "src/utils/actions/approveErc20Token"
import { getTransactionError } from "src/utils/getTransactionError"
import { ConfirmStakeButton } from "src/pages/stake/components/ConfirmStakeButton"
import { trimTrailingZeroes } from "src/utils/trimTrailingZeroes"
import { usePoolRewardsAPR } from "src/hooks/usePoolRewardsAPR"
import { MARGINAL_DAO_TOKEN } from "src/constants/tokens"
import { type PoolData } from "src/types"
import { stake } from "src/utils/actions/stake"
import { useTransactionModal } from "src/pages/trade/hooks/useTransactionModal"
import { useUserPoolBalance } from "src/pages/pool/hooks/useUserPoolBalance"
import { useUserStakedPoolBalance } from "src/pages/pool/hooks/useUserStakedPoolBalance"
import { formatNumberAmount } from "src/utils/formatNumberAmount"
import { convertMaintenanceToLeverage } from "src/utils/conversions/convertMaintenanceToLeverage"
import { useApplicationState } from "src/state/application/hooks"
import { extrapolateTokenPair } from "src/utils/extrapolateTokenPair"
import { useMarginalOraclePrices } from "src/pages/trade/hooks/useMarginalOraclePrices"
import { usePooledTokensUSDPrices } from "src/pages/trade/hooks/usePooledTokensUSDPrices"
import { formatUSDCurrency } from "src/utils/formatUSDCurrency"
import { useTotalSupplyInPools } from "src/pages/pools/hooks/useTotalSupplyInPools"
import { derivePoolLiquidity } from "src/pages/pool/helpers/derivePoolLiquidity"
import { useCurrentPoolsState } from "src/pages/pools/hooks/useCurrentPoolsState"
import { useLiquidityLockedInPools } from "src/pages/pools/hooks/useLiquidityLockedInPools"
import { calculatePercentageOfTotal } from "src/utils/conversions/calculatePercentageOfTotal"
import { getTokenByAddress } from "src/constants/tokenList"
import { DoubleTokenLogo } from "src/components/TokenBadge"

export const Stake = () => {
  const { chainId } = useApplicationState()
  const { address } = useAccount()
  const { poolAddress } = useParams()
  const { pools, poolsDataByAddress } = usePoolsData()
  const { stakePool, stakeTokenAddress, inputValue } = useStakeState()
  const { onUserInput, onSelectStakePool, onSelectStakeTokenAddress, onResetStakeInput } =
    useStakeActionHandlers()
  const { isInputValid, isTokenValid } = useStakeStatus()

  const location = useLocation()

  useEffect(() => {
    onResetStakeInput()
  }, [location, onResetStakeInput])

  const { onNavigateToPool, onNavigateToPools } = useNavigateRoutes()
  const handleReturnToPool = (poolAddress: string) => onNavigateToPool(poolAddress)

  useNetworkChangeRedirect(onNavigateToPools)

  const pool = getPoolDataByAddress(poolAddress as string, poolsDataByAddress)
  const lpTokenSymbol = useErc20TokenSymbol(poolAddress as Address)
  const { balances, refetch: refetchUserLpBalance } = useUserPoolBalance([pool], address)
  const { balances: userStakedBalance, refetch: refetchStakedBalance } =
    useUserStakedPoolBalance([pool as PoolData], address)

  const userLpBalance = balances?.[0]
  const userStakedLpBalance = userStakedBalance[pool?.stakePool as Address]
  const {
    token0: poolToken0,
    token1: poolToken1,
    maintenance,
    decimals: poolDecimals,
  } = (pool as PoolData) || {}
  const userTotalLpBalance = (
    parseFloat(userLpBalance?.parsedBalance ?? "0") +
    parseFloat(userStakedLpBalance?.parsedBalance ?? "0")
  ).toString()

  const token0 = getTokenByAddress(poolToken0?.address, chainId) ?? poolToken0
  const token1 = getTokenByAddress(poolToken1?.address, chainId) ?? poolToken1
  const leverageMax = convertMaintenanceToLeverage(maintenance)

  const formattedInput = !isBlank(inputValue)
    ? formatStringToBigInt(inputValue, poolDecimals)
    : 0n

  const { allowance, fetchAllowance } = useErc20TokenAllowance(
    pool?.poolAddress as Address,
    Number(pool?.decimals),
    address,
    pool?.stakePool as Address,
    chainId,
  )

  // Fetch pool liquidity
  const { poolStates } = useCurrentPoolsState([pool?.poolAddress as Address])
  const liquidityLockedInPools = useLiquidityLockedInPools([pool])
  const poolsTotalSupply = useTotalSupplyInPools([pool])
  const poolState = poolStates[pool?.poolAddress as Address]
  const poolLiquidityAvailable = poolState
  const poolLiquidityLocked = liquidityLockedInPools?.[0]
  const poolLiquidityTotal = poolsTotalSupply?.[0]
  const { total } = derivePoolLiquidity({
    token0,
    token1,
    sqrtPriceX96: poolState?.sqrtPriceX96,
    liquidityAvailable: poolLiquidityAvailable?.liquidity,
    liquidityLocked: poolLiquidityLocked?.liquidityLocked,
    liquidityTotal: poolLiquidityTotal?.totalSupply,
  })

  // Fetch token0 and token1 prices in USD
  const { sqrtPriceX96 } = useMarginalOraclePrices({
    chainId,
    token0: token0?.address as Address,
    token1: token1?.address as Address,
    maintenance: pool?.maintenance,
    oracle: pool?.oracleAddress,
  })
  const { quoteToken } = extrapolateTokenPair(token0, token1, chainId)
  const { token0PriceInUSD, token1PriceInUSD } = usePooledTokensUSDPrices({
    chainId,
    quoteToken,
    token0: pool?.token0,
    token1: pool?.token1,
    poolPriceX96: sqrtPriceX96,
  })

  // Calculate total value locked (TVL)
  const tvl =
    (total?.token0?.parsed ? parseFloat(total.token0.parsed) : 0) *
      (token0PriceInUSD ?? 0) +
    (total?.token1?.parsed ? parseFloat(total.token1.parsed) : 0) *
      (token1PriceInUSD ?? 0)

  // Calculate LP token price in USD
  const userSharePercentageOfTotal = calculatePercentageOfTotal({
    total: parseFloat(poolLiquidityTotal?.parsedTotalSupply ?? "0"),
    partial: parseFloat(userTotalLpBalance ?? "0"),
  })
  const lpTokenPriceInUSD =
    tvl *
    (userSharePercentageOfTotal ?? 0) *
    (parseFloat(userStakedLpBalance?.parsedBalance ?? "0") /
      parseFloat(userTotalLpBalance))

  useEffect(() => {
    if (!stakeTokenAddress || _.isUndefined(stakePool)) {
      onSelectStakePool(pools[0])
      onSelectStakeTokenAddress(pools[0]?.poolAddress)
    }
  }, [stakeTokenAddress, stakePool]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (stakeTokenAddress && address) {
      if (!isOnlyZeroes(inputValue)) {
        ;(async () => {
          await fetchAllowance()
        })()
      }
    }
  }, [chainId, stakeTokenAddress, address, fetchAllowance, inputValue])

  const durationOneYearInSeconds = 86400 * 365

  const { aprPercentage } = usePoolRewardsAPR(
    poolAddress as Address,
    MARGINAL_DAO_TOKEN as Address,
    poolAddress as Address,
    durationOneYearInSeconds,
    chainId,
  )

  const isApproved = useMemo(() => {
    if (_.isUndefined(allowance) || _.isUndefined(formattedInput)) return true
    return allowance >= formattedInput
  }, [allowance, formattedInput])

  const isBalanceSufficient = useMemo(() => {
    if (_.isUndefined(userLpBalance)) return false
    if (_.isUndefined(formattedInput)) return true

    if (formattedInput && userLpBalance) {
      return userLpBalance.balance >= formattedInput
    }
  }, [userLpBalance, formattedInput])

  const {
    state: {
      showConfirm,
      isPendingWallet,
      isPendingApprove,
      isPendingTx,
      isTxSubmitted,
      txHash,
      txError,
    },
    openConfirmModal,
    closeConfirmModal,
    resetState: resetTransactionState,
    setPendingWallet,
    setPendingApprove,
    setPendingTx,
    setTxSubmitted,
  } = useTransactionModal()

  const onSuccessReset = () => {
    resetTransactionState()
    onResetStakeInput()
  }

  const approveToken = async (
    amount: bigint | undefined,
    spenderAddress: Address,
    tokenAddress: Address,
  ) => {
    if (!amount) {
      throw new Error("Require amount to approve")
    }
    if (!spenderAddress) {
      throw new Error("Require spender address to approve")
    }
    if (!tokenAddress) {
      throw new Error("Require token address to approve")
    }

    try {
      openConfirmModal()
      setPendingWallet(true)
      const txHash = await approveErc20Token({
        chainId,
        amount: maxUint256,
        spenderAddress,
        tokenAddress,
      })
      setPendingApprove(true)
      await waitForTransactionReceipt(wagmiConfig, {
        hash: txHash,
      })
      setPendingApprove(false)
      fetchAllowance()
      onSuccessReset()
    } catch (error) {
      console.log("Error approving token: ", error)
    }
  }

  const handleStakeCallback = async () => {
    try {
      if (!pool?.stakePool || !formattedInput) {
        return new Error("Missing required parameters for stake")
      }
      openConfirmModal()
      setPendingWallet(true)
      const transaction = await stake(pool.stakePool as Address, formattedInput, chainId)
      setPendingWallet(false)
      setTxSubmitted(true, transaction.transactionHash)
      onSuccessReset()
      refetchUserLpBalance()
      refetchStakedBalance()
      return transaction.transactionHash
    } catch (error) {
      if (getTransactionError(error) === "Wallet rejected transaction.") {
        setPendingWallet(false)
        setPendingTx(false)
      }
    }
  }

  const handleApplyMaxBalance = () => {
    if (!_.isUndefined(userLpBalance)) {
      const maxBalance = trimTrailingZeroes(userLpBalance?.parsedBalance)

      if (!_.isUndefined(maxBalance)) {
        onUserInput(maxBalance)
      }
    }
  }

  return (
    <Card>
      <div className="flex justify-between">
        <div
          onClick={() => handleReturnToPool(poolAddress as string)}
          className="flex cursor-pointer items-center justify-start space-x-1 text-marginalGray-200"
        >
          <CaretLeftIcon />
          <span className="text-sm text-marginalGray-200">
            Back to Pool
          </span>
        </div>
      </div>

      <div className="relative mx-auto mt-12 w-full max-w-[343px] shadow-outerBlack sm:max-w-[440px]">
        <div className="rounded-t-3xl border border-marginalGray-800 bg-marginalGray-900">
          <div className="flex items-center justify-between p-4">
            <div className="relative text-lg text-marginalGray-200 md:text-xl">
              Stake
            </div>
          </div>

          <div className="px-4 pb-4 pt-0">
            <div className="rounded-xl border border-marginalGray-800 bg-marginalGray-950 p-4">
              <div className="flex items-center space-x-2">
                <DoubleTokenLogo token0={token0} token1={token1} size={8} />

                <div className="flex flex-col overflow-x-hidden">
                  <div className="flex items-center space-x-1 text-lg text-marginalGray-200">
                    <span>{token0?.symbol}</span>
                    <div className="my-auto">/</div>
                    <span>{token1?.symbol}</span>
                    <div className="ml-3">{leverageMax}x</div>
                  </div>

                  <div className="flex flex-nowrap items-center text-xs text-marginalGray-600 md:text-sm">
                    <span>{token0?.name}</span>
                    <div className="my-auto px-0.5">∙</div>
                    <span>{token1?.name}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="relative">
          <div className="space-y-2 rounded-b-3xl border border-t-0 border-marginalGray-800 bg-marginalGray-900 p-4">
            <InputContainer id="add-liquidity-input-a-container">
              <div className="flex w-full flex-col">
                <StakeInput inputValue={inputValue} onChange={onUserInput} />

                {inputValue && lpTokenPriceInUSD && (
                  <div className="mt-1 flex items-center justify-between">
                    <div
                      className={`text-xs text-marginalGray-400 md:text-sm`}
                    >
                      {formatUSDCurrency(lpTokenPriceInUSD * parseFloat(inputValue))}
                    </div>
                  </div>
                )}
              </div>

              {userLpBalance?.balance && (
                <div className="flex space-x-2 text-sm">
                  <div
                    className="cursor-pointer rounded-sm bg-[#4C2D1E] px-0.5 text-marginalOrange-500"
                    onClick={handleApplyMaxBalance}
                  >
                    Max
                  </div>
                </div>
              )}
            </InputContainer>

            <div className="space-y-2 py-2 text-sm text-marginalGray-600">
              <ListRow
                item="Wallet Balance"
                value={
                  <div className="flex items-baseline space-x-1 text-marginalGray-200">
                    <span>{userLpBalance?.parsedBalance}</span>
                    <span>{lpTokenSymbol}</span>
                  </div>
                }
              />
              <ListRow
                item="APR"
                value={
                  <div className="flex items-baseline space-x-1 text-marginalGray-200">
                    <span>{aprPercentage || "-"}%</span>
                  </div>
                }
              />
              <ListRow
                item={`Staked balance`}
                value={
                  <div className="flex items-center space-x-1 text-marginalGray-200">
                    <span>
                      {userStakedLpBalance
                        ? formatNumberAmount(
                            userStakedLpBalance?.parsedBalance ?? "-",
                            true,
                          )
                        : "0.0"}
                    </span>
                    <span>{lpTokenSymbol}</span>
                  </div>
                }
              />
            </div>
            <ConfirmStakeButton
              chainId={chainId}
              isInputValid={isInputValid}
              isTokenValid={isTokenValid}
              isTokenApproved={isApproved}
              isBalanceSufficient={isBalanceSufficient}
              isPendingWallet={isPendingWallet}
              isPendingApprove={isPendingApprove}
              tokenSymbol={lpTokenSymbol}
              onApproveToken={() =>
                approveToken(
                  formattedInput,
                  pool?.stakePool as Address,
                  pool?.poolAddress as Address,
                )
              }
              onConfirm={openConfirmModal}
              stakeCallback={handleStakeCallback}
              error={null}
            />
          </div>
        </div>
      </div>

      <TransactionStatusModal
        chainId={chainId}
        open={showConfirm}
        onOpen={openConfirmModal}
        onClose={closeConfirmModal}
        onReset={onSuccessReset}
        onCallback={handleStakeCallback}
        isPendingWallet={isPendingWallet}
        isPendingApprove={isPendingApprove}
        isPendingTx={isPendingTx}
        isTxSubmitted={isTxSubmitted}
        txHash={txHash}
        txError={txError}
        hasConfirmModal={false}
        onSuccessText="Staking Success"
      />
    </Card>
  )
}
