import { useState, useCallback } from "react"

interface TransactionModalState {
  showConfirm: boolean
  isPendingWallet: boolean
  isPendingApprove: boolean
  isPendingTx: boolean
  isTxSubmitted: boolean
  txHash: string | null
  txError: any
}

const initialState: TransactionModalState = {
  showConfirm: false,
  isPendingWallet: false,
  isPendingApprove: false,
  isPendingTx: false,
  isTxSubmitted: false,
  txHash: null,
  txError: null,
}

interface UseTransactionModalReturn {
  state: TransactionModalState
  openConfirmModal: () => void
  closeConfirmModal: () => void
  resetState: () => void
  setPendingWallet: (pending: boolean) => void
  setPendingApprove: (pending: boolean) => void
  setPendingTx: (pending: boolean) => void
  setTxSubmitted: (submitted: boolean, hash?: string) => void
  setTxError: (error: any) => void
}

export function useTransactionModal(): UseTransactionModalReturn {
  const [state, setState] = useState<TransactionModalState>(initialState)

  const openConfirmModal = useCallback(() => {
    setState((prev) => ({ ...prev, showConfirm: true }))
  }, [])

  const closeConfirmModal = useCallback(() => {
    setState((prev) => ({ ...prev, showConfirm: false }))
  }, [])

  const resetState = useCallback(() => {
    setState(initialState)
  }, [])

  const setPendingWallet = useCallback((pending: boolean) => {
    setState((prev) => ({ ...prev, isPendingWallet: pending }))
  }, [])

  const setPendingApprove = useCallback((pending: boolean) => {
    setState((prev) => ({ ...prev, isPendingApprove: pending }))
  }, [])

  const setPendingTx = useCallback((pending: boolean) => {
    setState((prev) => ({ ...prev, isPendingTx: pending }))
  }, [])

  const setTxSubmitted = useCallback((submitted: boolean, hash?: string) => {
    setState((prev) => ({
      ...prev,
      isTxSubmitted: submitted,
      txHash: hash ?? null,
      isPendingTx: false,
      isPendingWallet: false,
      isPendingApprove: false,
    }))
  }, [])

  const setTxError = useCallback((error: any) => {
    setState((prev) => ({
      ...prev,
      txError: error,
      isPendingTx: false,
      isPendingWallet: false,
      isPendingApprove: false,
    }))
  }, [])

  return {
    state,
    openConfirmModal,
    closeConfirmModal,
    resetState,
    setPendingWallet,
    setPendingApprove,
    setPendingTx,
    setTxSubmitted,
    setTxError,
  }
}
