import { useReadContracts } from "wagmi"
import { Address, zeroAddress } from "viem"
import { useEffect, useMemo } from "react"
import useObservationCardinality from "./useObservationCardinality"
import { UniswapPool } from "src/types"
import { useUniswapV3Factory } from "./useUniswapV3Factory"
import { UNISWAP_V3_POOL_ABI } from "src/constants/abis/Uniswapv3Pool"

const useUniswapV3Pools = () => {
  const {
    uniswapFactoryPools,
    isLoading: isFactoryLoading,
    isUniswapPool,
    isMarginalPool,
  } = useUniswapV3Factory()

  const { observationCardinalityMinimum, isOCLoading } = useObservationCardinality()

  const {
    data: slot0data,
    isLoading: isSlot0Loading,
    refetch: refetchSlot0,
  } = useReadContracts({
    contracts: uniswapFactoryPools?.map(({ address }) => ({
      address: address ?? zeroAddress,
      abi: UNISWAP_V3_POOL_ABI,
      functionName: "slot0",
    })),
    scopeKey: JSON.stringify(uniswapFactoryPools?.map(({ address }) => address)),
    query: {
      enabled: Boolean(uniswapFactoryPools),
    },
  })

  useEffect(() => {
    const intervalId = setInterval(() => refetchSlot0(), 4000)

    return () => clearInterval(intervalId)
  }, [refetchSlot0])

  const { data: token0data, isLoading: isToken0Loading } = useReadContracts({
    contracts: uniswapFactoryPools?.map(({ address }) => ({
      address: address ?? zeroAddress,
      abi: UNISWAP_V3_POOL_ABI,
      functionName: "token0",
    })),
    scopeKey: JSON.stringify(uniswapFactoryPools?.map(({ address }) => address)),
    query: {
      enabled: Boolean(uniswapFactoryPools),
      staleTime: Infinity,
    },
  })

  const { data: token1data, isLoading: isToken1Loading } = useReadContracts({
    contracts: uniswapFactoryPools?.map(({ address }) => ({
      address: address ?? zeroAddress,
      abi: UNISWAP_V3_POOL_ABI,
      functionName: "token1",
    })),
    scopeKey: JSON.stringify(uniswapFactoryPools?.map(({ address }) => address)),

    query: { enabled: Boolean(uniswapFactoryPools), staleTime: Infinity },
  })

  const { data: feeData, isLoading: isFeeLoading } = useReadContracts({
    contracts: uniswapFactoryPools?.map(({ address }) => ({
      address: address ?? zeroAddress,
      abi: UNISWAP_V3_POOL_ABI,
      functionName: "fee",
    })),
    scopeKey: JSON.stringify(uniswapFactoryPools?.map(({ address }) => address)),

    query: { enabled: Boolean(uniswapFactoryPools), staleTime: Infinity },
  })

  const uniswapPools: UniswapPool[] = useMemo(() => {
    if (!uniswapFactoryPools) return []

    return uniswapFactoryPools.map((pool, index) => ({
      ...pool,
      token0: token0data?.[index]?.result as Address,
      token1: token1data?.[index]?.result as Address,
      fee: feeData?.[index]?.result as UniswapPool["fee"],
      sqrtPriceStartX96: (slot0data?.[index] as { result: readonly bigint[] })
        ?.result?.[0],
      obsCarMin: observationCardinalityMinimum,
      obsCarNext: (slot0data?.[index] as { result: readonly number[] })
        ?.result?.[4] as number,
      isOCNIncreaseRequired:
        Number((slot0data?.[index] as { result: readonly number[] })?.result?.[4]) <
        Number(observationCardinalityMinimum),
    }))
  }, [
    uniswapFactoryPools,
    observationCardinalityMinimum,
    token0data,
    token1data,
    slot0data,
    feeData,
  ])

  return {
    uniswapPools,
    isUniswapPool,
    isMarginalPool,
    isLoading:
      isFactoryLoading &&
      isSlot0Loading &&
      isToken0Loading &&
      isToken1Loading &&
      isFeeLoading &&
      isOCLoading,
  }
}

export default useUniswapV3Pools
