import { isUndefined } from "lodash"

/**
 * Returns the appropriate CSS color class based on profit/loss status
 * @param isProfit boolean indicating if there's a profit, or undefined if not applicable
 * @returns CSS color class string
 */
export const getProfitLossIndicatorColor = (isProfit: boolean | undefined): string => {
  if (isUndefined(isProfit)) return "text-marginalGray-200"
  return isProfit ? "text-success-500" : "text-error-500"
}
