import { formatBigIntToString } from "src/utils/formatBigIntToString"
import { convertMaintenanceToLeverage } from "src/utils/conversions/convertMaintenanceToLeverage"
import { calculatePositionLeverage } from "src/utils/conversions/calculatePositionLeverage"
import { PoolData, Token } from "src/types"
import { type PositionState } from "src/pages/positions/hooks/usePositionsOnChainState"
import { calculatePositionHealthFactor } from "src/utils/conversions/calculatePositionHealthFactor"

interface DerivedPositionData {
  size: {
    raw: bigint | undefined
    parsed: string | undefined
  }
  margin: {
    raw: bigint | undefined
    parsed: string | undefined
  }
  totalSize: {
    raw: bigint | undefined
    parsed: string | undefined
  }
  debt: {
    raw: bigint | undefined
    parsed: string | undefined
  }
  safeMarginMin: {
    raw: bigint | undefined
    parsed: string | undefined
  }
  rewards: {
    raw: bigint | undefined
    parsed: string | undefined
  }
  leverage: {
    max: number | undefined | null
    current: number | undefined
  }
  healthFactor: number | null
  netQuotedAmountOut?: bigint
  tokenId: string | undefined
  positionId: bigint | undefined
  zeroForOne: boolean | undefined
  liquidated: boolean | undefined
  safe: boolean | undefined
}

export const derivePositionData = (
  position: PositionState | undefined | null,
  pool: PoolData | undefined | null,
  marginToken: Token | undefined | null,
  debtToken: Token | undefined | null,
): DerivedPositionData => {
  if (!position || !pool) {
    return {
      size: { raw: undefined, parsed: undefined },
      margin: { raw: undefined, parsed: undefined },
      totalSize: { raw: undefined, parsed: undefined },
      debt: { raw: undefined, parsed: undefined },
      safeMarginMin: { raw: undefined, parsed: undefined },
      rewards: { raw: undefined, parsed: undefined },
      leverage: { max: undefined, current: undefined },
      tokenId: undefined,
      positionId: undefined,
      zeroForOne: undefined,
      liquidated: undefined,
      safe: undefined,
      healthFactor: null,
    }
  }

  const size = {
    raw: position.size,
    parsed: formatBigIntToString(position.size, marginToken?.decimals),
  }

  const margin = {
    raw: position.margin,
    parsed: formatBigIntToString(position.margin, marginToken?.decimals),
  }

  const totalSize = {
    raw: position.margin + position.size,
    parsed: formatBigIntToString(position.margin + position.size, marginToken?.decimals),
  }

  const debt = {
    raw: position.debt,
    parsed: formatBigIntToString(position.debt, debtToken?.decimals),
  }

  const safeMarginMin = {
    raw: position.safeMarginMinimum,
    parsed: formatBigIntToString(position.safeMarginMinimum, marginToken?.decimals),
  }

  const rewards = {
    raw: position.rewards,
    parsed: formatBigIntToString(position.rewards, 18),
  }

  const maxLeverage = pool ? convertMaintenanceToLeverage(pool?.maintenance) : undefined

  const currentLeverage = calculatePositionLeverage(position.margin, position.size)

  const leverage = {
    max: maxLeverage,
    current: Number(currentLeverage),
  }

  const healthFactor = calculatePositionHealthFactor(
    position?.margin,
    position?.safeMarginMinimum,
  )

  return {
    size,
    margin,
    totalSize,
    debt,
    safeMarginMin,
    rewards,
    leverage,
    healthFactor,
    tokenId: position.tokenId,
    positionId: position.positionId,
    zeroForOne: position.zeroForOne,
    liquidated: position.liquidated,
    safe: position.safe,
  }
}
