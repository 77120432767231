import { PoolData } from "src/types"
import { PoolLiquidityLocked } from "src/pages/pools/hooks/useLiquidityLockedInPools"
import { PoolBalance } from "src/pages/pool/hooks/useUserPoolBalance"
import { PoolTotalSupply } from "src/pages/pools/hooks/useTotalSupplyInPools"
import { type PoolState } from "src/pages/pools/hooks/useCurrentPoolsState"
import { TokenBalance } from "src/pages/pools/hooks/useMultipleErc20TokenBalances"
import { PoolRowContent } from "src/pages/pools/components/PoolRowContent"
import { MobilePoolRowContent } from "src/pages/pools/components/MobilePoolRowContent"

export interface PoolRowProps {
  chainId: number
  pool: PoolData
  liquidityLocked?: PoolLiquidityLocked
  userPoolBalance?: PoolBalance
  poolTotalSupply?: PoolTotalSupply
  poolState?: PoolState
  poolTokenBalances?: TokenBalance[]
}

export const PoolRow = ({
  chainId,
  pool,
  liquidityLocked,
  userPoolBalance,
  poolTotalSupply,
  poolState,
  poolTokenBalances,
}: PoolRowProps) => {
  return (
    <>
      <MobilePoolRowContent
        chainId={chainId}
        pool={pool}
        liquidityLocked={liquidityLocked}
        userPoolBalance={userPoolBalance}
        poolTotalSupply={poolTotalSupply}
        poolState={poolState}
        poolTokenBalances={poolTokenBalances}
      />
      <PoolRowContent
        chainId={chainId}
        pool={pool}
        liquidityLocked={liquidityLocked}
        userPoolBalance={userPoolBalance}
        poolTotalSupply={poolTotalSupply}
        poolState={poolState}
        poolTokenBalances={poolTokenBalances}
      />
    </>
  )
}
