import { NavLink } from "react-router-dom"
import { DOCS_URL } from "src/constants/links"

export const NavLinks = () => {
  return (
    <nav className="flex flex-wrap justify-center">
      <StyledNavLink to="/">trade</StyledNavLink>
      <StyledNavLink to="/swap">swap</StyledNavLink>
      <StyledNavLink to="/positions">positions</StyledNavLink>
      <StyledNavLink to="/pools">pools</StyledNavLink>
      <StyledNavLink to="/launch">launch</StyledNavLink>
      <StyledNavLink to={DOCS_URL} external={true}>
        docs
      </StyledNavLink>
    </nav>
  )
}

const StyledNavLink = ({
  to,
  external,
  children,
}: {
  to: string
  external?: boolean
  children: React.ReactNode
}) => {
  const sharedClass = "text-sm font-bold px-5"

  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        isActive
          ? `text-marginalOrange-500 ${sharedClass}`
          : `text-marginalGray-100 ${sharedClass}`
      }
      target={external ? "_blank" : undefined}
    >
      {children}
    </NavLink>
  )
}
