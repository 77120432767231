import { api } from "./api-lb"
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never
}
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  BigDecimal: { input: any; output: any }
  BigInt: { input: any; output: any }
  Bytes: { input: any; output: any }
  Int8: { input: any; output: any }
  Timestamp: { input: any; output: any }
}

export type Adjust = {
  __typename?: "Adjust"
  id: Scalars["ID"]["output"]
  margin: Scalars["BigInt"]["output"]
  owner: Scalars["Bytes"]["output"]
  pool: Pool
  recipient: Scalars["Bytes"]["output"]
  timestamp: Scalars["BigInt"]["output"]
  token0: Token
  token1: Token
  transaction: Transaction
}

export type Adjust_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>
  and?: InputMaybe<Array<InputMaybe<Adjust_Filter>>>
  id?: InputMaybe<Scalars["ID"]["input"]>
  id_gt?: InputMaybe<Scalars["ID"]["input"]>
  id_gte?: InputMaybe<Scalars["ID"]["input"]>
  id_in?: InputMaybe<Array<Scalars["ID"]["input"]>>
  id_lt?: InputMaybe<Scalars["ID"]["input"]>
  id_lte?: InputMaybe<Scalars["ID"]["input"]>
  id_not?: InputMaybe<Scalars["ID"]["input"]>
  id_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>
  margin?: InputMaybe<Scalars["BigInt"]["input"]>
  margin_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  margin_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  margin_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  margin_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  margin_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  margin_not?: InputMaybe<Scalars["BigInt"]["input"]>
  margin_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  or?: InputMaybe<Array<InputMaybe<Adjust_Filter>>>
  owner?: InputMaybe<Scalars["Bytes"]["input"]>
  owner_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  owner_gt?: InputMaybe<Scalars["Bytes"]["input"]>
  owner_gte?: InputMaybe<Scalars["Bytes"]["input"]>
  owner_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  owner_lt?: InputMaybe<Scalars["Bytes"]["input"]>
  owner_lte?: InputMaybe<Scalars["Bytes"]["input"]>
  owner_not?: InputMaybe<Scalars["Bytes"]["input"]>
  owner_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  owner_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  pool?: InputMaybe<Scalars["String"]["input"]>
  pool_?: InputMaybe<Pool_Filter>
  pool_contains?: InputMaybe<Scalars["String"]["input"]>
  pool_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  pool_ends_with?: InputMaybe<Scalars["String"]["input"]>
  pool_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  pool_gt?: InputMaybe<Scalars["String"]["input"]>
  pool_gte?: InputMaybe<Scalars["String"]["input"]>
  pool_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  pool_lt?: InputMaybe<Scalars["String"]["input"]>
  pool_lte?: InputMaybe<Scalars["String"]["input"]>
  pool_not?: InputMaybe<Scalars["String"]["input"]>
  pool_not_contains?: InputMaybe<Scalars["String"]["input"]>
  pool_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  pool_not_ends_with?: InputMaybe<Scalars["String"]["input"]>
  pool_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  pool_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  pool_not_starts_with?: InputMaybe<Scalars["String"]["input"]>
  pool_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  pool_starts_with?: InputMaybe<Scalars["String"]["input"]>
  pool_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  recipient?: InputMaybe<Scalars["Bytes"]["input"]>
  recipient_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  recipient_gt?: InputMaybe<Scalars["Bytes"]["input"]>
  recipient_gte?: InputMaybe<Scalars["Bytes"]["input"]>
  recipient_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  recipient_lt?: InputMaybe<Scalars["Bytes"]["input"]>
  recipient_lte?: InputMaybe<Scalars["Bytes"]["input"]>
  recipient_not?: InputMaybe<Scalars["Bytes"]["input"]>
  recipient_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  recipient_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  timestamp?: InputMaybe<Scalars["BigInt"]["input"]>
  timestamp_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  timestamp_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  timestamp_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  timestamp_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  timestamp_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  timestamp_not?: InputMaybe<Scalars["BigInt"]["input"]>
  timestamp_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  token0?: InputMaybe<Scalars["String"]["input"]>
  token0_?: InputMaybe<Token_Filter>
  token0_contains?: InputMaybe<Scalars["String"]["input"]>
  token0_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  token0_ends_with?: InputMaybe<Scalars["String"]["input"]>
  token0_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  token0_gt?: InputMaybe<Scalars["String"]["input"]>
  token0_gte?: InputMaybe<Scalars["String"]["input"]>
  token0_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  token0_lt?: InputMaybe<Scalars["String"]["input"]>
  token0_lte?: InputMaybe<Scalars["String"]["input"]>
  token0_not?: InputMaybe<Scalars["String"]["input"]>
  token0_not_contains?: InputMaybe<Scalars["String"]["input"]>
  token0_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  token0_not_ends_with?: InputMaybe<Scalars["String"]["input"]>
  token0_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  token0_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  token0_not_starts_with?: InputMaybe<Scalars["String"]["input"]>
  token0_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  token0_starts_with?: InputMaybe<Scalars["String"]["input"]>
  token0_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  token1?: InputMaybe<Scalars["String"]["input"]>
  token1_?: InputMaybe<Token_Filter>
  token1_contains?: InputMaybe<Scalars["String"]["input"]>
  token1_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  token1_ends_with?: InputMaybe<Scalars["String"]["input"]>
  token1_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  token1_gt?: InputMaybe<Scalars["String"]["input"]>
  token1_gte?: InputMaybe<Scalars["String"]["input"]>
  token1_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  token1_lt?: InputMaybe<Scalars["String"]["input"]>
  token1_lte?: InputMaybe<Scalars["String"]["input"]>
  token1_not?: InputMaybe<Scalars["String"]["input"]>
  token1_not_contains?: InputMaybe<Scalars["String"]["input"]>
  token1_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  token1_not_ends_with?: InputMaybe<Scalars["String"]["input"]>
  token1_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  token1_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  token1_not_starts_with?: InputMaybe<Scalars["String"]["input"]>
  token1_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  token1_starts_with?: InputMaybe<Scalars["String"]["input"]>
  token1_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  transaction?: InputMaybe<Scalars["String"]["input"]>
  transaction_?: InputMaybe<Transaction_Filter>
  transaction_contains?: InputMaybe<Scalars["String"]["input"]>
  transaction_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  transaction_ends_with?: InputMaybe<Scalars["String"]["input"]>
  transaction_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  transaction_gt?: InputMaybe<Scalars["String"]["input"]>
  transaction_gte?: InputMaybe<Scalars["String"]["input"]>
  transaction_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  transaction_lt?: InputMaybe<Scalars["String"]["input"]>
  transaction_lte?: InputMaybe<Scalars["String"]["input"]>
  transaction_not?: InputMaybe<Scalars["String"]["input"]>
  transaction_not_contains?: InputMaybe<Scalars["String"]["input"]>
  transaction_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  transaction_not_ends_with?: InputMaybe<Scalars["String"]["input"]>
  transaction_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  transaction_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  transaction_not_starts_with?: InputMaybe<Scalars["String"]["input"]>
  transaction_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  transaction_starts_with?: InputMaybe<Scalars["String"]["input"]>
  transaction_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
}

export enum Adjust_OrderBy {
  Id = "id",
  Margin = "margin",
  Owner = "owner",
  Pool = "pool",
  PoolAddress = "pool__address",
  PoolCreatedAtBlockNumber = "pool__createdAtBlockNumber",
  PoolCreatedAtTimestamp = "pool__createdAtTimestamp",
  PoolDecimals = "pool__decimals",
  PoolFee = "pool__fee",
  PoolId = "pool__id",
  PoolLiquidityLocked = "pool__liquidityLocked",
  PoolMaintenance = "pool__maintenance",
  PoolOracle = "pool__oracle",
  PoolRewardPremium = "pool__rewardPremium",
  PoolTokenPair = "pool__tokenPair",
  PoolTxCount = "pool__txCount",
  Recipient = "recipient",
  Timestamp = "timestamp",
  Token0 = "token0",
  Token0Address = "token0__address",
  Token0ChainId = "token0__chainId",
  Token0Decimals = "token0__decimals",
  Token0Id = "token0__id",
  Token0Name = "token0__name",
  Token0Symbol = "token0__symbol",
  Token1 = "token1",
  Token1Address = "token1__address",
  Token1ChainId = "token1__chainId",
  Token1Decimals = "token1__decimals",
  Token1Id = "token1__id",
  Token1Name = "token1__name",
  Token1Symbol = "token1__symbol",
  Transaction = "transaction",
  TransactionBlockNumber = "transaction__blockNumber",
  TransactionGasLimit = "transaction__gasLimit",
  TransactionGasPrice = "transaction__gasPrice",
  TransactionId = "transaction__id",
  TransactionTimestamp = "transaction__timestamp",
}

export enum Aggregation_Interval {
  Day = "day",
  Hour = "hour",
}

export type BlockChangedFilter = {
  number_gte: Scalars["Int"]["input"]
}

export type Block_Height = {
  hash?: InputMaybe<Scalars["Bytes"]["input"]>
  number?: InputMaybe<Scalars["Int"]["input"]>
  number_gte?: InputMaybe<Scalars["Int"]["input"]>
}

export type Factory = {
  __typename?: "Factory"
  deployer: Scalars["ID"]["output"]
  id: Scalars["ID"]["output"]
  minCardinality: Scalars["BigInt"]["output"]
  owner: Scalars["ID"]["output"]
  poolCount: Scalars["BigInt"]["output"]
  pools: Array<Pool>
  txCount: Scalars["BigInt"]["output"]
  uniV3Factory: Scalars["ID"]["output"]
}

export type FactoryPoolsArgs = {
  first?: InputMaybe<Scalars["Int"]["input"]>
  orderBy?: InputMaybe<Pool_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars["Int"]["input"]>
  where?: InputMaybe<Pool_Filter>
}

export type Factory_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>
  and?: InputMaybe<Array<InputMaybe<Factory_Filter>>>
  deployer?: InputMaybe<Scalars["ID"]["input"]>
  deployer_gt?: InputMaybe<Scalars["ID"]["input"]>
  deployer_gte?: InputMaybe<Scalars["ID"]["input"]>
  deployer_in?: InputMaybe<Array<Scalars["ID"]["input"]>>
  deployer_lt?: InputMaybe<Scalars["ID"]["input"]>
  deployer_lte?: InputMaybe<Scalars["ID"]["input"]>
  deployer_not?: InputMaybe<Scalars["ID"]["input"]>
  deployer_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>
  id?: InputMaybe<Scalars["ID"]["input"]>
  id_gt?: InputMaybe<Scalars["ID"]["input"]>
  id_gte?: InputMaybe<Scalars["ID"]["input"]>
  id_in?: InputMaybe<Array<Scalars["ID"]["input"]>>
  id_lt?: InputMaybe<Scalars["ID"]["input"]>
  id_lte?: InputMaybe<Scalars["ID"]["input"]>
  id_not?: InputMaybe<Scalars["ID"]["input"]>
  id_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>
  minCardinality?: InputMaybe<Scalars["BigInt"]["input"]>
  minCardinality_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  minCardinality_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  minCardinality_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  minCardinality_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  minCardinality_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  minCardinality_not?: InputMaybe<Scalars["BigInt"]["input"]>
  minCardinality_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  or?: InputMaybe<Array<InputMaybe<Factory_Filter>>>
  owner?: InputMaybe<Scalars["ID"]["input"]>
  owner_gt?: InputMaybe<Scalars["ID"]["input"]>
  owner_gte?: InputMaybe<Scalars["ID"]["input"]>
  owner_in?: InputMaybe<Array<Scalars["ID"]["input"]>>
  owner_lt?: InputMaybe<Scalars["ID"]["input"]>
  owner_lte?: InputMaybe<Scalars["ID"]["input"]>
  owner_not?: InputMaybe<Scalars["ID"]["input"]>
  owner_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>
  poolCount?: InputMaybe<Scalars["BigInt"]["input"]>
  poolCount_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  poolCount_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  poolCount_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  poolCount_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  poolCount_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  poolCount_not?: InputMaybe<Scalars["BigInt"]["input"]>
  poolCount_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  pools_?: InputMaybe<Pool_Filter>
  txCount?: InputMaybe<Scalars["BigInt"]["input"]>
  txCount_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  txCount_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  txCount_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  txCount_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  txCount_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  txCount_not?: InputMaybe<Scalars["BigInt"]["input"]>
  txCount_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  uniV3Factory?: InputMaybe<Scalars["ID"]["input"]>
  uniV3Factory_gt?: InputMaybe<Scalars["ID"]["input"]>
  uniV3Factory_gte?: InputMaybe<Scalars["ID"]["input"]>
  uniV3Factory_in?: InputMaybe<Array<Scalars["ID"]["input"]>>
  uniV3Factory_lt?: InputMaybe<Scalars["ID"]["input"]>
  uniV3Factory_lte?: InputMaybe<Scalars["ID"]["input"]>
  uniV3Factory_not?: InputMaybe<Scalars["ID"]["input"]>
  uniV3Factory_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>
}

export enum Factory_OrderBy {
  Deployer = "deployer",
  Id = "id",
  MinCardinality = "minCardinality",
  Owner = "owner",
  PoolCount = "poolCount",
  Pools = "pools",
  TxCount = "txCount",
  UniV3Factory = "uniV3Factory",
}

export type Liquidate = {
  __typename?: "Liquidate"
  id: Scalars["ID"]["output"]
  owner: Scalars["Bytes"]["output"]
  pool: Pool
  recipient: Scalars["Bytes"]["output"]
  rewards: Scalars["BigInt"]["output"]
  timestamp: Scalars["BigInt"]["output"]
  token0: Token
  token1: Token
  transaction: Transaction
}

export type Liquidate_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>
  and?: InputMaybe<Array<InputMaybe<Liquidate_Filter>>>
  id?: InputMaybe<Scalars["ID"]["input"]>
  id_gt?: InputMaybe<Scalars["ID"]["input"]>
  id_gte?: InputMaybe<Scalars["ID"]["input"]>
  id_in?: InputMaybe<Array<Scalars["ID"]["input"]>>
  id_lt?: InputMaybe<Scalars["ID"]["input"]>
  id_lte?: InputMaybe<Scalars["ID"]["input"]>
  id_not?: InputMaybe<Scalars["ID"]["input"]>
  id_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>
  or?: InputMaybe<Array<InputMaybe<Liquidate_Filter>>>
  owner?: InputMaybe<Scalars["Bytes"]["input"]>
  owner_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  owner_gt?: InputMaybe<Scalars["Bytes"]["input"]>
  owner_gte?: InputMaybe<Scalars["Bytes"]["input"]>
  owner_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  owner_lt?: InputMaybe<Scalars["Bytes"]["input"]>
  owner_lte?: InputMaybe<Scalars["Bytes"]["input"]>
  owner_not?: InputMaybe<Scalars["Bytes"]["input"]>
  owner_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  owner_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  pool?: InputMaybe<Scalars["String"]["input"]>
  pool_?: InputMaybe<Pool_Filter>
  pool_contains?: InputMaybe<Scalars["String"]["input"]>
  pool_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  pool_ends_with?: InputMaybe<Scalars["String"]["input"]>
  pool_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  pool_gt?: InputMaybe<Scalars["String"]["input"]>
  pool_gte?: InputMaybe<Scalars["String"]["input"]>
  pool_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  pool_lt?: InputMaybe<Scalars["String"]["input"]>
  pool_lte?: InputMaybe<Scalars["String"]["input"]>
  pool_not?: InputMaybe<Scalars["String"]["input"]>
  pool_not_contains?: InputMaybe<Scalars["String"]["input"]>
  pool_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  pool_not_ends_with?: InputMaybe<Scalars["String"]["input"]>
  pool_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  pool_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  pool_not_starts_with?: InputMaybe<Scalars["String"]["input"]>
  pool_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  pool_starts_with?: InputMaybe<Scalars["String"]["input"]>
  pool_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  recipient?: InputMaybe<Scalars["Bytes"]["input"]>
  recipient_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  recipient_gt?: InputMaybe<Scalars["Bytes"]["input"]>
  recipient_gte?: InputMaybe<Scalars["Bytes"]["input"]>
  recipient_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  recipient_lt?: InputMaybe<Scalars["Bytes"]["input"]>
  recipient_lte?: InputMaybe<Scalars["Bytes"]["input"]>
  recipient_not?: InputMaybe<Scalars["Bytes"]["input"]>
  recipient_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  recipient_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  rewards?: InputMaybe<Scalars["BigInt"]["input"]>
  rewards_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  rewards_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  rewards_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  rewards_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  rewards_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  rewards_not?: InputMaybe<Scalars["BigInt"]["input"]>
  rewards_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  timestamp?: InputMaybe<Scalars["BigInt"]["input"]>
  timestamp_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  timestamp_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  timestamp_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  timestamp_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  timestamp_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  timestamp_not?: InputMaybe<Scalars["BigInt"]["input"]>
  timestamp_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  token0?: InputMaybe<Scalars["String"]["input"]>
  token0_?: InputMaybe<Token_Filter>
  token0_contains?: InputMaybe<Scalars["String"]["input"]>
  token0_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  token0_ends_with?: InputMaybe<Scalars["String"]["input"]>
  token0_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  token0_gt?: InputMaybe<Scalars["String"]["input"]>
  token0_gte?: InputMaybe<Scalars["String"]["input"]>
  token0_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  token0_lt?: InputMaybe<Scalars["String"]["input"]>
  token0_lte?: InputMaybe<Scalars["String"]["input"]>
  token0_not?: InputMaybe<Scalars["String"]["input"]>
  token0_not_contains?: InputMaybe<Scalars["String"]["input"]>
  token0_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  token0_not_ends_with?: InputMaybe<Scalars["String"]["input"]>
  token0_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  token0_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  token0_not_starts_with?: InputMaybe<Scalars["String"]["input"]>
  token0_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  token0_starts_with?: InputMaybe<Scalars["String"]["input"]>
  token0_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  token1?: InputMaybe<Scalars["String"]["input"]>
  token1_?: InputMaybe<Token_Filter>
  token1_contains?: InputMaybe<Scalars["String"]["input"]>
  token1_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  token1_ends_with?: InputMaybe<Scalars["String"]["input"]>
  token1_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  token1_gt?: InputMaybe<Scalars["String"]["input"]>
  token1_gte?: InputMaybe<Scalars["String"]["input"]>
  token1_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  token1_lt?: InputMaybe<Scalars["String"]["input"]>
  token1_lte?: InputMaybe<Scalars["String"]["input"]>
  token1_not?: InputMaybe<Scalars["String"]["input"]>
  token1_not_contains?: InputMaybe<Scalars["String"]["input"]>
  token1_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  token1_not_ends_with?: InputMaybe<Scalars["String"]["input"]>
  token1_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  token1_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  token1_not_starts_with?: InputMaybe<Scalars["String"]["input"]>
  token1_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  token1_starts_with?: InputMaybe<Scalars["String"]["input"]>
  token1_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  transaction?: InputMaybe<Scalars["String"]["input"]>
  transaction_?: InputMaybe<Transaction_Filter>
  transaction_contains?: InputMaybe<Scalars["String"]["input"]>
  transaction_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  transaction_ends_with?: InputMaybe<Scalars["String"]["input"]>
  transaction_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  transaction_gt?: InputMaybe<Scalars["String"]["input"]>
  transaction_gte?: InputMaybe<Scalars["String"]["input"]>
  transaction_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  transaction_lt?: InputMaybe<Scalars["String"]["input"]>
  transaction_lte?: InputMaybe<Scalars["String"]["input"]>
  transaction_not?: InputMaybe<Scalars["String"]["input"]>
  transaction_not_contains?: InputMaybe<Scalars["String"]["input"]>
  transaction_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  transaction_not_ends_with?: InputMaybe<Scalars["String"]["input"]>
  transaction_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  transaction_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  transaction_not_starts_with?: InputMaybe<Scalars["String"]["input"]>
  transaction_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  transaction_starts_with?: InputMaybe<Scalars["String"]["input"]>
  transaction_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
}

export enum Liquidate_OrderBy {
  Id = "id",
  Owner = "owner",
  Pool = "pool",
  PoolAddress = "pool__address",
  PoolCreatedAtBlockNumber = "pool__createdAtBlockNumber",
  PoolCreatedAtTimestamp = "pool__createdAtTimestamp",
  PoolDecimals = "pool__decimals",
  PoolFee = "pool__fee",
  PoolId = "pool__id",
  PoolLiquidityLocked = "pool__liquidityLocked",
  PoolMaintenance = "pool__maintenance",
  PoolOracle = "pool__oracle",
  PoolRewardPremium = "pool__rewardPremium",
  PoolTokenPair = "pool__tokenPair",
  PoolTxCount = "pool__txCount",
  Recipient = "recipient",
  Rewards = "rewards",
  Timestamp = "timestamp",
  Token0 = "token0",
  Token0Address = "token0__address",
  Token0ChainId = "token0__chainId",
  Token0Decimals = "token0__decimals",
  Token0Id = "token0__id",
  Token0Name = "token0__name",
  Token0Symbol = "token0__symbol",
  Token1 = "token1",
  Token1Address = "token1__address",
  Token1ChainId = "token1__chainId",
  Token1Decimals = "token1__decimals",
  Token1Id = "token1__id",
  Token1Name = "token1__name",
  Token1Symbol = "token1__symbol",
  Transaction = "transaction",
  TransactionBlockNumber = "transaction__blockNumber",
  TransactionGasLimit = "transaction__gasLimit",
  TransactionGasPrice = "transaction__gasPrice",
  TransactionId = "transaction__id",
  TransactionTimestamp = "transaction__timestamp",
}

export type MarginalV1LbFactory = {
  __typename?: "MarginalV1LBFactory"
  feeProtocol: Scalars["Int"]["output"]
  id: Scalars["ID"]["output"]
  marginalV1Deployer: Scalars["ID"]["output"]
  owner: Scalars["ID"]["output"]
  poolCount: Scalars["BigInt"]["output"]
  pools: Array<MarginalV1LbPool>
  txCount: Scalars["BigInt"]["output"]
}

export type MarginalV1LbFactoryPoolsArgs = {
  first?: InputMaybe<Scalars["Int"]["input"]>
  orderBy?: InputMaybe<MarginalV1LbPool_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars["Int"]["input"]>
  where?: InputMaybe<MarginalV1LbPool_Filter>
}

export type MarginalV1LbFactoryCollectProtocol = {
  __typename?: "MarginalV1LBFactoryCollectProtocol"
  amount: Scalars["BigInt"]["output"]
  blockNumber: Scalars["BigInt"]["output"]
  blockTimestamp: Scalars["BigInt"]["output"]
  id: Scalars["Bytes"]["output"]
  recipient: Scalars["Bytes"]["output"]
  sender: Scalars["Bytes"]["output"]
  token: Scalars["Bytes"]["output"]
  transactionHash: Scalars["Bytes"]["output"]
}

export type MarginalV1LbFactoryCollectProtocol_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>
  amount?: InputMaybe<Scalars["BigInt"]["input"]>
  amount_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  amount_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  amount_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  amount_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  amount_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  amount_not?: InputMaybe<Scalars["BigInt"]["input"]>
  amount_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  and?: InputMaybe<Array<InputMaybe<MarginalV1LbFactoryCollectProtocol_Filter>>>
  blockNumber?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  blockNumber_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_not?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  blockTimestamp?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  blockTimestamp_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_not?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  id?: InputMaybe<Scalars["Bytes"]["input"]>
  id_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  id_gt?: InputMaybe<Scalars["Bytes"]["input"]>
  id_gte?: InputMaybe<Scalars["Bytes"]["input"]>
  id_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  id_lt?: InputMaybe<Scalars["Bytes"]["input"]>
  id_lte?: InputMaybe<Scalars["Bytes"]["input"]>
  id_not?: InputMaybe<Scalars["Bytes"]["input"]>
  id_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  id_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  or?: InputMaybe<Array<InputMaybe<MarginalV1LbFactoryCollectProtocol_Filter>>>
  recipient?: InputMaybe<Scalars["Bytes"]["input"]>
  recipient_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  recipient_gt?: InputMaybe<Scalars["Bytes"]["input"]>
  recipient_gte?: InputMaybe<Scalars["Bytes"]["input"]>
  recipient_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  recipient_lt?: InputMaybe<Scalars["Bytes"]["input"]>
  recipient_lte?: InputMaybe<Scalars["Bytes"]["input"]>
  recipient_not?: InputMaybe<Scalars["Bytes"]["input"]>
  recipient_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  recipient_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  sender?: InputMaybe<Scalars["Bytes"]["input"]>
  sender_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  sender_gt?: InputMaybe<Scalars["Bytes"]["input"]>
  sender_gte?: InputMaybe<Scalars["Bytes"]["input"]>
  sender_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  sender_lt?: InputMaybe<Scalars["Bytes"]["input"]>
  sender_lte?: InputMaybe<Scalars["Bytes"]["input"]>
  sender_not?: InputMaybe<Scalars["Bytes"]["input"]>
  sender_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  sender_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  token?: InputMaybe<Scalars["Bytes"]["input"]>
  token_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  token_gt?: InputMaybe<Scalars["Bytes"]["input"]>
  token_gte?: InputMaybe<Scalars["Bytes"]["input"]>
  token_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  token_lt?: InputMaybe<Scalars["Bytes"]["input"]>
  token_lte?: InputMaybe<Scalars["Bytes"]["input"]>
  token_not?: InputMaybe<Scalars["Bytes"]["input"]>
  token_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  token_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  transactionHash?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_gt?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_gte?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  transactionHash_lt?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_lte?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_not?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
}

export enum MarginalV1LbFactoryCollectProtocol_OrderBy {
  Amount = "amount",
  BlockNumber = "blockNumber",
  BlockTimestamp = "blockTimestamp",
  Id = "id",
  Recipient = "recipient",
  Sender = "sender",
  Token = "token",
  TransactionHash = "transactionHash",
}

export type MarginalV1LbFactoryOwnerChanged = {
  __typename?: "MarginalV1LBFactoryOwnerChanged"
  blockNumber: Scalars["BigInt"]["output"]
  blockTimestamp: Scalars["BigInt"]["output"]
  id: Scalars["Bytes"]["output"]
  newOwner: Scalars["Bytes"]["output"]
  oldOwner: Scalars["Bytes"]["output"]
  transactionHash: Scalars["Bytes"]["output"]
}

export type MarginalV1LbFactoryOwnerChanged_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>
  and?: InputMaybe<Array<InputMaybe<MarginalV1LbFactoryOwnerChanged_Filter>>>
  blockNumber?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  blockNumber_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_not?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  blockTimestamp?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  blockTimestamp_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_not?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  id?: InputMaybe<Scalars["Bytes"]["input"]>
  id_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  id_gt?: InputMaybe<Scalars["Bytes"]["input"]>
  id_gte?: InputMaybe<Scalars["Bytes"]["input"]>
  id_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  id_lt?: InputMaybe<Scalars["Bytes"]["input"]>
  id_lte?: InputMaybe<Scalars["Bytes"]["input"]>
  id_not?: InputMaybe<Scalars["Bytes"]["input"]>
  id_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  id_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  newOwner?: InputMaybe<Scalars["Bytes"]["input"]>
  newOwner_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  newOwner_gt?: InputMaybe<Scalars["Bytes"]["input"]>
  newOwner_gte?: InputMaybe<Scalars["Bytes"]["input"]>
  newOwner_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  newOwner_lt?: InputMaybe<Scalars["Bytes"]["input"]>
  newOwner_lte?: InputMaybe<Scalars["Bytes"]["input"]>
  newOwner_not?: InputMaybe<Scalars["Bytes"]["input"]>
  newOwner_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  newOwner_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  oldOwner?: InputMaybe<Scalars["Bytes"]["input"]>
  oldOwner_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  oldOwner_gt?: InputMaybe<Scalars["Bytes"]["input"]>
  oldOwner_gte?: InputMaybe<Scalars["Bytes"]["input"]>
  oldOwner_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  oldOwner_lt?: InputMaybe<Scalars["Bytes"]["input"]>
  oldOwner_lte?: InputMaybe<Scalars["Bytes"]["input"]>
  oldOwner_not?: InputMaybe<Scalars["Bytes"]["input"]>
  oldOwner_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  oldOwner_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  or?: InputMaybe<Array<InputMaybe<MarginalV1LbFactoryOwnerChanged_Filter>>>
  transactionHash?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_gt?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_gte?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  transactionHash_lt?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_lte?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_not?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
}

export enum MarginalV1LbFactoryOwnerChanged_OrderBy {
  BlockNumber = "blockNumber",
  BlockTimestamp = "blockTimestamp",
  Id = "id",
  NewOwner = "newOwner",
  OldOwner = "oldOwner",
  TransactionHash = "transactionHash",
}

export type MarginalV1LbFactoryPoolCreated = {
  __typename?: "MarginalV1LBFactoryPoolCreated"
  blockNumber: Scalars["BigInt"]["output"]
  blockTimestamp: Scalars["BigInt"]["output"]
  blockTimestampInitialize: Scalars["BigInt"]["output"]
  id: Scalars["Bytes"]["output"]
  pool: Scalars["Bytes"]["output"]
  supplier: Scalars["Bytes"]["output"]
  tickLower: Scalars["Int"]["output"]
  tickUpper: Scalars["Int"]["output"]
  token0: Scalars["Bytes"]["output"]
  token1: Scalars["Bytes"]["output"]
  transactionHash: Scalars["Bytes"]["output"]
}

export type MarginalV1LbFactoryPoolCreated_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>
  and?: InputMaybe<Array<InputMaybe<MarginalV1LbFactoryPoolCreated_Filter>>>
  blockNumber?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  blockNumber_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_not?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  blockTimestamp?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestampInitialize?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestampInitialize_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestampInitialize_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestampInitialize_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  blockTimestampInitialize_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestampInitialize_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestampInitialize_not?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestampInitialize_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  blockTimestamp_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  blockTimestamp_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_not?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  id?: InputMaybe<Scalars["Bytes"]["input"]>
  id_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  id_gt?: InputMaybe<Scalars["Bytes"]["input"]>
  id_gte?: InputMaybe<Scalars["Bytes"]["input"]>
  id_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  id_lt?: InputMaybe<Scalars["Bytes"]["input"]>
  id_lte?: InputMaybe<Scalars["Bytes"]["input"]>
  id_not?: InputMaybe<Scalars["Bytes"]["input"]>
  id_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  id_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  or?: InputMaybe<Array<InputMaybe<MarginalV1LbFactoryPoolCreated_Filter>>>
  pool?: InputMaybe<Scalars["Bytes"]["input"]>
  pool_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  pool_gt?: InputMaybe<Scalars["Bytes"]["input"]>
  pool_gte?: InputMaybe<Scalars["Bytes"]["input"]>
  pool_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  pool_lt?: InputMaybe<Scalars["Bytes"]["input"]>
  pool_lte?: InputMaybe<Scalars["Bytes"]["input"]>
  pool_not?: InputMaybe<Scalars["Bytes"]["input"]>
  pool_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  pool_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  supplier?: InputMaybe<Scalars["Bytes"]["input"]>
  supplier_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  supplier_gt?: InputMaybe<Scalars["Bytes"]["input"]>
  supplier_gte?: InputMaybe<Scalars["Bytes"]["input"]>
  supplier_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  supplier_lt?: InputMaybe<Scalars["Bytes"]["input"]>
  supplier_lte?: InputMaybe<Scalars["Bytes"]["input"]>
  supplier_not?: InputMaybe<Scalars["Bytes"]["input"]>
  supplier_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  supplier_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  tickLower?: InputMaybe<Scalars["Int"]["input"]>
  tickLower_gt?: InputMaybe<Scalars["Int"]["input"]>
  tickLower_gte?: InputMaybe<Scalars["Int"]["input"]>
  tickLower_in?: InputMaybe<Array<Scalars["Int"]["input"]>>
  tickLower_lt?: InputMaybe<Scalars["Int"]["input"]>
  tickLower_lte?: InputMaybe<Scalars["Int"]["input"]>
  tickLower_not?: InputMaybe<Scalars["Int"]["input"]>
  tickLower_not_in?: InputMaybe<Array<Scalars["Int"]["input"]>>
  tickUpper?: InputMaybe<Scalars["Int"]["input"]>
  tickUpper_gt?: InputMaybe<Scalars["Int"]["input"]>
  tickUpper_gte?: InputMaybe<Scalars["Int"]["input"]>
  tickUpper_in?: InputMaybe<Array<Scalars["Int"]["input"]>>
  tickUpper_lt?: InputMaybe<Scalars["Int"]["input"]>
  tickUpper_lte?: InputMaybe<Scalars["Int"]["input"]>
  tickUpper_not?: InputMaybe<Scalars["Int"]["input"]>
  tickUpper_not_in?: InputMaybe<Array<Scalars["Int"]["input"]>>
  token0?: InputMaybe<Scalars["Bytes"]["input"]>
  token0_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  token0_gt?: InputMaybe<Scalars["Bytes"]["input"]>
  token0_gte?: InputMaybe<Scalars["Bytes"]["input"]>
  token0_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  token0_lt?: InputMaybe<Scalars["Bytes"]["input"]>
  token0_lte?: InputMaybe<Scalars["Bytes"]["input"]>
  token0_not?: InputMaybe<Scalars["Bytes"]["input"]>
  token0_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  token0_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  token1?: InputMaybe<Scalars["Bytes"]["input"]>
  token1_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  token1_gt?: InputMaybe<Scalars["Bytes"]["input"]>
  token1_gte?: InputMaybe<Scalars["Bytes"]["input"]>
  token1_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  token1_lt?: InputMaybe<Scalars["Bytes"]["input"]>
  token1_lte?: InputMaybe<Scalars["Bytes"]["input"]>
  token1_not?: InputMaybe<Scalars["Bytes"]["input"]>
  token1_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  token1_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  transactionHash?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_gt?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_gte?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  transactionHash_lt?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_lte?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_not?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
}

export enum MarginalV1LbFactoryPoolCreated_OrderBy {
  BlockNumber = "blockNumber",
  BlockTimestamp = "blockTimestamp",
  BlockTimestampInitialize = "blockTimestampInitialize",
  Id = "id",
  Pool = "pool",
  Supplier = "supplier",
  TickLower = "tickLower",
  TickUpper = "tickUpper",
  Token0 = "token0",
  Token1 = "token1",
  TransactionHash = "transactionHash",
}

export type MarginalV1LbFactorySetFeeProtocol = {
  __typename?: "MarginalV1LBFactorySetFeeProtocol"
  blockNumber: Scalars["BigInt"]["output"]
  blockTimestamp: Scalars["BigInt"]["output"]
  id: Scalars["Bytes"]["output"]
  newFeeProtocol: Scalars["Int"]["output"]
  oldFeeProtocol: Scalars["Int"]["output"]
  transactionHash: Scalars["Bytes"]["output"]
}

export type MarginalV1LbFactorySetFeeProtocol_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>
  and?: InputMaybe<Array<InputMaybe<MarginalV1LbFactorySetFeeProtocol_Filter>>>
  blockNumber?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  blockNumber_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_not?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  blockTimestamp?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  blockTimestamp_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_not?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  id?: InputMaybe<Scalars["Bytes"]["input"]>
  id_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  id_gt?: InputMaybe<Scalars["Bytes"]["input"]>
  id_gte?: InputMaybe<Scalars["Bytes"]["input"]>
  id_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  id_lt?: InputMaybe<Scalars["Bytes"]["input"]>
  id_lte?: InputMaybe<Scalars["Bytes"]["input"]>
  id_not?: InputMaybe<Scalars["Bytes"]["input"]>
  id_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  id_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  newFeeProtocol?: InputMaybe<Scalars["Int"]["input"]>
  newFeeProtocol_gt?: InputMaybe<Scalars["Int"]["input"]>
  newFeeProtocol_gte?: InputMaybe<Scalars["Int"]["input"]>
  newFeeProtocol_in?: InputMaybe<Array<Scalars["Int"]["input"]>>
  newFeeProtocol_lt?: InputMaybe<Scalars["Int"]["input"]>
  newFeeProtocol_lte?: InputMaybe<Scalars["Int"]["input"]>
  newFeeProtocol_not?: InputMaybe<Scalars["Int"]["input"]>
  newFeeProtocol_not_in?: InputMaybe<Array<Scalars["Int"]["input"]>>
  oldFeeProtocol?: InputMaybe<Scalars["Int"]["input"]>
  oldFeeProtocol_gt?: InputMaybe<Scalars["Int"]["input"]>
  oldFeeProtocol_gte?: InputMaybe<Scalars["Int"]["input"]>
  oldFeeProtocol_in?: InputMaybe<Array<Scalars["Int"]["input"]>>
  oldFeeProtocol_lt?: InputMaybe<Scalars["Int"]["input"]>
  oldFeeProtocol_lte?: InputMaybe<Scalars["Int"]["input"]>
  oldFeeProtocol_not?: InputMaybe<Scalars["Int"]["input"]>
  oldFeeProtocol_not_in?: InputMaybe<Array<Scalars["Int"]["input"]>>
  or?: InputMaybe<Array<InputMaybe<MarginalV1LbFactorySetFeeProtocol_Filter>>>
  transactionHash?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_gt?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_gte?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  transactionHash_lt?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_lte?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_not?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
}

export enum MarginalV1LbFactorySetFeeProtocol_OrderBy {
  BlockNumber = "blockNumber",
  BlockTimestamp = "blockTimestamp",
  Id = "id",
  NewFeeProtocol = "newFeeProtocol",
  OldFeeProtocol = "oldFeeProtocol",
  TransactionHash = "transactionHash",
}

export type MarginalV1LbFactory_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>
  and?: InputMaybe<Array<InputMaybe<MarginalV1LbFactory_Filter>>>
  feeProtocol?: InputMaybe<Scalars["Int"]["input"]>
  feeProtocol_gt?: InputMaybe<Scalars["Int"]["input"]>
  feeProtocol_gte?: InputMaybe<Scalars["Int"]["input"]>
  feeProtocol_in?: InputMaybe<Array<Scalars["Int"]["input"]>>
  feeProtocol_lt?: InputMaybe<Scalars["Int"]["input"]>
  feeProtocol_lte?: InputMaybe<Scalars["Int"]["input"]>
  feeProtocol_not?: InputMaybe<Scalars["Int"]["input"]>
  feeProtocol_not_in?: InputMaybe<Array<Scalars["Int"]["input"]>>
  id?: InputMaybe<Scalars["ID"]["input"]>
  id_gt?: InputMaybe<Scalars["ID"]["input"]>
  id_gte?: InputMaybe<Scalars["ID"]["input"]>
  id_in?: InputMaybe<Array<Scalars["ID"]["input"]>>
  id_lt?: InputMaybe<Scalars["ID"]["input"]>
  id_lte?: InputMaybe<Scalars["ID"]["input"]>
  id_not?: InputMaybe<Scalars["ID"]["input"]>
  id_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>
  marginalV1Deployer?: InputMaybe<Scalars["ID"]["input"]>
  marginalV1Deployer_gt?: InputMaybe<Scalars["ID"]["input"]>
  marginalV1Deployer_gte?: InputMaybe<Scalars["ID"]["input"]>
  marginalV1Deployer_in?: InputMaybe<Array<Scalars["ID"]["input"]>>
  marginalV1Deployer_lt?: InputMaybe<Scalars["ID"]["input"]>
  marginalV1Deployer_lte?: InputMaybe<Scalars["ID"]["input"]>
  marginalV1Deployer_not?: InputMaybe<Scalars["ID"]["input"]>
  marginalV1Deployer_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>
  or?: InputMaybe<Array<InputMaybe<MarginalV1LbFactory_Filter>>>
  owner?: InputMaybe<Scalars["ID"]["input"]>
  owner_gt?: InputMaybe<Scalars["ID"]["input"]>
  owner_gte?: InputMaybe<Scalars["ID"]["input"]>
  owner_in?: InputMaybe<Array<Scalars["ID"]["input"]>>
  owner_lt?: InputMaybe<Scalars["ID"]["input"]>
  owner_lte?: InputMaybe<Scalars["ID"]["input"]>
  owner_not?: InputMaybe<Scalars["ID"]["input"]>
  owner_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>
  poolCount?: InputMaybe<Scalars["BigInt"]["input"]>
  poolCount_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  poolCount_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  poolCount_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  poolCount_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  poolCount_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  poolCount_not?: InputMaybe<Scalars["BigInt"]["input"]>
  poolCount_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  pools_?: InputMaybe<MarginalV1LbPool_Filter>
  txCount?: InputMaybe<Scalars["BigInt"]["input"]>
  txCount_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  txCount_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  txCount_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  txCount_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  txCount_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  txCount_not?: InputMaybe<Scalars["BigInt"]["input"]>
  txCount_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
}

export enum MarginalV1LbFactory_OrderBy {
  FeeProtocol = "feeProtocol",
  Id = "id",
  MarginalV1Deployer = "marginalV1Deployer",
  Owner = "owner",
  PoolCount = "poolCount",
  Pools = "pools",
  TxCount = "txCount",
}

export type MarginalV1LbPool = {
  __typename?: "MarginalV1LBPool"
  blockTimestamp: Scalars["BigInt"]["output"]
  blockTimestampInitialize: Scalars["BigInt"]["output"]
  burn?: Maybe<MarginalV1LbPoolBurn>
  createdAtBlockNumber: Scalars["BigInt"]["output"]
  createdAtTimestamp: Scalars["BigInt"]["output"]
  decimals: Scalars["BigInt"]["output"]
  factory: MarginalV1LbFactory
  feeProtocol: Scalars["Int"]["output"]
  finalized: Scalars["Boolean"]["output"]
  id: Scalars["ID"]["output"]
  launchTokenIndex: Scalars["Int"]["output"]
  liquidity: Scalars["BigInt"]["output"]
  mint?: Maybe<MarginalV1LbPoolMint>
  positions: Array<MarginalV1LbPosition>
  sqrtPriceFinalizeX96?: Maybe<Scalars["BigInt"]["output"]>
  sqrtPriceInitializeX96?: Maybe<Scalars["BigInt"]["output"]>
  sqrtPriceLowerX96: Scalars["BigInt"]["output"]
  sqrtPriceUpperX96: Scalars["BigInt"]["output"]
  sqrtPriceX96: Scalars["BigInt"]["output"]
  supplier: Scalars["ID"]["output"]
  swaps: Array<MarginalV1LbPoolSwap>
  tick: Scalars["Int"]["output"]
  tickCumulative: Scalars["BigInt"]["output"]
  tickLower: Scalars["Int"]["output"]
  tickUpper: Scalars["Int"]["output"]
  token0: Token
  token1: Token
  tokenPair: Scalars["String"]["output"]
  totalPositions: Scalars["BigInt"]["output"]
  totalVolume: Scalars["BigInt"]["output"]
  txCount: Scalars["BigInt"]["output"]
}

export type MarginalV1LbPoolPositionsArgs = {
  first?: InputMaybe<Scalars["Int"]["input"]>
  orderBy?: InputMaybe<MarginalV1LbPosition_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars["Int"]["input"]>
  where?: InputMaybe<MarginalV1LbPosition_Filter>
}

export type MarginalV1LbPoolSwapsArgs = {
  first?: InputMaybe<Scalars["Int"]["input"]>
  orderBy?: InputMaybe<MarginalV1LbPoolSwap_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars["Int"]["input"]>
  where?: InputMaybe<MarginalV1LbPoolSwap_Filter>
}

export type MarginalV1LbPoolApproval = {
  __typename?: "MarginalV1LBPoolApproval"
  blockNumber: Scalars["BigInt"]["output"]
  blockTimestamp: Scalars["BigInt"]["output"]
  id: Scalars["Bytes"]["output"]
  owner: Scalars["Bytes"]["output"]
  spender: Scalars["Bytes"]["output"]
  transactionHash: Scalars["Bytes"]["output"]
  value: Scalars["BigInt"]["output"]
}

export type MarginalV1LbPoolApproval_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>
  and?: InputMaybe<Array<InputMaybe<MarginalV1LbPoolApproval_Filter>>>
  blockNumber?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  blockNumber_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_not?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  blockTimestamp?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  blockTimestamp_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_not?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  id?: InputMaybe<Scalars["Bytes"]["input"]>
  id_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  id_gt?: InputMaybe<Scalars["Bytes"]["input"]>
  id_gte?: InputMaybe<Scalars["Bytes"]["input"]>
  id_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  id_lt?: InputMaybe<Scalars["Bytes"]["input"]>
  id_lte?: InputMaybe<Scalars["Bytes"]["input"]>
  id_not?: InputMaybe<Scalars["Bytes"]["input"]>
  id_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  id_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  or?: InputMaybe<Array<InputMaybe<MarginalV1LbPoolApproval_Filter>>>
  owner?: InputMaybe<Scalars["Bytes"]["input"]>
  owner_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  owner_gt?: InputMaybe<Scalars["Bytes"]["input"]>
  owner_gte?: InputMaybe<Scalars["Bytes"]["input"]>
  owner_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  owner_lt?: InputMaybe<Scalars["Bytes"]["input"]>
  owner_lte?: InputMaybe<Scalars["Bytes"]["input"]>
  owner_not?: InputMaybe<Scalars["Bytes"]["input"]>
  owner_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  owner_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  spender?: InputMaybe<Scalars["Bytes"]["input"]>
  spender_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  spender_gt?: InputMaybe<Scalars["Bytes"]["input"]>
  spender_gte?: InputMaybe<Scalars["Bytes"]["input"]>
  spender_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  spender_lt?: InputMaybe<Scalars["Bytes"]["input"]>
  spender_lte?: InputMaybe<Scalars["Bytes"]["input"]>
  spender_not?: InputMaybe<Scalars["Bytes"]["input"]>
  spender_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  spender_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  transactionHash?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_gt?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_gte?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  transactionHash_lt?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_lte?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_not?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  value?: InputMaybe<Scalars["BigInt"]["input"]>
  value_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  value_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  value_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  value_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  value_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  value_not?: InputMaybe<Scalars["BigInt"]["input"]>
  value_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
}

export enum MarginalV1LbPoolApproval_OrderBy {
  BlockNumber = "blockNumber",
  BlockTimestamp = "blockTimestamp",
  Id = "id",
  Owner = "owner",
  Spender = "spender",
  TransactionHash = "transactionHash",
  Value = "value",
}

export type MarginalV1LbPoolBurn = {
  __typename?: "MarginalV1LBPoolBurn"
  amount0: Scalars["BigInt"]["output"]
  amount1: Scalars["BigInt"]["output"]
  blockNumber: Scalars["BigInt"]["output"]
  blockTimestamp: Scalars["BigInt"]["output"]
  fees0: Scalars["BigInt"]["output"]
  fees1: Scalars["BigInt"]["output"]
  id: Scalars["Bytes"]["output"]
  liquidityDelta: Scalars["BigInt"]["output"]
  owner: Scalars["Bytes"]["output"]
  pool: MarginalV1LbPool
  recipient: Scalars["Bytes"]["output"]
  transactionHash: Scalars["Bytes"]["output"]
}

export type MarginalV1LbPoolBurn_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>
  amount0?: InputMaybe<Scalars["BigInt"]["input"]>
  amount0_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  amount0_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  amount0_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  amount0_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  amount0_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  amount0_not?: InputMaybe<Scalars["BigInt"]["input"]>
  amount0_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  amount1?: InputMaybe<Scalars["BigInt"]["input"]>
  amount1_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  amount1_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  amount1_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  amount1_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  amount1_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  amount1_not?: InputMaybe<Scalars["BigInt"]["input"]>
  amount1_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  and?: InputMaybe<Array<InputMaybe<MarginalV1LbPoolBurn_Filter>>>
  blockNumber?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  blockNumber_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_not?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  blockTimestamp?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  blockTimestamp_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_not?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  fees0?: InputMaybe<Scalars["BigInt"]["input"]>
  fees0_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  fees0_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  fees0_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  fees0_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  fees0_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  fees0_not?: InputMaybe<Scalars["BigInt"]["input"]>
  fees0_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  fees1?: InputMaybe<Scalars["BigInt"]["input"]>
  fees1_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  fees1_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  fees1_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  fees1_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  fees1_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  fees1_not?: InputMaybe<Scalars["BigInt"]["input"]>
  fees1_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  id?: InputMaybe<Scalars["Bytes"]["input"]>
  id_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  id_gt?: InputMaybe<Scalars["Bytes"]["input"]>
  id_gte?: InputMaybe<Scalars["Bytes"]["input"]>
  id_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  id_lt?: InputMaybe<Scalars["Bytes"]["input"]>
  id_lte?: InputMaybe<Scalars["Bytes"]["input"]>
  id_not?: InputMaybe<Scalars["Bytes"]["input"]>
  id_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  id_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  liquidityDelta?: InputMaybe<Scalars["BigInt"]["input"]>
  liquidityDelta_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  liquidityDelta_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  liquidityDelta_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  liquidityDelta_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  liquidityDelta_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  liquidityDelta_not?: InputMaybe<Scalars["BigInt"]["input"]>
  liquidityDelta_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  or?: InputMaybe<Array<InputMaybe<MarginalV1LbPoolBurn_Filter>>>
  owner?: InputMaybe<Scalars["Bytes"]["input"]>
  owner_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  owner_gt?: InputMaybe<Scalars["Bytes"]["input"]>
  owner_gte?: InputMaybe<Scalars["Bytes"]["input"]>
  owner_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  owner_lt?: InputMaybe<Scalars["Bytes"]["input"]>
  owner_lte?: InputMaybe<Scalars["Bytes"]["input"]>
  owner_not?: InputMaybe<Scalars["Bytes"]["input"]>
  owner_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  owner_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  pool?: InputMaybe<Scalars["String"]["input"]>
  pool_?: InputMaybe<MarginalV1LbPool_Filter>
  pool_contains?: InputMaybe<Scalars["String"]["input"]>
  pool_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  pool_ends_with?: InputMaybe<Scalars["String"]["input"]>
  pool_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  pool_gt?: InputMaybe<Scalars["String"]["input"]>
  pool_gte?: InputMaybe<Scalars["String"]["input"]>
  pool_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  pool_lt?: InputMaybe<Scalars["String"]["input"]>
  pool_lte?: InputMaybe<Scalars["String"]["input"]>
  pool_not?: InputMaybe<Scalars["String"]["input"]>
  pool_not_contains?: InputMaybe<Scalars["String"]["input"]>
  pool_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  pool_not_ends_with?: InputMaybe<Scalars["String"]["input"]>
  pool_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  pool_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  pool_not_starts_with?: InputMaybe<Scalars["String"]["input"]>
  pool_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  pool_starts_with?: InputMaybe<Scalars["String"]["input"]>
  pool_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  recipient?: InputMaybe<Scalars["Bytes"]["input"]>
  recipient_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  recipient_gt?: InputMaybe<Scalars["Bytes"]["input"]>
  recipient_gte?: InputMaybe<Scalars["Bytes"]["input"]>
  recipient_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  recipient_lt?: InputMaybe<Scalars["Bytes"]["input"]>
  recipient_lte?: InputMaybe<Scalars["Bytes"]["input"]>
  recipient_not?: InputMaybe<Scalars["Bytes"]["input"]>
  recipient_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  recipient_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  transactionHash?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_gt?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_gte?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  transactionHash_lt?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_lte?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_not?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
}

export enum MarginalV1LbPoolBurn_OrderBy {
  Amount0 = "amount0",
  Amount1 = "amount1",
  BlockNumber = "blockNumber",
  BlockTimestamp = "blockTimestamp",
  Fees0 = "fees0",
  Fees1 = "fees1",
  Id = "id",
  LiquidityDelta = "liquidityDelta",
  Owner = "owner",
  Pool = "pool",
  PoolBlockTimestamp = "pool__blockTimestamp",
  PoolBlockTimestampInitialize = "pool__blockTimestampInitialize",
  PoolCreatedAtBlockNumber = "pool__createdAtBlockNumber",
  PoolCreatedAtTimestamp = "pool__createdAtTimestamp",
  PoolDecimals = "pool__decimals",
  PoolFeeProtocol = "pool__feeProtocol",
  PoolFinalized = "pool__finalized",
  PoolId = "pool__id",
  PoolLaunchTokenIndex = "pool__launchTokenIndex",
  PoolLiquidity = "pool__liquidity",
  PoolSqrtPriceFinalizeX96 = "pool__sqrtPriceFinalizeX96",
  PoolSqrtPriceInitializeX96 = "pool__sqrtPriceInitializeX96",
  PoolSqrtPriceLowerX96 = "pool__sqrtPriceLowerX96",
  PoolSqrtPriceUpperX96 = "pool__sqrtPriceUpperX96",
  PoolSqrtPriceX96 = "pool__sqrtPriceX96",
  PoolSupplier = "pool__supplier",
  PoolTick = "pool__tick",
  PoolTickCumulative = "pool__tickCumulative",
  PoolTickLower = "pool__tickLower",
  PoolTickUpper = "pool__tickUpper",
  PoolTokenPair = "pool__tokenPair",
  PoolTotalPositions = "pool__totalPositions",
  PoolTotalVolume = "pool__totalVolume",
  PoolTxCount = "pool__txCount",
  Recipient = "recipient",
  TransactionHash = "transactionHash",
}

export type MarginalV1LbPoolFinalize = {
  __typename?: "MarginalV1LBPoolFinalize"
  blockNumber: Scalars["BigInt"]["output"]
  blockTimestamp: Scalars["BigInt"]["output"]
  id: Scalars["Bytes"]["output"]
  liquidityDelta: Scalars["BigInt"]["output"]
  sqrtPriceX96: Scalars["BigInt"]["output"]
  tick: Scalars["Int"]["output"]
  transactionHash: Scalars["Bytes"]["output"]
}

export type MarginalV1LbPoolFinalize_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>
  and?: InputMaybe<Array<InputMaybe<MarginalV1LbPoolFinalize_Filter>>>
  blockNumber?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  blockNumber_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_not?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  blockTimestamp?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  blockTimestamp_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_not?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  id?: InputMaybe<Scalars["Bytes"]["input"]>
  id_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  id_gt?: InputMaybe<Scalars["Bytes"]["input"]>
  id_gte?: InputMaybe<Scalars["Bytes"]["input"]>
  id_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  id_lt?: InputMaybe<Scalars["Bytes"]["input"]>
  id_lte?: InputMaybe<Scalars["Bytes"]["input"]>
  id_not?: InputMaybe<Scalars["Bytes"]["input"]>
  id_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  id_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  liquidityDelta?: InputMaybe<Scalars["BigInt"]["input"]>
  liquidityDelta_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  liquidityDelta_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  liquidityDelta_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  liquidityDelta_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  liquidityDelta_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  liquidityDelta_not?: InputMaybe<Scalars["BigInt"]["input"]>
  liquidityDelta_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  or?: InputMaybe<Array<InputMaybe<MarginalV1LbPoolFinalize_Filter>>>
  sqrtPriceX96?: InputMaybe<Scalars["BigInt"]["input"]>
  sqrtPriceX96_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  sqrtPriceX96_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  sqrtPriceX96_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  sqrtPriceX96_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  sqrtPriceX96_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  sqrtPriceX96_not?: InputMaybe<Scalars["BigInt"]["input"]>
  sqrtPriceX96_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  tick?: InputMaybe<Scalars["Int"]["input"]>
  tick_gt?: InputMaybe<Scalars["Int"]["input"]>
  tick_gte?: InputMaybe<Scalars["Int"]["input"]>
  tick_in?: InputMaybe<Array<Scalars["Int"]["input"]>>
  tick_lt?: InputMaybe<Scalars["Int"]["input"]>
  tick_lte?: InputMaybe<Scalars["Int"]["input"]>
  tick_not?: InputMaybe<Scalars["Int"]["input"]>
  tick_not_in?: InputMaybe<Array<Scalars["Int"]["input"]>>
  transactionHash?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_gt?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_gte?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  transactionHash_lt?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_lte?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_not?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
}

export enum MarginalV1LbPoolFinalize_OrderBy {
  BlockNumber = "blockNumber",
  BlockTimestamp = "blockTimestamp",
  Id = "id",
  LiquidityDelta = "liquidityDelta",
  SqrtPriceX96 = "sqrtPriceX96",
  Tick = "tick",
  TransactionHash = "transactionHash",
}

export type MarginalV1LbPoolMint = {
  __typename?: "MarginalV1LBPoolMint"
  amount0: Scalars["BigInt"]["output"]
  amount1: Scalars["BigInt"]["output"]
  blockNumber: Scalars["BigInt"]["output"]
  blockTimestamp: Scalars["BigInt"]["output"]
  id: Scalars["Bytes"]["output"]
  liquidityDelta: Scalars["BigInt"]["output"]
  owner: Scalars["Bytes"]["output"]
  pool: MarginalV1LbPool
  sender: Scalars["Bytes"]["output"]
  transactionHash: Scalars["Bytes"]["output"]
}

export type MarginalV1LbPoolMint_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>
  amount0?: InputMaybe<Scalars["BigInt"]["input"]>
  amount0_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  amount0_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  amount0_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  amount0_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  amount0_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  amount0_not?: InputMaybe<Scalars["BigInt"]["input"]>
  amount0_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  amount1?: InputMaybe<Scalars["BigInt"]["input"]>
  amount1_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  amount1_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  amount1_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  amount1_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  amount1_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  amount1_not?: InputMaybe<Scalars["BigInt"]["input"]>
  amount1_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  and?: InputMaybe<Array<InputMaybe<MarginalV1LbPoolMint_Filter>>>
  blockNumber?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  blockNumber_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_not?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  blockTimestamp?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  blockTimestamp_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_not?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  id?: InputMaybe<Scalars["Bytes"]["input"]>
  id_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  id_gt?: InputMaybe<Scalars["Bytes"]["input"]>
  id_gte?: InputMaybe<Scalars["Bytes"]["input"]>
  id_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  id_lt?: InputMaybe<Scalars["Bytes"]["input"]>
  id_lte?: InputMaybe<Scalars["Bytes"]["input"]>
  id_not?: InputMaybe<Scalars["Bytes"]["input"]>
  id_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  id_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  liquidityDelta?: InputMaybe<Scalars["BigInt"]["input"]>
  liquidityDelta_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  liquidityDelta_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  liquidityDelta_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  liquidityDelta_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  liquidityDelta_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  liquidityDelta_not?: InputMaybe<Scalars["BigInt"]["input"]>
  liquidityDelta_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  or?: InputMaybe<Array<InputMaybe<MarginalV1LbPoolMint_Filter>>>
  owner?: InputMaybe<Scalars["Bytes"]["input"]>
  owner_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  owner_gt?: InputMaybe<Scalars["Bytes"]["input"]>
  owner_gte?: InputMaybe<Scalars["Bytes"]["input"]>
  owner_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  owner_lt?: InputMaybe<Scalars["Bytes"]["input"]>
  owner_lte?: InputMaybe<Scalars["Bytes"]["input"]>
  owner_not?: InputMaybe<Scalars["Bytes"]["input"]>
  owner_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  owner_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  pool?: InputMaybe<Scalars["String"]["input"]>
  pool_?: InputMaybe<MarginalV1LbPool_Filter>
  pool_contains?: InputMaybe<Scalars["String"]["input"]>
  pool_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  pool_ends_with?: InputMaybe<Scalars["String"]["input"]>
  pool_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  pool_gt?: InputMaybe<Scalars["String"]["input"]>
  pool_gte?: InputMaybe<Scalars["String"]["input"]>
  pool_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  pool_lt?: InputMaybe<Scalars["String"]["input"]>
  pool_lte?: InputMaybe<Scalars["String"]["input"]>
  pool_not?: InputMaybe<Scalars["String"]["input"]>
  pool_not_contains?: InputMaybe<Scalars["String"]["input"]>
  pool_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  pool_not_ends_with?: InputMaybe<Scalars["String"]["input"]>
  pool_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  pool_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  pool_not_starts_with?: InputMaybe<Scalars["String"]["input"]>
  pool_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  pool_starts_with?: InputMaybe<Scalars["String"]["input"]>
  pool_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  sender?: InputMaybe<Scalars["Bytes"]["input"]>
  sender_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  sender_gt?: InputMaybe<Scalars["Bytes"]["input"]>
  sender_gte?: InputMaybe<Scalars["Bytes"]["input"]>
  sender_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  sender_lt?: InputMaybe<Scalars["Bytes"]["input"]>
  sender_lte?: InputMaybe<Scalars["Bytes"]["input"]>
  sender_not?: InputMaybe<Scalars["Bytes"]["input"]>
  sender_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  sender_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  transactionHash?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_gt?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_gte?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  transactionHash_lt?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_lte?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_not?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
}

export enum MarginalV1LbPoolMint_OrderBy {
  Amount0 = "amount0",
  Amount1 = "amount1",
  BlockNumber = "blockNumber",
  BlockTimestamp = "blockTimestamp",
  Id = "id",
  LiquidityDelta = "liquidityDelta",
  Owner = "owner",
  Pool = "pool",
  PoolBlockTimestamp = "pool__blockTimestamp",
  PoolBlockTimestampInitialize = "pool__blockTimestampInitialize",
  PoolCreatedAtBlockNumber = "pool__createdAtBlockNumber",
  PoolCreatedAtTimestamp = "pool__createdAtTimestamp",
  PoolDecimals = "pool__decimals",
  PoolFeeProtocol = "pool__feeProtocol",
  PoolFinalized = "pool__finalized",
  PoolId = "pool__id",
  PoolLaunchTokenIndex = "pool__launchTokenIndex",
  PoolLiquidity = "pool__liquidity",
  PoolSqrtPriceFinalizeX96 = "pool__sqrtPriceFinalizeX96",
  PoolSqrtPriceInitializeX96 = "pool__sqrtPriceInitializeX96",
  PoolSqrtPriceLowerX96 = "pool__sqrtPriceLowerX96",
  PoolSqrtPriceUpperX96 = "pool__sqrtPriceUpperX96",
  PoolSqrtPriceX96 = "pool__sqrtPriceX96",
  PoolSupplier = "pool__supplier",
  PoolTick = "pool__tick",
  PoolTickCumulative = "pool__tickCumulative",
  PoolTickLower = "pool__tickLower",
  PoolTickUpper = "pool__tickUpper",
  PoolTokenPair = "pool__tokenPair",
  PoolTotalPositions = "pool__totalPositions",
  PoolTotalVolume = "pool__totalVolume",
  PoolTxCount = "pool__txCount",
  Sender = "sender",
  TransactionHash = "transactionHash",
}

export type MarginalV1LbPoolSwap = {
  __typename?: "MarginalV1LBPoolSwap"
  amount0: Scalars["BigInt"]["output"]
  amount1: Scalars["BigInt"]["output"]
  blockNumber: Scalars["BigInt"]["output"]
  blockTimestamp: Scalars["BigInt"]["output"]
  finalized: Scalars["Boolean"]["output"]
  id: Scalars["Bytes"]["output"]
  liquidity: Scalars["BigInt"]["output"]
  pool: MarginalV1LbPool
  recipient: Scalars["Bytes"]["output"]
  sender: Scalars["Bytes"]["output"]
  sqrtPriceX96: Scalars["BigInt"]["output"]
  tick: Scalars["Int"]["output"]
  transactionHash: Scalars["Bytes"]["output"]
}

export type MarginalV1LbPoolSwap_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>
  amount0?: InputMaybe<Scalars["BigInt"]["input"]>
  amount0_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  amount0_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  amount0_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  amount0_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  amount0_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  amount0_not?: InputMaybe<Scalars["BigInt"]["input"]>
  amount0_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  amount1?: InputMaybe<Scalars["BigInt"]["input"]>
  amount1_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  amount1_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  amount1_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  amount1_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  amount1_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  amount1_not?: InputMaybe<Scalars["BigInt"]["input"]>
  amount1_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  and?: InputMaybe<Array<InputMaybe<MarginalV1LbPoolSwap_Filter>>>
  blockNumber?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  blockNumber_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_not?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  blockTimestamp?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  blockTimestamp_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_not?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  finalized?: InputMaybe<Scalars["Boolean"]["input"]>
  finalized_in?: InputMaybe<Array<Scalars["Boolean"]["input"]>>
  finalized_not?: InputMaybe<Scalars["Boolean"]["input"]>
  finalized_not_in?: InputMaybe<Array<Scalars["Boolean"]["input"]>>
  id?: InputMaybe<Scalars["Bytes"]["input"]>
  id_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  id_gt?: InputMaybe<Scalars["Bytes"]["input"]>
  id_gte?: InputMaybe<Scalars["Bytes"]["input"]>
  id_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  id_lt?: InputMaybe<Scalars["Bytes"]["input"]>
  id_lte?: InputMaybe<Scalars["Bytes"]["input"]>
  id_not?: InputMaybe<Scalars["Bytes"]["input"]>
  id_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  id_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  liquidity?: InputMaybe<Scalars["BigInt"]["input"]>
  liquidity_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  liquidity_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  liquidity_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  liquidity_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  liquidity_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  liquidity_not?: InputMaybe<Scalars["BigInt"]["input"]>
  liquidity_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  or?: InputMaybe<Array<InputMaybe<MarginalV1LbPoolSwap_Filter>>>
  pool?: InputMaybe<Scalars["String"]["input"]>
  pool_?: InputMaybe<MarginalV1LbPool_Filter>
  pool_contains?: InputMaybe<Scalars["String"]["input"]>
  pool_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  pool_ends_with?: InputMaybe<Scalars["String"]["input"]>
  pool_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  pool_gt?: InputMaybe<Scalars["String"]["input"]>
  pool_gte?: InputMaybe<Scalars["String"]["input"]>
  pool_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  pool_lt?: InputMaybe<Scalars["String"]["input"]>
  pool_lte?: InputMaybe<Scalars["String"]["input"]>
  pool_not?: InputMaybe<Scalars["String"]["input"]>
  pool_not_contains?: InputMaybe<Scalars["String"]["input"]>
  pool_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  pool_not_ends_with?: InputMaybe<Scalars["String"]["input"]>
  pool_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  pool_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  pool_not_starts_with?: InputMaybe<Scalars["String"]["input"]>
  pool_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  pool_starts_with?: InputMaybe<Scalars["String"]["input"]>
  pool_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  recipient?: InputMaybe<Scalars["Bytes"]["input"]>
  recipient_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  recipient_gt?: InputMaybe<Scalars["Bytes"]["input"]>
  recipient_gte?: InputMaybe<Scalars["Bytes"]["input"]>
  recipient_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  recipient_lt?: InputMaybe<Scalars["Bytes"]["input"]>
  recipient_lte?: InputMaybe<Scalars["Bytes"]["input"]>
  recipient_not?: InputMaybe<Scalars["Bytes"]["input"]>
  recipient_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  recipient_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  sender?: InputMaybe<Scalars["Bytes"]["input"]>
  sender_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  sender_gt?: InputMaybe<Scalars["Bytes"]["input"]>
  sender_gte?: InputMaybe<Scalars["Bytes"]["input"]>
  sender_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  sender_lt?: InputMaybe<Scalars["Bytes"]["input"]>
  sender_lte?: InputMaybe<Scalars["Bytes"]["input"]>
  sender_not?: InputMaybe<Scalars["Bytes"]["input"]>
  sender_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  sender_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  sqrtPriceX96?: InputMaybe<Scalars["BigInt"]["input"]>
  sqrtPriceX96_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  sqrtPriceX96_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  sqrtPriceX96_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  sqrtPriceX96_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  sqrtPriceX96_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  sqrtPriceX96_not?: InputMaybe<Scalars["BigInt"]["input"]>
  sqrtPriceX96_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  tick?: InputMaybe<Scalars["Int"]["input"]>
  tick_gt?: InputMaybe<Scalars["Int"]["input"]>
  tick_gte?: InputMaybe<Scalars["Int"]["input"]>
  tick_in?: InputMaybe<Array<Scalars["Int"]["input"]>>
  tick_lt?: InputMaybe<Scalars["Int"]["input"]>
  tick_lte?: InputMaybe<Scalars["Int"]["input"]>
  tick_not?: InputMaybe<Scalars["Int"]["input"]>
  tick_not_in?: InputMaybe<Array<Scalars["Int"]["input"]>>
  transactionHash?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_gt?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_gte?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  transactionHash_lt?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_lte?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_not?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
}

export enum MarginalV1LbPoolSwap_OrderBy {
  Amount0 = "amount0",
  Amount1 = "amount1",
  BlockNumber = "blockNumber",
  BlockTimestamp = "blockTimestamp",
  Finalized = "finalized",
  Id = "id",
  Liquidity = "liquidity",
  Pool = "pool",
  PoolBlockTimestamp = "pool__blockTimestamp",
  PoolBlockTimestampInitialize = "pool__blockTimestampInitialize",
  PoolCreatedAtBlockNumber = "pool__createdAtBlockNumber",
  PoolCreatedAtTimestamp = "pool__createdAtTimestamp",
  PoolDecimals = "pool__decimals",
  PoolFeeProtocol = "pool__feeProtocol",
  PoolFinalized = "pool__finalized",
  PoolId = "pool__id",
  PoolLaunchTokenIndex = "pool__launchTokenIndex",
  PoolLiquidity = "pool__liquidity",
  PoolSqrtPriceFinalizeX96 = "pool__sqrtPriceFinalizeX96",
  PoolSqrtPriceInitializeX96 = "pool__sqrtPriceInitializeX96",
  PoolSqrtPriceLowerX96 = "pool__sqrtPriceLowerX96",
  PoolSqrtPriceUpperX96 = "pool__sqrtPriceUpperX96",
  PoolSqrtPriceX96 = "pool__sqrtPriceX96",
  PoolSupplier = "pool__supplier",
  PoolTick = "pool__tick",
  PoolTickCumulative = "pool__tickCumulative",
  PoolTickLower = "pool__tickLower",
  PoolTickUpper = "pool__tickUpper",
  PoolTokenPair = "pool__tokenPair",
  PoolTotalPositions = "pool__totalPositions",
  PoolTotalVolume = "pool__totalVolume",
  PoolTxCount = "pool__txCount",
  Recipient = "recipient",
  Sender = "sender",
  SqrtPriceX96 = "sqrtPriceX96",
  Tick = "tick",
  TransactionHash = "transactionHash",
}

export type MarginalV1LbPoolTransfer = {
  __typename?: "MarginalV1LBPoolTransfer"
  blockNumber: Scalars["BigInt"]["output"]
  blockTimestamp: Scalars["BigInt"]["output"]
  from: Scalars["Bytes"]["output"]
  id: Scalars["Bytes"]["output"]
  to: Scalars["Bytes"]["output"]
  transactionHash: Scalars["Bytes"]["output"]
  value: Scalars["BigInt"]["output"]
}

export type MarginalV1LbPoolTransfer_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>
  and?: InputMaybe<Array<InputMaybe<MarginalV1LbPoolTransfer_Filter>>>
  blockNumber?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  blockNumber_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_not?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  blockTimestamp?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  blockTimestamp_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_not?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  from?: InputMaybe<Scalars["Bytes"]["input"]>
  from_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  from_gt?: InputMaybe<Scalars["Bytes"]["input"]>
  from_gte?: InputMaybe<Scalars["Bytes"]["input"]>
  from_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  from_lt?: InputMaybe<Scalars["Bytes"]["input"]>
  from_lte?: InputMaybe<Scalars["Bytes"]["input"]>
  from_not?: InputMaybe<Scalars["Bytes"]["input"]>
  from_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  from_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  id?: InputMaybe<Scalars["Bytes"]["input"]>
  id_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  id_gt?: InputMaybe<Scalars["Bytes"]["input"]>
  id_gte?: InputMaybe<Scalars["Bytes"]["input"]>
  id_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  id_lt?: InputMaybe<Scalars["Bytes"]["input"]>
  id_lte?: InputMaybe<Scalars["Bytes"]["input"]>
  id_not?: InputMaybe<Scalars["Bytes"]["input"]>
  id_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  id_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  or?: InputMaybe<Array<InputMaybe<MarginalV1LbPoolTransfer_Filter>>>
  to?: InputMaybe<Scalars["Bytes"]["input"]>
  to_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  to_gt?: InputMaybe<Scalars["Bytes"]["input"]>
  to_gte?: InputMaybe<Scalars["Bytes"]["input"]>
  to_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  to_lt?: InputMaybe<Scalars["Bytes"]["input"]>
  to_lte?: InputMaybe<Scalars["Bytes"]["input"]>
  to_not?: InputMaybe<Scalars["Bytes"]["input"]>
  to_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  to_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  transactionHash?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_gt?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_gte?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  transactionHash_lt?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_lte?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_not?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  value?: InputMaybe<Scalars["BigInt"]["input"]>
  value_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  value_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  value_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  value_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  value_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  value_not?: InputMaybe<Scalars["BigInt"]["input"]>
  value_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
}

export enum MarginalV1LbPoolTransfer_OrderBy {
  BlockNumber = "blockNumber",
  BlockTimestamp = "blockTimestamp",
  From = "from",
  Id = "id",
  To = "to",
  TransactionHash = "transactionHash",
  Value = "value",
}

export type MarginalV1LbPool_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>
  and?: InputMaybe<Array<InputMaybe<MarginalV1LbPool_Filter>>>
  blockTimestamp?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestampInitialize?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestampInitialize_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestampInitialize_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestampInitialize_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  blockTimestampInitialize_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestampInitialize_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestampInitialize_not?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestampInitialize_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  blockTimestamp_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  blockTimestamp_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_not?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  burn_?: InputMaybe<MarginalV1LbPoolBurn_Filter>
  createdAtBlockNumber?: InputMaybe<Scalars["BigInt"]["input"]>
  createdAtBlockNumber_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  createdAtBlockNumber_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  createdAtBlockNumber_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  createdAtBlockNumber_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  createdAtBlockNumber_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  createdAtBlockNumber_not?: InputMaybe<Scalars["BigInt"]["input"]>
  createdAtBlockNumber_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  createdAtTimestamp?: InputMaybe<Scalars["BigInt"]["input"]>
  createdAtTimestamp_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  createdAtTimestamp_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  createdAtTimestamp_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  createdAtTimestamp_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  createdAtTimestamp_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  createdAtTimestamp_not?: InputMaybe<Scalars["BigInt"]["input"]>
  createdAtTimestamp_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  decimals?: InputMaybe<Scalars["BigInt"]["input"]>
  decimals_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  decimals_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  decimals_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  decimals_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  decimals_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  decimals_not?: InputMaybe<Scalars["BigInt"]["input"]>
  decimals_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  factory?: InputMaybe<Scalars["String"]["input"]>
  factory_?: InputMaybe<MarginalV1LbFactory_Filter>
  factory_contains?: InputMaybe<Scalars["String"]["input"]>
  factory_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  factory_ends_with?: InputMaybe<Scalars["String"]["input"]>
  factory_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  factory_gt?: InputMaybe<Scalars["String"]["input"]>
  factory_gte?: InputMaybe<Scalars["String"]["input"]>
  factory_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  factory_lt?: InputMaybe<Scalars["String"]["input"]>
  factory_lte?: InputMaybe<Scalars["String"]["input"]>
  factory_not?: InputMaybe<Scalars["String"]["input"]>
  factory_not_contains?: InputMaybe<Scalars["String"]["input"]>
  factory_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  factory_not_ends_with?: InputMaybe<Scalars["String"]["input"]>
  factory_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  factory_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  factory_not_starts_with?: InputMaybe<Scalars["String"]["input"]>
  factory_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  factory_starts_with?: InputMaybe<Scalars["String"]["input"]>
  factory_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  feeProtocol?: InputMaybe<Scalars["Int"]["input"]>
  feeProtocol_gt?: InputMaybe<Scalars["Int"]["input"]>
  feeProtocol_gte?: InputMaybe<Scalars["Int"]["input"]>
  feeProtocol_in?: InputMaybe<Array<Scalars["Int"]["input"]>>
  feeProtocol_lt?: InputMaybe<Scalars["Int"]["input"]>
  feeProtocol_lte?: InputMaybe<Scalars["Int"]["input"]>
  feeProtocol_not?: InputMaybe<Scalars["Int"]["input"]>
  feeProtocol_not_in?: InputMaybe<Array<Scalars["Int"]["input"]>>
  finalized?: InputMaybe<Scalars["Boolean"]["input"]>
  finalized_in?: InputMaybe<Array<Scalars["Boolean"]["input"]>>
  finalized_not?: InputMaybe<Scalars["Boolean"]["input"]>
  finalized_not_in?: InputMaybe<Array<Scalars["Boolean"]["input"]>>
  id?: InputMaybe<Scalars["ID"]["input"]>
  id_gt?: InputMaybe<Scalars["ID"]["input"]>
  id_gte?: InputMaybe<Scalars["ID"]["input"]>
  id_in?: InputMaybe<Array<Scalars["ID"]["input"]>>
  id_lt?: InputMaybe<Scalars["ID"]["input"]>
  id_lte?: InputMaybe<Scalars["ID"]["input"]>
  id_not?: InputMaybe<Scalars["ID"]["input"]>
  id_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>
  launchTokenIndex?: InputMaybe<Scalars["Int"]["input"]>
  launchTokenIndex_gt?: InputMaybe<Scalars["Int"]["input"]>
  launchTokenIndex_gte?: InputMaybe<Scalars["Int"]["input"]>
  launchTokenIndex_in?: InputMaybe<Array<Scalars["Int"]["input"]>>
  launchTokenIndex_lt?: InputMaybe<Scalars["Int"]["input"]>
  launchTokenIndex_lte?: InputMaybe<Scalars["Int"]["input"]>
  launchTokenIndex_not?: InputMaybe<Scalars["Int"]["input"]>
  launchTokenIndex_not_in?: InputMaybe<Array<Scalars["Int"]["input"]>>
  liquidity?: InputMaybe<Scalars["BigInt"]["input"]>
  liquidity_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  liquidity_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  liquidity_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  liquidity_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  liquidity_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  liquidity_not?: InputMaybe<Scalars["BigInt"]["input"]>
  liquidity_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  mint_?: InputMaybe<MarginalV1LbPoolMint_Filter>
  or?: InputMaybe<Array<InputMaybe<MarginalV1LbPool_Filter>>>
  positions_?: InputMaybe<MarginalV1LbPosition_Filter>
  sqrtPriceFinalizeX96?: InputMaybe<Scalars["BigInt"]["input"]>
  sqrtPriceFinalizeX96_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  sqrtPriceFinalizeX96_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  sqrtPriceFinalizeX96_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  sqrtPriceFinalizeX96_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  sqrtPriceFinalizeX96_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  sqrtPriceFinalizeX96_not?: InputMaybe<Scalars["BigInt"]["input"]>
  sqrtPriceFinalizeX96_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  sqrtPriceInitializeX96?: InputMaybe<Scalars["BigInt"]["input"]>
  sqrtPriceInitializeX96_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  sqrtPriceInitializeX96_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  sqrtPriceInitializeX96_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  sqrtPriceInitializeX96_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  sqrtPriceInitializeX96_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  sqrtPriceInitializeX96_not?: InputMaybe<Scalars["BigInt"]["input"]>
  sqrtPriceInitializeX96_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  sqrtPriceLowerX96?: InputMaybe<Scalars["BigInt"]["input"]>
  sqrtPriceLowerX96_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  sqrtPriceLowerX96_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  sqrtPriceLowerX96_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  sqrtPriceLowerX96_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  sqrtPriceLowerX96_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  sqrtPriceLowerX96_not?: InputMaybe<Scalars["BigInt"]["input"]>
  sqrtPriceLowerX96_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  sqrtPriceUpperX96?: InputMaybe<Scalars["BigInt"]["input"]>
  sqrtPriceUpperX96_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  sqrtPriceUpperX96_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  sqrtPriceUpperX96_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  sqrtPriceUpperX96_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  sqrtPriceUpperX96_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  sqrtPriceUpperX96_not?: InputMaybe<Scalars["BigInt"]["input"]>
  sqrtPriceUpperX96_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  sqrtPriceX96?: InputMaybe<Scalars["BigInt"]["input"]>
  sqrtPriceX96_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  sqrtPriceX96_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  sqrtPriceX96_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  sqrtPriceX96_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  sqrtPriceX96_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  sqrtPriceX96_not?: InputMaybe<Scalars["BigInt"]["input"]>
  sqrtPriceX96_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  supplier?: InputMaybe<Scalars["ID"]["input"]>
  supplier_gt?: InputMaybe<Scalars["ID"]["input"]>
  supplier_gte?: InputMaybe<Scalars["ID"]["input"]>
  supplier_in?: InputMaybe<Array<Scalars["ID"]["input"]>>
  supplier_lt?: InputMaybe<Scalars["ID"]["input"]>
  supplier_lte?: InputMaybe<Scalars["ID"]["input"]>
  supplier_not?: InputMaybe<Scalars["ID"]["input"]>
  supplier_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>
  swaps_?: InputMaybe<MarginalV1LbPoolSwap_Filter>
  tick?: InputMaybe<Scalars["Int"]["input"]>
  tickCumulative?: InputMaybe<Scalars["BigInt"]["input"]>
  tickCumulative_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  tickCumulative_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  tickCumulative_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  tickCumulative_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  tickCumulative_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  tickCumulative_not?: InputMaybe<Scalars["BigInt"]["input"]>
  tickCumulative_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  tickLower?: InputMaybe<Scalars["Int"]["input"]>
  tickLower_gt?: InputMaybe<Scalars["Int"]["input"]>
  tickLower_gte?: InputMaybe<Scalars["Int"]["input"]>
  tickLower_in?: InputMaybe<Array<Scalars["Int"]["input"]>>
  tickLower_lt?: InputMaybe<Scalars["Int"]["input"]>
  tickLower_lte?: InputMaybe<Scalars["Int"]["input"]>
  tickLower_not?: InputMaybe<Scalars["Int"]["input"]>
  tickLower_not_in?: InputMaybe<Array<Scalars["Int"]["input"]>>
  tickUpper?: InputMaybe<Scalars["Int"]["input"]>
  tickUpper_gt?: InputMaybe<Scalars["Int"]["input"]>
  tickUpper_gte?: InputMaybe<Scalars["Int"]["input"]>
  tickUpper_in?: InputMaybe<Array<Scalars["Int"]["input"]>>
  tickUpper_lt?: InputMaybe<Scalars["Int"]["input"]>
  tickUpper_lte?: InputMaybe<Scalars["Int"]["input"]>
  tickUpper_not?: InputMaybe<Scalars["Int"]["input"]>
  tickUpper_not_in?: InputMaybe<Array<Scalars["Int"]["input"]>>
  tick_gt?: InputMaybe<Scalars["Int"]["input"]>
  tick_gte?: InputMaybe<Scalars["Int"]["input"]>
  tick_in?: InputMaybe<Array<Scalars["Int"]["input"]>>
  tick_lt?: InputMaybe<Scalars["Int"]["input"]>
  tick_lte?: InputMaybe<Scalars["Int"]["input"]>
  tick_not?: InputMaybe<Scalars["Int"]["input"]>
  tick_not_in?: InputMaybe<Array<Scalars["Int"]["input"]>>
  token0?: InputMaybe<Scalars["String"]["input"]>
  token0_?: InputMaybe<Token_Filter>
  token0_contains?: InputMaybe<Scalars["String"]["input"]>
  token0_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  token0_ends_with?: InputMaybe<Scalars["String"]["input"]>
  token0_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  token0_gt?: InputMaybe<Scalars["String"]["input"]>
  token0_gte?: InputMaybe<Scalars["String"]["input"]>
  token0_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  token0_lt?: InputMaybe<Scalars["String"]["input"]>
  token0_lte?: InputMaybe<Scalars["String"]["input"]>
  token0_not?: InputMaybe<Scalars["String"]["input"]>
  token0_not_contains?: InputMaybe<Scalars["String"]["input"]>
  token0_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  token0_not_ends_with?: InputMaybe<Scalars["String"]["input"]>
  token0_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  token0_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  token0_not_starts_with?: InputMaybe<Scalars["String"]["input"]>
  token0_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  token0_starts_with?: InputMaybe<Scalars["String"]["input"]>
  token0_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  token1?: InputMaybe<Scalars["String"]["input"]>
  token1_?: InputMaybe<Token_Filter>
  token1_contains?: InputMaybe<Scalars["String"]["input"]>
  token1_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  token1_ends_with?: InputMaybe<Scalars["String"]["input"]>
  token1_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  token1_gt?: InputMaybe<Scalars["String"]["input"]>
  token1_gte?: InputMaybe<Scalars["String"]["input"]>
  token1_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  token1_lt?: InputMaybe<Scalars["String"]["input"]>
  token1_lte?: InputMaybe<Scalars["String"]["input"]>
  token1_not?: InputMaybe<Scalars["String"]["input"]>
  token1_not_contains?: InputMaybe<Scalars["String"]["input"]>
  token1_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  token1_not_ends_with?: InputMaybe<Scalars["String"]["input"]>
  token1_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  token1_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  token1_not_starts_with?: InputMaybe<Scalars["String"]["input"]>
  token1_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  token1_starts_with?: InputMaybe<Scalars["String"]["input"]>
  token1_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  tokenPair?: InputMaybe<Scalars["String"]["input"]>
  tokenPair_contains?: InputMaybe<Scalars["String"]["input"]>
  tokenPair_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  tokenPair_ends_with?: InputMaybe<Scalars["String"]["input"]>
  tokenPair_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  tokenPair_gt?: InputMaybe<Scalars["String"]["input"]>
  tokenPair_gte?: InputMaybe<Scalars["String"]["input"]>
  tokenPair_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  tokenPair_lt?: InputMaybe<Scalars["String"]["input"]>
  tokenPair_lte?: InputMaybe<Scalars["String"]["input"]>
  tokenPair_not?: InputMaybe<Scalars["String"]["input"]>
  tokenPair_not_contains?: InputMaybe<Scalars["String"]["input"]>
  tokenPair_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  tokenPair_not_ends_with?: InputMaybe<Scalars["String"]["input"]>
  tokenPair_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  tokenPair_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  tokenPair_not_starts_with?: InputMaybe<Scalars["String"]["input"]>
  tokenPair_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  tokenPair_starts_with?: InputMaybe<Scalars["String"]["input"]>
  tokenPair_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  totalPositions?: InputMaybe<Scalars["BigInt"]["input"]>
  totalPositions_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  totalPositions_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  totalPositions_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  totalPositions_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  totalPositions_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  totalPositions_not?: InputMaybe<Scalars["BigInt"]["input"]>
  totalPositions_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  totalVolume?: InputMaybe<Scalars["BigInt"]["input"]>
  totalVolume_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  totalVolume_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  totalVolume_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  totalVolume_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  totalVolume_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  totalVolume_not?: InputMaybe<Scalars["BigInt"]["input"]>
  totalVolume_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  txCount?: InputMaybe<Scalars["BigInt"]["input"]>
  txCount_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  txCount_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  txCount_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  txCount_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  txCount_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  txCount_not?: InputMaybe<Scalars["BigInt"]["input"]>
  txCount_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
}

export enum MarginalV1LbPool_OrderBy {
  BlockTimestamp = "blockTimestamp",
  BlockTimestampInitialize = "blockTimestampInitialize",
  Burn = "burn",
  BurnAmount0 = "burn__amount0",
  BurnAmount1 = "burn__amount1",
  BurnBlockNumber = "burn__blockNumber",
  BurnBlockTimestamp = "burn__blockTimestamp",
  BurnFees0 = "burn__fees0",
  BurnFees1 = "burn__fees1",
  BurnId = "burn__id",
  BurnLiquidityDelta = "burn__liquidityDelta",
  BurnOwner = "burn__owner",
  BurnRecipient = "burn__recipient",
  BurnTransactionHash = "burn__transactionHash",
  CreatedAtBlockNumber = "createdAtBlockNumber",
  CreatedAtTimestamp = "createdAtTimestamp",
  Decimals = "decimals",
  Factory = "factory",
  FactoryFeeProtocol = "factory__feeProtocol",
  FactoryId = "factory__id",
  FactoryMarginalV1Deployer = "factory__marginalV1Deployer",
  FactoryOwner = "factory__owner",
  FactoryPoolCount = "factory__poolCount",
  FactoryTxCount = "factory__txCount",
  FeeProtocol = "feeProtocol",
  Finalized = "finalized",
  Id = "id",
  LaunchTokenIndex = "launchTokenIndex",
  Liquidity = "liquidity",
  Mint = "mint",
  MintAmount0 = "mint__amount0",
  MintAmount1 = "mint__amount1",
  MintBlockNumber = "mint__blockNumber",
  MintBlockTimestamp = "mint__blockTimestamp",
  MintId = "mint__id",
  MintLiquidityDelta = "mint__liquidityDelta",
  MintOwner = "mint__owner",
  MintSender = "mint__sender",
  MintTransactionHash = "mint__transactionHash",
  Positions = "positions",
  SqrtPriceFinalizeX96 = "sqrtPriceFinalizeX96",
  SqrtPriceInitializeX96 = "sqrtPriceInitializeX96",
  SqrtPriceLowerX96 = "sqrtPriceLowerX96",
  SqrtPriceUpperX96 = "sqrtPriceUpperX96",
  SqrtPriceX96 = "sqrtPriceX96",
  Supplier = "supplier",
  Swaps = "swaps",
  Tick = "tick",
  TickCumulative = "tickCumulative",
  TickLower = "tickLower",
  TickUpper = "tickUpper",
  Token0 = "token0",
  Token0Address = "token0__address",
  Token0ChainId = "token0__chainId",
  Token0Decimals = "token0__decimals",
  Token0Id = "token0__id",
  Token0Name = "token0__name",
  Token0Symbol = "token0__symbol",
  Token1 = "token1",
  Token1Address = "token1__address",
  Token1ChainId = "token1__chainId",
  Token1Decimals = "token1__decimals",
  Token1Id = "token1__id",
  Token1Name = "token1__name",
  Token1Symbol = "token1__symbol",
  TokenPair = "tokenPair",
  TotalPositions = "totalPositions",
  TotalVolume = "totalVolume",
  TxCount = "txCount",
}

export type MarginalV1LbPosition = {
  __typename?: "MarginalV1LBPosition"
  id: Scalars["ID"]["output"]
  owner: Scalars["ID"]["output"]
  pool: MarginalV1LbPool
  shares: Scalars["BigInt"]["output"]
}

export type MarginalV1LbPosition_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>
  and?: InputMaybe<Array<InputMaybe<MarginalV1LbPosition_Filter>>>
  id?: InputMaybe<Scalars["ID"]["input"]>
  id_gt?: InputMaybe<Scalars["ID"]["input"]>
  id_gte?: InputMaybe<Scalars["ID"]["input"]>
  id_in?: InputMaybe<Array<Scalars["ID"]["input"]>>
  id_lt?: InputMaybe<Scalars["ID"]["input"]>
  id_lte?: InputMaybe<Scalars["ID"]["input"]>
  id_not?: InputMaybe<Scalars["ID"]["input"]>
  id_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>
  or?: InputMaybe<Array<InputMaybe<MarginalV1LbPosition_Filter>>>
  owner?: InputMaybe<Scalars["ID"]["input"]>
  owner_gt?: InputMaybe<Scalars["ID"]["input"]>
  owner_gte?: InputMaybe<Scalars["ID"]["input"]>
  owner_in?: InputMaybe<Array<Scalars["ID"]["input"]>>
  owner_lt?: InputMaybe<Scalars["ID"]["input"]>
  owner_lte?: InputMaybe<Scalars["ID"]["input"]>
  owner_not?: InputMaybe<Scalars["ID"]["input"]>
  owner_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>
  pool?: InputMaybe<Scalars["String"]["input"]>
  pool_?: InputMaybe<MarginalV1LbPool_Filter>
  pool_contains?: InputMaybe<Scalars["String"]["input"]>
  pool_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  pool_ends_with?: InputMaybe<Scalars["String"]["input"]>
  pool_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  pool_gt?: InputMaybe<Scalars["String"]["input"]>
  pool_gte?: InputMaybe<Scalars["String"]["input"]>
  pool_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  pool_lt?: InputMaybe<Scalars["String"]["input"]>
  pool_lte?: InputMaybe<Scalars["String"]["input"]>
  pool_not?: InputMaybe<Scalars["String"]["input"]>
  pool_not_contains?: InputMaybe<Scalars["String"]["input"]>
  pool_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  pool_not_ends_with?: InputMaybe<Scalars["String"]["input"]>
  pool_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  pool_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  pool_not_starts_with?: InputMaybe<Scalars["String"]["input"]>
  pool_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  pool_starts_with?: InputMaybe<Scalars["String"]["input"]>
  pool_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  shares?: InputMaybe<Scalars["BigInt"]["input"]>
  shares_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  shares_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  shares_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  shares_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  shares_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  shares_not?: InputMaybe<Scalars["BigInt"]["input"]>
  shares_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
}

export enum MarginalV1LbPosition_OrderBy {
  Id = "id",
  Owner = "owner",
  Pool = "pool",
  PoolBlockTimestamp = "pool__blockTimestamp",
  PoolBlockTimestampInitialize = "pool__blockTimestampInitialize",
  PoolCreatedAtBlockNumber = "pool__createdAtBlockNumber",
  PoolCreatedAtTimestamp = "pool__createdAtTimestamp",
  PoolDecimals = "pool__decimals",
  PoolFeeProtocol = "pool__feeProtocol",
  PoolFinalized = "pool__finalized",
  PoolId = "pool__id",
  PoolLaunchTokenIndex = "pool__launchTokenIndex",
  PoolLiquidity = "pool__liquidity",
  PoolSqrtPriceFinalizeX96 = "pool__sqrtPriceFinalizeX96",
  PoolSqrtPriceInitializeX96 = "pool__sqrtPriceInitializeX96",
  PoolSqrtPriceLowerX96 = "pool__sqrtPriceLowerX96",
  PoolSqrtPriceUpperX96 = "pool__sqrtPriceUpperX96",
  PoolSqrtPriceX96 = "pool__sqrtPriceX96",
  PoolSupplier = "pool__supplier",
  PoolTick = "pool__tick",
  PoolTickCumulative = "pool__tickCumulative",
  PoolTickLower = "pool__tickLower",
  PoolTickUpper = "pool__tickUpper",
  PoolTokenPair = "pool__tokenPair",
  PoolTotalPositions = "pool__totalPositions",
  PoolTotalVolume = "pool__totalVolume",
  PoolTxCount = "pool__txCount",
  Shares = "shares",
}

export type MultiRewardsFactory = {
  __typename?: "MultiRewardsFactory"
  id: Scalars["ID"]["output"]
  stakePools: Array<StakePool>
}

export type MultiRewardsFactoryStakePoolsArgs = {
  first?: InputMaybe<Scalars["Int"]["input"]>
  orderBy?: InputMaybe<StakePool_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars["Int"]["input"]>
  where?: InputMaybe<StakePool_Filter>
}

export type MultiRewardsFactory_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>
  and?: InputMaybe<Array<InputMaybe<MultiRewardsFactory_Filter>>>
  id?: InputMaybe<Scalars["ID"]["input"]>
  id_gt?: InputMaybe<Scalars["ID"]["input"]>
  id_gte?: InputMaybe<Scalars["ID"]["input"]>
  id_in?: InputMaybe<Array<Scalars["ID"]["input"]>>
  id_lt?: InputMaybe<Scalars["ID"]["input"]>
  id_lte?: InputMaybe<Scalars["ID"]["input"]>
  id_not?: InputMaybe<Scalars["ID"]["input"]>
  id_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>
  or?: InputMaybe<Array<InputMaybe<MultiRewardsFactory_Filter>>>
  stakePools_?: InputMaybe<StakePool_Filter>
}

export enum MultiRewardsFactory_OrderBy {
  Id = "id",
  StakePools = "stakePools",
}

export type Open = {
  __typename?: "Open"
  id: Scalars["ID"]["output"]
  margin: Scalars["BigInt"]["output"]
  owner: Scalars["Bytes"]["output"]
  pool: Pool
  sender: Scalars["Bytes"]["output"]
  timestamp: Scalars["BigInt"]["output"]
  token0: Token
  token1: Token
  transaction: Transaction
}

export type Open_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>
  and?: InputMaybe<Array<InputMaybe<Open_Filter>>>
  id?: InputMaybe<Scalars["ID"]["input"]>
  id_gt?: InputMaybe<Scalars["ID"]["input"]>
  id_gte?: InputMaybe<Scalars["ID"]["input"]>
  id_in?: InputMaybe<Array<Scalars["ID"]["input"]>>
  id_lt?: InputMaybe<Scalars["ID"]["input"]>
  id_lte?: InputMaybe<Scalars["ID"]["input"]>
  id_not?: InputMaybe<Scalars["ID"]["input"]>
  id_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>
  margin?: InputMaybe<Scalars["BigInt"]["input"]>
  margin_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  margin_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  margin_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  margin_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  margin_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  margin_not?: InputMaybe<Scalars["BigInt"]["input"]>
  margin_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  or?: InputMaybe<Array<InputMaybe<Open_Filter>>>
  owner?: InputMaybe<Scalars["Bytes"]["input"]>
  owner_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  owner_gt?: InputMaybe<Scalars["Bytes"]["input"]>
  owner_gte?: InputMaybe<Scalars["Bytes"]["input"]>
  owner_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  owner_lt?: InputMaybe<Scalars["Bytes"]["input"]>
  owner_lte?: InputMaybe<Scalars["Bytes"]["input"]>
  owner_not?: InputMaybe<Scalars["Bytes"]["input"]>
  owner_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  owner_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  pool?: InputMaybe<Scalars["String"]["input"]>
  pool_?: InputMaybe<Pool_Filter>
  pool_contains?: InputMaybe<Scalars["String"]["input"]>
  pool_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  pool_ends_with?: InputMaybe<Scalars["String"]["input"]>
  pool_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  pool_gt?: InputMaybe<Scalars["String"]["input"]>
  pool_gte?: InputMaybe<Scalars["String"]["input"]>
  pool_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  pool_lt?: InputMaybe<Scalars["String"]["input"]>
  pool_lte?: InputMaybe<Scalars["String"]["input"]>
  pool_not?: InputMaybe<Scalars["String"]["input"]>
  pool_not_contains?: InputMaybe<Scalars["String"]["input"]>
  pool_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  pool_not_ends_with?: InputMaybe<Scalars["String"]["input"]>
  pool_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  pool_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  pool_not_starts_with?: InputMaybe<Scalars["String"]["input"]>
  pool_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  pool_starts_with?: InputMaybe<Scalars["String"]["input"]>
  pool_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  sender?: InputMaybe<Scalars["Bytes"]["input"]>
  sender_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  sender_gt?: InputMaybe<Scalars["Bytes"]["input"]>
  sender_gte?: InputMaybe<Scalars["Bytes"]["input"]>
  sender_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  sender_lt?: InputMaybe<Scalars["Bytes"]["input"]>
  sender_lte?: InputMaybe<Scalars["Bytes"]["input"]>
  sender_not?: InputMaybe<Scalars["Bytes"]["input"]>
  sender_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  sender_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  timestamp?: InputMaybe<Scalars["BigInt"]["input"]>
  timestamp_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  timestamp_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  timestamp_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  timestamp_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  timestamp_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  timestamp_not?: InputMaybe<Scalars["BigInt"]["input"]>
  timestamp_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  token0?: InputMaybe<Scalars["String"]["input"]>
  token0_?: InputMaybe<Token_Filter>
  token0_contains?: InputMaybe<Scalars["String"]["input"]>
  token0_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  token0_ends_with?: InputMaybe<Scalars["String"]["input"]>
  token0_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  token0_gt?: InputMaybe<Scalars["String"]["input"]>
  token0_gte?: InputMaybe<Scalars["String"]["input"]>
  token0_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  token0_lt?: InputMaybe<Scalars["String"]["input"]>
  token0_lte?: InputMaybe<Scalars["String"]["input"]>
  token0_not?: InputMaybe<Scalars["String"]["input"]>
  token0_not_contains?: InputMaybe<Scalars["String"]["input"]>
  token0_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  token0_not_ends_with?: InputMaybe<Scalars["String"]["input"]>
  token0_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  token0_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  token0_not_starts_with?: InputMaybe<Scalars["String"]["input"]>
  token0_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  token0_starts_with?: InputMaybe<Scalars["String"]["input"]>
  token0_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  token1?: InputMaybe<Scalars["String"]["input"]>
  token1_?: InputMaybe<Token_Filter>
  token1_contains?: InputMaybe<Scalars["String"]["input"]>
  token1_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  token1_ends_with?: InputMaybe<Scalars["String"]["input"]>
  token1_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  token1_gt?: InputMaybe<Scalars["String"]["input"]>
  token1_gte?: InputMaybe<Scalars["String"]["input"]>
  token1_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  token1_lt?: InputMaybe<Scalars["String"]["input"]>
  token1_lte?: InputMaybe<Scalars["String"]["input"]>
  token1_not?: InputMaybe<Scalars["String"]["input"]>
  token1_not_contains?: InputMaybe<Scalars["String"]["input"]>
  token1_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  token1_not_ends_with?: InputMaybe<Scalars["String"]["input"]>
  token1_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  token1_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  token1_not_starts_with?: InputMaybe<Scalars["String"]["input"]>
  token1_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  token1_starts_with?: InputMaybe<Scalars["String"]["input"]>
  token1_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  transaction?: InputMaybe<Scalars["String"]["input"]>
  transaction_?: InputMaybe<Transaction_Filter>
  transaction_contains?: InputMaybe<Scalars["String"]["input"]>
  transaction_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  transaction_ends_with?: InputMaybe<Scalars["String"]["input"]>
  transaction_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  transaction_gt?: InputMaybe<Scalars["String"]["input"]>
  transaction_gte?: InputMaybe<Scalars["String"]["input"]>
  transaction_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  transaction_lt?: InputMaybe<Scalars["String"]["input"]>
  transaction_lte?: InputMaybe<Scalars["String"]["input"]>
  transaction_not?: InputMaybe<Scalars["String"]["input"]>
  transaction_not_contains?: InputMaybe<Scalars["String"]["input"]>
  transaction_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  transaction_not_ends_with?: InputMaybe<Scalars["String"]["input"]>
  transaction_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  transaction_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  transaction_not_starts_with?: InputMaybe<Scalars["String"]["input"]>
  transaction_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  transaction_starts_with?: InputMaybe<Scalars["String"]["input"]>
  transaction_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
}

export enum Open_OrderBy {
  Id = "id",
  Margin = "margin",
  Owner = "owner",
  Pool = "pool",
  PoolAddress = "pool__address",
  PoolCreatedAtBlockNumber = "pool__createdAtBlockNumber",
  PoolCreatedAtTimestamp = "pool__createdAtTimestamp",
  PoolDecimals = "pool__decimals",
  PoolFee = "pool__fee",
  PoolId = "pool__id",
  PoolLiquidityLocked = "pool__liquidityLocked",
  PoolMaintenance = "pool__maintenance",
  PoolOracle = "pool__oracle",
  PoolRewardPremium = "pool__rewardPremium",
  PoolTokenPair = "pool__tokenPair",
  PoolTxCount = "pool__txCount",
  Sender = "sender",
  Timestamp = "timestamp",
  Token0 = "token0",
  Token0Address = "token0__address",
  Token0ChainId = "token0__chainId",
  Token0Decimals = "token0__decimals",
  Token0Id = "token0__id",
  Token0Name = "token0__name",
  Token0Symbol = "token0__symbol",
  Token1 = "token1",
  Token1Address = "token1__address",
  Token1ChainId = "token1__chainId",
  Token1Decimals = "token1__decimals",
  Token1Id = "token1__id",
  Token1Name = "token1__name",
  Token1Symbol = "token1__symbol",
  Transaction = "transaction",
  TransactionBlockNumber = "transaction__blockNumber",
  TransactionGasLimit = "transaction__gasLimit",
  TransactionGasPrice = "transaction__gasPrice",
  TransactionId = "transaction__id",
  TransactionTimestamp = "transaction__timestamp",
}

/** Defines the order direction, either ascending or descending */
export enum OrderDirection {
  Asc = "asc",
  Desc = "desc",
}

export type Pool = {
  __typename?: "Pool"
  address: Scalars["Bytes"]["output"]
  createdAtBlockNumber: Scalars["BigInt"]["output"]
  createdAtTimestamp: Scalars["BigInt"]["output"]
  decimals: Scalars["BigInt"]["output"]
  factory: Factory
  fee: Scalars["BigInt"]["output"]
  id: Scalars["ID"]["output"]
  liquidityLocked: Scalars["BigInt"]["output"]
  maintenance: Scalars["BigInt"]["output"]
  oracle: Scalars["ID"]["output"]
  positions: Array<Position>
  rewardPremium: Scalars["BigInt"]["output"]
  stakePool?: Maybe<StakePool>
  token0: Token
  token1: Token
  tokenPair: Scalars["String"]["output"]
  txCount: Scalars["BigInt"]["output"]
}

export type PoolPositionsArgs = {
  first?: InputMaybe<Scalars["Int"]["input"]>
  orderBy?: InputMaybe<Position_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars["Int"]["input"]>
  where?: InputMaybe<Position_Filter>
}

export type Pool_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>
  address?: InputMaybe<Scalars["Bytes"]["input"]>
  address_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  address_gt?: InputMaybe<Scalars["Bytes"]["input"]>
  address_gte?: InputMaybe<Scalars["Bytes"]["input"]>
  address_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  address_lt?: InputMaybe<Scalars["Bytes"]["input"]>
  address_lte?: InputMaybe<Scalars["Bytes"]["input"]>
  address_not?: InputMaybe<Scalars["Bytes"]["input"]>
  address_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  address_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  and?: InputMaybe<Array<InputMaybe<Pool_Filter>>>
  createdAtBlockNumber?: InputMaybe<Scalars["BigInt"]["input"]>
  createdAtBlockNumber_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  createdAtBlockNumber_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  createdAtBlockNumber_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  createdAtBlockNumber_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  createdAtBlockNumber_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  createdAtBlockNumber_not?: InputMaybe<Scalars["BigInt"]["input"]>
  createdAtBlockNumber_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  createdAtTimestamp?: InputMaybe<Scalars["BigInt"]["input"]>
  createdAtTimestamp_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  createdAtTimestamp_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  createdAtTimestamp_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  createdAtTimestamp_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  createdAtTimestamp_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  createdAtTimestamp_not?: InputMaybe<Scalars["BigInt"]["input"]>
  createdAtTimestamp_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  decimals?: InputMaybe<Scalars["BigInt"]["input"]>
  decimals_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  decimals_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  decimals_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  decimals_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  decimals_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  decimals_not?: InputMaybe<Scalars["BigInt"]["input"]>
  decimals_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  factory?: InputMaybe<Scalars["String"]["input"]>
  factory_?: InputMaybe<Factory_Filter>
  factory_contains?: InputMaybe<Scalars["String"]["input"]>
  factory_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  factory_ends_with?: InputMaybe<Scalars["String"]["input"]>
  factory_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  factory_gt?: InputMaybe<Scalars["String"]["input"]>
  factory_gte?: InputMaybe<Scalars["String"]["input"]>
  factory_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  factory_lt?: InputMaybe<Scalars["String"]["input"]>
  factory_lte?: InputMaybe<Scalars["String"]["input"]>
  factory_not?: InputMaybe<Scalars["String"]["input"]>
  factory_not_contains?: InputMaybe<Scalars["String"]["input"]>
  factory_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  factory_not_ends_with?: InputMaybe<Scalars["String"]["input"]>
  factory_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  factory_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  factory_not_starts_with?: InputMaybe<Scalars["String"]["input"]>
  factory_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  factory_starts_with?: InputMaybe<Scalars["String"]["input"]>
  factory_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  fee?: InputMaybe<Scalars["BigInt"]["input"]>
  fee_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  fee_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  fee_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  fee_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  fee_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  fee_not?: InputMaybe<Scalars["BigInt"]["input"]>
  fee_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  id?: InputMaybe<Scalars["ID"]["input"]>
  id_gt?: InputMaybe<Scalars["ID"]["input"]>
  id_gte?: InputMaybe<Scalars["ID"]["input"]>
  id_in?: InputMaybe<Array<Scalars["ID"]["input"]>>
  id_lt?: InputMaybe<Scalars["ID"]["input"]>
  id_lte?: InputMaybe<Scalars["ID"]["input"]>
  id_not?: InputMaybe<Scalars["ID"]["input"]>
  id_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>
  liquidityLocked?: InputMaybe<Scalars["BigInt"]["input"]>
  liquidityLocked_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  liquidityLocked_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  liquidityLocked_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  liquidityLocked_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  liquidityLocked_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  liquidityLocked_not?: InputMaybe<Scalars["BigInt"]["input"]>
  liquidityLocked_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  maintenance?: InputMaybe<Scalars["BigInt"]["input"]>
  maintenance_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  maintenance_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  maintenance_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  maintenance_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  maintenance_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  maintenance_not?: InputMaybe<Scalars["BigInt"]["input"]>
  maintenance_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  or?: InputMaybe<Array<InputMaybe<Pool_Filter>>>
  oracle?: InputMaybe<Scalars["ID"]["input"]>
  oracle_gt?: InputMaybe<Scalars["ID"]["input"]>
  oracle_gte?: InputMaybe<Scalars["ID"]["input"]>
  oracle_in?: InputMaybe<Array<Scalars["ID"]["input"]>>
  oracle_lt?: InputMaybe<Scalars["ID"]["input"]>
  oracle_lte?: InputMaybe<Scalars["ID"]["input"]>
  oracle_not?: InputMaybe<Scalars["ID"]["input"]>
  oracle_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>
  positions_?: InputMaybe<Position_Filter>
  rewardPremium?: InputMaybe<Scalars["BigInt"]["input"]>
  rewardPremium_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  rewardPremium_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  rewardPremium_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  rewardPremium_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  rewardPremium_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  rewardPremium_not?: InputMaybe<Scalars["BigInt"]["input"]>
  rewardPremium_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  stakePool?: InputMaybe<Scalars["String"]["input"]>
  stakePool_?: InputMaybe<StakePool_Filter>
  stakePool_contains?: InputMaybe<Scalars["String"]["input"]>
  stakePool_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  stakePool_ends_with?: InputMaybe<Scalars["String"]["input"]>
  stakePool_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  stakePool_gt?: InputMaybe<Scalars["String"]["input"]>
  stakePool_gte?: InputMaybe<Scalars["String"]["input"]>
  stakePool_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  stakePool_lt?: InputMaybe<Scalars["String"]["input"]>
  stakePool_lte?: InputMaybe<Scalars["String"]["input"]>
  stakePool_not?: InputMaybe<Scalars["String"]["input"]>
  stakePool_not_contains?: InputMaybe<Scalars["String"]["input"]>
  stakePool_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  stakePool_not_ends_with?: InputMaybe<Scalars["String"]["input"]>
  stakePool_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  stakePool_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  stakePool_not_starts_with?: InputMaybe<Scalars["String"]["input"]>
  stakePool_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  stakePool_starts_with?: InputMaybe<Scalars["String"]["input"]>
  stakePool_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  token0?: InputMaybe<Scalars["String"]["input"]>
  token0_?: InputMaybe<Token_Filter>
  token0_contains?: InputMaybe<Scalars["String"]["input"]>
  token0_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  token0_ends_with?: InputMaybe<Scalars["String"]["input"]>
  token0_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  token0_gt?: InputMaybe<Scalars["String"]["input"]>
  token0_gte?: InputMaybe<Scalars["String"]["input"]>
  token0_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  token0_lt?: InputMaybe<Scalars["String"]["input"]>
  token0_lte?: InputMaybe<Scalars["String"]["input"]>
  token0_not?: InputMaybe<Scalars["String"]["input"]>
  token0_not_contains?: InputMaybe<Scalars["String"]["input"]>
  token0_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  token0_not_ends_with?: InputMaybe<Scalars["String"]["input"]>
  token0_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  token0_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  token0_not_starts_with?: InputMaybe<Scalars["String"]["input"]>
  token0_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  token0_starts_with?: InputMaybe<Scalars["String"]["input"]>
  token0_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  token1?: InputMaybe<Scalars["String"]["input"]>
  token1_?: InputMaybe<Token_Filter>
  token1_contains?: InputMaybe<Scalars["String"]["input"]>
  token1_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  token1_ends_with?: InputMaybe<Scalars["String"]["input"]>
  token1_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  token1_gt?: InputMaybe<Scalars["String"]["input"]>
  token1_gte?: InputMaybe<Scalars["String"]["input"]>
  token1_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  token1_lt?: InputMaybe<Scalars["String"]["input"]>
  token1_lte?: InputMaybe<Scalars["String"]["input"]>
  token1_not?: InputMaybe<Scalars["String"]["input"]>
  token1_not_contains?: InputMaybe<Scalars["String"]["input"]>
  token1_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  token1_not_ends_with?: InputMaybe<Scalars["String"]["input"]>
  token1_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  token1_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  token1_not_starts_with?: InputMaybe<Scalars["String"]["input"]>
  token1_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  token1_starts_with?: InputMaybe<Scalars["String"]["input"]>
  token1_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  tokenPair?: InputMaybe<Scalars["String"]["input"]>
  tokenPair_contains?: InputMaybe<Scalars["String"]["input"]>
  tokenPair_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  tokenPair_ends_with?: InputMaybe<Scalars["String"]["input"]>
  tokenPair_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  tokenPair_gt?: InputMaybe<Scalars["String"]["input"]>
  tokenPair_gte?: InputMaybe<Scalars["String"]["input"]>
  tokenPair_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  tokenPair_lt?: InputMaybe<Scalars["String"]["input"]>
  tokenPair_lte?: InputMaybe<Scalars["String"]["input"]>
  tokenPair_not?: InputMaybe<Scalars["String"]["input"]>
  tokenPair_not_contains?: InputMaybe<Scalars["String"]["input"]>
  tokenPair_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  tokenPair_not_ends_with?: InputMaybe<Scalars["String"]["input"]>
  tokenPair_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  tokenPair_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  tokenPair_not_starts_with?: InputMaybe<Scalars["String"]["input"]>
  tokenPair_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  tokenPair_starts_with?: InputMaybe<Scalars["String"]["input"]>
  tokenPair_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  txCount?: InputMaybe<Scalars["BigInt"]["input"]>
  txCount_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  txCount_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  txCount_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  txCount_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  txCount_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  txCount_not?: InputMaybe<Scalars["BigInt"]["input"]>
  txCount_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
}

export enum Pool_OrderBy {
  Address = "address",
  CreatedAtBlockNumber = "createdAtBlockNumber",
  CreatedAtTimestamp = "createdAtTimestamp",
  Decimals = "decimals",
  Factory = "factory",
  FactoryDeployer = "factory__deployer",
  FactoryId = "factory__id",
  FactoryMinCardinality = "factory__minCardinality",
  FactoryOwner = "factory__owner",
  FactoryPoolCount = "factory__poolCount",
  FactoryTxCount = "factory__txCount",
  FactoryUniV3Factory = "factory__uniV3Factory",
  Fee = "fee",
  Id = "id",
  LiquidityLocked = "liquidityLocked",
  Maintenance = "maintenance",
  Oracle = "oracle",
  Positions = "positions",
  RewardPremium = "rewardPremium",
  StakePool = "stakePool",
  StakePoolId = "stakePool__id",
  StakePoolStakeToken = "stakePool__stakeToken",
  Token0 = "token0",
  Token0Address = "token0__address",
  Token0ChainId = "token0__chainId",
  Token0Decimals = "token0__decimals",
  Token0Id = "token0__id",
  Token0Name = "token0__name",
  Token0Symbol = "token0__symbol",
  Token1 = "token1",
  Token1Address = "token1__address",
  Token1ChainId = "token1__chainId",
  Token1Decimals = "token1__decimals",
  Token1Id = "token1__id",
  Token1Name = "token1__name",
  Token1Symbol = "token1__symbol",
  TokenPair = "tokenPair",
  TxCount = "txCount",
}

export type Position = {
  __typename?: "Position"
  blockNumber?: Maybe<Scalars["BigInt"]["output"]>
  id: Scalars["ID"]["output"]
  initialMargin?: Maybe<Scalars["BigInt"]["output"]>
  initialSqrtPriceX96After?: Maybe<Scalars["BigInt"]["output"]>
  isClosed: Scalars["Boolean"]["output"]
  isLiquidated: Scalars["Boolean"]["output"]
  isSettled: Scalars["Boolean"]["output"]
  margin?: Maybe<Scalars["BigInt"]["output"]>
  marginAmountOut?: Maybe<Scalars["BigInt"]["output"]>
  owner: Scalars["ID"]["output"]
  pool: Pool
  positionId: Scalars["String"]["output"]
  rewards?: Maybe<Scalars["BigInt"]["output"]>
  timestamp?: Maybe<Scalars["BigInt"]["output"]>
  tokenId?: Maybe<Scalars["String"]["output"]>
  transaction: Transaction
  zeroForOne?: Maybe<Scalars["Boolean"]["output"]>
}

export type Position_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>
  and?: InputMaybe<Array<InputMaybe<Position_Filter>>>
  blockNumber?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  blockNumber_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_not?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  id?: InputMaybe<Scalars["ID"]["input"]>
  id_gt?: InputMaybe<Scalars["ID"]["input"]>
  id_gte?: InputMaybe<Scalars["ID"]["input"]>
  id_in?: InputMaybe<Array<Scalars["ID"]["input"]>>
  id_lt?: InputMaybe<Scalars["ID"]["input"]>
  id_lte?: InputMaybe<Scalars["ID"]["input"]>
  id_not?: InputMaybe<Scalars["ID"]["input"]>
  id_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>
  initialMargin?: InputMaybe<Scalars["BigInt"]["input"]>
  initialMargin_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  initialMargin_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  initialMargin_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  initialMargin_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  initialMargin_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  initialMargin_not?: InputMaybe<Scalars["BigInt"]["input"]>
  initialMargin_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  initialSqrtPriceX96After?: InputMaybe<Scalars["BigInt"]["input"]>
  initialSqrtPriceX96After_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  initialSqrtPriceX96After_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  initialSqrtPriceX96After_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  initialSqrtPriceX96After_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  initialSqrtPriceX96After_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  initialSqrtPriceX96After_not?: InputMaybe<Scalars["BigInt"]["input"]>
  initialSqrtPriceX96After_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  isClosed?: InputMaybe<Scalars["Boolean"]["input"]>
  isClosed_in?: InputMaybe<Array<Scalars["Boolean"]["input"]>>
  isClosed_not?: InputMaybe<Scalars["Boolean"]["input"]>
  isClosed_not_in?: InputMaybe<Array<Scalars["Boolean"]["input"]>>
  isLiquidated?: InputMaybe<Scalars["Boolean"]["input"]>
  isLiquidated_in?: InputMaybe<Array<Scalars["Boolean"]["input"]>>
  isLiquidated_not?: InputMaybe<Scalars["Boolean"]["input"]>
  isLiquidated_not_in?: InputMaybe<Array<Scalars["Boolean"]["input"]>>
  isSettled?: InputMaybe<Scalars["Boolean"]["input"]>
  isSettled_in?: InputMaybe<Array<Scalars["Boolean"]["input"]>>
  isSettled_not?: InputMaybe<Scalars["Boolean"]["input"]>
  isSettled_not_in?: InputMaybe<Array<Scalars["Boolean"]["input"]>>
  margin?: InputMaybe<Scalars["BigInt"]["input"]>
  marginAmountOut?: InputMaybe<Scalars["BigInt"]["input"]>
  marginAmountOut_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  marginAmountOut_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  marginAmountOut_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  marginAmountOut_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  marginAmountOut_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  marginAmountOut_not?: InputMaybe<Scalars["BigInt"]["input"]>
  marginAmountOut_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  margin_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  margin_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  margin_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  margin_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  margin_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  margin_not?: InputMaybe<Scalars["BigInt"]["input"]>
  margin_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  or?: InputMaybe<Array<InputMaybe<Position_Filter>>>
  owner?: InputMaybe<Scalars["ID"]["input"]>
  owner_gt?: InputMaybe<Scalars["ID"]["input"]>
  owner_gte?: InputMaybe<Scalars["ID"]["input"]>
  owner_in?: InputMaybe<Array<Scalars["ID"]["input"]>>
  owner_lt?: InputMaybe<Scalars["ID"]["input"]>
  owner_lte?: InputMaybe<Scalars["ID"]["input"]>
  owner_not?: InputMaybe<Scalars["ID"]["input"]>
  owner_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>
  pool?: InputMaybe<Scalars["String"]["input"]>
  pool_?: InputMaybe<Pool_Filter>
  pool_contains?: InputMaybe<Scalars["String"]["input"]>
  pool_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  pool_ends_with?: InputMaybe<Scalars["String"]["input"]>
  pool_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  pool_gt?: InputMaybe<Scalars["String"]["input"]>
  pool_gte?: InputMaybe<Scalars["String"]["input"]>
  pool_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  pool_lt?: InputMaybe<Scalars["String"]["input"]>
  pool_lte?: InputMaybe<Scalars["String"]["input"]>
  pool_not?: InputMaybe<Scalars["String"]["input"]>
  pool_not_contains?: InputMaybe<Scalars["String"]["input"]>
  pool_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  pool_not_ends_with?: InputMaybe<Scalars["String"]["input"]>
  pool_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  pool_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  pool_not_starts_with?: InputMaybe<Scalars["String"]["input"]>
  pool_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  pool_starts_with?: InputMaybe<Scalars["String"]["input"]>
  pool_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  positionId?: InputMaybe<Scalars["String"]["input"]>
  positionId_contains?: InputMaybe<Scalars["String"]["input"]>
  positionId_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  positionId_ends_with?: InputMaybe<Scalars["String"]["input"]>
  positionId_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  positionId_gt?: InputMaybe<Scalars["String"]["input"]>
  positionId_gte?: InputMaybe<Scalars["String"]["input"]>
  positionId_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  positionId_lt?: InputMaybe<Scalars["String"]["input"]>
  positionId_lte?: InputMaybe<Scalars["String"]["input"]>
  positionId_not?: InputMaybe<Scalars["String"]["input"]>
  positionId_not_contains?: InputMaybe<Scalars["String"]["input"]>
  positionId_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  positionId_not_ends_with?: InputMaybe<Scalars["String"]["input"]>
  positionId_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  positionId_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  positionId_not_starts_with?: InputMaybe<Scalars["String"]["input"]>
  positionId_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  positionId_starts_with?: InputMaybe<Scalars["String"]["input"]>
  positionId_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  rewards?: InputMaybe<Scalars["BigInt"]["input"]>
  rewards_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  rewards_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  rewards_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  rewards_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  rewards_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  rewards_not?: InputMaybe<Scalars["BigInt"]["input"]>
  rewards_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  timestamp?: InputMaybe<Scalars["BigInt"]["input"]>
  timestamp_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  timestamp_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  timestamp_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  timestamp_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  timestamp_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  timestamp_not?: InputMaybe<Scalars["BigInt"]["input"]>
  timestamp_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  tokenId?: InputMaybe<Scalars["String"]["input"]>
  tokenId_contains?: InputMaybe<Scalars["String"]["input"]>
  tokenId_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  tokenId_ends_with?: InputMaybe<Scalars["String"]["input"]>
  tokenId_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  tokenId_gt?: InputMaybe<Scalars["String"]["input"]>
  tokenId_gte?: InputMaybe<Scalars["String"]["input"]>
  tokenId_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  tokenId_lt?: InputMaybe<Scalars["String"]["input"]>
  tokenId_lte?: InputMaybe<Scalars["String"]["input"]>
  tokenId_not?: InputMaybe<Scalars["String"]["input"]>
  tokenId_not_contains?: InputMaybe<Scalars["String"]["input"]>
  tokenId_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  tokenId_not_ends_with?: InputMaybe<Scalars["String"]["input"]>
  tokenId_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  tokenId_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  tokenId_not_starts_with?: InputMaybe<Scalars["String"]["input"]>
  tokenId_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  tokenId_starts_with?: InputMaybe<Scalars["String"]["input"]>
  tokenId_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  transaction?: InputMaybe<Scalars["String"]["input"]>
  transaction_?: InputMaybe<Transaction_Filter>
  transaction_contains?: InputMaybe<Scalars["String"]["input"]>
  transaction_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  transaction_ends_with?: InputMaybe<Scalars["String"]["input"]>
  transaction_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  transaction_gt?: InputMaybe<Scalars["String"]["input"]>
  transaction_gte?: InputMaybe<Scalars["String"]["input"]>
  transaction_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  transaction_lt?: InputMaybe<Scalars["String"]["input"]>
  transaction_lte?: InputMaybe<Scalars["String"]["input"]>
  transaction_not?: InputMaybe<Scalars["String"]["input"]>
  transaction_not_contains?: InputMaybe<Scalars["String"]["input"]>
  transaction_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  transaction_not_ends_with?: InputMaybe<Scalars["String"]["input"]>
  transaction_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  transaction_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  transaction_not_starts_with?: InputMaybe<Scalars["String"]["input"]>
  transaction_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  transaction_starts_with?: InputMaybe<Scalars["String"]["input"]>
  transaction_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  zeroForOne?: InputMaybe<Scalars["Boolean"]["input"]>
  zeroForOne_in?: InputMaybe<Array<Scalars["Boolean"]["input"]>>
  zeroForOne_not?: InputMaybe<Scalars["Boolean"]["input"]>
  zeroForOne_not_in?: InputMaybe<Array<Scalars["Boolean"]["input"]>>
}

export enum Position_OrderBy {
  BlockNumber = "blockNumber",
  Id = "id",
  InitialMargin = "initialMargin",
  InitialSqrtPriceX96After = "initialSqrtPriceX96After",
  IsClosed = "isClosed",
  IsLiquidated = "isLiquidated",
  IsSettled = "isSettled",
  Margin = "margin",
  MarginAmountOut = "marginAmountOut",
  Owner = "owner",
  Pool = "pool",
  PoolAddress = "pool__address",
  PoolCreatedAtBlockNumber = "pool__createdAtBlockNumber",
  PoolCreatedAtTimestamp = "pool__createdAtTimestamp",
  PoolDecimals = "pool__decimals",
  PoolFee = "pool__fee",
  PoolId = "pool__id",
  PoolLiquidityLocked = "pool__liquidityLocked",
  PoolMaintenance = "pool__maintenance",
  PoolOracle = "pool__oracle",
  PoolRewardPremium = "pool__rewardPremium",
  PoolTokenPair = "pool__tokenPair",
  PoolTxCount = "pool__txCount",
  PositionId = "positionId",
  Rewards = "rewards",
  Timestamp = "timestamp",
  TokenId = "tokenId",
  Transaction = "transaction",
  TransactionBlockNumber = "transaction__blockNumber",
  TransactionGasLimit = "transaction__gasLimit",
  TransactionGasPrice = "transaction__gasPrice",
  TransactionId = "transaction__id",
  TransactionTimestamp = "transaction__timestamp",
  ZeroForOne = "zeroForOne",
}

export type Query = {
  __typename?: "Query"
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>
  adjust?: Maybe<Adjust>
  adjusts: Array<Adjust>
  factories: Array<Factory>
  factory?: Maybe<Factory>
  liquidate?: Maybe<Liquidate>
  liquidates: Array<Liquidate>
  marginalV1LBFactories: Array<MarginalV1LbFactory>
  marginalV1LBFactory?: Maybe<MarginalV1LbFactory>
  marginalV1LBFactoryCollectProtocol?: Maybe<MarginalV1LbFactoryCollectProtocol>
  marginalV1LBFactoryCollectProtocols: Array<MarginalV1LbFactoryCollectProtocol>
  marginalV1LBFactoryOwnerChanged?: Maybe<MarginalV1LbFactoryOwnerChanged>
  marginalV1LBFactoryOwnerChangeds: Array<MarginalV1LbFactoryOwnerChanged>
  marginalV1LBFactoryPoolCreated?: Maybe<MarginalV1LbFactoryPoolCreated>
  marginalV1LBFactoryPoolCreateds: Array<MarginalV1LbFactoryPoolCreated>
  marginalV1LBFactorySetFeeProtocol?: Maybe<MarginalV1LbFactorySetFeeProtocol>
  marginalV1LBFactorySetFeeProtocols: Array<MarginalV1LbFactorySetFeeProtocol>
  marginalV1LBPool?: Maybe<MarginalV1LbPool>
  marginalV1LBPoolApproval?: Maybe<MarginalV1LbPoolApproval>
  marginalV1LBPoolApprovals: Array<MarginalV1LbPoolApproval>
  marginalV1LBPoolBurn?: Maybe<MarginalV1LbPoolBurn>
  marginalV1LBPoolBurns: Array<MarginalV1LbPoolBurn>
  marginalV1LBPoolFinalize?: Maybe<MarginalV1LbPoolFinalize>
  marginalV1LBPoolFinalizes: Array<MarginalV1LbPoolFinalize>
  marginalV1LBPoolMint?: Maybe<MarginalV1LbPoolMint>
  marginalV1LBPoolMints: Array<MarginalV1LbPoolMint>
  marginalV1LBPoolSwap?: Maybe<MarginalV1LbPoolSwap>
  marginalV1LBPoolSwaps: Array<MarginalV1LbPoolSwap>
  marginalV1LBPoolTransfer?: Maybe<MarginalV1LbPoolTransfer>
  marginalV1LBPoolTransfers: Array<MarginalV1LbPoolTransfer>
  marginalV1LBPools: Array<MarginalV1LbPool>
  marginalV1LBPosition?: Maybe<MarginalV1LbPosition>
  marginalV1LBPositions: Array<MarginalV1LbPosition>
  multiRewardsFactories: Array<MultiRewardsFactory>
  multiRewardsFactory?: Maybe<MultiRewardsFactory>
  open?: Maybe<Open>
  opens: Array<Open>
  pool?: Maybe<Pool>
  pools: Array<Pool>
  position?: Maybe<Position>
  positions: Array<Position>
  receiverDeployed?: Maybe<ReceiverDeployed>
  receiverDeployeds: Array<ReceiverDeployed>
  settle?: Maybe<Settle>
  settles: Array<Settle>
  stakePool?: Maybe<StakePool>
  stakePools: Array<StakePool>
  token?: Maybe<Token>
  tokenPositionMapping?: Maybe<TokenPositionMapping>
  tokenPositionMappings: Array<TokenPositionMapping>
  tokens: Array<Token>
  transaction?: Maybe<Transaction>
  transactions: Array<Transaction>
}

export type Query_MetaArgs = {
  block?: InputMaybe<Block_Height>
}

export type QueryAdjustArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars["ID"]["input"]
  subgraphError?: _SubgraphErrorPolicy_
}

export type QueryAdjustsArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars["Int"]["input"]>
  orderBy?: InputMaybe<Adjust_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars["Int"]["input"]>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<Adjust_Filter>
}

export type QueryFactoriesArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars["Int"]["input"]>
  orderBy?: InputMaybe<Factory_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars["Int"]["input"]>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<Factory_Filter>
}

export type QueryFactoryArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars["ID"]["input"]
  subgraphError?: _SubgraphErrorPolicy_
}

export type QueryLiquidateArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars["ID"]["input"]
  subgraphError?: _SubgraphErrorPolicy_
}

export type QueryLiquidatesArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars["Int"]["input"]>
  orderBy?: InputMaybe<Liquidate_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars["Int"]["input"]>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<Liquidate_Filter>
}

export type QueryMarginalV1LbFactoriesArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars["Int"]["input"]>
  orderBy?: InputMaybe<MarginalV1LbFactory_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars["Int"]["input"]>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<MarginalV1LbFactory_Filter>
}

export type QueryMarginalV1LbFactoryArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars["ID"]["input"]
  subgraphError?: _SubgraphErrorPolicy_
}

export type QueryMarginalV1LbFactoryCollectProtocolArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars["ID"]["input"]
  subgraphError?: _SubgraphErrorPolicy_
}

export type QueryMarginalV1LbFactoryCollectProtocolsArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars["Int"]["input"]>
  orderBy?: InputMaybe<MarginalV1LbFactoryCollectProtocol_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars["Int"]["input"]>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<MarginalV1LbFactoryCollectProtocol_Filter>
}

export type QueryMarginalV1LbFactoryOwnerChangedArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars["ID"]["input"]
  subgraphError?: _SubgraphErrorPolicy_
}

export type QueryMarginalV1LbFactoryOwnerChangedsArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars["Int"]["input"]>
  orderBy?: InputMaybe<MarginalV1LbFactoryOwnerChanged_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars["Int"]["input"]>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<MarginalV1LbFactoryOwnerChanged_Filter>
}

export type QueryMarginalV1LbFactoryPoolCreatedArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars["ID"]["input"]
  subgraphError?: _SubgraphErrorPolicy_
}

export type QueryMarginalV1LbFactoryPoolCreatedsArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars["Int"]["input"]>
  orderBy?: InputMaybe<MarginalV1LbFactoryPoolCreated_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars["Int"]["input"]>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<MarginalV1LbFactoryPoolCreated_Filter>
}

export type QueryMarginalV1LbFactorySetFeeProtocolArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars["ID"]["input"]
  subgraphError?: _SubgraphErrorPolicy_
}

export type QueryMarginalV1LbFactorySetFeeProtocolsArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars["Int"]["input"]>
  orderBy?: InputMaybe<MarginalV1LbFactorySetFeeProtocol_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars["Int"]["input"]>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<MarginalV1LbFactorySetFeeProtocol_Filter>
}

export type QueryMarginalV1LbPoolArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars["ID"]["input"]
  subgraphError?: _SubgraphErrorPolicy_
}

export type QueryMarginalV1LbPoolApprovalArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars["ID"]["input"]
  subgraphError?: _SubgraphErrorPolicy_
}

export type QueryMarginalV1LbPoolApprovalsArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars["Int"]["input"]>
  orderBy?: InputMaybe<MarginalV1LbPoolApproval_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars["Int"]["input"]>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<MarginalV1LbPoolApproval_Filter>
}

export type QueryMarginalV1LbPoolBurnArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars["ID"]["input"]
  subgraphError?: _SubgraphErrorPolicy_
}

export type QueryMarginalV1LbPoolBurnsArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars["Int"]["input"]>
  orderBy?: InputMaybe<MarginalV1LbPoolBurn_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars["Int"]["input"]>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<MarginalV1LbPoolBurn_Filter>
}

export type QueryMarginalV1LbPoolFinalizeArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars["ID"]["input"]
  subgraphError?: _SubgraphErrorPolicy_
}

export type QueryMarginalV1LbPoolFinalizesArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars["Int"]["input"]>
  orderBy?: InputMaybe<MarginalV1LbPoolFinalize_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars["Int"]["input"]>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<MarginalV1LbPoolFinalize_Filter>
}

export type QueryMarginalV1LbPoolMintArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars["ID"]["input"]
  subgraphError?: _SubgraphErrorPolicy_
}

export type QueryMarginalV1LbPoolMintsArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars["Int"]["input"]>
  orderBy?: InputMaybe<MarginalV1LbPoolMint_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars["Int"]["input"]>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<MarginalV1LbPoolMint_Filter>
}

export type QueryMarginalV1LbPoolSwapArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars["ID"]["input"]
  subgraphError?: _SubgraphErrorPolicy_
}

export type QueryMarginalV1LbPoolSwapsArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars["Int"]["input"]>
  orderBy?: InputMaybe<MarginalV1LbPoolSwap_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars["Int"]["input"]>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<MarginalV1LbPoolSwap_Filter>
}

export type QueryMarginalV1LbPoolTransferArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars["ID"]["input"]
  subgraphError?: _SubgraphErrorPolicy_
}

export type QueryMarginalV1LbPoolTransfersArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars["Int"]["input"]>
  orderBy?: InputMaybe<MarginalV1LbPoolTransfer_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars["Int"]["input"]>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<MarginalV1LbPoolTransfer_Filter>
}

export type QueryMarginalV1LbPoolsArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars["Int"]["input"]>
  orderBy?: InputMaybe<MarginalV1LbPool_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars["Int"]["input"]>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<MarginalV1LbPool_Filter>
}

export type QueryMarginalV1LbPositionArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars["ID"]["input"]
  subgraphError?: _SubgraphErrorPolicy_
}

export type QueryMarginalV1LbPositionsArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars["Int"]["input"]>
  orderBy?: InputMaybe<MarginalV1LbPosition_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars["Int"]["input"]>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<MarginalV1LbPosition_Filter>
}

export type QueryMultiRewardsFactoriesArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars["Int"]["input"]>
  orderBy?: InputMaybe<MultiRewardsFactory_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars["Int"]["input"]>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<MultiRewardsFactory_Filter>
}

export type QueryMultiRewardsFactoryArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars["ID"]["input"]
  subgraphError?: _SubgraphErrorPolicy_
}

export type QueryOpenArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars["ID"]["input"]
  subgraphError?: _SubgraphErrorPolicy_
}

export type QueryOpensArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars["Int"]["input"]>
  orderBy?: InputMaybe<Open_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars["Int"]["input"]>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<Open_Filter>
}

export type QueryPoolArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars["ID"]["input"]
  subgraphError?: _SubgraphErrorPolicy_
}

export type QueryPoolsArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars["Int"]["input"]>
  orderBy?: InputMaybe<Pool_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars["Int"]["input"]>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<Pool_Filter>
}

export type QueryPositionArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars["ID"]["input"]
  subgraphError?: _SubgraphErrorPolicy_
}

export type QueryPositionsArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars["Int"]["input"]>
  orderBy?: InputMaybe<Position_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars["Int"]["input"]>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<Position_Filter>
}

export type QueryReceiverDeployedArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars["ID"]["input"]
  subgraphError?: _SubgraphErrorPolicy_
}

export type QueryReceiverDeployedsArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars["Int"]["input"]>
  orderBy?: InputMaybe<ReceiverDeployed_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars["Int"]["input"]>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<ReceiverDeployed_Filter>
}

export type QuerySettleArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars["ID"]["input"]
  subgraphError?: _SubgraphErrorPolicy_
}

export type QuerySettlesArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars["Int"]["input"]>
  orderBy?: InputMaybe<Settle_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars["Int"]["input"]>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<Settle_Filter>
}

export type QueryStakePoolArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars["ID"]["input"]
  subgraphError?: _SubgraphErrorPolicy_
}

export type QueryStakePoolsArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars["Int"]["input"]>
  orderBy?: InputMaybe<StakePool_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars["Int"]["input"]>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<StakePool_Filter>
}

export type QueryTokenArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars["ID"]["input"]
  subgraphError?: _SubgraphErrorPolicy_
}

export type QueryTokenPositionMappingArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars["ID"]["input"]
  subgraphError?: _SubgraphErrorPolicy_
}

export type QueryTokenPositionMappingsArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars["Int"]["input"]>
  orderBy?: InputMaybe<TokenPositionMapping_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars["Int"]["input"]>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<TokenPositionMapping_Filter>
}

export type QueryTokensArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars["Int"]["input"]>
  orderBy?: InputMaybe<Token_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars["Int"]["input"]>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<Token_Filter>
}

export type QueryTransactionArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars["ID"]["input"]
  subgraphError?: _SubgraphErrorPolicy_
}

export type QueryTransactionsArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars["Int"]["input"]>
  orderBy?: InputMaybe<Transaction_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars["Int"]["input"]>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<Transaction_Filter>
}

export type ReceiverDeployed = {
  __typename?: "ReceiverDeployed"
  blockNumber: Scalars["BigInt"]["output"]
  blockTimestamp: Scalars["BigInt"]["output"]
  data: Scalars["Bytes"]["output"]
  id: Scalars["Bytes"]["output"]
  pool: Scalars["Bytes"]["output"]
  receiver: Scalars["Bytes"]["output"]
  transactionHash: Scalars["Bytes"]["output"]
}

export type ReceiverDeployed_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>
  and?: InputMaybe<Array<InputMaybe<ReceiverDeployed_Filter>>>
  blockNumber?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  blockNumber_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_not?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  blockTimestamp?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  blockTimestamp_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_not?: InputMaybe<Scalars["BigInt"]["input"]>
  blockTimestamp_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  data?: InputMaybe<Scalars["Bytes"]["input"]>
  data_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  data_gt?: InputMaybe<Scalars["Bytes"]["input"]>
  data_gte?: InputMaybe<Scalars["Bytes"]["input"]>
  data_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  data_lt?: InputMaybe<Scalars["Bytes"]["input"]>
  data_lte?: InputMaybe<Scalars["Bytes"]["input"]>
  data_not?: InputMaybe<Scalars["Bytes"]["input"]>
  data_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  data_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  id?: InputMaybe<Scalars["Bytes"]["input"]>
  id_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  id_gt?: InputMaybe<Scalars["Bytes"]["input"]>
  id_gte?: InputMaybe<Scalars["Bytes"]["input"]>
  id_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  id_lt?: InputMaybe<Scalars["Bytes"]["input"]>
  id_lte?: InputMaybe<Scalars["Bytes"]["input"]>
  id_not?: InputMaybe<Scalars["Bytes"]["input"]>
  id_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  id_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  or?: InputMaybe<Array<InputMaybe<ReceiverDeployed_Filter>>>
  pool?: InputMaybe<Scalars["Bytes"]["input"]>
  pool_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  pool_gt?: InputMaybe<Scalars["Bytes"]["input"]>
  pool_gte?: InputMaybe<Scalars["Bytes"]["input"]>
  pool_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  pool_lt?: InputMaybe<Scalars["Bytes"]["input"]>
  pool_lte?: InputMaybe<Scalars["Bytes"]["input"]>
  pool_not?: InputMaybe<Scalars["Bytes"]["input"]>
  pool_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  pool_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  receiver?: InputMaybe<Scalars["Bytes"]["input"]>
  receiver_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  receiver_gt?: InputMaybe<Scalars["Bytes"]["input"]>
  receiver_gte?: InputMaybe<Scalars["Bytes"]["input"]>
  receiver_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  receiver_lt?: InputMaybe<Scalars["Bytes"]["input"]>
  receiver_lte?: InputMaybe<Scalars["Bytes"]["input"]>
  receiver_not?: InputMaybe<Scalars["Bytes"]["input"]>
  receiver_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  receiver_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  transactionHash?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_gt?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_gte?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  transactionHash_lt?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_lte?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_not?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  transactionHash_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
}

export enum ReceiverDeployed_OrderBy {
  BlockNumber = "blockNumber",
  BlockTimestamp = "blockTimestamp",
  Data = "data",
  Id = "id",
  Pool = "pool",
  Receiver = "receiver",
  TransactionHash = "transactionHash",
}

export type Settle = {
  __typename?: "Settle"
  id: Scalars["ID"]["output"]
  owner: Scalars["Bytes"]["output"]
  pool: Pool
  recipient: Scalars["Bytes"]["output"]
  rewards: Scalars["BigInt"]["output"]
  timestamp: Scalars["BigInt"]["output"]
  token0: Token
  token1: Token
  transaction: Transaction
}

export type Settle_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>
  and?: InputMaybe<Array<InputMaybe<Settle_Filter>>>
  id?: InputMaybe<Scalars["ID"]["input"]>
  id_gt?: InputMaybe<Scalars["ID"]["input"]>
  id_gte?: InputMaybe<Scalars["ID"]["input"]>
  id_in?: InputMaybe<Array<Scalars["ID"]["input"]>>
  id_lt?: InputMaybe<Scalars["ID"]["input"]>
  id_lte?: InputMaybe<Scalars["ID"]["input"]>
  id_not?: InputMaybe<Scalars["ID"]["input"]>
  id_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>
  or?: InputMaybe<Array<InputMaybe<Settle_Filter>>>
  owner?: InputMaybe<Scalars["Bytes"]["input"]>
  owner_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  owner_gt?: InputMaybe<Scalars["Bytes"]["input"]>
  owner_gte?: InputMaybe<Scalars["Bytes"]["input"]>
  owner_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  owner_lt?: InputMaybe<Scalars["Bytes"]["input"]>
  owner_lte?: InputMaybe<Scalars["Bytes"]["input"]>
  owner_not?: InputMaybe<Scalars["Bytes"]["input"]>
  owner_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  owner_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  pool?: InputMaybe<Scalars["String"]["input"]>
  pool_?: InputMaybe<Pool_Filter>
  pool_contains?: InputMaybe<Scalars["String"]["input"]>
  pool_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  pool_ends_with?: InputMaybe<Scalars["String"]["input"]>
  pool_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  pool_gt?: InputMaybe<Scalars["String"]["input"]>
  pool_gte?: InputMaybe<Scalars["String"]["input"]>
  pool_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  pool_lt?: InputMaybe<Scalars["String"]["input"]>
  pool_lte?: InputMaybe<Scalars["String"]["input"]>
  pool_not?: InputMaybe<Scalars["String"]["input"]>
  pool_not_contains?: InputMaybe<Scalars["String"]["input"]>
  pool_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  pool_not_ends_with?: InputMaybe<Scalars["String"]["input"]>
  pool_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  pool_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  pool_not_starts_with?: InputMaybe<Scalars["String"]["input"]>
  pool_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  pool_starts_with?: InputMaybe<Scalars["String"]["input"]>
  pool_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  recipient?: InputMaybe<Scalars["Bytes"]["input"]>
  recipient_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  recipient_gt?: InputMaybe<Scalars["Bytes"]["input"]>
  recipient_gte?: InputMaybe<Scalars["Bytes"]["input"]>
  recipient_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  recipient_lt?: InputMaybe<Scalars["Bytes"]["input"]>
  recipient_lte?: InputMaybe<Scalars["Bytes"]["input"]>
  recipient_not?: InputMaybe<Scalars["Bytes"]["input"]>
  recipient_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  recipient_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  rewards?: InputMaybe<Scalars["BigInt"]["input"]>
  rewards_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  rewards_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  rewards_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  rewards_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  rewards_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  rewards_not?: InputMaybe<Scalars["BigInt"]["input"]>
  rewards_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  timestamp?: InputMaybe<Scalars["BigInt"]["input"]>
  timestamp_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  timestamp_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  timestamp_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  timestamp_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  timestamp_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  timestamp_not?: InputMaybe<Scalars["BigInt"]["input"]>
  timestamp_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  token0?: InputMaybe<Scalars["String"]["input"]>
  token0_?: InputMaybe<Token_Filter>
  token0_contains?: InputMaybe<Scalars["String"]["input"]>
  token0_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  token0_ends_with?: InputMaybe<Scalars["String"]["input"]>
  token0_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  token0_gt?: InputMaybe<Scalars["String"]["input"]>
  token0_gte?: InputMaybe<Scalars["String"]["input"]>
  token0_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  token0_lt?: InputMaybe<Scalars["String"]["input"]>
  token0_lte?: InputMaybe<Scalars["String"]["input"]>
  token0_not?: InputMaybe<Scalars["String"]["input"]>
  token0_not_contains?: InputMaybe<Scalars["String"]["input"]>
  token0_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  token0_not_ends_with?: InputMaybe<Scalars["String"]["input"]>
  token0_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  token0_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  token0_not_starts_with?: InputMaybe<Scalars["String"]["input"]>
  token0_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  token0_starts_with?: InputMaybe<Scalars["String"]["input"]>
  token0_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  token1?: InputMaybe<Scalars["String"]["input"]>
  token1_?: InputMaybe<Token_Filter>
  token1_contains?: InputMaybe<Scalars["String"]["input"]>
  token1_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  token1_ends_with?: InputMaybe<Scalars["String"]["input"]>
  token1_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  token1_gt?: InputMaybe<Scalars["String"]["input"]>
  token1_gte?: InputMaybe<Scalars["String"]["input"]>
  token1_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  token1_lt?: InputMaybe<Scalars["String"]["input"]>
  token1_lte?: InputMaybe<Scalars["String"]["input"]>
  token1_not?: InputMaybe<Scalars["String"]["input"]>
  token1_not_contains?: InputMaybe<Scalars["String"]["input"]>
  token1_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  token1_not_ends_with?: InputMaybe<Scalars["String"]["input"]>
  token1_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  token1_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  token1_not_starts_with?: InputMaybe<Scalars["String"]["input"]>
  token1_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  token1_starts_with?: InputMaybe<Scalars["String"]["input"]>
  token1_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  transaction?: InputMaybe<Scalars["String"]["input"]>
  transaction_?: InputMaybe<Transaction_Filter>
  transaction_contains?: InputMaybe<Scalars["String"]["input"]>
  transaction_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  transaction_ends_with?: InputMaybe<Scalars["String"]["input"]>
  transaction_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  transaction_gt?: InputMaybe<Scalars["String"]["input"]>
  transaction_gte?: InputMaybe<Scalars["String"]["input"]>
  transaction_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  transaction_lt?: InputMaybe<Scalars["String"]["input"]>
  transaction_lte?: InputMaybe<Scalars["String"]["input"]>
  transaction_not?: InputMaybe<Scalars["String"]["input"]>
  transaction_not_contains?: InputMaybe<Scalars["String"]["input"]>
  transaction_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  transaction_not_ends_with?: InputMaybe<Scalars["String"]["input"]>
  transaction_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  transaction_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  transaction_not_starts_with?: InputMaybe<Scalars["String"]["input"]>
  transaction_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  transaction_starts_with?: InputMaybe<Scalars["String"]["input"]>
  transaction_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
}

export enum Settle_OrderBy {
  Id = "id",
  Owner = "owner",
  Pool = "pool",
  PoolAddress = "pool__address",
  PoolCreatedAtBlockNumber = "pool__createdAtBlockNumber",
  PoolCreatedAtTimestamp = "pool__createdAtTimestamp",
  PoolDecimals = "pool__decimals",
  PoolFee = "pool__fee",
  PoolId = "pool__id",
  PoolLiquidityLocked = "pool__liquidityLocked",
  PoolMaintenance = "pool__maintenance",
  PoolOracle = "pool__oracle",
  PoolRewardPremium = "pool__rewardPremium",
  PoolTokenPair = "pool__tokenPair",
  PoolTxCount = "pool__txCount",
  Recipient = "recipient",
  Rewards = "rewards",
  Timestamp = "timestamp",
  Token0 = "token0",
  Token0Address = "token0__address",
  Token0ChainId = "token0__chainId",
  Token0Decimals = "token0__decimals",
  Token0Id = "token0__id",
  Token0Name = "token0__name",
  Token0Symbol = "token0__symbol",
  Token1 = "token1",
  Token1Address = "token1__address",
  Token1ChainId = "token1__chainId",
  Token1Decimals = "token1__decimals",
  Token1Id = "token1__id",
  Token1Name = "token1__name",
  Token1Symbol = "token1__symbol",
  Transaction = "transaction",
  TransactionBlockNumber = "transaction__blockNumber",
  TransactionGasLimit = "transaction__gasLimit",
  TransactionGasPrice = "transaction__gasPrice",
  TransactionId = "transaction__id",
  TransactionTimestamp = "transaction__timestamp",
}

export type StakePool = {
  __typename?: "StakePool"
  id: Scalars["ID"]["output"]
  multiRewardsFactory: MultiRewardsFactory
  stakeToken: Scalars["Bytes"]["output"]
}

export type StakePool_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>
  and?: InputMaybe<Array<InputMaybe<StakePool_Filter>>>
  id?: InputMaybe<Scalars["ID"]["input"]>
  id_gt?: InputMaybe<Scalars["ID"]["input"]>
  id_gte?: InputMaybe<Scalars["ID"]["input"]>
  id_in?: InputMaybe<Array<Scalars["ID"]["input"]>>
  id_lt?: InputMaybe<Scalars["ID"]["input"]>
  id_lte?: InputMaybe<Scalars["ID"]["input"]>
  id_not?: InputMaybe<Scalars["ID"]["input"]>
  id_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>
  multiRewardsFactory?: InputMaybe<Scalars["String"]["input"]>
  multiRewardsFactory_?: InputMaybe<MultiRewardsFactory_Filter>
  multiRewardsFactory_contains?: InputMaybe<Scalars["String"]["input"]>
  multiRewardsFactory_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  multiRewardsFactory_ends_with?: InputMaybe<Scalars["String"]["input"]>
  multiRewardsFactory_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  multiRewardsFactory_gt?: InputMaybe<Scalars["String"]["input"]>
  multiRewardsFactory_gte?: InputMaybe<Scalars["String"]["input"]>
  multiRewardsFactory_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  multiRewardsFactory_lt?: InputMaybe<Scalars["String"]["input"]>
  multiRewardsFactory_lte?: InputMaybe<Scalars["String"]["input"]>
  multiRewardsFactory_not?: InputMaybe<Scalars["String"]["input"]>
  multiRewardsFactory_not_contains?: InputMaybe<Scalars["String"]["input"]>
  multiRewardsFactory_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  multiRewardsFactory_not_ends_with?: InputMaybe<Scalars["String"]["input"]>
  multiRewardsFactory_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  multiRewardsFactory_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  multiRewardsFactory_not_starts_with?: InputMaybe<Scalars["String"]["input"]>
  multiRewardsFactory_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  multiRewardsFactory_starts_with?: InputMaybe<Scalars["String"]["input"]>
  multiRewardsFactory_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  or?: InputMaybe<Array<InputMaybe<StakePool_Filter>>>
  stakeToken?: InputMaybe<Scalars["Bytes"]["input"]>
  stakeToken_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  stakeToken_gt?: InputMaybe<Scalars["Bytes"]["input"]>
  stakeToken_gte?: InputMaybe<Scalars["Bytes"]["input"]>
  stakeToken_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
  stakeToken_lt?: InputMaybe<Scalars["Bytes"]["input"]>
  stakeToken_lte?: InputMaybe<Scalars["Bytes"]["input"]>
  stakeToken_not?: InputMaybe<Scalars["Bytes"]["input"]>
  stakeToken_not_contains?: InputMaybe<Scalars["Bytes"]["input"]>
  stakeToken_not_in?: InputMaybe<Array<Scalars["Bytes"]["input"]>>
}

export enum StakePool_OrderBy {
  Id = "id",
  MultiRewardsFactory = "multiRewardsFactory",
  MultiRewardsFactoryId = "multiRewardsFactory__id",
  StakeToken = "stakeToken",
}

export type Subscription = {
  __typename?: "Subscription"
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>
  adjust?: Maybe<Adjust>
  adjusts: Array<Adjust>
  factories: Array<Factory>
  factory?: Maybe<Factory>
  liquidate?: Maybe<Liquidate>
  liquidates: Array<Liquidate>
  marginalV1LBFactories: Array<MarginalV1LbFactory>
  marginalV1LBFactory?: Maybe<MarginalV1LbFactory>
  marginalV1LBFactoryCollectProtocol?: Maybe<MarginalV1LbFactoryCollectProtocol>
  marginalV1LBFactoryCollectProtocols: Array<MarginalV1LbFactoryCollectProtocol>
  marginalV1LBFactoryOwnerChanged?: Maybe<MarginalV1LbFactoryOwnerChanged>
  marginalV1LBFactoryOwnerChangeds: Array<MarginalV1LbFactoryOwnerChanged>
  marginalV1LBFactoryPoolCreated?: Maybe<MarginalV1LbFactoryPoolCreated>
  marginalV1LBFactoryPoolCreateds: Array<MarginalV1LbFactoryPoolCreated>
  marginalV1LBFactorySetFeeProtocol?: Maybe<MarginalV1LbFactorySetFeeProtocol>
  marginalV1LBFactorySetFeeProtocols: Array<MarginalV1LbFactorySetFeeProtocol>
  marginalV1LBPool?: Maybe<MarginalV1LbPool>
  marginalV1LBPoolApproval?: Maybe<MarginalV1LbPoolApproval>
  marginalV1LBPoolApprovals: Array<MarginalV1LbPoolApproval>
  marginalV1LBPoolBurn?: Maybe<MarginalV1LbPoolBurn>
  marginalV1LBPoolBurns: Array<MarginalV1LbPoolBurn>
  marginalV1LBPoolFinalize?: Maybe<MarginalV1LbPoolFinalize>
  marginalV1LBPoolFinalizes: Array<MarginalV1LbPoolFinalize>
  marginalV1LBPoolMint?: Maybe<MarginalV1LbPoolMint>
  marginalV1LBPoolMints: Array<MarginalV1LbPoolMint>
  marginalV1LBPoolSwap?: Maybe<MarginalV1LbPoolSwap>
  marginalV1LBPoolSwaps: Array<MarginalV1LbPoolSwap>
  marginalV1LBPoolTransfer?: Maybe<MarginalV1LbPoolTransfer>
  marginalV1LBPoolTransfers: Array<MarginalV1LbPoolTransfer>
  marginalV1LBPools: Array<MarginalV1LbPool>
  marginalV1LBPosition?: Maybe<MarginalV1LbPosition>
  marginalV1LBPositions: Array<MarginalV1LbPosition>
  multiRewardsFactories: Array<MultiRewardsFactory>
  multiRewardsFactory?: Maybe<MultiRewardsFactory>
  open?: Maybe<Open>
  opens: Array<Open>
  pool?: Maybe<Pool>
  pools: Array<Pool>
  position?: Maybe<Position>
  positions: Array<Position>
  receiverDeployed?: Maybe<ReceiverDeployed>
  receiverDeployeds: Array<ReceiverDeployed>
  settle?: Maybe<Settle>
  settles: Array<Settle>
  stakePool?: Maybe<StakePool>
  stakePools: Array<StakePool>
  token?: Maybe<Token>
  tokenPositionMapping?: Maybe<TokenPositionMapping>
  tokenPositionMappings: Array<TokenPositionMapping>
  tokens: Array<Token>
  transaction?: Maybe<Transaction>
  transactions: Array<Transaction>
}

export type Subscription_MetaArgs = {
  block?: InputMaybe<Block_Height>
}

export type SubscriptionAdjustArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars["ID"]["input"]
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionAdjustsArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars["Int"]["input"]>
  orderBy?: InputMaybe<Adjust_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars["Int"]["input"]>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<Adjust_Filter>
}

export type SubscriptionFactoriesArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars["Int"]["input"]>
  orderBy?: InputMaybe<Factory_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars["Int"]["input"]>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<Factory_Filter>
}

export type SubscriptionFactoryArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars["ID"]["input"]
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionLiquidateArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars["ID"]["input"]
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionLiquidatesArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars["Int"]["input"]>
  orderBy?: InputMaybe<Liquidate_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars["Int"]["input"]>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<Liquidate_Filter>
}

export type SubscriptionMarginalV1LbFactoriesArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars["Int"]["input"]>
  orderBy?: InputMaybe<MarginalV1LbFactory_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars["Int"]["input"]>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<MarginalV1LbFactory_Filter>
}

export type SubscriptionMarginalV1LbFactoryArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars["ID"]["input"]
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionMarginalV1LbFactoryCollectProtocolArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars["ID"]["input"]
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionMarginalV1LbFactoryCollectProtocolsArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars["Int"]["input"]>
  orderBy?: InputMaybe<MarginalV1LbFactoryCollectProtocol_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars["Int"]["input"]>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<MarginalV1LbFactoryCollectProtocol_Filter>
}

export type SubscriptionMarginalV1LbFactoryOwnerChangedArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars["ID"]["input"]
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionMarginalV1LbFactoryOwnerChangedsArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars["Int"]["input"]>
  orderBy?: InputMaybe<MarginalV1LbFactoryOwnerChanged_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars["Int"]["input"]>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<MarginalV1LbFactoryOwnerChanged_Filter>
}

export type SubscriptionMarginalV1LbFactoryPoolCreatedArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars["ID"]["input"]
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionMarginalV1LbFactoryPoolCreatedsArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars["Int"]["input"]>
  orderBy?: InputMaybe<MarginalV1LbFactoryPoolCreated_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars["Int"]["input"]>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<MarginalV1LbFactoryPoolCreated_Filter>
}

export type SubscriptionMarginalV1LbFactorySetFeeProtocolArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars["ID"]["input"]
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionMarginalV1LbFactorySetFeeProtocolsArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars["Int"]["input"]>
  orderBy?: InputMaybe<MarginalV1LbFactorySetFeeProtocol_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars["Int"]["input"]>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<MarginalV1LbFactorySetFeeProtocol_Filter>
}

export type SubscriptionMarginalV1LbPoolArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars["ID"]["input"]
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionMarginalV1LbPoolApprovalArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars["ID"]["input"]
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionMarginalV1LbPoolApprovalsArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars["Int"]["input"]>
  orderBy?: InputMaybe<MarginalV1LbPoolApproval_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars["Int"]["input"]>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<MarginalV1LbPoolApproval_Filter>
}

export type SubscriptionMarginalV1LbPoolBurnArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars["ID"]["input"]
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionMarginalV1LbPoolBurnsArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars["Int"]["input"]>
  orderBy?: InputMaybe<MarginalV1LbPoolBurn_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars["Int"]["input"]>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<MarginalV1LbPoolBurn_Filter>
}

export type SubscriptionMarginalV1LbPoolFinalizeArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars["ID"]["input"]
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionMarginalV1LbPoolFinalizesArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars["Int"]["input"]>
  orderBy?: InputMaybe<MarginalV1LbPoolFinalize_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars["Int"]["input"]>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<MarginalV1LbPoolFinalize_Filter>
}

export type SubscriptionMarginalV1LbPoolMintArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars["ID"]["input"]
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionMarginalV1LbPoolMintsArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars["Int"]["input"]>
  orderBy?: InputMaybe<MarginalV1LbPoolMint_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars["Int"]["input"]>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<MarginalV1LbPoolMint_Filter>
}

export type SubscriptionMarginalV1LbPoolSwapArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars["ID"]["input"]
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionMarginalV1LbPoolSwapsArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars["Int"]["input"]>
  orderBy?: InputMaybe<MarginalV1LbPoolSwap_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars["Int"]["input"]>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<MarginalV1LbPoolSwap_Filter>
}

export type SubscriptionMarginalV1LbPoolTransferArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars["ID"]["input"]
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionMarginalV1LbPoolTransfersArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars["Int"]["input"]>
  orderBy?: InputMaybe<MarginalV1LbPoolTransfer_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars["Int"]["input"]>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<MarginalV1LbPoolTransfer_Filter>
}

export type SubscriptionMarginalV1LbPoolsArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars["Int"]["input"]>
  orderBy?: InputMaybe<MarginalV1LbPool_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars["Int"]["input"]>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<MarginalV1LbPool_Filter>
}

export type SubscriptionMarginalV1LbPositionArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars["ID"]["input"]
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionMarginalV1LbPositionsArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars["Int"]["input"]>
  orderBy?: InputMaybe<MarginalV1LbPosition_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars["Int"]["input"]>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<MarginalV1LbPosition_Filter>
}

export type SubscriptionMultiRewardsFactoriesArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars["Int"]["input"]>
  orderBy?: InputMaybe<MultiRewardsFactory_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars["Int"]["input"]>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<MultiRewardsFactory_Filter>
}

export type SubscriptionMultiRewardsFactoryArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars["ID"]["input"]
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionOpenArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars["ID"]["input"]
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionOpensArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars["Int"]["input"]>
  orderBy?: InputMaybe<Open_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars["Int"]["input"]>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<Open_Filter>
}

export type SubscriptionPoolArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars["ID"]["input"]
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionPoolsArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars["Int"]["input"]>
  orderBy?: InputMaybe<Pool_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars["Int"]["input"]>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<Pool_Filter>
}

export type SubscriptionPositionArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars["ID"]["input"]
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionPositionsArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars["Int"]["input"]>
  orderBy?: InputMaybe<Position_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars["Int"]["input"]>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<Position_Filter>
}

export type SubscriptionReceiverDeployedArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars["ID"]["input"]
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionReceiverDeployedsArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars["Int"]["input"]>
  orderBy?: InputMaybe<ReceiverDeployed_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars["Int"]["input"]>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<ReceiverDeployed_Filter>
}

export type SubscriptionSettleArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars["ID"]["input"]
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionSettlesArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars["Int"]["input"]>
  orderBy?: InputMaybe<Settle_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars["Int"]["input"]>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<Settle_Filter>
}

export type SubscriptionStakePoolArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars["ID"]["input"]
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionStakePoolsArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars["Int"]["input"]>
  orderBy?: InputMaybe<StakePool_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars["Int"]["input"]>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<StakePool_Filter>
}

export type SubscriptionTokenArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars["ID"]["input"]
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionTokenPositionMappingArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars["ID"]["input"]
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionTokenPositionMappingsArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars["Int"]["input"]>
  orderBy?: InputMaybe<TokenPositionMapping_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars["Int"]["input"]>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<TokenPositionMapping_Filter>
}

export type SubscriptionTokensArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars["Int"]["input"]>
  orderBy?: InputMaybe<Token_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars["Int"]["input"]>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<Token_Filter>
}

export type SubscriptionTransactionArgs = {
  block?: InputMaybe<Block_Height>
  id: Scalars["ID"]["input"]
  subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionTransactionsArgs = {
  block?: InputMaybe<Block_Height>
  first?: InputMaybe<Scalars["Int"]["input"]>
  orderBy?: InputMaybe<Transaction_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars["Int"]["input"]>
  subgraphError?: _SubgraphErrorPolicy_
  where?: InputMaybe<Transaction_Filter>
}

export type Token = {
  __typename?: "Token"
  address: Scalars["ID"]["output"]
  chainId: Scalars["Int"]["output"]
  decimals: Scalars["BigInt"]["output"]
  id: Scalars["ID"]["output"]
  name: Scalars["String"]["output"]
  symbol: Scalars["String"]["output"]
}

export type TokenPositionMapping = {
  __typename?: "TokenPositionMapping"
  id: Scalars["ID"]["output"]
  poolAddress: Scalars["ID"]["output"]
  positionId: Scalars["String"]["output"]
  tokenId: Scalars["String"]["output"]
}

export type TokenPositionMapping_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>
  and?: InputMaybe<Array<InputMaybe<TokenPositionMapping_Filter>>>
  id?: InputMaybe<Scalars["ID"]["input"]>
  id_gt?: InputMaybe<Scalars["ID"]["input"]>
  id_gte?: InputMaybe<Scalars["ID"]["input"]>
  id_in?: InputMaybe<Array<Scalars["ID"]["input"]>>
  id_lt?: InputMaybe<Scalars["ID"]["input"]>
  id_lte?: InputMaybe<Scalars["ID"]["input"]>
  id_not?: InputMaybe<Scalars["ID"]["input"]>
  id_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>
  or?: InputMaybe<Array<InputMaybe<TokenPositionMapping_Filter>>>
  poolAddress?: InputMaybe<Scalars["ID"]["input"]>
  poolAddress_gt?: InputMaybe<Scalars["ID"]["input"]>
  poolAddress_gte?: InputMaybe<Scalars["ID"]["input"]>
  poolAddress_in?: InputMaybe<Array<Scalars["ID"]["input"]>>
  poolAddress_lt?: InputMaybe<Scalars["ID"]["input"]>
  poolAddress_lte?: InputMaybe<Scalars["ID"]["input"]>
  poolAddress_not?: InputMaybe<Scalars["ID"]["input"]>
  poolAddress_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>
  positionId?: InputMaybe<Scalars["String"]["input"]>
  positionId_contains?: InputMaybe<Scalars["String"]["input"]>
  positionId_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  positionId_ends_with?: InputMaybe<Scalars["String"]["input"]>
  positionId_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  positionId_gt?: InputMaybe<Scalars["String"]["input"]>
  positionId_gte?: InputMaybe<Scalars["String"]["input"]>
  positionId_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  positionId_lt?: InputMaybe<Scalars["String"]["input"]>
  positionId_lte?: InputMaybe<Scalars["String"]["input"]>
  positionId_not?: InputMaybe<Scalars["String"]["input"]>
  positionId_not_contains?: InputMaybe<Scalars["String"]["input"]>
  positionId_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  positionId_not_ends_with?: InputMaybe<Scalars["String"]["input"]>
  positionId_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  positionId_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  positionId_not_starts_with?: InputMaybe<Scalars["String"]["input"]>
  positionId_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  positionId_starts_with?: InputMaybe<Scalars["String"]["input"]>
  positionId_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  tokenId?: InputMaybe<Scalars["String"]["input"]>
  tokenId_contains?: InputMaybe<Scalars["String"]["input"]>
  tokenId_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  tokenId_ends_with?: InputMaybe<Scalars["String"]["input"]>
  tokenId_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  tokenId_gt?: InputMaybe<Scalars["String"]["input"]>
  tokenId_gte?: InputMaybe<Scalars["String"]["input"]>
  tokenId_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  tokenId_lt?: InputMaybe<Scalars["String"]["input"]>
  tokenId_lte?: InputMaybe<Scalars["String"]["input"]>
  tokenId_not?: InputMaybe<Scalars["String"]["input"]>
  tokenId_not_contains?: InputMaybe<Scalars["String"]["input"]>
  tokenId_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  tokenId_not_ends_with?: InputMaybe<Scalars["String"]["input"]>
  tokenId_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  tokenId_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  tokenId_not_starts_with?: InputMaybe<Scalars["String"]["input"]>
  tokenId_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  tokenId_starts_with?: InputMaybe<Scalars["String"]["input"]>
  tokenId_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
}

export enum TokenPositionMapping_OrderBy {
  Id = "id",
  PoolAddress = "poolAddress",
  PositionId = "positionId",
  TokenId = "tokenId",
}

export type Token_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>
  address?: InputMaybe<Scalars["ID"]["input"]>
  address_gt?: InputMaybe<Scalars["ID"]["input"]>
  address_gte?: InputMaybe<Scalars["ID"]["input"]>
  address_in?: InputMaybe<Array<Scalars["ID"]["input"]>>
  address_lt?: InputMaybe<Scalars["ID"]["input"]>
  address_lte?: InputMaybe<Scalars["ID"]["input"]>
  address_not?: InputMaybe<Scalars["ID"]["input"]>
  address_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>
  and?: InputMaybe<Array<InputMaybe<Token_Filter>>>
  chainId?: InputMaybe<Scalars["Int"]["input"]>
  chainId_gt?: InputMaybe<Scalars["Int"]["input"]>
  chainId_gte?: InputMaybe<Scalars["Int"]["input"]>
  chainId_in?: InputMaybe<Array<Scalars["Int"]["input"]>>
  chainId_lt?: InputMaybe<Scalars["Int"]["input"]>
  chainId_lte?: InputMaybe<Scalars["Int"]["input"]>
  chainId_not?: InputMaybe<Scalars["Int"]["input"]>
  chainId_not_in?: InputMaybe<Array<Scalars["Int"]["input"]>>
  decimals?: InputMaybe<Scalars["BigInt"]["input"]>
  decimals_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  decimals_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  decimals_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  decimals_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  decimals_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  decimals_not?: InputMaybe<Scalars["BigInt"]["input"]>
  decimals_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  id?: InputMaybe<Scalars["ID"]["input"]>
  id_gt?: InputMaybe<Scalars["ID"]["input"]>
  id_gte?: InputMaybe<Scalars["ID"]["input"]>
  id_in?: InputMaybe<Array<Scalars["ID"]["input"]>>
  id_lt?: InputMaybe<Scalars["ID"]["input"]>
  id_lte?: InputMaybe<Scalars["ID"]["input"]>
  id_not?: InputMaybe<Scalars["ID"]["input"]>
  id_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>
  name?: InputMaybe<Scalars["String"]["input"]>
  name_contains?: InputMaybe<Scalars["String"]["input"]>
  name_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  name_ends_with?: InputMaybe<Scalars["String"]["input"]>
  name_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  name_gt?: InputMaybe<Scalars["String"]["input"]>
  name_gte?: InputMaybe<Scalars["String"]["input"]>
  name_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  name_lt?: InputMaybe<Scalars["String"]["input"]>
  name_lte?: InputMaybe<Scalars["String"]["input"]>
  name_not?: InputMaybe<Scalars["String"]["input"]>
  name_not_contains?: InputMaybe<Scalars["String"]["input"]>
  name_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  name_not_ends_with?: InputMaybe<Scalars["String"]["input"]>
  name_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  name_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  name_not_starts_with?: InputMaybe<Scalars["String"]["input"]>
  name_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  name_starts_with?: InputMaybe<Scalars["String"]["input"]>
  name_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  or?: InputMaybe<Array<InputMaybe<Token_Filter>>>
  symbol?: InputMaybe<Scalars["String"]["input"]>
  symbol_contains?: InputMaybe<Scalars["String"]["input"]>
  symbol_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  symbol_ends_with?: InputMaybe<Scalars["String"]["input"]>
  symbol_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  symbol_gt?: InputMaybe<Scalars["String"]["input"]>
  symbol_gte?: InputMaybe<Scalars["String"]["input"]>
  symbol_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  symbol_lt?: InputMaybe<Scalars["String"]["input"]>
  symbol_lte?: InputMaybe<Scalars["String"]["input"]>
  symbol_not?: InputMaybe<Scalars["String"]["input"]>
  symbol_not_contains?: InputMaybe<Scalars["String"]["input"]>
  symbol_not_contains_nocase?: InputMaybe<Scalars["String"]["input"]>
  symbol_not_ends_with?: InputMaybe<Scalars["String"]["input"]>
  symbol_not_ends_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  symbol_not_in?: InputMaybe<Array<Scalars["String"]["input"]>>
  symbol_not_starts_with?: InputMaybe<Scalars["String"]["input"]>
  symbol_not_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
  symbol_starts_with?: InputMaybe<Scalars["String"]["input"]>
  symbol_starts_with_nocase?: InputMaybe<Scalars["String"]["input"]>
}

export enum Token_OrderBy {
  Address = "address",
  ChainId = "chainId",
  Decimals = "decimals",
  Id = "id",
  Name = "name",
  Symbol = "symbol",
}

export type Transaction = {
  __typename?: "Transaction"
  adjust: Array<Adjust>
  blockNumber: Scalars["BigInt"]["output"]
  gasLimit: Scalars["BigInt"]["output"]
  gasPrice: Scalars["BigInt"]["output"]
  id: Scalars["ID"]["output"]
  liquidate: Array<Liquidate>
  open: Array<Open>
  settle: Array<Settle>
  timestamp: Scalars["BigInt"]["output"]
}

export type TransactionAdjustArgs = {
  first?: InputMaybe<Scalars["Int"]["input"]>
  orderBy?: InputMaybe<Adjust_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars["Int"]["input"]>
  where?: InputMaybe<Adjust_Filter>
}

export type TransactionLiquidateArgs = {
  first?: InputMaybe<Scalars["Int"]["input"]>
  orderBy?: InputMaybe<Liquidate_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars["Int"]["input"]>
  where?: InputMaybe<Liquidate_Filter>
}

export type TransactionOpenArgs = {
  first?: InputMaybe<Scalars["Int"]["input"]>
  orderBy?: InputMaybe<Open_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars["Int"]["input"]>
  where?: InputMaybe<Open_Filter>
}

export type TransactionSettleArgs = {
  first?: InputMaybe<Scalars["Int"]["input"]>
  orderBy?: InputMaybe<Settle_OrderBy>
  orderDirection?: InputMaybe<OrderDirection>
  skip?: InputMaybe<Scalars["Int"]["input"]>
  where?: InputMaybe<Settle_Filter>
}

export type Transaction_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>
  adjust_?: InputMaybe<Adjust_Filter>
  and?: InputMaybe<Array<InputMaybe<Transaction_Filter>>>
  blockNumber?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  blockNumber_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_not?: InputMaybe<Scalars["BigInt"]["input"]>
  blockNumber_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  gasLimit?: InputMaybe<Scalars["BigInt"]["input"]>
  gasLimit_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  gasLimit_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  gasLimit_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  gasLimit_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  gasLimit_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  gasLimit_not?: InputMaybe<Scalars["BigInt"]["input"]>
  gasLimit_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  gasPrice?: InputMaybe<Scalars["BigInt"]["input"]>
  gasPrice_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  gasPrice_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  gasPrice_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  gasPrice_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  gasPrice_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  gasPrice_not?: InputMaybe<Scalars["BigInt"]["input"]>
  gasPrice_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  id?: InputMaybe<Scalars["ID"]["input"]>
  id_gt?: InputMaybe<Scalars["ID"]["input"]>
  id_gte?: InputMaybe<Scalars["ID"]["input"]>
  id_in?: InputMaybe<Array<Scalars["ID"]["input"]>>
  id_lt?: InputMaybe<Scalars["ID"]["input"]>
  id_lte?: InputMaybe<Scalars["ID"]["input"]>
  id_not?: InputMaybe<Scalars["ID"]["input"]>
  id_not_in?: InputMaybe<Array<Scalars["ID"]["input"]>>
  liquidate_?: InputMaybe<Liquidate_Filter>
  open_?: InputMaybe<Open_Filter>
  or?: InputMaybe<Array<InputMaybe<Transaction_Filter>>>
  settle_?: InputMaybe<Settle_Filter>
  timestamp?: InputMaybe<Scalars["BigInt"]["input"]>
  timestamp_gt?: InputMaybe<Scalars["BigInt"]["input"]>
  timestamp_gte?: InputMaybe<Scalars["BigInt"]["input"]>
  timestamp_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  timestamp_lt?: InputMaybe<Scalars["BigInt"]["input"]>
  timestamp_lte?: InputMaybe<Scalars["BigInt"]["input"]>
  timestamp_not?: InputMaybe<Scalars["BigInt"]["input"]>
  timestamp_not_in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
}

export enum Transaction_OrderBy {
  Adjust = "adjust",
  BlockNumber = "blockNumber",
  GasLimit = "gasLimit",
  GasPrice = "gasPrice",
  Id = "id",
  Liquidate = "liquidate",
  Open = "open",
  Settle = "settle",
  Timestamp = "timestamp",
}

export type _Block_ = {
  __typename?: "_Block_"
  /** The hash of the block */
  hash?: Maybe<Scalars["Bytes"]["output"]>
  /** The block number */
  number: Scalars["Int"]["output"]
  /** The hash of the parent block */
  parentHash?: Maybe<Scalars["Bytes"]["output"]>
  /** Integer representation of the timestamp stored in blocks for the chain */
  timestamp?: Maybe<Scalars["Int"]["output"]>
}

/** The type for the top-level _meta field */
export type _Meta_ = {
  __typename?: "_Meta_"
  /**
   * Information about a specific subgraph block. The hash of the block
   * will be null if the _meta field has a block constraint that asks for
   * a block number. It will be filled if the _meta field has no block constraint
   * and therefore asks for the latest  block
   *
   */
  block: _Block_
  /** The deployment ID */
  deployment: Scalars["String"]["output"]
  /** If `true`, the subgraph encountered indexing errors at some past block */
  hasIndexingErrors: Scalars["Boolean"]["output"]
}

export enum _SubgraphErrorPolicy_ {
  /** Data will be returned even if the subgraph has indexing errors */
  Allow = "allow",
  /** If the subgraph has indexing errors, data will be omitted. The default. */
  Deny = "deny",
}

export type GetPoolQueryVariables = Exact<{
  address: Scalars["ID"]["input"]
}>

export type GetPoolQuery = {
  __typename?: "Query"
  marginalV1LBPool?: {
    __typename?: "MarginalV1LBPool"
    launchTokenIndex: number
    blockTimestamp: any
    blockTimestampInitialize: any
    createdAtBlockNumber: any
    createdAtTimestamp: any
    decimals: any
    feeProtocol: number
    finalized: boolean
    id: string
    liquidity: any
    sqrtPriceFinalizeX96?: any | null
    sqrtPriceInitializeX96?: any | null
    sqrtPriceLowerX96: any
    sqrtPriceUpperX96: any
    sqrtPriceX96: any
    supplier: string
    tick: number
    tickCumulative: any
    tickLower: number
    tickUpper: number
    totalPositions: any
    txCount: any
    tokenPair: string
    factory: { __typename?: "MarginalV1LBFactory"; id: string }
    mint?: { __typename?: "MarginalV1LBPoolMint"; amount0: any; amount1: any } | null
    token0: {
      __typename?: "Token"
      address: string
      decimals: any
      id: string
      name: string
      symbol: string
      chainId: number
    }
    token1: {
      __typename?: "Token"
      address: string
      decimals: any
      id: string
      name: string
      symbol: string
      chainId: number
    }
    positions: Array<{ __typename?: "MarginalV1LBPosition"; shares: any; owner: string }>
    swaps: Array<{
      __typename?: "MarginalV1LBPoolSwap"
      amount0: any
      amount1: any
      transactionHash: any
      tick: number
      sqrtPriceX96: any
      sender: any
      recipient: any
      liquidity: any
      finalized: boolean
      blockTimestamp: any
    }>
  } | null
}

export type GetPoolsQueryVariables = Exact<{ [key: string]: never }>

export type GetPoolsQuery = {
  __typename?: "Query"
  marginalV1LBPools: Array<{
    __typename?: "MarginalV1LBPool"
    launchTokenIndex: number
    blockTimestamp: any
    blockTimestampInitialize: any
    createdAtBlockNumber: any
    createdAtTimestamp: any
    decimals: any
    feeProtocol: number
    finalized: boolean
    id: string
    liquidity: any
    sqrtPriceFinalizeX96?: any | null
    sqrtPriceInitializeX96?: any | null
    sqrtPriceLowerX96: any
    sqrtPriceUpperX96: any
    sqrtPriceX96: any
    supplier: string
    tick: number
    tickCumulative: any
    tickLower: number
    tickUpper: number
    totalPositions: any
    txCount: any
    tokenPair: string
    factory: { __typename?: "MarginalV1LBFactory"; id: string }
    token0: {
      __typename?: "Token"
      address: string
      decimals: any
      id: string
      name: string
      symbol: string
      chainId: number
    }
    token1: {
      __typename?: "Token"
      address: string
      decimals: any
      id: string
      name: string
      symbol: string
      chainId: number
    }
  }>
}

export const GetPoolDocument = `
    query GetPool($address: ID!) {
  marginalV1LBPool(id: $address) {
    launchTokenIndex
    blockTimestamp
    blockTimestampInitialize
    createdAtBlockNumber
    createdAtTimestamp
    decimals
    feeProtocol
    finalized
    id
    liquidity
    factory {
      id
    }
    mint {
      amount0
      amount1
    }
    sqrtPriceFinalizeX96
    sqrtPriceInitializeX96
    sqrtPriceLowerX96
    sqrtPriceUpperX96
    sqrtPriceX96
    supplier
    tick
    tickCumulative
    tickLower
    tickUpper
    token0 {
      address
      decimals
      id
      name
      symbol
      chainId
    }
    token1 {
      address
      decimals
      id
      name
      symbol
      chainId
    }
    positions {
      shares
      owner
    }
    totalPositions
    txCount
    tokenPair
    swaps(orderBy: blockTimestamp) {
      amount0
      amount1
      transactionHash
      tick
      sqrtPriceX96
      sender
      recipient
      liquidity
      finalized
      blockTimestamp
    }
  }
}
    `
export const GetPoolsDocument = `
    query GetPools {
  marginalV1LBPools {
    launchTokenIndex
    blockTimestamp
    blockTimestampInitialize
    createdAtBlockNumber
    createdAtTimestamp
    decimals
    feeProtocol
    finalized
    id
    liquidity
    factory {
      id
    }
    sqrtPriceFinalizeX96
    sqrtPriceInitializeX96
    sqrtPriceLowerX96
    sqrtPriceUpperX96
    sqrtPriceX96
    supplier
    tick
    tickCumulative
    tickLower
    tickUpper
    token0 {
      address
      decimals
      id
      name
      symbol
      chainId
    }
    token1 {
      address
      decimals
      id
      name
      symbol
      chainId
    }
    totalPositions
    txCount
    tokenPair
  }
}
    `

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetPool: build.query<GetPoolQuery, GetPoolQueryVariables>({
      query: (variables) => ({ document: GetPoolDocument, variables }),
    }),
    GetPools: build.query<GetPoolsQuery, GetPoolsQueryVariables | void>({
      query: (variables) => ({ document: GetPoolsDocument, variables }),
    }),
  }),
})

export { injectedRtkApi as api }
export const {
  useGetPoolQuery,
  useLazyGetPoolQuery,
  useGetPoolsQuery,
  useLazyGetPoolsQuery,
} = injectedRtkApi
