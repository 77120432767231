import { simulateContract, waitForTransactionReceipt, writeContract } from "@wagmi/core"
import { wagmiConfig } from "src/wagmiConfig"
import { V1_ROUTER_ADDRESS } from "src/constants/addresses"
import marginalV1RouterAbi from "src/constants/abis/MarginalV1Router.json"
import { parseEther } from "viem"
import { AddLiquidityParams } from "src/types"

export const addLiquidity = async (
  addLiquidityParams: AddLiquidityParams,
  ethValue: string | undefined,
  chainId: number,
) => {
  const { request } = await simulateContract(wagmiConfig, {
    chainId,
    address: V1_ROUTER_ADDRESS[chainId],
    abi: marginalV1RouterAbi,
    functionName: "addLiquidity",
    args: [addLiquidityParams],
    value: ethValue ? parseEther(ethValue) : undefined,
  })

  const hash = await writeContract(wagmiConfig, request)

  const receipt = await waitForTransactionReceipt(wagmiConfig, { hash })

  return receipt
}
