import { useMemo } from "react"
import { Token } from "src/types"
import { TOKENS_BY_CHAIN } from "src/constants/tokenList"

/**
 * Hook to get a merged and prioritized list of tokens for a specific chain
 * Prioritizes tokens from our predefined tokenList over tokens from the subgraph
 */
export function useTokenList(
  chainId: number | undefined,
  tokensFromSubgraph: Token[] = [],
): Token[] {
  return useMemo(() => {
    if (!chainId) return []

    // Get our predefined tokens for this chain
    const predefinedTokens = TOKENS_BY_CHAIN[chainId] || []

    // Create a Set of addresses we already have from predefined tokens
    const predefinedAddresses = new Set(
      predefinedTokens.map((token) => token.address.toLowerCase()),
    )

    // Filter out subgraph tokens that we already have in predefined list
    const uniqueSubgraphTokens = tokensFromSubgraph.filter(
      (token) => !predefinedAddresses.has(token.address.toLowerCase()),
    )

    // Combine predefined tokens (which are already sorted by priority) with unique subgraph tokens
    return [...predefinedTokens, ...uniqueSubgraphTokens]
  }, [chainId, tokensFromSubgraph])
}
