import { InputContainer } from "src/components/ui/InputContainer"
import { AddLiquidityInput } from "src/pages/addLiquidity/components/AddLiquidityInput"
import {
  useCreatePoolActionHandlers,
  useCreatePoolState,
} from "src/state/createPool/hooks"
import { useAccount } from "wagmi"
import { formatBigIntToString } from "src/utils/formatBigIntToString"
import { formatNumberAmount } from "src/utils/formatNumberAmount"

interface InputAmountsProps {
  token0Balance: bigint | undefined
  token1Balance: bigint | undefined
}

const InputAmounts = ({ token0Balance, token1Balance }: InputAmountsProps) => {
  const { address } = useAccount()
  const { token0, token1, amount0, amount1 } = useCreatePoolState()
  const { onSelectAmount0, onSelectAmount1 } = useCreatePoolActionHandlers()

  const token0BalanceParsed = formatBigIntToString(token0Balance, token0?.decimals)
  const token1BalanceParsed = formatBigIntToString(token1Balance, token1?.decimals)

  return (
    <div className="flex flex-col gap-4">
      <InputContainer id="create-pool-input-0">
        <AddLiquidityInput
          inputValue={amount0}
          onChange={onSelectAmount0}
          disabled={!token0}
        />
        {token0 && token0BalanceParsed && (
          <div className="flex flex-col items-end">
            <div
              className="cursor-pointer rounded-sm bg-[#4C2D1E] px-1 text-marginalOrange-500"
              onClick={() => onSelectAmount0(token0BalanceParsed)}
            >
              Max
            </div>
            {address && (
              <div className="whitespace-nowrap text-xs text-marginalGray-600">
                <span>balance:</span>
                <span> {formatNumberAmount(token0BalanceParsed, true)} </span>
              </div>
            )}
          </div>
        )}
      </InputContainer>
      <InputContainer id="create-pool-input-1">
        <AddLiquidityInput
          inputValue={amount1}
          onChange={onSelectAmount1}
          disabled={!token1}
        />
        {token1 && token1BalanceParsed && (
          <div className="flex flex-col items-end">
            <div
              className="cursor-pointer rounded-sm bg-[#4C2D1E] px-1 text-marginalOrange-500"
              onClick={() => onSelectAmount1(token1BalanceParsed)}
            >
              Max
            </div>
            {address && (
              <div className="whitespace-nowrap text-xs text-marginalGray-600">
                <span>balance:</span>
                <span> {formatNumberAmount(token1BalanceParsed, true)} </span>
              </div>
            )}
          </div>
        )}
      </InputContainer>
    </div>
  )
}

export default InputAmounts
