import _ from "lodash"
import { useCallback, useMemo } from "react"
import { AppState } from "src/state"
import { useAppDispatch, useAppSelector } from "src/state/hooks"
import {
  setInputValue,
  setInputToken,
  setOutputToken,
  resetSwapState,
  setSwapToken,
} from "./reducer"
import { Token } from "src/types"
import { isBlank } from "src/utils/isBlank"
import { isOnlyZeroes } from "src/utils/isOnlyZeroes"
import { createAsyncThunk } from "@reduxjs/toolkit"
import { Address } from "viem"
import { getExactInputSingleQuote } from "src/pages/swap/helpers/getExactInputSingleQuote"
import { ExactInputSingleParams } from "src/types"

export const useSwapState = (): AppState["swap"] => {
  return useAppSelector((state) => state.swap)
}

export function useSwapActionHandlers(): {
  onUserInput: (value: string) => void
  onSelectSwapToken: (token: Token | null) => void
  onSelectInputToken: (token: Token | null) => void
  onSelectOutputToken: (token: Token | null) => void
  onResetSwapState: () => void
} {
  const dispatch = useAppDispatch()

  const onUserInput = useCallback(
    (value: string) => {
      dispatch(setInputValue(value))
    },
    [dispatch],
  )

  const onSelectSwapToken = useCallback(
    (token: Token | null) => {
      dispatch(setSwapToken(token))
    },
    [dispatch],
  )

  const onSelectInputToken = useCallback(
    (token: Token | null) => {
      dispatch(setInputToken(token))
    },
    [dispatch],
  )

  const onSelectOutputToken = useCallback(
    (token: Token | null) => {
      dispatch(setOutputToken(token))
    },
    [dispatch],
  )

  const onResetSwapState = useCallback(() => {
    dispatch(resetSwapState())
  }, [dispatch])

  return {
    onUserInput,
    onSelectSwapToken,
    onSelectInputToken,
    onSelectOutputToken,
    onResetSwapState,
  }
}

export const fetchExactInputSingleQuote = createAsyncThunk(
  "swap/fetchExactInputSingleQuote",
  async ({
    chainId,
    quoterAddress,
    params,
  }: {
    chainId: number
    quoterAddress: Address
    params: ExactInputSingleParams
  }) => {
    const quote = await getExactInputSingleQuote(chainId, quoterAddress, params)
    if (!quote) {
      throw new Error("Failed to fetch swap quote")
    }
    return quote
  },
)

export function useSwapStatus() {
  const { inputValue, inputToken, outputToken } = useSwapState()

  const isInputValid = useMemo(() => {
    return _.isString(inputValue) && !isBlank(inputValue) && !isOnlyZeroes(inputValue)
  }, [inputValue])

  const isInputTokenValid = useMemo(() => {
    return !_.isNull(inputToken)
  }, [inputToken])

  const isOutputTokenValid = useMemo(() => {
    return !_.isNull(outputToken)
  }, [outputToken])

  const isSwapInputsValid = useMemo(() => {
    return isInputValid && isInputTokenValid && isOutputTokenValid
  }, [isInputValid, isInputTokenValid, isOutputTokenValid])

  return {
    isInputValid,
    isInputTokenValid,
    isOutputTokenValid,
    isSwapInputsValid,
  }
}
