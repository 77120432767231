import { BrandLogo } from "src/components/Header/BrandLogo"
import { MobileMenu } from "src/components/Header/MobileMenu"

export const MobileHeader = () => {
  return (
    <div className="z-50 flex h-20 w-full items-center justify-between bg-marginalGray-950 p-4 md:hidden">
      <BrandLogo />
      <MobileMenu />
    </div>
  )
}
