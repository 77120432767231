import { isUndefined } from "lodash"

interface BalanceCheckParams {
  userBalance: bigint | undefined
  inputAmount: bigint | undefined
}

/**
 * Checks if a user has sufficient balance for a given input amount
 * @param params Object containing balance and input amount
 * @returns true if balance is sufficient, false otherwise
 */
const checkBalance = ({ userBalance, inputAmount }: BalanceCheckParams): boolean => {
  if (isUndefined(userBalance)) return false
  if (isUndefined(inputAmount)) return true
  return userBalance >= inputAmount
}

/**
 * Determines if user has sufficient balance for a trade
 * @param displayGasToken Whether gas token is being used
 * @param gasBalance User's gas token balance
 * @param tokenBalance User's token balance
 * @param formattedInput Formatted input amount as bigint
 * @returns boolean indicating if balance is sufficient
 */
export const checkBalanceSufficient = (
  displayGasToken: boolean,
  gasBalance: bigint | undefined,
  tokenBalance: bigint | undefined,
  formattedInput: bigint | undefined,
): boolean => {
  const balance = displayGasToken ? gasBalance : tokenBalance
  return checkBalance({ userBalance: balance, inputAmount: formattedInput })
}
