import _ from "lodash"
import { useEffect } from "react"
import { Address, maxUint256 } from "viem"
import { useAccount } from "wagmi"
import { waitForTransactionReceipt } from "@wagmi/core"
import { CaretLeftIcon } from "src/components/Icons/CaretLeftIcon"
import { Card } from "src/components/Card"
import { TransactionStatusModal } from "src/components/TransactionStatusModal"
import DeployPoolButton from "src/pages/createPool/components/DeployPoolButton"
import { ExecuteInitiateOracleButton } from "src/pages/createPool/components/ExecuteInitiateOracleButton"
import Collapsible from "src/pages/createPool/components/Collapsible"
import InputAmounts from "src/pages/createPool/components/InputAmounts"
import MaxLeverage from "src/pages/createPool/components/MaxLeverage"
import SelectOracle from "src/pages/createPool/components/SelectOracle"
import SelectTokens from "src/pages/createPool/components/SelectToken"
import SlippageConfig from "src/pages/createPool/components/SlippageConfig"
import { useNavigateRoutes } from "src/hooks/useNavigateRoutes"
import { useCreatePoolValidations } from "src/pages/createPool/hooks/useCreatePoolValidations"
import { useErc20TokenBalances } from "src/hooks/useErc20TokenBalances"
import { useErc20TokenAllowance } from "src/hooks/useErc20TokenAllowance"
import { useTransactionModal } from "src/pages/trade/hooks/useTransactionModal"
import {
  useCreatePoolState,
  useCreatePoolActionHandlers,
} from "src/state/createPool/hooks"
import { useSettingsState } from "src/state/settings/hooks"
import { useApplicationState } from "src/state/application/hooks"
import { useGetCurrentBlockTimestamp } from "src/hooks/useGetCurrentBlockTimestamp"
import { approveErc20Token } from "src/utils/actions/approveErc20Token"
import { createAndInitializePoolIfNecessary } from "src/utils/actions/createAndInitializePoolIfNecessary"
import { validateCreatePoolParams } from "src/pages/createPool/helpers/validateCreatePoolParams"
import { constructCreatePoolParams } from "src/pages/createPool/helpers/constructCreatePoolParams"
import { wagmiConfig } from "src/wagmiConfig"
import { V1_POOL_INITIALIZER } from "src/constants/addresses"

const CreateNewPool = () => {
  const { chainId } = useApplicationState()
  const { address } = useAccount()
  const { amount0, amount1, token0, token1, leverage, uniswapPool } = useCreatePoolState()
  const { onResetCreatePoolState } = useCreatePoolActionHandlers()
  const { maxSlippage, transactionDeadline } = useSettingsState()
  const { onNavigateToPools } = useNavigateRoutes()
  const { isOracleInitRequired, isOracleSelectionValid } = useCreatePoolValidations()
  const currentBlockTimestamp = useGetCurrentBlockTimestamp(chainId)

  const { balances: tokenBalances } = useErc20TokenBalances([token0, token1], address)
  const { balance: token0Balance } = _.find(tokenBalances, { token: token0 }) || {}
  const { balance: token1Balance } = _.find(tokenBalances, { token: token1 }) || {}

  const {
    state: {
      showConfirm,
      isPendingWallet,
      isPendingApprove,
      isPendingTx,
      isTxSubmitted,
      txHash,
      txError,
    },
    openConfirmModal,
    closeConfirmModal,
    resetState: resetTransactionState,
    setPendingWallet,
    setPendingApprove,
    setTxSubmitted,
  } = useTransactionModal()

  const onSuccessReset = () => {
    resetTransactionState()
    onResetCreatePoolState()
  }

  const { allowance: token0Allowance, fetchAllowance: fetchToken0Allowance } =
    useErc20TokenAllowance(
      token0?.address as Address,
      Number(token0?.decimals),
      address,
      V1_POOL_INITIALIZER[chainId ?? 1],
      chainId,
    )

  const { allowance: token1Allowance, fetchAllowance: fetchToken1Allowance } =
    useErc20TokenAllowance(
      token1?.address as Address,
      Number(token1?.decimals),
      address,
      V1_POOL_INITIALIZER[chainId ?? 1],
      chainId,
    )

  useEffect(() => {
    if (token0) {
      fetchToken0Allowance()
    }
  }, [token0, fetchToken0Allowance])

  useEffect(() => {
    if (token1) {
      fetchToken1Allowance()
    }
  }, [token1, fetchToken1Allowance])

  const onApprove = async (tokenAddress: Address, amount: bigint) => {
    if (!chainId) {
      throw new Error("Require chainId to approve")
    }
    try {
      setPendingApprove(true)
      const txHash = await approveErc20Token({
        chainId,
        amount: maxUint256,
        spenderAddress: V1_POOL_INITIALIZER[chainId],
        tokenAddress,
      })
      await waitForTransactionReceipt(wagmiConfig, {
        hash: txHash,
      })
      setPendingApprove(false)
      setTxSubmitted(true, txHash)
      // Refresh allowances
      await Promise.all([fetchToken0Allowance(), fetchToken1Allowance()])
    } catch (error) {
      console.error("Error approving token: ", error)
      setPendingApprove(false)
    }
  }

  const createPoolParams = validateCreatePoolParams(
    token0,
    token1,
    amount0,
    amount1,
    uniswapPool,
    leverage,
    currentBlockTimestamp,
  )
    ? constructCreatePoolParams(
        token0!,
        token1!,
        amount0,
        amount1,
        uniswapPool!,
        leverage!,
        address as Address,
        transactionDeadline,
        currentBlockTimestamp as bigint,
        maxSlippage,
      )
    : null

  const executeCreatePool = async () => {
    try {
      if (!createPoolParams) {
        throw new Error("Invalid parameters")
      }
      if (!chainId || !address) {
        throw new Error("Chain or address not available")
      }
      openConfirmModal()
      setPendingWallet(true)
      const transaction = await createAndInitializePoolIfNecessary(
        createPoolParams,
        V1_POOL_INITIALIZER[chainId],
        chainId,
      )
      setPendingWallet(false)
      setTxSubmitted(true, transaction.transactionHash)
    } catch (error) {
      console.error("Error executing create pool: ", error)
      resetTransactionState()
    }
  }

  return (
    <Card>
      <div className="flex justify-between">
        <div
          onClick={onNavigateToPools}
          className="flex cursor-pointer items-center justify-start space-x-1 text-marginalGray-200"
        >
          <CaretLeftIcon />
          <span className="text-sm text-marginalGray-200">
            Back to Pools
          </span>
        </div>
      </div>
      <div className="relative mx-auto mt-12 w-full max-w-[400px] overflow-hidden rounded-3xl shadow-outerBlack sm:max-w-[500px]">
        <div className="rounded-3xl border border-marginalGray-800 bg-marginalGray-900 last:rounded-b-3xl">
          <div className="flex w-full items-center justify-between border-b border-marginalGray-800 pl-5 pr-5">
            <span className="py-4 text-xl text-[#CACACA]">
              New Pool
            </span>
            <SlippageConfig />
          </div>
          <div className="flex flex-col">
            <Collapsible title="Select Tokens">
              <SelectTokens />
            </Collapsible>

            <Collapsible title="Choose Oracle">
              <div className="flex flex-col gap-4">
                <div className="align-center flex gap-2 whitespace-nowrap text-marginalGray-600">
                  Select an Uniswap v3 Pool as Oracle.
                </div>
                <SelectOracle />
              </div>
            </Collapsible>

            {isOracleSelectionValid && (
              <Collapsible title="Initialize Oracle">
                <div className="flex flex-col gap-4">
                  {!isOracleInitRequired ? (
                    <>
                      <div className="align-center flex gap-2 text-marginalGray-600">
                        After initializing the Oracle, a 12-hour waiting period is
                        required before the new pool can be deployed.
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="align-center flex gap-2 text-marginalGray-600">
                        Initialize the Oracle for the selected pool. This might take up to
                        12hs to process until the pool is finally ready to be deployed.
                      </div>
                    </>
                  )}
                  <ExecuteInitiateOracleButton />
                </div>
              </Collapsible>
            )}

            {!isOracleInitRequired && isOracleSelectionValid && (
              <>
                <Collapsible title="Deposit Amount">
                  <InputAmounts
                    token0Balance={token0Balance}
                    token1Balance={token1Balance}
                  />
                </Collapsible>

                <Collapsible title="Choose Max Leverage">
                  <div className="flex flex-col gap-4">
                    <div className="whitespace-nowrap text-marginalGray-600">
                      Max Allowed Leverage
                    </div>
                    <MaxLeverage />
                  </div>
                </Collapsible>
                <div className="p-4">
                  <DeployPoolButton
                    token0Balance={token0Balance}
                    token1Balance={token1Balance}
                    token0Allowance={token0Allowance}
                    token1Allowance={token1Allowance}
                    isPendingApprove={isPendingApprove}
                    onApprove={onApprove}
                    executeCreatePool={executeCreatePool}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <TransactionStatusModal
        chainId={chainId}
        open={showConfirm}
        onOpen={openConfirmModal}
        onClose={closeConfirmModal}
        onReset={onSuccessReset}
        onCallback={executeCreatePool}
        isPendingWallet={isPendingWallet}
        isPendingApprove={isPendingApprove}
        isPendingTx={isPendingTx}
        isTxSubmitted={isTxSubmitted}
        txHash={txHash}
        txError={txError}
        hasConfirmModal={false}
        onSuccessText="Pool Created!"
      />
    </Card>
  )
}

export default CreateNewPool
