import { useReadContract } from "wagmi"
import { Address } from "viem"
import { isListDefined } from "src/utils/isListDefined"
import marginalV1OracleAbi from "src/constants/abis/MarginalV1Oracle.json"
import { V1_ORACLE_ADDRESS } from "src/constants/addresses"

interface Props {
  chainId: number
  token0: Address
  token1: Address
  maintenance: bigint | undefined
  oracle: Address | undefined
}

interface OraclePrices {
  sqrtPriceX96: bigint
  oracleSqrtPriceX96: bigint
  fundingRatioX96: bigint
  isLoading: boolean
}

export const useMarginalOraclePrices = ({
  chainId,
  token0,
  token1,
  maintenance,
  oracle,
}: Props): OraclePrices => {
  const isValidProps = isListDefined([chainId, token0, token1, maintenance, oracle])

  const { data, isLoading } = useReadContract({
    address: V1_ORACLE_ADDRESS[chainId],
    abi: marginalV1OracleAbi,
    functionName: "sqrtPricesX96",
    args: [{ token0, token1, maintenance, oracle }],
    query: {
      enabled: isValidProps,
    },
  })

  const prices: any[] = Array.isArray(data) ? data : []

  return {
    sqrtPriceX96: prices[0] as bigint,
    oracleSqrtPriceX96: prices[1] as bigint,
    fundingRatioX96: prices[2] as bigint,
    isLoading,
  }
}
