import { Token } from "src/types"
import { zeroAddress } from "viem"
import { ChainId } from "./chains/chains"
import WethLogo from "src/assets/logos/weth.svg"
import EthLogo from "src/assets/logos/eth.svg"

export const ETH = {
  chainId: 1,
  name: "Ether",
  address: zeroAddress,
  symbol: "ETH",
  decimals: 18,
  imgUrl: EthLogo,
  priorityRank: 99,
}

export const MAINNET_WETH = {
  chainId: 1,
  name: "Wrapped Ether",
  symbol: "WETH",
  address: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
  decimals: 18,
  imgUrl: WethLogo,
  priorityRank: 2,
}

export const BASE_WETH = {
  chainId: 8453,
  name: "Wrapped Ether",
  symbol: "WETH",
  address: "0x4200000000000000000000000000000000000006",
  decimals: 18,
  imgUrl: WethLogo,
  priorityRank: 2,
}

export const SEPOLIA_WETH = {
  chainId: 11155111,
  name: "Wrapped Ether",
  symbol: "WETH",
  address: "0xfFf9976782d46CC05630D1f6eBAb18b2324d6B14",
  decimals: 18,
  imgUrl: WethLogo,
  priorityRank: 2,
}

export const BERA = {
  chainId: 80084,
  address: zeroAddress,
  name: "Bera",
  symbol: "BERA",
  decimals: 18,
  imgUrl: "https://docs.berachain.com/assets/BERA.png",
  priorityRank: 99,
}

export const BERACHAIN_BARTIO_WBERA = {
  chainId: 80084,
  name: "Wrapped Bera",
  symbol: "WBERA",
  address: "0x7507c1dc16935B82698e4C63f2746A2fCf994dF8",
  decimals: 18,
  imgUrl: "https://docs.berachain.com/assets/BERA.png",
  priorityRank: 3,
}

export const WRAPPED_GAS_TOKEN_MAP: { [chainId: number]: Token } = {
  [ChainId.BASE]: BASE_WETH,
  [ChainId.SEPOLIA]: SEPOLIA_WETH,
  [ChainId.BERACHAIN_BARTIO]: BERACHAIN_BARTIO_WBERA,
  [ChainId.ETH]: MAINNET_WETH,
}

export const GAS_TOKEN_MAP: { [chainId: number]: Token } = {
  [ChainId.BASE]: ETH,
  [ChainId.SEPOLIA]: ETH,
  [ChainId.BERACHAIN_BARTIO]: BERA,
  [ChainId.ETH]: ETH,
}

export const MARGINAL_DAO_TOKEN = "0x43Bdd46b310a78C8f077c162D45dBe7e70F32217"
