import { IgniteQuoteParams } from "src/pages/positions/helpers/constructIgniteQuoteParams"
import { formatStringToBigInt } from "src/utils/formatStringToBigInt"
import { formatBigIntToString } from "src/utils/formatBigIntToString"

const HUNDRED_PERCENT = "100"
const DECIMALS = 18

const ONE = 1000000000000000000n
const ONE_MWEI = 1000000n

interface AmountOutParams {
  margin: bigint
  debt: bigint
  size: bigint
  zeroForOne: boolean
  poolPrice: bigint
  fee: bigint
}

interface SlippageCalculationParams {
  value: bigint
  slippagePercent: string
}

const calculateDebtInMarginToken = (
  debt: bigint,
  poolPrice: bigint,
  zeroForOne: boolean,
): bigint => {
  return zeroForOne ? (debt * poolPrice) / ONE : (debt * ONE) / poolPrice
}

const calculateAmountOutRaw = ({
  margin,
  debt,
  size,
  zeroForOne,
  poolPrice,
  fee,
}: AmountOutParams): bigint | undefined => {
  if (!margin || !debt || !size || !fee || !poolPrice) {
    return undefined
  }

  const totalSize = margin + size
  const debtInMarginToken = calculateDebtInMarginToken(debt, poolPrice, zeroForOne)

  const feeInMwei = BigInt(fee) + ONE_MWEI
  const debtWithFee = (debtInMarginToken * feeInMwei) / ONE_MWEI

  return totalSize - debtWithFee
}

const calculateSlippageAdjustedValue = ({
  value,
  slippagePercent,
}: SlippageCalculationParams): bigint | undefined => {
  const effectivePercent = (100 - parseFloat(slippagePercent)).toString()
  const slippageNumerator = formatStringToBigInt(effectivePercent, DECIMALS)
  const slippageDenominator = formatStringToBigInt(HUNDRED_PERCENT, DECIMALS)

  if (!slippageNumerator || !slippageDenominator) {
    return undefined
  }

  const effectivePercentage = (slippageNumerator * ONE) / slippageDenominator
  return (value * effectivePercentage) / ONE
}

const calculatePriceImpact = (
  quotedAmount: bigint,
  calculatedAmount: bigint,
): string | undefined => {
  const amountDiff = quotedAmount - calculatedAmount
  const impact = (amountDiff * ONE) / calculatedAmount
  return formatBigIntToString(impact, DECIMALS)
}

export const getIgniteParamsWithSlippage = (
  igniteParams: IgniteQuoteParams | null,
  maxSlippage: string,
  margin?: bigint,
  debt?: bigint,
  size?: bigint,
  zeroForOne?: boolean,
  poolPrice?: bigint | null,
  fee?: bigint,
  quotedAmountOut?: bigint,
) => {
  if (
    !igniteParams ||
    !poolPrice ||
    !margin ||
    !debt ||
    !size ||
    !fee ||
    !quotedAmountOut ||
    zeroForOne === undefined
  ) {
    return null
  }

  const amountOutRaw = calculateAmountOutRaw({
    margin,
    debt,
    size,
    zeroForOne,
    poolPrice,
    fee,
  })

  if (!amountOutRaw) {
    return null
  }

  const minReceivedCollateral = calculateSlippageAdjustedValue({
    value: amountOutRaw,
    slippagePercent: maxSlippage,
  })

  if (!minReceivedCollateral) {
    return null
  }

  return {
    ...igniteParams,
    amountOutMinimum: minReceivedCollateral,
  }
}

export const calculateIgniteSlippage = (
  margin?: bigint,
  debt?: bigint,
  size?: bigint,
  zeroForOne?: boolean,
  poolPrice?: bigint | null,
  fee?: bigint,
  quotedAmountOut?: bigint,
): string | undefined => {
  if (
    !poolPrice ||
    !margin ||
    !debt ||
    !size ||
    !fee ||
    !quotedAmountOut ||
    zeroForOne === undefined
  ) {
    return undefined
  }

  const amountOutRaw = calculateAmountOutRaw({
    margin,
    debt,
    size,
    zeroForOne,
    poolPrice,
    fee,
  })

  if (!amountOutRaw) {
    return undefined
  }

  return calculatePriceImpact(quotedAmountOut, amountOutRaw)
}
