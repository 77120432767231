import { readContract } from "@wagmi/core"
import { Address } from "viem"
import { wagmiConfig } from "src/wagmiConfig"
import { MintQuoteParams, MintQuote } from "src/types"
import marginalV1QuoterAbi from "src/constants/abis/MarginalV1Quoter.json"

export async function getMintQuote(
  chainId: number,
  quoterAddress: Address,
  params: MintQuoteParams,
): Promise<MintQuote | null> {
  try {
    const data = await readContract(wagmiConfig, {
      chainId,
      address: quoterAddress,
      abi: marginalV1QuoterAbi,
      functionName: "quoteMint",
      args: [params],
    })

    if (!Array.isArray(data)) {
      return null
    }

    return {
      size: data[0],
      debt: data[1],
      margin: data[2],
      safeMarginMinimum: data[3],
      fees: data[4],
      safe: data[5],
      liquidityAfter: data[6],
      sqrtPriceX96After: data[7],
      liquidityLockedAfter: data[8],
    }
  } catch (error) {
    console.error("Error getting mint quote:", error)
    return null
  }
}
