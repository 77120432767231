import { useState, useEffect, useCallback } from "react"
import { isUndefined } from "lodash"
import { readContract, getBalance } from "@wagmi/core"
import { zeroAddress, erc20Abi, Address } from "viem"
import { trimTrailingZeroes } from "../utils/trimTrailingZeroes"
import { formatBigIntToString } from "../utils/formatBigIntToString"
import { wagmiConfig } from "src/wagmiConfig"

interface AllowanceParams {
  tokenAddress: Address
  ownerAddress: Address
  spenderAddress: Address
  chainId: number
}

interface UseErc20AllowanceResult {
  allowance: bigint | undefined
  parsedAllowance: string | undefined
  fetchAllowance: () => Promise<void>
  error: Error | null
}

const DISPLAY_DECIMALS = 18

const validateAllowanceParams = (params: Partial<AllowanceParams>): void => {
  if (!params.ownerAddress) {
    throw new Error("Missing owner address")
  }
  if (!params.chainId) {
    throw new Error("Missing chain id")
  }
}

const fetchNativeTokenBalance = async (
  ownerAddress: Address,
  chainId: number,
): Promise<bigint> => {
  const balance = await getBalance(wagmiConfig, {
    address: ownerAddress,
    chainId,
  })
  return balance.value
}

const fetchErc20Allowance = async (params: AllowanceParams): Promise<bigint> => {
  const { tokenAddress, ownerAddress, spenderAddress, chainId } = params
  return readContract(wagmiConfig, {
    address: tokenAddress,
    chainId,
    abi: erc20Abi,
    functionName: "allowance",
    args: [ownerAddress, spenderAddress],
  })
}

export const getErc20TokenAllowance = async (
  params: AllowanceParams,
): Promise<bigint> => {
  validateAllowanceParams(params)
  return fetchErc20Allowance(params)
}

export const useErc20TokenAllowance = (
  tokenAddress: Address,
  tokenDecimals: number,
  ownerAddress: Address | undefined,
  spenderAddress: Address,
  chainId: number | undefined,
): UseErc20AllowanceResult => {
  const [allowance, setAllowance] = useState<bigint | undefined>(undefined)
  const [error, setError] = useState<Error | null>(null)

  const fetchAllowance = useCallback(async () => {
    try {
      setError(null)
      validateAllowanceParams({ tokenAddress, ownerAddress, spenderAddress, chainId })

      const fetchedAllowance =
        tokenAddress === zeroAddress
          ? await fetchNativeTokenBalance(ownerAddress!, chainId!)
          : await fetchErc20Allowance({
              tokenAddress,
              ownerAddress: ownerAddress!,
              spenderAddress,
              chainId: chainId!,
            })

      setAllowance(fetchedAllowance)
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : "Error fetching allowance"
      setError(new Error(errorMessage))
      setAllowance(undefined)
      console.error("Error fetching allowance:", err)
    }
  }, [tokenAddress, ownerAddress, spenderAddress, chainId])

  useEffect(() => {
    setAllowance(undefined)
    setError(null)
  }, [tokenAddress, tokenDecimals, ownerAddress, spenderAddress, chainId])

  const parsedAllowance = isUndefined(allowance)
    ? undefined
    : trimTrailingZeroes(formatBigIntToString(allowance, tokenDecimals, DISPLAY_DECIMALS))

  return {
    allowance,
    parsedAllowance,
    fetchAllowance,
    error,
  }
}
