import { useCallback, useMemo } from "react"
import { Link } from "react-router-dom"
import { type Token } from "src/types"
import { V1_NFT_POSITION_MANAGER_ADDRESS } from "src/constants/addresses"
import useCopyClipboard from "src/hooks/useCopyClipboard"
import { getValidAddress } from "src/utils/getValidAddress"
import { getExplorerLink, ExplorerDataType } from "src/utils/getExplorerLink"
import { shortenAddress } from "src/utils/shortenAddress"
import { PoolDetailsLink } from "src/pages/pool/components/PoolDetailsLink"
import { MouseoverTooltip } from "src/components/ui/MouseoverTooltip"
import { SquareStackIcon } from "src/components/Icons/SquareStackIcon"
import { EtherscanLogo } from "src/components/Icons/Etherscan"

interface PositionLinksProps {
  chainId: number
  poolAddress: string | undefined
  token0: Token | undefined
  token1: Token | undefined
}

export const PositionLinks = ({
  chainId,
  poolAddress,
  token0,
  token1,
}: PositionLinksProps) => {
  if (!chainId || !poolAddress) return null
  return (
    <div className="space-y-4 md:px-4 md:pb-4">
      <div className="text-sm text-marginalGray-200">Links</div>
      <div className="flex flex-col space-y-6 md:flex-row md:space-y-0">
        <div className="flex w-full flex-col space-y-6">
          <PoolDetailsLink
            chainId={chainId}
            address={poolAddress}
            tokens={[token0, token1]}
          />
          <PoolDetailsLink
            chainId={chainId}
            address={token0?.address}
            tokens={[token0]}
          />
        </div>

        <div className="mx-6 my-auto hidden h-24 w-px bg-marginalGray-200/20 md:block" />

        <div className="flex w-full flex-col space-y-6">
          <PoolDetailsLink
            chainId={chainId}
            address={token1?.address}
            tokens={[token1]}
          />
          <ContractDetailsLink
            chainId={chainId}
            address={V1_NFT_POSITION_MANAGER_ADDRESS[chainId]}
            contractName="NFT Position Manager"
            contractDescription="Position contract"
          />
        </div>
      </div>
    </div>
  )
}

const ContractDetailsLink = ({
  chainId,
  address,
  contractName,
  contractDescription,
}: {
  chainId: number | undefined
  address: string | undefined
  contractName: string
  contractDescription: string
}) => {
  const [isCopied, setCopied] = useCopyClipboard()

  const explorerUrl = useMemo(
    () =>
      address && chainId
        ? getExplorerLink(chainId, address, ExplorerDataType.ADDRESS)
        : undefined,
    [address, chainId],
  )

  const copy = useCallback(() => {
    const checksummedAddress = getValidAddress(address)
    checksummedAddress && setCopied(checksummedAddress)
  }, [address, setCopied])

  if (!address) return null

  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-col">
        <div className="text-sm text-marginalGray-200">{contractName}</div>

        <p className="text-xs text-marginalGray-600">{contractDescription}</p>
      </div>

      <div className="flex items-center space-x-2">
        <MouseoverTooltip
          hoverContent={<div>{isCopied ? "Copied" : "Copy"}</div>}
          timeoutInMs={isCopied ? 500 : 0}
        >
          <div
            onClick={copy}
            className="flex cursor-pointer items-center space-x-1 rounded-lg bg-marginalGray-800 py-2 pl-2.5 pr-2"
          >
            <p className="text-sm text-marginalGray-200">{shortenAddress(address)}</p>
            <SquareStackIcon />
          </div>
        </MouseoverTooltip>

        <Link to={explorerUrl ?? "#"} target="_blank" className="p-1 hover:opacity-50">
          <EtherscanLogo />
        </Link>
      </div>
    </div>
  )
}
