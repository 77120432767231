import { GlobeAltIcon } from "@heroicons/react/24/outline"
import { LaunchBannerImage } from "./LaunchBannerImage"
import { TwitterIcon } from "../Icons/TwitterIcon"
import { DiscordIcon } from "../Icons/DiscordIcon"

const LaunchProjectDescription = () => {
  return (
    <div
      id="project-description"
      className="relative mx-auto w-[380px] rounded-3xl border border-marginalGray-800 bg-marginalGray-900 shadow-outerBlack sm:w-[440px]"
    >
      <div className="flex justify-between px-4 py-4">
        <span className="text-xl uppercase text-marginalGray-200">
          Marginal
        </span>

        <div id="project-socials" className="flex items-center space-x-2">
          <div className="flex h-7 w-fit items-center gap-1.5 rounded-md bg-marginalGray-800 px-1.5 pb-1.5 pt-1.5">
            <GlobeAltIcon className="h-3 w-3 text-marginalGray-200" />
            <span className="text-xs uppercase text-marginalGray-200">
              Website
            </span>
          </div>

          <div className="flex h-7 w-fit items-center rounded-md bg-marginalGray-800 px-1.5 py-1.5">
            <TwitterIcon />
          </div>

          <div className="flex h-7 w-fit items-center rounded-md bg-marginalGray-800 px-1.5 py-1.5">
            <DiscordIcon />
          </div>
        </div>
      </div>

      <hr className="m-0 h-px border-none bg-marginalGray-800" />

      <div id="project-description-content" className="space-y-5 px-4 py-4">
        <LaunchBannerImage />

        <div className="text-sm text-marginalGray-200">
          Marginal is a decentralized spot and perpetual exchange that facilitates
          leverage trading on any asset using an Uniswap V3 Oracle. Its core mechanism
          resembles overcollateralized short-selling, where traders borrow from pooled
          liquidity to take derivative positions and swap tokens for leverage.
        </div>
      </div>
    </div>
  )
}

export default LaunchProjectDescription
