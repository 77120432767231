import { Address } from "viem"
import { useNavigateRoutes } from "src/hooks/useNavigateRoutes"
import { useIncentivizedPoolYieldPercentageRate } from "src/pages/pools/hooks/useIncentivizedPoolYieldPercentageRate"
import { calculatePercentageOfTotal } from "src/utils/conversions/calculatePercentageOfTotal"
import { convertMaintenanceToLeverage } from "src/utils/conversions/convertMaintenanceToLeverage"
import { formatNumberAmount } from "src/utils/formatNumberAmount"
import { PoolRowProps } from "src/pages/pools/components/PoolRow"
import { MobileTableCell } from "src/pages/pools/components/TableComponents"
import { CircularProgressBar } from "src/pages/pools/components/CircularProgressBar"
import { CaretRightIcon } from "src/components/Icons/CaretRightIcon"
import { getTokenByAddress } from "src/constants/tokenList"
import { DoubleTokenLogo, TokenBadge } from "src/components/TokenBadge"
import { type Token } from "src/types"

export const MobilePoolRowContent = ({
  chainId,
  pool,
  liquidityLocked,
  userPoolBalance,
  poolTotalSupply,
  poolState,
  poolTokenBalances,
}: PoolRowProps) => {
  const { onNavigateToPool } = useNavigateRoutes()
  const handleNavigateToPool = () => onNavigateToPool(pool.poolAddress)
  const token0 = getTokenByAddress(pool?.token0?.address, chainId) ?? pool?.token0
  const token1 = getTokenByAddress(pool?.token1?.address, chainId) ?? pool?.token1
  const maxLeverage = convertMaintenanceToLeverage(pool.maintenance)
  const userSharePercentageOfTotal = calculatePercentageOfTotal({
    total: parseFloat(poolTotalSupply?.parsedTotalSupply ?? "0"),
    partial: parseFloat(userPoolBalance?.parsedBalance ?? "0"),
  })
  const poolUtilization = calculatePercentageOfTotal({
    total: parseFloat(poolTotalSupply?.parsedTotalSupply ?? "0"),
    partial: parseFloat(liquidityLocked?.parsedLockedLiquidity ?? "0"),
  })
  const incentivizedPoolYieldPercentageRate = useIncentivizedPoolYieldPercentageRate(
    chainId,
    pool,
    pool.poolAddress as Address,
    pool.poolAddress as Address, // TODO: Update for reward token when available
  )

  return (
    <tr
      onClick={handleNavigateToPool}
      className="flex h-min flex-col space-y-4 bg-marginalBlack p-4 md:hidden"
    >
      <MobileTableCell>
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-1.5">
            <DoubleTokenLogo token0={token0 as Token} token1={token1 as Token} size={6} />
            <span>
              {token0?.symbol} / {token1?.symbol}
            </span>
          </div>
          <CaretRightIcon />
        </div>
      </MobileTableCell>
      <div className="grid grid-cols-2">
        <div className="flex flex-col space-y-4">
          <MobileTableCell label="Leverage">{maxLeverage}x</MobileTableCell>
          <MobileTableCell label="APR">
            {formatNumberAmount(incentivizedPoolYieldPercentageRate?.parsedYieldRate) ??
              "-"}
            %
          </MobileTableCell>
          <MobileTableCell label="Pooled Assets">
            {poolTokenBalances?.map((balance, index) => (
              <div key={index} className="flex items-center gap-1.5">
                {formatNumberAmount(balance?.parsedBalance ?? "-")}
                <span className="flex items-center gap-1.5">
                  <TokenBadge token={index === 0 ? token0 : token1} size={4} />
                </span>
              </div>
            ))}
          </MobileTableCell>
        </div>
        <div className="flex flex-col space-y-4">
          <MobileTableCell label="Utilization">
            <div className="flex h-6 items-center justify-start gap-1.5">
              <CircularProgressBar
                sqSize={16}
                strokeWidth={2}
                percentage={poolUtilization ?? 0}
              />
              {formatNumberAmount(poolUtilization?.toString() ?? "0")}%
            </div>
          </MobileTableCell>
          <MobileTableCell label="My Share">
            {formatNumberAmount(userSharePercentageOfTotal?.toString())}%
          </MobileTableCell>
        </div>
      </div>
    </tr>
  )
}
