import { cleanInput } from "src/utils/cleanInput"

export const WithdrawInput = ({
  title,
  inputValue,
  totalBalance,
  onChange,
  setPercentage,
  setPercentError,
}: {
  title: string
  inputValue: string
  totalBalance: string | undefined
  onChange: (value: string) => void
  setPercentage: React.Dispatch<React.SetStateAction<number>>
  setPercentError: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const handleApplyFloatToPercentage = (value: string) => {
    const percentage = Number(((Number(value) / Number(totalBalance)) * 100).toFixed(0))
    if (percentage <= 100) {
      setPercentage(percentage)
      setPercentError(false)
    } else {
      setPercentage(0)
      setPercentError(true)
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(cleanInput(e.target.value))
    handleApplyFloatToPercentage(e.target.value)
  }
  return (
    <div className="flex-1 space-y-1">
      <label
        htmlFor="withdraw-input"
        className="text-sm text-textGray"
      >
        {title}
      </label>
      <input
        value={inputValue}
        onChange={handleChange}
        placeholder="0"
        pattern="^[0-9]*[.,]?[0-9]*$"
        autoComplete="off"
        className={`w-full border-none bg-marginalBlack p-0 text-3xl text-marginalGray-200 outline-none focus:border-none focus:outline-none focus:ring-0`}
      />
    </div>
  )
}
