import { Token } from "src/types"
import { getValidAddress } from "src/utils/getValidAddress"

export const getSwapOutputList = (
  pools: any[],
  inputToken?: Token | null,
  priorityTokenList?: Token[] | null,
): Token[] => {
  if (!inputToken || !pools?.length) return []

  const outputTokens = new Set<Token>()
  const inputTokenAddress = getValidAddress(inputToken.address)

  pools.forEach((pool) => {
    const token0Address = getValidAddress(pool.token0?.address)
    const token1Address = getValidAddress(pool.token1?.address)

    if (inputTokenAddress === token0Address) {
      // Try to find token in tokenList first
      const tokenFromPriorityList = priorityTokenList?.find(
        (t) => getValidAddress(t.address) === token1Address,
      )
      outputTokens.add(tokenFromPriorityList || pool.token1)
    } else if (inputTokenAddress === token1Address) {
      // Try to find token in tokenList first
      const tokenFromPriorityList = priorityTokenList?.find(
        (t) => getValidAddress(t.address) === token0Address,
      )
      outputTokens.add(tokenFromPriorityList || pool.token0)
    }
  })

  return Array.from(outputTokens)
}
