import { useState } from "react"
import { ActionButton } from "src/components/ActionButton"
import { TransactionModal } from "src/pages/createPool/components/TransactionModal"
import { submitTransaction } from "src/utils/submitTransaction"
import useInitializeOracle from "src/pages/createPool/hooks/useInitializeOracle"
import { useCreatePoolValidations } from "src/pages/createPool/hooks/useCreatePoolValidations"

export const ExecuteInitiateOracleButton = () => {
  const { initializeOracleAsync, isGasLoading, isGasLimitExceeded } =
    useInitializeOracle()
  const [modalOpen, setModalOpen] = useState(false)
  const {
    isOracleInitRequired,
    isUniswapPool,
    isTokenSelectionsValid,
    isOracleSelectionValid,
  } = useCreatePoolValidations()

  const isButtonDisabled =
    isGasLoading ||
    !isUniswapPool ||
    !isOracleInitRequired ||
    !isTokenSelectionsValid ||
    !isOracleSelectionValid

  let buttonProperties

  if (!isTokenSelectionsValid) {
    buttonProperties = {
      action: "Select token",
      onClick: () => setModalOpen(true),
    }
  } else if (!isOracleSelectionValid) {
    buttonProperties = {
      action: "Select Oracle",
      onClick: () => setModalOpen(true),
    }
  } else if (isGasLoading) {
    buttonProperties = {
      action: "Estimating Gas...",
      onClick: () => setModalOpen(true),
    }
  } else if (isUniswapPool && isOracleInitRequired) {
    buttonProperties = {
      action: "Initialize Oracle",
      onClick: () => setModalOpen(true),
    }
  } else if (!isUniswapPool) {
    buttonProperties = {
      action: "Uniswap Pool does not exist.",
      onClick: () => setModalOpen(true),
    }
  } else {
    buttonProperties = {
      action: "Oracle is already initialized.",
      onClick: () => setModalOpen(true),
    }
  }

  return (
    <div>
      <ActionButton {...buttonProperties} disabled={isButtonDisabled} />

      <TransactionModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onCallback={async () => {
          if (!initializeOracleAsync) {
            console.error("initializeOracleAsync is undefined")
            return
          }
          submitTransaction(initializeOracleAsync)
        }}
        onSuccessText="Oracle Initialized"
      >
        <div className="max-w-[440px] rounded-3xl bg-marginalBlack p-4 tracking-wide text-marginalGray-200 shadow-innerBlack">
          <h2 className="mb-2 flex items-center gap-2 text-lg font-semibold text-yellow-400">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              className="mt-[2px]"
            >
              <path
                d="M16.2973 13.5007L10.2973 3.00066C10.1665 2.76981 9.97678 2.5778 9.74752 2.44421C9.51826 2.31062 9.25767 2.24023 8.99232 2.24023C8.72698 2.24023 8.46639 2.31062 8.23713 2.44421C8.00787 2.5778 7.81815 2.76981 7.68733 3.00066L1.68733 13.5007C1.55509 13.7297 1.48575 13.9896 1.48633 14.254C1.48692 14.5185 1.55741 14.7781 1.69066 15.0065C1.82391 15.235 2.01519 15.4241 2.2451 15.5548C2.47501 15.6855 2.73538 15.753 2.99983 15.7507H14.9998C15.263 15.7504 15.5215 15.6809 15.7493 15.5491C15.9771 15.4174 16.1662 15.228 16.2977 15C16.4292 14.772 16.4984 14.5134 16.4983 14.2503C16.4982 13.9871 16.4289 13.7286 16.2973 13.5007Z"
                stroke="#FFC121"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9 6.75V9.75"
                stroke="#FFC121"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9 12.75H9.0075"
                stroke="#FFC121"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Important Notice
          </h2>
          <p className="text-sm leading-relaxed">
            By submitting this transaction, the oracle will be initialized for the
            selected Uniswap v3 pool. This process will take approximately 12 hours to
            gather the necessary price data before the pool can be fully deployed. Please
            ensure the details are correct before proceeding.
          </p>

          <div className="my-4 h-px bg-marginalGray-200/20" />

          <p className="text-sm leading-relaxed">
            {isGasLimitExceeded &&
              "Two transactions will be required for the initialization."}
          </p>
        </div>
      </TransactionModal>
    </div>
  )
}
