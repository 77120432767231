import { TableCell } from "src/pages/pools/components/TableComponents"

export const PoolRowLoading = () => {
  return (
    <>
      <MobilePoolRowContentLoading />
      <PoolRowContentLoading />
    </>
  )
}

const PoolRowContentLoading = () => {
  return (
    <tr className="hidden w-full cursor-pointer bg-marginalBlack md:table-row md:items-center">
      <TableCell>
        <LoadingContent type="pool" />
      </TableCell>
      <TableCell>
        <LoadingContent type="leverage" />
      </TableCell>
      <TableCell>
        <LoadingContent type="percentage" />
      </TableCell>
      <TableCell>
        <LoadingContent type="standard" />
      </TableCell>
      <TableCell>
        <LoadingContent type="percentage" />
      </TableCell>
      <TableCell>
        <LoadingContent type="percentage" />
      </TableCell>
    </tr>
  )
}

const MobilePoolRowContentLoading = () => {
  return (
    <tr className="relative h-52 space-y-4 rounded-xl bg-marginalGray-950 p-4 md:hidden">
      <LoadingRow />
      <LoadingRow />
      <LoadingRow />
      <LoadingRow />
      <LoadingRow />
    </tr>
  )
}

const LoadingRow = () => (
  <td className="flex items-center justify-between md:py-2">
    <div className="flex space-x-2">
      <div className="h-5 w-5 rounded-full bg-marginalGray-200"></div>
      <div className="h-5 w-8 rounded bg-marginalGray-200"></div>
    </div>
    <div className="h-5 w-14 rounded bg-marginalGray-200"></div>
  </td>
)

const LoadingContent = ({
  type,
}: {
  type: "pool" | "leverage" | "standard" | "percentage" | "empty"
}) => {
  switch (type) {
    case "pool":
      return (
        <div className="flex items-center space-x-4">
          <div className="h-8 w-8 rounded-full bg-marginalGray-200"></div>
          <div className="flex flex-col space-y-2">
            <div className="h-4 w-24 rounded bg-marginalGray-200"></div>
            <div className="h-3 w-36 rounded bg-marginalGray-200"></div>
          </div>
        </div>
      )
    case "leverage":
      return <div className="ml-auto h-4 w-8 rounded bg-marginalGray-200"></div>
    case "percentage":
      return (
        <div className="flex items-center justify-end">
          <div className="h-4 w-10 rounded bg-marginalGray-200"></div>
        </div>
      )
    case "empty":
      return null
    default:
      return (
        <div className="flex items-center space-x-2">
          <div className="h-7 w-7 rounded-full bg-marginalGray-200"></div>
          <div className="h-4 w-10 rounded bg-marginalGray-200"></div>
        </div>
      )
  }
}
