import { simulateContract, waitForTransactionReceipt, writeContract } from "@wagmi/core"
import { Address, parseEther } from "viem"
import { wagmiConfig } from "src/wagmiConfig"
import { ExactInputSingleParams } from "src/types"
import marginalV1RouterAbi from "src/constants/abis/MarginalV1Router.json"

export const exactInputSingle = async (
  chainId: number,
  amount: string,
  params: ExactInputSingleParams | null,
  router: Address,
  isGasToken: boolean,
) => {
  const { request } = await simulateContract(wagmiConfig, {
    chainId,
    address: router,
    abi: marginalV1RouterAbi,
    functionName: "exactInputSingle",
    args: [params],
    value: isGasToken ? parseEther(amount) : undefined,
  })

  const hash = await writeContract(wagmiConfig, request)

  const receipt = await waitForTransactionReceipt(wagmiConfig, { hash })

  return receipt
}
