import { useSettingsState } from "src/state/settings/hooks"
import { SlippageButton } from "src/components/Settings/SlippageButton"
import { useSettingsToggle } from "src/hooks/useSettingsToggle"

const SlippageConfig = () => {
  const { maxSlippage } = useSettingsState()
  const { showSettings, onOpenSettings, onCloseSettings } = useSettingsToggle()
  return (
    <SlippageButton
      maxSlippage={maxSlippage}
      showSettings={showSettings}
      onClose={onCloseSettings}
      onOpen={onOpenSettings}
    />
  )
}

export default SlippageConfig
