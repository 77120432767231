import { useReadContract } from "wagmi"
import { Address } from "viem"
import { isListDefined } from "src/utils/isListDefined"
import MarginalV1PoolAbi from "src/constants/abis/MarginalV1Pool.json"

export const usePoolOracle = (poolAddress?: Address) => {
  const isValidProps = isListDefined([poolAddress])

  const { data } = useReadContract({
    address: poolAddress as Address,
    abi: MarginalV1PoolAbi,
    functionName: "oracle",
    query: {
      enabled: isValidProps,
    },
  })

  const oracle = data ?? undefined

  return oracle
}
