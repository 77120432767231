import { useReadContracts } from "wagmi"
import { Address } from "viem"
import marginalV1QuoterAbi from "src/constants/abis/MarginalV1Quoter.json"
import { IgniteQuoteParams } from "../helpers/constructIgniteQuoteParams"
import { constructIgniteQuoteParams } from "../helpers/constructIgniteQuoteParams"
import { V1_QUOTER_ADDRESS } from "src/constants/addresses"
import { IgniteQuote } from "src/types"
import { isWrappedGasTokenAddress } from "src/utils/isWrappedGasToken"

export type QuotesStateMap = {
  [tokenId: string]: IgniteQuote & { tokenId: string }
}

interface ContractConfig {
  address: Address
  abi: any
  functionName: "quoteIgnite"
  args: [IgniteQuoteParams]
  chainId: number
}

interface Position {
  zeroForOne: boolean
  token0: Address
  token1: Address
  maintenance: string
  oracle: Address
  tokenId: string
}

const validateInputs = (positions: Position[], chainId: number | undefined): boolean => {
  return Array.isArray(positions) && positions.length > 0 && typeof chainId === "number"
}

const createContractConfigs = (
  positions: Position[],
  chainId: number,
): ContractConfig[] => {
  return positions
    .map((position) => {
      const igniteParams = constructIgniteQuoteParams({
        token0: position.token0,
        token1: position.token1,
        maintenance: position.maintenance,
        oracle: position.oracle,
        tokenId: position.tokenId,
        amountOutMinimum: 0n,
        recipient: "0x0000000000000000000000000000000000000000",
        currentBlockTimestamp: undefined,
        transactionDeadline: "0",
      })

      if (!igniteParams) return null

      return {
        address: V1_QUOTER_ADDRESS[chainId],
        abi: marginalV1QuoterAbi,
        functionName: "quoteIgnite",
        args: [igniteParams],
        chainId,
      } as const
    })
    .filter((config): config is ContractConfig => config !== null)
}

const formatQuotesData = (
  data: unknown[] | undefined,
  positions: Position[],
  chainId: number,
): QuotesStateMap => {
  if (!data) return {}

  return data.reduce<QuotesStateMap>((acc, result, index) => {
    if (
      !result ||
      typeof result !== "object" ||
      !("result" in result) ||
      !Array.isArray(result.result)
    ) {
      return acc
    }

    const position = positions[index]
    const quote = result.result
    const [marginToken] = position.zeroForOne
      ? [position.token1, position.token0]
      : [position.token0, position.token1]

    const isMarginTokenWeth = isWrappedGasTokenAddress(marginToken, chainId)

    acc[position.tokenId] = {
      tokenId: position.tokenId,
      amountOut: isMarginTokenWeth ? quote[0] - quote[1] : quote[0],
      rewards: quote[1],
      liquidityAfter: quote[2],
      sqrtPriceX96After: quote[3],
      liquidityLockedAfter: quote[4],
    }

    return acc
  }, {})
}

export interface IgniteQuotesState {
  data: QuotesStateMap
  isLoading: boolean
  isError: boolean
  error: Error | null
  refetch: () => void
}

export const useMultipleIgniteQuote = (
  positions: Position[],
  chainId: number,
): IgniteQuotesState => {
  const isValid = validateInputs(positions, chainId)
  const contracts = isValid ? createContractConfigs(positions, chainId) : []

  const { data, isLoading, isError, error, refetch } = useReadContracts({
    contracts,
    query: {
      enabled: isValid,
    },
  })

  const formattedData = formatQuotesData(data, positions, chainId)

  return {
    data: formattedData,
    isLoading,
    isError,
    error: error as Error | null,
    refetch,
  }
}
