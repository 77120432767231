import _ from "lodash"
import { useReadContracts } from "wagmi"
import { Address, erc20Abi } from "viem"
import { Token } from "../types"
import { formatBigIntToString } from "../utils/formatBigIntToString"
import multicallAbi from "src/constants/abis/multicall.json"
import { zeroAddress } from "viem"

interface TokenBalance {
  token: Token | undefined | null
  balance: bigint
  shortenedParsedBalance?: string
  parsedBalance?: string
}

interface ContractRead {
  address: Address
  abi: any
  functionName: string
  args: Address[]
}

const MULTICALL3_ADDRESS: Address = "0xcA11bde05977b3631167028862bE2a173976CA11"
const SHORTENED_DECIMAL_PLACES = 4

const contractService = {
  createErc20ContractRead: (
    tokenAddress: Address,
    ownerAddress: Address,
  ): ContractRead => ({
    address: tokenAddress,
    abi: erc20Abi,
    functionName: "balanceOf",
    args: [ownerAddress],
  }),

  createMulticallContractRead: (ownerAddress: Address): ContractRead => ({
    address: MULTICALL3_ADDRESS,
    abi: multicallAbi,
    functionName: "getEthBalance",
    args: [ownerAddress],
  }),

  createContractsForTokens: (
    tokens: (Token | undefined | null)[],
    owner?: string,
  ): ContractRead[] => {
    if (!owner) return []

    return tokens.reduce<ContractRead[]>((contracts, token) => {
      if (_.isEmpty(token)) return contracts

      const ownerAddress = owner as Address
      const contract =
        token?.address === zeroAddress
          ? contractService.createMulticallContractRead(ownerAddress)
          : contractService.createErc20ContractRead(
              token?.address as Address,
              ownerAddress,
            )

      return [...contracts, contract]
    }, [])
  },
}

const balanceService = {
  formatBalance: (
    rawBalance: bigint,
    token: Token | undefined | null,
  ): Omit<TokenBalance, "token"> => ({
    balance: rawBalance,
    shortenedParsedBalance: formatBigIntToString(
      rawBalance,
      token?.decimals,
      SHORTENED_DECIMAL_PLACES,
    ),
    parsedBalance: formatBigIntToString(rawBalance, token?.decimals),
  }),
}

export const useErc20TokenBalances = (
  tokens: (Token | undefined | null)[],
  owner?: string,
) => {
  const contracts = contractService.createContractsForTokens(tokens, owner)
  const isEnabled = !_.isEmpty(contracts) && !_.isEmpty(owner)

  const { data, isError, isLoading, refetch } = useReadContracts({
    contracts,
    query: {
      enabled: isEnabled,
    },
  })

  const balances: TokenBalance[] | undefined = data?.map((fetchedBalance, index) => ({
    token: tokens[index],
    ...balanceService.formatBalance(fetchedBalance?.result as bigint, tokens[index]),
  }))

  return {
    balances,
    refetch,
    isLoading,
    isError,
  }
}
