import _ from "lodash"
import { useReadContracts } from "wagmi"
import { Address } from "viem"
import marginalV1PoolAbi from "src/constants/abis/MarginalV1Pool.json"
import { formatBigIntToString } from "src/utils/formatBigIntToString"

export type PoolState = {
  sqrtPriceX96: bigint
  totalPositions: number
  liquidity: bigint
  parsedLiquidity: string
  tick: bigint
  blockTimestamp: string
  tickCumulative: bigint
  feeProtocol: bigint
  initialized: boolean
}

interface ContractConfig {
  address: Address
  abi: any
  functionName: "state"
}

interface PoolStateMap {
  [poolAddress: string]: PoolState | undefined
}

interface PoolStateResult {
  poolStates: PoolStateMap
  isError: boolean
  isLoading: boolean
  refetch: () => void
}

const createContractConfig = (poolAddress: Address): ContractConfig => ({
  address: poolAddress,
  abi: marginalV1PoolAbi,
  functionName: "state",
})

const parsePoolState = (data: readonly unknown[]): PoolState => {
  const liquidity = data[2] as bigint
  return {
    sqrtPriceX96: data[0] as bigint,
    totalPositions: Number(data[1]),
    liquidity,
    parsedLiquidity: formatBigIntToString(liquidity, "18") as string,
    tick: data[3] as bigint,
    blockTimestamp: String(data[4]),
    tickCumulative: data[5] as bigint,
    feeProtocol: data[6] as bigint,
    initialized: Boolean(data[7]),
  }
}

export const useCurrentPoolsState = (
  poolAddresses: readonly Address[],
): PoolStateResult => {
  const validAddresses = poolAddresses.filter(
    (address) => address !== "0x0000000000000000000000000000000000000000",
  )

  const contracts = validAddresses.map(createContractConfig)
  const isEnabled = contracts.length > 0

  const { data, isError, isLoading, refetch } = useReadContracts({
    contracts,
    query: {
      enabled: isEnabled,
    },
  })

  const poolStates = validAddresses.reduce<PoolStateMap>((acc, address, index) => {
    const stateData = data?.[index]?.result

    if (_.isArray(stateData)) {
      acc[address] = parsePoolState(stateData)
    } else {
      acc[address] = undefined
    }

    return acc
  }, {})

  return {
    poolStates,
    isError,
    isLoading,
    refetch,
  }
}
