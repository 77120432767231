type SwitchButtonProps = {
  view: string
  onLeftButtonClick?: () => void
  onRightButtonClick?: () => void
  leftButtonText: string
  rightButtonText: string
}

export const SwitchButton = ({
  view,
  onLeftButtonClick,
  onRightButtonClick,
  leftButtonText,
  rightButtonText,
}: SwitchButtonProps) => {
  const isLeftButtonActive = view === leftButtonText

  const sliderColor = "bg-marginalOrange-800"
  const activeTextClasses = "text-marginalOrange-500"
  const inactiveTextClasses = "text-marginalGray-200 hover:bg-marginalOrange-500/10"

  const buttonBaseClasses = `relative z-10 flex h-full w-1/2 cursor-pointer items-center justify-center rounded-md text-sm font-bold uppercase transition-colors duration-100 ease-out`

  return (
    <div className="h-full w-full overflow-hidden rounded-lg bg-marginalBlack p-1">
      <div className="relative flex h-full w-full items-center">
        <div
          className={`absolute h-full w-1/2 rounded-md ${sliderColor} transition-transform duration-300 ease-in-out ${isLeftButtonActive ? "translate-x-0" : "translate-x-full"}`}
        />

        <button
          key="left-switch-btn"
          onClick={onLeftButtonClick}
          className={`${buttonBaseClasses} ${
            isLeftButtonActive ? activeTextClasses : inactiveTextClasses
          }`}
        >
          <span>{leftButtonText}</span>
        </button>

        <button
          key="right-switch-btn"
          onClick={onRightButtonClick}
          className={`${buttonBaseClasses} ${
            !isLeftButtonActive ? activeTextClasses : inactiveTextClasses
          }`}
        >
          <span>{rightButtonText}</span>
        </button>
      </div>
    </div>
  )
}
