import { useReadContract } from "wagmi"
import { Address, erc20Abi } from "viem"

export const usePoolTotalSupplySingle = (poolAddress: string) => {
  const { data } = useReadContract({
    address: poolAddress as Address,
    abi: erc20Abi,
    functionName: "totalSupply",
  })

  return data
}
