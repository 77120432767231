import { useEffect, useState } from "react"
import { useMobileView } from "src/hooks/useMobileView"
import { LaunchContainer } from "src/pages/lbp/Launch"
import {
  MobileTableCell,
  TableCell,
  TableHeaderCell,
  TableRow,
} from "src/components/Launch/TableComponents"
import { useAccount } from "wagmi"
import { Link } from "react-router-dom"
import TablePagination from "../TablePagination/TablePagination"
import { shortenAddress } from "src/utils/shortenAddress"
import { LaunchProjectTransactionProps } from "src/hooks/useLBData"
import { CaretRightIcon } from "../Icons/CaretRightIcon"
import { TokenLogo } from "../TokenBadge"

interface LaunchProjectTradeHistoryTableProps {
  title: string
  transactions: Array<LaunchProjectTransactionProps>
}

const LaunchProjectTradeHistoryTable = ({
  title,
  transactions,
}: LaunchProjectTradeHistoryTableProps) => {
  const { address } = useAccount()
  const { isMobileView } = useMobileView()
  const isLoading = false

  const [currentPage, setCurrentPage] = useState<number>(1)
  const [currentPageTransactions, setCurrentPageTransactions] = useState<any[] | null>(
    null,
  )

  useEffect(() => {
    if (transactions) {
      const reversedPageTransactions = transactions.slice(0).reverse()
      setCurrentPageTransactions(
        reversedPageTransactions.slice(
          (currentPage - 1) * 10,
          (currentPage - 1) * 10 + 10,
        ),
      )
    }
  }, [transactions, address, currentPage])

  const handleRedirect = (link: string) => {
    window.location.href = link
  }

  return (
    <>
      <LaunchContainer>
        <div
          id="launches-header"
          className="flex w-full flex-col items-start space-y-4 rounded-t-3xl bg-marginalGray-900 px-4 py-5"
        >
          <div className="flex items-center gap-2">
            <span className="text-xl text-[#CACACA]">
              {title}
            </span>

            <div className="rounded-md bg-marginalGray-800 px-1 py-0.5 text-sm text-marginalGray-400">
              {transactions.length}
            </div>
          </div>
        </div>

        <table
          className={
            isMobileView
              ? "w-full border-separate border-spacing-0 rounded-b-3xl bg-marginalGray-900 p-2 text-marginalGray-200"
              : "w-full table-auto border-separate border-spacing-0 overflow-hidden rounded-b-3xl bg-marginalGray-900 p-2"
          }
        >
          <thead>
            <>
              {isMobileView || !address ? (
                <></>
              ) : (
                <TableRow>
                  <TableHeaderCell align="left">Type</TableHeaderCell>
                  <TableHeaderCell align="left">Price</TableHeaderCell>
                  <TableHeaderCell align="left">Input</TableHeaderCell>
                  <TableHeaderCell align="left">Output</TableHeaderCell>
                  <TableHeaderCell align="left">Maker</TableHeaderCell>
                  <TableHeaderCell align="left">Time</TableHeaderCell>
                </TableRow>
              )}
            </>
          </thead>
          <tbody className="">
            {isLoading ? (
              <></>
            ) : transactions && currentPageTransactions && address ? (
              currentPageTransactions.map((tx: any, index: number) => {
                return (
                  <LaunchProjectTransactionRow
                    // chainId={chain?.id ?? DEFAULT_CHAIN_ID}
                    transaction={tx}
                    isMobile={isMobileView}
                    isOnlyPosition={currentPageTransactions.length === 1}
                    isFirst={
                      currentPageTransactions[0] === currentPageTransactions[index]
                    }
                    isLast={
                      currentPageTransactions[currentPageTransactions.length - 1] ===
                      currentPageTransactions[index]
                    }
                    onNavigate={() => handleRedirect(tx.explorerLink)}
                  />
                )
              })
            ) : null}
          </tbody>
        </table>
      </LaunchContainer>
      {transactions && (
        <TablePagination
          totalCount={transactions?.length}
          pageSize={10}
          currentPage={currentPage}
          onPageChange={(page) => setCurrentPage(page)}
        />
      )}
    </>
  )
}

export default LaunchProjectTradeHistoryTable

const LaunchProjectTransactionRow = ({
  // chainId,
  transaction,
  isMobile,
  isOnlyPosition,
  isFirst,
  isLast,
  onNavigate,
}: {
  transaction: LaunchProjectTransactionProps
  isMobile: boolean
  isOnlyPosition: boolean
  isFirst: boolean
  isLast: boolean
  onNavigate: () => void
}) => {
  return (
    <>
      {isMobile ? (
        <>
          <tr onClick={onNavigate}>
            <div
              className={`relative h-52 space-y-4 border-b bg-marginalGray-950 p-4 ${
                isOnlyPosition
                  ? "rounded-xl border-b-transparent"
                  : isFirst
                    ? "rounded-t-xl border-b-marginalGray-800"
                    : isLast
                      ? "rounded-b-xl border-b-transparent"
                      : "border-b-marginalGray-800"
              } `}
            >
              <div className="flex items-center justify-between">
                <div
                  className={`${transaction.type === "Buy" ? "bg-success-800 text-success-500" : "bg-error-800 text-error-500"} w-12 gap-2 rounded-sm px-2 py-1.5 text-center text-xs`}
                >
                  {transaction.type}
                </div>

                <CaretRightIcon />
              </div>

              <div className="flex">
                <MobileTableCell label="Input">
                  <div className="flex items-center space-x-1">
                    <TokenLogo
                      symbol={transaction.inputToken?.symbol}
                      imgUrl={transaction.inputToken?.imgUrl}
                      size={4}
                    />
                    <span className="text-xs text-marginalGray-200">
                      {transaction.input}
                    </span>
                  </div>
                </MobileTableCell>

                <MobileTableCell label="Output">
                  <div className="flex items-center space-x-1">
                    <TokenLogo
                      symbol={transaction.outputToken?.symbol}
                      imgUrl={transaction.outputToken?.imgUrl}
                      size={4}
                    />
                    <span className="text-xs text-marginalGray-200">
                      {transaction.output}
                    </span>
                  </div>
                </MobileTableCell>
              </div>

              <div className="flex">
                <MobileTableCell label="Maker">
                  {shortenAddress(transaction.maker)}
                </MobileTableCell>
                <MobileTableCell label="Time">{transaction.time}</MobileTableCell>
              </div>
            </div>
          </tr>
        </>
      ) : (
        <tr
          className={`w-fit border-none bg-marginalBlack ${
            isOnlyPosition
              ? "[&>td]:border-none first:[&>td]:rounded-bl-2xl first:[&>td]:rounded-tl-2xl last:[&>td]:rounded-br-2xl last:[&>td]:rounded-tr-2xl"
              : isFirst
                ? "first:[&>td]:rounded-tl-2xl last:[&>td]:rounded-tr-2xl"
                : isLast
                  ? "[&>td]:border-none first:[&>td]:rounded-bl-2xl last:[&>td]:rounded-br-2xl"
                  : ""
          }`}
        >
          <TableCell>
            <div
              className={`${transaction.type === "Buy" ? "bg-success-800 text-success-500" : "bg-error-800 text-error-500"} w-12 gap-2 rounded-sm px-2 py-1.5 text-center text-xs`}
            >
              {transaction.type}
            </div>
          </TableCell>

          <TableCell>
            <div className="text-left text-sm text-marginalGray-200">
              {transaction.price}
            </div>
          </TableCell>

          <TableCell>
            <div className="flex items-center space-x-3">
              <TokenLogo
                symbol={transaction.inputToken?.symbol}
                imgUrl={transaction.inputToken?.imgUrl}
                size={4}
              />
              <span className="text-sm text-marginalGray-200">
                {transaction.input}
              </span>
            </div>
          </TableCell>

          <TableCell>
            <div className="flex items-center space-x-3">
              <TokenLogo
                symbol={transaction.outputToken?.symbol}
                imgUrl={transaction.outputToken?.imgUrl}
                size={4}
              />
              <span className="text-sm text-marginalGray-200">
                {transaction.output}
              </span>
            </div>
          </TableCell>

          <TableCell>
            <div className="text-left text-sm text-marginalGray-200">
              {shortenAddress(transaction.maker)}
            </div>
          </TableCell>

          <TableCell>
            <div className="text-left text-sm text-marginalGray-200">
              {transaction.time}
            </div>
          </TableCell>

          <TableCell>
            <Link
              className="w-fit gap-1 rounded-lg bg-marginalOrange-800 px-4 py-2 text-sm text-marginalOrange-500 hover:opacity-80"
              to={transaction.explorerLink}
              target="_blank"
            >
              Txn
            </Link>
          </TableCell>
        </tr>
      )}
    </>
  )
}
