export const TwitterIcon = () => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.875 13.125L6.59275 8.40725M6.59275 8.40725L1.875 1.875H5L8.40725 6.59275M6.59275 8.40725L10 13.125H13.125L8.40725 6.59275M13.125 1.875L8.40725 6.59275"
      stroke="#BEBEBE"
      stroke-linecap="square"
    />
  </svg>
)
