import { parseUnits } from "viem"

export function toAmountMin(
  amount: string | number,
  decimals: number,
  slippage: string | number,
) {
  return parseUnits((Number(amount) * (1 - Number(slippage) / 100)).toString(), decimals)
}

export function toAmountMax(
  amount: string | number,
  decimals: number,
  slippage: string | number,
) {
  return parseUnits((Number(amount) * (1 + Number(slippage) / 100)).toString(), decimals)
}
