import { Address } from "viem"
import { isNull, isUndefined } from "lodash"
import { XMarkIcon } from "@heroicons/react/24/outline"
import { useState } from "react"
import { ActionButton } from "src/components/ActionButton"
import { TransactionModal } from "src/pages/createPool/components/TransactionModal"
import { useCreatePoolState } from "src/state/createPool/hooks"
import { useCreatePoolValidations } from "src/pages/createPool/hooks/useCreatePoolValidations"
import { useAccount } from "wagmi"
import { useConnectModal } from "@rainbow-me/rainbowkit"
import { formatStringToBigInt } from "src/utils/formatStringToBigInt"
import { shortenAddress } from "src/utils/shortenAddress"
import { DoubleTokenLogo, TokenBadge } from "src/components/TokenBadge"
import { Token } from "src/types"

interface DeployPoolButtonProps {
  token0Allowance: bigint | undefined
  token1Allowance: bigint | undefined
  token0Balance: bigint | undefined
  token1Balance: bigint | undefined
  isPendingApprove: boolean
  onApprove: (tokenAddress: Address, amount: bigint) => Promise<void>
  executeCreatePool: () => Promise<void>
}

const DeployPoolButton = ({
  token0Allowance,
  token1Allowance,
  token0Balance,
  token1Balance,
  isPendingApprove,
  onApprove,
  executeCreatePool,
}: DeployPoolButtonProps) => {
  const { isConnected, address } = useAccount()
  const { openConnectModal } = useConnectModal()
  const { amount0, amount1, token0, token1, leverage, uniswapPool } = useCreatePoolState()
  const [deployPoolModal, setDeployPoolModal] = useState(false)

  const {
    isDeployPoolValid,
    isLeverageSelectionValid,
    isDepositAmountValid,
    isTokenSelectionsValid,
    isOracleSelectionValid,
    isOracleInitRequired,
  } = useCreatePoolValidations()

  const formattedAmount0 = formatStringToBigInt(amount0, token0?.decimals)
  const formattedAmount1 = formatStringToBigInt(amount1, token1?.decimals)

  const isToken0Approved =
    token0Allowance !== undefined &&
    !isNull(amount0) &&
    !isNull(token0) &&
    !isUndefined(formattedAmount0) &&
    token0Allowance >= formattedAmount0

  const isToken1Approved =
    token1Allowance !== undefined &&
    !isNull(amount1) &&
    !isNull(token1) &&
    !isUndefined(formattedAmount1) &&
    token1Allowance >= formattedAmount1

  const hasSufficientToken0Balance =
    token0Balance !== undefined &&
    formattedAmount0 !== undefined &&
    token0Balance >= formattedAmount0

  const hasSufficientToken1Balance =
    token1Balance !== undefined &&
    formattedAmount1 !== undefined &&
    token1Balance >= formattedAmount1

  const isButtonDisabled =
    address &&
    (isPendingApprove ||
      isOracleInitRequired ||
      !isDeployPoolValid ||
      !isConnected ||
      !isLeverageSelectionValid ||
      !isOracleSelectionValid ||
      !hasSufficientToken0Balance ||
      !hasSufficientToken1Balance)

  let buttonProperties

  if (!address) {
    buttonProperties = {
      action: "Connect wallet",
      onClick: openConnectModal,
    }
  } else if (!isTokenSelectionsValid) {
    buttonProperties = {
      action: "Select token",
      onClick: () => null,
    }
  } else if (!isOracleSelectionValid) {
    buttonProperties = {
      action: "Select oracle",
      onClick: () => null,
    }
  } else if (isOracleInitRequired) {
    buttonProperties = {
      action: "Require oracle initialization",
      onClick: () => null,
    }
  } else if (!isDepositAmountValid) {
    buttonProperties = {
      action: "Input amount",
      onClick: () => null,
    }
  } else if (!hasSufficientToken0Balance || !hasSufficientToken1Balance) {
    buttonProperties = {
      action: "Not enough balance",
      onClick: () => null,
    }
  } else if (!isLeverageSelectionValid) {
    buttonProperties = {
      action: "Select leverage tier",
      onClick: () => null,
    }
  } else if (!isToken0Approved && token0) {
    buttonProperties = {
      action: `Approve ${token0.symbol} token`,
      onClick: () => onApprove(token0.address as Address, formattedAmount0!),
    }
  } else if (!isToken1Approved && token1) {
    buttonProperties = {
      action: `Approve ${token1.symbol} token`,
      onClick: () => onApprove(token1.address as Address, formattedAmount1!),
    }
  } else {
    buttonProperties = {
      action: "Create Pool",
      onClick: () => setDeployPoolModal(true),
    }
  }

  return (
    <div>
      <ActionButton disabled={isButtonDisabled} {...buttonProperties} />
      <TransactionModal
        open={deployPoolModal}
        onClose={() => setDeployPoolModal(false)}
        onCallback={() => {
          executeCreatePool()
          setDeployPoolModal(false)
        }}
        onSuccessText="Oracle Initialized"
      >
        <section className="flex max-w-md flex-col space-y-5 p-1.5 text-[#CACACA]">
          <header className="flex items-center justify-between">
            <h1 className="font-bold">Creating pool</h1>
            <XMarkIcon
              onClick={() => setDeployPoolModal(false)}
              className="h-5 w-5 cursor-pointer stroke-marginalGray-200 stroke-2 hover:opacity-80"
            />
          </header>

          <section className="flex flex-col items-start space-y-1">
            <div className="flex items-center gap-1">
              <DoubleTokenLogo
                token0={token0 as Token}
                token1={token1 as Token}
                size={6}
              />

              <span className="flex items-center text-xl">
                <span>{token0?.symbol}</span>
                <span>/</span>
                <span>{token1?.symbol}</span>
              </span>
            </div>

            <p className="space-x-1 text-xs">
              <span>Type:</span>
              <span>Marginal V1 Pool</span>
            </p>

            <p className="space-x-1 text-xs">
              <span>Leverage:</span>
              <span>{leverage?.leverage}x (Max)</span>
            </p>

            <p className="space-x-1 text-xs">
              <span>Oracle:</span>
              <span className="whitespace-nowrap">
                Uniswap V3 {shortenAddress(uniswapPool?.address ?? "")}
              </span>
            </p>
          </section>

          <section className="space-y-2 font-bold">
            <h1>Depositing</h1>

            <span className="flex items-center justify-between text-lg">
              <span>{amount0}</span>
              <TokenBadge size={7} token={token0 as Token} reverse />
            </span>

            <span className="flex items-center justify-between text-lg">
              <span>{amount1}</span>
              <TokenBadge size={7} token={token1 as Token} reverse />
            </span>
          </section>

          <section className="space-y-1.5 rounded-md bg-marginalBlack px-3 py-2 font-bold text-yellow-500">
            <h1>Warning!</h1>
            <p className="text-sm">
              Uniswap V3 Pool must require a minimum of $250,000 USD equivalent in full
              range liquidity in order to secure manipulation resistance within safe
              levels of $2,000,000 USD equivalent.
            </p>
          </section>
        </section>
      </TransactionModal>
    </div>
  )
}

export default DeployPoolButton
