import { NFTLoading } from "src/pages/position/components/NFTView"

export const PositionLoading = () => {
  return (
    <div className="flex flex-col space-y-6">
      <div className="flex w-full flex-col rounded-3xl border border-marginalGray-800 bg-marginalGray-900 shadow-outerBlack">
        <PositionHeaderLoading />
        <div className="flex w-full flex-col items-center justify-center space-y-4 rounded-3xl p-2 md:flex-row md:space-x-2 md:space-y-0 md:p-4">
          <div className="max-w-100 flex w-full items-center">
            <NFTLoading />
          </div>

          <div className="flex w-full max-w-112 flex-col space-y-2">
            <div className="flex h-full flex-col space-y-2">
              <div className="flex w-full flex-col justify-start space-y-5 rounded-xl bg-marginalBlack p-4 pt-5 md:rounded-lg">
                <div className="mb-2 h-3 w-24 min-w-16 animate-pulse rounded bg-marginalGray-200"></div>
                {Array.from({ length: 3 }).map((_, index) => (
                  <PositionDetailsLoading key={index} />
                ))}
              </div>

              <div className="flex h-full w-full flex-col justify-start space-y-4 rounded-xl bg-marginalBlack p-4 pt-5 md:rounded-lg">
                <div className="mb-auto flex pb-2">
                  <div className="h-3 w-24 min-w-16 animate-pulse rounded bg-marginalGray-200"></div>
                </div>
                {Array.from({ length: 5 }).map((_, index) => (
                  <PositionDetailsLoading key={index} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <PositionLinksLoading />
    </div>
  )
}

const PositionHeaderLoading = () => {
  return (
    <div className="flex w-full flex-col gap-4 border-b border-marginalGray-800 p-4 pb-2 md:flex-row md:items-center md:justify-between md:space-y-0">
      <div className="flex">
        <div className="relative flex">
          <div className="h-8 w-8 animate-pulse rounded-full bg-marginalGray-200"></div>
          <div className="absolute left-4 h-8 w-8 animate-pulse rounded-full bg-marginalGray-200"></div>
        </div>
        <div className="ml-8 flex flex-col space-y-1">
          <div className="h-4 w-32 animate-pulse rounded bg-marginalGray-200"></div>
          <div className="h-4 w-36 animate-pulse rounded bg-marginalGray-200"></div>
        </div>
      </div>
      <div className="flex h-10 space-x-4 md:w-80">
        <div className="w-full animate-pulse rounded-lg bg-marginalGray-200"></div>
        <div className="w-full animate-pulse rounded-lg bg-marginalGray-200"></div>
      </div>
    </div>
  )
}

const PositionDetailsLoading = () => {
  return (
    <div className="flex w-full flex-col space-y-4 rounded-3xl md:flex-row md:space-x-2 md:space-y-0">
      <div className="flex w-full space-x-10">
        <div className="h-4 w-24 min-w-16 animate-pulse rounded bg-marginalGray-600"></div>
        <div className="h-4 w-full min-w-16 animate-pulse rounded bg-marginalGray-200"></div>
      </div>
    </div>
  )
}

const PositionLinksLoading = () => {
  return (
    <div className="space-y-4 md:px-4 md:pb-4">
      <div className="h-6 w-28 animate-pulse rounded-xl bg-marginalGray-800"></div>
      <div className="flex flex-col space-y-6 sm:flex-row md:space-y-0">
        <div className="flex w-full flex-col flex-wrap justify-between gap-6 md:flex-row">
          {Array.from({ length: 4 }).map((_, index) => (
            <div
              key={index}
              className="flex w-full items-center justify-between space-x-6 md:w-min"
            >
              <div className="flex w-full items-center space-x-2 md:w-min">
                <div className="h-6 w-10 min-w-10 animate-pulse rounded-xl bg-marginalGray-800"></div>
                <div className="h-8 w-full min-w-10 animate-pulse rounded-xl bg-marginalGray-800 md:w-20"></div>
              </div>
              <div className="flex w-full items-center space-x-2 md:w-min">
                <div className="h-8 w-full min-w-10 animate-pulse rounded-xl bg-marginalGray-800 md:w-32"></div>
                <div className="h-8 w-8 min-w-8 animate-pulse rounded-xl bg-marginalGray-800"></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
