import { Address } from "viem"
import { PositionView } from "src/pages/positions/Positions"
import { MobilePositionRowContent } from "src/pages/positions/components/MobilePositionRowContent"
import { PositionRowContent } from "src/pages/positions/components/PositionRowContent"
import { type IgniteQuote } from "src/types"
import { type PositionState } from "src/pages/positions/hooks/usePositionsOnChainState"

export interface PositionRowProps {
  view: PositionView
  positionKey: string
  chainId: number
  tokenId: string
  positionData: PositionState
  pool?: any
  igniteParams?: {
    token0: Address
    token1: Address
    maintenance: string
    oracle: Address
    tokenId: string
  }
  igniteQuote: IgniteQuote
  uniswapV3PoolPrice?: bigint
  uniswapV3PoolFee?: bigint
  indexedPositionData?: any
}

export const PositionRow = ({
  view,
  positionKey,
  chainId,
  tokenId,
  positionData,
  pool,
  igniteParams,
  igniteQuote,
  uniswapV3PoolPrice,
  uniswapV3PoolFee,
  indexedPositionData,
}: PositionRowProps) => {
  return (
    <>
      <MobilePositionRowContent
        view={view}
        positionKey={positionKey}
        chainId={chainId}
        tokenId={tokenId}
        positionData={positionData}
        pool={pool}
        igniteParams={igniteParams}
        igniteQuote={igniteQuote}
        uniswapV3PoolPrice={uniswapV3PoolPrice}
        uniswapV3PoolFee={uniswapV3PoolFee}
        indexedPositionData={indexedPositionData}
      />
      <PositionRowContent
        view={view}
        positionKey={positionKey}
        chainId={chainId}
        tokenId={tokenId}
        positionData={positionData}
        pool={pool}
        igniteParams={igniteParams}
        igniteQuote={igniteQuote}
        uniswapV3PoolPrice={uniswapV3PoolPrice}
        uniswapV3PoolFee={uniswapV3PoolFee}
        indexedPositionData={indexedPositionData}
      />
    </>
  )
}
