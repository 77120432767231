import { calculatePriceImpact } from "src/utils/conversions/calculatePriceImpact"
import { convertX96PricesToBigInt } from "src/utils/conversions/convertX96Price"
import { getLbpSwapParamsWithSlippage } from "./getLbpSwapParamsWithSlippage"
import { ExactInputSingleQuote } from "src/types"

interface DerivedLBPSwapQuoteData {
  priceImpact: number | null
  derivedSwapParamsWithSlippage: any // Replace 'any' with actual type from getLBPSwapParamsWithSlippage
  swapQuoteError: string | undefined
}

export const deriveLbpSwapQuoteData = (
  swapQuote: ExactInputSingleQuote | null,
  poolSqrtPriceX96: string,
  zeroForOne: boolean,
  maxSlippage: string,
  quoteParams: any, // Replace 'any' with actual type from quoteExactInputSingleParams
): DerivedLBPSwapQuoteData => {
  const priceImpact = calculatePriceImpact(
    swapQuote?.sqrtPriceX96After,
    BigInt(poolSqrtPriceX96),
    zeroForOne,
  )

  const derivedSwapParamsWithSlippage = getLbpSwapParamsWithSlippage(
    quoteParams,
    swapQuote,
    zeroForOne,
    convertX96PricesToBigInt(BigInt(poolSqrtPriceX96))?.toString(),
    maxSlippage,
  )

  const swapQuoteError = checkSwapQuoteError(priceImpact, maxSlippage)

  return {
    priceImpact,
    derivedSwapParamsWithSlippage,
    swapQuoteError,
  }
}

const checkSwapQuoteError = (
  priceImpact: number | null | undefined,
  maxSlippage: string | undefined,
): string | undefined => {
  if (!priceImpact || !maxSlippage) {
    return undefined
  }

  if (
    priceImpact &&
    maxSlippage &&
    Math.abs(parseFloat(priceImpact.toString())) > Math.abs(parseFloat(maxSlippage))
  ) {
    return "Slippage exceeds tolerance"
  }

  return undefined
}
