import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit"
import { Token } from "src/types"
import { Address } from "viem"
import { LbpExactInputSingleParams } from "src/types"
import {
  getLbpExactInputSingleQuote,
  LBPExactInputSingleQuote,
} from "src/pages/lbp/helpers/getLbpExactInputSingleQuote"

export enum FetchState {
  IDLE = "idle",
  LOADING = "loading",
  VALID = "valid",
  INVALID = "invalid",
}

export const EMPTY_LBP_QUOTE: LBPExactInputSingleQuote | null = null

export interface LBPState {
  inputValue: string
  inputToken: Token | null
  outputToken: Token | null
  exactInputSingleQuote: LBPExactInputSingleQuote | null
  status: FetchState
  error: any
  isBuy: boolean
}

export const initialState: LBPState = {
  inputValue: "",
  inputToken: null,
  outputToken: null,
  exactInputSingleQuote: EMPTY_LBP_QUOTE,
  status: FetchState.IDLE,
  error: null,
  isBuy: true,
}

export const fetchLbpExactInputSingleQuote = createAsyncThunk(
  "lbp/fetchLbpExactInputSingleQuote",
  async ({
    chainId,
    quoterAddress,
    params,
  }: {
    chainId: number
    quoterAddress: Address
    params: LbpExactInputSingleParams
  }) => {
    const quote = await getLbpExactInputSingleQuote(chainId, quoterAddress, params)
    if (!quote) {
      throw new Error("Failed to fetch LBP swap quote")
    }
    return quote
  },
)

export const lbpSlice = createSlice({
  name: "lbp",
  initialState,
  reducers: {
    setInputValue: (state: LBPState, action: PayloadAction<string>) => {
      state.inputValue = action.payload
    },
    setInputToken: (state: LBPState, action: PayloadAction<Token | null>) => {
      state.inputToken = action.payload
    },
    setOutputToken: (state: LBPState, action: PayloadAction<Token | null>) => {
      state.outputToken = action.payload
    },
    setIsBuy: (state: LBPState, action: PayloadAction<boolean>) => {
      state.isBuy = action.payload
    },
    resetLbpQuote: (state) => {
      state.exactInputSingleQuote = EMPTY_LBP_QUOTE
      state.status = FetchState.IDLE
      state.error = null
    },
    resetLbpState: () => {
      return initialState
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLbpExactInputSingleQuote.pending, (state) => {
        state.status = FetchState.LOADING
      })
      .addCase(
        fetchLbpExactInputSingleQuote.fulfilled,
        (state, action: PayloadAction<LBPExactInputSingleQuote>) => {
          state.exactInputSingleQuote = action.payload
          state.status = FetchState.VALID
          state.error = null
        },
      )
      .addCase(fetchLbpExactInputSingleQuote.rejected, (state, action) => {
        state.exactInputSingleQuote = EMPTY_LBP_QUOTE
        state.status = FetchState.INVALID
        state.error = action.payload
      })
  },
})

export const {
  setInputValue,
  setInputToken,
  setOutputToken,
  setIsBuy,
  resetLbpQuote,
  resetLbpState,
} = lbpSlice.actions

export default lbpSlice.reducer
