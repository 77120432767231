import { isNull } from "lodash"
import { useEffect, useMemo, useState } from "react"
import { Address, encodeFunctionData, zeroAddress } from "viem"
import { useCreatePoolState } from "src/state/createPool/hooks"
import { getContractError } from "src/utils/getContractError"
import { UNISWAP_V3_POOL_ABI } from "src/constants/abis/Uniswapv3Pool"
import {
  useAccount,
  useBlock,
  useEstimateGas,
  useSimulateContract,
  useWriteContract,
} from "wagmi"

const useInitializeOracle = () => {
  const { uniswapPool } = useCreatePoolState()
  const { chain } = useAccount()
  const [error, setError] = useState<any>(undefined)

  const {
    data: block,
    isLoading: isBlockLoading,
    isError: isBlockError,
  } = useBlock({
    scopeKey: chain?.id.toString(),
  })

  const {
    data: gas,
    isLoading: isGasLoading,
    isError: isGasError,
  } = useEstimateGas({
    data: encodeFunctionData({
      abi: UNISWAP_V3_POOL_ABI,
      functionName: "increaseObservationCardinalityNext",
      args: [(uniswapPool?.obsCarMin as number) ?? 0],
    }),
    to: uniswapPool?.address,
    query: {
      enabled:
        !isNull(uniswapPool) &&
        Boolean(uniswapPool) &&
        Boolean(uniswapPool?.address !== zeroAddress) &&
        Boolean(uniswapPool?.obsCarMin) &&
        Boolean(uniswapPool?.obsCarNext),
    },
  })

  const isGasLimitExceeded = useMemo(() => {
    if (block && gas) {
      if (Number(gas) > Number(block.gasLimit)) {
        return true
      }
    }
    return false
  }, [gas, block])

  const {
    data,
    isError,
    error: simulationError,
  } = useSimulateContract({
    address: uniswapPool?.address as Address,
    abi: UNISWAP_V3_POOL_ABI,
    functionName: "increaseObservationCardinalityNext",
    args: [
      isGasLimitExceeded
        ? Number(uniswapPool?.obsCarMin) / 2
        : Number(uniswapPool?.obsCarMin),
    ],
    query: {
      enabled:
        Boolean(uniswapPool) &&
        Boolean(uniswapPool?.address !== zeroAddress) &&
        Boolean(uniswapPool?.obsCarMin) &&
        Boolean(uniswapPool?.obsCarNext),
    },
  })

  useEffect(() => {
    if (isError) {
      let contractError = getContractError(
        simulationError,
        "createAndInitializePoolIfNecessary",
      )
      if (contractError) {
        console.error(contractError)
        setError(contractError)
      }
    } else {
      setError(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError])

  const {
    data: contractData,
    isLoading,
    isSuccess,
    writeContract,
    writeContractAsync,
  } = useWriteContract()

  return {
    initializeOracleAsync: () => writeContractAsync(data!.request),
    initializeOracle: writeContract,
    isLoading,
    data: contractData,
    isSuccess,
    error,
    isGasLimitExceeded,
    isGasLoading: isBlockLoading || isGasLoading,
    isGasError: isBlockError || isGasError,
  }
}

export default useInitializeOracle
