import { Fragment, useRef } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { XMarkIcon } from "@heroicons/react/24/outline"
import useCloseOnExternalClick from "src/hooks/useCloseOnExternalClick"

interface ModalProps {
  header: string
  isOpen: boolean
  onClose: () => void
  children: React.ReactNode
}

export const Modal = ({ header, isOpen, onClose, children }: ModalProps) => {
  const dialogRef = useRef(null)
  const cancelButtonRef = useRef(null)
  useCloseOnExternalClick(dialogRef, isOpen ? onClose : () => null)

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-opacity-80 backdrop-blur-sm transition-opacity bg-marginalBlack" />
        </Transition.Child>

        <div className="overflow-y-auto fixed inset-0 z-10 w-screen">
          <div className="flex justify-center items-end min-h-full md:items-center md:p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                ref={dialogRef}
                className="relative min-h-[80vh] w-full transform overflow-hidden border border-marginalGray-800 bg-marginalGray-950 text-left shadow-xl transition-all md:my-8 md:min-h-0 md:max-w-sm"
              >
                <div className="flex flex-col">
                  <div className="flex justify-between items-center p-3">
                    <h1 className="text-lg">{header}</h1>
                    <XMarkIcon
                      onClick={() => onClose()}
                      className="w-5 h-5 cursor-pointer stroke-2 hover:opacity-80"
                      aria-hidden="true"
                    />
                  </div>
                  {children}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
