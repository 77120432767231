import { useCallback, useState } from "react"
import { NavLink } from "react-router-dom"
import { DOCS_URL } from "src/constants/links"
import { MenuIcon } from "src/components/Icons/MenuIcon"
import { CloseIcon } from "src/components/Icons/CloseIcon"
import { BrandLogo } from "src/components/Header/BrandLogo"
import { CustomConnectButton } from "src/components/Header/CustomConnectButton"

const MENU_ITEMS = [
  { to: "/", label: "trade" },
  { to: "/swap", label: "swap" },
  { to: "/positions", label: "positions" },
  { to: "/pools", label: "pools" },
  { to: "/launch", label: "launch" },
  { to: DOCS_URL, label: "docs", external: true },
]

export const MobileMenu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const toggleMenu = useCallback(() => {
    setIsMenuOpen((prev) => !prev)
  }, [])

  return (
    <div className="relative">
      <button
        onClick={toggleMenu}
        aria-label="Toggle Mobile Menu"
        className="rounded-md p-2 hover:bg-marginalGray-800 focus:outline-none"
      >
        <MenuIcon className="h-8 w-8 cursor-pointer" />
      </button>

      {isMenuOpen && (
        <div className="fixed inset-0 z-50 h-fit w-screen bg-marginalGray-950">
          <div className="flex h-20 place-content-between items-center px-4 py-4">
            <BrandLogo />
            <button
              onClick={toggleMenu}
              aria-label="Close Mobile Menu"
              className="rounded-md p-2 hover:bg-marginalGray-800 focus:outline-none"
            >
              <CloseIcon className="h-8 w-8 cursor-pointer" />
            </button>
          </div>
          <nav
            className="flex flex-col items-start justify-start gap-2 pb-4"
            onClick={(e) => e.stopPropagation()}
          >
            {MENU_ITEMS.map((item) => (
              <NavLink
                key={item.to}
                to={item.to}
                target={item.external ? "_blank" : undefined}
                className={({ isActive }) =>
                  `px-4 py-2 text-2xl font-bold ${
                    isActive ? "text-marginalOrange-500" : "text-marginalGray-100/50"
                  } `
                }
                onClick={toggleMenu}
              >
                {item.label}
              </NavLink>
            ))}
          </nav>
          <div className="flex items-center bg-marginalBlack/40 px-2 py-4">
            <CustomConnectButton />
          </div>
        </div>
      )}
    </div>
  )
}
