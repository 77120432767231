import { formatBigIntToString } from "src/utils/formatBigIntToString"
import { formatNumberAmount } from "src/utils/formatNumberAmount"
import { getRemoveLiquidityParamsWithSlippage } from "./getRemoveLiquidityParamsWithSlippage"
import { RemoveLiquidityQuote, RemoveLiquidityParams } from "src/types"
import { Token } from "src/types"

interface DerivedRemoveLiquidityQuoteData {
  token0Amount: {
    raw: bigint | undefined
    parsed: string | undefined
    formatted: string | null | undefined
  }
  token1Amount: {
    raw: bigint | undefined
    parsed: string | undefined
    formatted: string | null | undefined
  }
  shares: {
    raw: bigint | undefined
    parsed: string | undefined
    formatted: string | null | undefined
  }
  derivedRemoveLiquidityParamsWithSlippage: RemoveLiquidityParams | null
  quotedSharesFormatted: string | null | undefined
}

export const deriveRemoveLiquidityQuoteData = (
  removeLiquidityQuote: RemoveLiquidityQuote | null,
  token0: Token | null | undefined,
  token1: Token | null | undefined,
  poolDecimals: string | undefined,
  maxSlippage: string,
  removeLiquidityParams: RemoveLiquidityParams | null,
): DerivedRemoveLiquidityQuoteData => {
  // Process token0 amount data
  const token0Amount = {
    raw: removeLiquidityQuote?.amount0,
    parsed: formatBigIntToString(removeLiquidityQuote?.amount0, token0?.decimals),
    formatted: formatNumberAmount(
      formatBigIntToString(removeLiquidityQuote?.amount0, token0?.decimals),
      true,
    ),
  }

  // Process token1 amount data
  const token1Amount = {
    raw: removeLiquidityQuote?.amount1,
    parsed: formatBigIntToString(removeLiquidityQuote?.amount1, token1?.decimals),
    formatted: formatNumberAmount(
      formatBigIntToString(removeLiquidityQuote?.amount1, token1?.decimals),
      true,
    ),
  }

  // Process shares data
  const shares = {
    raw: removeLiquidityQuote?.liquidityAfter,
    parsed: formatBigIntToString(removeLiquidityQuote?.liquidityAfter, poolDecimals),
    formatted: formatNumberAmount(
      formatBigIntToString(removeLiquidityQuote?.liquidityAfter, poolDecimals),
      true,
    ),
  }

  const derivedRemoveLiquidityParamsWithSlippage = getRemoveLiquidityParamsWithSlippage(
    removeLiquidityParams,
    removeLiquidityQuote,
    maxSlippage,
  )

  return {
    token0Amount,
    token1Amount,
    shares,
    derivedRemoveLiquidityParamsWithSlippage,
    quotedSharesFormatted: shares.formatted,
  }
}
