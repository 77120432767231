import { readContract } from "@wagmi/core"
import { Address } from "viem"
import { wagmiConfig } from "src/wagmiConfig"
import marginalV1LBQuoterAbi from "src/constants/abis/MarginalV1LBQuoter.json"
import { LbpExactInputSingleParams } from "src/types"

export type LBPExactInputSingleQuote = {
  amountOut: bigint
  liquidityAfter: bigint
  sqrtPriceX96After: bigint
}

export async function getLbpExactInputSingleQuote(
  chainId: number,
  quoterAddress: Address,
  params: LbpExactInputSingleParams,
): Promise<LBPExactInputSingleQuote | null> {
  try {
    const data = await readContract(wagmiConfig, {
      chainId,
      address: quoterAddress,
      abi: marginalV1LBQuoterAbi,
      functionName: "quoteExactInputSingle",
      args: [params],
    })

    if (!Array.isArray(data)) {
      return null
    }

    return {
      amountOut: data[1],
      liquidityAfter: data[2],
      sqrtPriceX96After: data[3],
    }
  } catch (error) {
    console.error("Error getting LBP quote:", error)
    return null
  }
}
