import { isNull } from "lodash"
import { ReactNode } from "react"
import { Modal } from "src/components/ui/Modal"
import { ListRow } from "src/components/ui/ListRow"
import { TradeButton } from "src/pages/trade/components/TradeButton"
import { MintQuote, type PoolData, type Token } from "src/types"
import { ErrorIcon } from "src/components/Icons/ErrorIcon"
import { SpinningLoader } from "src/components/Icons/SpinningLoader"
import { HealthFactorIcon } from "src/components/Icons/HealthFactorIcon"
import { DoubleTokenLogo } from "src/components/TokenBadge"
import { AssetPairPriceRatio } from "../../../components/AssetPairPriceRatio"
import { ExplorerDataType, getExplorerLink } from "src/utils/getExplorerLink"
import { getPriceImpactIndicatorColor } from "src/pages/trade/helpers/getPriceImpactIndicatorColor"
import { CheckIcon } from "src/components/Icons/CheckIcon"
import { ModalStatusView } from "src/components/ui/ModalStatusView"

interface Props {
  chainId: number
  marginToken: Token | null
  debtToken: Token | null
  pool: PoolData | null
  token0: Token | null
  token1: Token | null
  leverage: number
  leverageMax: number | undefined | null
  useGas: boolean
  gasToken: Token | null | undefined

  open: boolean
  onOpen: () => void
  onClose: () => void
  onReset: () => void

  quotedMargin: string | null | undefined
  quotedDebt: string | null | undefined
  quotedSize: string | null | undefined
  quotedFees: string | null | undefined
  mintQuote: MintQuote | null
  mintCallback: (() => Promise<any>) | undefined

  isPendingWallet: boolean
  isPendingApprove: boolean
  isPendingTx: boolean
  isTxSubmitted: boolean
  txHash: string | null
  txError: any

  poolPrice: string | null | undefined
  oraclePrice: string | null | undefined
  liquidationPrice: string | null | undefined
  fundingRate: string | number | null | undefined
  escrowRewards: string | null | undefined
  priceImpact: number | null
  healthFactor: number | null

  useInverse: boolean
  onToggleInverse: () => void
}

export const ConfirmTradeModal = ({
  chainId,
  marginToken,
  debtToken,
  pool,
  token0,
  token1,
  leverage,
  leverageMax,
  useGas,
  gasToken,

  open,
  onOpen,
  onClose,
  onReset,

  quotedMargin,
  quotedDebt,
  quotedSize,
  quotedFees,
  mintQuote,
  mintCallback,

  isPendingWallet,
  isPendingApprove,
  isPendingTx,
  isTxSubmitted,
  txHash,
  txError,

  poolPrice,
  oraclePrice,
  liquidationPrice,
  fundingRate,
  escrowRewards,
  priceImpact,
  healthFactor,

  useInverse,
  onToggleInverse,
}: Props) => {
  const priceImpactIndicator = getPriceImpactIndicatorColor(priceImpact)

  const fundingRateIndicator =
    Math.abs(Number(fundingRate)) > 1
      ? "text-warning-500"
      : Math.abs(Number(fundingRate)) > 5
        ? "text-error-500"
        : "text-marginalGray-200"

  let healthFactorIndicator

  if (isNull(healthFactor)) {
    healthFactorIndicator = "text-white"
  } else if (healthFactor <= 1.25) {
    healthFactorIndicator = "text-error-500"
  } else if (healthFactor <= 1.5) {
    healthFactorIndicator = "text-warning-500"
  } else if (1.5 < healthFactor) {
    healthFactorIndicator = "text-success-500"
  }

  return (
    <Modal
      header="Review Trade"
      isOpen={open}
      onClose={txHash ? onReset : isPendingTx ? () => null : onClose}
    >
      {txError ? (
        <ModalStatusView
          icon={<ErrorIcon />}
          primaryText="Trade failed"
          secondaryText="Something went wrong"
          buttonText="Close"
          onClick={onReset}
        />
      ) : isPendingWallet ? (
        <ModalStatusView
          icon={<SpinningLoader />}
          primaryText="Confirm trade"
          secondaryText="Proceed in your wallet"
        />
      ) : isPendingTx ? (
        <ModalStatusView
          icon={<SpinningLoader />}
          primaryText="Transaction submitted"
          secondaryText=" Waiting for transaction to finalize"
        />
      ) : !isPendingTx && txHash ? (
        <ModalStatusView
          icon={<CheckIcon />}
          primaryText="Trade executed"
          buttonText="Close"
          onClick={onReset}
          blockExplorerUrl={
            txHash ? getExplorerLink(chainId, txHash, ExplorerDataType.TRANSACTION) : ""
          }
        />
      ) : (
        <div className="flex w-full flex-col items-center space-y-3 p-4">
          <DetailsContainer>
            <div className="flex flex-col space-y-3">
              <header className="flex items-center gap-2">
                <DoubleTokenLogo
                  token0={token0 as Token}
                  token1={token1 as Token}
                  size={8}
                />
                <div className="flex flex-wrap items-baseline space-x-2">
                  <div className="flex items-center text-xl font-bold text-marginalGray-200">
                    <span>{token0?.symbol}</span>
                    <span>/</span>
                    <span>{token1?.symbol}</span>
                  </div>
                </div>
              </header>

              <div className="flex flex-col md:flex-row md:items-center md:justify-between md:space-x-8">
                <AssetPairPriceRatio
                  token0={token0}
                  token1={token1}
                  price={poolPrice}
                  useInverse={useInverse}
                  onToggleInverse={onToggleInverse}
                />
              </div>
            </div>

            <div className="my-4 h-px bg-marginalGray-200/20" />

            <div className="space-y-1">
              <ListRow
                item="Health Factor"
                value={
                  <div className="flex items-baseline space-x-1 text-marginalGray-200">
                    <HealthFactorIcon />
                    <div className={`${healthFactorIndicator}`}>{healthFactor}</div>
                  </div>
                }
              />
              <ListRow
                item="Pool price"
                value={
                  <div className="flex items-baseline space-x-1 text-marginalGray-200">
                    <span>{poolPrice}</span>
                    {!useInverse ? (
                      <div className="flex items-center">
                        <span>{token1?.symbol}</span>
                        <span>/</span>
                        <span>{token0?.symbol}</span>
                      </div>
                    ) : (
                      <div className="flex items-center">
                        <span>{token0?.symbol}</span>
                        <span>/</span>
                        <span>{token1?.symbol}</span>
                      </div>
                    )}
                  </div>
                }
              />
              <ListRow
                item="Oracle price"
                value={
                  <div className="flex items-baseline space-x-1 text-marginalGray-200">
                    <span>{oraclePrice}</span>
                    {!useInverse ? (
                      <div className="flex items-center">
                        <span>{token1?.symbol}</span>
                        <div>/</div>
                        <span>{token0?.symbol}</span>
                      </div>
                    ) : (
                      <div className="flex items-center">
                        <span>{token0?.symbol}</span>
                        <div>/</div>
                        <span>{token1?.symbol}</span>
                      </div>
                    )}
                  </div>
                }
              />
              <ListRow
                item="Funding rate"
                value={
                  <div
                    className={`flex items-baseline space-x-1 ${fundingRateIndicator}`}
                  >
                    <span>{fundingRate}% weekly</span>
                  </div>
                }
              />
              <ListRow
                item="Size"
                value={
                  <div className="flex items-baseline space-x-1 text-marginalGray-200">
                    <span>{quotedSize}</span>
                    <span>{marginToken?.symbol}</span>
                  </div>
                }
              />
            </div>

            <div className="my-4 h-px bg-marginalGray-200/20" />

            <div className="space-y-1">
              <ListRow
                item="Liquidation price"
                value={
                  <div className="flex items-baseline space-x-1 text-marginalGray-200">
                    <span>{liquidationPrice}</span>
                    {!useInverse ? (
                      <div className="flex items-center">
                        <span>{token1?.symbol}</span>
                        <div>/</div>
                        <span>{token0?.symbol}</span>
                      </div>
                    ) : (
                      <div className="flex items-center">
                        <span>{token0?.symbol}</span>
                        <div>/</div>
                        <span>{token1?.symbol}</span>
                      </div>
                    )}
                  </div>
                }
              />
              <ListRow
                item="Collateral"
                value={
                  <div className="flex items-baseline space-x-1 text-marginalGray-200">
                    <span>{quotedMargin}</span>
                    <span>{marginToken?.symbol}</span>
                  </div>
                }
              />

              <ListRow
                item="Debt"
                value={
                  <div className="flex items-baseline space-x-1 text-marginalGray-200">
                    <span>{quotedDebt}</span>
                    <span>{debtToken?.symbol}</span>
                  </div>
                }
              />
            </div>
          </DetailsContainer>

          <DetailsContainer>
            <div className="mb-4 text-marginalGray-200">Execution Details</div>
            <div className="space-y-1">
              <ListRow
                item="Impact"
                value={
                  <div
                    className={`flex items-baseline space-x-1 ${priceImpactIndicator}`}
                  >
                    <span>{priceImpact?.toFixed(2)}%</span>
                  </div>
                }
              />
              <ListRow
                item="Leverage"
                value={
                  <div className="flex items-baseline space-x-1 text-marginalGray-200">
                    <span>{leverage}x</span>
                  </div>
                }
              />
              <ListRow
                item="Max leverage"
                value={
                  <div className="flex items-baseline space-x-1 text-marginalGray-200">
                    {leverageMax}x
                  </div>
                }
              />
            </div>

            <div className="my-4 h-px bg-marginalGray-200/20" />

            <div className="space-y-1">
              <ListRow
                item="Escrow Rewards"
                value={
                  <div className="flex items-baseline space-x-1 text-marginalGray-200">
                    <span>{escrowRewards}</span>
                    <span className="text-xs">ETH</span>
                  </div>
                }
              />
              <ListRow
                item="Fee"
                value={
                  <div className="flex items-baseline space-x-1 text-marginalGray-200">
                    <span>{quotedFees}</span>
                    <span className="text-xs">
                      {useGas ? gasToken?.symbol : marginToken?.symbol}
                    </span>
                  </div>
                }
              />
            </div>
          </DetailsContainer>
          <TradeButton
            mintCallback={mintCallback}
            isPendingWallet={isPendingWallet}
            isPendingApprove={isPendingApprove}
            isPendingTx={isPendingTx}
            isTxSubmitted={isTxSubmitted}
            txError={txError}
          />
        </div>
      )}
    </Modal>
  )
}

const DetailsContainer = ({ children }: { children: ReactNode }) => {
  return (
    <div className="h-fit w-full bg-marginalBlack p-4 text-xs text-marginalGray-600">
      {children}
    </div>
  )
}
