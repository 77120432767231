import { Token } from "src/types"
import { isAddress } from "viem"

export const validateFreeParams = (
  token0: Token | undefined | null,
  token1: Token | undefined | null,
  maintenance: bigint | undefined,
  oracle: string | undefined,
  tokenId: string | undefined,
  marginOut: string | undefined,
  marginToken: Token | undefined | null,
  recipient: string | undefined,
): boolean => {
  if (!token0 || !token1 || !marginToken) {
    return false
  }
  if (!token0.address || !token1.address || !marginToken.address) {
    return false
  }
  if (!isAddress(token0.address) || !isAddress(token1.address)) {
    return false
  }
  if (!oracle || !isAddress(oracle)) {
    return false
  }
  if (!maintenance) {
    return false
  }
  if (!tokenId) {
    return false
  }
  if (!marginOut) {
    return false
  }
  if (!recipient || !isAddress(recipient)) {
    return false
  }
  return true
}
