import { RemoveLiquidityQuote, RemoveLiquidityParams } from "src/types"
import { formatStringToBigInt } from "src/utils/formatStringToBigInt"

const HUNDRED_PERCENT = "100"
const DECIMALS = 18
const ONE = 1000000000000000000n

interface SlippageCalculationParams {
  value: bigint
  slippagePercent: string
}

const calculateSlippageAdjustedValue = ({
  value,
  slippagePercent,
}: SlippageCalculationParams): bigint | undefined => {
  // For remove liquidity, we subtract slippage from both amounts
  const effectivePercent = (100 - parseFloat(slippagePercent)).toString()

  const slippageNumerator = formatStringToBigInt(effectivePercent, DECIMALS)
  const slippageDenominator = formatStringToBigInt(HUNDRED_PERCENT, DECIMALS)

  if (!ONE || !slippageNumerator || !slippageDenominator) {
    return undefined
  }

  const effectivePercentage = (slippageNumerator * ONE) / slippageDenominator
  return (value * effectivePercentage) / ONE
}

const convertToFloorValue = (value: bigint | undefined): bigint => {
  if (!value) return 0n
  return BigInt(Math.floor(Number(value.toString())))
}

export const getRemoveLiquidityParamsWithSlippage = (
  removeLiquidityParams: RemoveLiquidityParams | null,
  removeLiquidityQuote: RemoveLiquidityQuote | null,
  maxSlippage: string,
): RemoveLiquidityParams | null => {
  if (!removeLiquidityParams || !removeLiquidityQuote) {
    return null
  }

  const amount0 = BigInt(removeLiquidityQuote.amount0.toString())
  const amount1 = BigInt(removeLiquidityQuote.amount1.toString())

  // Calculate minimum amounts with slippage
  const amount0Min = calculateSlippageAdjustedValue({
    value: amount0,
    slippagePercent: maxSlippage,
  })

  const amount1Min = calculateSlippageAdjustedValue({
    value: amount1,
    slippagePercent: maxSlippage,
  })

  return {
    ...removeLiquidityParams,
    amount0Min: convertToFloorValue(amount0Min),
    amount1Min: convertToFloorValue(amount1Min),
  }
}
