interface PercentageCalculationInput {
  total: number
  partial: number
}

/**
 * Validates input numbers for percentage calculation
 * @param total The total number
 * @param partial The partial number
 * @returns boolean indicating if inputs are valid
 */
const isValidInput = (total: number, partial: number): boolean => {
  return (
    !isNaN(total) &&
    !isNaN(partial) &&
    isFinite(total) &&
    isFinite(partial) &&
    total !== 0
  )
}

/**
 * Calculates the percentage that partial represents of total
 * @param input Object containing total and partial numbers
 * @returns Percentage value or null if inputs are invalid
 */
export const calculatePercentageOfTotal = (
  input: PercentageCalculationInput,
): number | null => {
  const { total, partial } = input

  if (!isValidInput(total, partial)) {
    return null
  }

  return (partial / total) * 100
}

export const calculatePercentageOfTotalBigInt = (
  total: bigint,
  partial: bigint,
  decimals: number = 2,
) => {
  if (total === 0n || partial === 0n) {
    return 0
  }

  // Scale up the partialAmount for precision
  const scaleFactor = 10n ** BigInt(decimals) // +2 for the %
  const percentage = (BigInt(partial) * scaleFactor * 100n) / BigInt(total)

  // Convert the scaled percentage to a number with correct decimal places
  return parseInt(percentage.toString()) / 10 ** decimals
}
