import { PoolData, Token } from "src/types"
import { getAddress } from "viem"
import { convertInputToSize } from "src/utils/conversions/convertInputToSize"
import { AddLiquidityParams } from "src/types"

const MAX_DEADLINE =
  115792089237316195423570985008687907853269984665640564039457584007913129639935n

export const constructAddLiquidityQuoteParams = (
  pool: PoolData,
  token0: Token,
  token1: Token,
  input0: string,
  input1: string,
  recipient: string,
  deadline: string,
  currentBlockTimestamp?: bigint,
): AddLiquidityParams => {
  return {
    token0: getAddress(pool.token0.address),
    token1: getAddress(pool.token1.address),
    maintenance: pool.maintenance,
    oracle: getAddress(pool.oracleAddress),
    recipient: getAddress(recipient),
    amount0Desired: convertInputToSize(input0, token0.decimals) ?? 0n,
    amount1Desired: convertInputToSize(input1, token1.decimals) ?? 0n,
    amount0Min: 0n,
    amount1Min: 0n,
    deadline: currentBlockTimestamp
      ? currentBlockTimestamp + BigInt(deadline) * BigInt("60")
      : MAX_DEADLINE,
  }
}
