import _ from "lodash"
import { Token } from "../types"
import { useGetLBPoolsQuery } from "./useLBPoolsQuery"
import {
  MarginalV1LbPool,
  MarginalV1LbPoolSwap,
  MarginalV1LbPosition,
} from "src/state/api/generated-lb"
import moment from "moment"
import { useGetLBPoolQuery } from "./useLBPoolsQuery"
import { formatBigIntToString } from "src/utils/formatBigIntToString"
import { ExplorerDataType, getExplorerLink } from "src/utils/getExplorerLink"
import { calcRangeAmountsFromLiquiditySqrtPriceX96 } from "src/utils/conversions/calculatePoolLiquidityTokenAmounts"
import { usePoolTotalSupplySingle } from "./usePoolTotalSupplySingle"
import { convertX96Price } from "src/utils/conversions/convertX96Price"
import { calculatePercentageOfTotalBigInt } from "src/utils/conversions/calculatePercentageOfTotal"

export const useLBPoolsData = () => {
  const { pools, isLoading } = useGetLBPoolsQuery()
  const poolsData = useConstructedPoolsData(pools)
  // const poolsDataByAddress = usePoolsDataByAddress(poolsData)
  // const tokensFromPools = useTokensFromPools(poolsData)

  return {
    pools: poolsData,
    isLoading,
    // poolsDataByAddress,
    // tokensFromPools,
  }
}

enum TokenType {
  LAUNCH = "LAUNCH",
  BASE = "BASE",
}

export interface LaunchProjectProps extends LaunchItemProps {
  transactions: LaunchProjectTransactionProps[]
  holders: LaunchProjectHoldersProps[]
}

export interface LaunchProjectTransactionProps {
  type: string
  price: string
  input: string
  output: string
  inputToken: Token
  outputToken: Token
  maker: string
  time: string
  explorerLink: string
}

export interface LaunchProjectHoldersProps {
  rank: string
  holder: string
  tokens: string
  allocation: string
}

interface LaunchItemStatsAmountProps {
  launchToken: string
  baseToken: string
}

export interface LaunchItemStatsProps {
  startBalance: LaunchItemStatsAmountProps
  endBalance: LaunchItemStatsAmountProps
  currentBalance: LaunchItemStatsAmountProps
}

export interface LaunchItemProps {
  address: string
  name: string
  startedIn: string
  startDate: string
  endIn: string
  endDate: string
  price: string
  rawPrice: string | undefined
  totalVolume: string
  liquidity: string
  logoURI: string
  status: string
  statusTime: string
  released: string
  fundsRaised: string
  launchToken: Token
  baseToken: Token
  swapFee: string
  stats: LaunchItemStatsProps
  marketCap: string

  launchTokenIndex: number

  sqrtPriceX96: string

  supplier: string
  blockTimestampInitialize: string

  tickLower: number
  tickUpper: number
}

export const getPoolBaseIndex = (pool: MarginalV1LbPool): number => {
  // // Check which token is the base token, for that we will be checking the mint amounts
  // // the one that is none zero is the launch token
  // const baseTokenIndex = pool.mint?.amount0 === "0" ? 0 : 1

  // return baseTokenIndex

  return pool.launchTokenIndex === 0 ? 1 : 0
}

const amountHelper = (
  amountToken0: bigint,
  amountToken1: bigint,
  pool: MarginalV1LbPool,
  type: TokenType,
): bigint => {
  const baseTokenIndex = getPoolBaseIndex(pool)

  // based on type and index, return the amount
  if (type === TokenType.BASE) {
    return baseTokenIndex === 0 ? BigInt(amountToken0) : BigInt(amountToken1)
  } else {
    return baseTokenIndex === 0 ? BigInt(amountToken1) : BigInt(amountToken0)
  }
}

const poolTokenHelper = (pool: MarginalV1LbPool, type: TokenType): Token => {
  const baseTokenIndex = getPoolBaseIndex(pool)
  // based on type and index, return the token
  return baseTokenIndex === 0
    ? type === TokenType.BASE
      ? pool.token0
      : pool.token1
    : type === TokenType.BASE
      ? pool.token1
      : pool.token0
}

const formatAmountTokenDecimalsHelper = (
  amountToken0: bigint,
  amountToken1: bigint,
  pool: MarginalV1LbPool,
  type: TokenType,
  decimalPlaces?: number,
): string => {
  const amount = amountHelper(amountToken0, amountToken1, pool, type)
  const token = poolTokenHelper(pool, type)

  return formatAmountTokenDecimals(amount, token, decimalPlaces)
}

const formatAmountTokenDecimals = (
  amount: bigint,
  token: Token,
  decimalPlaces: number = 8,
): string => {
  return formatBigIntToString(amount, token.decimals, decimalPlaces)! + " " + token.symbol
}

export const useLBPoolData = (poolAddress: string | undefined, chainId: number) => {
  const { pool, refetch } = useGetLBPoolQuery(poolAddress!)
  const poolData = useConstructedPoolData(pool, chainId)

  return {
    pool: poolData,
    refetch,
  }
}

function useConstructedPoolData(
  pool: any | undefined,
  chainId: number,
): LaunchProjectProps | null {
  let _tokenAddress = ""

  if (pool) {
    const _baseIndex = getPoolBaseIndex(pool)
    _tokenAddress = _baseIndex === 1 ? pool.token0.id : pool.token1.id
  }

  const totalSupply = usePoolTotalSupplySingle(_tokenAddress)

  if (!pool) {
    return null
  }

  return {
    ...constructPoolDataFromQuery(pool),
    transactions: constructTransactionsDataFromQuery(pool, chainId),
    holders: constructHoldersDataFromQuery(pool, totalSupply),
  }
}

function constructTransactionsDataFromQuery(
  pool: MarginalV1LbPool,
  chainId: number,
): LaunchProjectTransactionProps[] {
  const basePoolIndex = getPoolBaseIndex(pool)

  return pool.swaps?.map((swap: MarginalV1LbPoolSwap) => {
    // The negative value is the output
    let output = BigInt(swap.amount0) < 0 ? BigInt(swap.amount0) : BigInt(swap.amount1)
    let input = BigInt(swap.amount0) > 0 ? BigInt(swap.amount0) : BigInt(swap.amount1)

    // convert to absolute value
    output = output < 0 ? -output : output
    input = input < 0 ? -input : input

    const outputToken = BigInt(swap.amount0) < 0 ? pool.token0 : pool.token1
    const inputToken = BigInt(swap.amount0) > 0 ? pool.token0 : pool.token1

    // If the launch token is the output, then the swap is a buy
    // If the launch token is the input, then the swap is a sell
    // We should use baseTokenIndex to determine if the
    // base token is in amount0 or amount1

    let type = "Buy"
    // If the base token index is 1, this means
    // that the base token is in amount1. If the amount0
    // is negative, we are buying the launch token from the pool
    if (basePoolIndex === 1) {
      type = BigInt(swap.amount0) < 0 ? "Buy" : "Sell"
    } else {
      type = BigInt(swap.amount1) < 0 ? "Buy" : "Sell"
    }

    return {
      type: type,
      price: getPoolPriceWithToken(swap.sqrtPriceX96, pool),
      input: formatAmountTokenDecimals(input, inputToken, 5),
      inputToken: inputToken,
      output: formatAmountTokenDecimals(output, outputToken, 5),
      outputToken: outputToken,
      maker: swap.recipient,
      time: formatUnixIntoTime(swap.blockTimestamp),
      explorerLink: chainId
        ? getExplorerLink(chainId, swap.transactionHash, ExplorerDataType.TRANSACTION)
        : "",
    }
  })
}

function constructHoldersDataFromQuery(
  pool: MarginalV1LbPool,
  totalSupply: bigint | undefined,
): LaunchProjectHoldersProps[] {
  const positions = [...pool.positions]
  // Total the pool positions shares
  // let totalShares = positions.reduce((sum, position) => sum + BigInt(position.shares), BigInt(0))

  // Sort the pool positions by shares if we have any
  let sortedByShares: MarginalV1LbPosition[] = []
  if (positions.length > 0) {
    sortedByShares = positions.sort((a, b) => a.shares - b.shares)
  }

  // Map positions to LaunchProjectHoldersProps array
  const holdersData: LaunchProjectHoldersProps[] = sortedByShares
    .filter((position) => position.shares > 0)
    .map((position, index) => {
      const allocation = calculatePercentageOfTotalBigInt(
        totalSupply || BigInt(0),
        position.shares,
        4,
      )
      return {
        // Rank is length - index
        rank: (positions.length - index).toString(),
        holder: position.owner,
        // If baseTokenIndex is 0, then the base token is in token0CurrentAmount
        // else the base token is in token1CurrentAmount, we want the launch token
        tokens: formatAmountTokenDecimalsHelper(
          position.shares,
          position.shares,
          pool,
          TokenType.LAUNCH,
          2,
        ),
        // tokens: formatAmountTokenDecimals(baseTokenIndex === 0 ? token1CurrentAmount : token0CurrentAmount, launchToken, 2),
        allocation: Number(allocation).toFixed(4) + "%", // Format to 2 decimal places
      }
    })

  return holdersData
}

const useConstructedPoolsData = (pools: any[]): LaunchItemProps[] => {
  return pools?.map((pool: any) => constructPoolDataFromQuery(pool))
}

const constructPoolDataFromQuery = (pool: MarginalV1LbPool): LaunchItemProps => {
  const launchToken = poolTokenHelper(pool, TokenType.LAUNCH)
  const baseToken = poolTokenHelper(pool, TokenType.BASE)

  // We want to calculate the % of the pool that has been released
  // we will using the sqrtPriceInitializeX96 and sqrtPriceFinalizeX96 range, current value is sqrtPriceX96
  // calculate the % of the range that has been released
  let percentReleased = 0
  let token0CurrentAmount = BigInt(0)
  let token1CurrentAmount = BigInt(0)

  if (pool.sqrtPriceInitializeX96 && pool.sqrtPriceFinalizeX96 && pool.sqrtPriceX96) {
    const sqrtPriceRange =
      BigInt(pool.sqrtPriceFinalizeX96) - BigInt(pool.sqrtPriceInitializeX96)
    const sqrtPriceReleased =
      BigInt(pool.sqrtPriceX96) - BigInt(pool.sqrtPriceInitializeX96)
    percentReleased = calculatePercentageOfTotalBigInt(sqrtPriceRange, sqrtPriceReleased)

    const [_token0CurrentAmount, _token1CurrentAmount] =
      calcRangeAmountsFromLiquiditySqrtPriceX96(
        BigInt(pool.liquidity),
        BigInt(pool.sqrtPriceX96),
        BigInt(pool.sqrtPriceInitializeX96),
        BigInt(pool.sqrtPriceFinalizeX96),
      )

    token0CurrentAmount = _token0CurrentAmount
    token1CurrentAmount = _token1CurrentAmount
  }

  // 12 hours
  // const MINIMUM_DURATION = 43200

  return {
    address: pool.id,
    name: pool.token0.name,
    startedIn: pool.blockTimestampInitialize
      ? formatUnixIntoTime(pool.blockTimestampInitialize)
      : "-",
    endIn: pool.finalized ? "Finished" : "-",
    startDate: pool.blockTimestampInitialize
      ? formatUnixDate(pool.blockTimestampInitialize)
      : "-",
    endDate: pool.finalized ? formatUnixDate(pool.blockTimestamp) : "-",
    price: getCurrentPoolPriceWithToken(pool),
    rawPrice: getCurrentPoolPrice(pool),
    totalVolume: "-",
    // For liquidity, we need the base token, check index
    // If the base token index is 0, this means
    // that the base token is in token0CurrentAmount.
    liquidity: formatAmountTokenDecimalsHelper(
      token0CurrentAmount,
      token1CurrentAmount,
      pool,
      TokenType.BASE,
    ),
    // liquidity: formatPriceFromPriceAndTokens(pool.sqrtPriceX96 || 0 , launchToken, baseToken),
    logoURI:
      "https://raw.githubusercontent.com/999yugen/cloud/main/my-app/public/marginal.svg",
    status: constructStatus(pool),
    statusTime: constructStatusTime(pool),
    released: `${percentReleased.toFixed(2)}%`,
    launchToken: launchToken,
    baseToken: baseToken,
    fundsRaised: formatAmountTokenDecimalsHelper(
      token0CurrentAmount,
      token1CurrentAmount,
      pool,
      TokenType.BASE,
      5,
    ),
    marketCap: "-",
    swapFee: `${pool.feeProtocol}%`,
    stats: constructStats(pool),

    launchTokenIndex: pool.launchTokenIndex,

    sqrtPriceX96: pool.sqrtPriceX96,

    supplier: pool.supplier,
    blockTimestampInitialize: pool.blockTimestampInitialize,

    tickLower: pool.tickLower,
    tickUpper: pool.tickUpper,
  }
}

const constructStats = (pool: MarginalV1LbPool): LaunchItemStatsProps => {
  if (pool.sqrtPriceInitializeX96 && pool.sqrtPriceFinalizeX96 && pool.sqrtPriceX96) {
    const [token0CurrentAmount, token1CurrentAmount] =
      calcRangeAmountsFromLiquiditySqrtPriceX96(
        BigInt(pool.liquidity),
        BigInt(pool.sqrtPriceX96),
        BigInt(pool.sqrtPriceInitializeX96),
        BigInt(pool.sqrtPriceFinalizeX96),
      )

    const [token0FinalizeAmount, token1FinalizeAmount] =
      calcRangeAmountsFromLiquiditySqrtPriceX96(
        BigInt(pool.liquidity),
        BigInt(pool.sqrtPriceFinalizeX96),
        BigInt(pool.sqrtPriceInitializeX96),
        BigInt(pool.sqrtPriceFinalizeX96),
      )

    return {
      startBalance: {
        baseToken: formatAmountTokenDecimalsHelper(
          BigInt(pool.mint?.amount0 || 0),
          BigInt(pool.mint?.amount1 || 0),
          pool,
          TokenType.BASE,
        ),
        launchToken: formatAmountTokenDecimalsHelper(
          BigInt(pool.mint?.amount0 || 0),
          BigInt(pool.mint?.amount1 || 0),
          pool,
          TokenType.LAUNCH,
        ),
      },
      endBalance: {
        baseToken: formatAmountTokenDecimalsHelper(
          token0FinalizeAmount,
          token1FinalizeAmount,
          pool,
          TokenType.BASE,
        ),
        launchToken: formatAmountTokenDecimalsHelper(
          token0FinalizeAmount,
          token1FinalizeAmount,
          pool,
          TokenType.LAUNCH,
        ),
      },
      currentBalance: {
        baseToken: formatAmountTokenDecimalsHelper(
          token0CurrentAmount,
          token1CurrentAmount,
          pool,
          TokenType.BASE,
        ),
        launchToken: formatAmountTokenDecimalsHelper(
          token0CurrentAmount,
          token1CurrentAmount,
          pool,
          TokenType.LAUNCH,
        ),
      },
    }
  } else {
    return {
      startBalance: {
        baseToken: "-",
        launchToken: "-",
      },
      endBalance: {
        baseToken: "-",
        launchToken: "-",
      },
      currentBalance: {
        baseToken: "-",
        launchToken: "-",
      },
    }
  }
}

const getPoolPriceWithToken = (priceX96: string, pool: MarginalV1LbPool): string => {
  const launchToken = poolTokenHelper(pool, TokenType.LAUNCH)
  const baseToken = poolTokenHelper(pool, TokenType.BASE)

  return (
    convertX96Price(BigInt(priceX96), launchToken, baseToken) + " " + baseToken.symbol
  )
}

const getPoolPrice = (priceX96: string, pool: MarginalV1LbPool): string | undefined => {
  const launchToken = poolTokenHelper(pool, TokenType.LAUNCH)
  const baseToken = poolTokenHelper(pool, TokenType.BASE)

  return convertX96Price(BigInt(priceX96), launchToken, baseToken)
}

const getCurrentPoolPrice = (pool: MarginalV1LbPool): string | undefined => {
  return getPoolPrice(pool.sqrtPriceX96, pool)
}

const getCurrentPoolPriceWithToken = (pool: MarginalV1LbPool): string => {
  return getPoolPriceWithToken(pool.sqrtPriceX96, pool)
}

const formatUnixDate = (date: string): string => {
  // Aug 8, 2024, 1:59 PM
  return moment.unix(Number(date)).format("MMM D, YYYY, h:mm A")
}

const formatUnixIntoTime = (date: string): string => {
  return moment.unix(Number(date)).fromNow()
}

const constructStatus = (pool: MarginalV1LbPool): string => {
  if (pool.finalized) {
    return "Finished"
  }
  // Get the current time and compare it to the start time
  // If the current time is less than the start time, then the pool is upcoming
  if (pool.blockTimestampInitialize && pool.blockTimestampInitialize > moment().unix()) {
    return "Upcoming"
  }

  return "Live Now"
}

const constructStatusTime = (pool: MarginalV1LbPool): string => {
  if (pool.finalized) {
    return formatUnixIntoTime(pool.blockTimestamp)
  }

  // If we have an initialize time, return the time since the pool was initialized
  // or the time until the pool is initialized
  if (pool.blockTimestampInitialize) {
    return moment.unix(Number(pool.blockTimestampInitialize)).fromNow()
  }

  return "-"
}

export const getPoolAddress = (poolAddress: string | undefined): string => {
  return _.isUndefined(poolAddress) ? "" : poolAddress
}
