export const SwapOutput = ({
  title,
  outputValue,
}: {
  title: string
  outputValue: string | undefined
}) => {
  return (
    <div className="flex-1 space-y-1">
      <label htmlFor="swap-output" className="text-sm">
        {title}
      </label>
      <input
        readOnly
        value={outputValue}
        placeholder="-"
        className="w-full border-none bg-marginalBlack p-0 text-2xl text-marginalGray-200 outline-none focus:border-none focus:outline-none focus:ring-0"
      />
    </div>
  )
}
