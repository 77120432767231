import { Token, LbpExactInputSingleParams } from "src/types"
import { getValidAddress } from "src/utils/getValidAddress"
import { convertInputToSize } from "src/utils/conversions"

const MAX_DEADLINE =
  115792089237316195423570985008687907853269984665640564039457584007913129639935n

export const constructLbpExactInputSingleParams = (
  tokenIn: Token,
  tokenOut: Token,
  supplier: string,
  recipient: string,
  amountIn: string,
  currentBlockTimestamp: bigint | undefined,
  blockTimestampInitialize: string,
  transactionDeadline: string,
  tickLower: bigint,
  tickUpper: bigint,
): LbpExactInputSingleParams | null => {
  return {
    tokenIn: getValidAddress(tokenIn.address),
    tokenOut: getValidAddress(tokenOut.address),
    tickLower: tickLower, // TODO: Update this to factor in slippage
    tickUpper: tickUpper, // TODO: Update this to factor in slippage
    supplier: supplier,
    recipient: getValidAddress(recipient),
    blockTimestampInitialize: blockTimestampInitialize,
    deadline: currentBlockTimestamp
      ? currentBlockTimestamp + BigInt(transactionDeadline) * BigInt("60")
      : MAX_DEADLINE,
    amountIn: convertInputToSize(amountIn, tokenIn.decimals) ?? 0n,
    amountOutMinimum: 0n, // TODO: Update this to factor in slippage
    sqrtPriceLimitX96: 0n, // TODO: Update this to factor in slippage
  }
}
