import { ReactNode } from "react"

export const DetailRow = ({
  label,
  value,
}: {
  label: string
  value?: string | ReactNode
}) => {
  const renderValue = () => {
    if (value === undefined || value === null) {
      return <span className="text-xs text-marginalGray-200">-</span>
    }

    if (typeof value === "string" || typeof value === "number") {
      return <p className="text-xs text-marginalGray-200">{value}</p>
    }

    return value
  }

  return (
    <div className="flex items-center justify-between space-x-5">
      <div className="whitespace-nowrap text-xs text-marginalGray-600">
        {label}
      </div>
      <div className="text-right">{renderValue()}</div>
    </div>
  )
}
