import { formatBigIntToString } from "../formatBigIntToString"

interface ProfitLossCalculator {
  calculateRawPnL(
    quotedAmountOut: bigint | undefined,
    margin: bigint | undefined,
  ): bigint | undefined
  calculatePnLPercentage(
    pnl: bigint | undefined,
    margin: bigint | undefined,
  ): number | undefined
}

class BigIntProfitLossCalculator implements ProfitLossCalculator {
  private readonly PRECISION_DECIMALS = 18n
  private readonly ONE_ETHER = 10n ** this.PRECISION_DECIMALS

  calculateRawPnL(
    quotedAmountOut: bigint | undefined,
    margin: bigint | undefined,
  ): bigint | undefined {
    if (!this.isValidInput(quotedAmountOut) || !this.isValidInput(margin)) {
      return undefined
    }

    return quotedAmountOut! - margin!
  }

  calculatePnLPercentage(
    pnl: bigint | undefined,
    margin: bigint | undefined,
  ): number | undefined {
    if (!this.isValidInput(pnl) || !this.isValidInput(margin) || margin === 0n) {
      return undefined
    }

    // Calculate with high precision: (pnl * 10^18) / margin
    const scaledPnL = (pnl! * this.ONE_ETHER) / margin!
    const parsedPnL = formatBigIntToString(scaledPnL, Number(this.PRECISION_DECIMALS))

    return parsedPnL ? parseFloat(parsedPnL) * 100 : undefined
  }

  private isValidInput(value: bigint | undefined): boolean {
    return value !== undefined && value !== null && typeof value === "bigint"
  }
}

// Singleton instance for the calculator
const profitLossCalculator = new BigIntProfitLossCalculator()

/**
 * Calculates the raw profit/loss for a position
 * @param quotedAmountOut - The quoted amount out in base units
 * @param margin - The margin amount in base units
 * @returns The raw profit/loss in base units, or undefined if inputs are invalid
 */
export function calculateRawPnL(
  quotedAmountOut: bigint | undefined,
  margin: bigint | undefined,
): bigint | undefined {
  return profitLossCalculator.calculateRawPnL(quotedAmountOut, margin)
}

/**
 * Calculates the profit/loss percentage for a position
 * @param pnl - The raw profit/loss in base units
 * @param margin - The margin amount in base units
 * @returns The profit/loss percentage, or undefined if inputs are invalid
 */
export function calculatePnLByPercentage(
  pnl: bigint | undefined,
  margin: bigint | undefined,
): number | undefined {
  return profitLossCalculator.calculatePnLPercentage(pnl, margin)
}
