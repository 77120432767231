import { useEffect, useRef } from "react"
import { useApplicationState } from "src/state/application/hooks"

export const useNetworkChangeRedirect = (onNavigation: () => void) => {
  const { chainId } = useApplicationState()
  const initialChainId = useRef<number>(chainId)

  useEffect(() => {
    if (chainId && initialChainId.current !== chainId) {
      onNavigation()
    }
  }, [chainId, onNavigation])
}
