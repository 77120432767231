import { Token } from "src/types"
import { getAddress } from "viem"
import { convertInputToSize } from "src/utils/conversions/convertInputToSize"
import { convertLeverageInputToDesiredSize } from "src/utils/conversions/convertLeverageInputToDesiredSize"
import { MintQuoteParams } from "src/types"

const MAX_DEADLINE =
  115792089237316195423570985008687907853269984665640564039457584007913129639935n

export const constructMintQuoteParams = (
  pool: any,
  marginToken: Token,
  debtToken: Token,
  input: string,
  leverage: number,
  recipient: string,
  deadline: string,
  currentBlockTimestamp?: bigint,
): MintQuoteParams => {
  return {
    token0: getAddress(pool.token0.address),
    token1: getAddress(pool.token1.address),
    maintenance: pool.maintenance,
    oracle: getAddress(pool.oracleAddress),
    zeroForOne: getAddress(marginToken.address) === getAddress(pool.token1.address),
    sizeDesired:
      convertLeverageInputToDesiredSize(input, leverage, marginToken.decimals) ?? 0n,
    sizeMinimum: 0n,
    debtMaximum: 0n,
    amountInMaximum: 0n,
    sqrtPriceLimitX96: 0n,
    margin: convertInputToSize(input, marginToken.decimals) ?? 0n,
    recipient: getAddress(recipient),
    deadline: currentBlockTimestamp
      ? currentBlockTimestamp + BigInt(deadline) * BigInt("60")
      : MAX_DEADLINE,
  }
}
