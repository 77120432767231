/**
 * Calculates pool liquidity token amounts based on liquidity and sqrt price
 */
export function calculatePoolLiquidityTokenAmounts(
  liquidity?: bigint,
  sqrtPriceX96?: bigint,
): { token0: string | null; token1: string | null } {
  if (liquidity === undefined || sqrtPriceX96 === undefined) {
    return { token0: null, token1: null }
  }

  const Q96 = 1n << 96n

  const token0 = (liquidity * Q96) / sqrtPriceX96
  const token1 = (liquidity * sqrtPriceX96) / Q96

  return {
    token0: token0.toString(),
    token1: token1.toString(),
  }
}

/**
 * Calculates range amounts from liquidity and sqrt prices
 */
export function calcRangeAmountsFromLiquiditySqrtPriceX96(
  liquidity: bigint,
  sqrtPriceX96: bigint,
  sqrtPriceLowerX96: bigint,
  sqrtPriceUpperX96: bigint,
): [bigint, bigint] {
  const Q96 = 1n << 96n
  const amount0 = (liquidity * Q96) / sqrtPriceX96 - (liquidity * Q96) / sqrtPriceUpperX96
  const amount1 = (liquidity * (sqrtPriceX96 - sqrtPriceLowerX96)) / Q96
  return [amount0, amount1]
}
