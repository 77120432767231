import { Token } from "src/types"
import { isAddress } from "viem"

export const validateExactInputSingleParams = (
  swapToken: Token | undefined | null,
  outputToken: Token | undefined | null,
  maintenance: string | undefined,
  oracleAddress: string | undefined,
  input: string | undefined,
): boolean => {
  if (!swapToken || !outputToken || !input) {
    return false
  }
  if (!maintenance) {
    return false
  }
  if (!oracleAddress) {
    return false
  }
  if (!isAddress(oracleAddress)) {
    return false
  }
  if (!swapToken.address || !outputToken.address) {
    return false
  }
  if (!isAddress(swapToken.address) || !isAddress(outputToken.address)) {
    return false
  }
  return true
}
