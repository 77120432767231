import { ReactNode } from "react"
import { type Token } from "src/types"
import { formatUSDCurrency } from "src/utils/formatUSDCurrency"
import { TokenBadge } from "src/components/TokenBadge"
import { AssetPairPriceRatio } from "src/components/AssetPairPriceRatio"
import { HealthFactorIcon } from "src/components/Icons/HealthFactorIcon"
import { formatNumberAmount } from "src/utils/formatNumberAmount"
import { getHealthFactorIndicatorColor } from "src/pages/position/managePosition/helpers/getHealthFactorIndicatorColor"
import { getProfitLossIndicatorColor } from "src/utils/getProfitLossIndicatorColor"

interface PositionDetailsOverviewProps {
  tokenId: string | undefined | null
  token0: Token | null
  token1: Token | null
  poolPrice: string | undefined
  oraclePrice: string | undefined
  liquidationPrice: string | undefined
  healthFactor: number | null

  margin: string | undefined
  marginToken: Token | null | undefined
  marginTokenPriceInUSD: number | undefined
  totalSize: string | undefined
  debt: string | undefined
  debtToken: Token | null | undefined
  profitLoss: {
    raw: bigint | undefined
    parsed: string | undefined
    formatted: string | null | undefined
    percentage: number | undefined
    percentageFormatted: string | null | undefined
    isProfit: boolean | undefined
  }
  escrowRewards: string | undefined
  fundingRate: string | null | undefined

  useInverse: boolean
  onToggleInverse: () => void
}

export const PositionDetailsOverview = ({
  tokenId,
  token0,
  token1,
  poolPrice,
  oraclePrice,
  liquidationPrice,
  healthFactor,

  margin,
  marginToken,
  marginTokenPriceInUSD,
  totalSize,
  debt,
  debtToken,
  profitLoss,
  escrowRewards,
  fundingRate,

  useInverse,
  onToggleInverse,
}: PositionDetailsOverviewProps) => {
  const profitColor = getProfitLossIndicatorColor(profitLoss?.isProfit)

  const profitLossFormatted = profitLoss?.parsed
    ? formatNumberAmount(profitLoss.parsed, true)
    : undefined

  return (
    <div className="flex w-full flex-col space-y-2">
      <div className="w-full rounded-xl bg-marginalBlack p-4 md:rounded-lg">
        <div className="flex flex-col md:flex-row">
          <div className="flex flex-col">
            <div className="mb-2 text-xs text-marginalGray-200">
              P&L
            </div>
            <p className={`mb-1 text-3xl ${profitColor}`}>
              {profitLoss?.percentage?.toString()?.slice(0, 6)}%
            </p>
            <p className="mb-1 flex items-center space-x-1 text-xs">
              <div className={profitColor}>{profitLossFormatted}</div>
              <TokenBadge token={marginToken as Token} size={4} />
            </p>
            {profitLossFormatted && marginTokenPriceInUSD && (
              <p className={`mb-1 text-xs ${profitColor}`}>
                {formatUSDCurrency(
                  parseFloat(profitLossFormatted) * marginTokenPriceInUSD,
                )}
              </p>
            )}
          </div>

          <div className="mx-6 w-px bg-marginalGray-200/20" />

          <div className="mt-4 flex w-full items-center justify-start md:mt-0">
            <AssetPairPriceRatio
              token0={token0}
              token1={token1}
              price={formatNumberAmount(poolPrice, true)}
              useInverse={useInverse}
              onToggleInverse={onToggleInverse}
            />
          </div>
        </div>

        <div className="my-3 h-px bg-marginalGray-200/20" />

        <div className="space-y-1">
          <PositionListRow label="Oracle price">
            <p className="flex items-center gap-1 text-xs text-marginalGray-200">
              {formatNumberAmount(oraclePrice, true)}
              {!useInverse ? (
                <span>
                  {token1?.symbol} / {token0?.symbol}
                </span>
              ) : (
                <span>
                  {token0?.symbol} / {token1?.symbol}
                </span>
              )}
            </p>
          </PositionListRow>

          <PositionListRow label="Liq price">
            <p className="flex items-center gap-1 text-xs text-marginalGray-200">
              {formatNumberAmount(liquidationPrice, true)}
              {!useInverse ? (
                <span>
                  {token1?.symbol} / {token0?.symbol}
                </span>
              ) : (
                <span>
                  {token0?.symbol} / {token1?.symbol}
                </span>
              )}
            </p>
          </PositionListRow>

          <PositionListRow label="Health factor">
            <p
              className={`flex items-center space-x-1 ${getHealthFactorIndicatorColor(healthFactor)}`}
            >
              <HealthFactorIcon />
              <span className="text-xs">{healthFactor?.toFixed(2)}</span>
            </p>
          </PositionListRow>
        </div>
      </div>

      <div className="flex h-full w-full flex-col rounded-xl bg-marginalBlack p-4 md:rounded-lg">
        <div className="mb-auto pb-2 text-xs text-marginalGray-200">
          Details
        </div>

        <div className="space-y-1">
          <PositionListRow label="Token ID">
            <p className="text-xs text-marginalGray-200">{tokenId}</p>
          </PositionListRow>

          <PositionListRow label="Size">
            <p className="flex items-center gap-1 text-xs text-marginalGray-200">
              {formatNumberAmount(totalSize, true)}
              <span>{marginToken?.symbol}</span>
            </p>
          </PositionListRow>

          <PositionListRow label="Debt">
            <p className="flex items-center gap-1 text-xs text-marginalGray-200">
              {formatNumberAmount(debt, true)}
              <span>{debtToken?.symbol}</span>
            </p>
          </PositionListRow>

          <PositionListRow label="Collateral">
            <p className="flex items-center gap-1 text-xs text-marginalGray-200">
              {formatNumberAmount(margin, true)}
              <span>{marginToken?.symbol}</span>
            </p>
          </PositionListRow>

          <PositionListRow label="Funding rate">
            <p className="text-xs text-marginalGray-200">
              {formatNumberAmount(fundingRate, true)}% weekly
            </p>
          </PositionListRow>
        </div>

        <div className="my-3 h-px bg-marginalGray-200/20" />

        <PositionListRow label="Escrow rewards">
          <p className="flex items-center gap-1 text-xs text-marginalGray-200">
            {formatNumberAmount(escrowRewards, true)}
            <span>WETH</span>
          </p>
        </PositionListRow>
      </div>
    </div>
  )
}

export const PositionListRow = ({
  label,
  children,
}: {
  label: string
  children?: ReactNode
}) => {
  return (
    <div className="flex items-center justify-between">
      <div className="text-xs text-marginalGray-600">{label}</div>
      {children}
    </div>
  )
}
