import { useRef } from "react"
import { type Token } from "src/types"
import { TokenBadge } from "src/components/TokenBadge"

export const SelectTokenOption = ({
  token,
  selectedToken,
  isActive,
  onClick,
}: {
  token: Token
  selectedToken: Token | null
  isActive: boolean
  onClick: () => void
}) => {
  const ref = useRef<HTMLButtonElement>(null)
  const isSelected = selectedToken?.address === token.address

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    // Only trigger onClick for mouse clicks, not keyboard events
    if (event.detail > 0) {
      // event.detail > 0 means it's a real mouse click
      onClick()
    }
  }

  return (
    <button
      ref={ref}
      id="select-token-option"
      onClick={handleClick}
      className={`flex cursor-pointer border p-2 hover:bg-borderGray ${isActive ? "bg-borderGray" : ""} ${isSelected ? "border-marginalOrange-500" : "border-transparent"}`}
    >
      <TokenBadge token={token} />
    </button>
  )
}
