import { useReadContract, useReadContracts } from "wagmi"
import { Address } from "viem"
import { isListDefined } from "src/utils/isListDefined"
import { convertX96PricesToBigInt } from "src/utils/conversions/convertX96Price"
import { UNISWAP_V3_POOL_ABI } from "src/constants/abis/Uniswapv3Pool"

interface PoolState {
  price: bigint
  sqrtPriceX96: bigint
  tick: number
  observationIndex: number
  observationCardinality: number
  observationCardinalityNext: number
  feeProtocol: number
  unlocked: boolean
  fee: bigint | undefined
  isLoading: boolean
  isError: boolean
}

export type MultiPoolState = Omit<PoolState, "isLoading" | "isError">

export type PoolStateMap = {
  [poolAddress: string]: MultiPoolState
}

export interface MultiPoolStateResult {
  data: PoolStateMap
  isLoading: boolean
  isError: boolean
  error: Error | null
}

const validateInputs = (pools: Address[]): boolean => {
  return Array.isArray(pools) && pools.length > 0
}

const createContractConfigs = (pools: Address[], chainId: number) => {
  return pools.flatMap((pool) => [
    {
      address: pool,
      abi: UNISWAP_V3_POOL_ABI as any,
      functionName: "slot0",
      chainId,
    },
    {
      address: pool,
      abi: UNISWAP_V3_POOL_ABI as any,
      functionName: "fee",
      chainId,
    },
  ])
}

const formatPoolsData = (data: unknown[] | undefined, pools: Address[]): PoolStateMap => {
  if (!data) return {}

  return pools.reduce<PoolStateMap>((acc, pool, index) => {
    const slot0Index = index * 2
    const feeIndex = slot0Index + 1

    const slot0Result = data[slot0Index]
    const feeResult = data[feeIndex]

    if (
      !slot0Result ||
      typeof slot0Result !== "object" ||
      !("result" in slot0Result) ||
      !Array.isArray(slot0Result.result)
    ) {
      return acc
    }

    const poolData = slot0Result.result
    const feeData =
      feeResult && typeof feeResult === "object" && "result" in feeResult
        ? feeResult.result
        : undefined

    acc[pool] = {
      price: convertX96PricesToBigInt(poolData[0]) as bigint,
      sqrtPriceX96: poolData[0] as bigint,
      tick: Number(poolData[1] ?? 0),
      observationIndex: Number(poolData[2] ?? 0),
      observationCardinality: Number(poolData[3] ?? 0),
      observationCardinalityNext: Number(poolData[4] ?? 0),
      feeProtocol: Number(poolData[5] ?? 0),
      unlocked: Boolean(poolData[6] ?? false),
      fee: feeData as bigint,
    }

    return acc
  }, {})
}

export const useMultipleUniswapV3PoolState = (
  pools: Address[],
  chainId: number,
): MultiPoolStateResult => {
  const isValid = validateInputs(pools)
  const contracts = isValid ? createContractConfigs(pools, chainId) : []

  const { data, isLoading, isError, error } = useReadContracts({
    contracts,
    query: {
      enabled: isValid,
    },
  })

  const formattedData = formatPoolsData(data, pools)

  return {
    data: formattedData,
    isLoading,
    isError,
    error: error as Error | null,
  }
}

export const useUniswapV3PoolState = (pool?: Address, chainId?: number): PoolState => {
  const isValidProps = isListDefined([pool, chainId])

  const {
    data: slot0Data,
    isError: slot0Error,
    isLoading: slot0Loading,
  } = useReadContract({
    address: pool as Address,
    abi: UNISWAP_V3_POOL_ABI,
    functionName: "slot0",
    chainId,
    query: {
      enabled: isValidProps,
    },
  })

  const {
    data: feeData,
    isError: feeError,
    isLoading: feeLoading,
  } = useReadContract({
    address: pool as Address,
    abi: UNISWAP_V3_POOL_ABI,
    functionName: "fee",
    chainId,
    query: {
      enabled: isValidProps,
    },
  })

  const poolData: any[] = Array.isArray(slot0Data) ? slot0Data : []
  const isLoading = slot0Loading || feeLoading
  const isError = slot0Error || feeError

  return {
    price: convertX96PricesToBigInt(poolData[0]) as bigint,
    sqrtPriceX96: poolData[0] as bigint,
    tick: Number(poolData[1] ?? 0),
    observationIndex: Number(poolData[2] ?? 0),
    observationCardinality: Number(poolData[3] ?? 0),
    observationCardinalityNext: Number(poolData[4] ?? 0),
    feeProtocol: Number(poolData[5] ?? 0),
    unlocked: Boolean(poolData[6] ?? false),
    fee: BigInt(feeData ?? 0),
    isLoading,
    isError,
  }
}
