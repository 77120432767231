import { NavLink } from "react-router-dom"
import { BrandLogo } from "src/components/Header/BrandLogo"

const FOOTER_LINKS = {
  Functions: {
    trade: "/",
    swap: "/swap",
    positions: "/positions",
    pools: "/pools",
    launch: "/launch",
  },
  Resources: {
    docs: "https://marginal.gitbook.io/docs",
    whitepaper: "https://github.com/MarginalProtocol/v1-core/blob/main/wp/v1.pdf",
    github: "https://github.com/MarginalProtocol",
  },
  Contact: {
    twitter: "https://x.com/MarginalDEX",
    discord: undefined,
  },
}

export const Footer = () => {
  return (
    <footer
      id="footer"
      className="mx-auto flex w-full max-w-224 flex-col px-8 pb-12 pt-24"
    >
      <div className="flex flex-wrap items-start justify-between gap-x-4 gap-y-8">
        <div className="flex w-full items-center md:w-min">
          <BrandLogo />
        </div>

        <div className="flex w-full flex-col md:w-auto">
          <div className="flex flex-wrap gap-12">
            {Object.entries(FOOTER_LINKS).map(([columnHeading, links], i) => (
              <div key={i} className="flex flex-col items-start md:items-end">
                <h3 className="mb-2 text-marginalGray-100">{columnHeading}</h3>
                <div className="flex flex-col items-start space-y-1 md:items-end">
                  {Object.entries(links).map(([linkText, href], i) =>
                    href ? (
                      <NavLink
                        key={i}
                        to={href!}
                        aria-disabled={!href}
                        onClick={() => window.scrollTo(0, 0)}
                        target={
                          columnHeading === "Resources" || columnHeading === "Contact"
                            ? "_blank"
                            : "_self"
                        }
                        className={({ isActive }) =>
                          `transition ${
                            href
                              ? `cursor-pointer ${isActive ? "text-marginalOrange-500" : "text-marginalGray-200"} ${columnHeading === "Functions" && "hover:text-marginalOrange-500"} ${columnHeading === "Resources" && "hover:text-marginalGray-100"} ${linkText === "twitter" && "hover:text-[#1DA1F2]"}`
                              : "cursor-not-allowed text-marginalGray-400 line-through hover:text-marginalGray-600"
                          }`
                        }
                      >
                        {linkText}
                      </NavLink>
                    ) : (
                      <p className="cursor-not-allowed select-none text-marginalGray-400 line-through hover:text-marginalGray-600">
                        {linkText}
                      </p>
                    ),
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* <div className="flex items-center mx-auto mt-8 md:mt-12">
        <a
          className="cursor-pointer text-marginalGray-200 hover:text-marginalGray-100"
          href="/terms-of-use" // TODO: Add link to Terms of Use
        >
          terms of use
        </a>
        <div className="mx-4 h-6 border-r border-marginalGray-600"></div>
        <a
          className="cursor-pointer text-marginalGray-200 hover:text-marginalGray-100"
          href="/privacy-policy" // TODO: Add link to Privacy Policy
        >
          privacy policy
        </a>
      </div> */}
    </footer>
  )
}
