import { Token } from "src/types"
import { formatBigIntToString } from "src/utils/formatBigIntToString"
import { isUndefined } from "lodash"

/**
 * Calculates the maximum balance input for a trade considering leverage and fees
 * @param tokenBalance - The user's token balance
 * @param poolLeverageMax - The maximum leverage allowed by the pool
 * @param marginToken - The token being used as margin
 * @returns Formatted string representing the maximum input amount
 */
export const calculateMaxBalanceInput = (
  tokenBalance: bigint | undefined,
  poolLeverageMax: number | undefined,
  marginToken: Token | null,
): string => {
  // Early returns for invalid inputs
  if (isUndefined(tokenBalance) || isUndefined(poolLeverageMax) || !marginToken) {
    return ""
  }

  // Constants
  const SCALE_FACTOR = 1000n
  const FEE_RATE = 1n // 0.1% = 1/1000

  try {
    // Calculate maximum position size with leverage
    const maxSize = tokenBalance * BigInt(poolLeverageMax)

    // Calculate fees (scaled for precision)
    const scaledFees = maxSize * FEE_RATE

    // Calculate the difference between scaled fees and balance
    const difference = scaledFees - tokenBalance

    // Unscale the difference
    const unscaledDifference = difference / SCALE_FACTOR

    // Calculate max collateral user can provide
    const maxCollateral = tokenBalance - unscaledDifference

    // Format the result
    const formattedMaxBalance = formatBigIntToString(maxCollateral, marginToken.decimals)

    return formattedMaxBalance ?? ""
  } catch (error) {
    console.error("Error calculating max balance:", error)
    return ""
  }
}
