import { V1_FACTORY_ABI } from "src/constants/abis/MarginalV1Factory"
import { V1_FACTORY_ADDRESS } from "src/constants/addresses"
import { useAccount, useReadContract } from "wagmi"

const useObservationCardinality = () => {
  const { chain } = useAccount()
  const { data: observationCardinalityMinimum, isLoading: isMarginalLoading } =
    useReadContract({
      address: V1_FACTORY_ADDRESS[chain?.id ?? 0],
      abi: V1_FACTORY_ABI,
      functionName: "observationCardinalityMinimum",
      scopeKey: chain?.id.toString(),
      query: {
        staleTime: Infinity,
      },
    })

  return {
    observationCardinalityMinimum,
    isOCLoading: isMarginalLoading,
  }
}

export default useObservationCardinality
