import { type PositionRowProps } from "src/pages/positions/components/PositionRow"
import { useNavigateRoutes } from "src/hooks/useNavigateRoutes"
import {
  calculatePnLByPercentage,
  calculateRawPnL,
} from "src/utils/conversions/calculateProfitLoss"
import { formatNumberAmount } from "src/utils/formatNumberAmount"
import { formatBigIntToString } from "src/utils/formatBigIntToString"
import { derivePositionData } from "src/pages/position/managePosition/helpers/derivePositionData"
import { getHealthFactorIndicatorColor } from "src/pages/position/managePosition/helpers/getHealthFactorIndicatorColor"
import { deriveIgniteQuoteData } from "src/pages/position/managePosition/helpers/deriveIgniteQuoteData"
import { MobileTableCell } from "src/pages/pools/components"
import { PositionView } from "src/pages/positions/Positions"
import { CaretRightIcon } from "src/components/Icons/CaretRightIcon"
import { DoubleTokenLogo } from "src/components/TokenBadge"
import { getTokenByAddress } from "src/constants/tokenList"
import { getProfitLossIndicatorColor } from "src/utils/getProfitLossIndicatorColor"

export const MobilePositionRowContent = ({
  view,
  positionKey,
  chainId,
  tokenId,
  positionData,
  pool,
  igniteParams,
  igniteQuote,
  uniswapV3PoolPrice,
  uniswapV3PoolFee,
  indexedPositionData,
}: PositionRowProps) => {
  const { onNavigateToPosition } = useNavigateRoutes()
  const handleNavigateToPosition = () => onNavigateToPosition(positionKey)
  const zeroForOne: boolean = positionData?.zeroForOne
  const token0 = getTokenByAddress(pool?.token0?.address, chainId) ?? pool?.token0
  const token1 = getTokenByAddress(pool?.token1?.address, chainId) ?? pool?.token1
  const [marginToken, debtToken] = zeroForOne ? [token1, token0] : [token0, token1]
  const { margin, debt, size, totalSize, healthFactor } = derivePositionData(
    positionData,
    pool,
    marginToken,
    debtToken,
  )
  const healthFactorIndicator = getHealthFactorIndicatorColor(healthFactor)

  const { profitLoss } = deriveIgniteQuoteData(
    igniteQuote,
    marginToken,
    igniteParams,
    "100",
    margin?.raw as bigint,
    debt?.raw as bigint,
    size?.raw as bigint,
    zeroForOne,
    uniswapV3PoolPrice as bigint,
    uniswapV3PoolFee as bigint,
  )

  const profitLossIndicator = getProfitLossIndicatorColor(profitLoss?.isProfit)

  const { marginAmountOut, initialMargin, isClosed, isLiquidated } =
    indexedPositionData || {}

  const marginAmountOut_BI = marginAmountOut ? BigInt(marginAmountOut) : undefined
  const initialMargin_BI = initialMargin ? BigInt(initialMargin) : undefined
  const settledProfitLoss = calculateRawPnL(marginAmountOut_BI, initialMargin_BI)
  const settledProfitLossPercentage = calculatePnLByPercentage(
    settledProfitLoss,
    initialMargin_BI,
  )

  const settledProfitLossIndicator = getProfitLossIndicatorColor(
    settledProfitLossPercentage ? settledProfitLossPercentage > 0 : undefined,
  )

  if (isClosed && view === PositionView.CLOSED) {
    return (
      <tr className="flex h-min flex-col space-y-4 bg-marginalGray-950 p-4 md:hidden">
        <MobileTableCell>
          <div className="flex items-center gap-1.5">
            <DoubleTokenLogo token0={token0} token1={token1} size={6} />
            <span className="text-sm">
              {token0?.symbol} / {token1?.symbol}
            </span>
          </div>
        </MobileTableCell>
        <div className="grid grid-cols-2">
          <div className="flex flex-col space-y-4">
            <MobileTableCell label="Token">{tokenId}</MobileTableCell>
            <MobileTableCell label="Closing Payout">
              {formatNumberAmount(
                formatBigIntToString(marginAmountOut, marginToken?.decimals),
                true,
              )}{" "}
              {marginToken?.symbol}
            </MobileTableCell>
            <MobileTableCell label="Profit/Loss (ROI)">
              <div className={`flex flex-col ${settledProfitLossIndicator}`}>
                {formatNumberAmount(
                  formatBigIntToString(settledProfitLoss, marginToken?.decimals),
                  true,
                )}{" "}
                {marginToken?.symbol}
                <span className={settledProfitLossIndicator}>
                  ({formatNumberAmount(settledProfitLossPercentage?.toString(), true)}%)
                </span>
              </div>
            </MobileTableCell>
          </div>
          <div className="flex flex-col space-y-4">
            <MobileTableCell label="Status">
              {isLiquidated ? "Liquidated" : "Closed"}
            </MobileTableCell>
          </div>
        </div>
      </tr>
    )
  }

  if (!isClosed && view === PositionView.OPEN) {
    return (
      <tr
        onClick={handleNavigateToPosition}
        className="flex h-min flex-col space-y-4 bg-marginalGray-950 p-4 md:hidden"
      >
        <MobileTableCell>
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-1.5">
              <DoubleTokenLogo token0={token0} token1={token1} size={6} />
              <span className="text-sm">
                {token0?.symbol} / {token1?.symbol}
              </span>
            </div>
            <CaretRightIcon />
          </div>
        </MobileTableCell>
        <div className="grid grid-cols-2">
          <div className="flex flex-col space-y-4">
            <MobileTableCell label="Token">{tokenId}</MobileTableCell>
            <MobileTableCell label="Size">
              {formatNumberAmount(totalSize?.parsed, true)} {marginToken?.symbol}
            </MobileTableCell>
            <MobileTableCell label="Profit/Loss (ROI)">
              <div className={`flex flex-col ${profitLossIndicator}`}>
                {formatNumberAmount(profitLoss?.parsed, true)} {marginToken?.symbol}
                <span className={profitLossIndicator}>
                  ({formatNumberAmount(profitLoss?.percentageFormatted, true)}%)
                </span>
              </div>
            </MobileTableCell>
          </div>
          <div className="flex flex-col space-y-4">
            <MobileTableCell label="Collateral">
              {formatNumberAmount(margin?.parsed, true)} {marginToken?.symbol}
            </MobileTableCell>
            <MobileTableCell label="Health Factor">
              <span className={healthFactorIndicator}>{healthFactor?.toFixed(2)}</span>
            </MobileTableCell>
          </div>
        </div>
      </tr>
    )
  }
}
