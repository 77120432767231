import { ChainId } from "src/constants/chains/chains"

const BLOCK_EXPLORER_PREFIXES: { [chainId: number]: string } = {
  [ChainId.ETH]: "https://etherscan.io",
  [ChainId.SEPOLIA]: "https://sepolia.etherscan.io",
  [ChainId.BASE]: "https://basescan.org",
  [ChainId.BERACHAIN_BARTIO]: "https://bartio.beratrail.io",
}

export enum ExplorerDataType {
  TRANSACTION = "transaction",
  TOKEN = "token",
  ADDRESS = "address",
  BLOCK = "block",
  NATIVE = "native",
}

/**
 * Return the explorer link for the given data and data type
 * @param chainId the ID of the chain for which to return the data
 * @param data the data to return a link for
 * @param type the type of the data
 */
export function getExplorerLink(
  chainId: number,
  data: string,
  type: ExplorerDataType,
): string {
  const prefix = BLOCK_EXPLORER_PREFIXES[chainId] ?? "https://etherscan.io"

  switch (type) {
    case ExplorerDataType.TRANSACTION:
      return `${prefix}/tx/${data}`

    case ExplorerDataType.TOKEN:
      return `${prefix}/token/${data}`

    case ExplorerDataType.BLOCK:
      return `${prefix}/block/${data}`

    case ExplorerDataType.ADDRESS:
      return `${prefix}/address/${data}`
    default:
      return `${prefix}`
  }
}
