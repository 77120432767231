import { TableCell } from "src/pages/pools/components/TableComponents"

export const PositionRowLoading = ({ isOpen }: { isOpen: boolean }) => {
  return (
    <>
      <MobilePositionRowContentLoading isOpen={isOpen} />
      <PositionRowContentLoading isOpen={isOpen} />
    </>
  )
}

const PositionRowContentLoading = ({ isOpen }: { isOpen: boolean }) => {
  return (
    <tr className="hidden w-full cursor-pointer bg-marginalBlack md:table-row md:items-center">
      <TableCell>
        <div className="flex items-center space-x-2">
          <div className="h-7 w-7 rounded-full bg-marginalGray-200"></div>
          <div className="h-4 w-10 rounded bg-marginalGray-200"></div>
        </div>
      </TableCell>
      <TableCell>
        <div className="flex items-center space-x-4">
          <div className="h-8 w-8 rounded-full bg-marginalGray-200"></div>
          <div className="flex flex-col space-y-2">
            <div className="h-4 w-24 rounded bg-marginalGray-200"></div>
            <div className="h-3 w-36 rounded bg-marginalGray-200"></div>
          </div>
        </div>
      </TableCell>
      <TableCell>
        <div className="ml-auto h-4 w-20 rounded bg-marginalGray-200"></div>
      </TableCell>
      <TableCell>
        <div className="ml-auto h-4 w-20 rounded bg-marginalGray-200"></div>
      </TableCell>
      <TableCell>
        <div className="ml-auto h-4 w-20 rounded bg-marginalGray-200"></div>
      </TableCell>
      {isOpen && (
        <TableCell>
          <div className="ml-auto h-4 w-20 rounded bg-marginalGray-200"></div>
        </TableCell>
      )}
    </tr>
  )
}

const MobilePositionRowContentLoading = ({ isOpen }: { isOpen: boolean }) => {
  return (
    <tr className="relative h-52 space-y-4 rounded-xl bg-marginalGray-950 p-4 md:hidden">
      <LoadingRow />
      <LoadingRow />
      <LoadingRow />
      <LoadingRow />
      <LoadingRow />
      {isOpen && <LoadingRow />}
    </tr>
  )
}

const LoadingRow = () => (
  <td className="flex items-center justify-between md:py-2">
    <div className="flex space-x-2">
      <div className="h-5 w-5 rounded-full bg-marginalGray-200"></div>
      <div className="h-5 w-8 rounded bg-marginalGray-200"></div>
    </div>
    <div className="h-5 w-14 rounded bg-marginalGray-200"></div>
  </td>
)
