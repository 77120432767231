import { formatBigIntToString } from "src/utils/formatBigIntToString"

const EXTEND_DECIMALS = 18n
const ONE_WITH_DECIMALS = 10n ** EXTEND_DECIMALS
const HUNDRED = 100n

export const calculatePriceImpact = (
  sqrtPriceX96After: bigint | undefined | null,
  sqrtPriceX96: bigint | undefined | null,
  zeroForOne: boolean,
): number | null => {
  if (!sqrtPriceX96 || !sqrtPriceX96After) {
    return null
  }
  try {
    // For better precision, multiply numerator by 1e18
    const numerator = zeroForOne
      ? sqrtPriceX96 * ONE_WITH_DECIMALS
      : sqrtPriceX96After * ONE_WITH_DECIMALS

    const denominator = zeroForOne ? sqrtPriceX96After : sqrtPriceX96

    if (denominator === 0n) {
      return null
    }

    const ratio = numerator / denominator
    const formattedRatio = formatBigIntToString(ratio, Number(EXTEND_DECIMALS))

    if (!formattedRatio) {
      return null
    }

    // Calculate percentage difference from 1.0 and convert to number
    const difference = parseFloat(formattedRatio) - 1
    return difference * Number(HUNDRED)
  } catch (error) {
    console.error("Error calculating price impact:", error)
    return null
  }
}
