import { isUndefined } from "lodash"
import { type Token } from "src/types"
import { isWrappedGasToken } from "src/utils/isWrappedGasToken"
import { formatNumberAmount } from "src/utils/formatNumberAmount"
import { ActionButton } from "src/components/ActionButton"
import { DoubleTokenLogo } from "src/components/TokenBadge"
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid"
import { getHealthFactorStatus } from "../managePosition/helpers/getHealthFactorIndicatorColor"

interface PositionHeaderProps {
  chainId?: number
  token0: Token | null
  token1: Token | null
  zeroForOne?: boolean
  healthFactor?: number | null
  leverageCurrent?: number | undefined
  handlePrimaryButton: () => void
  handleSecondaryButton: () => void
  primaryText?: string
  secondaryText?: string
}

export const PositionHeader = ({
  chainId,
  token0,
  token1,
  healthFactor,
  zeroForOne,
  leverageCurrent,
  handlePrimaryButton,
  handleSecondaryButton,
  primaryText = "Manage Position",
  secondaryText = "Close Position",
}: PositionHeaderProps) => {
  const isLong =
    !isUndefined(zeroForOne) && chainId
      ? (!zeroForOne && isWrappedGasToken(token1, chainId)) ||
        (zeroForOne && isWrappedGasToken(token0, chainId))
      : undefined

  const isLiquidatable = healthFactor
    ? getHealthFactorStatus(healthFactor) === "liquidatable"
    : undefined

  const leverageCurrentFormatted = leverageCurrent
    ? formatNumberAmount(leverageCurrent.toString(), true)
    : undefined

  return (
    <div className="flex flex-col border-b border-marginalGray-800 p-4 pb-2">
      <div className="flex w-full flex-col items-start space-y-4 rounded-t-3xl md:flex-row md:items-center md:justify-between md:gap-4 md:space-y-0">
        <div className="flex items-center space-x-2">
          <DoubleTokenLogo token0={token0 as Token} token1={token1 as Token} size={8} />

          <div className="flex flex-col">
            <div className="flex items-center space-x-2 text-lg text-marginalGray-200">
              <div>
                {token0?.symbol} / {token1?.symbol}
              </div>

              {leverageCurrentFormatted && !isUndefined(isLong) && (
                <div
                  className={`${
                    isLong
                      ? "bg-success-800 text-success-500"
                      : "bg-error-800 text-error-500"
                  } my-auto gap-2 rounded-lg px-1.5 py-1 text-sm`}
                >
                  {isLong ? `LONG` : `SHORT`} {leverageCurrentFormatted}X
                </div>
              )}
            </div>

            <p className="text-xs text-marginalGray-600 md:text-sm">
              {token0?.name} ∙ {token1?.name}
            </p>
          </div>
        </div>
        <div className="flex w-full justify-center space-x-2 md:w-fit md:justify-end md:space-x-4">
          <ActionButton action={primaryText} onClick={handlePrimaryButton} size="sm" />
          <ActionButton
            action={secondaryText}
            onClick={handleSecondaryButton}
            primary={false}
            size="sm"
          />
        </div>
      </div>

      {isLiquidatable && (
        <div className="mt-4 flex flex-wrap items-center gap-2 text-sm">
          <div className="flex items-center space-x-2 rounded-lg bg-error-800 px-2 py-1">
            <p className="flex items-center space-x-2 text-error-500">
              <ExclamationTriangleIcon className="inline w-4 min-w-4" />
              <span>health factor below 1.0</span>
            </p>
          </div>
          <p className="text-marginalGray-200">
            deposit more collateral to restore health factor.
          </p>
        </div>
      )}
    </div>
  )
}
