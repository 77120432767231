export type HealthFactorStatus =
  | "critical"
  | "warning"
  | "healthy"
  | "default"
  | "liquidatable"

export const getHealthFactorIndicatorColor = (
  healthFactor: number | null | undefined,
): string => {
  const healthFactorStatus = getHealthFactorStatus(healthFactor)

  switch (healthFactorStatus) {
    case "critical":
    case "liquidatable":
      return "text-error-500"
    case "warning":
      return "text-warning-500"
    case "healthy":
      return "text-success-500"
    default:
      return "text-white"
  }
}

export const getHealthFactorStatus = (
  healthFactor: number | null | undefined,
): HealthFactorStatus => {
  if (healthFactor === null || healthFactor === undefined) {
    return "default"
  }

  if (healthFactor <= 1) {
    return "liquidatable"
  }

  if (healthFactor > 1 && healthFactor <= 1.25) {
    return "critical"
  }

  if (healthFactor > 1.25 && healthFactor <= 1.5) {
    return "warning"
  }

  return "healthy"
}
