import { useState } from "react"
import { RotatingActiveArrow } from "src/components/RotatingActiveArrow"

interface AccordionProps {
  title: string
  content?: any
}

const LaunchDetailsAccordion = ({ title, content }: AccordionProps) => {
  const [show, setShow] = useState(true)

  return (
    <div
      id="accordion-container"
      className="mx-auto flex w-full max-w-[380px] flex-col rounded-2xl border border-marginalGray-800 bg-marginalGray-900 shadow-outerBlack sm:max-w-[440px] lg:max-w-[616px]"
    >
      <div id="accordion-title" className="flex justify-between px-4 py-4">
        <span className="text-xl text-marginalGray-200">
          {title}
        </span>

        <div
          onClick={() => setShow(!show)}
          className="flex cursor-pointer items-center hover:opacity-60"
        >
          <RotatingActiveArrow isActive={show} />
        </div>
      </div>

      {show && (
        <>
          <hr className="m-0 h-px border-none bg-marginalGray-800" />

          <div id="accordion-content" className="px-4 py-4">
            {content}
          </div>
        </>
      )}
    </div>
  )
}

export default LaunchDetailsAccordion
// transition-max-height ease-in-out transform-gpu duration-500 overflow-y-hidden
