import _ from "lodash"
import { isBlank } from "src/utils/isBlank"
import { isOnlyZeroes } from "src/utils/isOnlyZeroes"
import { useCallback, useMemo } from "react"
import { useAppDispatch, useAppSelector } from "src/state/hooks"
import { AppState } from "src/state"
import { createAsyncThunk } from "@reduxjs/toolkit"
import { Address } from "viem"
import { AddLiquidityParams } from "src/types"
import { getAddLiquidityQuote } from "src/pages/addLiquidity/helpers/getAddLiquidityQuote"
import {
  setInputValueA,
  setInputValueB,
  setTokenA,
  setTokenB,
  resetAddLiquidityState,
} from "./reducer"
import { Token } from "src/types"

export const useAddLiquidityState = (): AppState["addLiquidity"] => {
  return useAppSelector((state) => state.addLiquidity)
}

export function useAddLiquidityActionHandlers(): {
  onUserInputA: (value: string) => void
  onUserInputB: (value: string) => void
  onSelectTokenA: (token: Token) => void
  onSelectTokenB: (token: Token) => void
  onResetAddLiquidityInput: () => void
  onTokenASelect: (token: Token) => void
  onTokenBSelect: (token: Token) => void
  onInputAChange: (value: string) => void
  onInputBChange: (value: string) => void
  onResetAddLiquidityState: () => void
} {
  const dispatch = useAppDispatch()

  const onUserInputA = useCallback(
    (value: string) => {
      dispatch(setInputValueA(value))
    },
    [dispatch],
  )

  const onUserInputB = useCallback(
    (value: string) => {
      dispatch(setInputValueB(value))
    },
    [dispatch],
  )

  const onSelectTokenA = useCallback(
    (token: Token) => {
      dispatch(setTokenA(token))
    },
    [dispatch],
  )

  const onSelectTokenB = useCallback(
    (token: Token) => {
      dispatch(setTokenB(token))
    },
    [dispatch],
  )

  const onResetAddLiquidityInput = useCallback(() => {
    dispatch(resetAddLiquidityState())
  }, [dispatch])

  const onTokenASelect = useCallback(
    (token: Token) => {
      dispatch(setTokenA(token))
    },
    [dispatch],
  )

  const onTokenBSelect = useCallback(
    (token: Token) => {
      dispatch(setTokenB(token))
    },
    [dispatch],
  )

  const onInputAChange = useCallback(
    (value: string) => {
      dispatch(setInputValueA(value))
    },
    [dispatch],
  )

  const onInputBChange = useCallback(
    (value: string) => {
      dispatch(setInputValueB(value))
    },
    [dispatch],
  )

  const onResetAddLiquidityState = useCallback(() => {
    dispatch(resetAddLiquidityState())
  }, [dispatch])

  return {
    onUserInputA,
    onUserInputB,
    onSelectTokenA,
    onSelectTokenB,
    onResetAddLiquidityInput,
    onTokenASelect,
    onTokenBSelect,
    onInputAChange,
    onInputBChange,
    onResetAddLiquidityState,
  }
}

export function useAddLiquidityStatus() {
  const { tokenA, tokenB, inputValueA, inputValueB } = useAddLiquidityState()

  const isInputAValid = useMemo(() => {
    return _.isString(inputValueA) && !isBlank(inputValueA) && !isOnlyZeroes(inputValueA)
  }, [inputValueA])

  const isInputBValid = useMemo(() => {
    return _.isString(inputValueB) && !isBlank(inputValueB) && !isOnlyZeroes(inputValueB)
  }, [inputValueB])

  const isValidInputs = useMemo(() => {
    return isInputAValid || isInputBValid
  }, [isInputAValid, isInputBValid])

  const isTokenAValid = useMemo(() => {
    return !_.isNull(tokenA)
  }, [tokenA])

  const isTokenBValid = useMemo(() => {
    return !_.isNull(tokenB)
  }, [tokenB])

  const isValidTokens = useMemo(() => {
    return isTokenAValid && isTokenBValid
  }, [isTokenAValid, isTokenBValid])

  const isValidAddLiquidityInputs = useMemo(() => {
    return isValidInputs && isValidTokens
  }, [isValidInputs, isValidTokens])

  return {
    isValidInputs,
    isValidTokens,
    isValidAddLiquidityInputs,
  }
}

export const fetchAddLiquidityQuote = createAsyncThunk(
  "addLiquidity/fetchAddLiquidityQuote",
  async ({
    chainId,
    quoterAddress,
    params,
  }: {
    chainId: number
    quoterAddress: Address
    params: AddLiquidityParams
  }) => {
    const quote = await getAddLiquidityQuote(chainId, quoterAddress, params)
    if (!quote) {
      throw new Error("Failed to fetch add liquidity quote")
    }
    return quote
  },
)
