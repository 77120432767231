export const DiscordIcon = () => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.6875 11.0938L10.625 12.3438C10.625 12.3438 12.4161 11.7823 13.75 10.7812C13.75 5.15625 12.1875 3.55451 12.1875 3.55451C12.1875 3.55451 10.9375 2.96875 9.375 2.65625L8.99869 3.42544C8.99869 3.42544 8.30331 3.28125 7.5 3.28125C6.69669 3.28125 6.00132 3.42544 6.00132 3.42544L5.625 2.65625C4.0625 2.96875 2.8125 3.55451 2.8125 3.55451C2.8125 3.55451 1.25 5.15625 1.25 10.7812C2.58387 11.7823 4.375 12.3438 4.375 12.3438L5.3125 11.0938"
      stroke="#BEBEBE"
    />
    <path
      d="M10.9375 10.4688C10.9375 10.4688 9.50306 11.4062 7.5 11.4062C5.49692 11.4062 4.0625 10.4688 4.0625 10.4688"
      stroke="#BEBEBE"
      stroke-linecap="square"
      stroke-linejoin="round"
    />
    <path
      d="M10.7812 7.65625C10.7812 8.34662 10.2916 8.90625 9.6875 8.90625C9.08344 8.90625 8.59375 8.34662 8.59375 7.65625C8.59375 6.96588 9.08344 6.40625 9.6875 6.40625C10.2916 6.40625 10.7812 6.96588 10.7812 7.65625Z"
      stroke="#BEBEBE"
    />
    <path
      d="M6.40625 7.65625C6.40625 8.34662 5.91656 8.90625 5.3125 8.90625C4.70844 8.90625 4.21875 8.34662 4.21875 7.65625C4.21875 6.96588 4.70844 6.40625 5.3125 6.40625C5.91656 6.40625 6.40625 6.96588 6.40625 7.65625Z"
      stroke="#BEBEBE"
    />
  </svg>
)
