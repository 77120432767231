/**
 * Derives the opposite token amount needed for adding liquidity when one input is provided
 * @param inputAmount - The amount of the input token
 * @param isToken0 - Whether the input token is token0
 * @param poolPriceX96 - The pool price in X96 format
 * @returns The derived opposite token amount as a string, or null if calculation fails
 */
export function deriveOppositeAddLiquidityInputFromSingleInput(
  inputAmount: string | undefined,
  isToken0: boolean,
  poolPriceX96?: string,
): string | null {
  // Early return if required parameters are missing
  if (!poolPriceX96 || !inputAmount) {
    return null
  }

  try {
    // Convert inputs to BigInt
    const inputAmountBigInt = BigInt(inputAmount)
    const poolPriceX96BigInt = BigInt(poolPriceX96)
    const squaredPoolPriceX96 = poolPriceX96BigInt ** 2n
    const bitwiseLeftShift = 2n ** 192n

    // Calculate opposite amount based on whether input is token0 or token1
    if (isToken0) {
      // If input is token0, calculate token1 amount
      // amount1 = (amount0 * poolPriceX96^2) / 2^192
      return ((inputAmountBigInt * squaredPoolPriceX96) / bitwiseLeftShift).toString()
    } else {
      // If input is token1, calculate token0 amount
      // amount0 = (amount1 * 2^192) / poolPriceX96^2
      return ((inputAmountBigInt * bitwiseLeftShift) / squaredPoolPriceX96).toString()
    }
  } catch (error) {
    console.error("Error calculating opposite liquidity amount:", error)
    return null
  }
}
