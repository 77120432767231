import _ from "lodash"
import { Address, maxUint256 } from "viem"
import { useParams } from "react-router-dom"
import { InputContainer } from "src/components/ui/InputContainer"
import { useNavigateRoutes } from "src/hooks/useNavigateRoutes"
import { usePoolsData, getPoolDataByAddress } from "src/hooks/usePoolsData"
import { useAccount } from "wagmi"
import { ListRow } from "src/components/ui/ListRow"
import { useErc20TokenSymbol } from "src/hooks/useErc20TokenSymbol"
import { Card } from "src/components/Card"
import { CaretLeftIcon } from "src/components/Icons/CaretLeftIcon"
import { useNetworkChangeRedirect } from "src/hooks/useNetworkChangeRedirect"
import { StakeInput } from "src/pages/stake/components/StakeInput"
import { useUnstakeActionHandlers, useUnstakeState } from "src/state/unstake/hook"
import { useEffect, useMemo, useState } from "react"
import { isBlank } from "src/utils/isBlank"
import { formatStringToBigInt } from "src/utils/formatStringToBigInt"
import { useErc20TokenAllowance } from "src/hooks/useErc20TokenAllowance"
import { isOnlyZeroes } from "src/utils/isOnlyZeroes"
import { approveErc20Token } from "src/utils/actions/approveErc20Token"
import { waitForTransactionReceipt } from "@wagmi/core"
import { wagmiConfig } from "src/wagmiConfig"
import { getTransactionError } from "src/utils/getTransactionError"
import { TransactionStatusModal } from "src/components/TransactionStatusModal"
import { unstake } from "src/utils/actions/unstake"
import { ConfirmUnstakeButton } from "src/pages/unstake/ConfirmUnstakeButton"
import { trimTrailingZeroes } from "src/utils/trimTrailingZeroes"
import { usePoolRewardsAPR } from "src/hooks/usePoolRewardsAPR"
import { MARGINAL_DAO_TOKEN } from "src/constants/tokens"
import { PercentSlider } from "src/components/PercentSlider"
import { useTransactionModal } from "src/pages/trade/hooks/useTransactionModal"
import { useUserPoolBalance } from "src/pages/pool/hooks/useUserPoolBalance"
import { useUserStakedPoolBalance } from "src/pages/pool/hooks/useUserStakedPoolBalance"
import { formatNumberAmount } from "src/utils/formatNumberAmount"
import { type PoolData, type Token } from "src/types"
import { convertMaintenanceToLeverage } from "src/utils/conversions/convertMaintenanceToLeverage"
import { useApplicationState } from "src/state/application/hooks"
import { DoubleTokenLogo } from "src/components/TokenBadge"
import { getTokenByAddress } from "src/constants/tokenList"

const Unstake = () => {
  const { chainId } = useApplicationState()
  const { address } = useAccount()
  const { poolAddress } = useParams()
  const { poolsDataByAddress } = usePoolsData()
  const { unstakePool, unstakeTokenAddress, inputValue } = useUnstakeState()
  const { onUserInput, onResetUnstakeState } = useUnstakeActionHandlers()

  const { onNavigateToPool, onNavigateToPools } = useNavigateRoutes()
  const handleReturnToPool = (poolAddress: string) => onNavigateToPool(poolAddress)
  useNetworkChangeRedirect(onNavigateToPools)

  const pool = getPoolDataByAddress(poolAddress as string, poolsDataByAddress)
  const lpTokenSymbol = useErc20TokenSymbol(pool?.poolAddress as Address)
  const { balances, refetch: refetchUserLpBalance } = useUserPoolBalance([pool], address)
  const { balances: userStakedBalance, refetch: refetchStakedBalance } =
    useUserStakedPoolBalance([pool as PoolData], address)
  const userLpBalance = balances?.[0]
  const userStakedLpBalance = userStakedBalance[pool?.stakePool as Address]
  const {
    token0: poolToken0,
    token1: poolToken1,
    maintenance,
    decimals: poolDecimals,
  } = (pool as PoolData) || {}
  const token0 = getTokenByAddress(poolToken0?.address, chainId) ?? poolToken0
  const token1 = getTokenByAddress(poolToken1?.address, chainId) ?? poolToken1

  const [percentage, setPercentage] = useState<number>(0)
  const [percentError, setPercentError] = useState(false)
  const leverageMax = convertMaintenanceToLeverage(maintenance)

  const formattedInput = !isBlank(inputValue)
    ? formatStringToBigInt(inputValue, poolDecimals)
    : 0n

  const { allowance, fetchAllowance } = useErc20TokenAllowance(
    pool?.poolAddress as Address,
    Number(pool?.decimals),
    address,
    pool?.stakePool as Address,
    chainId,
  )

  useEffect(() => {
    if (unstakeTokenAddress && address) {
      if (!isOnlyZeroes(inputValue)) {
        ;(async () => {
          await fetchAllowance()
        })()
      }
    }
  }, [chainId, unstakeTokenAddress, address, fetchAllowance, inputValue])

  useEffect(() => {
    onResetUnstakeState()
  }, [chainId, onResetUnstakeState])

  const durationOneYearInSeconds = 86400 * 365

  const { aprPercentage } = usePoolRewardsAPR(
    poolAddress as Address,
    MARGINAL_DAO_TOKEN as Address,
    poolAddress as Address,
    durationOneYearInSeconds,
    chainId,
  )

  const isApproved = useMemo(() => {
    if (_.isUndefined(allowance) || _.isUndefined(formattedInput)) return true
    return allowance >= formattedInput
  }, [allowance, formattedInput])

  const isBalanceSufficient = useMemo(() => {
    if (_.isUndefined(userStakedLpBalance?.balance)) return false
    if (_.isUndefined(formattedInput)) return true

    return userStakedLpBalance?.balance >= formattedInput
  }, [userStakedLpBalance, formattedInput])

  const {
    state: {
      showConfirm,
      isPendingWallet,
      isPendingApprove,
      isPendingTx,
      isTxSubmitted,
      txHash,
      txError,
    },
    openConfirmModal,
    closeConfirmModal,
    resetState: resetTransactionState,
    setPendingWallet,
    setPendingApprove,
    setTxSubmitted,
  } = useTransactionModal()

  const onSuccessReset = () => {
    resetTransactionState()
    onResetUnstakeState()
    setPercentage(0)
  }

  const handleOpenConfirmModal = () => {
    openConfirmModal()
  }

  const handleCloseConfirmModal = () => {
    closeConfirmModal()
  }

  const approveToken = async (
    amount: bigint | undefined,
    spenderAddress: Address,
    tokenAddress: Address,
  ) => {
    if (!amount) {
      throw new Error("Require amount to approve")
    }
    if (!spenderAddress) {
      throw new Error("Require spender address to approve")
    }
    if (!tokenAddress) {
      throw new Error("Require token address to approve")
    }

    try {
      openConfirmModal()
      setPendingWallet(true)
      const txHash = await approveErc20Token({
        chainId,
        amount: maxUint256,
        spenderAddress,
        tokenAddress,
      })
      setPendingApprove(true)
      await waitForTransactionReceipt(wagmiConfig, {
        hash: txHash,
      })
      setPendingApprove(false)
      setTxSubmitted(true, txHash)
      fetchAllowance()
    } catch (error) {
      console.log("Error approving token: ", error)
    }
  }

  const handleUnstakeCallback = async () => {
    try {
      if (!isApproved) {
        return new Error("Unstake not approved")
      }
      if (!pool?.stakePool || !formattedInput) {
        return new Error("Missing required parameters for unstake")
      }
      openConfirmModal()
      setPendingWallet(true)
      const transaction = await unstake(
        pool?.stakePool as Address,
        formattedInput,
        chainId,
      )
      setPendingWallet(false)
      setTxSubmitted(true, transaction.transactionHash)
      onSuccessReset()
      refetchUserLpBalance()
      refetchStakedBalance()
      return transaction.transactionHash
    } catch (error) {
      console.error("Error executing unstake: ", error)
      if (getTransactionError(error) === "Wallet rejected transaction.") {
        closeConfirmModal()
      }
    }
  }

  const handleApplyMaxBalance = () => {
    if (!_.isUndefined(userLpBalance)) {
      const maxBalance = trimTrailingZeroes(userLpBalance?.parsedBalance)

      if (!_.isUndefined(maxBalance)) {
        onUserInput(maxBalance)
      }
    }
  }

  return (
    <Card>
      <div className="flex justify-between">
        <div
          onClick={() => handleReturnToPool(poolAddress as string)}
          className="flex cursor-pointer items-center justify-start space-x-1 text-marginalGray-200"
        >
          <CaretLeftIcon />
          <span className="text-sm text-marginalGray-200">
            Back to Pool
          </span>
        </div>
      </div>

      <div className="relative mx-auto mt-12 w-full max-w-[343px] shadow-outerBlack sm:max-w-[440px]">
        <div className="rounded-t-3xl border border-marginalGray-800 bg-marginalGray-900">
          <div className="flex items-center justify-between p-4">
            <div className="relative text-lg text-marginalGray-200 md:text-xl">
              Unstake
            </div>
          </div>

          <div className="px-4 pb-4 pt-0">
            <div className="rounded-xl border border-marginalGray-800 bg-marginalGray-950 p-4">
              <div className="flex items-center space-x-2">
                <DoubleTokenLogo
                  token0={token0 as Token}
                  token1={token1 as Token}
                  size={8}
                />

                <div className="flex flex-col overflow-x-hidden">
                  <div className="flex items-center space-x-1 text-lg text-marginalGray-200">
                    <pre>{token0?.symbol}</pre>
                    <div className="my-auto">/</div>
                    <pre>{token1?.symbol}</pre>
                    <div className="ml-3">{leverageMax}x</div>
                  </div>

                  <div className="flex flex-nowrap items-center text-xs text-marginalGray-600 md:text-sm">
                    <pre>{token0?.name}</pre>

                    <div className="my-auto px-0.5">∙</div>
                    <pre>{token1?.name}</pre>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="relative">
          <div className="space-y-2 rounded-b-3xl border border-t-0 border-marginalGray-800 bg-marginalGray-900 p-4">
            <InputContainer id="add-liquidity-input-a-container">
              <div className="flex items-center space-x-1">
                <StakeInput inputValue={inputValue} onChange={onUserInput} />
              </div>

              <div className="flex space-x-2 text-sm">
                <div
                  className="cursor-pointer rounded-sm bg-[#4C2D1E] px-0.5 text-marginalOrange-500"
                  onClick={handleApplyMaxBalance}
                >
                  Max
                </div>
              </div>
            </InputContainer>

            <PercentSlider
              userInput={inputValue}
              isInputValid={true}
              formattedBalance={userStakedLpBalance?.parsedBalance}
              margin={userStakedLpBalance?.balance}
              decimals={Number(poolDecimals)}
              onUserInput={onUserInput}
              selectedPercentage={percentage}
              setPercentage={setPercentage}
              setPercentError={setPercentError}
              percentError={percentError}
            />

            <div className="space-y-2 py-2 text-sm text-marginalGray-600">
              <ListRow
                item="Available to Unstake"
                value={
                  <div className="flex items-baseline space-x-1 text-marginalGray-200">
                    <span>
                      {userStakedLpBalance
                        ? formatNumberAmount(userStakedLpBalance?.parsedBalance, true)
                        : "0.0"}
                    </span>
                    <span>{lpTokenSymbol}</span>
                  </div>
                }
              />
              <ListRow
                item="APR"
                value={
                  <div className="flex items-baseline space-x-1 text-marginalGray-200">
                    <pre>{aprPercentage || "-"}%</pre>
                  </div>
                }
              />
            </div>
            <ConfirmUnstakeButton
              chainId={chainId}
              isInputValid={true}
              isTokenValid={true}
              isTokenApproved={isApproved}
              isBalanceSufficient={isBalanceSufficient}
              isPendingWallet={isPendingWallet}
              isPendingApprove={isPendingApprove}
              tokenSymbol={lpTokenSymbol}
              onApproveToken={() =>
                approveToken(
                  formattedInput,
                  unstakePool?.stakePool as Address,
                  unstakeTokenAddress as Address,
                )
              }
              onConfirm={handleOpenConfirmModal}
              unstakeCallback={handleUnstakeCallback}
              error={null}
            />
          </div>
        </div>
      </div>

      <TransactionStatusModal
        chainId={chainId}
        open={showConfirm}
        onOpen={handleOpenConfirmModal}
        onClose={handleCloseConfirmModal}
        onReset={onSuccessReset}
        onCallback={handleUnstakeCallback}
        isPendingWallet={isPendingWallet}
        isPendingApprove={isPendingApprove}
        isPendingTx={isPendingTx}
        isTxSubmitted={isTxSubmitted}
        txHash={txHash}
        txError={txError}
        hasConfirmModal={false}
        onSuccessText="Unstaking Success"
      />
    </Card>
  )
}

export default Unstake
