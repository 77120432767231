import { useReadContracts } from "wagmi"
import { Address } from "viem"
import { V1_APR_ADDRESS } from "src/constants/addresses"
import { getValidAddress } from "src/utils/getValidAddress"
import marginalV1APRAbi from "src/constants/abis/MarginalV1APR.json"
import { type PoolData } from "src/types"
import { formatBigIntToString } from "src/utils/formatBigIntToString"

interface YieldRateParams {
  stakingToken: Address
  rewardsToken: Address
  pool: PoolData
  duration: number
  chainId: number
}

interface ContractConfig {
  chainId: number
  address: Address
  abi: any
  functionName: "quotePercentageRate"
  args: [Address, Address, Address, number]
}

export interface PoolYieldRate {
  pool: PoolData
  yieldRate: bigint
  parsedYieldRate?: string
}

const validateInputs = ({
  stakingToken,
  rewardsToken,
  pool,
  duration,
  chainId,
}: YieldRateParams): boolean => {
  return (
    getValidAddress(stakingToken) &&
    getValidAddress(rewardsToken) &&
    getValidAddress(pool.poolAddress) &&
    duration > 0 &&
    chainId > 0 &&
    V1_APR_ADDRESS[chainId] !== undefined
  )
}

const createContractConfig = ({
  stakingToken,
  rewardsToken,
  pool,
  duration,
  chainId,
}: YieldRateParams): ContractConfig => {
  return {
    chainId,
    address: V1_APR_ADDRESS[chainId],
    abi: marginalV1APRAbi,
    functionName: "quotePercentageRate",
    args: [stakingToken, rewardsToken, pool.poolAddress as Address, duration],
  }
}

const formatYieldRate = (
  rawYieldRate: bigint,
  decimals: string,
  pool: PoolData,
): PoolYieldRate => {
  const parsedYieldRate = formatBigIntToString(rawYieldRate, decimals, 4)

  return {
    pool,
    yieldRate: rawYieldRate,
    parsedYieldRate,
  }
}

export const useIncentivizedPoolYieldPercentageRate = (
  chainId: number,
  pool: PoolData,
  stakingTokenAddress: Address,
  rewardTokenAddress: Address,
): PoolYieldRate | undefined => {
  const params: YieldRateParams = {
    stakingToken: stakingTokenAddress,
    rewardsToken: rewardTokenAddress,
    pool,
    duration: 365 * 24 * 60 * 60, // 1 year in seconds
    chainId,
  }

  const isValid = validateInputs(params)
  const contracts = isValid ? [createContractConfig(params)] : []

  const { data } = useReadContracts({
    contracts,
    query: {
      enabled: isValid,
    },
  })

  const yieldRateData = data?.[0]?.result
    ? formatYieldRate(data[0].result as bigint, pool.decimals, pool)
    : undefined

  return yieldRateData
}
