import { useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { useAccount } from "wagmi"
import { Address, zeroAddress } from "viem"
import { useTokenUri } from "src/hooks/useTokenUri"
import { useNavigateRoutes } from "src/hooks/useNavigateRoutes"
import { V1_QUOTER_ADDRESS } from "src/constants/addresses"
import { NFTView } from "src/pages/position/components/NFTView"
import { type Token } from "src/types"
import { CaretLeftIcon } from "src/components/Icons/CaretLeftIcon"
import { useInversePrice } from "src/hooks/useInversePrice"
import { useNetworkChangeRedirect } from "src/hooks/useNetworkChangeRedirect"
import { usePositionsOnChainState } from "src/pages/positions/hooks/usePositionsOnChainState"
import { usePoolsData, getPoolDataByAddress } from "src/hooks/usePoolsData"
import { getIgniteQuote } from "src/pages/position/managePosition/helpers/getIgniteQuote"
import { derivePositionData } from "src/pages/position/managePosition/helpers/derivePositionData"
import { validateIgniteQuoteParams } from "src/pages/positions/helpers/validateIgniteQuoteParams"
import { constructIgniteQuoteParams } from "src/pages/positions/helpers/constructIgniteQuoteParams"
import { useGetCurrentBlockTimestamp } from "src/hooks/useGetCurrentBlockTimestamp"
import { deriveIgniteQuoteData } from "src/pages/position/managePosition/helpers/deriveIgniteQuoteData"
import { useSettingsState } from "src/state/settings/hooks"
import { useUniswapV3PoolState } from "src/pages/position/managePosition/hooks/useUniswapV3PoolState"
import { useLivePoolPrices } from "src/pages/trade/hooks/useLivePoolPrices"
import { extrapolateTokenPair } from "src/utils/extrapolateTokenPair"
import { IgniteQuote } from "src/types"
import { useSubgraphIndexedPosition } from "src/pages/positions/hooks/useSubgraphIndexedPositions"
import { useApplicationState } from "src/state/application/hooks"
import { getTokenByAddress } from "src/constants/tokenList"
import { resolvePoolTokenPrice } from "src/utils/pools/resolvePoolTokenPrice"
import { PositionHeader } from "src/pages/position/components/PositionHeader"
import { PositionDetailsOverview } from "src/pages/position/components/PositionDetailsOverview"
import { PositionLinks } from "src/pages/position/components/PositionLinks"
import { PositionLoading } from "src/pages/position/components/PositionLoading"

export const Position = () => {
  const { chainId } = useApplicationState()
  const { address } = useAccount()
  const { positionKey } = useParams()
  const { position, isLoading: isLoadingPosition } =
    useSubgraphIndexedPosition(positionKey)
  const tokenId = position?.tokenId ?? ""
  const { data, isLoading: isLoadingPositionState } = usePositionsOnChainState(
    [tokenId],
    chainId,
  )
  const positionData = data[tokenId]
  const { poolsDataByAddress } = usePoolsData()
  const pool = getPoolDataByAddress(positionData?.pool, poolsDataByAddress)
  const metadata = useTokenUri(tokenId, chainId)
  const zeroForOne: boolean = positionData?.zeroForOne
  const token0 = getTokenByAddress(pool?.token0?.address, chainId) ?? pool?.token0
  const token1 = getTokenByAddress(pool?.token1?.address, chainId) ?? pool?.token1
  const currentBlockTimestamp = useGetCurrentBlockTimestamp(chainId)
  const [marginToken, debtToken] = zeroForOne ? [token1, token0] : [token0, token1]
  const { quoteToken } = extrapolateTokenPair(token0 ?? null, token1 ?? null, chainId)
  const [useInverse, onToggleInverse] = useInversePrice()
  const { maxSlippage, transactionDeadline } = useSettingsState()
  const { onNavigateToPositions, onNavigateToManagePosition, onNavigateToClosePosition } =
    useNavigateRoutes()
  useNetworkChangeRedirect(onNavigateToPositions)

  const { token0PriceInUSD, token1PriceInUSD } = useLivePoolPrices({
    chainId,
    selectedPool: pool,
    zeroForOne,
    useInverse,
    quoteToken,
    position: positionData,
  })

  const marginTokenPriceInUSD = resolvePoolTokenPrice(
    marginToken,
    pool,
    token0PriceInUSD,
    token1PriceInUSD,
  )

  const igniteParams = validateIgniteQuoteParams(
    position?.pool?.token0 as Token,
    position?.pool?.token1 as Token,
    position?.pool?.maintenance,
    position?.pool?.oracle,
    position?.tokenId,
    0n,
    address,
  )
    ? constructIgniteQuoteParams({
        token0: position?.pool?.token0?.address as Address,
        token1: position?.pool?.token1?.address as Address,
        maintenance: position?.pool?.maintenance,
        oracle: position?.pool?.oracle as Address,
        tokenId: position?.tokenId as string,
        amountOutMinimum: 0n,
        recipient: address ?? zeroAddress,
        currentBlockTimestamp,
        transactionDeadline,
      })
    : null

  const [igniteQuote, setIgniteQuote] = useState<IgniteQuote | null>(null)

  useEffect(() => {
    if (!igniteParams || !marginToken) {
      setIgniteQuote(null)
      return
    }

    ;(async () => {
      setIgniteQuote(
        await getIgniteQuote(
          chainId,
          V1_QUOTER_ADDRESS[chainId],
          igniteParams,
          marginToken,
        ),
      )
    })()
  }, [chainId, marginToken]) // eslint-disable-line react-hooks/exhaustive-deps

  const { margin, debt, size, totalSize, leverage, healthFactor, rewards } =
    derivePositionData(positionData, pool, marginToken, debtToken)

  const { price: uniswapV3PoolPrice, fee: uniswapV3PoolFee } = useUniswapV3PoolState(
    pool?.oracleAddress as Address,
  )

  const { profitLoss } = deriveIgniteQuoteData(
    igniteQuote,
    marginToken,
    igniteParams,
    maxSlippage,
    margin?.raw as bigint,
    debt?.raw as bigint,
    size?.raw as bigint,
    zeroForOne,
    uniswapV3PoolPrice,
    uniswapV3PoolFee as bigint,
  )

  const { oraclePrice, poolPrice, liquidationPrice, fundingRate } = useLivePoolPrices({
    chainId,
    selectedPool: {
      token0: pool?.token0 as Token,
      token1: pool?.token1 as Token,
      maintenance: position?.pool?.maintenance as bigint,
      oracleAddress: pool?.oracleAddress as Address,
    },
    zeroForOne,
    useInverse,
    quoteToken,
    position: {
      margin: margin?.raw as bigint,
      debt: debt?.raw as bigint,
      size: size?.raw as bigint,
    },
  })

  const isLoading = isLoadingPosition || isLoadingPositionState

  return (
    <div className="mx-auto flex w-full flex-col space-y-4 md:max-w-224 md:space-y-6">
      <div
        onClick={() => onNavigateToPositions()}
        className="flex cursor-pointer items-center justify-start space-x-1 text-marginalGray-200"
      >
        <CaretLeftIcon />
        <span className="text-sm">Back to Positions</span>
      </div>

      {isLoading ? (
        <PositionLoading />
      ) : (
        <>
          <div className="rounded-3xl border border-marginalGray-800 bg-marginalGray-900 shadow-outerBlack">
            <PositionHeader
              chainId={chainId}
              token0={token0 as Token}
              token1={token1 as Token}
              zeroForOne={zeroForOne}
              healthFactor={healthFactor}
              leverageCurrent={leverage?.current}
              handlePrimaryButton={() => onNavigateToManagePosition(positionKey)}
              handleSecondaryButton={() => onNavigateToClosePosition(positionKey)}
            />
            <div className="flex h-min w-full flex-col space-y-4 rounded-3xl p-2 md:flex-row md:space-x-2 md:space-y-0 md:p-4">
              <div className="max-w-100 flex w-full items-center overflow-hidden rounded-xl md:rounded-lg">
                <NFTView imgSrc={metadata?.image} />
              </div>
              <PositionDetailsOverview
                tokenId={tokenId}
                token0={token0 as Token}
                token1={token1 as Token}
                poolPrice={poolPrice}
                oraclePrice={oraclePrice}
                liquidationPrice={liquidationPrice}
                healthFactor={healthFactor}
                margin={margin?.parsed}
                marginToken={marginToken}
                marginTokenPriceInUSD={marginTokenPriceInUSD}
                totalSize={totalSize?.parsed}
                debt={debt?.parsed}
                debtToken={debtToken}
                profitLoss={profitLoss}
                escrowRewards={rewards?.parsed}
                fundingRate={fundingRate}
                useInverse={useInverse}
                onToggleInverse={onToggleInverse}
              />
            </div>
          </div>
          <PositionLinks
            chainId={chainId}
            poolAddress={pool?.poolAddress}
            token0={token0}
            token1={token1}
          />
        </>
      )}
    </div>
  )
}
