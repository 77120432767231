import { isUndefined } from "lodash"
import { formatBigIntToString } from "../formatBigIntToString"

/**
 * Decimal precision for leverage calculations (10^6)
 */
const LEVERAGE_DECIMAL_PRECISION = 1_000_000n

/**
 * Validates the input parameters for leverage calculation
 * @param margin - The position's margin amount
 * @param size - The position's size amount
 * @returns True if inputs are valid, false otherwise
 */
const validateLeverageInputs = (
  margin: bigint | undefined,
  size: bigint | undefined,
): boolean => {
  if (isUndefined(margin) || isUndefined(size)) {
    return false
  }

  if (typeof margin !== "bigint" || typeof size !== "bigint") {
    return false
  }
  // Check if margin is zero
  if (margin === 0n) {
    return false
  }

  return true
}

/**
 * Calculates the position's leverage ratio
 * Formula: leverage = (margin + size) * PRECISION / margin
 *
 * @param margin - The position's margin amount as bigint
 * @param size - The position's size amount as bigint
 * @returns The calculated leverage with 6 decimal precision, or undefined if calculation is not possible
 */
export const calculatePositionLeverage = (
  margin: bigint | undefined,
  size: bigint | undefined,
): string | undefined => {
  try {
    // Validate inputs
    if (!validateLeverageInputs(margin, size)) {
      return undefined
    }

    // At this point we know margin and size are defined
    const totalSize = margin! + size!

    // Calculate leverage: (totalSize * PRECISION) / margin
    const numerator = totalSize * LEVERAGE_DECIMAL_PRECISION
    const leverage = numerator / margin!

    // Format the result to a string with proper decimal places
    return formatBigIntToString(leverage, 6)
  } catch (error) {
    console.error("Error calculating position leverage:", error)
    return undefined
  }
}
