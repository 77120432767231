import { simulateContract, waitForTransactionReceipt, writeContract } from "@wagmi/core"
import MultiRewardsAbi from "src/constants/abis/MultiRewards.json"
import { type Address } from "viem"
import { wagmiConfig } from "src/wagmiConfig"

export async function getReward(spenderAddress: Address, chainId: number) {
  const { request } = await simulateContract(wagmiConfig, {
    chainId,
    address: spenderAddress,
    abi: MultiRewardsAbi,
    functionName: "getReward",
  })

  const transaction = await writeContract(wagmiConfig, request)

  const receipt = await waitForTransactionReceipt(wagmiConfig, { hash: transaction })

  return receipt
}
