import { ConnectButton } from "@rainbow-me/rainbowkit"
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline"
import { useState } from "react"
import NetworkModal from "./DisconnectedChainModal"
import { useApplicationState } from "src/state/application/hooks"
import { CHAINS_BY_ID } from "src/constants/chains/chains"

export const CustomConnectButton = () => {
  const [isModalOpen, setModalOpen] = useState(false)
  const { chainId } = useApplicationState()

  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        authenticationStatus,
        mounted,
      }) => {
        // Note: If your app doesn't use authentication, you
        // can remove all 'authenticationStatus' checks
        const ready = mounted && authenticationStatus !== "loading"
        const connected =
          ready &&
          account &&
          chain &&
          (!authenticationStatus || authenticationStatus === "authenticated")

        return (
          <div
            {...(!ready && {
              "aria-hidden": true,
              style: {
                opacity: 0,
                pointerEvents: "none",
                userSelect: "none",
              },
            })}
          >
            {(() => {
              if (!connected) {
                return (
                  <div className="flex items-center gap-1 py-1">
                    <NetworkModal
                      isOpen={isModalOpen}
                      onClose={() => setModalOpen(false)}
                    />
                    <button
                      onClick={() => setModalOpen(true)}
                      className="flex items-center px-2.5 py-2 text-sm text-marginalGray-200 hover:opacity-80"
                      type="button"
                    >
                      <div
                        style={{
                          background: "gray",
                          width: 16,
                          height: 16,
                          borderRadius: 999,
                          overflow: "hidden",
                          marginRight: 4,
                        }}
                      >
                        <img
                          alt={CHAINS_BY_ID[chainId]?.name ?? "Chain icon"}
                          src={CHAINS_BY_ID[chainId]?.chainImg}
                          style={{ width: 16, height: 16 }}
                        />
                      </div>
                      {CHAINS_BY_ID[chainId]?.name?.slice(0, 3)}
                    </button>
                    <button
                      onClick={openConnectModal}
                      type="button"
                      className="text-md flex items-center rounded-lg border-2 border-marginalOrange-500 bg-marginalOrange-500 px-2 py-1 text-marginalGray-100"
                    >
                      Connect Wallet
                    </button>
                  </div>
                )
              }

              if (chain.unsupported) {
                return (
                  <button
                    onClick={openChainModal}
                    type="button"
                    className="flex items-center space-x-1 rounded-lg bg-marginalGray-800 px-2 py-1 text-marginalGray-200"
                  >
                    <ExclamationTriangleIcon width={20} className="mt-1" />
                    <span>Wrong network</span>
                  </button>
                )
              }

              return (
                <div className="flex gap-1 py-1">
                  <button
                    onClick={openChainModal}
                    className="flex items-center px-2.5 py-2 text-sm text-marginalGray-200 hover:opacity-80"
                    type="button"
                  >
                    {chain.hasIcon && (
                      <div
                        style={{
                          background: chain.iconBackground,
                          width: 16,
                          height: 16,
                          borderRadius: 999,
                          overflow: "hidden",
                          marginRight: 4,
                        }}
                      >
                        {chain.iconUrl && (
                          <img
                            alt={chain.name ?? "Chain icon"}
                            src={chain.iconUrl}
                            style={{ width: 16, height: 16 }}
                          />
                        )}
                      </div>
                    )}
                    {chain.name?.slice(0, 3)}
                  </button>
                  <button
                    onClick={openAccountModal}
                    type="button"
                    className="whitespace-nowrap rounded-lg bg-marginalGray-800 px-2.5 py-2 text-sm text-marginalGray-200"
                  >
                    <span className="">{account.displayName}</span>
                    {account.displayBalance ? ` (${account.displayBalance})` : ""}
                  </button>
                </div>
              )
            })()}
          </div>
        )
      }}
    </ConnectButton.Custom>
  )
}
