import React, { useState, useEffect } from "react"
import { InformationCircleIcon } from "@heroicons/react/20/solid"

export const InfoTip = ({
  width = 12,
  height = 12,
  longText = false,
  children,
}: {
  width?: number
  height?: number
  longText?: boolean
  children: React.ReactNode
}) => {
  return (
    <MouseoverTooltip longText={longText} hoverContent={children}>
      <InformationCircleIcon
        width={width}
        height={height}
        className="cursor-pointer hover:opacity-70"
      />
    </MouseoverTooltip>
  )
}

export const MouseoverTooltip = ({
  children,
  hoverContent,
  timeoutInMs,
  show = false,
  longText = false,
}: {
  children: any
  hoverContent: any
  timeoutInMs?: number
  show?: boolean
  longText?: boolean
}) => {
  const [showTooltip, setShowTooltip] = useState(show)

  const activateTooltip = () => setShowTooltip(true)
  const hideTooltip = () => setShowTooltip(false)

  useEffect(() => {
    if (showTooltip && timeoutInMs) {
      const tooltipTimer = setTimeout(() => {
        setShowTooltip(false)
      }, timeoutInMs)

      return () => {
        clearTimeout(tooltipTimer)
      }
    }
    return
  }, [timeoutInMs, showTooltip])

  return (
    <div
      onMouseEnter={activateTooltip}
      onMouseLeave={hideTooltip}
      onClick={() => setShowTooltip(!showTooltip)}
      className="relative flex items-center"
    >
      {children}
      <Tooltip isHovered={showTooltip} longText={longText}>
        {hoverContent}
      </Tooltip>
    </div>
  )
}

const Tooltip = ({
  isHovered,
  longText,
  children,
}: {
  isHovered: boolean
  longText: boolean
  children: React.ReactNode
}) => {
  if (isHovered) {
    return (
      <div
        className={`${longText ? "-translate-x-full before:right-3 md:w-80" : "-translate-x-1/2 before:left-1/2 before:right-1/2 before:-translate-x-1/2 md:w-fit"} hover-content absolute left-1/2 top-full z-50 w-min text-wrap rounded-lg border border-solid border-borderGray bg-marginalGray-850 px-2 py-1 text-left text-sm text-white before:absolute before:-top-1 before:z-[-1] before:mx-auto before:h-2 before:w-2 before:rotate-45 before:border-l before:border-t before:border-borderGray before:bg-marginalGray-850 md:text-nowrap`}
      >
        <data>{children}</data>
      </div>
    )
  } else {
    return null
  }
}
