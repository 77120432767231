import { ChangeEvent } from "react"
import { NumericFormat } from "react-number-format"
import { cleanInput } from "src/utils/cleanInput"

interface AmountInputProps {
  value: string
  disabled?: boolean
  onChange: (value: any) => void
}

export const AmountInput = ({ value, disabled = false, onChange }: AmountInputProps) => {
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(cleanInput(e.target.value))
  }

  return (
    <NumericFormat
      inputMode="numeric"
      placeholder="0"
      value={value}
      pattern={disabled ? "[0-9.]+" : "^[0-9]+([.,]?[0-9]+)?$"}
      disabled={disabled}
      readOnly={disabled}
      onChange={handleInputChange}
      minLength={1}
      maxLength={79}
      autoComplete="off"
      thousandSeparator={true}
      allowNegative={false}
      className="w-full border-none bg-transparent p-0 text-2xl placeholder:text-zinc-400 focus:border-none focus:outline-none focus:ring-0 placeholder:dark:text-zinc-400"
    />
  )
}
