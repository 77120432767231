import { calculatePriceImpact } from "src/utils/conversions/calculatePriceImpact"
import { convertX96PricesToBigInt } from "src/utils/conversions/convertX96Price"
import { getSwapParamsWithSlippage } from "./getSwapParamsWithSlippage"
import { ExactInputSingleQuote } from "src/types"

interface DerivedSwapQuoteData {
  priceImpact: number | null
  derivedSwapParamsWithSlippage: any // Replace 'any' with actual type from getSwapParamsWithSlippage
  swapQuoteError: string | undefined
}

export const deriveSwapQuoteData = (
  swapQuote: ExactInputSingleQuote | null,
  sqrtPriceX96: bigint,
  zeroForOne: boolean,
  maxSlippage: string,
  quoteParams: any, // Replace 'any' with actual type from quoteExactInputSingleParams
): DerivedSwapQuoteData => {
  const priceImpact = calculatePriceImpact(
    swapQuote?.sqrtPriceX96After,
    sqrtPriceX96,
    zeroForOne,
  )

  const derivedSwapParamsWithSlippage = getSwapParamsWithSlippage(
    quoteParams,
    swapQuote,
    zeroForOne,
    convertX96PricesToBigInt(sqrtPriceX96),
    maxSlippage,
  )

  const swapQuoteError = checkSwapQuoteError(priceImpact, maxSlippage)

  return {
    priceImpact,
    derivedSwapParamsWithSlippage,
    swapQuoteError,
  }
}

const checkSwapQuoteError = (
  priceImpact: number | null | undefined,
  maxSlippage: string | undefined,
): string | undefined => {
  if (!priceImpact || !maxSlippage) {
    return undefined
  }

  if (priceImpact && maxSlippage && priceImpact > parseFloat(maxSlippage)) {
    return "Slippage exceeds tolerance"
  }

  return undefined
}
