import { isUndefined } from "lodash"

interface TokenAmounts {
  token0: bigint | null
  token1: bigint | null
}

/**
 * Constant for 2^96, used in UniswapV3 price calculations
 */
const BIT_PRECISION_96 = 2n ** 96n

/**
 * Calculates the token amounts in a Uniswap V3 pool based on liquidity and sqrt price
 * Uses native BigInt for calculations to avoid external dependencies
 *
 * @param liquidity - The liquidity amount in the pool
 * @param sqrtPriceX96 - The square root price scaled by 2^96
 * @returns Object containing calculated amounts for both tokens
 */
export function calculatePoolTokensLiquidityAmounts(
  liquidity?: bigint,
  sqrtPriceX96?: bigint,
): TokenAmounts {
  // Early return if required parameters are missing
  if (isUndefined(liquidity) || isUndefined(sqrtPriceX96)) {
    return { token0: null, token1: null }
  }

  try {
    // Calculate token0 amount: (liquidity * 2^96) / sqrtPriceX96
    const token0Amount = (liquidity * BIT_PRECISION_96) / sqrtPriceX96

    // Calculate token1 amount: (liquidity * sqrtPriceX96) / 2^96
    const token1Amount = (liquidity * sqrtPriceX96) / BIT_PRECISION_96

    return {
      token0: token0Amount,
      token1: token1Amount,
    }
  } catch (error) {
    console.error("Error calculating pool token amounts:", error)
    return { token0: null, token1: null }
  }
}
