export const PoolLoading = () => {
  return (
    <>
      <div className="rounded-3xl border border-marginalGray-800 bg-marginalGray-900 shadow-outerBlack">
        <PoolHeaderLoading />
        <div className="flex w-full flex-col space-x-0 p-2 md:flex-row md:space-x-2 md:p-4">
          <div className="flex w-full flex-col space-y-1 rounded-3xl">
            <div className="flex w-full flex-col space-y-4 rounded-xl bg-marginalBlack p-4 md:rounded-lg">
              <div className="h-4 w-28 rounded-xl bg-marginalGray-800"></div>

              <div className="flex w-full justify-between">
                <div className="flex w-full items-center space-x-2">
                  <div className="h-6 w-6 rounded-xl bg-marginalGray-800"></div>
                  <div className="h-8 w-28 rounded-xl bg-marginalGray-800"></div>
                </div>

                <div className="flex flex-col items-end space-y-1">
                  <div className="h-4 w-20 rounded-xl bg-marginalGray-800"></div>
                  <div className="h-4 w-20 rounded-xl bg-marginalGray-800"></div>
                </div>
              </div>

              <div className="flex w-full justify-between">
                <div className="h-4 w-11 rounded-xl bg-marginalGray-800"></div>
                <div className="h-4 w-28 rounded-xl bg-marginalGray-800"></div>
              </div>

              <div className="h-px bg-marginalGray-200/20" />

              <div className="h-4 w-28 rounded-xl bg-marginalGray-800"></div>

              <div className="flex justify-between">
                <div className="flex w-full flex-col items-start space-y-3">
                  <div className="h-4 w-10 rounded-xl bg-marginalGray-800"></div>
                  <div className="h-4 w-12 rounded-xl bg-marginalGray-800"></div>
                  <div className="h-4 w-12 rounded-xl bg-marginalGray-800"></div>
                </div>

                <div className="flex w-full flex-col items-end space-y-3">
                  <div className="h-4 w-12 rounded-xl bg-marginalGray-800"></div>
                  <div className="h-4 w-20 rounded-xl bg-marginalGray-800"></div>
                  <div className="h-4 w-16 rounded-xl bg-marginalGray-800"></div>
                </div>

                <div className="flex w-full flex-col items-end space-y-3">
                  <div className="h-4 w-7 rounded-xl bg-marginalGray-800"></div>
                  <div className="h-4 w-20 rounded-xl bg-marginalGray-800"></div>
                  <div className="h-4 w-16 rounded-xl bg-marginalGray-800"></div>
                </div>
              </div>
            </div>

            <div className="flex h-full w-full flex-col space-y-4 rounded-xl bg-marginalBlack p-4 md:rounded-lg">
              <div className="h-4 w-20 rounded-xl bg-marginalGray-800"></div>

              <div className="space-y-1">
                <div className="flex w-full justify-between">
                  <div className="h-4 w-20 rounded-xl bg-marginalGray-800"></div>
                  <div className="h-4 w-12 rounded-xl bg-marginalGray-800"></div>
                </div>

                <div className="flex w-full justify-between">
                  <div className="h-4 w-12 rounded-xl bg-marginalGray-800"></div>
                  <div className="h-4 w-20 rounded-xl bg-marginalGray-800"></div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full space-y-1">
            <div className="mt-2 flex h-fit w-full flex-col space-y-3 rounded-xl bg-marginalBlack p-4 md:mt-0 md:rounded-lg">
              <div className="h-4 w-28 rounded-xl bg-marginalGray-800"></div>

              <div className="flex w-full justify-between">
                <div className="h-8 w-28 rounded-xl bg-marginalGray-800"></div>

                <div className="flex flex-col items-end space-y-1">
                  <div className="h-4 w-20 rounded-xl bg-marginalGray-800"></div>
                  <div className="h-4 w-20 rounded-xl bg-marginalGray-800"></div>
                </div>
              </div>

              <div className="h-px bg-marginalGray-200/20" />

              <div className="space-y-3">
                <div className="h-4 w-12 rounded-xl bg-marginalGray-800"></div>
                <div className="flex w-full justify-between">
                  <div className="h-6 w-28 rounded-xl bg-marginalGray-800"></div>
                  <div className="h-4 w-20 rounded-xl bg-marginalGray-800"></div>
                </div>

                <div className="flex justify-between space-x-3">
                  <div className="h-10 w-full rounded-xl bg-marginalGray-800"></div>
                  <div className="h-10 w-full rounded-xl bg-marginalGray-800"></div>
                </div>
              </div>
            </div>

            <div className="mt-4 flex h-fit w-full flex-col space-y-3 rounded-xl bg-marginalBlack p-4 md:mt-0 md:rounded-lg">
              <div className="h-4 w-28 rounded-xl bg-marginalGray-800"></div>

              <div className="flex w-full justify-between">
                <div className="h-8 w-28 rounded-xl bg-marginalGray-800"></div>

                <div className="flex flex-col items-end space-y-1">
                  <div className="h-4 w-20 rounded-xl bg-marginalGray-800"></div>
                  <div className="h-4 w-20 rounded-xl bg-marginalGray-800"></div>
                </div>
              </div>
              <div className="h-10 w-full rounded-xl bg-marginalGray-800"></div>
            </div>
          </div>
        </div>
      </div>
      <PoolLinkLoading />
    </>
  )
}

const PoolHeaderLoading = () => {
  return (
    <div className="flex w-full flex-col space-y-4 border-b border-marginalGray-800 p-4 pb-2 md:flex-row md:items-center md:justify-between md:space-y-0">
      <div className="flex">
        <div className="relative flex">
          <div className="h-8 w-8 animate-pulse rounded-full bg-marginalGray-800"></div>
          <div className="absolute left-4 h-8 w-8 animate-pulse rounded-full bg-marginalGray-800"></div>
        </div>
        <div className="ml-8 flex flex-col space-y-1">
          <div className="h-4 w-32 animate-pulse rounded bg-marginalGray-800"></div>
          <div className="h-4 w-36 animate-pulse rounded bg-marginalGray-800"></div>
        </div>
      </div>
      <div className="flex h-10 w-full space-x-4 md:w-80">
        <div className="w-full animate-pulse rounded-lg bg-marginalGray-800"></div>
        <div className="w-full animate-pulse rounded-lg bg-marginalGray-800"></div>
      </div>
    </div>
  )
}

const PoolLinkLoading = () => {
  return (
    <div className="space-y-4 md:px-4 md:pb-4">
      <div className="h-6 w-28 rounded-xl bg-marginalGray-800"></div>
      <div className="flex flex-col space-y-6 md:flex-row md:space-y-0">
        <div className="flex w-full flex-col space-y-6">
          <PoolDetailLinkLoading />
          <PoolDetailLinkLoading />
        </div>

        <div className="mx-6 hidden h-20 w-px bg-marginalGray-200/20 md:block" />

        <div className="flex w-full flex-col space-y-6">
          <PoolDetailLinkLoading />
        </div>
      </div>
    </div>
  )
}

const PoolDetailLinkLoading = () => {
  return (
    <div className="flex items-center justify-between space-x-4">
      <div className="flex items-center space-x-2">
        <div className="h-6 w-6 rounded-xl bg-marginalGray-800"></div>
        <div className="h-8 w-20 rounded-xl bg-marginalGray-800"></div>
      </div>

      <div className="flex items-center space-x-2">
        <div className="h-8 w-32 rounded-xl bg-marginalGray-800"></div>
        <div className="h-8 w-8 rounded-xl bg-marginalGray-800"></div>
      </div>
    </div>
  )
}
