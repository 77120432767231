import { useState, useRef, useEffect } from "react"
import { useContainerDimensions } from "src/hooks/useContainerDimensions"

export const NFTView = ({ imgSrc }: { imgSrc: string }) => {
  const [isHover, setIsHover] = useState<boolean>(false)
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const imgRef = useRef<HTMLImageElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const { height } = useContainerDimensions(containerRef, true)

  useEffect(() => {
    if (imgSrc && imgRef.current && canvasRef.current)
      getSnapshot(imgRef.current, canvasRef.current, height)
  }, [imgSrc, height, isHover])

  return (
    <div className="relative flex h-full w-full flex-col items-center justify-center">
      <div
        ref={containerRef}
        className="flex w-full"
        onMouseOver={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        {imgSrc ? (
          <>
            <canvas
              ref={canvasRef}
              className={`absolute z-20 my-auto rounded-xl object-contain w-[${height}px] h-[${height}px] aspect-1 md:rounded-lg`}
            />
            <img
              ref={imgRef}
              src={imgSrc}
              alt="NFT"
              className={`relative aspect-1 w-full rounded-xl object-contain md:rounded-lg ${isHover ? "z-10" : "z-30"}`}
            />
          </>
        ) : (
          <NFTLoading />
        )}
      </div>
    </div>
  )
}

export const NFTLoading = () => {
  return (
    <div className="flex aspect-1 h-full w-full animate-pulse rounded-3xl bg-marginalGray-850"></div>
  )
}

/**
 * Takes a snapshot of an animated SVG and draws it onto the
 * referenced canvas.
 *
 * @param img - The image element representing the animated SVG
 * @param canvas - The canvas element representing the snapshot
 */
export function getSnapshot(
  img: HTMLImageElement,
  canvas: HTMLCanvasElement,
  targetHeight: number,
) {
  const context = canvas.getContext("2d")

  if (context) {
    // Assign image height/width to targetHeight
    let { width, height } = img
    height = targetHeight
    width = targetHeight

    // Scale canvas dimensions to ensure high DPI
    canvas.width = width * devicePixelRatio
    canvas.height = height * devicePixelRatio
    canvas.style.width = width + "px"
    canvas.style.height = height + "px"
    context.scale(devicePixelRatio, devicePixelRatio)

    // Draw snapshotted image onto canvas
    context.clearRect(0, 0, width, height)
    context.drawImage(img, 0, 0, width, height)
  }
}
