export type PriceImpactStatus = "severe" | "moderate" | "normal"

export const getPriceImpactIndicatorColor = (
  priceImpact: number | null | undefined,
): string => {
  const priceImpactStatus = getPriceImpactStatus(priceImpact)

  switch (priceImpactStatus) {
    case "severe":
      return "text-red-500"
    case "moderate":
      return "text-yellow-500"
    default:
      return "text-marginalGray-200"
  }
}

const getPriceImpactStatus = (
  priceImpact: number | null | undefined,
): PriceImpactStatus => {
  if (!priceImpact) return "normal"

  const priceImpactPercentage = Math.floor(Number(priceImpact) * 100)

  if (priceImpactPercentage >= 500) {
    return "severe"
  }

  if (priceImpactPercentage >= 100) {
    return "moderate"
  }

  return "normal"
}
