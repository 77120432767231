import {
  useCreatePoolActionHandlers,
  useCreatePoolState,
} from "src/state/createPool/hooks"

export const MAX_LEVERAGE_LIST = {
  x2: {
    leverage: 2,
    maintenance: 1000000,
  },
  x3: {
    leverage: 3,
    maintenance: 500000,
  },
  x5: {
    leverage: 5,
    maintenance: 250000,
  },
}

interface LeverageOptionProps {
  value: string
  selected: boolean
  onSelect: () => void
}

const LeverageOption = ({ value, selected, onSelect }: LeverageOptionProps) => {
  return (
    <div
      className={`flex cursor-pointer select-none flex-col items-center rounded-lg border-2 p-2 ${
        selected ? "border-marginalOrange-500 bg-marginalBlack" : "border-gray-600"
      } w-full transition-all`}
      onClick={onSelect}
    >
      <div className="text-base font-semibold text-[#CACACA]">{value}</div>
    </div>
  )
}

const SelectLeverage = () => {
  const { leverage: selectedLeverage } = useCreatePoolState()
  const { onSelectLeverage } = useCreatePoolActionHandlers()

  return (
    <div className="flex gap-3">
      {Object.values(MAX_LEVERAGE_LIST).map(({ leverage, maintenance }) => (
        <LeverageOption
          key={leverage}
          value={`${leverage}x`}
          selected={selectedLeverage?.leverage === leverage}
          onSelect={() => onSelectLeverage({ leverage, maintenance })}
        />
      ))}
    </div>
  )
}

export default SelectLeverage
