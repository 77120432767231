import { useState } from "react"
import { useParams } from "react-router-dom"
import { Deposit } from "./Deposit"
import { Withdraw } from "./Withdraw"
import { useNavigateRoutes } from "src/hooks/useNavigateRoutes"
import { CaretLeftIcon } from "src/components/Icons/CaretLeftIcon"
import { useNetworkChangeRedirect } from "src/hooks/useNetworkChangeRedirect"
import { useSubgraphIndexedPosition } from "src/pages/positions/hooks/useSubgraphIndexedPositions"
import { usePositionsOnChainState } from "src/pages/positions/hooks/usePositionsOnChainState"
import { useApplicationState } from "src/state/application/hooks"
import { SwitchButton } from "src/components/ui/SwitchButton"

const ManagePosition = () => {
  const { chainId } = useApplicationState()
  const { positionKey } = useParams()
  const { onNavigateToPosition, onNavigateToPositions } = useNavigateRoutes()
  const { position } = useSubgraphIndexedPosition(positionKey)
  const tokenId = position?.tokenId ?? ""
  const { data: positionStates, refetch: refetchPositionState } =
    usePositionsOnChainState(tokenId ? [tokenId] : [], chainId)

  useNetworkChangeRedirect(onNavigateToPositions)

  const [mode, setMode] = useState<"Deposit" | "Withdraw">("Deposit")
  const handleDepositMode = () => setMode("Deposit")
  const handleWithdrawMode = () => setMode("Withdraw")

  const handleNavigate = () => {
    if (positionKey) {
      onNavigateToPosition(positionKey)
    }
  }
  return (
    <div className="relative mx-auto w-full max-w-md">
      <button
        onClick={handleNavigate}
        className="my-6 flex cursor-pointer items-center justify-start space-x-1 text-marginalGray-200"
      >
        <CaretLeftIcon />
        <span className="text-sm text-marginalGray-200">
          Back to Token
        </span>
      </button>

      <div className="rounded-t-3xl border border-marginalGray-800 bg-marginalGray-900">
        <div className="flex items-center justify-between p-4">
          <div className="relative text-lg text-marginalGray-200 md:text-xl md:leading-6">
            Manage Position
          </div>
        </div>
        <div className="px-4 pb-4 pt-0">
          <div className="h-8 w-full">
            <SwitchButton
              view={mode}
              onLeftButtonClick={handleDepositMode}
              onRightButtonClick={handleWithdrawMode}
              leftButtonText="Deposit"
              rightButtonText="Withdraw"
            />
          </div>
        </div>
      </div>
      {mode === "Deposit" ? (
        <Deposit
          position={position}
          positionState={positionStates[tokenId]}
          fetchPositionState={refetchPositionState}
        />
      ) : (
        <Withdraw
          position={position}
          positionState={positionStates[tokenId]}
          fetchPositionState={refetchPositionState}
        />
      )}
    </div>
  )
}

export default ManagePosition
