import { useState } from "react"
import { type LBPoolData, type PoolData, type Token } from "src/types"
import { ListRow } from "src/components/ui/ListRow"
import { DoubleTokenLogo } from "src/components/TokenBadge"
import { AssetPairPriceRatio } from "src/components/AssetPairPriceRatio"
import { RotatingActiveArrow } from "src/components/RotatingActiveArrow"

interface Props {
  pool: PoolData | LBPoolData | null
  token0: Token | null
  token1: Token | null

  poolPrice: string | null | undefined
  maxSlippage: string
  leverageTier: number | undefined | null

  useInverse: boolean
  onToggleInverse: () => void
}

export const SwapDetailList = ({
  pool,
  token0,
  token1,

  poolPrice,
  maxSlippage,
  leverageTier,

  useInverse,
  onToggleInverse,
}: Props) => {
  const [show, setShow] = useState(true)

  if (!pool) return null

  return (
    <div
      id="swap-detail-list"
      className="duration-175 transform-gpu rounded-lg px-2 py-2"
    >
      <section className="space-y-3">
        <header className="flex items-center justify-between">
          <h1 className="whitespace-nowrap">Execution details</h1>
          <button
            onClick={() => setShow(!show)}
            className="flex w-full cursor-pointer items-center justify-end gap-1 hover:opacity-60"
          >
            <span className="text-sm">{show ? "hide" : "show"}</span>
            <RotatingActiveArrow isActive={show} />
          </button>
        </header>
        <div className="flex items-center justify-between">
          <AssetPairPriceRatio
            token0={token0}
            token1={token1}
            price={poolPrice}
            useInverse={useInverse}
            onToggleInverse={onToggleInverse}
          />
        </div>
      </section>

      <div
        className={`transform-gpu overflow-y-hidden text-sm text-marginalGray-200 transition-max-height duration-500 ease-in-out ${show ? "max-h-fit" : "max-h-0"} `}
      >
        <div className="my-4 h-px bg-marginalGray-800" />

        <div className="space-y-2 text-marginalGray-400">
          <ListRow
            item="Max slippage"
            value={
              maxSlippage && (
                <div className="flex flex-wrap justify-end space-x-1 text-marginalGray-200">
                  <div>{maxSlippage}%</div>
                </div>
              )
            }
          />
          <ListRow
            item="Pool"
            value={
              pool && (
                <div className="flex items-center space-x-1 text-marginalGray-200">
                  {token0 && token1 && (
                    <DoubleTokenLogo token0={token0} token1={token1} size={4} />
                  )}
                  <div className="flex flex-wrap items-baseline space-x-2">
                    <div className="flex items-center space-x-1">
                      <span>{token0?.symbol}</span>
                      <div className="my-auto font-bold">/</div>
                      <span>{token1?.symbol}</span>
                    </div>
                  </div>
                </div>
              )
            }
          />
          <ListRow
            item="Leverage Tier"
            value={
              leverageTier && (
                <div className="flex flex-wrap justify-end space-x-1 text-marginalGray-200">
                  <div>{leverageTier}x Max</div>
                </div>
              )
            }
          />
        </div>
      </div>
    </div>
  )
}
