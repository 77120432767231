import { useState } from "react"
import { useAccount } from "wagmi"
import { Address } from "viem"
import { usePoolsData } from "src/hooks/usePoolsData"
import { useUserPoolBalance } from "../pool/hooks/useUserPoolBalance"
import { usePoolsFilters, PoolsView } from "./hooks/usePoolsFilters"
import { PoolsHeader } from "./components/PoolsHeader"
import { PoolsTable } from "./components/PoolsTable"
import { useLiquidityLockedInPools } from "./hooks/useLiquidityLockedInPools"
import { useTotalSupplyInPools } from "./hooks/useTotalSupplyInPools"
import { useCurrentPoolsState } from "./hooks/useCurrentPoolsState"
import { useMultipleErc20TokenBalances } from "./hooks/useMultipleErc20TokenBalances"
import { useApplicationState } from "src/state/application/hooks"

export const Pools = () => {
  const { chainId } = useApplicationState()
  const { pools, isLoading } = usePoolsData()
  const { address } = useAccount()
  const [view, setView] = useState<PoolsView>(PoolsView.INCENTIVIZED)
  const { incentivizedPools, unincentivizedPools } = usePoolsFilters(pools)
  const { balances: userPoolBalances } = useUserPoolBalance(pools, address)
  const poolTotalSupply = useTotalSupplyInPools(pools)
  const liquidityLockedInPools = useLiquidityLockedInPools(pools)

  const { poolStates } = useCurrentPoolsState([
    ...incentivizedPools.map((pool) => pool.poolAddress as Address),
    ...unincentivizedPools.map((pool) => pool.poolAddress as Address),
  ])

  const { balances: poolTokenBalances } = useMultipleErc20TokenBalances([
    ...incentivizedPools.map((pool) => ({
      tokens: [pool.token0, pool.token1],
      owner: pool.poolAddress as Address,
    })),
    ...unincentivizedPools.map((pool) => ({
      tokens: [pool.token0, pool.token1],
      owner: pool.poolAddress as Address,
    })),
  ])

  const currentPools =
    view === PoolsView.INCENTIVIZED ? incentivizedPools : unincentivizedPools

  return (
    <div className="mx-auto flex w-full max-w-4xl flex-col border border-marginalGray-800">
      <PoolsHeader view={view} onViewChange={setView} />
      <PoolsTable
        view={view}
        pools={currentPools}
        isLoading={isLoading}
        address={address}
        chainId={chainId}
        liquidityLocked={liquidityLockedInPools}
        userPoolBalances={userPoolBalances}
        poolTotalSupply={poolTotalSupply}
        poolStates={poolStates}
        poolTokenBalances={poolTokenBalances}
      />
    </div>
  )
}
