import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { useAccount } from "wagmi"
import { Address, zeroAddress } from "viem"
import { trimTrailingZeroes } from "src/utils/trimTrailingZeroes"
import {
  V1_NFT_POSITION_MANAGER_ADDRESS,
  V1_QUOTER_ADDRESS,
} from "src/constants/addresses"
import { getTransactionError } from "src/utils/getTransactionError"
import { useNavigateRoutes } from "src/hooks/useNavigateRoutes"
import { ListRow } from "src/components/ui/ListRow"
import { ClosePositionButton } from "src/pages/position/managePosition/components/ClosePositionButton"
import { useSettingsToggle } from "src/hooks/useSettingsToggle"
import { useSettingsState } from "src/state/settings/hooks"
import { usePoolOracle } from "src/hooks/usePoolOracle"
import { TransactionStatusModal } from "src/components/TransactionStatusModal"
import { SlippageButton } from "src/components/Settings/SlippageButton"
import { useGetCurrentBlockTimestamp } from "src/hooks/useGetCurrentBlockTimestamp"
import { Card } from "src/components/Card"
import { CaretLeftIcon } from "src/components/Icons/CaretLeftIcon"
import { ignite } from "src/utils/actions/ignite"
import { getIgniteQuote } from "./helpers/getIgniteQuote"
import { IgniteQuote, Token } from "src/types"
import { derivePositionData } from "./helpers/derivePositionData"
import { useUniswapV3PoolState } from "./hooks/useUniswapV3PoolState"
import { useTransactionModal } from "src/pages/trade/hooks/useTransactionModal"
import { deriveIgniteQuoteData } from "./helpers/deriveIgniteQuoteData"
import { getPriceImpactIndicatorColor } from "src/pages/trade/helpers/getPriceImpactIndicatorColor"
import { validateIgniteQuoteParams } from "src/pages/positions/helpers/validateIgniteQuoteParams"
import { constructIgniteQuoteParams } from "src/pages/positions/helpers/constructIgniteQuoteParams"
import { useSubgraphIndexedPosition } from "src/pages/positions/hooks/useSubgraphIndexedPositions"
import { usePositionsOnChainState } from "src/pages/positions/hooks/usePositionsOnChainState"
import { formatNumberAmount } from "src/utils/formatNumberAmount"
import { usePoolsData, getPoolDataByAddress } from "src/hooks/usePoolsData"
import { useApplicationState } from "src/state/application/hooks"
import { getTokenByAddress } from "src/constants/tokenList"
import { TokenBadge } from "src/components/TokenBadge"

export const Close = () => {
  const { chainId } = useApplicationState()
  const { indexedId } = useParams()
  const { address } = useAccount()
  const { maxSlippage, transactionDeadline } = useSettingsState()
  const { showSettings, onOpenSettings, onCloseSettings } = useSettingsToggle()
  const { onNavigateToPositions, onNavigateToPosition } = useNavigateRoutes()
  const { position } = useSubgraphIndexedPosition(indexedId)
  const { poolsDataByAddress } = usePoolsData()
  const pool = getPoolDataByAddress(position?.pool?.address, poolsDataByAddress)
  const oracle = usePoolOracle(position?.pool?.address)

  const tokenId = position?.tokenId ?? ""
  const zeroForOne = position?.zeroForOne
  const { data: positionData, refetch: refetchPositionState } = usePositionsOnChainState(
    tokenId ? [tokenId] : [],
    chainId,
  )

  const token0Address = position?.pool?.token0?.address
  const token1Address = position?.pool?.token1?.address

  const token0 =
    token0Address && chainId
      ? (getTokenByAddress(token0Address, chainId) ?? (position?.pool?.token0 as Token))
      : undefined
  const token1 =
    token1Address && chainId
      ? (getTokenByAddress(token1Address, chainId) ?? (position?.pool?.token1 as Token))
      : undefined

  const [marginToken, debtToken] = zeroForOne ? [token1, token0] : [token0, token1]

  const currentBlockTimestamp = useGetCurrentBlockTimestamp(chainId)
  const { price: uniswapV3PoolPrice, fee: uniswapV3PoolFee } = useUniswapV3PoolState(
    oracle as Address,
    chainId,
  )

  const igniteParams = validateIgniteQuoteParams(
    token0,
    token1,
    position?.pool?.maintenance,
    position?.pool?.oracle,
    position?.tokenId,
    0n,
    address,
  )
    ? constructIgniteQuoteParams({
        token0: position?.pool?.token0?.address as Address,
        token1: position?.pool?.token1?.address as Address,
        maintenance: position?.pool?.maintenance,
        oracle: position?.pool?.oracle as Address,
        tokenId: position?.tokenId as string,
        amountOutMinimum: 0n,
        recipient: address ?? zeroAddress,
        currentBlockTimestamp,
        transactionDeadline,
      })
    : null

  const [igniteQuote, setIgniteQuote] = useState<IgniteQuote | null>(null)

  useEffect(() => {
    if (!igniteParams || !marginToken) {
      setIgniteQuote(null)
      return
    }

    ;(async () => {
      setIgniteQuote(
        await getIgniteQuote(
          chainId,
          V1_QUOTER_ADDRESS[chainId],
          igniteParams,
          marginToken,
        ),
      )
    })()
  }, [chainId, marginToken]) // eslint-disable-line react-hooks/exhaustive-deps

  const { margin, debt, size, rewards } = derivePositionData(
    positionData[tokenId],
    pool,
    marginToken,
    debtToken,
  )

  const {
    profitLoss,
    impact,
    minReceived,
    igniteParamsWithSlippage,
    error: isSlippageMoreThanToleranceError,
  } = deriveIgniteQuoteData(
    igniteQuote,
    marginToken,
    igniteParams,
    maxSlippage,
    margin?.raw as bigint,
    debt?.raw as bigint,
    size?.raw as bigint,
    zeroForOne as boolean,
    uniswapV3PoolPrice,
    uniswapV3PoolFee as bigint,
  )

  const impactIndicator = getPriceImpactIndicatorColor(parseFloat(impact?.raw as string))
  const isPositionInProfit = profitLoss?.isProfit

  const {
    state: {
      showConfirm,
      isPendingWallet,
      isPendingApprove,
      isPendingTx,
      isTxSubmitted,
      txHash,
      txError,
    },
    openConfirmModal,
    closeConfirmModal,
    resetState: resetTransactionState,
    setPendingWallet,
    setTxSubmitted,
  } = useTransactionModal()

  const onSuccessTx = () => {
    resetTransactionState()
    setIgniteQuote(null)
    onNavigateToPositions()
  }

  const handleClose = async () => {
    try {
      if (!igniteParamsWithSlippage) {
        return new Error("Missing ignite params")
      }
      setPendingWallet(true)
      const transaction = await ignite(
        chainId,
        V1_NFT_POSITION_MANAGER_ADDRESS[chainId],
        igniteParamsWithSlippage,
      )
      refetchPositionState()
      setTxSubmitted(true, transaction.transactionHash)
      return transaction.transactionHash
    } catch (error) {
      console.error("Error executing close: ", error)
      if (getTransactionError(error) === "Wallet rejected transaction.") {
        setPendingWallet(false)
      } else {
        refetchPositionState()
      }
    }
  }

  if (!position) {
    return (
      <div id="position-container" className="m-auto mt-10 space-y-4 p-4">
        <div className="text-center text-lg text-marginalGray-200">
          Loading position...
        </div>
      </div>
    )
  }

  return (
    <Card>
      <div className="my-6 flex items-center justify-between">
        <div
          onClick={indexedId ? () => onNavigateToPosition(indexedId) : () => null}
          className="flex cursor-pointer items-center justify-start space-x-1 text-marginalGray-200"
        >
          <CaretLeftIcon />
          <span className="text-sm text-marginalGray-200">
            Back to Token
          </span>
        </div>
        <SlippageButton
          maxSlippage={maxSlippage}
          showSettings={showSettings}
          onClose={onCloseSettings}
          onOpen={onOpenSettings}
        />
      </div>
      <div className="relative mx-auto mt-12 w-full max-w-[343px] rounded-3xl border border-marginalGray-800 bg-marginalGray-900 shadow-outerBlack sm:max-w-[440px]">
        <div className="flex items-center border-b border-b-marginalGray-800 p-4 text-lg font-bold text-marginalGray-200 md:text-xl md:leading-6">
          Close Position
        </div>
        <div className="space-y-2 p-2 sm:space-y-4 sm:p-4">
          <div className="space-y-2 rounded-xl bg-marginalBlack p-4 text-xs text-marginalGray-600">
            <div className="flex items-center justify-between">
              <div className="flex flex-col space-y-1">
                Token ID
                {position && (
                  <div className="text-sm text-marginalGray-200">
                    <div>{position?.tokenId}</div>
                  </div>
                )}
              </div>

              <div className="flex flex-col">
                Profit/Loss (Without Impact)
                {profitLoss.formatted && (
                  <div
                    className={`flex flex-wrap justify-end space-x-1 ${profitLoss.percentage ? (isPositionInProfit ? "text-success-500" : "text-error-500") : ""}`}
                  >
                    <div className="flex text-sm">
                      <pre>
                        {profitLoss.formatted} {marginToken?.symbol}
                      </pre>
                    </div>
                    <pre className="text-sm">({profitLoss.percentageFormatted}%)</pre>
                  </div>
                )}
              </div>
            </div>

            <div className="my-4 h-px bg-marginalGray-100/20" />
            <div className="space-y-2 text-marginalGray-600">
              <ListRow
                item="Impact"
                value={
                  <div className="flex flex-wrap justify-end space-x-1 text-marginalGray-600">
                    <div className={`${impactIndicator}`}>{impact.formatted}%</div>
                  </div>
                }
              />
              <ListRow
                item="Max slippage"
                value={
                  <div className="flex flex-wrap justify-end space-x-1 text-marginalGray-200">
                    <div>{maxSlippage}%</div>
                  </div>
                }
              />
              <ListRow
                item="Escrow rewards"
                value={
                  <div className="flex flex-wrap justify-end space-x-1 text-marginalGray-200">
                    <div>{formatNumberAmount(rewards.parsed, true) ?? "-"} ETH</div>
                  </div>
                }
              />
              <ListRow
                item="Minimum received"
                value={
                  minReceived.parsed && (
                    <div className="flex flex-wrap justify-end space-x-1 text-marginalGray-200">
                      <div>
                        {trimTrailingZeroes(
                          parseFloat(minReceived.parsed)?.toPrecision(6),
                        )}
                      </div>
                      <TokenBadge token={marginToken as Token} size={4} />
                    </div>
                  )
                }
              />
            </div>
          </div>
          <ClosePositionButton
            igniteCallback={handleClose}
            isPendingWallet={isPendingWallet}
            isPendingTx={isPendingTx}
            error={isSlippageMoreThanToleranceError}
          />
        </div>
      </div>
      <TransactionStatusModal
        chainId={chainId}
        open={showConfirm}
        onOpen={openConfirmModal}
        onClose={closeConfirmModal}
        onReset={onSuccessTx}
        onCallback={handleClose}
        isPendingWallet={isPendingWallet}
        isPendingApprove={isPendingApprove}
        isPendingTx={isPendingTx}
        isTxSubmitted={isTxSubmitted}
        txHash={txHash}
        txError={txError}
        hasConfirmModal={false}
        onSuccessText="Position Closed"
      />
    </Card>
  )
}
