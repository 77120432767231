import { ReactNode } from "react"

export const TableHeaderCell = ({
  align = "right",
  children,
}: {
  align?: "right" | "left"
  children?: ReactNode
}) => {
  return (
    <th
      className={`whitespace-nowrap px-4 pb-3 pt-1 text-xs text-marginalGray-200 ${
        align === "right" ? "text-right" : "text-left"
      }`}
    >
      {children}
    </th>
  )
}

export const TableCell = ({ children }: { children?: ReactNode }) => {
  return (
    <td className="whitespace-nowrap border-b border-marginalGray-200/20 px-4 py-4 text-right text-sm text-marginalGray-200 first:text-left">
      {children}
    </td>
  )
}
export const TableRow = ({ children }: { children: ReactNode }) => {
  return <tr className="">{children}</tr>
}
export const MobileTableCell = ({
  label,
  children,
}: {
  label: string
  children?: ReactNode
}) => {
  return (
    <td
      className={`flex w-full flex-col text-xs text-marginalGray-200`}
    >
      <span className="text-marginalGray-600">{label}:</span>
      {children}
    </td>
  )
}

interface PoolRowLoadingProps {
  isMobile?: boolean
  isOnlyPosition: boolean
  isFirst: boolean
  isLast: boolean
}
export const PoolRowLoading = ({
  isMobile = false,
  isOnlyPosition,
  isFirst,
  isLast,
}: PoolRowLoadingProps) => {
  if (isMobile) {
    return (
      <tr>
        <div
          className={`relative h-52 space-y-4 border-b bg-marginalGray-950 p-4 ${
            isOnlyPosition
              ? "rounded-xl border-b-transparent"
              : isFirst
                ? "rounded-t-xl border-b-marginalGray-800"
                : isLast
                  ? "rounded-b-xl border-b-transparent"
                  : "border-b-marginalGray-800"
          } `}
        >
          <LoadingRow />
          <LoadingRow />
          <LoadingRow />
          <LoadingRow />
          <LoadingRow />
          <LoadingRow />
        </div>
      </tr>
    )
  }

  return (
    <tr
      className={`w-full cursor-pointer border-none bg-marginalBlack hover:bg-marginalGray-950 ${
        isOnlyPosition
          ? "[&>td]:border-none first:[&>td]:rounded-bl-2xl first:[&>td]:rounded-tl-2xl last:[&>td]:rounded-br-2xl last:[&>td]:rounded-tr-2xl"
          : isFirst
            ? "first:[&>td]:rounded-tl-2xl last:[&>td]:rounded-tr-2xl"
            : isLast
              ? "[&>td]:border-none first:[&>td]:rounded-bl-2xl last:[&>td]:rounded-br-2xl"
              : ""
      } `}
    >
      <TableCell>
        <LoadingContent type="pool" />
      </TableCell>
      <TableCell>
        <LoadingContent type="leverage" />
      </TableCell>
      <TableCell>
        <LoadingContent type="percentage" />
      </TableCell>
      <TableCell>
        <LoadingContent type="empty" />
      </TableCell>
      <TableCell>
        <LoadingContent type="standard" />
      </TableCell>
      <TableCell>
        <LoadingContent type="percentage" />
      </TableCell>
      <TableCell>
        <LoadingContent type="percentage" />
      </TableCell>
    </tr>
  )
}

const LoadingRow = () => (
  <td className="flex items-center justify-between md:py-2">
    <div className="flex space-x-2">
      <div className="h-5 w-5 rounded-full bg-marginalGray-200"></div>
      <div className="h-5 w-8 rounded bg-marginalGray-200"></div>
    </div>
    <div className="h-5 w-14 rounded bg-marginalGray-200"></div>
  </td>
)

const LoadingContent = ({
  type,
}: {
  type: "pool" | "leverage" | "standard" | "percentage" | "empty"
}) => {
  switch (type) {
    case "pool":
      return (
        <div className="flex items-center space-x-4">
          <div className="h-8 w-8 rounded-full bg-marginalGray-200"></div>
          <div className="flex flex-col space-y-2">
            <div className="h-4 w-24 rounded bg-marginalGray-200"></div>
            <div className="h-3 w-36 rounded bg-marginalGray-200"></div>
          </div>
        </div>
      )
    case "leverage":
      return <div className="ml-auto h-4 w-8 rounded bg-marginalGray-200"></div>
    case "percentage":
      return (
        <div className="flex items-center justify-end">
          <div className="h-4 w-10 rounded bg-marginalGray-200"></div>
        </div>
      )
    case "empty":
      return null
    default:
      return (
        <div className="flex items-center space-x-2">
          <div className="h-7 w-7 rounded-full bg-marginalGray-200"></div>
          <div className="h-4 w-10 rounded bg-marginalGray-200"></div>
        </div>
      )
  }
}
