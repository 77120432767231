import _ from "lodash"
import { useMemo, useEffect, useState } from "react"
import { InputContainer } from "src/components/ui/InputContainer"
import { SwapInput } from "src/pages/swap/components/SwapInput"
import { SwapOutput } from "src/pages/swap/components/SwapOutput"
import { useAccount } from "wagmi"
import { waitForTransactionReceipt } from "@wagmi/core"
import { maxUint256, zeroAddress, Address } from "viem"
import { useErc20TokenBalances } from "src/hooks/useErc20TokenBalances"
import { trimTrailingZeroes } from "src/utils/trimTrailingZeroes"
import { formatBigIntToString } from "src/utils/formatBigIntToString"
import { V1_LB_ROUTER_ADDRESS, V1_LB_QUOTER_ADDRESS } from "src/constants/addresses"
import { isBlank } from "src/utils/isBlank"
import { formatStringToBigInt } from "src/utils/formatStringToBigInt"
import { useErc20TokenAllowance } from "src/hooks/useErc20TokenAllowance"
import { isTransactionReceiptError } from "src/utils/isTransactionReceiptError"
import { approveErc20Token } from "src/utils/actions/approveErc20Token"
import { useSettingsToggle } from "src/hooks/useSettingsToggle"
import { useSettingsState } from "src/state/settings/hooks"
import { SlippageButton } from "src/components/Settings/SlippageButton"
import { Token } from "src/types"
import { isWrappedGasToken } from "src/utils/isWrappedGasToken"
import { GAS_TOKEN_MAP } from "src/constants/tokens"
import { formatNumberAmount } from "src/utils/formatNumberAmount"
import { SwapDetailList } from "src/pages/swap/components/SwapDetailList"
import { useGetCurrentBlockTimestamp } from "src/hooks/useGetCurrentBlockTimestamp"
import { getValidAddress } from "src/utils/getValidAddress"
import { useApplicationState } from "src/state/application/hooks"
import { useInversePrice } from "src/hooks/useInversePrice"
import { formatUSDCurrency } from "src/utils/formatUSDCurrency"
import { deriveLbpSwapQuoteData } from "src/pages/lbp/helpers/deriveLbpSwapQuoteData"
import { ConfirmTradeButton } from "src/components/Launch/ConfirmTradeButton"
import { calculateInversePrice } from "src/pages/trade/components/TradeDetailList"
import { LaunchProjectProps } from "src/hooks/useLBData"
import { usePooledTokensUSDPrices } from "src/pages/trade/hooks/usePooledTokensUSDPrices"
import { useTransactionModal } from "src/pages/trade/hooks/useTransactionModal"
import { wagmiConfig } from "src/wagmiConfig"
import { constructLbpExactInputSingleParams } from "src/pages/lbp/helpers/constructLBPExactInputSingleParams"
import { validateLBPExactInputSingleParams } from "src/pages/lbp/helpers/validateLBPExactInputSingleParams"
import { lbpExactInputSingle } from "src/utils/actions/lbpExactInputSingle"
import { useAppDispatch } from "src/state/hooks"
import { fetchLbpExactInputSingleQuote, resetLbpQuote } from "src/state/lbp/reducer"
import { useLbpState, useLbpActionHandlers, useLbpValidations } from "src/state/lbp/hooks"
import { getPriceImpactIndicatorColor } from "src/pages/trade/helpers/getPriceImpactIndicatorColor"
import { isOnlyZeroes } from "src/utils/isOnlyZeroes"
import { GasSelect } from "src/components/GasSelect"
import { TokenSelector } from "src/pages/trade/components/TokenSelector"
import { checkBalanceSufficient } from "src/pages/trade/helpers/checkBalanceSufficient"
import { TokenBadge } from "../TokenBadge"
import { getTokenByAddress } from "src/constants/tokenList"
import { SwitchButton } from "src/components/ui/SwitchButton"

interface TradeProps {
  pool: LaunchProjectProps
  launchToken: Token
  launchTokenBase: Token
  refetchLbData: () => void
}

export const Trade = ({
  launchToken,
  launchTokenBase,
  pool,
  refetchLbData,
}: TradeProps) => {
  const dispatch = useAppDispatch()
  const { address } = useAccount()
  const { chainId } = useApplicationState()
  const { inputValue, inputToken, outputToken, exactInputSingleQuote, error, isBuy } =
    useLbpState()
  const { onUserInput, onSelectInputToken, onSelectOutputToken, onSetIsBuy } =
    useLbpActionHandlers()
  const { isInputValid, isInputTokenValid, isOutputTokenValid, isLbpSwapQuoteValid } =
    useLbpValidations()
  const { showSettings, onOpenSettings, onCloseSettings } = useSettingsToggle()
  const { maxSlippage, transactionDeadline } = useSettingsState()
  const [useInverse, onToggleInverse] = useInversePrice()
  const currentBlockTimestamp = useGetCurrentBlockTimestamp(chainId)

  const GAS_TOKEN = GAS_TOKEN_MAP[chainId]
  const [useGasToken, setUseGasToken] = useState<boolean>(false)
  const isInputWrappedGasToken = isWrappedGasToken(inputToken, chainId)

  const _launchToken =
    getTokenByAddress(pool.launchToken?.address, chainId) ?? pool.launchToken
  const _baseToken = getTokenByAddress(pool.baseToken?.address, chainId) ?? pool.baseToken
  const token0 = pool.launchTokenIndex === 0 ? _launchToken : _baseToken
  const token1 = pool.launchTokenIndex === 1 ? _launchToken : _baseToken
  const zeroForOne =
    getValidAddress(inputToken?.address) === getValidAddress(token0.address)

  useEffect(() => {
    if (isBuy) {
      onSelectInputToken(_baseToken)
      onSelectOutputToken(_launchToken)
    } else {
      onSelectInputToken(_launchToken)
      onSelectOutputToken(_baseToken)
    }
  }, [isBuy, _launchToken, _baseToken, onSelectOutputToken, onSelectInputToken])

  const { token0PriceInUSD, token1PriceInUSD } = usePooledTokensUSDPrices({
    chainId,
    quoteToken: pool.launchToken,
    token0,
    token1,
    poolPriceX96: BigInt(pool.sqrtPriceX96),
  })

  const inputTokenPriceInUSD =
    getValidAddress(inputToken?.address) === getValidAddress(token0.address)
      ? token0PriceInUSD
      : token1PriceInUSD

  const outputTokenPriceInUSD =
    getValidAddress(outputToken?.address) === getValidAddress(token0.address)
      ? token0PriceInUSD
      : token1PriceInUSD

  const poolPrice = useInverse ? calculateInversePrice(pool.rawPrice) : pool.rawPrice

  const { allowance, fetchAllowance } = useErc20TokenAllowance(
    inputToken?.address as Address,
    Number(inputToken?.decimals),
    address,
    V1_LB_ROUTER_ADDRESS[chainId],
    chainId,
  )

  useEffect(() => {
    if (chainId && inputToken && address) {
      ;(async () => {
        await fetchAllowance()
      })()
    }
  }, [chainId, inputToken, address, fetchAllowance])

  const formattedInput = !isBlank(inputValue)
    ? formatStringToBigInt(inputValue, inputToken?.decimals)
    : 0n

  const quotedOutput = useMemo(() => {
    if (exactInputSingleQuote?.amountOut) {
      return trimTrailingZeroes(
        formatBigIntToString(
          BigInt(exactInputSingleQuote?.amountOut.toString()),
          outputToken?.decimals,
        ),
      )
    } else {
      return ""
    }
  }, [outputToken, exactInputSingleQuote])

  const { balances: tokenBalances, refetch: fetchTokenBalances } = useErc20TokenBalances(
    [inputToken, GAS_TOKEN],
    address,
  )

  const { balance: inputTokenBalance, parsedBalance: parsedInputTokenBalance } =
    _.find(tokenBalances, { token: inputToken }) || {}

  const { balance: gasBalance, parsedBalance: parsedGasBalance } =
    _.find(tokenBalances, { token: GAS_TOKEN }) || {}

  const isBalanceSufficient = useMemo(() => {
    return checkBalanceSufficient(
      useGasToken,
      gasBalance,
      inputTokenBalance,
      formattedInput,
    )
  }, [useGasToken, gasBalance, inputTokenBalance, formattedInput])

  const isApproved = useMemo(() => {
    if (isInputWrappedGasToken && useGasToken) return true
    if (!allowance || !formattedInput) return true
    return allowance >= formattedInput
  }, [allowance, formattedInput, isInputWrappedGasToken, useGasToken])

  const quoteExactInputSingleParams = validateLBPExactInputSingleParams(
    inputToken,
    outputToken,
    pool.supplier,
    address ?? zeroAddress,
    inputValue,
    pool.blockTimestampInitialize,
    BigInt(pool.tickLower),
    BigInt(pool.tickUpper),
  )
    ? constructLbpExactInputSingleParams(
        inputToken as Token,
        outputToken as Token,
        pool.supplier,
        address ?? zeroAddress,
        inputValue,
        currentBlockTimestamp,
        pool.blockTimestampInitialize,
        transactionDeadline,
        BigInt(pool.tickLower),
        BigInt(pool.tickUpper),
      )
    : null

  const fetchAndSetLbpQuote = async () => {
    try {
      if (quoteExactInputSingleParams) {
        dispatch(
          fetchLbpExactInputSingleQuote({
            chainId,
            quoterAddress: V1_LB_QUOTER_ADDRESS[chainId],
            params: quoteExactInputSingleParams,
          }),
        )
      }
    } catch (error) {
      console.error("Error fetching LBP quote:", error)
    }
  }

  useEffect(() => {
    if (pool && inputToken && outputToken && inputValue && !isOnlyZeroes(inputValue)) {
      fetchAndSetLbpQuote()
    } else {
      dispatch(resetLbpQuote())
    }
  }, [dispatch, chainId, inputValue, inputToken, outputToken, address, pool]) // eslint-disable-line react-hooks/exhaustive-deps

  const {
    priceImpact,
    derivedSwapParamsWithSlippage,
    swapQuoteError: isSlippageMoreThanToleranceError,
  } = deriveLbpSwapQuoteData(
    exactInputSingleQuote,
    pool.sqrtPriceX96,
    zeroForOne,
    maxSlippage,
    quoteExactInputSingleParams,
  )

  const priceImpactIndicator = getPriceImpactIndicatorColor(priceImpact)

  const {
    state: { isPendingWallet, isPendingApprove, isPendingTx },
    openConfirmModal,
    resetState: resetTransactionState,
    setPendingWallet,
    setPendingApprove,
    setTxSubmitted,
  } = useTransactionModal()

  const onSuccessReset = () => {
    onUserInput("")
    resetTransactionState()
    fetchTokenBalances()
    refetchLbData()
  }

  const approveToken = async (
    amount: bigint | undefined,
    spenderAddress: Address,
    tokenAddress: Address,
  ) => {
    if (!amount) {
      throw new Error("Require amount to approve")
    }
    if (!spenderAddress) {
      throw new Error("Require spender address to approve")
    }
    if (!tokenAddress) {
      throw new Error("Require token address to approve")
    }
    try {
      setPendingWallet(true)
      const txHash = await approveErc20Token({
        chainId,
        amount: maxUint256,
        spenderAddress,
        tokenAddress,
      })
      setPendingApprove(true)
      setPendingWallet(false)
      await waitForTransactionReceipt(wagmiConfig, {
        hash: txHash,
      })
      await fetchAllowance()
      resetTransactionState()
    } catch (error) {
      console.error("Error approving token: ", error)
      if (isTransactionReceiptError(error)) {
        resetTransactionState()
        fetchAllowance()
      }
    }
  }

  const handleTradeCallback = async () => {
    try {
      if (!isApproved) {
        return new Error("Not approved")
      }
      setPendingWallet(true)
      const transaction = await lbpExactInputSingle(
        chainId,
        inputValue,
        derivedSwapParamsWithSlippage,
        V1_LB_ROUTER_ADDRESS[chainId],
        useGasToken,
      )
      await fetchTokenBalances()
      setTxSubmitted(true, transaction.transactionHash)
      onSuccessReset()
      return transaction.transactionHash
    } catch (error) {
      console.error("Error executing lbp trade: ", error)
      resetTransactionState()
    }
  }

  return (
    <>
      <div className="drop-shadow-black relative mx-auto max-w-[380px] rounded-3xl border border-marginalGray-800 bg-marginalGray-900 sm:max-w-[440px]">
        <div className="flex items-center justify-between p-4">
          <div className="relative text-lg text-[#CACACA] sm:text-xl">Trade</div>
          <SlippageButton
            maxSlippage={maxSlippage}
            showSettings={showSettings}
            onClose={onCloseSettings}
            onOpen={onOpenSettings}
          />
        </div>

        <div className="gap-2 space-y-2 px-2 pb-2 pt-0 md:px-4 md:pb-4">
          <div className="h-10 w-full">
            <SwitchButton
              view={isBuy ? "Buy" : "Sell"}
              onLeftButtonClick={() => onSetIsBuy(true)}
              onRightButtonClick={() => onSetIsBuy(false)}
              leftButtonText="Buy"
              rightButtonText="Sell"
            />
          </div>
        </div>

        <div className="h-px bg-marginalGray-800" />

        <div className="space-y-1 p-2 md:p-4">
          <div>
            <InputContainer id="swap-input-container">
              <div className="flex w-full flex-col">
                <SwapInput
                  title="You Pay"
                  inputValue={inputValue}
                  onChange={onUserInput}
                />
                {inputValue && inputTokenPriceInUSD && (
                  <div className="mt-1 flex items-center justify-between">
                    <div className="text-xs text-marginalGray-400 md:text-sm">
                      {formatUSDCurrency(inputTokenPriceInUSD * parseFloat(inputValue))}
                    </div>
                  </div>
                )}
              </div>

              <div className="flex flex-col items-end justify-center">
                <div className="mb-1.5 flex items-center space-x-1 text-xl">
                  <TokenBadge token={useGasToken ? GAS_TOKEN : (inputToken as Token)} />
                  {isInputWrappedGasToken && (
                    <>
                      <GasSelect
                        useGas={useGasToken}
                        setUseGas={setUseGasToken}
                        chainId={chainId}
                      />
                    </>
                  )}
                </div>

                {address && (
                  <div className="mt-3 flex space-x-2 text-xs sm:text-sm">
                    <div className="whitespace-nowrap text-marginalGray-600">
                      Balance:
                      {formatNumberAmount(
                        useGasToken ? parsedGasBalance : parsedInputTokenBalance,
                      )}
                    </div>
                    <div
                      className="cursor-pointer rounded-sm bg-[#4C2D1E] px-0.5 text-marginalOrange-500 hover:underline"
                      onClick={() => {
                        onUserInput(
                          useGasToken
                            ? parsedGasBalance
                              ? parsedGasBalance
                              : ""
                            : parsedInputTokenBalance
                              ? parsedInputTokenBalance
                              : "",
                        )
                      }}
                    >
                      Max
                    </div>
                  </div>
                )}
              </div>
            </InputContainer>
          </div>

          <InputContainer id="swap-output-container">
            <div className="flex w-full flex-col">
              <SwapOutput title="You Receive" outputValue={quotedOutput} />
              {quotedOutput && outputTokenPriceInUSD && (
                <div className="mt-1 flex items-center justify-between">
                  <div className="text-xs text-marginalGray-400 md:text-sm">
                    {formatUSDCurrency(outputTokenPriceInUSD * parseFloat(quotedOutput))}
                  </div>
                </div>
              )}

              {priceImpact && priceImpact > 0 && (
                <div className="mt-1 flex items-center justify-between">
                  <div className={`text-xs ${priceImpactIndicator} md:text-sm`}>
                    impact: (-{priceImpact?.toFixed(2)}%)
                  </div>
                </div>
              )}
            </div>
            <TokenSelector
              onClick={() => {}}
              selectedToken={outputToken}
              tokenOptions={[]}
              onSelect={onSelectInputToken}
              showSwapStyles={true}
              showArrow={false}
            />
          </InputContainer>
          <div className="pt-1">
            <ConfirmTradeButton
              chainId={chainId}
              isSell={!isBuy}
              isQuoteValid={isLbpSwapQuoteValid}
              inputToken={inputToken}
              launchToken={launchToken}
              isInputValid={isInputValid}
              isTokensValid={isInputTokenValid && isOutputTokenValid}
              isApproved={isApproved}
              isBalanceSufficient={isBalanceSufficient}
              onApprove={() =>
                approveToken(
                  formattedInput,
                  V1_LB_ROUTER_ADDRESS[chainId],
                  inputToken?.address as Address,
                )
              }
              onConfirm={openConfirmModal}
              tradeCallback={handleTradeCallback}
              isPendingWallet={isPendingWallet}
              isPendingApprove={isPendingApprove}
              isPendingTx={isPendingTx}
              error={error || isSlippageMoreThanToleranceError}
            />
          </div>
        </div>
      </div>

      <div className="mx-auto mb-[96px] mt-4 w-full max-w-[380px] px-0 sm:max-w-[440px] md:mb-0 md:px-4">
        <SwapDetailList
          pool={pool}
          token0={token0}
          token1={token1}
          poolPrice={formatNumberAmount(poolPrice, true)}
          maxSlippage={maxSlippage}
          leverageTier={null}
          useInverse={useInverse}
          onToggleInverse={onToggleInverse}
        />
        {useGasToken && (
          <span className="flex justify-end">
            <a
              href="https://app.uniswap.org/swap"
              target="_blank"
              rel="noreferrer"
              className="text-sm text-marginalOrange-500 hover:underline"
            >
              Wrap your {GAS_TOKEN.symbol}
            </a>
          </span>
        )}
      </div>
    </>
  )
}

export default Trade
