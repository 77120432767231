import { useMemo, useEffect, useState } from "react"
import { maxUint256 } from "viem"
import { useAccount } from "wagmi"
import { InputContainer } from "src/components/ui/InputContainer"
import { TradeInput } from "src/pages/trade/components/TradeInput"
import {
  useAddMarginActionHandlers,
  useAddMarginState,
  useAddMarginStatus,
} from "src/state/addMargin/hooks"
import { V1_NFT_POSITION_MANAGER_ADDRESS } from "src/constants/addresses"
import { useErc20TokenBalances } from "src/hooks/useErc20TokenBalances"
import { isBlank } from "src/utils/isBlank"
import { formatStringToBigInt } from "src/utils/formatStringToBigInt"
import { DepositButton } from "./components/DepositButton"
import { waitForTransactionReceipt } from "@wagmi/core"
import { isTransactionReceiptError } from "src/utils/isTransactionReceiptError"
import { approveErc20Token } from "src/utils/actions/approveErc20Token"
import { Address } from "viem"
import { useErc20TokenAllowance } from "src/hooks/useErc20TokenAllowance"
import { ListRow } from "src/components/ui/ListRow"
import { formatNumberAmount } from "src/utils/formatNumberAmount"
import { AssetPairPriceRatio } from "src/components/AssetPairPriceRatio"
import { getTransactionError } from "src/utils/getTransactionError"
import { TransactionStatusModal } from "src/components/TransactionStatusModal"
import { useGetCurrentBlockTimestamp } from "src/hooks/useGetCurrentBlockTimestamp"
import { useSettingsState } from "src/state/settings/hooks"
import { RotatingActiveArrow } from "src/components/RotatingActiveArrow"
import { HealthFactorIcon } from "src/components/Icons/HealthFactorIcon"
import { useInversePrice } from "src/hooks/useInversePrice"
import { isWrappedGasToken } from "src/utils/isWrappedGasToken"
import { useNativeTokenBalance } from "src/hooks/useNativeTokenBalance"
import { GasSelect } from "src/components/GasSelect"
import { wagmiConfig } from "src/wagmiConfig"
import { lock } from "src/utils/actions/lock"
import { constructLockParams } from "./helpers/constructLockParams"
import { validateLockParams } from "./helpers/validateLockParams"
import { getHealthFactorIndicatorColor } from "./helpers/getHealthFactorIndicatorColor"
import { Token } from "src/types"
import { useTransactionModal } from "src/pages/trade/hooks/useTransactionModal"
import { useLivePoolPrices } from "src/pages/trade/hooks/useLivePoolPrices"
import { extrapolateTokenPair } from "src/utils/extrapolateTokenPair"
import { derivePositionData } from "src/pages/position/managePosition/helpers/derivePositionData"
import { checkBalanceSufficient } from "src/pages/trade/helpers/checkBalanceSufficient"
import { GetPositionQuery } from "src/state/api/generated"
import { PositionState } from "src/pages/positions/hooks/usePositionsOnChainState"
import { usePoolsData, getPoolDataByAddress } from "src/hooks/usePoolsData"
import { useApplicationState } from "src/state/application/hooks"
import { getTokenByAddress } from "src/constants/tokenList"
import { GAS_TOKEN_MAP } from "src/constants/tokens"
import { TokenBadge } from "src/components/TokenBadge"

interface DepositProps {
  position: GetPositionQuery["position"]
  positionState: PositionState
  fetchPositionState: () => void
}

export const Deposit = ({
  position,
  positionState,
  fetchPositionState,
}: DepositProps) => {
  const { chainId } = useApplicationState()
  const { address } = useAccount()
  const { inputValue } = useAddMarginState()
  const { transactionDeadline } = useSettingsState()
  const currentBlockTimestamp = useGetCurrentBlockTimestamp(chainId)
  const [useInverse, onToggleInverse] = useInversePrice()
  const { poolsDataByAddress } = usePoolsData()
  const pool = getPoolDataByAddress(position?.pool?.address, poolsDataByAddress)
  const tokenId = positionState?.tokenId
  const zeroForOne = positionState?.zeroForOne
  const token0 = getTokenByAddress(pool?.token0?.address, chainId) ?? pool?.token0
  const token1 = getTokenByAddress(pool?.token1?.address, chainId) ?? pool?.token1
  const [marginToken, debtToken] = zeroForOne ? [token1, token0] : [token0, token1]
  const { quoteToken } = extrapolateTokenPair(token0 ?? null, token1 ?? null, chainId)
  const GAS_TOKEN = GAS_TOKEN_MAP[chainId]

  const [useGasToken, setUseGasToken] = useState<boolean>(true)
  const isTokenWrappedGasToken = isWrappedGasToken(marginToken, chainId)

  const [showDetailList, setShowDetailList] = useState(true)

  const { isInputValid } = useAddMarginStatus()
  const { onUserInput } = useAddMarginActionHandlers()

  const { allowance, fetchAllowance } = useErc20TokenAllowance(
    marginToken?.address as Address,
    Number(marginToken?.decimals),
    address,
    V1_NFT_POSITION_MANAGER_ADDRESS[chainId],
    chainId,
  )

  const formattedInput = !isBlank(inputValue)
    ? formatStringToBigInt(inputValue, marginToken?.decimals)
    : 0n

  useEffect(() => {
    if (marginToken && address) {
      ;(async () => {
        await fetchAllowance()
      })()
    }
  }, [chainId, marginToken, address, fetchAllowance])

  const {
    balance: gasBalance,
    parsedBalance: parsedGasBalance,
    shortenedParsedBalance: shortenedParsedGasBalance,
  } = useNativeTokenBalance(address, chainId)

  const { balances: tokenBalance, refetch: fetchTokenBalances } = useErc20TokenBalances(
    [marginToken],
    address,
  )

  const {
    balance: marginTokenBalance,
    parsedBalance: parsedMarginTokenBalance,
    shortenedParsedBalance: shortenedParsedMarginTokenBalance,
  } = tokenBalance?.[0] || {}

  const isApproved = useMemo(() => {
    if (useGasToken && isTokenWrappedGasToken) return true
    if (!allowance || !formattedInput) return false
    return allowance >= formattedInput
  }, [allowance, formattedInput, useGasToken, isTokenWrappedGasToken])

  const isBalanceSufficient = useMemo(
    () =>
      checkBalanceSufficient(
        useGasToken && isTokenWrappedGasToken,
        gasBalance,
        marginTokenBalance,
        formattedInput,
      ),
    [useGasToken, gasBalance, marginTokenBalance, formattedInput, isTokenWrappedGasToken],
  )

  const lockParams = validateLockParams(
    pool?.token0,
    pool?.token1,
    pool?.maintenance,
    pool?.oracleAddress,
    tokenId,
    inputValue,
    marginToken,
  )
    ? constructLockParams(
        pool?.token0 as Token,
        pool?.token1 as Token,
        pool?.maintenance as bigint,
        pool?.oracleAddress as Address,
        tokenId,
        inputValue,
        marginToken as Token,
        currentBlockTimestamp,
        transactionDeadline,
      )
    : null

  const {
    state: {
      showConfirm,
      isPendingWallet,
      isPendingApprove,
      isPendingTx,
      isTxSubmitted,
      txHash,
      txError,
    },
    openConfirmModal,
    closeConfirmModal,
    resetState: resetTransactionState,
    setPendingWallet,
    setPendingApprove,
    setTxSubmitted,
  } = useTransactionModal()

  const onSuccessReset = () => {
    resetTransactionState()
    onUserInput("")
  }

  const interactiveMargin = positionState?.margin
    ? positionState.margin + (lockParams?.marginIn ?? 0n)
    : undefined

  const { oraclePrice, poolPrice, liquidationPrice } = useLivePoolPrices({
    chainId,
    selectedPool: {
      token0: pool?.token0 as Token,
      token1: pool?.token1 as Token,
      maintenance: pool?.maintenance as bigint,
      oracleAddress: pool?.oracleAddress as Address,
    },
    zeroForOne: zeroForOne as boolean,
    useInverse,
    quoteToken,
    position: {
      size: positionState?.size,
      debt: positionState?.debt,
      margin: interactiveMargin as bigint,
    },
  })

  const { margin, safeMarginMin, leverage, healthFactor } = derivePositionData(
    {
      ...positionState,
      margin: interactiveMargin ? BigInt(interactiveMargin) : positionState?.margin,
    },
    pool,
    marginToken,
    debtToken,
  )

  const healthFactorIndicator = getHealthFactorIndicatorColor(healthFactor)

  const approveToken = async (
    amount: bigint | undefined,
    spenderAddress: Address,
    tokenAddress: Address,
  ) => {
    if (!amount) {
      throw new Error("Require amount to approve")
    }
    if (!spenderAddress) {
      throw new Error("Require spender address to approve")
    }
    if (!tokenAddress) {
      throw new Error("Require token address to approve")
    }
    try {
      setPendingWallet(true)
      const txHash = await approveErc20Token({
        chainId,
        amount: maxUint256,
        spenderAddress,
        tokenAddress,
      })
      setPendingApprove(true)
      await waitForTransactionReceipt(wagmiConfig, {
        hash: txHash,
      })
      setPendingApprove(false)
      await fetchAllowance()
    } catch (error) {
      console.error("Error approving token: ", error)
      if (isTransactionReceiptError(error)) {
        resetTransactionState()
        fetchTokenBalances()
      }
    }
  }

  const handleDeposit = async () => {
    try {
      if (!lockParams) {
        throw new Error("Missing lock params")
      }
      setPendingWallet(true)
      const transaction = await lock(
        chainId,
        V1_NFT_POSITION_MANAGER_ADDRESS[chainId],
        lockParams,
        useGasToken && isTokenWrappedGasToken,
        inputValue,
      )
      setTxSubmitted(true, transaction.transactionHash)
      fetchPositionState()
      onSuccessReset()
      return transaction.transactionHash
    } catch (error) {
      console.error("Error executing deposit: ", error)
      if (getTransactionError(error) === "Wallet rejected transaction.") {
        resetTransactionState()
      }
    }
  }
  return (
    <div className="relative">
      <div className="space-y-2 rounded-b-3xl border border-t-0 border-marginalGray-800 bg-marginalGray-900 p-2 sm:space-y-4 sm:p-4">
        <InputContainer id="add-margin-input-container">
          <TradeInput title="Collateral" value={inputValue} onChange={onUserInput} />
          <div className="flex flex-col items-end">
            <div className="mb-1.5 flex items-center space-x-1 text-xl">
              <TokenBadge
                token={
                  isTokenWrappedGasToken && useGasToken
                    ? GAS_TOKEN
                    : (marginToken as Token)
                }
              />

              {address && isTokenWrappedGasToken && (
                <>
                  <GasSelect
                    useGas={useGasToken}
                    setUseGas={setUseGasToken}
                    chainId={chainId}
                  />
                </>
              )}
            </div>

            {isTokenWrappedGasToken && useGasToken ? (
              <div className="flex space-x-2 text-sm">
                <div className="whitespace-nowrap text-marginalGray-600">
                  Balance: {shortenedParsedGasBalance}
                </div>
                <div
                  className="cursor-pointer rounded-sm bg-[#4C2D1E] px-0.5 text-marginalOrange-500"
                  onClick={() => onUserInput(parsedGasBalance ? parsedGasBalance : "")}
                >
                  Max
                </div>
              </div>
            ) : (
              <div className="flex space-x-2 text-sm">
                <div className="whitespace-nowrap text-marginalGray-600">
                  Balance:{" "}
                  {shortenedParsedMarginTokenBalance
                    ? parseFloat(shortenedParsedMarginTokenBalance).toFixed(4).trim()
                    : "0.0"}
                </div>
                <div
                  className="cursor-pointer rounded-sm bg-[#4C2D1E] px-0.5 text-marginalOrange-500"
                  onClick={() =>
                    onUserInput(parsedMarginTokenBalance ? parsedMarginTokenBalance : "")
                  }
                >
                  Max
                </div>
              </div>
            )}
          </div>
        </InputContainer>

        <DepositButton
          chainId={chainId}
          marginToken={marginToken as Token}
          isInputValid={isInputValid}
          isTokenValid={!isBlank(marginToken)}
          isApproved={isApproved}
          onApprove={() =>
            approveToken(
              formattedInput,
              V1_NFT_POSITION_MANAGER_ADDRESS[chainId],
              marginToken?.address as Address,
            )
          }
          lockCallback={handleDeposit}
          isPendingWallet={isPendingWallet}
          isPendingApprove={isPendingApprove}
          isBalanceSufficient={isBalanceSufficient}
          isPendingTx={isPendingTx}
          error={null}
          disabled={
            isTokenWrappedGasToken && useGasToken
              ? Number(shortenedParsedGasBalance) === 0
              : Number(shortenedParsedMarginTokenBalance) === 0
          }
          useGasToken={isTokenWrappedGasToken && useGasToken}
        />
      </div>
      <div className="mx-auto mt-4 w-full px-0 sm:px-4">
        <div
          id="manage-position-detail-list"
          className="duration-175 transform-gpu rounded-lg px-2 py-2"
        >
          <section className="space-y-3">
            <header className="flex items-center justify-between">
              <h1 className="whitespace-nowrap">Execution details</h1>
              <button
                onClick={() => setShowDetailList(!showDetailList)}
                className="flex w-full cursor-pointer items-center justify-end gap-1 hover:opacity-60"
              >
                <span className="text-sm">{showDetailList ? "hide" : "show"}</span>
                <RotatingActiveArrow isActive={showDetailList} />
              </button>
            </header>
            <div className="flex items-center justify-between">
              <AssetPairPriceRatio
                token0={token0}
                token1={token1}
                price={poolPrice}
                useInverse={useInverse}
                onToggleInverse={onToggleInverse}
              />
            </div>
          </section>

          <div
            className={`transform-gpu overflow-y-hidden text-sm text-marginalGray-600 transition-max-height duration-500 ease-in-out ${showDetailList ? "max-h-[600px]" : "max-h-0"} `}
          >
            <div className="space-y-2 py-4">
              <div className="h-px bg-marginalGray-200/20" />

              <ListRow
                item="Margin"
                value={
                  <div className={`flex items-center space-x-1 text-marginalGray-200`}>
                    <div>
                      {formatNumberAmount(margin.parsed, true)} {marginToken?.symbol}
                    </div>
                  </div>
                }
              />
              <ListRow
                item="Maintenance margin"
                value={
                  <div className={`flex items-center space-x-1 text-marginalGray-200`}>
                    {formatNumberAmount(safeMarginMin.parsed, true)} {marginToken?.symbol}
                  </div>
                }
              />
              <ListRow
                item="Health factor"
                value={
                  <div className={`flex items-center space-x-1 ${healthFactorIndicator}`}>
                    <HealthFactorIcon />
                    <div>{healthFactor?.toFixed(2)}</div>
                  </div>
                }
              />
              <ListRow
                item="Leverage"
                value={
                  leverage ? (
                    <div className="text-marginalGray-200">{leverage.current}x</div>
                  ) : (
                    "-"
                  )
                }
              />
              <ListRow
                item="Oracle price"
                value={
                  oraclePrice && (
                    <div className="flex flex-wrap items-center space-x-1 text-marginalGray-200">
                      <div>{formatNumberAmount(oraclePrice, true)}</div>

                      {!useInverse ? (
                        <div className="flex items-center">
                          <div>{token1?.symbol}</div>
                          <div>/</div>
                          <div>{token0?.symbol}</div>
                        </div>
                      ) : (
                        <div className="flex items-center">
                          <div>{token0?.symbol}</div>
                          <div>/</div>
                          <div>{token1?.symbol}</div>
                        </div>
                      )}
                    </div>
                  )
                }
              />
              <ListRow
                item="Liquidation price"
                value={
                  <div className="flex flex-wrap items-center space-x-1 text-marginalGray-200">
                    {liquidationPrice ? (
                      <div>{formatNumberAmount(liquidationPrice, true)}</div>
                    ) : (
                      <div>-</div>
                    )}
                    {!useInverse ? (
                      <div className="flex items-center">
                        <div>{token1?.symbol}</div>
                        <div>/</div>
                        <div>{token0?.symbol}</div>
                      </div>
                    ) : (
                      <div className="flex items-center">
                        <div>{token0?.symbol}</div>
                        <div>/</div>
                        <div>{token1?.symbol}</div>
                      </div>
                    )}
                  </div>
                }
              />
            </div>
          </div>
        </div>
      </div>
      <TransactionStatusModal
        chainId={chainId}
        open={showConfirm}
        onOpen={openConfirmModal}
        onClose={closeConfirmModal}
        onReset={resetTransactionState}
        onCallback={handleDeposit}
        isPendingWallet={isPendingWallet}
        isPendingApprove={isPendingApprove}
        isPendingTx={isPendingTx}
        isTxSubmitted={isTxSubmitted}
        txHash={txHash}
        txError={txError}
        hasConfirmModal={false}
        onSuccessText="Deposit Executed"
      />
    </div>
  )
}
