import { type Token } from "src/types"
import { getAddress } from "viem"

/**
 * Filters out a single token from a list of tokens
 * @param tokenList The list of tokens to filter
 * @param excludeToken The token to exclude from the list
 * @returns A new array with the filtered tokens
 */
export const filterTokenFromList = (
  tokenList: Token[],
  excludeToken?: Token | null,
): Token[] => {
  if (!excludeToken) return tokenList

  const excludeAddress = getAddress(excludeToken.address)
  return tokenList.filter((token) => getAddress(token.address) !== excludeAddress)
}

/**
 * Filters out tokens from a list based on a list of tokens to exclude
 * @param tokenList The list of tokens to filter
 * @param excludeTokens The tokens to exclude from the list
 * @returns A new array with the filtered tokens
 */
export const filterTokensFromList = (
  tokenList: Token[],
  excludeTokens: Token[],
): Token[] => {
  if (!excludeTokens.length) return tokenList

  const excludeAddresses = new Set(
    excludeTokens.map((token) => getAddress(token.address)),
  )

  return tokenList.filter((token) => !excludeAddresses.has(getAddress(token.address)))
}
