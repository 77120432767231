import { Token } from "src/types"
import { isAddress } from "viem"

export const validateRemoveLiquidityParams = (
  selectedPool: {
    token0: Token
    token1: Token
    maintenance: bigint | undefined
    oracleAddress: string | undefined
  } | null,
  tokenA: Token | null,
  tokenB: Token | null,
  lpTokenDecimals: string | undefined,
  inputValue: string,
  recipient: string,
): boolean => {
  if (
    !selectedPool ||
    !tokenA ||
    !tokenB ||
    !inputValue ||
    !recipient ||
    !lpTokenDecimals
  ) {
    return false
  }

  const { token0, token1, maintenance, oracleAddress } = selectedPool

  if (!token0 || !token1 || !maintenance || !oracleAddress) {
    return false
  }

  if (!isAddress(token0.address) || !isAddress(token1.address)) {
    return false
  }

  if (!isAddress(oracleAddress)) {
    return false
  }

  if (!isAddress(recipient)) {
    return false
  }

  const parsedInput = parseFloat(inputValue)
  if (isNaN(parsedInput) || parsedInput <= 0) {
    return false
  }

  return true
}
