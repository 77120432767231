import { SettingsModal } from "./SettingsModal"
import { Cog6ToothIcon } from "@heroicons/react/20/solid"

export const SlippageButton = ({
  maxSlippage,
  showSettings,
  onClose,
  onOpen,
}: {
  maxSlippage: string
  showSettings: boolean
  onClose: () => void
  onOpen: () => void
}) => {
  return (
    <div className="relative z-20 my-auto cursor-pointer text-marginalGray-200">
      <button
        className="flex items-center gap-2 rounded-md bg-marginalGray-800 p-2 pr-1.5 text-xs text-marginalGray-400 hover:opacity-80"
        onClick={showSettings ? onClose : onOpen}
      >
        <span>{maxSlippage ? `${maxSlippage}%` : "Select"} slippage</span>
        <Cog6ToothIcon className="w-4 text-marginalGray-200" />
      </button>
      <SettingsModal showSettings={showSettings} onCloseSettings={onClose} />
    </div>
  )
}
