import { type Token } from "src/types"
import { type PoolLiquidityPair } from "src/pages/pool/helpers/derivePoolLiquidity"
import { formatNumberAmount } from "src/utils/formatNumberAmount"
import { TokenBadge } from "src/components/TokenBadge"
import { DetailRow } from "src/components/ui/DetailRow"
import { CircularProgressBar } from "src/pages/pools/components"

interface PoolDetailsUtilizationProps {
  token0: Token | null
  token1: Token | null
  poolUtilizationPercentage: number | null

  lpTokenSymbol: string | undefined
  poolSupplyTotal: string | undefined
  userPoolBalance: string | null | undefined

  availableBalance: PoolLiquidityPair
  lockedBalance: PoolLiquidityPair

  leverageCap: number | null
  tvl: number
}

export const PoolDetailsUtilization = ({
  token0,
  token1,
  poolUtilizationPercentage,

  lpTokenSymbol,
  poolSupplyTotal,
  userPoolBalance,

  availableBalance,
  lockedBalance,

  leverageCap,
  tvl,
}: PoolDetailsUtilizationProps) => {
  return (
    <div className="flex w-full flex-col space-y-1 rounded-3xl">
      <div className="flex w-full flex-col space-y-4 rounded-xl bg-marginalBlack p-4 md:rounded-lg">
        <div className="text-sm text-marginalGray-200">
          Pool Utilization
        </div>

        <div className="flex w-full justify-between">
          <div className="flex w-full items-center space-x-2">
            <CircularProgressBar
              sqSize={24}
              strokeWidth={4}
              percentage={poolUtilizationPercentage ?? 0}
            />
            <div className="text-3xl text-marginalGray-100">
              {poolUtilizationPercentage?.toFixed(2)}%
            </div>
          </div>
        </div>

        <div className="space-y-1">
          <DetailRow
            label="Total"
            value={
              <p className="text-xs text-marginalGray-200">
                {poolSupplyTotal} {lpTokenSymbol}
              </p>
            }
          />

          <DetailRow
            label="Your Contributions"
            value={
              <p className="text-xs text-marginalGray-200">
                {userPoolBalance} {lpTokenSymbol}
              </p>
            }
          />
        </div>

        <div className="h-px bg-marginalGray-200/20" />

        <div className="text-sm text-marginalGray-200">
          Pool Composition
        </div>

        <div className="space-y-1">
          <div className="flex justify-between">
            <div className="w-1/3 text-xs text-marginalGray-600">
              Token
            </div>
            <div className="text-xs text-marginalGray-600">
              Available
            </div>
            <div className="text-xs text-marginalGray-600">
              Locked
            </div>
          </div>

          <div className="flex justify-between">
            <div className="w-1/3 text-xs text-marginalGray-200">
              <TokenBadge token={token0 as Token} size={4} />
            </div>

            <p className="text-xs text-marginalGray-200">
              {formatNumberAmount(availableBalance.token0.parsed)}
            </p>

            <p className="text-xs text-marginalGray-200">
              {formatNumberAmount(lockedBalance.token0.parsed)}
            </p>
          </div>

          <div className="flex justify-between">
            <div className="w-1/3 text-xs text-marginalGray-200">
              <TokenBadge token={token1 as Token} size={4} />
            </div>

            <p className="text-xs text-marginalGray-200">
              {formatNumberAmount(availableBalance.token1.parsed)}
            </p>

            <p className="text-xs text-marginalGray-200">
              {formatNumberAmount(lockedBalance.token1.parsed)}
            </p>
          </div>
        </div>
      </div>

      <div className="flex w-full flex-col space-y-4 rounded-xl bg-marginalBlack p-4 md:rounded-lg">
        <div className="text-sm text-marginalGray-200">
          Pool Details
        </div>

        <div className="space-y-1">
          <DetailRow
            label="Leverage"
            value={
              <p className="text-xs text-marginalGray-200">
                {leverageCap}x
              </p>
            }
          />
          <DetailRow
            label="TVL"
            value={
              <p className="text-xs text-marginalGray-200">
                ${tvl.toFixed(2)}
              </p>
            }
          />
        </div>
      </div>
    </div>
  )
}
