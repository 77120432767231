import { Token } from "src/types"
import { getAddress } from "viem"
import { RemoveLiquidityParams } from "src/types"
import { formatStringToBigInt } from "src/utils/formatStringToBigInt"

const MAX_DEADLINE =
  115792089237316195423570985008687907853269984665640564039457584007913129639935n

export const constructRemoveLiquidityParams = (
  inputValue: string,
  tokenA: Token,
  tokenB: Token,
  lpTokenDecimals: string,
  maintenance: bigint,
  oracleAddress: string,
  recipient: string,
  deadline: string,
  currentBlockTimestamp?: bigint,
): RemoveLiquidityParams => {
  return {
    token0: getAddress(tokenA.address),
    token1: getAddress(tokenB.address),
    maintenance: maintenance.toString(),
    oracle: getAddress(oracleAddress),
    recipient: getAddress(recipient),
    shares: formatStringToBigInt(inputValue, lpTokenDecimals) ?? 0n,
    amount0Min: 0n,
    amount1Min: 0n,
    deadline: currentBlockTimestamp
      ? currentBlockTimestamp + BigInt(deadline) * BigInt("60")
      : MAX_DEADLINE,
  }
}
