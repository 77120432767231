import { useMemo } from "react"
import { usePoolsData } from "../../../hooks/usePoolsData"
import { Token } from "src/types"

type Props = {
  token0: Token | null
  token1: Token | null
}

const useMarginalPoolExists = ({ token0, token1 }: Props) => {
  const { pools: marginalPools } = usePoolsData()

  const isMarginalPool = useMemo(() => {
    return marginalPools.some((pool) => {
      if (pool.token0.address === token0?.address) {
        return pool.token1.address === token1?.address
      }
      if (pool.token0.address === token1?.address) {
        return pool.token1.address === token0?.address
      }
      return false
    })
  }, [marginalPools, token0, token1])

  return {
    isMarginalPool,
  }
}

export default useMarginalPoolExists
