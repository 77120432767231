import { useReadContracts } from "wagmi"
import { Address } from "viem"
import { V1_NFT_POSITION_MANAGER_ADDRESS } from "src/constants/addresses"
import nftPositionManagerAbi from "src/constants/abis/MarginalV1NonfungiblePositionManager.json"

export interface PositionState {
  tokenId: string
  pool: Address
  positionId: bigint
  zeroForOne: boolean
  size: bigint
  debt: bigint
  margin: bigint
  safeMarginMinimum: bigint
  liquidated: boolean
  safe: boolean
  rewards: bigint
}

export type PositionsStateMap = {
  [tokenId: string]: PositionState
}

interface ContractConfig {
  chainId: number
  address: Address
  abi: any
  functionName: "positions"
  args: [number]
}

const validateInputs = (
  tokenIds?: (string | undefined | null)[],
  chainId?: number,
): boolean => {
  return (
    Array.isArray(tokenIds) &&
    tokenIds.length > 0 &&
    typeof chainId === "number" &&
    chainId > 0 &&
    V1_NFT_POSITION_MANAGER_ADDRESS[chainId] !== undefined
  )
}

const createContractConfigs = (tokenIds: string[], chainId: number): ContractConfig[] => {
  return tokenIds
    .filter((tokenId) => tokenId !== "")
    .map((tokenId) => ({
      chainId,
      address: V1_NFT_POSITION_MANAGER_ADDRESS[chainId],
      abi: nftPositionManagerAbi,
      functionName: "positions",
      args: [Number(tokenId)],
    }))
}

const formatPositionsData = (
  data: unknown[] | undefined,
  tokenIds: string[],
): PositionsStateMap => {
  if (!data) return {}

  return data.reduce<PositionsStateMap>((acc, result, index) => {
    // Type guard to check if result has a result property and is an array
    if (
      !result ||
      typeof result !== "object" ||
      !("result" in result) ||
      !Array.isArray(result.result)
    ) {
      return acc
    }

    const tokenId = tokenIds[index]
    const positionState = result.result

    acc[tokenId] = {
      tokenId,
      pool: positionState[0],
      positionId: positionState[1],
      zeroForOne: positionState[2],
      size: positionState[3],
      debt: positionState[4],
      margin: positionState[5],
      safeMarginMinimum: positionState[6],
      liquidated: positionState[7],
      safe: positionState[8],
      rewards: positionState[9],
    }

    return acc
  }, {})
}

export interface PositionsOnChainState {
  data: PositionsStateMap
  isLoading: boolean
  isError: boolean
  error: Error | null
  refetch: () => void
}

export const usePositionsOnChainState = (
  tokenIds?: (string | undefined | null)[],
  chainId?: number,
): PositionsOnChainState => {
  const isValid = validateInputs(tokenIds, chainId)
  const contracts = isValid
    ? createContractConfigs(tokenIds as string[], chainId as number)
    : []

  const { data, isLoading, isError, error, refetch } = useReadContracts({
    contracts,
    query: {
      enabled: isValid,
    },
  })

  const formattedData = formatPositionsData(data, tokenIds as string[])

  return {
    data: formattedData,
    isLoading,
    isError,
    error: error as Error | null,
    refetch,
  }
}
