import { useNavigateRoutes } from "src/hooks/useNavigateRoutes"
import useCopyClipboard from "src/hooks/useCopyClipboard"
// import { LineChart } from "src/components/Launch/LineChart"
import { MouseoverTooltip } from "src/components/ui/MouseoverTooltip"
import { CaretLeftIcon } from "src/components/Icons/CaretLeftIcon"
import { BoxCheckIcon } from "src/components/Icons/BoxCheckIcon"
import { SquareStackIcon } from "src/components/Icons/SquareStackIcon"
import LaunchDetailsAccordion from "src/components/Launch/LaunchDetailsAccordion"
import { ListRow } from "src/components/ui/ListRow"
import { LaunchTabs } from "src/components/Launch/LaunchTabs"
import { useCallback, useState } from "react"
import { CircularProgressBar } from "../pools/components"
import { CheckIcon } from "@heroicons/react/20/solid"
import { ArrowOutgoingIcon } from "src/components/Icons/ArrowOutgoingIcon"
import BondingCurveProgress from "src/components/Launch/BondingCurveProgress"
import LaunchProjectDescription from "src/components/Launch/LaunchProjectDescription"
import LaunchProjectTradeHistoryTable from "src/components/Launch/LaunchProjectTradeHistoryTables"
// import { mylaunchProjectsTx } from "src/components/Launch/DummyMyTrades"
// import { holders } from "src/components/Launch/DummyHolders"
import LaunchProjectHoldersTable from "src/components/Launch/LaunchProjectHoldersTable"
import { useParams } from "react-router-dom"
import { shortenAddress } from "src/utils/shortenAddress"
import { getValidAddress } from "src/utils/getValidAddress"
import { LaunchProjectProps, useLBPoolData } from "src/hooks/useLBData"
import { Token } from "src/types"
import { filterTransactionsByMaker } from "src/utils/filterTransactionsByMaker"
import { useAccount } from "wagmi"
import Trade from "src/components/Launch/Trade"
import { ActionButton } from "src/components/ActionButton"
import { TokenBadge, TokenLogo } from "src/components/TokenBadge"
import { useApplicationState } from "src/state/application/hooks"

// Remove dummy data after getting real project data
// const data: number[] = [0.1, 0.11, 0.111101, 0.11131, 0.112109, 0.12, 0.13, 0.15, 0.3]
// const labels: string[] = [
//   "04:00",
//   "06:30",
//   "09:00",
//   "11:30",
//   "14:00",
//   "16:30",
//   "19:00",
//   "21:30",
//   "29 JUL",
// ]

enum LaunchProjectView {
  DETAILS = "Details",
  HISTORY = "Trade History",
  HOLDERS = "Holders",
}

export const LaunchProject = () => {
  const { chainId } = useApplicationState()
  const { address } = useAccount()
  const [isCopied, setCopied] = useCopyClipboard()
  const [view, setView] = useState<LaunchProjectView>(LaunchProjectView.DETAILS)
  const { projectAddress } = useParams()
  const { pool, refetch } = useLBPoolData(projectAddress, chainId)

  const copy = useCallback(() => {
    const checksummedAddress = getValidAddress(projectAddress)
    checksummedAddress && setCopied(checksummedAddress)
  }, [projectAddress, setCopied])

  const { onNavigateToLaunch } = useNavigateRoutes()

  const handleView = (view: LaunchProjectView) => {
    setView(view)
  }

  if (!pool) {
    return null
  }

  const { launchToken, baseToken } = pool

  return (
    <>
      <div className="mx-auto origin-top space-y-6 pb-20 md:mt-12 md:scale-[0.85] md:pb-0 xl:w-[1000px]">
        <div
          onClick={() => onNavigateToLaunch()}
          className="flex cursor-pointer items-center justify-start space-x-1 text-marginalGray-200"
        >
          <CaretLeftIcon />
          <span className="text-sm">
            Back to Launches
          </span>
        </div>
        <div
          id="launch-project-header"
          className="flex flex-col space-y-4 md:flex-row md:justify-between md:space-y-0"
        >
          <div className="flex items-center space-x-3">
            <TokenLogo symbol={launchToken.symbol} imgUrl={launchToken.imgUrl} size={9} />
            <span className="text-xl text-marginalGray-200">
              {pool.name}
            </span>
            <BoxCheckIcon className="h-5 w-5" />
            <div
              className={`w-fit gap-2 rounded-sm px-2 py-0.5 text-xs ${
                pool.status === "Live Now"
                  ? "bg-success-800 text-success-500"
                  : pool.status === "Upcoming"
                    ? "bg-[#162A3B] text-[#3BB7DB]"
                    : "bg-marginalOrange-800 text-marginalOrange-500"
              } `}
            >
              {pool.status}
            </div>
          </div>

          <div className="flex items-center space-x-4">
            <div className="flex h-8 w-fit items-center rounded-md border border-marginalGray-800 bg-marginalGray-900 px-2 py-2">
              <div className="flex items-center space-x-1.5">
                <TokenLogo
                  symbol={launchToken.symbol}
                  imgUrl={launchToken.imgUrl}
                  size={4}
                />
                <span className="text-xs text-marginalGray-400">
                  ${launchToken.symbol}
                </span>
              </div>
            </div>
            <MouseoverTooltip
              hoverContent={<div>{isCopied ? "Copied" : "Copy"}</div>}
              timeoutInMs={isCopied ? 500 : 0}
            >
              <div
                onClick={copy}
                className="flex h-8 w-fit cursor-pointer items-center space-x-1.5 rounded-md border border-marginalGray-800 bg-marginalGray-900 py-2 pl-2.5 pr-2 hover:border-marginalGray-600"
              >
                <span className="text-xs text-marginalGray-400">
                  {shortenAddress(projectAddress)}
                </span>
                <SquareStackIcon />
              </div>
            </MouseoverTooltip>
          </div>
        </div>
        <LaunchProjectDetails
          pool={pool}
          launchToken={launchToken}
          baseToken={baseToken}
        />
        {/* <div className="flex">
          <div id="chart" className="px-4 py-4 w-full rounded-lg bg-marginalBlack">
            <LineChart data={data} labels={labels} />
          </div>
        </div> */}
        <LaunchTabs
          view={view}
          transactionsCount={pool.transactions.length}
          holdersCount={pool.holders.length}
          handlePrimaryClick={() => handleView(LaunchProjectView.DETAILS)}
          handleSecondaryClick={() => handleView(LaunchProjectView.HISTORY)}
          handleTertiaryClick={() => handleView(LaunchProjectView.HOLDERS)}
        />
        {view === "Details" ? (
          <div id="launch-details" className="flex w-full flex-col md:flex-row-reverse">
            <div className="flex flex-col space-y-6 md:ml-6">
              {pool.status === "Finished" ? (
                <div className="drop-shadow-black relative mx-auto max-w-[380px] rounded-3xl border border-marginalGray-800 bg-marginalGray-900 sm:max-w-[440px]">
                  <div className="flex items-center justify-between p-4">
                    <div className="relative text-lg text-[#CACACA] sm:text-xl">
                      LBP is Finished!
                    </div>
                  </div>

                  <div className="h-px bg-marginalGray-800" />

                  <div className="space-y-1 p-4">
                    <div className="space-y-4 rounded-lg bg-marginalBlack px-4 py-4 text-sm">
                      <span className="text-marginalGray-200">
                        Information on the transfer of liquidity to the pool.
                      </span>
                    </div>
                    <div className="space-y-4 rounded-lg bg-marginalBlack px-4 py-4 text-sm text-marginalGray-600">
                      <ListRow
                        item="My purchased tokens"
                        value={
                          <div className="text-marginalGray-200">
                            {
                              pool.holders.find(
                                (holder) =>
                                  holder.holder.toLowerCase() ===
                                  address?.toLowerCase(),
                              )?.tokens
                            }
                          </div>
                        }
                      />

                      <ListRow
                        item="Participants"
                        value={
                          <div className="text-marginalGray-200">
                            {pool.holders.length}
                          </div>
                        }
                      />
                    </div>
                  </div>

                  <div className="space-y-1 p-4">
                    <ActionButton action="Go to the pool" onClick={() => {}} />
                  </div>
                </div>
              ) : (
                pool.status !== "Upcoming" && (
                  <Trade
                    pool={pool}
                    launchToken={launchToken}
                    launchTokenBase={baseToken}
                    refetchLbData={refetch}
                  />
                )
              )}

              <BondingCurveProgress token={launchToken} />

              <LaunchProjectDescription />
            </div>
            <div
              id="launch-accordions"
              className="mt-6 flex w-full flex-col space-y-6 md:mt-0"
            >
              <LaunchDetailsAccordion
                title="duration"
                content={
                  <div className="space-y-4 rounded-lg bg-marginalBlack px-4 py-4 text-sm text-marginalGray-600">
                    <ListRow
                      item="Start Date"
                      value={
                        <div className="text-marginalGray-200">{pool.startDate}</div>
                      }
                    />

                    <ListRow
                      item="End Date"
                      value={<div className="text-marginalGray-200">{pool.endDate}</div>}
                    />
                  </div>
                }
              />

              <LaunchDetailsAccordion
                title="statistics"
                content={
                  <div className="space-y-4">
                    <div className="space-y-4 rounded-lg bg-marginalBlack px-4 py-4 text-sm text-marginalGray-600">
                      <ListRow
                        item="Start Balances"
                        value={
                          <div className="flex items-center space-x-2">
                            <TokenLogo
                              symbol={launchToken.symbol}
                              imgUrl={launchToken.imgUrl}
                              size={4}
                            />
                            <div className="text-marginalGray-200">
                              {pool.stats.startBalance.launchToken}
                            </div>
                          </div>
                        }
                      />

                      <ListRow
                        item=""
                        value={
                          <div className="flex items-center space-x-2">
                            <TokenLogo
                              symbol={baseToken.symbol}
                              imgUrl={baseToken.imgUrl}
                              size={4}
                            />
                            <div className="text-marginalGray-200">
                              {pool.stats.startBalance.baseToken}
                            </div>
                          </div>
                        }
                      />
                    </div>

                    <div className="space-y-4 rounded-lg bg-marginalBlack px-4 py-4 text-sm text-marginalGray-600">
                      <ListRow
                        item="Current Balances"
                        value={
                          <div className="flex items-center space-x-2">
                            <TokenLogo
                              symbol={launchToken.symbol}
                              imgUrl={launchToken.imgUrl}
                              size={4}
                            />
                            <div className="text-marginalGray-200">
                              {pool.stats.currentBalance.launchToken}
                            </div>
                          </div>
                        }
                      />

                      <ListRow
                        item=""
                        value={
                          <div className="flex items-center space-x-2">
                            <TokenLogo
                              symbol={baseToken.symbol}
                              imgUrl={baseToken.imgUrl}
                              size={4}
                            />
                            <div className="text-marginalGray-200">
                              {pool.stats.currentBalance.baseToken}
                            </div>
                          </div>
                        }
                      />
                    </div>

                    <div className="space-y-4 rounded-lg bg-marginalBlack px-4 py-4 text-sm text-marginalGray-600">
                      <ListRow
                        item="End Balances"
                        value={
                          <div className="flex items-center space-x-2">
                            <TokenLogo
                              symbol={launchToken.symbol}
                              imgUrl={launchToken.imgUrl}
                              size={4}
                            />
                            <div className="text-marginalGray-200">
                              {pool.stats.endBalance.launchToken}
                            </div>
                          </div>
                        }
                      />

                      <ListRow
                        item=""
                        value={
                          <div className="flex items-center space-x-2">
                            <TokenLogo
                              symbol={baseToken.symbol}
                              imgUrl={baseToken.imgUrl}
                              size={4}
                            />
                            <div className="text-marginalGray-200">
                              {pool.stats.endBalance.baseToken}
                            </div>
                          </div>
                        }
                      />
                    </div>

                    <div className="rounded-lg bg-marginalBlack px-4 py-4 text-sm text-marginalGray-600">
                      <ListRow
                        item="Swap Fee"
                        value={
                          <div className="text-marginalGray-200">{pool.swapFee}</div>
                        }
                      />
                    </div>
                  </div>
                }
              />

              <LaunchDetailsAccordion
                title="composition"
                content={
                  <div className="space-y-4">
                    <div className="space-y-4 rounded-lg bg-marginalBlack px-4 py-4 text-sm text-marginalGray-600">
                      <ListRow
                        item="Start Weight"
                        value={
                          <div className="flex items-center space-x-2">
                            <TokenBadge token={launchToken} size={4} />
                            <CircularProgressBar
                              sqSize={16}
                              strokeWidth={2}
                              percentage={100}
                              className="stroke-marginalOrange-500"
                            />
                            <div className="text-marginalGray-200">100%</div>
                          </div>
                        }
                      />

                      <ListRow
                        item=""
                        value={
                          <div className="flex items-center space-x-2">
                            <TokenBadge token={baseToken} size={4} />
                            <CircularProgressBar
                              sqSize={16}
                              strokeWidth={2}
                              percentage={0}
                              className="stroke-marginalOrange-500"
                            />
                            <div className="text-marginalGray-200">0%</div>
                          </div>
                        }
                      />
                    </div>

                    <div className="space-y-4 rounded-lg bg-marginalBlack px-4 py-4 text-sm text-marginalGray-600">
                      <ListRow
                        item="Current Weight"
                        value={
                          <div className="flex items-center space-x-2">
                            <TokenBadge token={launchToken} size={4} />
                            <CircularProgressBar
                              sqSize={16}
                              strokeWidth={2}
                              percentage={100 - parseFloat(pool.released)}
                              className="stroke-marginalOrange-500"
                            />
                            <div className="text-marginalGray-200">
                              {(100 - parseFloat(pool.released)).toFixed(2)}%
                            </div>
                          </div>
                        }
                      />

                      <ListRow
                        item=""
                        value={
                          <div className="flex items-center space-x-2">
                            <TokenBadge token={baseToken} size={4} />
                            <CircularProgressBar
                              sqSize={16}
                              strokeWidth={2}
                              percentage={parseFloat(pool.released)}
                              className="stroke-marginalOrange-500"
                            />
                            <div className="text-marginalGray-200">{pool.released}</div>
                          </div>
                        }
                      />
                    </div>

                    <div className="space-y-4 rounded-lg bg-marginalBlack px-4 py-4 text-sm text-marginalGray-600">
                      <ListRow
                        item="End Weight"
                        value={
                          <div className="flex items-center space-x-2">
                            <TokenBadge token={launchToken} size={4} />
                            <CircularProgressBar
                              sqSize={16}
                              strokeWidth={2}
                              percentage={0}
                              className="stroke-marginalOrange-500"
                            />
                            <div className="text-marginalGray-200">0%</div>
                          </div>
                        }
                      />

                      <ListRow
                        item=""
                        value={
                          <div className="flex items-center space-x-2">
                            <TokenBadge token={baseToken} size={4} />
                            <CircularProgressBar
                              sqSize={16}
                              strokeWidth={2}
                              percentage={100}
                              className="stroke-marginalOrange-500"
                            />
                            <div className="text-marginalGray-200">100%</div>
                          </div>
                        }
                      />
                    </div>
                  </div>
                }
              />

              <LaunchDetailsAccordion
                title="owners rights"
                content={
                  <div className="space-y-4 rounded-lg bg-marginalBlack px-4 py-4 text-sm text-marginalGray-600">
                    <ListRow
                      item="Pause Snapping"
                      value={
                        <div className="flex items-center space-x-1">
                          <CheckIcon className="h-4 w-4 text-success-500" />
                          <div className="text-marginalGray-200">Yes</div>
                        </div>
                      }
                    />

                    <ListRow
                      item="Unpause Snapping"
                      value={
                        <div className="flex items-center space-x-1">
                          <CheckIcon className="h-4 w-4 text-success-500" />
                          <div className="text-marginalGray-200">Yes</div>
                        </div>
                      }
                    />

                    <ListRow
                      item="Pull Liquidity"
                      value={
                        <div className="flex items-center space-x-1">
                          <CheckIcon className="h-4 w-4 text-success-500" />
                          <div className="text-marginalGray-200">Yes</div>
                        </div>
                      }
                    />
                  </div>
                }
              />

              <LaunchDetailsAccordion
                title="Geo-blocked Countries"
                content={
                  <div className="space-y-4 rounded-lg bg-marginalBlack px-4 py-4 text-sm text-marginalGray-600">
                    <ListRow
                      item="ofac restricted countries"
                      value={
                        <div className="flex items-center space-x-2">
                          <div className="text-marginalGray-600">Read More</div>
                          <ArrowOutgoingIcon className="h-3 w-3" />
                        </div>
                      }
                    />
                  </div>
                }
              />

              <LaunchDetailsAccordion
                title="faq"
                content={
                  <div className="space-y-4">
                    <div className="space-y-4 rounded-lg bg-marginalBlack px-4 py-4 text-sm">
                      <span className="text-marginalGray-200">
                        What is a liquidity bootstrapping pool?
                      </span>

                      <div className="text-marginalGray-600">
                        Liquidity bootstrapping pools (LBPs) are a special AMM pool type
                        pioneered by Balancer that can be understood as a modified dutch
                        auction. LBPs use dynamic weighting of assets in pools to more
                        easily distribute tokens to the broader community in a way seen as
                        more fair than traditional IDO methods.
                      </div>
                    </div>

                    <div className="space-y-4 rounded-lg bg-marginalBlack px-4 py-4 text-sm">
                      <span className="text-marginalGray-200">
                        How does it work?
                      </span>

                      <div className="text-marginalGray-600">
                        LBP's start at a high weight of the distributed token ('Token B')
                        and a low weight of the acquired token ('Token A') with a
                        pre-established price often set higher than what the market would
                        deem fair. Over the course of the LBP, the price periodically
                        changes depending on the buy and sell pressure of the distributed
                        asset.
                      </div>
                    </div>

                    <div className="space-y-4 rounded-lg bg-marginalBlack px-4 py-4 text-sm">
                      <div className="flex justify-between">
                        <span className="text-marginalGray-200">
                          What is a Liquidity Bootstrapping Pool?
                        </span>

                        <div className="text-marginalGray-600">Read More</div>
                      </div>

                      <div className="text-marginalGray-600">
                        ThalaLaunch is a LBP platform for Aptos native token launches.
                        There are inherent risks in participating in early-stage projects
                        and nascent blockchain technology.
                      </div>
                    </div>
                  </div>
                }
              />
            </div>
          </div>
        ) : view === "Trade History" ? (
          <div className="space-y-6">
            <LaunchProjectTradeHistoryTable
              title="My Trades"
              transactions={filterTransactionsByMaker(address, pool.transactions)}
            />
            <LaunchProjectTradeHistoryTable
              title="All Trades"
              transactions={pool.transactions}
            />
          </div>
        ) : (
          <LaunchProjectHoldersTable
            holders={pool.holders}
            launchToken={pool.launchToken}
          />
        )}
      </div>
    </>
  )
}

const LaunchProjectDetails = ({
  launchToken,
  baseToken,
  pool,
}: {
  launchToken: Token
  baseToken: Token
  pool: LaunchProjectProps
}) => {
  return (
    <div className="mx-auto grid w-full max-w-[380px] grid-cols-2 gap-2 space-x-0 sm:max-w-[440px] md:flex md:max-w-full md:flex-row md:gap-0 md:space-x-4">
      <LaunchProjectDescriptionContainer title="Current Price" value={pool.price} />

      <LaunchProjectDescriptionContainer title="Market Cap" value={pool.marketCap} />

      <LaunchProjectDescriptionContainer title="Liquidity" value={pool.liquidity} />

      <LaunchProjectDescriptionContainer
        title="Funds Raised"
        value={pool.released}
        currencyToken={launchToken}
      />
      <LaunchProjectDescriptionContainer title="Ends In" value={pool.endIn} />
    </div>
  )
}

const LaunchProjectDescriptionContainer = ({
  title,
  value,
  currencyToken,
}: {
  title: string
  value: string
  currencyToken?: Token
}) => {
  return (
    <div className="flex w-full flex-col items-start gap-4 rounded-2xl border border-marginalGray-800 bg-marginalGray-900 px-4 py-4 shadow-outerBlack">
      <span className="text-xs text-marginalGray-200 md:text-sm">
        {title}
      </span>
      {currencyToken ? (
        <div className="flex items-center space-x-2.5">
          <TokenLogo
            symbol={currencyToken.symbol}
            imgUrl={currencyToken.imgUrl}
            size={5}
          />
          <span className="text-sm text-marginalGray-100 md:text-xl">
            {value}
          </span>
        </div>
      ) : (
        <span className="text-sm text-marginalGray-100 md:text-xl">
          {value}
        </span>
      )}
    </div>
  )
}
