import { Nav } from "src/components/Header/Nav"
import { Footer } from "src/components/Footer/Footer"

export const Layout = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="flex min-h-dvh flex-col justify-between sm:h-fit sm:min-h-screen">
      <div className="flex flex-col">
        <Nav />
        <main
          id="body-wrapper"
          className="relative flex h-min w-full flex-col p-4 md:py-8"
        >
          {children}
        </main>
      </div>
      <Footer />
    </div>
  )
}
