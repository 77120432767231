import { cleanInput } from "src/utils/cleanInput"

export const SwapInput = ({
  title,
  inputValue,
  onChange,
}: {
  title: string
  inputValue: string
  onChange: (value: string) => void
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(cleanInput(e.target.value))
  }

  return (
    <div className="flex-1 space-y-1">
      <label htmlFor="swap-input" className="text-sm">
        {title}
      </label>
      <input
        id="swap-input"
        value={inputValue}
        onChange={handleChange}
        placeholder="0"
        pattern="^[0-9]*[.,]?[0-9]*$"
        autoComplete="off"
        className="w-full border-none bg-marginalBlack p-0 text-2xl text-marginalGray-200 outline-none focus:border-none focus:outline-none focus:ring-0"
      />
    </div>
  )
}
