export const BondingCurveProgressSlider = () => {
  return (
    <div
      id="bonding-curve-progress-slider"
      className={`flex flex-col space-y-1 text-xs text-marginalGray-200`}
    >
      <div className="w-fit rounded-md bg-marginalGray-900 px-2.5 py-1">$15.58M LEFT</div>
      <input
        type="range"
        min={0}
        max={30}
        step={0.1}
        // value={selectedLeverage}
        // onChange={handleLeverageChange}
        className="w-full cursor-pointer bg-marginalGray-600 accent-marginalOrange-500"
      />
      <div className="mt-1 flex w-full justify-between">
        <div>$0.00</div>
        <div>$30M</div>
      </div>
    </div>
  )
}
