import { Token } from "src/types"
import { isAddress } from "viem"

export const validateLBPExactInputSingleParams = (
  tokenIn: Token | undefined | null,
  tokenOut: Token | undefined | null,
  supplier: string | undefined,
  recipient: string | undefined,
  amountIn: string | undefined,
  blockTimestampInitialize: string | undefined,
  tickLower: bigint | undefined,
  tickUpper: bigint | undefined,
): boolean => {
  if (!tokenIn || !tokenOut || !amountIn) {
    return false
  }

  if (!tokenIn.address || !tokenOut.address) {
    return false
  }

  if (!isAddress(tokenIn.address) || !isAddress(tokenOut.address)) {
    return false
  }

  if (!supplier || !isAddress(supplier)) {
    return false
  }

  if (!recipient || !isAddress(recipient)) {
    return false
  }

  if (!blockTimestampInitialize) {
    return false
  }

  if (tickLower === undefined) {
    return false
  }

  if (!tickUpper === undefined) {
    return false
  }

  return true
}
