import { Modal } from "src/components/ui/Modal"
import { CheckIcon } from "src/components/Icons/CheckIcon"
import { SpinningLoader } from "src/components/Icons/SpinningLoader"
import { getExplorerLink, ExplorerDataType } from "src/utils/getExplorerLink"
import { ModalStatusView } from "src/components/ui/ModalStatusView"

interface Props {
  chainId: number
  open: boolean
  onOpen: () => void
  onClose: () => void
  onReset: () => void
  onCallback: () => void

  isPendingWallet: boolean
  isPendingApprove: boolean
  isPendingTx: boolean
  isTxSubmitted?: boolean

  txHash: string | null
  txError?: any

  hasConfirmModal: boolean
  children?: React.ReactNode
  action?: string
  buttonText?: string
  onConfirmText?: string
  onPendingText?: string
  onApproveText?: string
  onSuccessText?: string
}

export const TransactionStatusModal = ({
  chainId,
  open,
  onOpen,
  onClose,
  onReset,
  onCallback,

  isPendingWallet,
  isPendingApprove,
  isPendingTx,
  isTxSubmitted,

  txHash,
  txError,

  hasConfirmModal,
  children,
  buttonText,
  onConfirmText,
  onApproveText,
  onPendingText,
  onSuccessText,
}: Props) => {
  if (open) {
    const isPending = isPendingWallet || isPendingTx || isPendingApprove

    const handleModalClose = () => {
      if (isPending) {
        return
      }

      if (txHash) {
        onReset()
      } else {
        onClose()
      }
    }

    return (
      <Modal header="" isOpen={open} onClose={handleModalClose}>
        {isPendingWallet ? (
          <ModalStatusView
            icon={<SpinningLoader />}
            primaryText={onConfirmText ?? "Confirm transaction"}
            secondaryText="Proceed in your wallet"
          />
        ) : isPendingApprove ? (
          <ModalStatusView
            icon={<SpinningLoader />}
            primaryText={onApproveText ?? "Awaiting approval"}
            secondaryText="Waiting for transaction to finalize"
          />
        ) : isPendingTx ? (
          <ModalStatusView
            icon={<SpinningLoader />}
            primaryText={onPendingText ?? "Transaction submitted"}
            secondaryText="Waiting for transaction to finalize"
          />
        ) : !isPendingTx && txHash ? (
          <ModalStatusView
            icon={<CheckIcon />}
            primaryText={onSuccessText ?? "Transaction Executed"}
            buttonText="Close"
            onClick={onReset}
            blockExplorerUrl={
              txHash ? getExplorerLink(chainId, txHash, ExplorerDataType.TRANSACTION) : ""
            }
          />
        ) : hasConfirmModal ? (
          <div className="flex flex-col space-y-3 px-2 py-3">
            {children}

            <button
              id={`${buttonText}-button`}
              className={`w-full rounded-xl bg-marginalOrange-500 p-2 font-bold text-white hover:animate-pulse hover:opacity-80`}
              onClick={() => onCallback()}
            >
              {buttonText}
            </button>
          </div>
        ) : null}
      </Modal>
    )
  } else {
    return null
  }
}
