import { useState } from "react"
import { isUndefined } from "lodash"
import { type PoolData, type Token } from "src/types"
import { ListRow } from "src/components/ui/ListRow"
import { TokenBadge, DoubleTokenLogo } from "src/components/TokenBadge"
import { getPriceImpactIndicatorColor } from "src/pages/trade/helpers/getPriceImpactIndicatorColor"
import { RotatingActiveArrow } from "src/components/RotatingActiveArrow"
import { AssetPairPriceRatio } from "../../../components/AssetPairPriceRatio"

interface Props {
  pool: PoolData | null
  token0: Token | null
  token1: Token | null
  marginToken: Token | null
  debtToken: Token | null
  poolMaxLeverage: number | undefined | null

  quotedDebt: string | null | undefined
  poolPrice: string | null | undefined
  oraclePrice: string | null | undefined
  liquidationPrice: string | null | undefined

  fundingRate: string | number | null | undefined
  escrowRewards: string | null | undefined
  maxSlippage: string
  priceImpact: number | null
  healthFactor: number | null

  useInverse: boolean
  onToggleInverse: () => void
}

export const TradeDetailList = ({
  pool,
  token0,
  token1,
  marginToken,
  debtToken,
  poolMaxLeverage,

  quotedDebt,
  poolPrice,
  oraclePrice,
  liquidationPrice,

  fundingRate,
  escrowRewards,
  maxSlippage,
  priceImpact,
  healthFactor,

  useInverse,
  onToggleInverse,
}: Props) => {
  const [show, setShow] = useState(true)

  const priceImpactIndicator = getPriceImpactIndicatorColor(priceImpact)

  const fundingRateIndicator =
    Math.abs(Number(fundingRate)) > 1
      ? "text-warning-500"
      : Math.abs(Number(fundingRate)) > 5
        ? "text-error-500"
        : "text-marginalGray-200"

  if (!pool) return null

  return (
    <div
      id="trade-detail-list"
      className="duration-175 transform-gpu rounded-lg px-2 py-2"
    >
      <section className="space-y-3">
        <header className="flex items-center justify-between">
          <h1 className="whitespace-nowrap">Execution details</h1>
          <button
            onClick={() => setShow(!show)}
            className="flex w-full cursor-pointer items-center justify-end gap-1 hover:opacity-60"
          >
            <span className="text-sm">{show ? "hide" : "show"}</span>
            <RotatingActiveArrow isActive={show} />
          </button>
        </header>
        <div className="flex items-center justify-between">
          <AssetPairPriceRatio
            token0={token0}
            token1={token1}
            price={poolPrice}
            useInverse={useInverse}
            onToggleInverse={onToggleInverse}
          />
        </div>
      </section>

      <div
        className={`transform-gpu overflow-y-hidden text-sm text-marginalGray-200 transition-max-height duration-500 ease-in-out ${show ? "max-h-fit" : "max-h-0"} `}
      >
        <div className="py-4 text-marginalGray-400">
          <ListRow
            item="Pool"
            value={
              pool && (
                <div className="flex items-center space-x-1">
                  {token0 && token1 && (
                    <DoubleTokenLogo token0={token0} token1={token1} size={4} />
                  )}
                  <div className="flex flex-wrap items-baseline space-x-2">
                    <div className="flex items-center space-x-1 text-marginalGray-200">
                      <span>{token0?.symbol}</span>
                      <div className="my-auto">/</div>
                      <span>{token1?.symbol}</span>
                    </div>
                  </div>
                </div>
              )
            }
          />
          <div className="my-3 h-px bg-marginalGray-200/20" />
          <div className="space-y-2">
            <ListRow
              item="Oracle price"
              value={
                oraclePrice && (
                  <div className="flex flex-wrap justify-end space-x-1 text-marginalGray-200">
                    <div>{oraclePrice}</div>
                    {!useInverse ? (
                      <div className="flex items-center">
                        <span>{token1?.symbol}</span>
                        <div>/</div>
                        <span>{token0?.symbol}</span>
                      </div>
                    ) : (
                      <div className="flex items-center">
                        <span>{token0?.symbol}</span>
                        <div>/</div>
                        <span>{token1?.symbol}</span>
                      </div>
                    )}
                  </div>
                )
              }
            />
            <ListRow
              item="Pool price"
              value={
                poolPrice && (
                  <div className="flex flex-wrap justify-end space-x-1 text-marginalGray-200">
                    <div>{poolPrice}</div>
                    {!useInverse ? (
                      <div className="flex items-center">
                        <span>{token1?.symbol}</span>
                        <div>/</div>
                        <span>{token0?.symbol}</span>
                      </div>
                    ) : (
                      <div className="flex items-center">
                        <span>{token0?.symbol}</span>
                        <div>/</div>
                        <span>{token1?.symbol}</span>
                      </div>
                    )}
                  </div>
                )
              }
            />
            <ListRow
              item="Liquidation price"
              value={
                liquidationPrice && (
                  <div className="flex flex-wrap justify-end space-x-1 text-marginalGray-200">
                    <div>{liquidationPrice}</div>
                    {!useInverse ? (
                      <div className="flex items-center text-marginalGray-200">
                        <span>{token1?.symbol}</span>
                        <div>/</div>
                        <span>{token0?.symbol}</span>
                      </div>
                    ) : (
                      <div className="flex items-center text-marginalGray-200">
                        <span>{token0?.symbol}</span>
                        <div>/</div>
                        <span>{token1?.symbol}</span>
                      </div>
                    )}
                  </div>
                )
              }
            />
            <ListRow
              item="Funding rate"
              value={
                fundingRate && (
                  <div
                    className={`flex flex-wrap justify-end space-x-1 ${fundingRateIndicator}`}
                  >
                    <span>{fundingRate}% weekly</span>
                  </div>
                )
              }
            />
            <ListRow
              item="Escrow rewards"
              value={
                escrowRewards && (
                  <div className="flex flex-wrap justify-end space-x-1 text-marginalGray-200">
                    <span>{escrowRewards} ETH</span>
                  </div>
                )
              }
            />
          </div>

          <div className="my-3 h-px bg-marginalGray-200/20" />

          <div className="space-y-2">
            <ListRow
              item="Debt"
              value={
                quotedDebt && (
                  <div className="flex flex-wrap justify-end space-x-1 text-marginalGray-200">
                    <div>{quotedDebt}</div>
                    {debtToken && (
                      <TokenBadge token={debtToken} size={4} reverse={true} />
                    )}
                  </div>
                )
              }
            />
            <ListRow
              item="Max slippage"
              value={
                maxSlippage && (
                  <div className="flex flex-wrap justify-end space-x-1 text-marginalGray-200">
                    {maxSlippage === "0.5" && (
                      <div className="text-xs rounded bg-marginalGray-800 px-0.5">
                        Auto
                      </div>
                    )}
                    <div>{maxSlippage}%</div>
                  </div>
                )
              }
            />
            <ListRow
              item="Max leverage"
              value={
                poolMaxLeverage && (
                  <div className="text-marginalGray-200">{poolMaxLeverage + "x"}</div>
                )
              }
            />
            <ListRow
              item="Impact"
              value={
                priceImpact && (
                  <div className="flex flex-wrap justify-end space-x-1">
                    <div className={priceImpactIndicator}>{-priceImpact.toFixed(2)}%</div>
                  </div>
                )
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export function calculateInversePrice(price: string | undefined) {
  if (isUndefined(price)) return undefined

  const priceFloat = parseFloat(price)

  if (isNaN(priceFloat)) {
    throw new Error("Invalid price input")
  }

  const inversePrice = 1 / priceFloat

  return inversePrice.toString()
}
