import { Modal } from "src/components/ui/Modal"
import { ListRow } from "src/components/ui/ListRow"
import { type PoolData, type Token } from "src/types"
import { CheckIcon } from "src/components/Icons/CheckIcon"
import { SwapButton } from "src/pages/swap/components/SwapButton"
import { AssetPairPriceRatio } from "src/components/AssetPairPriceRatio"
import { getExplorerLink, ExplorerDataType } from "src/utils/getExplorerLink"
import { ReactNode } from "react"
import { ArrowRightIcon } from "src/components/Icons/ArrowRightIcon"
import { ErrorIcon } from "src/components/Icons/ErrorIcon"
import { DoubleTokenLogo, TokenBadge } from "src/components/TokenBadge"
import { ModalStatusView } from "src/components/ui/ModalStatusView"

interface Props {
  chainId: number
  pool: PoolData | null
  inputToken: Token | null
  outputToken: Token | null

  open: boolean
  onOpen: () => void
  onClose: () => void
  onReset: () => void

  quotedInput: string
  quotedOutput: string | undefined

  swapQuote: any
  swapCallback: (() => Promise<any>) | undefined

  isPendingWallet: boolean
  isPendingApprove: boolean
  isPendingTx: boolean
  isTxSubmitted: boolean
  txHash: string | null
  txError: any

  poolPrice: string | null | undefined
  maxSlippage: string

  useInverse: boolean
  onToggleInverse: () => void
}

export const ConfirmSwapModal = ({
  chainId,
  pool,
  inputToken,
  outputToken,

  open,
  onOpen,
  onClose,
  onReset,

  quotedInput,
  quotedOutput,
  swapQuote,
  swapCallback,

  isPendingWallet,
  isPendingApprove,
  isPendingTx,
  isTxSubmitted,
  txHash,
  txError,

  poolPrice,
  maxSlippage,

  useInverse,
  onToggleInverse,
}: Props) => {
  return (
    <Modal
      header="Review Swap"
      isOpen={open}
      onClose={txHash ? onReset : isPendingTx ? () => null : onClose}
    >
      {txError ? (
        <ModalStatusView
          icon={<ErrorIcon />}
          primaryText="Swap failed"
          secondaryText="Something went wrong"
          buttonText="Close"
          onClick={onReset}
        />
      ) : !isPendingTx && txHash ? (
        <ModalStatusView
          icon={<CheckIcon />}
          primaryText="Swap executed"
          buttonText="Close"
          onClick={onReset}
          blockExplorerUrl={
            txHash ? getExplorerLink(chainId, txHash, ExplorerDataType.TRANSACTION) : ""
          }
        />
      ) : (
        <div className="flex w-full flex-col items-center space-y-3 p-4">
          <DetailsContainer>
            <div className="flex flex-col">
              <div className="mb-1 flex flex-wrap items-center space-x-2">
                <DoubleTokenLogo
                  token0={inputToken as Token}
                  token1={outputToken as Token}
                  size={4}
                />
                <div className="flex items-center space-x-1 text-xl text-marginalGray-200">
                  <span>{inputToken?.symbol}</span>
                  <div className="my-auto text-lg">/</div>
                  <span>{outputToken?.symbol}</span>
                </div>
              </div>
              <AssetPairPriceRatio
                token0={inputToken}
                token1={outputToken}
                price={poolPrice}
                useInverse={useInverse}
                onToggleInverse={onToggleInverse}
              />
            </div>

            <div className="mt-3 space-y-1 text-marginalGray-600">
              <ListRow
                item="Pool price"
                value={
                  <div className="flex items-baseline space-x-1 text-marginalGray-200">
                    <span>{poolPrice}</span>
                    {useInverse ? (
                      <div className="flex items-center">
                        <span>{outputToken?.symbol}</span>
                        <span>/</span>
                        <span>{inputToken?.symbol}</span>
                      </div>
                    ) : (
                      <div className="flex items-center">
                        <span>{inputToken?.symbol}</span>
                        <span>/</span>
                        <span>{outputToken?.symbol}</span>
                      </div>
                    )}
                  </div>
                }
              />
              <ListRow
                item="Slippage Tolerance"
                value={
                  <div className="flex items-baseline space-x-1 text-marginalGray-200">
                    <span>{maxSlippage}%</span>
                  </div>
                }
              />
            </div>

            <div className="my-4 h-px bg-marginalGray-800" />

            <div className="flex flex-col space-y-2">
              <span className="text-sm text-marginalGray-600">
                You Pay to Receive
              </span>
              <div className="flex flex-wrap items-center space-x-1 text-sm text-marginalGray-200">
                <div className="flex flex-wrap justify-end space-x-1">
                  <div>{quotedInput}</div>
                  <TokenBadge token={inputToken as Token} size={4} />
                </div>

                <ArrowRightIcon />

                <div className="flex flex-wrap justify-end space-x-1">
                  <div>{quotedOutput}</div>
                  <TokenBadge token={outputToken as Token} size={4} />
                </div>
              </div>
            </div>
          </DetailsContainer>
          <SwapButton
            swapCallback={swapCallback}
            isPendingWallet={isPendingWallet}
            isPendingApprove={isPendingApprove}
            isPendingTx={isPendingTx}
            isTxSubmitted={isTxSubmitted}
            txError={txError}
          />
        </div>
      )}
    </Modal>
  )
}

const DetailsContainer = ({ children }: { children: ReactNode }) => {
  return (
    <div className="h-fit w-full rounded-lg bg-marginalBlack p-4 text-xs text-marginalGray-600">
      {children}
    </div>
  )
}
