import { useAccount } from "wagmi"
import { Address } from "viem"
import { useState, useMemo } from "react"
import { getTransactionError } from "src/utils/getTransactionError"
import { PercentSlider } from "src/components/PercentSlider"
import { WithdrawButton } from "src/pages/position/managePosition/components/WithdrawButton"
import { InputContainer } from "src/components/ui/InputContainer"
import { WithdrawInput } from "src/pages/position/managePosition/components/WithdrawInput"
import { formatBigIntToString } from "src/utils/formatBigIntToString"
import { trimTrailingZeroes } from "src/utils/trimTrailingZeroes"
import { ListRow } from "src/components/ui/ListRow"
import { formatNumberAmount } from "src/utils/formatNumberAmount"
import { AssetPairPriceRatio } from "src/components/AssetPairPriceRatio"
import { TransactionStatusModal } from "src/components/TransactionStatusModal"
import { useGetCurrentBlockTimestamp } from "src/hooks/useGetCurrentBlockTimestamp"
import { useSettingsState } from "src/state/settings/hooks"
import { useInversePrice } from "src/hooks/useInversePrice"
import { RotatingActiveArrow } from "src/components/RotatingActiveArrow"
import { HealthFactorIcon } from "src/components/Icons/HealthFactorIcon"
import { free } from "src/utils/actions/free"
import { extrapolateTokenPair } from "src/utils/extrapolateTokenPair"
import { validateFreeParams } from "./helpers/validateFreeParams"
import { constructFreeParams } from "./helpers/constructFreeParams"
import { Token } from "src/types"
import { useLivePoolPrices } from "src/pages/trade/hooks/useLivePoolPrices"
import { derivePositionData } from "src/pages/position/managePosition/helpers/derivePositionData"
import { getHealthFactorIndicatorColor } from "./helpers/getHealthFactorIndicatorColor"
import { useTransactionModal } from "src/pages/trade/hooks/useTransactionModal"
import { isBlank } from "src/utils/isBlank"
import { formatStringToBigInt } from "src/utils/formatStringToBigInt"
import { GetPositionQuery } from "src/state/api/generated"
import { PositionState } from "src/pages/positions/hooks/usePositionsOnChainState"
import { usePoolsData, getPoolDataByAddress } from "src/hooks/usePoolsData"
import { useApplicationState } from "src/state/application/hooks"
import { getTokenByAddress } from "src/constants/tokenList"
import { TokenBadge } from "src/components/TokenBadge"

interface WithdrawProps {
  position: GetPositionQuery["position"]
  positionState: PositionState
  fetchPositionState: () => void
}

export const Withdraw = ({
  position,
  positionState,
  fetchPositionState,
}: WithdrawProps) => {
  const { chainId } = useApplicationState()
  const { address } = useAccount()
  const currentBlockTimestamp = useGetCurrentBlockTimestamp(chainId)
  const { transactionDeadline } = useSettingsState()
  const [useInverse, onToggleInverse] = useInversePrice()
  const { poolsDataByAddress } = usePoolsData()
  const pool = getPoolDataByAddress(position?.pool?.address, poolsDataByAddress)
  const tokenId = positionState?.tokenId
  const zeroForOne: boolean = positionState?.zeroForOne
  const token0 = getTokenByAddress(pool?.token0?.address, chainId) ?? pool?.token0
  const token1 = getTokenByAddress(pool?.token1?.address, chainId) ?? pool?.token1
  const [marginToken, debtToken] = zeroForOne ? [token1, token0] : [token0, token1]
  const { quoteToken } = extrapolateTokenPair(token0 ?? null, token1 ?? null, chainId)
  const [percentError, setPercentError] = useState(false)
  const [showDetailList, setShowDetailList] = useState(true)

  // TODO: Lift up to Store
  const [inputValue, setInputValue] = useState("")
  const [freePercentage, setFreePercentage] = useState<number>(0)

  const formattedBalance = formatBigIntToString(
    positionState?.margin,
    marginToken?.decimals,
  )
  const formattedInput = !isBlank(inputValue)
    ? formatStringToBigInt(inputValue, marginToken?.decimals)
    : 0n

  const safeMarginOut = calculateSafeMaxAmountOut(
    positionState?.margin,
    positionState?.safeMarginMinimum,
  )

  const isInputValid = useMemo(() => {
    if (!formattedInput) return false
    if (!freePercentage) return false
    return true
  }, [formattedInput, freePercentage])

  const isBalanceSufficient = useMemo(() => {
    if (!formattedInput) return true
    if (!safeMarginOut) return true
    return formattedInput <= safeMarginOut
  }, [formattedInput, safeMarginOut])

  const freeParams = validateFreeParams(
    pool?.token0,
    pool?.token1,
    pool?.maintenance,
    pool?.oracleAddress,
    tokenId,
    inputValue,
    marginToken,
    address,
  )
    ? constructFreeParams(
        pool?.token0 as Token,
        pool?.token1 as Token,
        pool?.maintenance as bigint,
        pool?.oracleAddress as Address,
        tokenId,
        inputValue as string,
        marginToken as Token,
        address as Address,
        currentBlockTimestamp,
        transactionDeadline,
      )
    : null

  const interactiveMargin = positionState?.margin
    ? BigInt(positionState.margin) - (freeParams?.marginOut ?? 0n)
    : positionState?.margin

  const { margin, safeMarginMin, leverage, healthFactor } = derivePositionData(
    {
      ...positionState,
      margin: interactiveMargin,
    },
    pool,
    marginToken,
    debtToken,
  )

  const { oraclePrice, poolPrice, liquidationPrice } = useLivePoolPrices({
    chainId,
    selectedPool: {
      token0: pool?.token0 as Token,
      token1: pool?.token1 as Token,
      maintenance: pool?.maintenance as bigint,
      oracleAddress: pool?.oracleAddress as Address,
    },
    zeroForOne,
    useInverse,
    quoteToken,
    position: {
      size: positionState?.size,
      debt: positionState?.debt,
      margin: interactiveMargin as bigint,
    },
  })

  const healthFactorIndicator = getHealthFactorIndicatorColor(healthFactor)

  const {
    state: {
      showConfirm,
      isPendingWallet,
      isPendingApprove,
      isPendingTx,
      isTxSubmitted,
      txHash,
      txError,
    },
    openConfirmModal,
    closeConfirmModal,
    resetState: resetTransactionState,
    setPendingWallet,
    setTxSubmitted,
  } = useTransactionModal()

  const onSuccessReset = () => {
    resetTransactionState()
    setInputValue("")
    setFreePercentage(0)
  }

  const handleWithdraw = async () => {
    try {
      if (!freeParams) {
        throw new Error("Missing free params")
      }
      setPendingWallet(true)
      const transaction = await free(freeParams, chainId)
      fetchPositionState()
      setTxSubmitted(true, transaction.transactionHash)
      onSuccessReset()
      return transaction.transactionHash
    } catch (error) {
      console.error("Error executing withdraw: ", error)
      if (getTransactionError(error) === "Wallet rejected transaction.") {
        resetTransactionState()
      }
    }
  }

  return (
    <div className="relative">
      <div className="space-y-2 rounded-b-xl border border-t-0 border-marginalGray-800 bg-marginalGray-900 p-2 sm:space-y-4 sm:p-4">
        <div className="space-y-2">
          <InputContainer id="withdraw-input-container">
            <WithdrawInput
              title="Collateral"
              inputValue={inputValue}
              totalBalance={formattedBalance}
              setPercentage={setFreePercentage}
              onChange={setInputValue}
              setPercentError={setPercentError}
            />
            <div className="flex flex-col items-end justify-center gap-1.5">
              <TokenBadge token={marginToken as Token} size={6} />
              <div className="flex space-x-2 text-sm">
                <div className="whitespace-nowrap text-marginalGray-600">
                  balance: {trimTrailingZeroes(formattedBalance)}
                </div>
                <div
                  className="cursor-pointer rounded-sm bg-[#4C2D1E] px-0.5 text-marginalOrange-500"
                  onClick={() => {
                    setInputValue(formattedBalance ? formattedBalance : "")
                    setFreePercentage(100)
                  }}
                >
                  Max
                </div>
              </div>
            </div>
          </InputContainer>
          <PercentSlider
            userInput={inputValue}
            isInputValid={isInputValid}
            formattedBalance={formattedBalance}
            margin={position?.margin}
            decimals={marginToken?.decimals}
            onUserInput={setInputValue}
            selectedPercentage={freePercentage}
            setPercentage={setFreePercentage}
            setPercentError={setPercentError}
            percentError={percentError}
          />
        </div>
        <WithdrawButton
          chainId={chainId}
          isInputValid={isInputValid}
          freeCallback={handleWithdraw}
          isPendingWallet={isPendingWallet}
          isPendingTx={isPendingTx}
          error={!isBalanceSufficient ? "Not enough balance." : null}
        />
      </div>

      <div className="mx-auto mt-4 w-full px-0 sm:px-4">
        <div
          id="manage-position-detail-list"
          className="duration-175 transform-gpu rounded-lg px-2 py-2"
        >
          <section className="space-y-3">
            <header className="flex items-center justify-between">
              <h1 className="whitespace-nowrap">Execution details</h1>
              <button
                onClick={() => setShowDetailList(!showDetailList)}
                className="flex w-full cursor-pointer items-center justify-end gap-1 hover:opacity-60"
              >
                <span className="text-sm">{showDetailList ? "hide" : "show"}</span>
                <RotatingActiveArrow isActive={showDetailList} />
              </button>
            </header>
            <div className="flex items-center justify-between">
              <AssetPairPriceRatio
                token0={token0}
                token1={token1}
                price={poolPrice}
                useInverse={useInverse}
                onToggleInverse={onToggleInverse}
              />
            </div>
          </section>

          <div
            className={`transform-gpu overflow-y-hidden text-sm text-marginalGray-600 transition-max-height duration-500 ease-in-out ${showDetailList ? "max-h-fitw" : "max-h-0"} `}
          >
            <div className="space-y-2 py-4">
              <div className="h-px bg-marginalGray-200/20" />
              <ListRow
                item="Margin"
                value={
                  <div className={`flex items-center space-x-1 text-marginalGray-200`}>
                    <div>
                      {formatNumberAmount(margin.parsed, true)} {marginToken?.symbol}
                    </div>
                  </div>
                }
              />
              <ListRow
                item="Maintenance margin"
                value={
                  <div className={`flex items-center space-x-1 text-marginalGray-200`}>
                    <div>
                      {formatNumberAmount(safeMarginMin.parsed, true)}{" "}
                      {marginToken?.symbol}
                    </div>
                  </div>
                }
              />

              <ListRow
                item="Health factor"
                value={
                  <div className={`flex items-center space-x-1 ${healthFactorIndicator}`}>
                    <HealthFactorIcon />
                    <div>{healthFactor?.toFixed(2)}</div>
                  </div>
                }
              />
              <ListRow
                item="Leverage"
                value={
                  leverage.current ? (
                    <div className="text-marginalGray-200">{leverage.current}x</div>
                  ) : (
                    "-"
                  )
                }
              />
              <ListRow
                item="Oracle price"
                value={
                  oraclePrice && (
                    <div className="flex flex-wrap items-center space-x-1 text-marginalGray-200">
                      <div>{formatNumberAmount(oraclePrice, true)}</div>
                      {!useInverse ? (
                        <div className="flex items-center">
                          <div>{token1?.symbol}</div>
                          <div>/</div>
                          <div>{token0?.symbol}</div>
                        </div>
                      ) : (
                        <div className="flex items-center">
                          <div>{token0?.symbol}</div>
                          <div>/</div>
                          <div>{token1?.symbol}</div>
                        </div>
                      )}
                    </div>
                  )
                }
              />
              <ListRow
                item="Liquidation price"
                value={
                  <div className="flex flex-wrap items-center space-x-1 text-marginalGray-200">
                    {liquidationPrice ? (
                      <div>{formatNumberAmount(liquidationPrice, true)}</div>
                    ) : (
                      <div>-</div>
                    )}
                    {!useInverse ? (
                      <div className="flex items-center">
                        <div>{token1?.symbol}</div>
                        <div>/</div>
                        <div>{token0?.symbol}</div>
                      </div>
                    ) : (
                      <div className="flex items-center">
                        <div>{token0?.symbol}</div>
                        <div>/</div>
                        <div>{token1?.symbol}</div>
                      </div>
                    )}
                  </div>
                }
              />
            </div>
          </div>
        </div>
      </div>
      <TransactionStatusModal
        chainId={chainId}
        open={showConfirm}
        onOpen={openConfirmModal}
        onClose={closeConfirmModal}
        onReset={onSuccessReset}
        onCallback={handleWithdraw}
        isPendingWallet={isPendingWallet}
        isPendingApprove={isPendingApprove}
        isPendingTx={isPendingTx}
        isTxSubmitted={isTxSubmitted}
        txHash={txHash}
        txError={txError}
        hasConfirmModal={false}
        onSuccessText="Withdraw Executed"
      />
    </div>
  )
}

const calculateSafeMaxAmountOut = (
  margin?: bigint,
  safeMarginMinimum?: bigint,
): bigint | undefined => {
  if (!margin || !safeMarginMinimum) return undefined
  return margin - safeMarginMinimum
}
