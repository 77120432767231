import { ReactNode } from "react"

export const TableHeaderCell = ({
  align = "right",
  children,
}: {
  align?: "right" | "left"
  children?: ReactNode
}) => {
  return (
    <th
      className={`whitespace-nowrap p-4 text-xs text-marginalGray-200 ${align === "right" ? "text-right" : "text-left"} `}
    >
      {children}
    </th>
  )
}

export const TableCell = ({ children }: { children?: ReactNode }) => {
  return (
    <td className="whitespace-nowrap p-4 text-right text-sm text-marginalGray-200 first:text-left">
      {children}
    </td>
  )
}

export const MobileTableCell = ({
  label,
  children,
}: {
  label?: string
  children: ReactNode
}) => {
  return (
    <td className="flex w-full flex-col space-y-1 text-xs text-marginalGray-200">
      {label && <span className="text-marginalGray-600">{label}:</span>}
      {children}
    </td>
  )
}
