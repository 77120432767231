import { createConfig } from "wagmi"
import {
  metaMaskWallet,
  rabbyWallet,
  coinbaseWallet,
  rainbowWallet,
  walletConnectWallet,
  trustWallet,
  ledgerWallet,
  frameWallet,
  safeWallet,
} from "@rainbow-me/rainbowkit/wallets"
import { connectorsForWallets } from "@rainbow-me/rainbowkit"
import { createTransports } from "./utils/createTransports"
import { supportedChains } from "./constants/chains/supportedChains"
import { type Chain } from "viem"

const appName = "Marginal"
const projectId = "8086f382c384d69b52b07654ae394a46"

const connectors = connectorsForWallets(
  [
    {
      groupName: "Wallets",
      wallets: [
        metaMaskWallet,
        walletConnectWallet,
        coinbaseWallet,
        rainbowWallet,
        rabbyWallet,
        trustWallet,
        ledgerWallet,
        frameWallet,
        safeWallet,
      ],
    },
  ],
  {
    projectId,
    appName,
  },
)

const transports = createTransports(supportedChains as Chain[])

export const wagmiConfig = createConfig({
  connectors,
  chains: supportedChains as unknown as readonly [Chain, ...Chain[]],
  transports,
})
