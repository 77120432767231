import { useReadContracts } from "wagmi"
import { Address } from "viem"
import { formatBigIntToString } from "src/utils/formatBigIntToString"
import MultiRewardsAbi from "src/constants/abis/MultiRewards.json"
import { type PoolData } from "src/types"

interface StakeBalanceResult {
  balance: bigint
  parsedBalance?: string
}

interface StakeBalancesMap {
  [poolAddress: string]: StakeBalanceResult
}

/**
 * Creates a contract read configuration for the MultiRewards balanceOf function
 * @param stakeAddress - The address of the staking contract
 * @param ownerAddress - The address of the token owner
 */
const createBalanceOfCall = (stakeAddress: Address, ownerAddress: Address) => ({
  address: stakeAddress,
  abi: MultiRewardsAbi as any,
  functionName: "balanceOf",
  args: [ownerAddress],
})

/**
 * Hook to fetch user's staked balances across multiple pools
 * @param stakePools - Array of pool data objects
 * @param owner - Address of the token owner
 * @returns Object containing balances mapped by pool address and refetch function
 */
export const useUserStakedPoolBalance = (stakePools: PoolData[], owner?: Address) => {
  // Filter out pools without staking contracts and create contract calls
  const contracts = stakePools
    .filter((pool): pool is PoolData & { stakePool: Address } => !!pool?.stakePool)
    .map((pool) => createBalanceOfCall(pool.stakePool, owner as Address))

  const isEnabled = contracts.length > 0 && !!owner

  const { data, isError, isLoading, refetch } = useReadContracts({
    contracts,
    query: {
      enabled: isEnabled,
    },
  })

  // Transform raw balance data into a mapped object
  const balances = stakePools.reduce<StakeBalancesMap>((acc, pool, index) => {
    if (!pool?.stakePool || !data?.[index]?.result) return acc

    const rawBalance = data[index].result as bigint

    acc[pool.stakePool] = {
      balance: rawBalance,
      parsedBalance: formatBigIntToString(rawBalance, pool.decimals),
    }

    return acc
  }, {})

  return {
    balances,
    isError,
    isLoading,
    refetch,
  }
}
