import { useMemo } from "react"
import { useReadContracts } from "wagmi"
import { Address } from "viem"
import { V1_APR_ADDRESS } from "../constants/addresses"
import marginalV1APRAbi from "../constants/abis/MarginalV1APR.json"
import { formatBigIntToString } from "src/utils/formatBigIntToString"

interface APRQuoteResult {
  aprPercentage: number | undefined
  isError: boolean
  isLoading: boolean
}

/**
 * Custom hook to fetch and calculate APR quote percentage rate
 * @returns Object containing the calculated APR percentage and status flags
 */
export const usePoolRewardsAPR = (
  stakingToken: Address,
  rewardsToken: Address,
  rewardsPoolWithWETH9: Address,
  duration: number,
  chainId: number,
): APRQuoteResult => {
  // Create contract configuration
  const contract = useMemo(() => {
    if (
      !stakingToken ||
      !rewardsToken ||
      !rewardsPoolWithWETH9 ||
      !duration ||
      !chainId
    ) {
      return null
    }

    return {
      address: V1_APR_ADDRESS[chainId],
      abi: marginalV1APRAbi as any,
      functionName: "quotePercentageRate",
      args: [stakingToken, rewardsToken, rewardsPoolWithWETH9, duration],
      chainId,
    }
  }, [stakingToken, rewardsToken, rewardsPoolWithWETH9, duration, chainId])

  // Fetch APR data
  const { data, isError, isLoading } = useReadContracts({
    contracts: contract ? [contract] : [],
    query: {
      enabled: !!contract,
    },
  })

  // Calculate APR percentage
  const aprPercentage = useMemo(() => {
    const rawRate = data?.[0]?.result as bigint | undefined

    if (!rawRate) return undefined

    const formattedRate = formatBigIntToString(rawRate, 18)
    if (!formattedRate) return undefined

    return parseFloat(formattedRate) * 100
  }, [data])

  return {
    aprPercentage,
    isError,
    isLoading,
  }
}
