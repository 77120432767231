import { useMemo } from "react"
import { isUndefined } from "lodash"
import { PoolData } from "src/types"

export enum PoolsView {
  INCENTIVIZED = "Incentivized",
  UNINCENTIVIZED = "Unincentivized",
}

export const usePoolsFilters = (pools: PoolData[]) => {
  const incentivizedPools = useMemo(() => {
    return pools.filter((pool) => pool.stakePool)
  }, [pools])

  const unincentivizedPools = useMemo(() => {
    return pools.filter((pool) => isUndefined(pool.stakePool))
  }, [pools])

  return {
    incentivizedPools,
    unincentivizedPools,
  }
}
