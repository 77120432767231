import bigDecimal from "js-big-decimal"
import { convertInputToSize } from "./convertInputToSize"

export const convertLeverageInputToDesiredSize = (
  inputValue: string,
  leverage: number,
  decimals: number,
) => {
  const inputValue_BD = new bigDecimal(inputValue)
  const applyLeverage_BD = new bigDecimal(leverage - 1)

  const leveragedValue = inputValue_BD.multiply(applyLeverage_BD).getValue()

  return convertInputToSize(leveragedValue, decimals)
}
