import { PoolsTabSelector } from "src/pages/pools/components/PoolsTabSelector"
import { PoolsView } from "src/pages/pools/hooks/usePoolsFilters"
import { ActionButton } from "src/components/ActionButton"
import { useNavigateRoutes } from "src/hooks/useNavigateRoutes"

interface PoolsHeaderProps {
  view: PoolsView
  onViewChange: (view: PoolsView) => void
}

export const PoolsHeader = ({ view, onViewChange }: PoolsHeaderProps) => {
  const { onNavigateToCreatePool } = useNavigateRoutes()

  return (
    <div className="flex w-full flex-col items-start border-b border-marginalGray-800 bg-marginalGray-900 px-4">
      <div className="flex w-full items-center justify-between">
        <p className="py-4 text-xl text-marginalGray-200">Pools</p>
        <div className="flex justify-center space-x-2 md:w-fit md:justify-end md:space-x-4">
          <ActionButton action="Create Pool" onClick={onNavigateToCreatePool} size="sm" />
        </div>
      </div>
      <PoolsTabSelector
        view={view}
        handlePrimaryClick={() => onViewChange(PoolsView.INCENTIVIZED)}
        handleSecondaryClick={() => onViewChange(PoolsView.UNINCENTIVIZED)}
      />
    </div>
  )
}
