import _ from "lodash"
import { useReadContracts } from "wagmi"
import { Address } from "viem"
import marginalV1PoolAbi from "src/constants/abis/MarginalV1Pool.json"
import { formatBigIntToString } from "src/utils/formatBigIntToString"
import { PoolData } from "src/types"

export type PoolState = {
  sqrtPriceX96: bigint
  totalPositions: number
  liquidity: bigint
  parsedLiquidity: string
  tick: bigint
  blockTimestamp: string
  tickCumulative: bigint
  feeProtocol: bigint
  initialized: boolean
}

export interface EnhancedPoolState extends PoolState {
  pool: PoolData | undefined | null
  liquidityLocked: bigint
  parsedLockedLiquidity: string | undefined
  liquidityFree: number
}

interface PoolStateMap {
  [poolAddress: string]: EnhancedPoolState | undefined
}

interface PoolStateResult {
  poolStates: PoolStateMap
  isError: boolean
  isLoading: boolean
  refetch: () => void
}

const createStateContractConfig = (poolAddress: Address, chainId?: number) => ({
  address: poolAddress,
  abi: marginalV1PoolAbi as any,
  functionName: "state" as const,
  chainId,
})

const createLiquidityLockedContractConfig = (poolAddress: Address, chainId?: number) => ({
  address: poolAddress,
  abi: marginalV1PoolAbi as any,
  functionName: "liquidityLocked" as const,
  chainId,
})

const parsePoolState = (data: readonly unknown[]): PoolState => {
  const liquidity = data[2] as bigint
  return {
    sqrtPriceX96: data[0] as bigint,
    totalPositions: Number(data[1]),
    liquidity,
    parsedLiquidity: formatBigIntToString(liquidity, "18") as string,
    tick: data[3] as bigint,
    blockTimestamp: String(data[4]),
    tickCumulative: data[5] as bigint,
    feeProtocol: data[6] as bigint,
    initialized: Boolean(data[7]),
  }
}

const formatLiquidityLocked = (
  liquidityLocked: bigint,
  decimals?: string,
): string | undefined => {
  return formatBigIntToString(liquidityLocked, decimals)
}

export const useMarginalPoolsState = (
  pools: (PoolData | undefined | null)[],
  chainId?: number,
): PoolStateResult => {
  const validPools = pools.filter(
    (pool): pool is PoolData =>
      pool !== null &&
      pool !== undefined &&
      pool.poolAddress !== "0x0000000000000000000000000000000000000000",
  )

  const validAddresses = validPools.map((pool) => pool.poolAddress as Address)
  const isEnabled = validAddresses.length > 0

  // Create contracts for both state and liquidityLocked queries
  const stateContracts = validAddresses.map((address) =>
    createStateContractConfig(address, chainId),
  )
  const liquidityLockedContracts = validAddresses.map((address) =>
    createLiquidityLockedContractConfig(address, chainId),
  )

  // Combine both contract arrays
  const allContracts = [...stateContracts, ...liquidityLockedContracts]

  const { data, isError, isLoading, refetch } = useReadContracts({
    contracts: allContracts,
    query: {
      enabled: isEnabled,
    },
  })

  // Split the results back into state and liquidityLocked
  const stateResults = data?.slice(0, validAddresses.length)
  const liquidityLockedResults = data?.slice(validAddresses.length)

  const poolStates = validAddresses.reduce<PoolStateMap>((acc, address, index) => {
    const stateData = stateResults?.[index]?.result
    const liquidityLockedData = (liquidityLockedResults?.[index]?.result as bigint) ?? 0n
    const currentPool = validPools[index]

    if (_.isArray(stateData)) {
      const baseState = parsePoolState(stateData)
      const parsedLockedLiquidity = formatLiquidityLocked(
        liquidityLockedData,
        currentPool?.decimals,
      )

      acc[address] = {
        ...baseState,
        pool: currentPool,
        liquidityLocked: liquidityLockedData,
        parsedLockedLiquidity,
        liquidityFree:
          parseFloat(baseState.liquidity.toString()) -
          parseFloat(liquidityLockedData.toString()),
      }
    }

    return acc
  }, {})

  return {
    poolStates,
    isError,
    isLoading,
    refetch,
  }
}
