import { getAddress } from "viem"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Token, UniswapPool } from "../../types"
import { calculateToken0Amount, calculateToken1Amount } from "./functions"

export interface CreatePoolState {
  token0: Token | null
  amount0: string
  token1: Token | null
  amount1: string
  uniswapPool: UniswapPool | null
  leverage: { leverage: number; maintenance: number } | null
}

export const initialState: CreatePoolState = {
  token0: null,
  amount0: "",
  token1: null,
  amount1: "",
  uniswapPool: null,
  leverage: null,
}

export const createPoolSlice = createSlice({
  name: "createPool",
  initialState,
  reducers: {
    setToken0: (state: CreatePoolState, action: PayloadAction<Token | null>) => {
      state.token0 = action.payload
    },
    setAmount0: (state: CreatePoolState, action: PayloadAction<string>) => {
      state.amount0 = action.payload
      if (
        state.token0?.address &&
        state.uniswapPool?.sqrtPriceStartX96 &&
        getAddress(state.uniswapPool.token0) === getAddress(state.token0.address)
      ) {
        state.amount1 = calculateToken1Amount(
          Number(action.payload),
          state.uniswapPool.sqrtPriceStartX96,
        ).toString()
      } else if (
        state.token0?.address &&
        state.uniswapPool?.sqrtPriceStartX96 &&
        getAddress(state.uniswapPool.token1) === getAddress(state.token0?.address)
      ) {
        state.amount1 = calculateToken0Amount(
          Number(action.payload),
          state.uniswapPool.sqrtPriceStartX96,
        ).toString()
      }
    },
    setToken1: (state: CreatePoolState, action: PayloadAction<Token | null>) => {
      state.token1 = action.payload
    },
    setAmount1: (state: CreatePoolState, action: PayloadAction<string>) => {
      state.amount1 = action.payload
      if (
        state.token1?.address &&
        state.uniswapPool?.sqrtPriceStartX96 &&
        getAddress(state.uniswapPool.token0) === getAddress(state.token1?.address)
      ) {
        state.amount0 = calculateToken1Amount(
          Number(action.payload),
          state.uniswapPool.sqrtPriceStartX96,
        ).toString()
      } else if (
        state.token1?.address &&
        state.uniswapPool?.sqrtPriceStartX96 &&
        getAddress(state.uniswapPool.token1) === getAddress(state.token1?.address)
      ) {
        state.amount0 = calculateToken0Amount(
          Number(action.payload),
          state.uniswapPool.sqrtPriceStartX96,
        ).toString()
      }
    },
    setUniswapPool: (
      state: CreatePoolState,
      action: PayloadAction<UniswapPool | null>,
    ) => {
      state.uniswapPool = action.payload
    },
    setLeverage: (
      state: CreatePoolState,
      action: PayloadAction<{ leverage: number; maintenance: number } | null>,
    ) => {
      state.leverage = action.payload
    },
    resetCreatePoolState: (state) => {
      state.token0 = initialState.token0
      state.amount0 = initialState.amount0
      state.token1 = initialState.token1
      state.amount1 = initialState.amount1
      state.leverage = initialState.leverage
      state.uniswapPool = initialState.uniswapPool
    },
  },
})

export const {
  setToken0,
  setAmount0,
  setToken1,
  setAmount1,
  setUniswapPool,
  setLeverage,
  resetCreatePoolState,
} = createPoolSlice.actions

export default createPoolSlice.reducer
