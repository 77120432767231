import { Token } from "src/types"
import { getValidAddress } from "src/utils/getValidAddress"
import { convertInputToSize } from "src/utils/conversions"
import { ExactInputSingleParams } from "src/types"

const MAX_DEADLINE =
  115792089237316195423570985008687907853269984665640564039457584007913129639935n

export const constructExactInputSingleParams = (
  tokenIn: Token,
  tokenOut: Token,
  maintenance: bigint,
  oracle: string,
  recipient: string,
  amountIn: string,
  currentBlockTimestamp: bigint | undefined,
  transactionDeadline: string,
): ExactInputSingleParams => {
  return {
    tokenIn: getValidAddress(tokenIn.address),
    tokenOut: getValidAddress(tokenOut.address),
    maintenance,
    oracle: getValidAddress(oracle),
    recipient: getValidAddress(recipient),
    deadline: currentBlockTimestamp
      ? currentBlockTimestamp + BigInt(transactionDeadline) * BigInt("60")
      : MAX_DEADLINE,
    amountIn: convertInputToSize(amountIn, tokenIn.decimals) ?? 0n,
    amountOutMinimum: 0n,
    sqrtPriceLimitX96: 0n,
  }
}
