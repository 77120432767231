import { useEffect } from "react"
import { cleanInput } from "src/utils/cleanInput"

export const SlippageInput = ({
  inputValue,
  onChange,
  isAuto,
  onSetAuto,
}: {
  inputValue: string
  onChange: (value: string) => void
  isAuto: boolean
  onSetAuto: (selected: boolean) => void
}) => {
  useEffect(() => {
    if (isAuto) {
      onChange(cleanInput("0.5"))
    }
  }, [isAuto, inputValue, onChange])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(cleanInput(e.target.value))
    onSetAuto(false)
  }

  const handleBlur = (): void => {
    if (Number(inputValue) === 0 || Number(inputValue) > 50) {
      onChange(cleanInput("0.5"))
    }
  }

  const handleFocus = (): void => {
    if (isAuto) {
      onSetAuto(false)
    }
  }

  return (
    <div
      id="slippage-input"
      className="flex h-8 w-24 items-center space-x-1 rounded-lg bg-marginalBlack text-sm text-marginalGray-200"
    >
      <input
        value={inputValue}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        placeholder="0"
        min="0.01"
        max="50"
        pattern="^[0-9]*[.,]?[0-9]*$"
        autoComplete="off"
        className="h-8 w-full border-none bg-marginalBlack p-0 text-right text-sm text-marginalGray-600 outline-none focus:border-none focus:outline-none focus:ring-0 disabled:text-marginalGray-600"
        name="slippage-input"
      />
      <label htmlFor="slippage-input text-marginalGray-100">% </label>
    </div>
  )
}
