import { Address } from "viem"
import { BigNumberish } from "ethers"
import { simulateContract, writeContract, waitForTransactionReceipt } from "@wagmi/core"
import { wagmiConfig } from "src/wagmiConfig"
import marginalV1NonfungiblePositionManagerAbi from "src/constants/abis/MarginalV1NonfungiblePositionManager.json"
import { V1_NFT_POSITION_MANAGER_ADDRESS } from "src/constants/addresses"

export interface FreeParams {
  token0: Address
  token1: Address
  maintenance: bigint
  oracle: Address
  tokenId: string
  marginOut: bigint
  recipient: Address
  deadline: BigNumberish
}

export const free = async (freeParams: FreeParams, chainId: number) => {
  if (!freeParams) {
    throw new Error("Free parameters are required")
  }

  const { request } = await simulateContract(wagmiConfig, {
    chainId,
    address: V1_NFT_POSITION_MANAGER_ADDRESS[chainId],
    abi: marginalV1NonfungiblePositionManagerAbi,
    functionName: "free",
    args: [freeParams],
  })

  const hash = await writeContract(wagmiConfig, request)

  const receipt = await waitForTransactionReceipt(wagmiConfig, { hash })

  return receipt
}
