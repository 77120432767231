import { zeroAddress } from "viem"
import MarginalLogo from "src/assets/logos/marginal.svg"
import WethLogo from "src/assets/logos/weth.svg"
import EthLogo from "src/assets/logos/eth.svg"
import BrettLogo from "src/assets/logos/brett.svg"
import BeraLogo from "src/assets/logos/bera.svg"
import DegenLogo from "src/assets/logos/degen.svg"
import AnonLogo from "src/assets/logos/super-anon.svg"
import HarryPotterLogo from "src/assets/logos/harryPotterObamaSonic10Inu.svg"
import MogLogo from "src/assets/logos/mog.svg"
import SPXLogo from "src/assets/logos/spx.svg"
import YeetLogo from "src/assets/logos/yeet.svg"

import { type Token } from "src/types"

export const tokenList: Token[] = [
  {
    chainId: 1,
    name: "Ether",
    address: zeroAddress,
    symbol: "ETH",
    decimals: 18,
    imgUrl: EthLogo,
    priorityRank: 99,
  },
  {
    chainId: 11155111,
    name: "Ether",
    address: zeroAddress,
    symbol: "ETH",
    decimals: 18,
    imgUrl: EthLogo,
    priorityRank: 99,
  },
  {
    chainId: 8453,
    name: "Ether",
    address: zeroAddress,
    symbol: "ETH",
    decimals: 18,
    imgUrl: EthLogo,
    priorityRank: 99,
  },
  {
    chainId: 80084,
    address: zeroAddress,
    name: "Bera",
    symbol: "BERA",
    decimals: 18,
    imgUrl: BeraLogo,
    priorityRank: 99,
  },
  {
    chainId: 11155111,
    name: "Marginal DAO Token",
    symbol: "MARG",
    address: "0x43Bdd46b310a78C8f077c162D45dBe7e70F32217",
    decimals: 18,
    imgUrl: MarginalLogo,
    priorityRank: 1,
  },
  {
    chainId: 1,
    name: "Wrapped Ether",
    symbol: "WETH",
    address: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
    decimals: 18,
    imgUrl: WethLogo,
    priorityRank: 2,
  },
  {
    chainId: 11155111,
    name: "Wrapped Ether",
    symbol: "WETH",
    address: "0xfFf9976782d46CC05630D1f6eBAb18b2324d6B14",
    decimals: 18,
    imgUrl: WethLogo,
    priorityRank: 2,
  },
  {
    chainId: 8453,
    name: "Wrapped Ether",
    symbol: "WETH",
    address: "0x4200000000000000000000000000000000000006",
    decimals: 18,
    imgUrl: WethLogo,
    priorityRank: 2,
  },
  {
    chainId: 8453,
    name: "Degen",
    symbol: "DEGEN",
    address: "0x4ed4E862860beD51a9570b96d89aF5E1B0Efefed",
    decimals: 18,
    imgUrl: DegenLogo,
    priorityRank: 3,
  },
  {
    chainId: 8453,
    name: "Brett",
    symbol: "BRETT",
    address: "0x532f27101965dd16442E59d40670FaF5eBB142E4",
    decimals: 18,
    imgUrl: BrettLogo,
    priorityRank: 3,
  },
  {
    chainId: 8453,
    name: "Super Anon",
    symbol: "ANON",
    address: "0x0Db510e79909666d6dEc7f5e49370838c16D950f",
    decimals: 18,
    imgUrl: AnonLogo,
    priorityRank: 3,
  },
  {
    chainId: 1,
    name: "HarryPotterObamaSonic10Inu",
    symbol: "BITCOIN",
    address: "0x72e4f9f808c49a2a61de9c5896298920dc4eeea9",
    decimals: 8,
    imgUrl: HarryPotterLogo,
    priorityRank: 3,
  },
  {
    chainId: 1,
    name: "Mog Coin",
    symbol: "MOG",
    address: "0xaaee1a9723aadb7afa2810263653a34ba2c21c7a",
    decimals: 18,
    imgUrl: MogLogo,
    priorityRank: 3,
  },
  {
    chainId: 1,
    name: "SPX6900",
    symbol: "SPX",
    address: "0xe0f63a424a4439cbe457d80e4f4b51ad25b2c56c",
    decimals: 8,
    imgUrl: SPXLogo,
    priorityRank: 3,
  },
  {
    chainId: 80084,
    name: "Wrapped Bera",
    symbol: "WBERA",
    address: "0x7507c1dc16935B82698e4C63f2746A2fCf994dF8",
    decimals: 18,
    imgUrl: BeraLogo,
    priorityRank: 3,
  },
  {
    chainId: 80084,
    name: "Beartic",
    symbol: "BEARTIC",
    address: "0x1E94a8ceE3E5bD97e0cD933B8F8537fC3Db4FcE7",
    decimals: 18,
    imgUrl: "https://app.kodiak.finance/static/media/beartic.053d03b28aa2aa7b36a9.png",
    priorityRank: 3,
  },
  {
    chainId: 80084,
    name: "KEVIN TOKEN",
    symbol: "KEVIN",
    address: "0xbd6f6bd4a9463625452d19455ffbe11d7f018772",
    decimals: 18,
    imgUrl:
      "https://pbs.twimg.com/profile_images/1751046012494364672/rucKYJCl_400x400.jpg",
    priorityRank: 3,
  },
  {
    chainId: 80084,
    name: "Yeet",
    symbol: "YEET",
    address: "0x1740F679325ef3686B2f574e392007A92e4BeD41",
    decimals: 18,
    imgUrl: YeetLogo,
    priorityRank: 3,
  },
]

type TokensByChain = {
  [chainId: number]: Token[]
}

export const getRankedTokenListByChain = (tokens: Token[]): TokensByChain => {
  const groupedTokens = tokens.reduce<TokensByChain>((acc, token) => {
    if (!acc[token.chainId]) {
      acc[token.chainId] = []
    }
    acc[token.chainId].push(token)
    return acc
  }, {})

  // Sort tokens by priority rank, using 100 as default for tokens without rank
  Object.keys(groupedTokens).forEach((chainId) => {
    groupedTokens[Number(chainId)].sort(
      (a, b) => (a.priorityRank ?? 100) - (b.priorityRank ?? 100),
    )
  })

  return groupedTokens
}

export const TOKENS_BY_CHAIN = getRankedTokenListByChain(tokenList)

export const getTokenByAddress = (
  address: string | undefined,
  chainId: number,
): Token | undefined => {
  const tokens = TOKENS_BY_CHAIN[chainId]
  if (!tokens || !address) return undefined

  return tokens.find((token) => token.address?.toLowerCase() === address?.toLowerCase())
}
