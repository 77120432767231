import { Token } from "src/types"
import { formatBigIntToString } from "src/utils/formatBigIntToString"
import { calculatePoolTokensLiquidityAmounts } from "./calculatePoolTokensLiquidityAmounts"

interface TokenLiquidityData {
  raw: bigint | null
  parsed?: string | null
}

export interface PoolLiquidityPair {
  token0: TokenLiquidityData
  token1: TokenLiquidityData
}

interface PoolLiquidityData {
  available: PoolLiquidityPair
  locked: PoolLiquidityPair
  total: PoolLiquidityPair
}

interface DerivePoolDataParams {
  token0: Token | null
  token1: Token | null
  sqrtPriceX96: bigint | undefined
  liquidityAvailable: bigint | undefined
  liquidityLocked: bigint | undefined
  liquidityTotal: bigint | undefined
}

/**
 * Calculates token amounts for a specific liquidity value
 * @param liquidity - The liquidity amount to calculate tokens for
 * @param sqrtPriceX96 - The square root price scaled by 2^96
 * @param token0 - First token in the pair
 * @param token1 - Second token in the pair
 * @returns Calculated token amounts with parsing and formatting
 */
const calculateTokenAmounts = (
  liquidity: bigint | undefined,
  sqrtPriceX96: bigint | undefined,
  token0: Token | null,
  token1: Token | null,
): PoolLiquidityPair => {
  const { token0: amount0, token1: amount1 } = calculatePoolTokensLiquidityAmounts(
    liquidity,
    sqrtPriceX96,
  )

  return {
    token0: {
      raw: amount0,
      parsed: formatBigIntToString(amount0, token0?.decimals),
    },
    token1: {
      raw: amount1,
      parsed: formatBigIntToString(amount1, token1?.decimals),
    },
  }
}

/**
 * Derives comprehensive pool data including available, locked, and total liquidity amounts
 * for both tokens in the pool
 *
 * @param params - Object containing required pool parameters
 * @returns Object containing derived pool liquidity data
 */
export const derivePoolLiquidity = ({
  token0,
  token1,
  sqrtPriceX96,
  liquidityAvailable,
  liquidityLocked,
  liquidityTotal,
}: DerivePoolDataParams): PoolLiquidityData => {
  // Calculate amounts for each liquidity type
  const available = calculateTokenAmounts(
    liquidityAvailable,
    sqrtPriceX96,
    token0,
    token1,
  )
  const locked = calculateTokenAmounts(liquidityLocked, sqrtPriceX96, token0, token1)
  const total = calculateTokenAmounts(liquidityTotal, sqrtPriceX96, token0, token1)

  return {
    available,
    locked,
    total,
  }
}

interface MulDivParams {
  x?: bigint | null
  y?: bigint | null
  d?: bigint | null
}

/**
 * Calculates (x * y) / d using native BigInt
 * @param params Object containing x (multiplicand), y (multiplier), and d (divisor)
 * @returns The calculated result as a string, or undefined if inputs are invalid
 */
export const calculateMulDiv = ({ x, y, d }: MulDivParams): string | undefined => {
  // Early return if any required parameter is missing
  if (!x || !y || !d) {
    return undefined
  }

  // Early return if divisor is zero to prevent division by zero
  if (d === 0n) {
    return undefined
  }

  try {
    const result = (x * y) / d
    return result.toString()
  } catch (error) {
    console.error("Error in calculateMulDiv:", error)
    return undefined
  }
}
