import _ from "lodash"
import { useReadContracts } from "wagmi"
import { Address, erc20Abi } from "viem"
import { type Token } from "src/types"
import { formatBigIntToString } from "src/utils/formatBigIntToString"
import multicallAbi from "src/constants/abis/multicall.json"
import { zeroAddress } from "viem"

interface TokenBalanceQuery {
  tokens: Token[]
  owner: Address
}

export interface TokenBalance extends Token {
  balance: bigint
  parsedBalance?: string
}

interface TokenBalanceMap {
  [owner: string]: TokenBalance[]
}

interface ContractConfig {
  address: Address
  abi: any
  functionName: "balanceOf" | "getEthBalance"
  args: [Address]
}

interface TokenBalanceResult {
  balances: TokenBalanceMap
  isError: boolean
  isLoading: boolean
  refetch: () => void
}

const MULTICALL3_ADDRESS: Address = "0xcA11bde05977b3631167028862bE2a173976CA11"

const createErc20ContractConfig = (
  tokenAddress: Address,
  ownerAddress: Address,
): ContractConfig => ({
  address: tokenAddress,
  abi: erc20Abi,
  functionName: "balanceOf",
  args: [ownerAddress],
})

const createMulticallContractConfig = (ownerAddress: Address): ContractConfig => ({
  address: MULTICALL3_ADDRESS,
  abi: multicallAbi,
  functionName: "getEthBalance",
  args: [ownerAddress],
})

const createContractConfigs = (query: TokenBalanceQuery): ContractConfig[] =>
  query.tokens.map((token) =>
    token.address === zeroAddress
      ? createMulticallContractConfig(query.owner)
      : createErc20ContractConfig(token.address as Address, query.owner),
  )

const parseTokenBalance = (token: Token, rawBalance: bigint): TokenBalance => ({
  ...token,
  balance: rawBalance,
  parsedBalance: formatBigIntToString(rawBalance, token.decimals),
})

export const useMultipleErc20TokenBalances = (
  queries: TokenBalanceQuery[],
): TokenBalanceResult => {
  const validQueries = queries.filter(
    (query): query is TokenBalanceQuery =>
      !_.isEmpty(query.tokens) && !_.isEmpty(query.owner),
  )

  const contractConfigs = validQueries.flatMap(createContractConfigs)
  const isEnabled = contractConfigs.length > 0

  const { data, isError, isLoading, refetch } = useReadContracts({
    contracts: contractConfigs,
    query: {
      enabled: isEnabled,
    },
  })

  const balances = validQueries.reduce<TokenBalanceMap>((acc, query, queryIndex) => {
    const queryResults = data?.slice(
      queryIndex * query.tokens.length,
      (queryIndex + 1) * query.tokens.length,
    )

    if (queryResults) {
      acc[query.owner] = query.tokens.map((token, tokenIndex) => {
        const rawBalance = queryResults[tokenIndex]?.result as bigint
        return parseTokenBalance(token, rawBalance ?? 0n)
      })
    }

    return acc
  }, {})

  return {
    balances,
    isError,
    isLoading,
    refetch,
  }
}
