import { NavLinks } from "src/components/Header/NavLinks"
import { BrandLogo } from "src/components/Header/BrandLogo"
import { CustomConnectButton } from "src/components/Header/CustomConnectButton"
import { MobileHeader } from "src/components/Header/MobileHeader"

export const Nav = () => {
  return (
    <>
      <MobileHeader />
      <header className="flex w-full bg-transparent">
        <div className="mx-auto hidden w-full max-w-screen-2xl place-content-between items-center bg-transparent p-6 md:flex">
          <div className="min-w-64">
            <BrandLogo />
          </div>

          <NavLinks />

          <div className="min-w-64">
            <CustomConnectButton />
          </div>
        </div>
      </header>
    </>
  )
}
