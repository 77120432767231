import { simulateContract, waitForTransactionReceipt, writeContract } from "@wagmi/core"
import { Address, parseEther } from "viem"
import { wagmiConfig } from "src/wagmiConfig"
import { MintQuoteParams } from "src/types"
import marginalV1NonfungiblePositionManagerAbi from "src/constants/abis/MarginalV1NonfungiblePositionManager.json"

export const mint = async (
  params: MintQuoteParams | null,
  escrowRewards: string | null | undefined,
  positionManager: Address,
  chainId: number,
) => {
  const { request } = await simulateContract(wagmiConfig, {
    chainId,
    address: positionManager,
    abi: marginalV1NonfungiblePositionManagerAbi,
    functionName: "mint",
    args: [params],
    value: escrowRewards ? parseEther(escrowRewards) : parseEther("0.012"),
  })

  const hash = await writeContract(wagmiConfig, request)

  const receipt = await waitForTransactionReceipt(wagmiConfig, { hash })

  return receipt
}
