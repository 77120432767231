import { useState, useEffect } from "react"
import { type Token } from "src/types"
import { useKeyPress } from "src/hooks/useKeyPress"
import { SelectTokenOption } from "src/components/ui/SelectTokenOption"

interface TokenSelectorProps {
  tokenOptions: Token[]
  selectedToken: Token | null
  isOpen: boolean
  onSelect: (token: Token) => void
  onClose: () => void
}

export const TokenSelector = ({
  tokenOptions,
  selectedToken,
  isOpen,
  onSelect,
  onClose,
}: TokenSelectorProps) => {
  const [currentAddress, setCurrentAddress] = useState<string | null>(null)

  const arrowUp = useKeyPress("ArrowUp", isOpen)
  const arrowDown = useKeyPress("ArrowDown", isOpen)
  const enterPress = useKeyPress("Enter", isOpen)

  const onSelectToken = (token: Token) => {
    onSelect(token)
    onClose()
  }

  const onArrowUp = () => {
    const currentIndex = tokenOptions.findIndex(
      (token) => token.address === currentAddress,
    )
    const prevIndex = currentIndex - 1
    if (arrowUp && prevIndex >= 0) {
      setCurrentAddress(tokenOptions[prevIndex].address)
    }
  }

  const onArrowDown = () => {
    const currentIndex = tokenOptions.findIndex(
      (token) => token.address === currentAddress,
    )
    const nextIndex = currentIndex + 1
    if (arrowDown && nextIndex < tokenOptions.length) {
      setCurrentAddress(tokenOptions[nextIndex].address)
    }
  }

  const onEnter = () => {
    if (currentAddress !== null) {
      const token = tokenOptions.find((token) => token.address === currentAddress)
      if (token) {
        onSelectToken(token)
      }
    }
  }

  useEffect(onArrowDown, [arrowDown]) // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(onArrowUp, [arrowUp]) // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(onEnter, [enterPress]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="flex flex-col">
      {tokenOptions.length > 0 ? (
        tokenOptions.map((token) => (
          <SelectTokenOption
            key={token.address}
            token={token}
            onClick={() => onSelectToken(token)}
            selectedToken={selectedToken}
            isActive={currentAddress === token.address}
          />
        ))
      ) : (
        <div className="flex items-center justify-center p-4 text-sm text-marginalGray-400">
          No tokens available
        </div>
      )}
    </div>
  )
}
