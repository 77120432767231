import { ExactInputSingleParams, ExactInputSingleQuote } from "src/types"
import { formatStringToBigInt } from "src/utils/formatStringToBigInt"

const DECIMALS = 18
const HUNDRED_PERCENT = "100"
const ONE = 1000000000000000000n

interface SlippageCalculationParams {
  amountIn: bigint
  poolPrice: bigint
  zeroForOne: boolean
  maxSlippage: string
}

const calculateMinimumOutputWithSlippage = ({
  amountIn,
  poolPrice,
  zeroForOne,
  maxSlippage,
}: SlippageCalculationParams): bigint | undefined => {
  const effectivePercent = (100 - parseFloat(maxSlippage)).toString()

  const slippageNumerator = formatStringToBigInt(effectivePercent, DECIMALS)
  const slippageDenominator = formatStringToBigInt(HUNDRED_PERCENT, DECIMALS)

  if (!ONE || !slippageNumerator || !slippageDenominator) {
    return undefined
  }

  const effectivePercentage = (slippageNumerator * ONE) / slippageDenominator

  // Calculate theoretical output without slippage
  const theoreticalOutput = zeroForOne
    ? (amountIn * poolPrice) / ONE
    : (amountIn * ONE) / poolPrice

  // Apply slippage to theoretical output
  return (theoreticalOutput * effectivePercentage) / ONE
}

export const getSwapParamsWithSlippage = (
  params: ExactInputSingleParams | null,
  quote: ExactInputSingleQuote | null,
  zeroForOne: boolean,
  poolPrice: bigint | undefined,
  maxSlippage: string,
): ExactInputSingleParams | null => {
  if (!params || !quote || !poolPrice) {
    return null
  }

  const minimumOutput = calculateMinimumOutputWithSlippage({
    amountIn: params.amountIn,
    poolPrice: BigInt(poolPrice),
    zeroForOne,
    maxSlippage,
  })

  if (!minimumOutput) {
    return null
  }

  // Safety check for large numbers
  if (minimumOutput > BigInt(Number.MAX_SAFE_INTEGER)) {
    console.warn("Minimum output amount exceeds safe integer limits")
    return null
  }

  return {
    ...params,
    amountOutMinimum: minimumOutput,
  }
}
