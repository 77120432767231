import _ from "lodash"
import { useCallback, useMemo } from "react"
import { isBlank } from "src/utils/isBlank"
import { isOnlyZeroes } from "src/utils/isOnlyZeroes"
import { AppState } from "src/state"
import { useAppDispatch, useAppSelector } from "src/state/hooks"
import {
  EMPTY_LBP_QUOTE,
  setInputValue,
  setInputToken,
  setOutputToken,
  setIsBuy,
  resetLbpState,
} from "./reducer"
import { Token } from "src/types"

export const useLbpState = (): AppState["lbp"] => {
  return useAppSelector((state) => state.lbp)
}

export function useLbpActionHandlers(): {
  onUserInput: (input: string) => void
  onSelectInputToken: (token: Token | null) => void
  onSelectOutputToken: (token: Token | null) => void
  onSetIsBuy: (isBuy: boolean) => void
  onResetLbpState: () => void
} {
  const dispatch = useAppDispatch()

  const onUserInput = useCallback(
    (input: string) => {
      dispatch(setInputValue(input))
    },
    [dispatch],
  )

  const onSelectInputToken = useCallback(
    (token: Token | null) => {
      dispatch(setInputToken(token))
    },
    [dispatch],
  )

  const onSelectOutputToken = useCallback(
    (token: Token | null) => {
      dispatch(setOutputToken(token))
    },
    [dispatch],
  )

  const onSetIsBuy = useCallback(
    (isBuy: boolean) => {
      dispatch(setIsBuy(isBuy))
    },
    [dispatch],
  )

  const onResetLbpState = useCallback(() => {
    dispatch(resetLbpState())
  }, [dispatch])

  return {
    onUserInput,
    onSelectInputToken,
    onSelectOutputToken,
    onSetIsBuy,
    onResetLbpState,
  }
}

export function useLbpValidations() {
  const { inputValue, inputToken, outputToken, exactInputSingleQuote, isBuy } =
    useLbpState()

  const isInputValid = useMemo(() => {
    return _.isString(inputValue) && !isBlank(inputValue) && !isOnlyZeroes(inputValue)
  }, [inputValue])

  const isInputTokenValid = useMemo(() => {
    return !_.isNull(inputToken)
  }, [inputToken])

  const isOutputTokenValid = useMemo(() => {
    return !_.isNull(outputToken)
  }, [outputToken])

  const isLbpInputsValid = useMemo(() => {
    return isInputValid && isInputTokenValid && isOutputTokenValid
  }, [isInputValid, isInputTokenValid, isOutputTokenValid])

  const isLbpSwapQuoteValid = useMemo(() => {
    return exactInputSingleQuote !== EMPTY_LBP_QUOTE
  }, [exactInputSingleQuote])

  return {
    isInputValid,
    isInputTokenValid,
    isOutputTokenValid,
    isLbpInputsValid,
    isLbpSwapQuoteValid,
    isBuy,
  }
}
